import { AccordionModelDetalhado } from "../../../../../../components/dialogos/modal-accordion/modal-accordion";
import { ResponseModel } from "../../../../../../models/api/comum/response-base";
import { PermissoesLote } from "../../../../../../models/permissoes/estoque/lote/permissoes-lote";
import {
  checarResponseExibeMensagemExecutadoComSucesso,
  tratarErroApi,
} from "../../../../../../utils/api/api-utils";
import {
  GeradorMensagensNotificacao,
  JanelasDeNotificacaoTitulos,
} from "../../../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../../utils/common/permissoes-utils";
import { exibirConfirmacao } from "../../../../../../utils/dialogos";
import { CancelarLotesResponse, LoteGridModel } from "../../../models/lote.api";
import { LoteServico } from "../../../servicos/lote.servico";

async function cancelarLoteHandler(
  registro: LoteGridModel,
  handleAtualizarGrid: () => void
) {
  if (
    !verificaComNotificacaoSeUsuarioPossuiPermissoes([
      PermissoesLote.InserirEditar,
    ])
  ) {
    return;
  }

  const confimar = await exibirConfirmacao(
    "Confirmar cancelamento",
    "Tem certeza de que deseja cancelar o lote/número de série selecionado?"
  );

  if (!confimar) {
    return;
  }

  return cancelarLote(registro.id, handleAtualizarGrid);
}

async function cancelarLotesHandler(
  registros: LoteGridModel[],
  handleAtualizarGrid: () => void
) {
  if (
    !verificaComNotificacaoSeUsuarioPossuiPermissoes([
      PermissoesLote.InserirEditar,
    ])
  ) {
    return;
  }

  const confimar = await exibirConfirmacao(
    "Confirmar cancelamento",
    "Tem certeza de que deseja cancelar os lotes/números de série selecionados?"
  );

  if (!confimar) {
    return;
  }

  return cancelarLotes(registros, handleAtualizarGrid);
}

async function cancelarLote(loteId: number, handleAtualizarGrid: () => void) {
  try {
    const resposta = await LoteServico.Cancelar(loteId);

    if (resposta) {
      checarResponseExibeMensagemExecutadoComSucesso(
        resposta,
        GeradorMensagensNotificacao.CanceladoComSucessoMasculino("Lote")
      );
      handleAtualizarGrid();
    }
  } catch (erro) {
    tratarErroApi(erro);
  }
}

async function cancelarLotes(
  registros: LoteGridModel[],
  handleAtualizarGrid: () => void
) {
  try {
    const resposta = await LoteServico.CancelarLotes(
      registros.map((x) => x.id)
    );

    handleAtualizarGrid();

    return handleResponse(resposta);
  } catch (erro) {
    tratarErroApi(erro);
  }
}

function handleResponse(response: ResponseModel<CancelarLotesResponse[]>) {
  if (response.sucesso) {
    const resultadosDaConfirmacao = response.model;
    const lotesCanceladosComSucesso = resultadosDaConfirmacao.filter(
      (x) => x.valido
    );
    const lotesCanceladosSemSucesso = resultadosDaConfirmacao.filter(
      (x) => !x.valido
    );

    return handlerNotificacoes(
      lotesCanceladosComSucesso,
      lotesCanceladosSemSucesso
    );
  }
}

function handlerNotificacoes(
  lotesCanceladosComSucesso: CancelarLotesResponse[],
  lotesCanceladosSemSucesso: CancelarLotesResponse[]
) {
  const mensagem = formatarMensagensAccordion(
    lotesCanceladosComSucesso,
    lotesCanceladosSemSucesso
  );

  return {
    accordionVisivel: true,
    mensagem: mensagem,
  };
}

export function formatarMensagensAccordion(
  lotesCanceladosComSucesso: CancelarLotesResponse[],
  lotesCanceladosSemSucesso: CancelarLotesResponse[]
): AccordionModelDetalhado[] {
  const accordionModel: AccordionModelDetalhado[] = [];

  if (lotesCanceladosComSucesso.length > 0) {
    let mensagem = "";

    if (lotesCanceladosComSucesso.length == 1) {
      mensagem = `${lotesCanceladosComSucesso.length} lote/número de série foi cancelado com sucesso. Clique para ver mais:`;
    } else {
      mensagem = `${lotesCanceladosComSucesso.length} lotes/números de série foram cancelados com sucesso. Clique para ver mais:`;
    }

    accordionModel.push({
      grupoDeMensagensTitulo: mensagem,
      mensagensDetalhadas: lotesCanceladosComSucesso.map((x) => {
        return {
          mensagemDetalhadaTitulo: `Lote/Número de série "${x.loteDoFabricanteOuNumeroDeSerie}", fabricante "${x.apelidoFabricante}", item ${x.itemCodigo} (${x.itemDescricao}).`,
        };
      }),
    });
  }

  if (lotesCanceladosSemSucesso.length > 0) {
    let mensagem = "";

    if (lotesCanceladosSemSucesso.length == 1) {
      mensagem = `${lotesCanceladosSemSucesso.length} lote/número de série não foi cancelado com sucesso. Clique para ver mais:`;
    } else {
      mensagem = `${lotesCanceladosSemSucesso.length} lotes/números de série não foram cancelados com sucesso. Clique para ver mais:`;
    }

    accordionModel.push({
      grupoDeMensagensTitulo: mensagem,
      mensagensDetalhadas: lotesCanceladosSemSucesso.map((x) => {
        return {
          mensagemDetalhadaTitulo: `Lote/Número de série "${x.loteDoFabricanteOuNumeroDeSerie}", fabricante "${x.apelidoFabricante}", item ${x.itemCodigo} (${x.itemDescricao}).`,
          listaDeErrosTitulo: JanelasDeNotificacaoTitulos.Motivo,
          mensagensDeErro: x.listaDeErros.map((y) => y.mensagem),
        };
      }),
    });
  }

  return accordionModel;
}

export { cancelarLoteHandler, cancelarLotesHandler };
