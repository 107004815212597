import { AxiosError } from "axios";
import getApi from "../../configs/api";
import {
  EstoqueMovimentacaoGridModel,
  EstoqueMovimentacaoSelecionarGridModel,
} from "../../features/estoque/estoque-movimentacao/models/estoque-movimentacao";
import { MovimentacaoBaseGridModel } from "../../features/estoque/estoque-movimentacao/models/estoque-movimentacao.api";
import {
  RetiradaDeAparaRequest,
  RetiradaDeMaterialRequest,
} from "../../features/producao/ordem-de-producao/models/ordem-de-producao.api";
import {
  ResponseBase,
  ResponseModel,
} from "../../models/api/comum/response-base";
import { checarResponse, tratarErroApi } from "../../utils/api/api-utils";
import DataSourceFactory, {
  DataSourceFiltragem,
  DataSourceRawFilter,
} from "../../utils/grid/data-source-factory";
import { NomesEndpoints } from "../comum/nomesEndpoints";
import { ServiceBase } from "../comum/serviceBase";

class EstoqueMovimentacaoService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.EstoqueMovimentacao);
  }

  public async VerificaSeInsumoPossuiMovimentacoes(idInsumo: number) {
    const api = getApi();
    const response = await api.get<boolean>(
      `${this._nomeEndpoint}/VerificaSeInsumoPossuiMovimentacoes?id=${idInsumo}`
    );

    return response.data;
  }

  public async ObterRetiradaDeMaterial(id: number) {
    const api = getApi();
    const response = await api.get<ResponseModel<RetiradaDeMaterialRequest>>(
      `${this._nomeEndpoint}/ObterRetiradaDeMaterial?id=${id}`
    );

    return response.data;
  }

  public async ObterRetiradaDeApara(id: number) {
    const api = getApi();
    const response = await api.get<ResponseModel<RetiradaDeAparaRequest>>(
      `${this._nomeEndpoint}/ObterRetiradaDeApara?id=${id}`
    );

    return response.data;
  }

  public ObterDataSourceParaGridRetiradaDeMaterial(
    id: number,
    filtroPadrao?: DataSourceFiltragem<MovimentacaoBaseGridModel>[],
    filtroPadraoExato?: DataSourceRawFilter<MovimentacaoBaseGridModel>
  ) {
    return DataSourceFactory.CriarParaGrid<MovimentacaoBaseGridModel, number>(
      `${this._nomeEndpoint}/GridRetiradaDeMaterial?id=${id}`,
      filtroPadrao,
      filtroPadraoExato
    );
  }

  public ObterDataSourceParaGridRetiradaDeApara(
    id: number,
    filtroPadrao?: DataSourceFiltragem<MovimentacaoBaseGridModel>[],
    filtroPadraoExato?: DataSourceRawFilter<MovimentacaoBaseGridModel>
  ) {
    return DataSourceFactory.CriarParaGrid<MovimentacaoBaseGridModel, number>(
      `${this._nomeEndpoint}/GridRetiradaDeApara?id=${id}`,
      filtroPadrao,
      filtroPadraoExato
    );
  }

  public ObterDataSourceParaConclusoesOrdemDeProducao(
    id: number,
    filtroPadrao?: DataSourceFiltragem<MovimentacaoBaseGridModel>[],
    filtroPadraoExato?: DataSourceRawFilter<MovimentacaoBaseGridModel>
  ) {
    return DataSourceFactory.CriarParaGrid<MovimentacaoBaseGridModel, number>(
      `${this._nomeEndpoint}/GridConclusoesOrdemDeProducao?id=${id}`,
      filtroPadrao,
      filtroPadraoExato
    );
  }

  public async EstornarMovimentacoes(
    movimentacoes: EstoqueMovimentacaoSelecionarGridModel[]
  ) {
    try {
      const api = getApi();
      const response = await api.post<ResponseBase>(
        `${this._nomeEndpoint}/EstornarMovimentacoes`,
        {
          movimentacoes: movimentacoes.map((mov) => {
            return {
              movimentacaoId: mov.id,
              quantidade: mov.quantidadeParaEstornar,
            };
          }),
        }
      );

      checarResponse(response.data);
      return response.data;
    } catch (error) {
      tratarErroApi(error);

      return {
        sucesso: false,
        mensagem: (error as AxiosError<ResponseBase, any>)?.response?.data
          ?.mensagem,
      } as ResponseBase;
    }
  }

  public async ObterMovimentacoesDeConclusao(
    ordemDeProducaoId: number | undefined,
    quantidade: number
  ) {
    if (!ordemDeProducaoId) {
      return undefined;
    }

    const api = getApi();
    try {
      const response = await api.get<
        ResponseModel<EstoqueMovimentacaoGridModel[]>
      >(
        `${this._nomeEndpoint}/gridMovimentacoesDeConclusao?ordemDeProducaoId=${ordemDeProducaoId}`
      );

      checarResponse(response.data);

      const movimentacoes = response.data.model
        .map((x) => x as EstoqueMovimentacaoSelecionarGridModel)
        .sort((a, b) => {
          return (
            new Date(b.dataDaMovimentacao).getTime() -
            new Date(a.dataDaMovimentacao).getTime()
          );
        });
      let quantidadeParaEstornar = quantidade;
      movimentacoes.forEach((x) => {
        x.quantidadeParaEstornar = Math.min(
          x.quantidade,
          quantidadeParaEstornar
        );
        quantidadeParaEstornar -= x.quantidadeParaEstornar;
      });

      return movimentacoes;
    } catch (error) {
      tratarErroApi(error);
    }

    return undefined;
  }
}

export const EstoqueMovimentacaoServico = new EstoqueMovimentacaoService();
