import Decimal from "decimal.js";
import { useEffect, useMemo, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import {
  FormDateBox,
  FormNumberBox,
  FormSelectBox,
  FormSelectBoxLazyMxp,
} from "../../../../../../components/formularios";
import FormNumberBoxSimples from "../../../../../../components/formularios/numberbox-simples";
import { assertConfiguracaoExibicaoEBuscaType } from "../../../../../../components/formularios/selectbox-lazy-mxp";
import FormSelectBoxSimples from "../../../../../../components/formularios/selectbox-simples";
import { Coluna, Linha } from "../../../../../../components/layout/grid-system";
import ComboContaContabilMxp from "../../../../../../components/mxp/inputs-custom/conta-contabil";
import Sessao from "../../../../../../components/organizacao/sessao";
import { PermissoesContaContabil } from "../../../../../../models/permissoes/contabilidade/conta-contabil/permissoes-conta-contabil-b";
import { obterFormatStringNumero } from "../../../../../../utils/formatadores/formatador-de-numeros";
import {
  FormataDescricao,
  FormatadoresSelectBox,
} from "../../../../../../utils/formatadores/formatador-de-selectbox";
import GridCentroDeCustos from "../../../../centro-de-custos/componentes/grid";
import { CentroDeCustosGridModel } from "../../../../centro-de-custos/models/centro-de-custos";
import { CentroDeCustosServico } from "../../../../centro-de-custos/servicos/centro-de-custos.service";
import { ObrigatoriedadeCentroDeCustos } from "../../../../conta-contabil/models/conta-contabil";
import ImobilizadoConstantes from "../../../models/imobilizado.constantes";
import {
  EstadoDaDepreciacao,
  FormaDeCalculoDaPrimeiraParcela,
  MesDeInicioDaDepreciacao,
  SelectItemEnumEstadoDaDepreciacao,
  SelectItemEnumFormaDeCalculoDaPrimeiraParcela,
  SelectItemEnumMesDeInicioDaDepreciacao,
  SelectItemEnumTipoDeDepreciacao,
  TipoDeDepreciacao,
} from "../../../models/imobilizado.enums";
import { ImobilizadoViewModel } from "../../../models/imobilizado.viewmodel";

const centroDeCustosDataSource =
  CentroDeCustosServico.ObterDataSourceParaSelectBoxLazy<CentroDeCustosGridModel>(
    {
      camposRetorno: ["id", "codigo", "descricao", "classificacao"],
      camposOrdenacao: [{ campo: "classificacao", desc: false }],
    }
  );

const configuracoesExibicaoSelectCentroCusto =
  assertConfiguracaoExibicaoEBuscaType<CentroDeCustosGridModel>({
    expressaoDeBusca: ["id", "codigo", "descricao", "classificacao"],
    nomeCampoChave: "id",
    nomeCampoExibicao: (c) => {
      if (c) {
        return FormataDescricao(
          FormatadoresSelectBox.ClassificacaoCodigoDescricaoEspacoParenteses,
          c.classificacao,
          c.descricao,
          c.codigo
        );
      }

      return "";
    },
  });

interface AbaDepreciacaoImobilizadoProps {
  hookForm: UseFormReturn<ImobilizadoViewModel, any, undefined>;
}

export default function AbaDepreciacaoImobilizado({
  hookForm,
}: AbaDepreciacaoImobilizadoProps) {
  const { control, watch, setValue, trigger } = hookForm;

  const existeLancamentosContabeis =
    watch("valorDepreciadoPorLancamentoContabil") > 0;

  const [
    desabilitarCentroDeCustosDeDebito,
    setDesabilitarCentroDeCustosDeDebito,
  ] = useState(false);

  const [
    desabilitarCentroDeCustosDeCredito,
    setDesabilitarCentroDeCustosDeCredito,
  ] = useState(false);

  const custoAquisicao = watch("valorDaAquisicao");
  const valorResidual = watch("valorResidual");
  const percentual = watch("percentualDeDepreciacao") ?? 0;
  const tipoDepreciacao = watch("tipoDeDepreciacao");
  const formaDeCalculoDaPrimeiraParcela = watch(
    "formaDeCalculoDaPrimeiraParcela"
  );

  const mesDeInicioDaDepreciacaoDesabilitado = useMemo(() => {
    if (
      !tipoDepreciacao ||
      obterEstadoDaDepreciacao() != EstadoDaDepreciacao.NaoIniciada
    ) {
      return true;
    }

    if (
      formaDeCalculoDaPrimeiraParcela ==
        FormaDeCalculoDaPrimeiraParcela.ProRata &&
      tipoDepreciacao == TipoDeDepreciacao.Mensal
    ) {
      return true;
    }

    return false;
  }, [tipoDepreciacao, formaDeCalculoDaPrimeiraParcela]);

  const valorParcelaDepreciacao = useMemo(() => {
    if (!tipoDepreciacao) {
      return 0;
    }

    let valor = (custoAquisicao - valorResidual) * (percentual / 100);

    if (tipoDepreciacao == TipoDeDepreciacao.Mensal) {
      valor = valor / 12;
    }

    return new Decimal(valor).toNumber();
  }, [custoAquisicao, valorResidual, percentual, tipoDepreciacao]);

  useEffect(() => {
    setValue("valorParcelaDepreciacao", valorParcelaDepreciacao);
    trigger("valorParcelaDepreciacao");
  }, [valorParcelaDepreciacao]);

  useEffect(() => {
    if (
      formaDeCalculoDaPrimeiraParcela ==
        FormaDeCalculoDaPrimeiraParcela.ProRata &&
      tipoDepreciacao == TipoDeDepreciacao.Mensal
    ) {
      setValue(
        "mesDeInicioDaDepreciacao",
        MesDeInicioDaDepreciacao.MesDaAquisicao
      );
    }
  }, [formaDeCalculoDaPrimeiraParcela, tipoDepreciacao]);

  function obterQuantidadeDeParcelasEmMeses(quantidade: number) {
    return watch("tipoDeDepreciacao") === TipoDeDepreciacao.Anual
      ? quantidade * 12
      : quantidade;
  }

  function calcularVidaRestante() {
    return (
      watch("vidaUtilEmMeses") -
      obterQuantidadeDeParcelasEmMeses(
        watch("quantidadeInicialDeParcelasDepreciadas")
      ) -
      obterQuantidadeDeParcelasEmMeses(
        watch("quantidadeParcelasDepreciadasPorLancamentoContabil")
      )
    );
  }

  function obterEstadoDaDepreciacao() {
    const vidaRestante = calcularVidaRestante();
    const vidaUtil = watch("vidaUtilEmMeses");

    if (vidaRestante == vidaUtil) {
      return EstadoDaDepreciacao.NaoIniciada;
    } else if (vidaRestante > 0 && vidaRestante < vidaUtil) {
      return EstadoDaDepreciacao.EmAndamento;
    } else if (vidaRestante == 0) {
      return EstadoDaDepreciacao.Concluida;
    }
  }

  function editCentroDeCustosDeDebito(e: any) {
    const desabilitar =
      e.selectedItem.ObrigatoriedadeCentroDeCustos ==
      ObrigatoriedadeCentroDeCustos.Proibida;

    setDesabilitarCentroDeCustosDeDebito(desabilitar);

    if (desabilitar) {
      setValue("centroDeCustosDeDebitoDaDepreciacaoId", null);
    }
  }

  function editCentroDeCustosDeCredito(e: any) {
    const desabilitar =
      e.selectedItem.ObrigatoriedadeCentroDeCustos ==
      ObrigatoriedadeCentroDeCustos.Proibida;

    setDesabilitarCentroDeCustosDeCredito(desabilitar);

    if (desabilitar) {
      setValue("centroDeCustosDeCreditoDaDepreciacaoId", null);
    }
  }

  return (
    <>
      <Linha>
        <Coluna md={3}>
          <FormDateBox
            name="dataDaAquisicao"
            titulo="Data de aquisição"
            control={control}
            requerido
            aceitaValorCustomizado={true}
            aceitaDigitacaoComMascara={true}
          />
        </Coluna>
        <Coluna md={6}>
          <FormSelectBox
            name="formaDeCalculoDaPrimeiraParcela"
            titulo="Forma de cálculo da primeira parcela"
            control={control}
            dataSource={SelectItemEnumFormaDeCalculoDaPrimeiraParcela}
            somenteLeitura={
              obterEstadoDaDepreciacao() != EstadoDaDepreciacao.NaoIniciada
            }
            requerido
          />
        </Coluna>
        <Coluna md={3}>
          <FormNumberBox
            name="valorDaAquisicao"
            titulo="Custo de aquisição"
            control={control}
            requerido
            formato={obterFormatStringNumero(2)}
          />
        </Coluna>
      </Linha>
      <Linha>
        <Coluna md={3}>
          <FormNumberBox
            name="valorResidual"
            titulo="Valor residual"
            control={control}
            formato={obterFormatStringNumero(2)}
          />
        </Coluna>
        <Coluna md={3}>
          <FormSelectBox
            name="tipoDeDepreciacao"
            titulo="Tipo de depreciação"
            control={control}
            dataSource={SelectItemEnumTipoDeDepreciacao}
          />
        </Coluna>
        <Coluna md={3}>
          <FormSelectBox
            name="mesDeInicioDaDepreciacao"
            titulo="Mês de início da depreciação"
            control={control}
            dataSource={SelectItemEnumMesDeInicioDaDepreciacao}
            somenteLeitura={mesDeInicioDaDepreciacaoDesabilitado}
            requerido={tipoDepreciacao == TipoDeDepreciacao.Mensal}
          />
        </Coluna>
        <Coluna md={3}>
          <FormNumberBox
            name="percentualDeDepreciacao"
            titulo="Percentual (%)"
            control={control}
            formato={obterFormatStringNumero(2)}
            minimo={ImobilizadoConstantes.PercentualDeDepreciacao.Minimo}
            maximo={ImobilizadoConstantes.PercentualDeDepreciacao.Maximo}
          />
        </Coluna>
      </Linha>
      <Linha>
        <Coluna md={3}>
          <FormNumberBox
            name="vidaUtilEmMeses"
            titulo="Vida útil (em meses)"
            control={control}
            formato={obterFormatStringNumero(0)}
            minimo={ImobilizadoConstantes.ParcelasEVidaUtilEmMeses.Minimo}
            maximo={ImobilizadoConstantes.ParcelasEVidaUtilEmMeses.Maximo}
            requerido
          />
        </Coluna>
        <Coluna md={3}>
          <FormNumberBox
            name="valorParcelaDepreciacao"
            titulo="Valor da parcela de depreciação"
            formato={obterFormatStringNumero(2)}
            control={control}
            requerido
            somenteLeitura
          />
        </Coluna>
        <Coluna md={3}>
          <FormSelectBoxSimples
            titulo="Estado"
            requerido
            valor={obterEstadoDaDepreciacao()}
            dataSource={SelectItemEnumEstadoDaDepreciacao}
            somenteLeitura
          />
        </Coluna>
      </Linha>
      <Linha>
        <Coluna md={6}>
          <ComboContaContabilMxp
            name={"contaDeDebitoDaDepreciacaoId"}
            control={control}
            titulo="Conta contábil de débito"
            onSelectionChanged={editCentroDeCustosDeDebito}
          />
        </Coluna>
        <Coluna md={6}>
          <FormSelectBoxLazyMxp
            name="centroDeCustosDeDebitoDaDepreciacaoId"
            titulo="Centro de custos de débito"
            control={control}
            somenteLeitura={desabilitarCentroDeCustosDeDebito}
            dataSource={centroDeCustosDataSource}
            configuracoesExibicaoEBusca={configuracoesExibicaoSelectCentroCusto}
            labelSemDados="Sem dados"
            seletorConfig={{
              modo: "selecaoUnica",
              titulo: "Selecionar centro de custos",
              componenteGrid: <GridCentroDeCustos />,
            }}
            permissoesNecessarias={[PermissoesContaContabil.Consultar]}
          />
        </Coluna>
      </Linha>
      <Linha>
        <Coluna md={6}>
          <ComboContaContabilMxp
            name={"contaDeCreditoDaDepreciacaoId"}
            control={control}
            titulo="Conta contábil de crédito"
            onSelectionChanged={editCentroDeCustosDeCredito}
          />
        </Coluna>
        <Coluna md={6}>
          <FormSelectBoxLazyMxp
            name="centroDeCustosDeCreditoDaDepreciacaoId"
            titulo="Centro de custos de crédito"
            control={control}
            somenteLeitura={desabilitarCentroDeCustosDeCredito}
            dataSource={centroDeCustosDataSource}
            configuracoesExibicaoEBusca={configuracoesExibicaoSelectCentroCusto}
            labelSemDados="Sem dados"
            seletorConfig={{
              modo: "selecaoUnica",
              titulo: "Selecionar centro de custos",
              componenteGrid: <GridCentroDeCustos />,
            }}
            permissoesNecessarias={[PermissoesContaContabil.Consultar]}
          />
        </Coluna>
      </Linha>
      <Linha>
        <Coluna md={6}>
          <FormNumberBox
            name="quantidadeInicialDeParcelasDepreciadas"
            titulo="Quantidade inicial de parcelas depreciadas"
            control={control}
            formato={obterFormatStringNumero(0)}
            somenteLeitura={existeLancamentosContabeis}
            requerido
            minimo={0}
          />
        </Coluna>
        <Coluna md={6}>
          <FormNumberBox
            name="valorInicialDepreciado"
            titulo="Valor depreciado inicial"
            control={control}
            formato={obterFormatStringNumero(2)}
            somenteLeitura={existeLancamentosContabeis}
            requerido
            minimo={0}
          />
        </Coluna>
      </Linha>
      <Linha>
        <Coluna md={6}>
          <FormNumberBox
            name="quantidadeParcelasDepreciadasPorLancamentoContabil"
            titulo="Qt de parcelas depreciadas por lançamentos contábeis"
            control={control}
            formato={obterFormatStringNumero(0)}
            somenteLeitura
            requerido
            minimo={0}
          />
        </Coluna>
        <Coluna md={6}>
          <FormNumberBox
            name="valorDepreciadoPorLancamentoContabil"
            titulo="Valor depreciado por lançamentos contábeis"
            control={control}
            formato={obterFormatStringNumero(2)}
            somenteLeitura
            requerido
            minimo={0}
          />
        </Coluna>
      </Linha>
      <Sessao titulo="Totalizadores">
        <Linha>
          <Coluna md={4}>
            <FormNumberBoxSimples
              titulo="Vida restante (em meses)"
              formato={obterFormatStringNumero(0)}
              requerido
              somenteLeitura
              minimo={0}
              valor={calcularVidaRestante()}
            />
          </Coluna>
          <Coluna md={4}>
            <FormNumberBoxSimples
              titulo="Valor depreciado"
              formato={obterFormatStringNumero(2)}
              requerido
              somenteLeitura
              minimo={0}
              valor={
                watch("valorDepreciadoPorLancamentoContabil") +
                watch("valorInicialDepreciado")
              }
            />
          </Coluna>
          <Coluna md={4}>
            <FormDateBox
              name="dataUltimaDepreciacao"
              titulo="Data da última depreciação"
              control={control}
              requerido
              somenteLeitura
            />
          </Coluna>
        </Linha>
      </Sessao>
    </>
  );
}
