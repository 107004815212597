import { Button } from "devextreme-react";
import { useCallback } from "react";
import { HomeBackground } from "../../../components/layout/login-bg/styles";
import ApiMrp from "../../../services/producao/mrp/mrp.service";
import { tratarErroApi } from "../../../utils/api/api-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../utils/common/notificacoes-utils";

export const ModalMrp = () => {
  const rodaMrp = useCallback(() => {
    ApiMrp.executarMrp()
      .catch((erro) => {
        tratarErroApi(erro);
      })
      .then((resultado) => {
        if (resultado?.sucesso) {
          exibirNotificacaoToast({
            mensagem: "MRP concluído com sucesso.",
            tipo: TipoNotificacao.Sucesso,
          });
        }
      });
  }, []);

  return (
    <HomeBackground id="HomeBackgroundMrp">
      <Button onClick={rodaMrp}>Rodar Mrp</Button>
    </HomeBackground>
  );
};
