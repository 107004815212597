import { GridComponentSimples } from "../../../../../components/componentes-simples/grid-simples";
import {
  ConfiguracoesErros,
  TipoErro,
} from "../../../../../models/api/comum/erros";
import {
  formataMensagemDeErroComoElementoHtmlDivPadrao,
  formataMensagemDeErroComoElementoHtmlDivPadraoComSugestao,
} from "../../../../../utils/erros/erro.utils";
import { ErroOrdemDeProducao } from "./ordem-de-producao.erros";

export const configuracoesErrosOrdemDeProducao = Object.freeze<
  ConfiguracoesErros<ErroOrdemDeProducao>
>({
  "MSG-012-0001": {
    tipo: TipoErro.Confirmacao,
    titulo: "Atenção",
    formatador({ dadosExtras }) {
      return (
        <div>
          <p>
            Existem insumos baixados com quantidade proporcionalmente
            insuficiente para a quantidade que será concluída.
          </p>
          {dadosExtras && dadosExtras.length > 0 && (
            <GridComponentSimples
              dados={dadosExtras}
              nomesColunas={[
                "Item",
                "Descrição",
                "Quantidade baixada",
                "Quantidade necessária",
              ]}
              colunasParaIgnorar={["itemId"]}
            />
          )}

          <p>Tem certeza de que deseja prosseguir?</p>
        </div>
      );
    },
  },
  "MSG-012-0002": {
    tipo: TipoErro.Confirmacao,
    formatador() {
      return (
        <div>
          <p>
            Não é possível fechar a ordem de produção sem concluir todas as
            baixas de insumos.
          </p>
          <p>
            Deseja considerar o estado dos insumos remanescentes como
            &quot;Baixado&quot;? Esta operação não gera movimentações dos
            insumos restantes.
          </p>
        </div>
      );
    },
  },
  "MSG-012-0003": {
    tipo: TipoErro.Confirmacao,
    formatador() {
      return (
        <div>
          <p>A quantidade a concluir é maior que a prevista.</p>
          <p>Tem certeza de que deseja prosseguir?</p>
        </div>
      );
    },
  },
  "MSG-012-0004": {
    tipo: TipoErro.PopUpAgrupado,
    formatador(erro) {
      return formataMensagemDeErroComoElementoHtmlDivPadrao(erro);
    },
  },
  "MSG-012-0005": {
    tipo: TipoErro.PopUpAgrupado,
    formatador(erro) {
      return formataMensagemDeErroComoElementoHtmlDivPadrao(erro);
    },
  },
  "MSG-012-0006": {
    tipo: TipoErro.PopUpAgrupado,
    formatador(erro) {
      return formataMensagemDeErroComoElementoHtmlDivPadrao(erro);
    },
  },
  "MSG-012-0007": {
    tipo: TipoErro.PopUpAgrupado,
    formatador(erro) {
      return formataMensagemDeErroComoElementoHtmlDivPadrao(erro);
    },
  },
  "MSG-012-0008": {
    tipo: TipoErro.PopUpAgrupado,
    formatador() {
      return (
        <div>
          <p>
            Não é possível cancelar esta ordem de produção, pois ela é
            referenciada por movimentações de estoque.
            <br />
            Para prosseguir com o cancelamento, remova esses relacionamentos
            primeiro.
            <br />
            Acesse a ordem de produção e verifique as conclusões, baixas de
            insumos, retiradas de materiais e aparas.
          </p>
        </div>
      );
    },
  },
  "MSG-012-0009": {
    tipo: TipoErro.PopUpAgrupado,
    formatador() {
      return (
        <div>
          <p>
            Não é possível cancelar esta ordem de produção, pois ela é
            referenciada por apontamentos de operações.
            <br />
            Para prosseguir com o cancelamento, remova esses relacionamentos
            primeiro.
            <br />
            Acesse o menu &quot;Produção &gt; Apontamento de operações&quot;
            para verificar os relacionamentos existentes.
          </p>
        </div>
      );
    },
  },
  "MSG-012-0010": {
    tipo: TipoErro.PopUpAgrupado,
    formatador({ dadosExtras }) {
      return (
        <div>
          <p>
            Não é possível {dadosExtras.acao} esta ordem de produção, pois a
            opção &quot;Quantidade inteira&quot; no cadastro do item &apos;
            {dadosExtras.itemCodigo} ({dadosExtras.itemDescricao})&apos; se
            encontra marcada.
            <br />
            Por isso, a quantidade da ordem de produção deve ser inteira.
            <br />
            Para prosseguir com a {dadosExtras.acaoDaOrdemDeProducao}, entre na
            edição da ordem de produção e altere a quantidade para um valor
            inteiro.
            <br />
            Valores inteiros são aqueles sem casas decimais. Ou, caso esteja
            correto utilizar um valor não inteiro na ordem de produção, altere o
            cadastro do item, desmarcando a opção &quot;Quantidade
            inteira&quot;.
          </p>
        </div>
      );
    },
  },
  "MSG-012-0012": {
    tipo: TipoErro.PopUpAgrupado,
    formatador(erro) {
      return formataMensagemDeErroComoElementoHtmlDivPadrao(erro);
    },
  },
  "MSG-012-0013": {
    tipo: TipoErro.PopUpAgrupado,
    formatador() {
      return (
        <div>
          <p>
            Não foi possível atualizar o roteiro desta ordem de produção porque
            existem movimentações vinculadas às suas operações.
          </p>
          <p>
            <b>Sugestão</b>: Verifique se há baixas de insumos, retiradas de
            aparas ou retirada de materiais associadas a esta ordem de produção.
            Essas movimentações devem ser removidas para permitir a atualização
            das operações da ordem de produção.
          </p>
        </div>
      );
    },
  },
  "MSG-012-0014": {
    tipo: TipoErro.PopUpAgrupado,
    formatador() {
      return (
        <div>
          <p>
            Não foi possível atualizar o roteiro desta ordem de produção porque
            existem operações iniciadas.
          </p>
          <p>
            <b>Sugestão</b>: Na tela de operações de ordens de produção,
            verifique o estado das operações. As operações iniciadas e ou
            concluídas deverão ter seu apontamento removido para realizar a
            atualização do roteiro.
          </p>
        </div>
      );
    },
  },
  "MSG-012-0015": {
    tipo: TipoErro.PopUpAgrupado,
    formatador(erro) {
      return formataMensagemDeErroComoElementoHtmlDivPadrao(erro);
    },
  },
  "MSG-012-0016": {
    tipo: TipoErro.PopUpAgrupado,
    formatador(erro) {
      return formataMensagemDeErroComoElementoHtmlDivPadrao(erro);
    },
  },
  "MSG-012-0017": {
    tipo: TipoErro.PopUpAgrupado,
    formatador({ dadosExtras }) {
      return (
        <div>
          <p>
            O estado do item &quot;{dadosExtras.itemCodigo} (
            {dadosExtras.descricao})&quot; precisa ser &quot;Ativo&quot; para
            poder criar uma retirada de apara.
          </p>
        </div>
      );
    },
  },
  "MSG-012-0018": {
    tipo: TipoErro.PopUpAgrupado,
    formatador(erro) {
      return formataMensagemDeErroComoElementoHtmlDivPadrao(erro);
    },
  },
  "MSG-012-0019": {
    tipo: TipoErro.PopUpAgrupado,
    formatador(erro) {
      return formataMensagemDeErroComoElementoHtmlDivPadrao(erro);
    },
  },
  "MSG-012-0020": {
    tipo: TipoErro.PopUpAgrupado,
    formatador({ dadosExtras }) {
      return (
        <div>
          <p>
            O estado do item &quot;{dadosExtras.itemCodigo} (
            {dadosExtras.descricao})&quot; precisa ser &quot;Ativo&quot; para
            poder criar uma retirada de material.
          </p>
        </div>
      );
    },
  },
  "MSG-012-0101": {
    tipo: TipoErro.PopUpAgrupado,
    formatador(erro) {
      return formataMensagemDeErroComoElementoHtmlDivPadrao(erro);
    },
  },
  "MSG-012-0102": {
    tipo: TipoErro.PopUpAgrupado,
    formatador() {
      return (
        <div>
          <p>
            Não é possível excluir esta ordem de produção, pois ela é
            referenciada por apontamentos de operações.
            <br />
            Para prosseguir com a exclusão, remova esses relacionamentos
            primeiro.
            <br />
            Acesse a tela &quot;Produção &gt; Apontamento de operações&quot; e
            verifique os apontamentos existentes.
          </p>
        </div>
      );
    },
  },
  "MSG-012-0103": {
    tipo: TipoErro.PopUpAgrupado,
    formatador() {
      return (
        <div>
          <p>
            Não é possível excluir esta ordem de produção, pois ela é
            referenciada por movimentações de estoque.
            <br />
            Para prosseguir com a exclusão, remova esses relacionamentos
            primeiro.
            <br />
            Acesse a ordem de produção e verifique as conclusões, baixas de
            insumos, retiradas de materiais e aparas.
          </p>
        </div>
      );
    },
  },
  "MSG-012-0104": {
    tipo: TipoErro.PopUpAgrupado,
    formatador(erro) {
      return formataMensagemDeErroComoElementoHtmlDivPadraoComSugestao(
        erro,
        "Execute o MRP para que as projeções de uso entre das ordens de produção e os pedidos de venda sejam criadas."
      );
    },
  },
  "MSG-012-0026": {
    tipo: TipoErro.Popup,
    formatador({ dadosExtras }) {
      const lotes = dadosExtras as string[];
      return (
        <div>
          <p>A ordem de produção gerou mais de um lote registrado.</p>
          {lotes.map((lote) => (
            <span key={lote}>
              {lote}
              <br></br>
            </span>
          ))}
          <p>
            Utilize a tela de lotes para criar um boletim de inspeção para o
            lote desejado.
          </p>
        </div>
      );
    },
  },
  "MSG-012-0107": {
    tipo: TipoErro.PopUpAgrupado,
    formatador(erro) {
      return formataMensagemDeErroComoElementoHtmlDivPadrao(erro);
    },
  },
});
