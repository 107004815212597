import ArrayStore from "devextreme/data/array_store";
import { useContext } from "react";
import BotaoCancelarMxp from "../../../../../components/botoes/botao-cancelar-mxp";
import BotaoConfirmarMxp from "../../../../../components/botoes/botao-confirmar-mxp";
import FormMxp from "../../../../../components/layout/form";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import ToolbarMxp from "../../../../../components/layout/toolbar-mxp";
import { ContextoSeletor } from "../../../../../components/selecao/contexto-seletor";
import ProvedorSeletor from "../../../../../components/selecao/provedor-seletor";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import exibirNotificacaoToast, {
  JanelasDeNotificacaoTitulos,
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { exibirAlerta } from "../../../../../utils/dialogos";
import {
  ConcluirOrdemDeProducaoRequest,
  RastreabilidadeReserva,
} from "../../../../producao/ordem-de-producao/models/ordem-de-producao.api";
import { OrdemDeProducaoServico } from "../../../../producao/ordem-de-producao/servicos/ordem-de-producao.service";
import { ReservaSelecionarGridModel } from "../../models/selecionar-reservas";
import { GridSelecaoReserva } from "../grid-selecao-reserva";

interface ModalSelecionarReservasProps {
  dados: ArrayStore | undefined;
  dadosConclusao: ConcluirOrdemDeProducaoRequest;
  fecharModal: () => void;
  callBackUnprocessableEntity: () => void;
}

export function ModalSelecionarReservas(props: ModalSelecionarReservasProps) {
  return (
    <ProvedorSeletor>
      <ModalSelecionarReservasInterna {...props} />
    </ProvedorSeletor>
  );
}

function ModalSelecionarReservasInterna(props: ModalSelecionarReservasProps) {
  const { refGrid } = useContext(ContextoSeletor);

  const fecharModal = () => {
    refGrid?.instance().cancelEditData();
    props.fecharModal();
  };

  const obterDadosVisiveisDaGrid = () => {
    if (!refGrid) {
      return [];
    }

    return refGrid
      .instance()
      .getVisibleRows()
      .map((row) => row.data as ReservaSelecionarGridModel);
  };

  async function concluirOrdemDeProducao() {
    if (!refGrid) {
      return;
    }

    try {
      const dadosParaConclusao = props.dadosConclusao;
      if (!dadosParaConclusao.rastreabilidadeReserva) {
        dadosParaConclusao.rastreabilidadeReserva = [];
      }

      const reservas = obterDadosVisiveisDaGrid();
      const quantidadeSelecionada = reservas.reduce(
        (sum, current) => sum + current.quantidadeConcluir,
        0
      );

      let mensagem = "";
      if (quantidadeSelecionada > dadosParaConclusao.quantidade) {
        mensagem = `A soma das quantidades escolhidas (${quantidadeSelecionada}) é maior do que a quantidade informada na tela de 
        conclusão da ordem de produção (${dadosParaConclusao.quantidade}). 
        Ajuste as quantidades das reservas escolhidas ou retorne à tela anterior e escolha uma quantidade maior para ser movimentada.`;
      }

      if (quantidadeSelecionada < dadosParaConclusao.quantidade) {
        mensagem = `A soma das quantidades escolhidas (${quantidadeSelecionada}) é menor do que a quantidade informada na tela de 
        conclusão da ordem de produção (${dadosParaConclusao.quantidade}). 
        Aumente as quantidades escolhidas dos registros ou retorne à tela anterior e escolha uma quantidade menor para ser movimentada.`;
      }

      if (mensagem) {
        await exibirAlerta(JanelasDeNotificacaoTitulos.Atencao, mensagem);
        return;
      }

      reservas.forEach((x) => {
        const dados: RastreabilidadeReserva = {
          reservaId: x.id,
          quantidadeConcluirReserva: x.quantidadeConcluir ?? 0,
        };
        dadosParaConclusao.rastreabilidadeReserva =
          dadosParaConclusao.rastreabilidadeReserva || [];
        dadosParaConclusao.rastreabilidadeReserva.push(dados);
      });

      const resposta = await OrdemDeProducaoServico.Concluir(
        dadosParaConclusao
      );
      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: "Conclusão de ordem de produção realizada com sucesso.",
          tipo: TipoNotificacao.Sucesso,
        });

        props.fecharModal();
      }
    } catch (erro) {
      tratarErroApi(erro, props.callBackUnprocessableEntity);
    }
  }

  return (
    <FormMxp>
      <ModalMxp
        titulo={"Seleção de reservas"}
        visivel={props.dados != undefined}
        handleFechar={fecharModal}
        largura={"auto"}
        altura={"90vh"}
      >
        <FormMxp>
          <span style={{ fontSize: "14px" }}>
            Defina a quantidade a ser concluída de cada reserva
          </span>
          <GridSelecaoReserva
            dataSource={props.dados ?? new ArrayStore({})}
            dadosConclusao={props.dadosConclusao}
            style={{ height: "100%" }}
          />
        </FormMxp>
        <ToolbarMxp>
          <BotaoConfirmarMxp handleClick={concluirOrdemDeProducao} />
          <BotaoCancelarMxp handleClick={fecharModal} />
        </ToolbarMxp>
      </ModalMxp>
    </FormMxp>
  );
}
