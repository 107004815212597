import { DropDownButton } from "devextreme-react";
import { ButtonStyle, ButtonType } from "devextreme/common";
import "./index.scss";

export interface BotaoDropDownProps {
  texto?: string;
  icone?: string;
  tipo?: ButtonType;
  estilo?: ButtonStyle;
  itens: ItemBotaoDropDownProps[];
  opcoes?: any;
  exibeSplitButton?: boolean;
  onButtonClick?: () => void;
}

export interface ItemBotaoDropDownProps {
  texto?: string;
  icone?: string;
  onClick: () => void;
  desabilitado: boolean;
}
export default function BotaoDropDown(props: BotaoDropDownProps) {
  return (
    <DropDownButton
      displayExpr={"text"}
      icon={props.icone}
      text={props.texto}
      items={props.itens?.map(function (item) {
        return {
          icon: item.icone,
          disabled: item.desabilitado,
          text: item.texto,
          onClick: item.onClick,
        };
      })}
      onButtonClick={props.onButtonClick}
      dropDownOptions={props.opcoes}
      type={props.tipo ?? "default"}
      stylingMode={props.estilo ?? "contained"}
      splitButton={props.exibeSplitButton ?? false}
    />
  );
}
