import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import BotaoCancelarMxp from "../../../../../../../components/botoes/botao-cancelar-mxp";
import BotaoSalvarMxp from "../../../../../../../components/botoes/botao-salvar-mxp";
import { FormTextBoxSimples } from "../../../../../../../components/formularios";
import FormMxp from "../../../../../../../components/layout/form";
import { ContainerFormMxp } from "../../../../../../../components/layout/form/styles";
import {
  Coluna,
  Linha,
} from "../../../../../../../components/layout/grid-system";
import ToolbarMxp from "../../../../../../../components/layout/toolbar-mxp";
import AuditavelDTO from "../../../../../../../models/api/comum/auditavel-dto";
import {
  IFormularioEditavelBase,
  ResultadoAcaoFormulario,
} from "../../../../../../../models/shared/ui/formularios";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../../../utils/api/api-utils";
import exibirNotificacaoToast, {
  GeradorMensagensNotificacao,
  TipoNotificacao,
} from "../../../../../../../utils/common/notificacoes-utils";
import yup from "../../../../../../../utils/validacao/validacao";
import ComboEnderecoEstoqueMxp from "../../../../../../estoque/endereco-de-estoque/componentes/parts/combo-endereco-estoque";
import {
  VinculoEnderecoEstoqueCentroDeTrabalhoRequestDTO,
  VinculoEnderecoEstoqueCentroDeTrabalhoResponseDTO,
} from "../../../../../vinculo-endereco-estoque-centro-de-trabalho/models/vinculo-endereco-estoque-centro-de-trabalho.api";
import { VinculoEnderecoEstoqueCentroDeTrabalhoServico } from "../../../../../vinculo-endereco-estoque-centro-de-trabalho/servicos/vinculo-endereco-estoque-centro-de-trabalho.service";

interface FormAdicionarVinculoEnderecoDeEstoqueCentroDeTrabalhoProps
  extends IFormularioEditavelBase {
  idCentroDeTrabalho: number;
  codigoCentroDeTrabalhoVinculado: string;
}

let dadosAuditoria: AuditavelDTO | undefined = undefined;

export default function FormAdicionarVinculoEnderecoDeEstoqueCentroDeTrabalho(
  props: FormAdicionarVinculoEnderecoDeEstoqueCentroDeTrabalhoProps
) {
  const novoRegistro: VinculoEnderecoEstoqueCentroDeTrabalhoRequestDTO =
    useMemo(() => {
      return {
        id: 0,
        idCentroDeTrabalho: props.idCentroDeTrabalho,
        idEnderecoEstoque: null,
      };
    }, [props.idCentroDeTrabalho]);

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    idCentroDeTrabalho: yup.number().required().moreThan(0).integer(),
    idEnderecoEstoque: yup.number().required().moreThan(0).integer(),
  });

  const { control, handleSubmit, getValues, reset, register } =
    useForm<VinculoEnderecoEstoqueCentroDeTrabalhoRequestDTO>({
      resolver: yupResolver(schema),
    });

  const limparTela = useCallback(() => {
    dadosAuditoria = undefined;
    reset(novoRegistro);
  }, [novoRegistro, reset]);

  const carregarRegistroDoId = useCallback(async () => {
    try {
      const resposta =
        await VinculoEnderecoEstoqueCentroDeTrabalhoServico.ObterPorIdComDadosAuditoria<VinculoEnderecoEstoqueCentroDeTrabalhoResponseDTO>(
          props.idRegistroEmEdicao
        );
      checarResponse(resposta);
      dadosAuditoria = resposta.model;
      reset(resposta.model);
    } catch (erro) {
      tratarErroApi(erro);
    }
  }, [props.idRegistroEmEdicao, reset]);

  const preencherTela = useCallback(async () => {
    if (props.idRegistroEmEdicao == 0) {
      limparTela();
    } else if (props.idRegistroEmEdicao > 0) {
      await carregarRegistroDoId();
    }
  }, [carregarRegistroDoId, limparTela, props.idRegistroEmEdicao]);

  //Hook usado para carregar os dados da tela
  useEffect(() => {
    if (Number.isNaN(props.idRegistroEmEdicao)) {
      return;
    }

    preencherTela();
  }, [preencherTela, props.idRegistroEmEdicao]);

  async function handleSalvar() {
    const model = getValues();

    try {
      const resposta =
        props.idRegistroEmEdicao > 0
          ? await VinculoEnderecoEstoqueCentroDeTrabalhoServico.Atualizar(model)
          : await VinculoEnderecoEstoqueCentroDeTrabalhoServico.Inserir(model);

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: GeradorMensagensNotificacao.SalvoComSucessoMasculino(
            "Vínculo com endereço de estoque"
          ),
          tipo: TipoNotificacao.Sucesso,
        });
        fechar(ResultadoAcaoFormulario.AcaoConcluida);
      }
      fechar(ResultadoAcaoFormulario.AcaoCancelada);
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    }
  }

  function callBackUnprocessableEntity() {
    fechar(ResultadoAcaoFormulario.AcaoConcluida);
  }

  function handleCancelar() {
    fechar(ResultadoAcaoFormulario.AcaoCancelada);
  }

  function fechar(resultado: ResultadoAcaoFormulario) {
    limparTela();
    props.handleCallback(resultado);
  }

  return (
    <>
      <ContainerFormMxp>
        <FormMxp>
          <input type="hidden" {...register("id")} defaultValue={0} />
          <Linha>
            <Coluna md={12}>
              <FormTextBoxSimples
                titulo="Centro de trabalho"
                valor={props.codigoCentroDeTrabalhoVinculado}
                toolTip="Campo referente ao centro de trabalho"
                tipo="text"
                somenteLeitura={true}
              />
            </Coluna>
          </Linha>
          <Linha>
            <Coluna md={12}>
              <ComboEnderecoEstoqueMxp
                name="idEnderecoEstoque"
                titulo="Endereço de estoque"
                control={control}
                requerido
                tituloSeletor="Selecionar o endereco de estoque do centro de trabalho"
              />
            </Coluna>
          </Linha>
        </FormMxp>
        <ToolbarMxp dadosAuditoria={dadosAuditoria}>
          <BotaoSalvarMxp handleClick={handleSubmit(handleSalvar)} />
          <BotaoCancelarMxp handleClick={handleCancelar} />
        </ToolbarMxp>
      </ContainerFormMxp>
    </>
  );
}
