import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import BotaoCancelarMxp from "../../../../../components/botoes/botao-cancelar-mxp";
import BotaoSalvarMxp from "../../../../../components/botoes/botao-salvar-mxp";
import {
  FormGrupo,
  FormSelectBox,
  FormTextBox,
} from "../../../../../components/formularios";
import FormMxp from "../../../../../components/layout/form";
import { ContainerFormMxp } from "../../../../../components/layout/form/styles";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import ToolbarMxp from "../../../../../components/layout/toolbar-mxp";
import AuditavelDTO from "../../../../../models/api/comum/auditavel-dto";
import { PermissoesVeiculo } from "../../../../../models/permissoes/vendas/veiculo/permissoes-veiculo";
import {
  IFormularioEditavelBase,
  ResultadoAcaoFormulario,
} from "../../../../../models/shared/ui/formularios";
import SelectItem from "../../../../../models/shared/ui/select-item";
import APIVeiculo from "../../../../../services/veiculo/veiculo.service";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import yup from "../../../../../utils/validacao/validacao";
import { TipoUnidadeTransporte } from "../../../veiculo/models/veiculo.enums";
import {
  ConjuntoVeiculoRequestDTO,
  ConjuntoVeiculoResponseDTO,
} from "../../models/conjunto-veiculo";
import ConjuntoVeiculoConstantes from "../../models/conjunto-veiculo.constantes";
import { ConjuntoVeiculoServico } from "../../servicos/conjunto-veiculo.servico";

const novoRegistro: ConjuntoVeiculoRequestDTO = {
  id: 0,
  descricao: "",
  idVeiculoTracao: 0,
  idVeiculoReboque1: 0,
  idVeiculoReboque2: null,
  idVeiculoReboque3: null,
};

let dadosAuditoria: AuditavelDTO | undefined = undefined;

export default function FormConjuntoVeiculo(props: IFormularioEditavelBase) {
  const [veiculosTracao, setVeiculoTracao] = useState<SelectItem[]>([]);
  const [veiculosReboque, setVeiculoReboque] = useState<SelectItem[]>([]);

  const schema = yup.object().shape({
    id: yup
      .number()
      .required()
      .moreThan(ConjuntoVeiculoConstantes.ValorMinimoMaiorQue)
      .integer(),
    descricao: yup
      .string()
      .required()
      .max(ConjuntoVeiculoConstantes.DescricaoTamanhoMaximo),
    idVeiculoTracao: yup
      .number()
      .required()
      .positive()
      .integer()
      .transform((v) => (v ? v : null))
      .oneOf(
        veiculosTracao.map((x) => x.valor as number),
        "Campo inválido"
      ),
    idVeiculoReboque1: yup
      .number()
      .required()
      .positive()
      .integer()
      .transform((v) => (v ? v : null))
      .oneOf(
        veiculosReboque.map((x) => x.valor as number),
        "Campo inválido"
      )
      .test(
        "reboque1IgualAo2Tests",
        "Campo igual ao reboque 2",
        function (value) {
          return this.parent.idVeiculoReboque2 != value;
        }
      )
      .test(
        "reboque1IgualAo3Tests",
        "Campo igual ao reboque 3",
        function (value) {
          return this.parent.idVeiculoReboque3 != value;
        }
      ),
    idVeiculoReboque2: yup
      .number()
      .positive()
      .integer()
      .transform((v) => (v ? v : null))
      .nullable()
      .oneOf(
        veiculosReboque.map((x) => x.valor as number),
        "Campo inválido"
      )
      .test(
        "reboque2IgualAo1Tests",
        "Campo igual ao reboque 1",
        function (value) {
          if (!value) {
            return true;
          }
          return this.parent.idVeiculoReboque1 != value;
        }
      )
      .test(
        "reboque2IgualAo3Tests",
        "Campo igual ao reboque 3",
        function (value) {
          if (!value) {
            return true;
          }
          return this.parent.idVeiculoReboque3 != value;
        }
      ),
    idVeiculoReboque3: yup
      .number()
      .positive()
      .integer()
      .transform((v) => (v ? v : null))
      .nullable()
      .oneOf(
        veiculosReboque.map((x) => x.valor as number),
        "Campo inválido"
      )
      .test(
        "reboque3IgualAo1Tests",
        "Campo igual ao reboque 1",
        function (value) {
          if (!value) {
            return true;
          }

          return this.parent.idVeiculoReboque1 != value;
        }
      )
      .test(
        "reboque3IgualAo2Tests",
        "Campo igual ao reboque 2",
        function (value) {
          if (!value) {
            return true;
          }
          return this.parent.idVeiculoReboque2 != value;
        }
      ),
  });

  const { register, control, handleSubmit, getValues, reset } =
    useForm<ConjuntoVeiculoRequestDTO>({
      resolver: yupResolver(schema),
    });

  //Hook usado para carregar os dados da tela
  useEffect(() => {
    if (Number.isNaN(props.idRegistroEmEdicao)) {
      return;
    }

    preencherTela();
  }, [props.idRegistroEmEdicao]);

  async function preencherTela() {
    carregarCombos();

    if (props.idRegistroEmEdicao == 0) {
      limparTela();
    } else if (props.idRegistroEmEdicao > 0) {
      await carregarRegistroDoId();
    }
  }

  async function carregarRegistroDoId() {
    try {
      const resposta =
        await ConjuntoVeiculoServico.ObterPorIdComDadosAuditoria<ConjuntoVeiculoResponseDTO>(
          props.idRegistroEmEdicao
        );
      checarResponse(resposta);
      dadosAuditoria = resposta.model;
      reset(resposta.model);
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  async function carregarCombos() {
    await carregarVeiculosTracao();
    await carregarVeiculosReboque();
  }

  async function carregarVeiculosTracao() {
    try {
      const resposta = await APIVeiculo.obterListaSimples([
        TipoUnidadeTransporte.RodoviarioTracao,
      ]);
      checarResponse(resposta);
      setVeiculoTracao(
        resposta.model.map((x) => ({
          valor: x.valor,
          descricao: x.descricao,
        }))
      );
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  async function carregarVeiculosReboque() {
    try {
      const resposta = await APIVeiculo.obterListaSimples([
        TipoUnidadeTransporte.RodoviarioReboque,
        TipoUnidadeTransporte.Outros,
      ]);
      checarResponse(resposta);
      setVeiculoReboque(
        resposta.model.map((x) => ({
          valor: x.valor,
          descricao: x.descricao,
        }))
      );
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  function limparTela() {
    dadosAuditoria = undefined;
    reset(novoRegistro);
  }

  async function handleSalvar() {
    const model = getValues();
    try {
      const resposta =
        props.idRegistroEmEdicao > 0
          ? await ConjuntoVeiculoServico.Atualizar(model)
          : await ConjuntoVeiculoServico.Inserir(model);

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar(ResultadoAcaoFormulario.AcaoConcluida);
      }
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  function handleCancelar() {
    fechar(ResultadoAcaoFormulario.AcaoCancelada);
  }

  function fechar(resultado: ResultadoAcaoFormulario) {
    limparTela();
    props.handleCallback(resultado);
  }

  return (
    <>
      <ContainerFormMxp>
        <FormMxp>
          <ProvedorAjuda id="edit-form-conjunto-veiculo">
            <input type="hidden" {...register("id")} defaultValue={0} />
            <FormGrupo>
              <Linha>
                <Coluna md={12}>
                  <FormTextBox
                    name="descricao"
                    titulo="Descrição"
                    control={control}
                    requerido
                    tamanhoMaximo={
                      ConjuntoVeiculoConstantes.DescricaoTamanhoMaximo
                    }
                  />
                </Coluna>
              </Linha>
              <Linha>
                <Coluna md={12}>
                  <FormSelectBox
                    name="idVeiculoTracao"
                    titulo="Veículo tração"
                    control={control}
                    requerido
                    dataSource={veiculosTracao}
                    habilitaBusca
                    tipoBusca="contains"
                    permissoesNecessarias={[PermissoesVeiculo.Consultar]}
                  />
                </Coluna>
              </Linha>
              <Linha>
                <Coluna md={12}>
                  <FormSelectBox
                    name="idVeiculoReboque1"
                    titulo="Veículo reboque 1"
                    control={control}
                    requerido
                    dataSource={veiculosReboque}
                    habilitaBusca
                    tipoBusca="contains"
                    permissoesNecessarias={[PermissoesVeiculo.Consultar]}
                  />
                </Coluna>
              </Linha>
              <Linha>
                <Coluna md={12}>
                  <FormSelectBox
                    name="idVeiculoReboque2"
                    titulo="Veículo reboque 2"
                    control={control}
                    dataSource={veiculosReboque}
                    habilitaBusca
                    tipoBusca="contains"
                    permissoesNecessarias={[PermissoesVeiculo.Consultar]}
                  />
                </Coluna>
              </Linha>
              <Linha>
                <Coluna md={12}>
                  <FormSelectBox
                    name="idVeiculoReboque3"
                    titulo="Veículo reboque 3"
                    control={control}
                    dataSource={veiculosReboque}
                    habilitaBusca
                    tipoBusca="contains"
                    permissoesNecessarias={[PermissoesVeiculo.Consultar]}
                  />
                </Coluna>
              </Linha>
            </FormGrupo>
          </ProvedorAjuda>
        </FormMxp>
        <ToolbarMxp dadosAuditoria={dadosAuditoria}>
          <BotaoSalvarMxp handleClick={handleSubmit(handleSalvar)} />
          <BotaoCancelarMxp handleClick={handleCancelar} />
        </ToolbarMxp>
      </ContainerFormMxp>
    </>
  );
}
