import { Popup } from "devextreme-react";
import { AssinaturaServico } from "../../../features/seguranca/assinatura/servicos/assinatura.service";
import { PermissoesServico } from "../../../features/seguranca/permissoes/servicos/permissoes.service";
import useEffectOnLoad from "../../../hooks/effect.hooks";
import { useAppDispatch, useAppSelector } from "../../../hooks/store.hooks";
import {
  definirDetalhesOpcionaisAssinatura,
  definirDetalhesPermissoes,
  ocultarModalRequerPermissao,
} from "../../../store/ui/ui.slice";
import { JanelasDeNotificacaoTitulos } from "../../../utils/common/notificacoes-utils";
import PartialPermissoesFaltando from "./parts/listagem-permissoes-faltantes";

export default function ModalRequerPermissao() {
  const dispatch = useAppDispatch();
  const visible = useAppSelector(
    (state) => state.estadoUI.modalRequerPermissao.visivel
  );
  const permissoes = useAppSelector(
    (state) => state.estadoUI.modalRequerPermissao.permissoes
  );

  function onOk() {
    dispatch(ocultarModalRequerPermissao());
  }

  useEffectOnLoad(() => {
    PermissoesServico.obterDetalhesPermissoes().then((permissoes) => {
      dispatch(definirDetalhesPermissoes(permissoes));
    });

    AssinaturaServico.obterDetalhesOpcionais().then((opcionais) => {
      dispatch(definirDetalhesOpcionaisAssinatura(opcionais));
    });
  });

  return (
    <Popup
      visible={visible}
      hideOnOutsideClick={false}
      showCloseButton={true}
      onHiding={onOk}
      showTitle={true}
      dragEnabled={true}
      resizeEnabled={false}
      width="400px"
      height="auto"
      toolbarItems={[
        {
          text: JanelasDeNotificacaoTitulos.Atencao,
          location: "center",
          toolbar: "top",
        },
        {
          widget: "dxButton",
          location: "center",
          toolbar: "bottom",
          options: {
            text: "OK",
            onClick: onOk,
          },
        },
      ]}
    >
      <PartialPermissoesFaltando permissoesFormatadas={permissoes} />
    </Popup>
  );
}
