import {
  ConfiguracoesErros,
  TipoErro,
} from "../../../../../models/api/comum/erros";
import { formataMensagemDeErroComoElementoHtmlDivPadrao } from "../../../../../utils/erros/erro.utils";
import { ErroRazaoPorIntervaloDeContas } from "./razao-por-intervalo-de-contas.erros";

export const configuracoesErrosRazaoPorIntervaloDeContas = Object.freeze<
  ConfiguracoesErros<ErroRazaoPorIntervaloDeContas>
>({
  "MSG-020-0001": {
    tipo: TipoErro.PopUpAgrupado,
    formatador(erro) {
      return formataMensagemDeErroComoElementoHtmlDivPadrao(erro);
    },
  },
  "MSG-020-0002": {
    tipo: TipoErro.PopUpAgrupado,
    formatador(erro) {
      return formataMensagemDeErroComoElementoHtmlDivPadrao(erro);
    },
  },
});
