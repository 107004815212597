import { DataGridRef } from "devextreme-react/data-grid";
import { SingleMultipleOrNone } from "devextreme/common";
import { RowDblClickEvent } from "devextreme/ui/data_grid";
import { IGridSelecao } from "../../models/shared/ui/formularios";

export function getGridSelecaoProps(
  funcModoSelecao: () => SingleMultipleOrNone,
  funcAcaoDoubleClick: () =>
    | ((dadosLinhaDoubleClick?: any) => void)
    | undefined,
  funcDoubleCLickPadrao?: ((x: any) => void) | undefined
): any {
  const modoSelecao = funcModoSelecao();
  if (modoSelecao != "none") {
    return {
      height: "75vh",
      selection: {
        mode: modoSelecao,
        showCheckBoxesMode: "always",
      },
      onRowDblClick: (data: RowDblClickEvent<any, any>) => {
        const acaoDoubleClick = funcAcaoDoubleClick();
        if (acaoDoubleClick && typeof acaoDoubleClick === "function") {
          acaoDoubleClick(data.data);
        } else {
          if (funcDoubleCLickPadrao) {
            funcDoubleCLickPadrao(data.data);
          }
        }
      },
    };
  } else {
    return {
      showCheckBoxesMode: "none",
      onRowDblClick: (data: RowDblClickEvent<any, any>) => {
        if (funcDoubleCLickPadrao) {
          funcDoubleCLickPadrao(data.data);
        }
      },
    };
  }
}

export function gridSelecaoConstructor(
  gridRef: DataGridRef<any, any>,
  modoSelecao: () => SingleMultipleOrNone,
  setModoSelecao: (x: SingleMultipleOrNone) => void,
  setAcaoDoubleClick: (x: () => void) => void
): IGridSelecao {
  return {
    definirModo: (modo) => {
      let selec: SingleMultipleOrNone = "none";
      switch (modo) {
        case "selecaoUnica":
          selec = "single";
          break;
        case "selecaoMultipla":
          selec = "multiple";
          break;
      }

      if (selec != modoSelecao()) {
        setModoSelecao(selec);
      }
    },
    limparSelecao: () => {
      gridRef.instance().clearSelection();
      gridRef.instance().clearFilter();
      gridRef.instance().pageIndex(0);
    },
    obterSelecao: () => {
      const dados = gridRef.instance().getSelectedRowsData();
      return dados;
    },
    definirCallBackDoubleClick: (callBackDoubleClick) => {
      setAcaoDoubleClick(() => callBackDoubleClick);
    },
    getGridRef: () => gridRef,
    atualizarGrid: () => gridRef.instance().refresh(),
  };
}

export function gridOperacoesConstructor(
  gridRef: DataGridRef<any, any>,
  modoSelecao: () => SingleMultipleOrNone,
  setModoSelecao: (x: SingleMultipleOrNone) => void,
  setAcaoDoubleClick: (x: () => void) => void
): IGridSelecao {
  return {
    definirModo: (modo) => {
      let selec: SingleMultipleOrNone = "none";
      switch (modo) {
        case "selecaoUnica":
          selec = "single";
          break;
        case "selecaoMultipla":
          selec = "multiple";
          break;
      }

      if (selec != modoSelecao()) {
        setModoSelecao(selec);
      }
    },
    limparSelecao: () => {
      gridRef.instance().clearSelection();
      gridRef.instance().clearFilter();
      gridRef.instance().pageIndex(0);
    },
    obterSelecao: () => {
      const dados = gridRef.instance().getSelectedRowsData();
      return dados;
    },
    definirCallBackDoubleClick: (callBackDoubleClick) => {
      setAcaoDoubleClick(() => callBackDoubleClick);
    },
    getGridRef: () => gridRef,
    atualizarGrid: () => gridRef.instance().refresh(),
  };
}
