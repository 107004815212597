import { AccordionModel } from "../../../../../../components/dialogos/modal-accordion/modal-accordion";
import { ResponseModel } from "../../../../../../models/api/comum/response-base";
import { PermissoesInsumosDasOrdensDeProducao } from "../../../../../../models/permissoes/producao/insumo-da-ordem-de-producao/InsumoDaOrdemDeProducaoPermissoes";
import { tratarErroApi } from "../../../../../../utils/api/api-utils";
import { JanelasDeNotificacaoTitulos } from "../../../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../../utils/common/permissoes-utils";
import {
  exibirAlerta,
  exibirConfirmacao,
} from "../../../../../../utils/dialogos";
import { OrdemDeProducaoEstado } from "../../../../ordem-de-producao/utils/enums/ordem-de-producao-enums";
import {
  BaixarInsumosEmMassaRequest,
  BaixarInsumosEmMassaResponse,
  InsumoDaOrdemDeProducaoGridModel,
} from "../../../models/insumo-da-ordem-de-producao";
import { InsumoDaOrdemDeProducaoServico } from "../../../servicos/insumo-da-ordem-de-producao";

async function baixarInsumosSelecionadosHandler(
  registros: InsumoDaOrdemDeProducaoGridModel[],
  ordemDeProducaoEstado: OrdemDeProducaoEstado | undefined,
  handleAtualizarGrid: () => void
) {
  if (
    !verificaComNotificacaoSeUsuarioPossuiPermissoes([
      PermissoesInsumosDasOrdensDeProducao.BaixarEConcluirBaixas,
      PermissoesInsumosDasOrdensDeProducao.BaixarInsumosEmMassa,
      PermissoesInsumosDasOrdensDeProducao.BaixarInsumoDaOrdemDeProducaoNaoTotalmenteReservadaParaTerceiro,
    ])
  ) {
    return;
  }

  if (ordemDeProducaoEstado === OrdemDeProducaoEstado.Sugerida) {
    await exibirAlerta(
      JanelasDeNotificacaoTitulos.Atencao,
      "Não é possível baixar insumos de uma ordem de produção no estado 'Sugerida'. Ajuste o estado e tente novamente"
    );

    return;
  }

  const confirmar = await exibirConfirmacao(
    JanelasDeNotificacaoTitulos.Atencao,
    "Tem certeza que deseja baixar os insumos selecionados?"
  );

  if (!confirmar) {
    return;
  }

  try {
    const response = await InsumoDaOrdemDeProducaoServico.BaixarInsumos(
      registros.map(
        (i): BaixarInsumosEmMassaRequest => ({
          InsumoId: i.id,
          QuantidadeABaixar: i.quantidadeABaixar,
          InsumoItemId: i.itemId,
        })
      )
    );

    handleAtualizarGrid();

    return handleResponse(response);
  } catch (erro) {
    tratarErroApi(erro);
  }
}

function handleResponse(
  response: ResponseModel<BaixarInsumosEmMassaResponse[]>
) {
  if (response.sucesso) {
    const resultadosDaConfirmacao = response.model;
    const insumosBaixadosComSucesso = resultadosDaConfirmacao.filter(
      (x) => x.valido
    );
    const insumosBaixadosSemSucesso = resultadosDaConfirmacao.filter(
      (x) => !x.valido
    );

    return handlerNotificacoes(
      insumosBaixadosComSucesso,
      insumosBaixadosSemSucesso
    );
  }
}

function handlerNotificacoes(
  insumosBaixadosComSucesso: BaixarInsumosEmMassaResponse[],
  insumosBaixadosSemSucesso: BaixarInsumosEmMassaResponse[]
) {
  const mensagem = formatarMensagensAccordion(
    insumosBaixadosComSucesso,
    insumosBaixadosSemSucesso
  );

  return {
    accordionVisivel: true,
    mensagem: mensagem,
  };
}

export function formatarMensagensAccordion(
  entidadesAcaoSucesso: BaixarInsumosEmMassaResponse[],
  entidadesAcaoSemSucesso: BaixarInsumosEmMassaResponse[]
): AccordionModel[] {
  const accordionModel: AccordionModel[] = [];

  if (entidadesAcaoSucesso.length > 0) {
    let mensagem = "";

    if (entidadesAcaoSucesso.length == 1) {
      mensagem = `${entidadesAcaoSucesso.length} insumo foi baixado com sucesso. Clique aqui para mais detalhes.`;
    } else {
      mensagem = `${entidadesAcaoSucesso.length} insumos foram baixados com sucesso. Clique aqui para mais detalhes.`;
    }

    accordionModel.push({
      grupoDeMensagensTitulo: mensagem,
      mensagens: entidadesAcaoSucesso.map((x) => {
        return `Insumo ${x.itemCodigo} (${x.itemDescricao}). Quantidade baixada: ${x.quantidadeABaixar}`;
      }),
    });
  }

  if (entidadesAcaoSemSucesso.length > 0) {
    let mensagem = "";

    if (entidadesAcaoSemSucesso.length == 1) {
      mensagem = `${entidadesAcaoSemSucesso.length} não foi baixado. Clique aqui para mais detalhes.`;
    } else {
      mensagem = `${entidadesAcaoSemSucesso.length} não foram baixados. Clique aqui para mais detalhes.`;
    }

    accordionModel.push({
      grupoDeMensagensTitulo: mensagem,
      mensagens: entidadesAcaoSemSucesso.map((x) => {
        return `${x.itemCodigo} (${x.itemDescricao}): ${x.listaDeErros
          .map((y) => y.mensagem)
          .join(" \n")}`;
      }),
    });
  }

  return accordionModel;
}

export { baixarInsumosSelecionadosHandler };
