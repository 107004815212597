import * as Sentry from "@sentry/react";
import { isAxiosError } from "axios";
import config from "devextreme/core/config";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import SobrescritorDeCss from "./components/estilizacao/sobreescritor-de-css";
import { StatusCodesParaIgnorar } from "./configs/api/sentry/status-codes-ignorar.enum";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import store from "./store";

const configsSentry = {
  dsn: process.env.REACT_APP_SENTRY_DSN as string | undefined,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT as string | undefined,
};

const configDevexpress = {
  licenseKey: process.env.REACT_APP_DEVEXPRESS_LICENSE_KEY,
};

Sentry.init({
  dsn: configsSentry.dsn,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  autoSessionTracking: true,
  enableTracing: true,
  environment: configsSentry.environment,

  beforeSend(event, hint) {
    const error = hint.originalException;

    if (isAxiosError(error)) {
      if (error?.request?.status) {
        if (StatusCodesParaIgnorar.includes(error.request.status)) {
          return null;
        }
      }
    }

    return event;
  },
});

config({
  licenseKey: configDevexpress.licenseKey,
  editorStylingMode: "outlined",
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <SobrescritorDeCss>
        <App />
      </SobrescritorDeCss>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
