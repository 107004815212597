import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../hooks/store.hooks";
import {
  desabilitarToasts,
  habilitarToasts,
} from "../../../../../store/ui/ui.slice";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import { aguardar } from "../../../../../utils/common/common-utils";
import { RemoverSessaoReduxELocalStorage } from "../../../../../utils/oauth/oauth-utils";
import { TokenServico } from "../../servicos/token.servico";

export default function LogoutRedirect() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  let payload = searchParams.get("payload");

  const chaveSessao = useAppSelector(
    (state) => state.sessao.dadosSessao?.chaveSessao
  );
  if (!payload) {
    payload = chaveSessao ?? null;
  }

  useEffect(() => {
    dispatch(desabilitarToasts());
    executarLogout();

    async function executarLogout() {
      try {
        const resposta = payload
          ? await TokenServico.efetuarLogoffPayload(payload)
          : await TokenServico.efetuarLogoff();
        checarResponse(resposta);
        RemoverSessaoReduxELocalStorage();
        navigate("/", { replace: true });
      } catch (erro) {
        dispatch(habilitarToasts());
        tratarErroApi(erro);
      } finally {
        await aguardar(2000);
        dispatch(habilitarToasts());
      }
    }
  }, [dispatch, navigate, payload]);

  return <></>;
}
