import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import ProvedorAjuda from "../../../../../../components/ajuda/provedor-ajuda";
import BotaoCancelarMxp from "../../../../../../components/botoes/botao-cancelar-mxp";
import BotaoSalvarMxp from "../../../../../../components/botoes/botao-salvar-mxp";
import {
  FormDateBox,
  FormGrupo,
  FormTextBoxSimples,
} from "../../../../../../components/formularios";
import FormMxp from "../../../../../../components/layout/form";
import { ContainerFormMxp } from "../../../../../../components/layout/form/styles";
import { Coluna, Linha } from "../../../../../../components/layout/grid-system";
import ToolbarMxp from "../../../../../../components/layout/toolbar-mxp";
import { useAppSelector } from "../../../../../../hooks/store.hooks";
import AuditavelDTO from "../../../../../../models/api/comum/auditavel-dto";
import {
  IFormularioEditavelBase,
  ResultadoAcaoFormulario,
} from "../../../../../../models/shared/ui/formularios";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../../utils/api/api-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../../utils/common/notificacoes-utils";
import { formatarCpfCnpj } from "../../../../../../utils/formatadores/formatador-de-strings";
import yup from "../../../../../../utils/validacao/validacao";
import { ApuracaoDoInformativoDaComposicaoDeCustosDaEcfDTO } from "../../../models/apuracao-informativo-composicao-custos-ecf";
import { ApuracaoDoInformativoDaComposicaoDeCustosDaEcfServico } from "../../../servicos/apuracao-informativo-composicao-custos-ecf";

const novoRegistro: ApuracaoDoInformativoDaComposicaoDeCustosDaEcfDTO = {
  id: 0,
  dataInicial: "",
  dataFinal: "",
};

let dadosAuditoria: AuditavelDTO | undefined = undefined;

export default function EditFormInsercaoApuracaoDoInformativoDaComposicaoDeCustosDaEcf(
  props: IFormularioEditavelBase
) {
  const empresaLogada = useAppSelector((state) => {
    return {
      Apelido: state.sessao.dadosSessao?.empresa.apelido,
      CpfCpj: state.sessao.dadosSessao?.empresa.cnpjCpfCodigo,
    };
  });

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    dataInicial: yup
      .string()
      .required()
      .test(
        "data_inicial_valida",
        "A data inicial está maior do que a final.",
        function (value, contexto) {
          if (value && contexto.parent.dataFinal) {
            const dataInicial = new Date(value);
            const dataFinal = new Date(contexto.parent.dataFinal);
            return dataInicial <= dataFinal;
          }
          return true;
        }
      ),
    dataFinal: yup
      .string()
      .required()
      .test(
        "data_final_valida",
        "A data final está menor do que a inicial.",
        function (valor, contexto) {
          if (valor && contexto.parent.dataInicial) {
            const dataInicial = new Date(contexto.parent.dataInicial);
            const dataFinal = new Date(valor);
            return dataInicial <= dataFinal;
          }
          return true;
        }
      ),
  });

  const { register, control, handleSubmit, getValues, reset } =
    useForm<ApuracaoDoInformativoDaComposicaoDeCustosDaEcfDTO>({
      resolver: yupResolver(schema),
    });

  // Usado para limpar o formulário se for NaN
  useEffect(() => {
    if (Number.isNaN(props.idRegistroEmEdicao)) {
      reset(novoRegistro);
    }
  }, [props.idRegistroEmEdicao]);

  function limparTela() {
    dadosAuditoria = undefined;
    reset(novoRegistro);
  }

  function handleCancelar() {
    fechar(ResultadoAcaoFormulario.AcaoCancelada);
  }

  function fechar(resultado: ResultadoAcaoFormulario) {
    limparTela();
    props.handleCallback(resultado);
  }

  async function handleSalvar() {
    const model = getValues();
    try {
      const resposta =
        await ApuracaoDoInformativoDaComposicaoDeCustosDaEcfServico.Calcular(
          model
        );

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar(ResultadoAcaoFormulario.AcaoConcluida);
      }
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  return (
    <>
      <ContainerFormMxp>
        <FormMxp>
          <ProvedorAjuda id="edit-form-informativo-composicao-custos-lucro-real">
            <input type="hidden" {...register("id")} defaultValue={0} />
            <FormGrupo>
              <Linha>
                <Coluna md={8}>
                  <FormTextBoxSimples
                    titulo={"Minha empresa"}
                    valor={`${empresaLogada.Apelido} - ${formatarCpfCnpj(
                      empresaLogada.CpfCpj!
                    )}`}
                    somenteLeitura
                  />
                </Coluna>
              </Linha>
              <Linha>
                <Coluna md={3}>
                  <FormDateBox
                    name="dataInicial"
                    titulo="Período de"
                    requerido
                    control={control}
                    exibirBotaoLimpar
                    aceitaValorCustomizado={true}
                    aceitaDigitacaoComMascara={true}
                  />
                </Coluna>
                <Coluna md={3}>
                  <FormDateBox
                    name="dataFinal"
                    titulo="a"
                    control={control}
                    requerido
                    exibirBotaoLimpar
                    aceitaValorCustomizado={true}
                    aceitaDigitacaoComMascara={true}
                  />
                </Coluna>
              </Linha>
            </FormGrupo>
          </ProvedorAjuda>
        </FormMxp>
        <ToolbarMxp dadosAuditoria={dadosAuditoria}>
          <BotaoSalvarMxp handleClick={handleSubmit(handleSalvar)} />
          <BotaoCancelarMxp handleClick={handleCancelar} />
        </ToolbarMxp>
      </ContainerFormMxp>
    </>
  );
}
