import { AccordionModel } from "../../../../components/dialogos/modal-accordion/modal-accordion";
import { ErroApi } from "../../../../models/api/comum/erros";
import { capitalizarPrimeiraLetra } from "../../../../utils/strings/string-utils";

export interface IOperacaoEmMassaResponse {
  listaDeErros: ErroApi[];
  valido: boolean;
}

export interface IAcaoEmMassa {
  acaoPlural: string;
  acaoSingular: string;
}

export interface IAcaoComEntidadeEmMassa<T extends IOperacaoEmMassaResponse>
  extends IAcaoEmMassa {
  acaoPlural: string;
  acaoSingular: string;
  entidadeSingular: string;
  entidadePlural: string;
  formatarEntidade: (entidade: T) => string;
  verboSingular: string;
  verboPlural: string;
}

export function formatarMensagensAcaoAccordion<
  T extends IOperacaoEmMassaResponse
>(
  entidadesAcaoSucesso: T[],
  entidadesAcaoSemSucesso: T[],
  acoes: IAcaoComEntidadeEmMassa<T>
): AccordionModel[] {
  const accordionModel: AccordionModel[] = [];

  if (entidadesAcaoSucesso.length > 0) {
    let mensagem = "";

    if (entidadesAcaoSucesso.length == 1) {
      mensagem = `${entidadesAcaoSucesso.length} ${acoes.entidadeSingular} ${acoes.verboSingular} ${acoes.acaoSingular} com sucesso. Clique para ver mais:`;
    } else {
      mensagem = `${entidadesAcaoSucesso.length} ${acoes.entidadePlural} ${acoes.verboPlural} ${acoes.acaoPlural} com sucesso. Clique para ver mais:`;
    }

    accordionModel.push({
      grupoDeMensagensTitulo: mensagem,
      mensagens: entidadesAcaoSucesso.map((x) => {
        return `${capitalizarPrimeiraLetra(
          acoes.entidadeSingular
        )} ${acoes.formatarEntidade(x)}`;
      }),
    });
  }

  if (entidadesAcaoSemSucesso.length > 0) {
    let mensagem = "";

    if (entidadesAcaoSemSucesso.length == 1) {
      mensagem = `${entidadesAcaoSemSucesso.length} ${acoes.entidadeSingular} não ${acoes.verboSingular} ${acoes.acaoSingular}. Clique para ver mais:`;
    } else {
      mensagem = `${entidadesAcaoSemSucesso.length} ${acoes.entidadePlural} não ${acoes.verboPlural} ${acoes.acaoPlural}. Clique para ver mais:`;
    }

    accordionModel.push({
      grupoDeMensagensTitulo: mensagem,
      mensagens: entidadesAcaoSemSucesso.map((x) => {
        return `${capitalizarPrimeiraLetra(
          acoes.entidadeSingular
        )} ${acoes.formatarEntidade(x)} - Motivos do erro: ${x.listaDeErros
          .map((y) => y.mensagem)
          .join(" \n")}`;
      }),
    });
  }

  return accordionModel;
}
