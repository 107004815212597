import { IButtonOptions } from "devextreme-react/button";
import { IDataGridOptions } from "devextreme-react/data-grid";
import { SingleMultipleOrNone } from "devextreme/common";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import { exportDataGrid } from "devextreme/excel_exporter";
import dxDataGrid, {
  CellPreparedEvent,
  ContextMenuPreparingEvent,
  DataGridPredefinedToolbarItem,
  RowPreparedEvent,
  ToolbarItem,
} from "devextreme/ui/data_grid";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import React from "react";
import { StringsComum } from "../../features/comum/strings";
import { PreferenciasGradeServico } from "../../features/sistema/preferencias-grade/servicos/preferencias-grade.servico";
import store from "../../store";
import { tratarErroApi } from "../api/api-utils";
import { geraNomeDeArquivoValido } from "../common/common-utils";
import exibirNotificacaoToast, {
  MensagensParaNotificacao,
  TipoNotificacao,
} from "../common/notificacoes-utils";
import {
  criarTooltipsMenuContexto,
  ItemContextMenuMxp,
  ItemContextMenuNativo,
} from "../context-menu/context-menu-utils";
import { exibirConfirmacao } from "../dialogos";

export default class GridBuilder {
  private _grid: IDataGridOptions;
  private _getRef: () => dxDataGrid | null | undefined;
  private _gridId: string;
  private _gridIdParaGravacaoDasPreferencias: string;
  private static _alturaPadrao: string =
    "calc(100vh - (2.35 * var(--header-menu-height)))";
  private _gridInterna: boolean;
  private _filtroPadraoNoCliente?: any;

  public static criar(
    gridId: string,
    getRef: () => dxDataGrid | null | undefined,
    gridInterna: boolean = false,
    filtroPadraoNoCliente: any = undefined
  ): GridBuilder {
    return new GridBuilder(
      {
        id: gridId,
        className: "mxp-grid-compacto",
        remoteOperations: true,
        allowColumnResizing: true,
        rowAlternationEnabled: true,
        columnResizingMode: "widget",
        allowColumnReordering: true,
        showColumnLines: true,
        width: "100%",
        paging: { enabled: false },
        errorRowEnabled: false,
        columnAutoWidth: false,
        hoverStateEnabled: true,
        repaintChangesOnly: true,
        noDataText: "Nenhum registro encontrado.",
        syncLookupFilterValues: false,
        columnFixing: {
          enabled: true,
        },
        toolbar: {
          visible: true,
        },
        height: this._alturaPadrao,
        onCellPrepared: (e) => {
          if (e.rowType != "header") {
            return;
          }

          if (!e.column.dataField) {
            return;
          }

          e.cellElement.setAttribute("data-nomeColuna", e.column.dataField);

          e.cellElement.addEventListener("mouseover", function () {
            handleMouseOverLeaveHeader(e, "block");
          });

          e.cellElement.addEventListener("mouseleave", function () {
            handleMouseOverLeaveHeader(e, "none");
          });
        },
      },
      getRef,
      gridId,
      gridInterna,
      filtroPadraoNoCliente
    );
  }

  private constructor(
    grid: IDataGridOptions,
    getRef: () => dxDataGrid | null | undefined,
    gridId: string,
    gridInterna: boolean,
    filtroPadraoNoCliente: any
  ) {
    this._grid = grid;
    this._getRef = getRef;
    this._gridId = gridId;
    this._gridIdParaGravacaoDasPreferencias = `grid:${gridId}`;
    this._gridInterna = gridInterna;
    this._filtroPadraoNoCliente = filtroPadraoNoCliente;
  }

  public build(): IDataGridOptions {
    // Ordena os itens da toolbar, para sempre ficar na ordem correta
    this._grid.toolbar?.items?.sort((a: any, b: any) =>
      a.options.ordemNaInterface < b.options.ordemNaInterface ? -1 : 1
    );

    return this._grid;
  }

  public definirStyles(styles?: React.CSSProperties): GridBuilder {
    if (!styles) {
      return new GridBuilder(
        this._grid,
        this._getRef,
        this._gridId,
        this._gridInterna,
        this._filtroPadraoNoCliente
      );
    }
    const grid: IDataGridOptions = {
      ...this._grid,
      style: styles,
      height: styles.height ? styles.height : GridBuilder._alturaPadrao,
    };

    return new GridBuilder(
      grid,
      this._getRef,
      this._gridId,
      this._gridInterna,
      this._filtroPadraoNoCliente
    );
  }

  public definirDataSource(dataSource: DataSource | ArrayStore): GridBuilder {
    const grid: IDataGridOptions = {
      ...this._grid,
      dataSource: dataSource,
    };

    return new GridBuilder(
      grid,
      this._getRef,
      this._gridId,
      this._gridInterna,
      this._filtroPadraoNoCliente
    );
  }

  public definirOperacoesNoLadoDoCliente(): GridBuilder {
    const grid: IDataGridOptions = {
      ...this._grid,
      remoteOperations: false,
    };

    return new GridBuilder(
      grid,
      this._getRef,
      this._gridId,
      this._gridInterna,
      this._filtroPadraoNoCliente
    );
  }

  public definirFiltros(): GridBuilder {
    const grid: IDataGridOptions = {
      ...this._grid,
      filterPanel: {
        texts: {
          clearFilter: "Limpar filtro",
        },
        customizeText: () => {
          return "Ver filtro";
        },
        visible: true,
      },
      headerFilter: {
        visible: true,
      },
      filterRow: {
        visible: true,
      },
      defaultFilterValue: this._filtroPadraoNoCliente,
    };

    return new GridBuilder(
      grid,
      this._getRef,
      this._gridId,
      this._gridInterna,
      this._filtroPadraoNoCliente
    );
  }

  public definirSelecao(modo?: SingleMultipleOrNone): GridBuilder {
    const grid: IDataGridOptions = {
      ...this._grid,
      selection: {
        mode: modo ?? "multiple",
        selectAllMode: "allPages",
        showCheckBoxesMode: "onClick",
      },
    };

    return new GridBuilder(
      grid,
      this._getRef,
      this._gridId,
      this._gridInterna,
      this._filtroPadraoNoCliente
    );
  }

  public definirGravacaoPreferenciasGrid(): GridBuilder {
    const customLoad = async () => {
      let state = undefined;

      const resposta = await PreferenciasGradeServico.ObterPreferencias(
        this._gridIdParaGravacaoDasPreferencias
      );

      if (resposta.sucesso) {
        state = JSON.parse(resposta.model.jsonComPreferencias);
      }

      // Se não achou estado retorna estado nulo.
      if (!state) {
        return null;
      }

      this.limparCamposGravacaoPreferenciasDaGrid(state);

      /**
       * Necessário isso pois se a grid possui filtro padrão
       * ele não estava sendo inicializado, já que sobrescrevia com o
       * estado salvo (nesse estado, não existe informação de filtro).
       */
      if (this._filtroPadraoNoCliente) {
        state.filterValue = this._filtroPadraoNoCliente;
      }

      return state;
    };

    const onStateGridChange = () => {
      this.deixarIconeSalvarPreferenciasAtivo();
    };

    const grid: IDataGridOptions = {
      ...this._grid,
      stateStoring: {
        enabled: true,
        type: "custom",
        savingTimeout: 500,
        customLoad: customLoad,
        customSave: onStateGridChange,
      },
      toolbar: {
        ...this._grid.toolbar,
        items: [
          ...this.obterItensToolbar(),
          {
            location: "after",
            widget: "dxButton",
            visible: true,
            options: {
              ordemNaInterface: 6,
              icon: "ic-material-symbols-outlined ic-save",
              hint: StringsComum.salvarAsAlteracoesDeExibicaoDaTela,
              onClick: async () => {
                const estadoDaGrid = this._getRef()?.state();

                if (
                  this.getClassesIconeSalvarPreferencias()?.contains(
                    "mxp-toolbar-ic-enabled"
                  )
                ) {
                  await this.salvarPreferenciasDaGridInternal(estadoDaGrid);
                }
              },
              elementAttr: {
                id: this.obterIdIconeSalvarPreferenciasGrid(),
                class: "ic-material-disabled",
              },
            },
          },
          {
            location: "after",
            widget: "dxButton",
            visible: true,
            options: {
              ordemNaInterface: 7,
              icon: "ic-material-symbols-outlined ic-restore-page",
              hint: StringsComum.restaurarTelaParaAsDefinicoesPadroes,
              onClick: async () => {
                if (!this._getRef) {
                  return;
                }

                const restaurar = await exibirConfirmacao(
                  "Confirmar restauração",
                  "Tem certeza de que deseja restaurar os ordenamentos, colunas e filtros da tela para as definições padrões do sistema?"
                );

                if (restaurar) {
                  try {
                    const resposta =
                      await PreferenciasGradeServico.RemoverPreferencias(
                        this._gridIdParaGravacaoDasPreferencias
                      );

                    if (resposta.sucesso) {
                      this._getRef()?.state(null);
                    }
                  } catch (erro) {
                    tratarErroApi(erro);
                  }
                }
              },
            },
          },
        ],
      },
    };

    return new GridBuilder(
      grid,
      this._getRef,
      this._gridId,
      this._gridInterna,
      this._filtroPadraoNoCliente
    );
  }

  public configurarSelecionadorDeColunas(): GridBuilder {
    const grid: IDataGridOptions = {
      ...this._grid,
      columnChooser: {
        selection: {
          allowSelectAll: true,
          selectByClick: true,
        },
        height: "80vh",
        enabled: true,
        mode: "select",
        position: {
          my: "right top",
          at: "right bottom",
          of: ".dx-datagrid-column-chooser-button",
        },
        search: {
          enabled: true,
          editorOptions: {
            placeholder: "Buscar coluna",
          },
        },
      },
      toolbar: {
        ...this._grid.toolbar,
        items: [
          ...this.obterItensToolbar(),
          {
            location: "after",
            name: "columnChooserButton",
            visible: true,
            locateInMenu: "auto",
            options: {
              ordemNaInterface: 5,
            },
          },
        ],
      },
    };

    return new GridBuilder(
      grid,
      this._getRef,
      this._gridId,
      this._gridInterna,
      this._filtroPadraoNoCliente
    );
  }

  public configurarAgrupamento(): GridBuilder {
    const grid: IDataGridOptions = {
      ...this._grid,
      groupPanel: {
        visible: true,
        allowColumnDragging: true,
        emptyPanelText: "",
      },
      grouping: {
        contextMenuEnabled: true,
      },
      toolbar: {
        ...this._grid.toolbar,
        items: [
          ...this.obterItensToolbar(),
          {
            location: "after",
            name: "groupPanel",
            visible: true,
            locateInMenu: "auto",
            options: {
              ordemNaInterface: 1,
            },
          },
        ],
      },
    };

    return new GridBuilder(
      grid,
      this._getRef,
      this._gridId,
      this._gridInterna,
      this._filtroPadraoNoCliente
    );
  }

  public configurarExportacao(
    nomeArquivo: string,
    nomePlanilha?: string,
    permitirExportarSelecionados = true
  ): GridBuilder {
    const grid: IDataGridOptions = {
      ...this._grid,
      export: {
        enabled: true,
        allowExportSelectedData: permitirExportarSelecionados,
        texts: {
          exportAll: "Exportar todos os dados para o Excel",
          exportSelectedRows: "Exportar os dados selecionados para o Excel",
        },
      },
      toolbar: {
        ...this._grid.toolbar,
        items: [
          ...this.obterItensToolbar(),
          {
            location: "before",
            locateInMenu: "auto",
            name: "exportButton",
            visible: true,
            options: {
              ordemNaInterface: 4,
            },
          },
        ],
      },
      onExporting: (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet(nomePlanilha ?? "Main sheet");
        nomeArquivo = geraNomeDeArquivoValido(nomeArquivo);

        if (
          (e.component as any).getController("export")._selectionOnly &&
          e.component.getSelectedRowKeys().length === 0
        ) {
          exibirNotificacaoToast({
            mensagem: MensagensParaNotificacao.EhNecessarioSelecionarUmRegistro,
            tipo: TipoNotificacao.Advertencia,
          });
          return;
        }

        exportDataGrid({
          component: e.component,
          worksheet,
          autoFilterEnabled: true,
        }).then(() => {
          workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(
              new Blob([buffer], { type: "application/octet-stream" }),
              `${nomeArquivo}.xlsx`
            );
          });
        });
      },
    };

    return new GridBuilder(
      grid,
      this._getRef,
      this._gridId,
      this._gridInterna,
      this._filtroPadraoNoCliente
    );
  }

  public definirPaginacao(
    quantidadesDisponiveis: number[] = [50, 100, 200],
    tamanhoPaginaPadrao: number = 50
  ): GridBuilder {
    const grid: IDataGridOptions = {
      ...this._grid,
      paging: {
        enabled: true,
        pageSize: tamanhoPaginaPadrao,
      },
      pager: {
        visible: true,
        allowedPageSizes: quantidadesDisponiveis,
        displayMode: "full",
        showPageSizeSelector: true,
        showInfo: true,
        showNavigationButtons: true,
      },
    };

    return new GridBuilder(
      grid,
      this._getRef,
      this._gridId,
      this._gridInterna,
      this._filtroPadraoNoCliente
    );
  }

  public definirBotaoNovo(
    handleNovoRegistro: () => void,
    somenteParaMaster: boolean = false
  ) {
    const { texto, botaoVisivel } =
      this.obterTextoEVisibilidadeBotaoNovo(somenteParaMaster);

    const grid: IDataGridOptions = {
      ...this._grid,
      toolbar: {
        ...this._grid.toolbar,
        items: [
          ...this.obterItensToolbar(),
          {
            location: "before",
            widget: "dxButton",
            visible: botaoVisivel,
            options: {
              type: "success",
              stylingMode: "contained",
              icon: "add",
              text: texto,
              useItemTextAsTitle: false,
              onClick: handleNovoRegistro,
              ordemNaInterface: 1,
            },
          },
        ],
      },
    };

    return new GridBuilder(
      grid,
      this._getRef,
      this._gridId,
      this._gridInterna,
      this._filtroPadraoNoCliente
    );
  }

  public definirDuploCliqueLinha<T>(acao: (data: T) => void) {
    const grid: IDataGridOptions = {
      ...this._grid,
      onRowDblClick: (data) => {
        acao(data.data);
      },
    };

    return new GridBuilder(
      grid,
      this._getRef,
      this._gridId,
      this._gridInterna,
      this._filtroPadraoNoCliente
    );
  }

  public definirBotaoAdicional(configuracoes: IButtonOptions) {
    const grid: IDataGridOptions = {
      ...this._grid,
      toolbar: {
        ...this._grid.toolbar,
        items: [
          ...this.obterItensToolbar(),
          {
            location: "before",
            widget: "dxButton",
            options: {
              ...configuracoes,
              ordemNaInterface: 2,
            },
          },
        ],
      },
    };

    return new GridBuilder(
      grid,
      this._getRef,
      this._gridId,
      this._gridInterna,
      this._filtroPadraoNoCliente
    );
  }

  public definirBotaoNovoComoDropDown(
    itens: IButtonOptions[],
    somenteParaMaster: boolean = false
  ) {
    const { texto, botaoVisivel } =
      this.obterTextoEVisibilidadeBotaoNovo(somenteParaMaster);

    const grid: IDataGridOptions = {
      ...this._grid,
      toolbar: {
        ...this._grid.toolbar,
        items: [
          ...this.obterItensToolbar(),
          {
            location: "before",
            widget: "dxDropDownButton",
            visible: botaoVisivel,
            options: {
              type: "success",
              stylingMode: "contained",
              icon: "add",
              text: texto,
              useItemTextAsTitle: false,
              items: itens,
              ordemNaInterface: 1,
              dropDownOptions: {
                width: () => {
                  // Calcula a largura com base no texto mais longo
                  const maxWidth = itens.reduce((max, item) => {
                    const itemText = item.text || "";
                    return Math.max(max, itemText.length);
                  }, 0);

                  return `${maxWidth * 7}px`;
                },
              },
            },
          },
        ],
      },
    };

    return new GridBuilder(
      grid,
      this._getRef,
      this._gridId,
      this._gridInterna,
      this._filtroPadraoNoCliente
    );
  }

  public definirBotaoRefresh(handleClick: () => void) {
    const grid: IDataGridOptions = {
      ...this._grid,
      toolbar: {
        ...this._grid.toolbar,
        items: [
          ...this.obterItensToolbar(),
          {
            location: "before",
            widget: "dxButton",
            visible: true,
            options: {
              icon: "refresh",
              hint: StringsComum.recarregarDadosGrid,
              onClick: handleClick,
              ordemNaInterface: 3,
            },
          },
        ],
      },
    };

    return new GridBuilder(
      grid,
      this._getRef,
      this._gridId,
      this._gridInterna,
      this._filtroPadraoNoCliente
    );
  }

  public definirMenuDeContexto(itens: ItemContextMenuMxp[]): GridBuilder {
    const referencia = this._getRef();

    const itensAdd = itens
      .filter((x) => x.exibirNaLinhaDaGrid == "menuDeContexto")
      .map(convertItemContextMenu);

    const grid: IDataGridOptions = {
      ...this._grid,
      onContextMenuPreparing: (e: ContextMenuPreparingEvent) => {
        if (e.row && e.row.rowType === "data") {
          referencia?.instance().selectRows([e.row.key], false);
          e.items = itensAdd;
          criarTooltipsMenuContexto(e);
        }
      },
    };

    return new GridBuilder(
      grid,
      this._getRef,
      this._gridId,
      this._gridInterna,
      this._filtroPadraoNoCliente
    );
  }

  public definirRolagem(): GridBuilder {
    const grid: IDataGridOptions = {
      ...this._grid,
      scrolling: {
        useNative: true,
        rowRenderingMode: "standard",
      },
    };

    return new GridBuilder(
      grid,
      this._getRef,
      this._gridId,
      this._gridInterna,
      this._filtroPadraoNoCliente
    );
  }

  public definirOrdenacao(): GridBuilder {
    const grid: IDataGridOptions = {
      ...this._grid,
      sorting: {
        mode: "multiple",
      },
    };

    return new GridBuilder(
      grid,
      this._getRef,
      this._gridId,
      this._gridInterna,
      this._filtroPadraoNoCliente
    );
  }

  public definirFormatadorDeLinhas(
    formatador: (e: RowPreparedEvent<any, any>) => void
  ): GridBuilder {
    const grid: IDataGridOptions = {
      ...this._grid,
      onRowPrepared: formatador,
    };

    return new GridBuilder(
      grid,
      this._getRef,
      this._gridId,
      this._gridInterna,
      this._filtroPadraoNoCliente
    );
  }

  public definirEditavel(): GridBuilder {
    const grid: IDataGridOptions = {
      ...this._grid,
      editing: {
        allowUpdating: true,
        mode: "batch",
        startEditAction: "click",
        selectTextOnEditStart: true,
      },
    };

    return new GridBuilder(
      grid,
      this._getRef,
      this._gridId,
      this._gridInterna,
      this._filtroPadraoNoCliente
    );
  }

  private checarContemFiltroPadrao(
    filtroPassado: string,
    filtroPadrao: string
  ) {
    // O slice retira o primeiro e último colchete da string do JSON do filtro padrão.
    // Em filtros mais complexos os colchetes atrapalham e não são necessários
    // para realizar a comparação do filtro padrão com o filtro passado
    return filtroPassado.includes(filtroPadrao?.slice(1, -1));
  }

  private obterIdIconeSalvarPreferenciasGrid() {
    return `iconeSalvar${this._gridId}}`;
  }

  private getClassesIconeSalvarPreferencias() {
    const iconeSalvar = document.getElementById(
      this.obterIdIconeSalvarPreferenciasGrid()
    );

    return iconeSalvar?.classList;
  }

  private deixarIconeSalvarPreferenciasAtivo() {
    this.getClassesIconeSalvarPreferencias()?.add("mxp-toolbar-ic-enabled");
    this.getClassesIconeSalvarPreferencias()?.remove("ic-material-disabled");
  }

  private deixarIconeSalvarPreferenciasInativo() {
    this.getClassesIconeSalvarPreferencias()?.add("ic-material-disabled");
    this.getClassesIconeSalvarPreferencias()?.remove("mxp-toolbar-ic-enabled");
  }

  private obterItensToolbar() {
    const itensExistentes: Array<DataGridPredefinedToolbarItem | ToolbarItem> =
      this._grid.toolbar?.items || [];

    return itensExistentes;
  }

  private async salvarPreferenciasDaGridInternal(estadoDaGrid: any) {
    if (estadoDaGrid) {
      try {
        this.limparCamposGravacaoPreferenciasDaGrid(estadoDaGrid);

        const jsonEstado = JSON.stringify(estadoDaGrid);

        // Salva o estado da grid no banco de dados
        const resposta = await PreferenciasGradeServico.Salvar({
          nomeGrade: this._gridIdParaGravacaoDasPreferencias,
          jsonComPreferencias: jsonEstado,
        });

        if (resposta.sucesso) {
          this.deixarIconeSalvarPreferenciasInativo();

          exibirNotificacaoToast({
            mensagem: StringsComum.preferenciasSalvas,
            tipo: TipoNotificacao.Sucesso,
          });
        }
      } catch (erro) {
        tratarErroApi(erro);
      }
    } else {
      exibirNotificacaoToast({
        mensagem: StringsComum.gradeNaoPossuiAlteracoes,
        tipo: TipoNotificacao.Informacao,
      });
    }
  }

  private limparCamposGravacaoPreferenciasDaGrid(estadoDaGrid: any) {
    delete estadoDaGrid.selectedRowKeys;
    delete estadoDaGrid.filterValue;
    delete estadoDaGrid.searchText;
    delete estadoDaGrid.allowedPageSizes;

    for (const coluna of estadoDaGrid.columns) {
      delete coluna.filterType;
      delete coluna.filterValue;
    }
  }

  private obterTextoEVisibilidadeBotaoNovo(somenteMaster: boolean) {
    let texto = this._gridInterna ? StringsComum.adicionar : StringsComum.novo;
    texto += somenteMaster ? StringsComum.posFixoMaster : "";

    let botaoVisivel = true;

    if (somenteMaster) {
      botaoVisivel =
        store.getState().sessao.dadosSessao?.usuario.isUsuarioMaster ?? false;
    }

    return { texto, botaoVisivel };
  }
}

// Deu problema declarado dentro da classe
export function convertItemContextMenu(x: ItemContextMenuMxp) {
  const item: ItemContextMenuNativo = {
    ...x,
    onClick: x.gestorEventoClick?.eventoParaMenuContextoNativo(),
    items: x?.items?.map(convertItemContextMenu),
  };

  return item;
}

function handleMouseOverLeaveHeader(
  e: CellPreparedEvent<any, any>,
  display: "block" | "none"
) {
  const containerIcone = e.cellElement.querySelector(".container-icone");

  if (!containerIcone) {
    return;
  }

  const icone = containerIcone.querySelector(".icone-tooltip");

  if (!icone) {
    return;
  }

  (containerIcone as HTMLDivElement).style.display = display;
}
