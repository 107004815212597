import DataGrid, { Column, DataGridRef } from "devextreme-react/data-grid";
import { useCallback, useEffect, useMemo, useRef } from "react";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import GridColunaAcoes from "../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import { useRegistrarAtalhosGrid } from "../../../../../hooks/atalhos.hooks";
import { useControlarFormDeEdicao } from "../../../../../hooks/form.hooks";
import { useMenuDeContextosGrid } from "../../../../../hooks/menus.hooks";
import { useParametroId } from "../../../../../hooks/route.hooks";
import { PermissoesApuracaoLacsCsll } from "../../../../../models/permissoes/fiscal/apuracao-lacs-csll/permissoes-apuracao-lacs-csll";
import { ResultadoAcaoFormulario } from "../../../../../models/shared/ui/formularios";
import GetColunasDeAuditoria from "../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import {
  checarResponseExibeMensagemExclusaoDeSucesso,
  checarResponseExibeMensagemExecutadoComSucesso,
} from "../../../../../utils/api/api-utils";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesModais from "../../../../../utils/common/nomes-modais";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import NormalizaTituloModal from "../../../../../utils/common/normaliza-titulo";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import { ItemContextMenuMxp } from "../../../../../utils/context-menu/context-menu-utils";
import { GestorEventoClickUnicaLinha } from "../../../../../utils/context-menu/gestor-evento-click";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import { formatarDataAno4digitos } from "../../../../../utils/formatadores/formatador-de-datas";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import UrlUtils from "../../../../../utils/url/url-utils";
import { ApuracaoLacsCsllGridModel } from "../../models/apuracao-lacs-csll";
import { ApuracaoLacsCsllServico } from "../../servicos/apuracao-lacs-csll.service";
import EditFormAlteracaoApuracaoLacsCsll from "../formulario/form-edicao";
import EditFormInsercaoApuracaoLacsCsll from "../formulario/form-insercao";

const dataSource =
  ApuracaoLacsCsllServico.ObterDataSourceParaGrid<ApuracaoLacsCsllGridModel>();
const nameOfGridHandler = criarNameof<ApuracaoLacsCsllGridModel>();

export default function GridApuracaoLacsCsll() {
  const gridRef = useRef<DataGridRef>(null);
  const parametroId = useParametroId();

  const {
    idRegistroEmEdicao,
    setIdRegistroEmEdicao,
    modalVisivel,
    encerrarEdicao,
  } = useControlarFormDeEdicao(NaN);

  const handleNovoRegistro = useCallback(() => {
    if (
      !verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesApuracaoLacsCsll.InserirEditar,
      ])
    ) {
      return;
    }
    setIdRegistroEmEdicao(0);
  }, []);

  const handleEditarRegistro = useCallback(
    (registro: ApuracaoLacsCsllGridModel) => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesApuracaoLacsCsll.InserirEditar,
        ])
      ) {
        return;
      }
      setIdRegistroEmEdicao(registro.id);
    },
    []
  );

  const handleExcluirRegistro = useCallback(
    async (registro: ApuracaoLacsCsllGridModel) => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesApuracaoLacsCsll.Excluir,
        ])
      ) {
        return;
      }
      const excluir = await exibirConfirmacao(
        "Confirmar exclusão",
        obterMensagemExclusao(registro)
      );

      if (excluir) {
        const resposta = await ApuracaoLacsCsllServico.Excluir(registro.id);

        if (resposta) {
          checarResponseExibeMensagemExclusaoDeSucesso(resposta);
          handleAtualizarGrid();
        }
      }
    },
    []
  );

  function obterMensagemExclusao(registro: ApuracaoLacsCsllGridModel) {
    return `Tem certeza que deseja excluir a apuração de ${formatarDataAno4digitos(
      registro.dataInicial
    )} a ${formatarDataAno4digitos(registro.dataFinal)}?`;
  }

  async function handleRecalcular(registro: ApuracaoLacsCsllGridModel) {
    if (
      !verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesApuracaoLacsCsll.InserirEditar,
      ])
    ) {
      return;
    }

    const recalcular = await exibirConfirmacao(
      "Confirmar recálculo",
      "Tem certeza de que deseja recalcular a apuração? As informações serão apagadas e recalculadas."
    );

    if (recalcular) {
      const resultado = await ApuracaoLacsCsllServico.Recalcular(registro.id);
      checarResponseExibeMensagemExecutadoComSucesso(resultado);
      if (resultado.sucesso) {
        handleAtualizarGrid();
      }
    }
  }

  const gridController = useMemo(
    () =>
      new GridController<ApuracaoLacsCsllGridModel>(() =>
        gridRef.current?.instance()
      ),
    []
  );

  const acoesDeContexto: ItemContextMenuMxp[] = useMemo(() => {
    return [
      {
        text: "Ações",
        icon: "ic-material-symbols-outlined ic-vertical",
        exibirNaLinhaDaGrid: "menuDeContexto",
        exibirNoMenuPrincipal: true,
        items: [
          {
            text: "Recalcular apuração",
            gestorEventoClick: new GestorEventoClickUnicaLinha(
              handleRecalcular,
              () => gridController
            ),
          },
        ],
      },
    ];
  }, [gridController]);

  const menuContexto = useMenuDeContextosGrid(
    acoesDeContexto,
    true,
    gridController
  );

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar("apuracao-lacs-csll", () =>
      gridRef.current?.instance()
    )
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(NomesTelas.apuracaoLacsCsll)
      .definirBotaoNovo(handleNovoRegistro)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirSelecao()
      .definirOrdenacao()
      .definirDuploCliqueLinha(handleEditarRegistro)
      .definirMenuDeContexto(menuContexto)
      .build();
  }, [menuContexto]);

  useEffect(() => {
    if (parametroId || parametroId === 0) {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesApuracaoLacsCsll.InserirEditar,
        ])
      ) {
        return;
      }

      setIdRegistroEmEdicao(parametroId);
    }
  }, []);

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  useRegistrarAtalhosGrid<ApuracaoLacsCsllGridModel>({
    controller: gridController,
    handleNovo: handleNovoRegistro,
    handleEditar: handleEditarRegistro,
    handleExcluir: handleExcluirRegistro,
  });

  const handleCallbackFormulario = useCallback(
    (resultado: ResultadoAcaoFormulario) => {
      encerrarEdicao();

      if (resultado == ResultadoAcaoFormulario.AcaoConcluida) {
        handleAtualizarGrid();
      }

      UrlUtils.RemoverParametroId(parametroId);
    },
    [parametroId]
  );

  const handleFecharModal = useCallback(() => {
    encerrarEdicao();
    UrlUtils.RemoverParametroId(parametroId);
  }, [parametroId]);

  return (
    <>
      <ProvedorAjuda id={"tooltips-grid-apuracao-lacs-csll"}>
        <DataGrid ref={gridRef} {...configuracoesGrid}>
          {GridColunaAcoes<ApuracaoLacsCsllGridModel>({
            handleEditar: handleEditarRegistro,
            handleExcluir: handleExcluirRegistro,
          })}
          <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
          <Column
            key={nameOfGridHandler("dataInicial")}
            dataField={nameOfGridHandler("dataInicial")}
            {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
            caption="Data inicial"
            sortIndex={0}
            sortOrder="desc"
            width={140}
          />
          <Column
            key={nameOfGridHandler("dataFinal")}
            dataField={nameOfGridHandler("dataFinal")}
            {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
            caption="Data final"
            sortIndex={1}
            sortOrder="desc"
            width={140}
          />
          <Column
            key={nameOfGridHandler("lucroOuPrejuizo")}
            dataField={nameOfGridHandler("lucroOuPrejuizo")}
            {...obterConfiguracaoColuna("monetario")}
            caption="Lucro do período antes do CSLL"
            width={230}
          />
          <Column
            key={nameOfGridHandler("adicoes")}
            dataField={nameOfGridHandler("adicoes")}
            {...obterConfiguracaoColuna("monetario")}
            caption="Adições (+)"
            width={120}
          />
          <Column
            key={nameOfGridHandler("exclusoes")}
            dataField={nameOfGridHandler("exclusoes")}
            {...obterConfiguracaoColuna("monetario")}
            caption="Exclusões (-)"
            width={120}
          />
          <Column
            key={nameOfGridHandler("baseCalculoAntesCompensacao")}
            dataField={nameOfGridHandler("baseCalculoAntesCompensacao")}
            {...obterConfiguracaoColuna("monetario")}
            caption="Base de cálculo antes das compensações de prejuízos anteriores"
            width={430}
          />
          <Column
            key={nameOfGridHandler("compensacoes")}
            dataField={nameOfGridHandler("compensacoes")}
            {...obterConfiguracaoColuna("monetario")}
            caption="Compensações"
            width={140}
          />
          <Column
            key={nameOfGridHandler("baseCalculo")}
            dataField={nameOfGridHandler("baseCalculo")}
            {...obterConfiguracaoColuna("monetario")}
            caption="Base de cálculo do CSLL"
            width={190}
          />
          <Column
            key={nameOfGridHandler("contribuicaoSocialApurada")}
            dataField={nameOfGridHandler("contribuicaoSocialApurada")}
            {...obterConfiguracaoColuna("monetario")}
            caption="Contribuição social apurada à alíquota de 9%"
            width={310}
          />
          <Column
            key={nameOfGridHandler("contribuicaoSocialAPagar")}
            dataField={nameOfGridHandler("contribuicaoSocialAPagar")}
            {...obterConfiguracaoColuna("monetario")}
            caption="Contribuição social a pagar"
            width={200}
          />
          {GetColunasDeAuditoria()}
        </DataGrid>
      </ProvedorAjuda>

      <ModalMxp
        titulo={NormalizaTituloModal.Normalizar(
          idRegistroEmEdicao,
          NomesModais.apuracaoDoLacsCsll,
          true
        )}
        idRegistro={idRegistroEmEdicao}
        visivel={modalVisivel && idRegistroEmEdicao === 0}
        handleFechar={handleFecharModal}
        largura={"max(30vw, 600px)"}
        altura={"auto"}
      >
        <EditFormInsercaoApuracaoLacsCsll
          idRegistroEmEdicao={idRegistroEmEdicao}
          handleCallback={handleCallbackFormulario}
        />
      </ModalMxp>

      <ModalMxp
        titulo={NormalizaTituloModal.Normalizar(
          idRegistroEmEdicao,
          NomesModais.apuracaoDoLacsCsll,
          true
        )}
        idRegistro={idRegistroEmEdicao}
        visivel={modalVisivel && idRegistroEmEdicao > 0}
        handleFechar={handleFecharModal}
        largura={"max(30vw, 700px)"}
        altura={"auto"}
      >
        <EditFormAlteracaoApuracaoLacsCsll
          idRegistroEmEdicao={idRegistroEmEdicao}
          handleCallback={handleCallbackFormulario}
        />
      </ModalMxp>
    </>
  );
}
