import { useParams } from "react-router-dom";

export function useParametroId() {
  const param = useParams();
  const id = param["id"];

  if (!id) {
    return undefined;
  }

  // @ts-expect-error: O isNan consegue lidar com valores string, mas o typescript não permite (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/isNaN#difference_between_number.isnan_and_global_isnan).
  if (isNaN(id) && id == "novo") {
    return 0;
  }

  return Number(id);
}
