import getApi from "../../../../configs/api";
import { InserirEmMassaResponse } from "../../../../models/api/comum/inserir-varios";
import { ResponseModel } from "../../../../models/api/comum/response-base";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";
import { CentroDeCustosServico } from "../../../../services/contabilidade/centro-de-custos/centro-de-custos.service";
import ApiEmpresa from "../../../../services/empresa/empresa.service";
import { UnidadeDeMedidaServico } from "../../../../services/unidade-medida/unidade-medida.service";
import { tratarErroApi } from "../../../../utils/api/api-utils";
import { formartarDataParaSerializacao } from "../../../../utils/formatadores/formatador-de-datas";
import { ItemNotaFiscalRecebidaServico } from "../../../compras/item-nota-fiscal-recebida/servicos/item-nota-fiscal-recebida.service";
import { ItemServico } from "../../../itens/item/servicos/item.service";
import { ValorOpcaoServico } from "../../../sistema/valor-opcao/servicos/valor-opcao.service";
import { ContaContabilServico } from "../../conta-contabil/servicos/conta-contabil.service";
import {
  BuscaNotaImportacaoImobilizadoRequest,
  BuscaNotaImportacaoImobilizadoResponse,
  CalcularDepreciacaoRequest,
  CalcularDepreciacaoResponse,
  ImobilizadoInserirVariosExcelRequest,
  ImobilizadoRequest,
} from "../models/imobilizado";
import {
  EstadoDoImobilizado,
  FormaDeCalculoDaPrimeiraParcela,
  formaDeCalculoDaPrimeiraParcelaDecodificado,
  MesDeInicioDaDepreciacao,
  mesDeInicioDaDepreciacaoDecodificado,
  ResultadoBuscaItemNotaRecebida,
  TipoDeDepreciacao,
  tipoDeDepreciacaoDecodificado,
} from "../models/imobilizado.enums";

const entriesTipoDepreciacao = Object.entries(tipoDeDepreciacaoDecodificado);
const entriesFormasCalculoPrimeiraParcela = Object.entries(
  formaDeCalculoDaPrimeiraParcelaDecodificado
);
const entriesMesInicioDaDepreciacao = Object.entries(
  mesDeInicioDaDepreciacaoDecodificado
);

const gerarMensagemItemNaoEncontrado = (
  it: BuscaNotaImportacaoImobilizadoResponse
) => {
  switch (it.resultado) {
    case ResultadoBuscaItemNotaRecebida.Encontrado:
      return "";
    case ResultadoBuscaItemNotaRecebida.DivergenciaFornecedor:
      return 'Não foi possível encontrar item de nota fiscal recebida correspondente aos dados informados. Verifique o preenchimento do campo "Remetente" e tente novamente.';
    case ResultadoBuscaItemNotaRecebida.DivergenciaSerie:
      return 'Não foi possível encontrar item de nota fiscal recebida correspondente aos dados informados. Verifique o preenchimento do campo "Série" e tente novamente.';
    case ResultadoBuscaItemNotaRecebida.NaoEncontrado:
      return "Não foi possível encontrar item de nota fiscal recebida correspondente aos dados informados. Verifique o preenchimento dos campos (Número da nota fiscal recebida, número do item na nota fiscal recebida, série e remetente) e tente novamente.";
    default:
      return "";
  }
};

const procurarFormaCalculoPrimeiraParcela = (descricao: string | null) => {
  if (!descricao) {
    return null;
  }

  const vl = entriesFormasCalculoPrimeiraParcela.find(
    (x) => x[1].toUpperCase() == descricao.toUpperCase()
  );
  return vl ? Number(vl[0]) : null;
};

const procurarMesInicioDaDepreciacao = (descricao: string | null) => {
  if (!descricao) {
    return null;
  }

  const vl = entriesMesInicioDaDepreciacao.find(
    (x) => x[1].toUpperCase() == descricao.toUpperCase()
  );
  return vl ? Number(vl[0]) : null;
};

const procurarTipoDepreciacao = (descricao: string) => {
  const vl = entriesTipoDepreciacao.find(
    (x) => x[1].toUpperCase() == descricao.toUpperCase()
  );
  return vl ? Number(vl[0]) : null;
};

class ImobilizadoService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.Imobilizado);
  }

  public async CalcularDepreciacao(model: CalcularDepreciacaoRequest) {
    const api = getApi();
    const response = await api.post<ResponseModel<CalcularDepreciacaoResponse>>(
      `${this._nomeEndpoint}/CalcularDepreciacao`,
      model
    );

    return response.data;
  }

  public async InserirEmMassaExcel(
    imobilizados: ImobilizadoInserirVariosExcelRequest[]
  ) {
    try {
      imobilizados.forEach((im) => {
        if (
          im.tipoDeDepreciacao &&
          !procurarTipoDepreciacao(im!.tipoDeDepreciacao)
        ) {
          im.erros += `\nNão foi possível encontrar tipo de depreciação com o valor: "${im.codigoItem}".`;
        }
      });

      const idsItens = await ItemServico.obterIdsPorCodigos(
        imobilizados.map((im) => im.codigoItem)
      );

      imobilizados.forEach((im) => {
        if (!idsItens[im.codigoItem]) {
          im.erros += `\nNão foi possível encontrar item com o código: "${im.codigoItem}".`;
        }
      });

      const codigosContasContabeisConsultar = [
        ...imobilizados.map((x) => x.contaContabil),
        ...imobilizados.map((x) => x.contaDeCreditoDaDepreciacao),
        ...imobilizados.map((x) => x.contaDeDebitoDaDepreciacao),
      ];

      const idsContasContabeis = await ContaContabilServico.obterIdsPorCodigos(
        codigosContasContabeisConsultar
      );

      imobilizados.forEach((im) => {
        if (!idsContasContabeis[im.contaContabil]) {
          im.erros += `\nNão foi possível encontrar conta contábil com o código: "${im.contaContabil}".`;
        }

        if (!idsContasContabeis[im.contaDeCreditoDaDepreciacao]) {
          im.erros += `\nNão foi possível encontrar conta contábil de crédito da depreciação com o código: "${im.contaDeCreditoDaDepreciacao}".`;
        }

        if (!idsContasContabeis[im.contaDeDebitoDaDepreciacao]) {
          im.erros += `\nNão foi possível encontrar conta contábil de débito da depreciação com o código: "${im.contaDeDebitoDaDepreciacao}".`;
        }
      });

      const codigosCentrosCustosConsultar = [
        ...imobilizados.map((x) => x.centroDeCustos),
        ...imobilizados
          .filter((x) => x.centroDeCustosDeCreditoDaDepreciacao)
          .map((x) => x.centroDeCustosDeCreditoDaDepreciacao!),
        ...imobilizados
          .filter((x) => x.centroDeCustosDeDebitoDaDepreciacao)
          .map((x) => x.centroDeCustosDeDebitoDaDepreciacao!),
      ];

      const idsCentrosCustos = await CentroDeCustosServico.obterIdsPorCodigos(
        codigosCentrosCustosConsultar
      );

      imobilizados.forEach((im) => {
        if (!idsCentrosCustos[im.centroDeCustos]) {
          im.erros += `\nNão foi possível encontrar centro de custos com o código: "${im.centroDeCustos}".`;
        }

        if (
          im.centroDeCustosDeCreditoDaDepreciacao &&
          !idsCentrosCustos[im.centroDeCustosDeCreditoDaDepreciacao]
        ) {
          im.erros += `\nNão foi possível encontrar centro de custos de crédito da depreciação com o código: "${im.centroDeCustosDeCreditoDaDepreciacao}".`;
        }

        if (
          im.centroDeCustosDeDebitoDaDepreciacao &&
          !idsCentrosCustos[im.centroDeCustosDeDebitoDaDepreciacao]
        ) {
          im.erros += `\nNão foi possível encontrar centro de custos de débito da depreciação com o código: "${im.centroDeCustosDeDebitoDaDepreciacao}".`;
        }
      });

      const unidadesBuscar = imobilizados
        .filter((x) => x.unidade)
        .map((x) => x.unidade!);

      const idsUnidades =
        unidadesBuscar.length > 0
          ? (await UnidadeDeMedidaServico.ObterListaSimples()).model
          : [];

      imobilizados.forEach((im) => {
        if (im.unidade && !idsUnidades.find((x) => x.descricao == im.unidade)) {
          im.erros += `\nNão foi possível encontrar unidade o código: "${im.unidade}".`;
        }
      });

      const fornecedoresBuscar = imobilizados
        .filter((x) => x.fornecedor)
        .map((x) => x.fornecedor!);

      const idsFornecedores = await ApiEmpresa.obterIdsPorApelidos(
        fornecedoresBuscar
      );

      imobilizados.forEach((im) => {
        if (im.fornecedor && !idsFornecedores[im.fornecedor]) {
          im.erros += `\nNão foi possível encontrar remetente com o código: "${im.fornecedor}".`;
        }
      });

      const modelosBuscar = imobilizados
        .filter((x) => x.documentoFiscal)
        .map((x) => x.documentoFiscal!);

      const idsModelo =
        modelosBuscar.length > 0
          ? (
              await ValorOpcaoServico.ObterListaSimplesDoTiposDeDocumentosFiscaisDoIcmsParaOSped()
            ).model
          : [];

      imobilizados.forEach((im) => {
        if (
          im.documentoFiscal &&
          !idsModelo.find(
            (x) => x.descricao.substring(0, 2) == im.documentoFiscal
          )
        ) {
          im.erros += `\nNão foi possível encontrar tipo de documento fiscal com o código: "${im.documentoFiscal}."`;
        }
      });

      imobilizados.forEach((im) => {
        const tipo = im.tipoDeDepreciacao
          ? procurarTipoDepreciacao(im.tipoDeDepreciacao)
          : null;

        if (tipo == TipoDeDepreciacao.Anual && im.mesDeInicioDaDepreciacao) {
          im.erros +=
            '\nQuando o "Tipo de depreciação" for "Anual", o campo "Mês de início da depreciação" não deve ser preenchido. Verifique o campo e tente novamente.';
        } else if (
          tipo == TipoDeDepreciacao.Mensal &&
          !im.mesDeInicioDaDepreciacao
        ) {
          im.erros +=
            'Quando o "Tipo de depreciação" for "Mensal", o campo "Mês de início da depreciação" deve ser preenchido com "Mês da aquisição" ou o "Mês seguinte". Verifique o campo e tente novamente.';
        } else if (
          tipo &&
          procurarMesInicioDaDepreciacao(im.mesDeInicioDaDepreciacao) ==
            null /* Comparado com null pois pode retornar 0 e zero é implicitamente false. */
        ) {
          im.erros += `O valor "${im.mesDeInicioDaDepreciacao}" não é válido para o campo "Mês de início da depreciação".`;
        }
      });

      imobilizados.forEach((im) => {
        if (
          procurarFormaCalculoPrimeiraParcela(
            im.formaDeCalculoDaPrimeiraParcela
          ) ==
          null /* Comparado com null pois pode retornar 0 e zero é implicitamente false. */
        ) {
          im.erros += `O valor "${im.formaDeCalculoDaPrimeiraParcela}" não é válido para o campo "Forma de cálculo da primeira parcela".`;
        }
      });

      const dadosItemNotaBuscar = imobilizados
        .filter(
          (x) => x.sujeitoAoCiap && x.itemNotaRecebida && x.numeroNotaRecebida
        )
        .map(function (registro): BuscaNotaImportacaoImobilizadoRequest {
          return {
            linha: registro.linha,
            sequenciaDoItemNaNota: registro.itemNotaRecebida!,
            numeroNota: registro.numeroNotaRecebida!,
            serie: registro.serie,
            apelidoFornecedor: registro.fornecedor,
          };
        });

      const itensNotaRecebida =
        await ItemNotaFiscalRecebidaServico.BuscarItensParaImportacao(
          dadosItemNotaBuscar
        );

      imobilizados.forEach((im) => {
        const it = itensNotaRecebida.find((x) => x.linha == im.linha);

        if (it && it.resultado != ResultadoBuscaItemNotaRecebida.Encontrado) {
          im.erros += `\n${gerarMensagemItemNaoEncontrado(it)}`;
        }
      });

      const requests = imobilizados
        .filter((im) => im.erros.length === 0)
        .map(function (
          im: ImobilizadoInserirVariosExcelRequest
        ): ImobilizadoRequest & { linha: number } {
          const itemNf = itensNotaRecebida.find((x) => x.linha == im.linha);

          return {
            linha: im.linha,
            id: 0,
            itemId: idsItens[im.codigoItem],
            descricao: im.descricao,
            centroDeCustosId: idsCentrosCustos[im.centroDeCustos],
            centroDeCustosDeCreditoDaDepreciacaoId:
              im.centroDeCustosDeCreditoDaDepreciacao
                ? idsCentrosCustos[im.centroDeCustosDeCreditoDaDepreciacao]
                : null,
            centroDeCustosDeDebitoDaDepreciacaoId:
              im.centroDeCustosDeDebitoDaDepreciacao
                ? idsCentrosCustos[im.centroDeCustosDeDebitoDaDepreciacao]
                : null,
            contaContabilId: idsContasContabeis[im.contaContabil],
            contaDeCreditoDaDepreciacaoId:
              idsContasContabeis[im.contaDeCreditoDaDepreciacao],
            contaDeDebitoDaDepreciacaoId:
              idsContasContabeis[im.contaDeDebitoDaDepreciacao],
            estado: EstadoDoImobilizado.Ativo,
            vidaUtilEmMeses: im.vidaUtilEmMeses,
            dataDaAquisicao: formartarDataParaSerializacao(im.dataDaAquisicao),
            descricaoEstaFixa: true,
            numeroDoPatrimonio: im.numeroDoPatrimonio,
            utilizacaoDoBem: im.utilizacaoDoBem,
            valorResidual: im.valorResidual ?? 0,
            valorDaAquisicao: im.valorDaAquisicao,
            valorInicialDepreciado: im.valorInicialDepreciado ?? 0,
            tipoDeDepreciacao: im.tipoDeDepreciacao
              ? procurarTipoDepreciacao(im.tipoDeDepreciacao)
              : null,
            percentualDeDepreciacao: im.percentualDeDepreciacao,
            quantidadeInicialDeParcelasDepreciadas:
              im.quantidadeInicialDeParcelasDepreciadas ?? 0,
            sujeitoAoCiap: im.sujeitoAoCiap ?? false,
            formaDeCalculoDaPrimeiraParcela:
              procurarFormaCalculoPrimeiraParcela(
                im.formaDeCalculoDaPrimeiraParcela
              ) ?? FormaDeCalculoDaPrimeiraParcela.ProRata,
            mesDeInicioDaDepreciacao:
              procurarMesInicioDaDepreciacao(im.mesDeInicioDaDepreciacao) ??
              MesDeInicioDaDepreciacao.MesDaAquisicao,
            ciapDoImobilizado: im.sujeitoAoCiap
              ? {
                  parcelas: im.parcelas ?? 0,
                  parcelasApropriadas: im.parcelasApropriadas ?? 0,
                  itemNotaFiscalId: itemNf?.item?.itemNotaFiscalId ?? null,
                  chaveAcesso:
                    im.chaveAcesso ?? itemNf?.item?.chaveDeAcesso ?? null,
                  serie: im.serie ?? itemNf?.item?.serie ?? null,
                  unidadeId: im.unidade
                    ? idsUnidades.find((x) => x.descricao == im.unidade)
                        ?.valor ?? 0
                    : itemNf?.item?.unidadeId ?? 0,
                  numero: im.numero ?? itemNf?.item?.numeroNota ?? "",
                  fornecedorId: im.fornecedor
                    ? idsFornecedores[im.fornecedor] ?? 0
                    : itemNf?.item?.forncedorId ?? 0,
                  tipoDeDocumentoFiscalId: im.documentoFiscal
                    ? idsModelo.find(
                        (x) => x.descricao.substring(0, 2) == im.documentoFiscal
                      )?.valor ?? 0
                    : itemNf?.item?.tipoDocumentoFiscalId ?? 0,
                  dataEmissao: im.dataEmissao
                    ? formartarDataParaSerializacao(im.dataEmissao)
                    : itemNf?.item?.dataEmissao ?? "",
                  quantidade: im.quantidade ?? itemNf?.item?.quantidade ?? 0,
                  baseCalculoIcms:
                    im.baseCalculoIcms ?? itemNf?.item?.baseDeCalculoIcms ?? 0,
                  aliquotaIcms:
                    im.aliquotaIcms ?? itemNf?.item?.aliquotaIcms ?? 0,
                  valorIcms: im.valorIcms ?? itemNf?.item?.valorIcms ?? 0,
                  valorIcmsSt: im.valorIcmsSt ?? itemNf?.item?.valorIcmsSt ?? 0,
                  valorIcmsDifal:
                    im.valorIcmsDifal ?? itemNf?.item?.valorIcmsDifal ?? 0,
                  valorIcmsFrete: im.valorIcmsFrete,
                }
              : null,
          };
        });

      const resultado = await this.InserirEmMassa(requests);

      if (!resultado.sucesso) {
        return resultado;
      }

      resultado.model.forEach(
        (m, i) => (m.indiceDoRegistro = requests.at(i)?.linha ?? 0)
      );

      imobilizados
        .filter((x) => x.erros.length > 0)
        .forEach((x) =>
          resultado.model.push({
            indiceDoRegistro: x.linha,
            id: 0,
            erros: x.erros,
            mensagem: "",
          })
        );

      return resultado;
    } catch (error) {
      tratarErroApi(error);
      return {
        sucesso: false,
        model: [],
        mensagem: error,
        erros: [],
      } as ResponseModel<InserirEmMassaResponse[]>;
    }
  }

  public async InserirEmMassa(
    model: ImobilizadoRequest[]
  ): Promise<ResponseModel<InserirEmMassaResponse[]>> {
    const api = getApi();
    const response = await api.post<ResponseModel<InserirEmMassaResponse[]>>(
      `${this._nomeEndpoint}/InserirEmMassa`,
      model
    );

    return response.data;
  }
}

export const ImobilizadoServico = new ImobilizadoService();
