import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ProvedorAjuda from "../../../../../../components/ajuda/provedor-ajuda";
import BotaoCancelarMxp from "../../../../../../components/botoes/botao-cancelar-mxp";
import BotaoSalvarMxp from "../../../../../../components/botoes/botao-salvar-mxp";
import ModalAccordion from "../../../../../../components/dialogos/modal-accordion";
import { AccordionDataSource } from "../../../../../../components/dialogos/modal-accordion/modal-accordion";
import { FormDateBox } from "../../../../../../components/formularios";
import FormMxp from "../../../../../../components/layout/form";
import { ContainerFormMxp } from "../../../../../../components/layout/form/styles";
import { Coluna, Linha } from "../../../../../../components/layout/grid-system";
import ToolbarMxp from "../../../../../../components/layout/toolbar-mxp";
import AuditavelDTO from "../../../../../../models/api/comum/auditavel-dto";
import {
  ResponseModel,
  ResultadoResponse,
} from "../../../../../../models/api/comum/response-base";
import {
  IFormularioEditavelBase,
  ResultadoAcaoFormulario,
} from "../../../../../../models/shared/ui/formularios";
import {
  checarResponse,
  gerarMensagemAccordion,
  tratarErroApi,
} from "../../../../../../utils/api/api-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../../utils/common/notificacoes-utils";
import yup from "../../../../../../utils/validacao/validacao";
import {
  EfdReinfApuracaoRequestDTO,
  EfdReinfApuracaoResponseDTO,
} from "../../../models/apuracao/apuracao";
import { EfdReinfApuracaoServico } from "../../../servicos/apuracao/efd-reinf-apuracao.service";

const novoRegistro: EfdReinfApuracaoRequestDTO = {
  dataInicial: "",
  dataFinal: "",
};

let dadosAuditoria: AuditavelDTO | undefined = undefined;

export default function EditFormEfdReinfApuracao(
  props: IFormularioEditavelBase
) {
  const [modalAccordionVisivel, setModalAccordionVisivel] = useState(false);
  const [detalhamentoAccordion, setDetalhamentoAccordion] =
    useState<AccordionDataSource>();

  const schema = yup.object().shape({
    dataInicial: yup
      .string()
      .required()
      .test(
        "data_inicial_valida",
        "A data inicial está maior do que a final.",
        function (value, contexto) {
          if (value && contexto.parent.dataFinal) {
            const dataInicial = new Date(value);
            const dataFinal = new Date(contexto.parent.dataFinal);
            return dataInicial <= dataFinal;
          }
          return true;
        }
      ),
    dataFinal: yup
      .string()
      .required()
      .test(
        "data_final_valida",
        "A data final está menor do que a inicial.",
        function (valor, contexto) {
          if (valor && contexto.parent.dataInicial) {
            const dataInicial = new Date(contexto.parent.dataInicial);
            const dataFinal = new Date(valor);
            return dataInicial <= dataFinal;
          }
          return true;
        }
      ),
  });

  const { control, handleSubmit, getValues, reset } =
    useForm<EfdReinfApuracaoRequestDTO>({
      resolver: yupResolver(schema),
    });

  //Hook usado para carregar os dados da tela
  useEffect(() => {
    if (Number.isNaN(props.idRegistroEmEdicao)) {
      return;
    }

    preencherTela();
  }, [props.idRegistroEmEdicao]);

  async function preencherTela() {
    if (props.idRegistroEmEdicao == 0) {
      limparTela();
    } else if (props.idRegistroEmEdicao > 0) {
      await carregarRegistroDoId();
    }
  }

  async function carregarRegistroDoId() {
    try {
      const resposta =
        await EfdReinfApuracaoServico.ObterPorIdComDadosAuditoria<EfdReinfApuracaoResponseDTO>(
          props.idRegistroEmEdicao
        );
      checarResponse(resposta);
      dadosAuditoria = resposta.model;
      reset(resposta.model);
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  function limparTela() {
    dadosAuditoria = undefined;
    reset(novoRegistro);
  }

  async function handleSalvar() {
    const model = getValues();
    try {
      // Essa tela não permite edição de apuração já gerada
      if (props.idRegistroEmEdicao != 0) {
        return;
      }

      const resposta = await EfdReinfApuracaoServico.Inserir(model);

      if (resposta.sucesso) {
        const accordion = gerarMensagemAccordion(
          (
            resposta as ResponseModel<
              ResultadoResponse<EfdReinfApuracaoResponseDTO>
            >
          ).model
        );

        if (accordion.length > 0) {
          setModalAccordionVisivel(true);
          setDetalhamentoAccordion({
            model: accordion,
          });
        } else {
          fechar(ResultadoAcaoFormulario.AcaoConcluida);
        }

        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
      }
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  function handleCancelar() {
    fechar(ResultadoAcaoFormulario.AcaoCancelada);
  }

  function fechar(resultado: ResultadoAcaoFormulario) {
    limparTela();
    props.handleCallback(resultado);
  }

  const handleFecharModalAccordion = useCallback(
    (
      setModalAccordionVisivel: (value: React.SetStateAction<boolean>) => void,
      setDetalhamentoModalAccordion: (
        value: React.SetStateAction<AccordionDataSource | undefined>
      ) => void
    ) => {
      setModalAccordionVisivel(false);
      setDetalhamentoModalAccordion(undefined);
      fechar(ResultadoAcaoFormulario.AcaoConcluida);
    },
    []
  );

  return (
    <>
      <ContainerFormMxp>
        <FormMxp>
          <ProvedorAjuda id="edit-form-efd-reinf-apuracao">
            <Linha>
              <Coluna md={3}>
                <FormDateBox
                  name="dataInicial"
                  titulo="Período de"
                  control={control}
                  requerido
                  aceitaValorCustomizado={true}
                  aceitaDigitacaoComMascara={true}
                  somenteLeitura={props.idRegistroEmEdicao > 0}
                />
              </Coluna>
              <Coluna md={3}>
                <FormDateBox
                  name="dataFinal"
                  titulo="a"
                  control={control}
                  requerido
                  aceitaValorCustomizado={true}
                  aceitaDigitacaoComMascara={true}
                  somenteLeitura={props.idRegistroEmEdicao > 0}
                />
              </Coluna>
            </Linha>
          </ProvedorAjuda>
        </FormMxp>
        <ToolbarMxp dadosAuditoria={dadosAuditoria}>
          {props.idRegistroEmEdicao == 0 && (
            <BotaoSalvarMxp handleClick={handleSubmit(handleSalvar)} />
          )}
          <BotaoCancelarMxp handleClick={handleCancelar} />
        </ToolbarMxp>
      </ContainerFormMxp>

      <ModalAccordion
        modalAccordionVisivel={modalAccordionVisivel}
        handlerFecharModalAccordion={() =>
          handleFecharModalAccordion(
            setModalAccordionVisivel,
            setDetalhamentoAccordion
          )
        }
        dataSource={detalhamentoAccordion?.model}
        modalTitulo={detalhamentoAccordion?.accordionTitulo}
        accordionId="accordion-acao-insumo"
        itemDoAccordionAltura="auto"
      />
    </>
  );
}
