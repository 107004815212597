import DataGrid, { Column, DataGridRef } from "devextreme-react/data-grid";
import { useCallback, useEffect, useMemo, useRef } from "react";
import ProvedorAjuda from "../../../../../../components/ajuda/provedor-ajuda";
import GridColunaAcoes from "../../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import { ModalMxp } from "../../../../../../components/layout/modal-mxp";
import { useRegistrarAtalhosGrid } from "../../../../../../hooks/atalhos.hooks";
import { useControlarFormDeEdicao } from "../../../../../../hooks/form.hooks";
import { useMenuDeContextosGrid } from "../../../../../../hooks/menus.hooks";
import { useParametroId } from "../../../../../../hooks/route.hooks";
import { PermissoesEfdReinfApuracao } from "../../../../../../models/permissoes/fiscal/efd-reinf/permissoes-apuracao-efd-reinf";
import { ResultadoAcaoFormulario } from "../../../../../../models/shared/ui/formularios";
import GetColunasDeAuditoria from "../../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import {
  checarResponse,
  checarResponseExibeMensagemExclusaoDeSucesso,
  tratarErroApi,
} from "../../../../../../utils/api/api-utils";
import criarNameof from "../../../../../../utils/common/cria-name-of";
import NomesModais from "../../../../../../utils/common/nomes-modais";
import NomesTelas from "../../../../../../utils/common/nomes-telas";
import NormalizaTituloModal from "../../../../../../utils/common/normaliza-titulo";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../../utils/common/permissoes-utils";
import { ItemContextMenuMxp } from "../../../../../../utils/context-menu/context-menu-utils";
import { GestorEventoClickUnicaLinha } from "../../../../../../utils/context-menu/gestor-evento-click";
import { exibirConfirmacao } from "../../../../../../utils/dialogos";
import { formatarDataAno4digitos } from "../../../../../../utils/formatadores/formatador-de-datas";
import GridBuilder from "../../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../../utils/grid/padroes-colunas";
import UrlUtils from "../../../../../../utils/url/url-utils";
import { EfdReinfApuracaoGridModel } from "../../../models/apuracao/apuracao";
import { EfdReinfApuracaoServico } from "../../../servicos/apuracao/efd-reinf-apuracao.service";
import EditFormEfdReinfApuracao from "../../formulario/apuracao";

const dataSource =
  EfdReinfApuracaoServico.ObterDataSourceParaGrid<EfdReinfApuracaoGridModel>();

const nameOfGridHandler = criarNameof<EfdReinfApuracaoGridModel>();

export default function GridEfdReinfApuracao() {
  const gridRef = useRef<DataGridRef>(null);
  const parametroId = useParametroId();

  const {
    idRegistroEmEdicao,
    setIdRegistroEmEdicao,
    modalVisivel,
    encerrarEdicao,
  } = useControlarFormDeEdicao(NaN);

  const handleNovoRegistro = useCallback(() => {
    if (
      verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesEfdReinfApuracao.InserirEditar,
      ])
    ) {
      setIdRegistroEmEdicao(0);
    }
  }, []);

  const handleEditarRegistro = useCallback(
    (registro: EfdReinfApuracaoGridModel) => {
      if (
        verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesEfdReinfApuracao.InserirEditar,
        ])
      ) {
        setIdRegistroEmEdicao(registro.id);
      }
    },
    []
  );

  const handleExcluirRegistro = useCallback(
    async (registro: EfdReinfApuracaoGridModel) => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesEfdReinfApuracao.Excluir,
        ])
      ) {
        return;
      }

      const excluir = await exibirConfirmacao(
        "Confirmar exclusão",
        obterMensagemExclusao(registro)
      );

      if (excluir) {
        const resposta = await EfdReinfApuracaoServico.Excluir(registro.id);

        if (resposta) {
          checarResponseExibeMensagemExclusaoDeSucesso(resposta);
          handleAtualizarGrid();
        }
      }
    },
    []
  );

  function obterMensagemExclusao(registro: EfdReinfApuracaoGridModel) {
    return `Tem certeza que deseja excluir o registro entre o período ${formatarDataAno4digitos(
      registro.dataInicial
    )} - ${formatarDataAno4digitos(registro.dataFinal)}?`;
  }

  const recalcularApuracao = useCallback(
    async (registro: EfdReinfApuracaoGridModel) => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesEfdReinfApuracao.Recalcular,
        ])
      ) {
        return;
      }

      if (registro.id) {
        try {
          const resposta = await EfdReinfApuracaoServico.RecalcularApuracao(
            registro.id
          );
          checarResponse(resposta);

          if (resposta.sucesso) {
            handleAtualizarGrid();

            exibirNotificacaoToast({
              mensagem: resposta.mensagem,
              tipo: TipoNotificacao.Sucesso,
            });
          }
        } catch (erro) {
          tratarErroApi(erro);
        }
      }
    },
    []
  );

  const gridController = useMemo(
    () =>
      new GridController<EfdReinfApuracaoGridModel>(() =>
        gridRef.current?.instance()
      ),
    []
  );

  const acoesDeContexto: ItemContextMenuMxp[] = useMemo(() => {
    return [
      {
        text: "Ações",
        icon: "ic-material-symbols-outlined ic-vertical",
        exibirNaLinhaDaGrid: "menuDeContexto",
        exibirNoMenuPrincipal: true,
        items: [
          {
            text: "Recalcular apuração",
            gestorEventoClick: new GestorEventoClickUnicaLinha(
              recalcularApuracao,
              () => gridController
            ),
            exibirNaLinhaDaGrid: "sempre",
          },
        ],
      },
    ];
  }, [gridController]);

  const menuContexto = useMenuDeContextosGrid(
    acoesDeContexto,
    true,
    gridController
  );

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar("efd-reinf-apuracao", () =>
      gridRef.current?.instance()
    )
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(NomesTelas.efdReinfApuracao)
      .definirBotaoNovo(handleNovoRegistro)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirSelecao()
      .definirOrdenacao()
      .definirDuploCliqueLinha(handleEditarRegistro)
      .definirMenuDeContexto(menuContexto)
      .build();
  }, [menuContexto]);

  useEffect(() => {
    if (parametroId || parametroId === 0) {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesEfdReinfApuracao.InserirEditar,
        ])
      ) {
        return;
      }

      setIdRegistroEmEdicao(parametroId);
    }
  }, []);

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  useRegistrarAtalhosGrid<EfdReinfApuracaoGridModel>({
    controller: gridController,
    handleNovo: handleNovoRegistro,
    handleEditar: handleEditarRegistro,
    handleExcluir: handleExcluirRegistro,
  });

  const handleCallbackFormulario = useCallback(
    (resultado: ResultadoAcaoFormulario) => {
      encerrarEdicao();

      if (resultado == ResultadoAcaoFormulario.AcaoConcluida) {
        handleAtualizarGrid();
      }

      UrlUtils.RemoverParametroId(parametroId);
    },
    [parametroId]
  );

  const handleFecharModal = useCallback(() => {
    encerrarEdicao();
    UrlUtils.RemoverParametroId(parametroId);
  }, [parametroId]);

  return (
    <>
      <ProvedorAjuda id={"tooltips-grid-efd-reinf-apuracao"}>
        <DataGrid ref={gridRef} {...configuracoesGrid}>
          {GridColunaAcoes<EfdReinfApuracaoGridModel>({
            handleEditar: handleEditarRegistro,
            handleExcluir: handleExcluirRegistro,
          })}
          <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
          <Column
            key={nameOfGridHandler("dataInicial")}
            dataField={nameOfGridHandler("dataInicial")}
            {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
            width={130}
            sortIndex={0}
            sortOrder="desc"
            caption="Data inicial"
          />
          <Column
            key={nameOfGridHandler("dataFinal")}
            dataField={nameOfGridHandler("dataFinal")}
            {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
            width={130}
            caption="Data final"
          />
          <Column
            key={nameOfGridHandler("minhaEmpresaApelido")}
            dataField={nameOfGridHandler("minhaEmpresaApelido")}
            {...obterConfiguracaoColuna("stringM")}
            caption="Minha empresa"
            visible={false}
          />
          {GetColunasDeAuditoria()}
        </DataGrid>
      </ProvedorAjuda>

      <ModalMxp
        titulo={NormalizaTituloModal.Normalizar(
          idRegistroEmEdicao,
          NomesModais.efdReinfApuracao,
          true
        )}
        idRegistro={idRegistroEmEdicao}
        visivel={modalVisivel}
        handleFechar={handleFecharModal}
        largura={"max(30vw, 600px)"}
        altura={"auto"}
      >
        <EditFormEfdReinfApuracao
          idRegistroEmEdicao={idRegistroEmEdicao}
          handleCallback={handleCallbackFormulario}
        />
      </ModalMxp>
    </>
  );
}
