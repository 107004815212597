import DataSource from "devextreme/data/data_source";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import ProvedorAjuda from "../../../../../../../components/ajuda/provedor-ajuda";
import BotaoCancelarMxp from "../../../../../../../components/botoes/botao-cancelar-mxp";
import BotaoSalvarMxp from "../../../../../../../components/botoes/botao-salvar-mxp";
import {
  FormDateBox,
  FormGrupo,
  FormNumberBox,
  FormSelectBox,
  FormSelectBoxLazy,
  FormTextBox,
} from "../../../../../../../components/formularios";
import FormMxp from "../../../../../../../components/layout/form";
import {
  Coluna,
  Linha,
} from "../../../../../../../components/layout/grid-system";
import { ModalMxp } from "../../../../../../../components/layout/modal-mxp";
import ToolbarMxp from "../../../../../../../components/layout/toolbar-mxp";
import {
  useCarregarRegistro,
  useHookForms,
  useLimparFormSeIdForNaN,
} from "../../../../../../../hooks/form.hooks";
import DocumentoFiscalSelecaoMdfeGrid, {
  novoRegistroDocumentoFiscalSelecaoMdfeGrid,
} from "../../../../../../../models/api/nota-fiscal/nota-fiscal-selecao-mdfe";
import {
  IFormularioEditavelBase,
  ResultadoAcaoFormulario,
} from "../../../../../../../models/shared/ui/formularios";
import SelectItem from "../../../../../../../models/shared/ui/select-item";
import APIMunicipio from "../../../../../../../services/municipio/municipio.service";
import APIUF from "../../../../../../../services/uf/uf.service";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../../../utils/api/api-utils";
import { previneDigitacaoDeCaracteres } from "../../../../../../../utils/common/common-utils";
import NomesModais from "../../../../../../../utils/common/nomes-modais";
import NormalizaTituloModal from "../../../../../../../utils/common/normaliza-titulo";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../../../utils/common/notificacoes-utils";
import { letrasDigitosSimbolosSemEspacos } from "../../../../../../../utils/common/regex-padrao";
import { extraiTipoDocumentoChaveAcesso } from "../../../../../../../utils/especifico/mdfe/mdfe-utils";
import { obterFormatStringNumero } from "../../../../../../../utils/formatadores/formatador-de-numeros";
import {
  FormataDescricao,
  FormatadoresSelectBox,
} from "../../../../../../../utils/formatadores/formatador-de-selectbox";
import yup from "../../../../../../../utils/validacao/validacao";
import { ComboClienteEmpresaMxp } from "../../../../../../sistema/empresa/componentes/select-box-lazy";
import MDFeEditFormContext from "../../../../contexts/mdfe-editform.context";
import { tiposCTe, tiposNFe } from "../../../../models/mdfe-enums";
import { pesoTamanhoMaximo, valorTamanhoMaximo } from "./constantes";

interface EditFormDocumentoFiscalAvulsoProps extends IFormularioEditavelBase {
  documentoFiscal?: DocumentoFiscalSelecaoMdfeGrid | undefined;
  visivel: boolean;
}

const exibeMunicipioDeDestino = (c: any) => {
  if (c) {
    return FormataDescricao(
      FormatadoresSelectBox.CodigoDescricaoParenteses,
      c.Descricao,
      c.SiglaUf
    );
  }

  return "";
};

const municipioDeDestinoExpressaoDeBusca = ["Descricao", "SiglaUf"];

const previneDigitacaoDeCaracteresHandler = (e: any) =>
  previneDigitacaoDeCaracteres(e, letrasDigitosSimbolosSemEspacos);

const SelecaoUfMunicipioDestino = (props: {
  control: any;
  documentoFiscal: DocumentoFiscalSelecaoMdfeGrid | undefined;
}) => {
  const [municipios, setMunicipios] = useState<DataSource | undefined>();
  const [ufs, setUfs] = useState<SelectItem[]>([]);
  const model =
    props.documentoFiscal ?? novoRegistroDocumentoFiscalSelecaoMdfeGrid;

  useEffect(() => {
    carregarUfs().then(() => carregarMunicipios(model?.idUfDestino ?? 0));
  }, []);

  const carregarUfs = useCallback(async () => {
    try {
      const resposta = await APIUF.obterListagemSimples();
      checarResponse(resposta);
      setUfs(
        resposta.model.map((x) => ({
          valor: x.valor,
          descricao: x.descricao,
        }))
      );
    } catch (erro) {
      tratarErroApi(erro);
    }
  }, [setUfs]);

  const carregarMunicipios = useCallback(
    (idUfDestino: any) => {
      setMunicipios(
        APIMunicipio.getDataSourceSelectBoxLazy({
          camposRetorno: ["Id", "Descricao", "SiglaUf"],
          filtros: idUfDestino
            ? [{ nomeCampo: "IdUf", operador: "=", valor: idUfDestino }]
            : undefined,
          camposOrdenacao: [
            {
              nomeCampo: "Descricao",
              desc: false,
            },
          ],
        })
      );
    },
    [setMunicipios]
  );

  const handleUfDestinoChange = useCallback(
    (ufDestino: any) => {
      carregarMunicipios(ufDestino);
    },
    [carregarMunicipios]
  );

  return (
    <>
      <Coluna md={3}>
        <FormSelectBox
          name="idUfDestino"
          titulo="UF de destino"
          control={props.control}
          exibirBotaoLimpar
          dataSource={ufs}
          habilitaBusca
          tipoBusca="contains"
          onValueChange={handleUfDestinoChange}
          requerido
        />
      </Coluna>
      <Coluna md={9}>
        <FormSelectBoxLazy
          name="idMunicipioDestino"
          titulo="Município de destino"
          control={props.control}
          dataSource={municipios}
          nomeCampoChave="Id"
          nomeCampoExibicao={exibeMunicipioDeDestino}
          expressaoDeBusca={municipioDeDestinoExpressaoDeBusca}
          requerido
        />
      </Coluna>
    </>
  );
};

const novoRegistro = novoRegistroDocumentoFiscalSelecaoMdfeGrid;

const CHAVE_DE_ACESSO_TAMANHO = 44;

const dataSourceMunicipioConsulta = APIMunicipio.getDataSourceSelectBoxLazy({
  camposRetorno: ["Id", "Descricao", "SiglaUf"],
  camposOrdenacao: [
    {
      nomeCampo: "Descricao",
      desc: false,
    },
  ],
});

export const EditFormDocumentoFiscalAvulso = (
  props: EditFormDocumentoFiscalAvulsoProps
) => {
  const {
    descarregamentos,
    editarDocumentoFiscal,
    removerEAdicionarDocumentoFiscal,
    adicionarDocumentoFiscal,
  } = useContext(MDFeEditFormContext);
  const schema = useMemo(
    () =>
      yup.object().shape({
        id: yup.number().required().moreThan(-1).integer(),
        chaveAcesso: yup
          .string()
          .required()
          .typeError("O código tem que ser numérico")
          .min(CHAVE_DE_ACESSO_TAMANHO)
          .max(CHAVE_DE_ACESSO_TAMANHO)
          .test(
            "chaveAcesso_necessarioSerCTeNFe",
            "É necessário que a chave de acesso seja de uma NF-e ou CT-e",
            function (value) {
              return (
                tiposNFe.includes(extraiTipoDocumentoChaveAcesso(value)) ||
                tiposCTe.includes(extraiTipoDocumentoChaveAcesso(value))
              );
            }
          )
          .test(
            "chaveAcesso_chaveAcessoNaoDuplicada",
            "O documento fiscal com essa chave de acesso já foi adicionado.",
            function (value) {
              return !descarregamentos.some(
                (x) =>
                  x.documentosVinculados?.some(
                    (y) =>
                      y.documentoFiscal?.chaveAcesso === value &&
                      y.id !== this.parent.id
                  ) ?? false
              );
            }
          ),
        numero: yup
          .string()
          .required()
          .typeError("O código tem que ser numérico"),
        serie: yup
          .string()
          .required()
          .typeError("O código tem que ser numérico"),
        dataEmissao: yup.string().required(),
        IdEmpresaDestinataria: yup.number().notRequired().nullable().positive(),
        idUfDestino: yup.number().moreThan(0).required().integer(),
        idMunicipioDestino: yup
          .number()
          .required()
          .positive()
          .integer()
          .transform((v) => (v ? v : null)),
        valor: yup.number().moreThan(0).required(),
        pesoLiquido: yup
          .number()
          .notRequired()
          .nullable()
          .positive()
          .transform((v) => (v ? v : null)),
        pesoBruto: yup.number().moreThan(0).required(),
      }),
    [descarregamentos]
  );

  const hookForm = useHookForms(schema);
  const { register, control, handleSubmit, getValues, reset, setValue } =
    hookForm;

  const carregarTela = useCallback(async () => {
    try {
      const model = props.documentoFiscal ?? novoRegistro;
      reset(model);
    } catch (erro) {
      tratarErroApi(erro);
    }
  }, [reset, props.documentoFiscal]);

  useCarregarRegistro(props.idRegistroEmEdicao, carregarTela);
  useLimparFormSeIdForNaN(hookForm, novoRegistro, props.idRegistroEmEdicao);

  const handleChaveAcessoChange = useCallback(
    (e: any) => {
      const chaveAcesso = e;

      if (chaveAcesso.length >= 22) {
        setValue("serie", chaveAcesso.substring(22, 25));
      }

      if (chaveAcesso.length >= 25) {
        setValue("numero", chaveAcesso.substring(25, 34));
      }
    },
    [setValue]
  );

  const fechar = useCallback(
    (info: ResultadoAcaoFormulario) => {
      if (props.handleCallback) {
        props.handleCallback(info);
      }
    },
    [props]
  );

  const exibeMensagemInsercaoDocumentoFiscal = useCallback(
    (model: DocumentoFiscalSelecaoMdfeGrid) => {
      exibirNotificacaoToast({
        mensagem: `Documento Nº ${model.numero} adicionado com sucesso. Clique em "Salvar" para confirmar a operação.`,
        tipo: TipoNotificacao.Advertencia,
      });
    },
    []
  );

  const callBackUnprocessableEntity = useCallback(() => {
    fechar(ResultadoAcaoFormulario.AcaoComErro);
  }, [fechar]);

  const handleSalvar = useCallback(async () => {
    const model = getValues() as DocumentoFiscalSelecaoMdfeGrid;

    dataSourceMunicipioConsulta.filter(["Id", "=", model.idMunicipioDestino]);
    await dataSourceMunicipioConsulta.load();
    const dado = dataSourceMunicipioConsulta.items()[0];

    model.ufDestino = dado.SiglaUf;
    model.municipioDestino = dado.Descricao;

    try {
      if (props.documentoFiscal?.id ?? 0 > 0) {
        editarDocumentoFiscal(model);
      } else if (props.documentoFiscal?.chaveAcesso) {
        removerEAdicionarDocumentoFiscal(props.documentoFiscal, [model]);
        exibeMensagemInsercaoDocumentoFiscal(model);
      } else {
        adicionarDocumentoFiscal([model]);
        exibeMensagemInsercaoDocumentoFiscal(model);
      }

      fechar(ResultadoAcaoFormulario.AcaoConcluida);
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    }
  }, [
    props.documentoFiscal,
    editarDocumentoFiscal,
    adicionarDocumentoFiscal,
    callBackUnprocessableEntity,
    fechar,
    getValues,
    exibeMensagemInsercaoDocumentoFiscal,
    removerEAdicionarDocumentoFiscal,
  ]);

  const handleCancelar = useCallback(async () => {
    fechar(ResultadoAcaoFormulario.AcaoCancelada);
  }, [fechar]);

  return (
    <>
      <div id="edit-form-documento-fiscal">
        <ModalMxp
          visivel={props.visivel}
          handleFechar={handleCancelar}
          titulo={NormalizaTituloModal.Normalizar(
            props.idRegistroEmEdicao,
            NomesModais.documentoAvulso
          )}
          largura="max(50vw, 1000px)"
          altura="auto"
        >
          <ProvedorAjuda id="edit-form-documento-fiscal">
            <FormMxp>
              <input type="hidden" {...register("id")} defaultValue={0} />
              <FormGrupo>
                <Linha>
                  <Coluna md={8}>
                    <FormTextBox
                      name="chaveAcesso"
                      titulo="Chave de acesso"
                      control={control}
                      requerido
                      tamanhoMaximo={44}
                      onKeyDown={previneDigitacaoDeCaracteresHandler}
                      onChange={handleChaveAcessoChange}
                    />
                  </Coluna>
                  <Coluna md={2}>
                    <FormTextBox
                      name="numero"
                      titulo="Número"
                      requerido
                      desabilitado
                      control={control}
                      tamanhoMaximo={10}
                      onKeyDown={previneDigitacaoDeCaracteresHandler}
                    />
                  </Coluna>
                  <Coluna md={2}>
                    <FormTextBox
                      name="serie"
                      titulo="Série"
                      requerido
                      desabilitado
                      control={control}
                      tamanhoMaximo={3}
                      onKeyDown={previneDigitacaoDeCaracteresHandler}
                    />
                  </Coluna>
                </Linha>
                <Linha>
                  <Coluna md={3}>
                    <FormDateBox
                      name="dataEmissao"
                      titulo="Emissão"
                      toolTip="Emissão"
                      control={control}
                      requerido
                      exibirBotaoLimpar
                      aceitaValorCustomizado={true}
                      aceitaDigitacaoComMascara={true}
                    />
                  </Coluna>
                  <Coluna md={9}>
                    <ComboClienteEmpresaMxp
                      name="idDestinatario"
                      titulo="Destinatário"
                      control={control}
                    />
                  </Coluna>
                </Linha>
                <Linha>
                  <SelecaoUfMunicipioDestino
                    control={control}
                    documentoFiscal={props.documentoFiscal}
                  />
                </Linha>
                <Linha>
                  <Coluna md={3}>
                    <FormNumberBox
                      name="valor"
                      titulo="Valor"
                      control={control}
                      requerido
                      formato={obterFormatStringNumero(2)}
                      maximo={valorTamanhoMaximo}
                    />
                  </Coluna>
                  <Coluna md={3}>
                    <FormNumberBox
                      name="pesoLiquido"
                      titulo="Peso líquido"
                      control={control}
                      formato={obterFormatStringNumero(4)}
                      maximo={pesoTamanhoMaximo}
                    />
                  </Coluna>
                  <Coluna md={3}>
                    <FormNumberBox
                      name="pesoBruto"
                      titulo="Peso bruto"
                      toolTip="Peso bruto"
                      control={control}
                      requerido
                      formato={obterFormatStringNumero(4)}
                      maximo={pesoTamanhoMaximo}
                    />
                  </Coluna>
                </Linha>
              </FormGrupo>
            </FormMxp>
          </ProvedorAjuda>
          <ToolbarMxp>
            <BotaoSalvarMxp handleClick={handleSubmit(handleSalvar)} />
            <BotaoCancelarMxp handleClick={handleCancelar} />
          </ToolbarMxp>
        </ModalMxp>
      </div>
    </>
  );
};

export default EditFormDocumentoFiscalAvulso;
