import { DataGrid } from "devextreme-react";
import { Column, DataGridRef } from "devextreme-react/data-grid";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import { ModalAccordionDetalhado } from "../../../../../components/dialogos/modal-accordion";
import { AccordionDetalhadoDataSource } from "../../../../../components/dialogos/modal-accordion/modal-accordion";
import ImportadorDeRegistros, {
  colunaUtcParaData,
  MxpSchemaImportar,
} from "../../../../../components/formularios/importador";
import GridColunaAcoes from "../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import { useRegistrarAtalhosGrid } from "../../../../../hooks/atalhos.hooks";
import { useControlarFormDeEdicao } from "../../../../../hooks/form.hooks";
import { useMenuDeContextosGrid } from "../../../../../hooks/menus.hooks";
import { useParametroId } from "../../../../../hooks/route.hooks";
import { useSeletorGridMxp } from "../../../../../hooks/seletor.hooks";
import { PermissoesLote } from "../../../../../models/permissoes/estoque/lote/permissoes-lote";
import { ResultadoAcaoFormulario } from "../../../../../models/shared/ui/formularios";
import { GridMxpProps } from "../../../../../models/shared/ui/grid";
import GetColunasDeAuditoria from "../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import {
  checarResponseExibeMensagemExclusaoDeSucesso,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesModais from "../../../../../utils/common/nomes-modais";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import NormalizaTituloModal from "../../../../../utils/common/normaliza-titulo";
import {
  GeradorMensagensNotificacao,
  JanelasDeNotificacaoTitulos,
} from "../../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import { ItemContextMenuMxp } from "../../../../../utils/context-menu/context-menu-utils";
import {
  GestorEventoClickMultiplasLinhas,
  GestorEventoClickSimples,
  GestorEventoClickUnicaLinha,
} from "../../../../../utils/context-menu/gestor-evento-click";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import UrlUtils from "../../../../../utils/url/url-utils";
import yup from "../../../../../utils/validacao/validacao";
import {
  LoteGridModel,
  LoteInserirVariosExcelRequest,
} from "../../models/lote.api";
import LoteContantes from "../../models/lote.constantes";
import { LoteServico } from "../../servicos/lote.servico";
import {
  abrirModalDetalhesDoLoteMxp1,
  criarBoletimDeInspecao,
} from "../../utils/lote.utils";
import { NomesMenuLote } from "../../utils/menu/acoes-menu";
import FormLote from "../formulario";
import {
  cancelarLoteHandler,
  cancelarLotesHandler,
} from "./helper/cancelar-lote-helper";

const nameOfGridHandler = criarNameof<LoteGridModel>();

const schema: MxpSchemaImportar<LoteInserirVariosExcelRequest> = {
  "Lote do fabricante/número de série*": {
    prop: "loteDoFabricanteOuNumeroDeSerie",
    type: String,
    dataType: "string",
  },
  "Item*": {
    // JSON object property name.
    prop: "codigoItem",
    type: String,
    dataType: "string",
  },
  "Fabricante*": {
    prop: "codigoFabricante",
    type: String,
    dataType: "string",
  },
  "Data de fabricação": {
    prop: "fabricacaoData",
    type: colunaUtcParaData,
    dataType: "date",
  },
  "Data de vencimento": {
    prop: "vencimentoData",
    type: colunaUtcParaData,
    dataType: "date",
  },
  "Data de reanálise": {
    prop: "reanaliseData",
    type: colunaUtcParaData,
    dataType: "date",
  },
  Observações: {
    prop: "observacoes",
    type: String,
    dataType: "string",
  },
  "Observações internas": {
    prop: "observacoesInternas",
    type: String,
    dataType: "number",
  },
};

const schemaYup = yup.object().shape({
  codigoItem: yup.string().label("Item*").required(),
  codigoFabricante: yup.string().label("Fabricante*").required(),
  loteDoFabricanteOuNumeroDeSerie: yup
    .string()
    .label("Lote do fabricante/número de serie*")
    .required()
    .max(LoteContantes.LoteDoFabricanteOuNumeroDeSerieMaxLength),
});

export default function GridLote(props: GridMxpProps<LoteGridModel>) {
  const gridRef = useRef<DataGridRef>(null);
  const parametroId = useParametroId();
  const [importarVisivel, setImportarVisivel] = useState(false);

  const [modalAcaoLotesAccordionVisivel, setModalAcaoLotesAccordionVisivel] =
    useState(false);
  const [detalhamentoAcaoLotesAccordion, setDetalhamentoAcaoLotesAccordion] =
    useState<AccordionDetalhadoDataSource>();

  const {
    idRegistroEmEdicao,
    setIdRegistroEmEdicao,
    modalVisivel,
    encerrarEdicao,
  } = useControlarFormDeEdicao(NaN);

  const dataSource = LoteServico.ObterDataSourceParaGrid<LoteGridModel>(
    props.filtrosNoServidor
  );

  useSeletorGridMxp(() => gridRef.current, props.filtrosNoCliente);

  useEffect(() => {
    if (parametroId || parametroId === 0) {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesLote.InserirEditar,
        ])
      ) {
        return;
      }

      setIdRegistroEmEdicao(parametroId);
    }
  }, []);

  const handleNovoRegistro = useCallback(() => {
    if (
      verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesLote.InserirEditar,
      ])
    ) {
      setIdRegistroEmEdicao(0);
    }
  }, []);

  const handleEditarRegistro = useCallback((registro: LoteGridModel) => {
    if (
      verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesLote.InserirEditar,
      ])
    ) {
      setIdRegistroEmEdicao(registro.id);
    }
  }, []);

  const handleExcluirRegistro = useCallback(async (registro: LoteGridModel) => {
    if (
      !verificaComNotificacaoSeUsuarioPossuiPermissoes([PermissoesLote.Excluir])
    ) {
      return;
    }

    const excluir = await exibirConfirmacao(
      "Confirmar exclusão",
      obterMensagemExclusao(registro)
    );

    if (excluir) {
      try {
        const resposta = await LoteServico.Excluir(registro.id);

        if (resposta) {
          checarResponseExibeMensagemExclusaoDeSucesso(
            resposta,
            GeradorMensagensNotificacao.ExcluidoComSucessoMasculino(
              "Lote/número de série"
            )
          );
          handleAtualizarGrid();
        }
      } catch (erro) {
        tratarErroApi(erro);
      }
    }
  }, []);

  function obterMensagemExclusao(registro: LoteGridModel) {
    return `Tem certeza de que deseja excluir o lote ${registro.loteDoFabricanteOuNumeroDeSerie}?`;
  }

  const gridController = useMemo(
    () => new GridController<LoteGridModel>(() => gridRef.current?.instance()),
    []
  );

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  const handleCallbackFormulario = useCallback(
    (resultado: ResultadoAcaoFormulario) => {
      encerrarEdicao();

      if (resultado == ResultadoAcaoFormulario.AcaoConcluida) {
        handleAtualizarGrid();
      }

      UrlUtils.RemoverParametroId(parametroId);
    },
    [parametroId]
  );

  const handleFecharModal = useCallback(() => {
    encerrarEdicao();
    UrlUtils.RemoverParametroId(parametroId);
  }, [parametroId]);

  const handleCancelarLote = useCallback(
    async (registro: LoteGridModel) => {
      await cancelarLoteHandler(registro, handleAtualizarGrid);
    },
    [handleAtualizarGrid]
  );

  const handleCancelarLotes = useCallback(
    async (registros: LoteGridModel[]) => {
      const result = await cancelarLotesHandler(registros, handleAtualizarGrid);

      if (result) {
        const { accordionVisivel, mensagem } = result;
        setModalAcaoLotesAccordionVisivel(accordionVisivel);
        setDetalhamentoAcaoLotesAccordion({
          model: mensagem,
        });
      }
    },
    [handleAtualizarGrid]
  );

  const handleFecharModalAccordion = useCallback(
    (
      setModalAccordionVisivel: (value: React.SetStateAction<boolean>) => void,
      setDetalhamentoModalAccordion: (
        value: React.SetStateAction<AccordionDetalhadoDataSource | undefined>
      ) => void
    ) => {
      setModalAccordionVisivel(false);
      setDetalhamentoModalAccordion(undefined);
    },
    []
  );

  const criarBoletimDeInspecaoItemDoMenuDeContexto = useCallback(() => {
    return {
      text: NomesMenuLote.acoes.criarBoletimDeInspecao,
      icon: "ic-material-symbols-outlined ic-lab-profile",
      gestorEventoClick: new GestorEventoClickUnicaLinha(
        (data) => {
          criarBoletimDeInspecao(data.id, data.loteDoFabricanteOuNumeroDeSerie);
        },
        () => gridController
      ),
    };
  }, [gridController]);

  const menusDeContexto: ItemContextMenuMxp[] = useMemo(() => {
    return [
      {
        text: "Imprimir",
        icon: "ic-material-symbols-outlined ic-print",
        gestorEventoClick: new GestorEventoClickUnicaLinha(
          (data) => {
            abrirModalDetalhesDoLoteMxp1(data.id);
          },
          () => gridController
        ),
        exibirNaLinhaDaGrid: "sempre",
        exibirNoMenuPrincipal: false,
      },
      {
        text: NomesMenuLote.acoes.cancelarLoteNumeroDeSerie,
        icon: "ic-material-symbols-outlined ic-cancel",
        exibirNaLinhaDaGrid: "menuDeContexto",
        exibirNoMenuPrincipal: false,
        gestorEventoClick: new GestorEventoClickUnicaLinha(
          (registro) => handleCancelarLote(registro),
          () => gridController
        ),
      },
      {
        ...criarBoletimDeInspecaoItemDoMenuDeContexto(),
        exibirNaLinhaDaGrid: "menuDeContexto",
      },
      {
        text: "Ações",
        icon: "ic-material-symbols-outlined ic-vertical",
        exibirNoMenuPrincipal: true,
        items: [
          {
            text: NomesMenuLote.acoes.importarLotesDeArquivoExcel,
            icon: "ic-material-symbols-outlined ic-file-upload",
            gestorEventoClick: new GestorEventoClickSimples(() =>
              setImportarVisivel(true)
            ),
          },
          {
            text: NomesMenuLote.acoes.cancelarLoteNumeroDeSerie,
            icon: "ic-material-symbols-outlined ic-cancel",
            gestorEventoClick: new GestorEventoClickMultiplasLinhas(
              (registros) => handleCancelarLotes(registros),
              () => gridController
            ),
          },
          {
            ...criarBoletimDeInspecaoItemDoMenuDeContexto(),
          },
        ],
      },
    ];
  }, [gridController]);

  useRegistrarAtalhosGrid<LoteGridModel>({
    controller: gridController,
    handleNovo: handleNovoRegistro,
    handleEditar: handleEditarRegistro,
    handleExcluir: handleExcluirRegistro,
  });

  const handleImportarExcel = useCallback(
    async (registros: LoteInserirVariosExcelRequest[]) => {
      const resultado = await LoteServico.InserirEmMassaExcel(registros);
      handleAtualizarGrid();
      return resultado;
    },
    [LoteServico]
  );

  const menuContexto = useMenuDeContextosGrid(
    menusDeContexto,
    true,
    gridController
  );

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar(
      "lote",
      () => gridRef.current?.instance(),
      false,
      props.filtrosNoCliente
    )
      .definirDataSource(dataSource)
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirFiltros()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(NomesTelas.lotes)
      .definirBotaoNovo(handleNovoRegistro)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirSelecao()
      .definirOrdenacao()
      .definirMenuDeContexto(menuContexto)
      .definirDuploCliqueLinha(handleEditarRegistro)
      .build();
  }, [props.filtrosNoCliente, props.filtrosNoServidor, menuContexto]);

  return (
    <>
      <ProvedorAjuda id={"tooltips-grid-lote"}>
        <DataGrid ref={gridRef} {...configuracoesGrid}>
          <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
          {GridColunaAcoes<LoteGridModel>({
            handleEditar: handleEditarRegistro,
            handleExcluir: handleExcluirRegistro,
          })}
          <Column
            key={nameOfGridHandler("item")}
            dataField={nameOfGridHandler("item")}
            {...obterConfiguracaoColuna("stringM")}
            caption="Item"
          />
          <Column
            key={nameOfGridHandler("descricao")}
            dataField={nameOfGridHandler("descricao")}
            {...obterConfiguracaoColuna("stringGG")}
            caption="Descrição"
          />
          <Column
            key={nameOfGridHandler("fabricante")}
            dataField={nameOfGridHandler("fabricante")}
            {...obterConfiguracaoColuna("stringGG")}
            caption="Fabricante"
          />
          <Column
            key={nameOfGridHandler("loteDoFabricanteOuNumeroDeSerie")}
            dataField={nameOfGridHandler("loteDoFabricanteOuNumeroDeSerie")}
            {...obterConfiguracaoColuna("stringGG")}
            caption="Lote/número de série do fabricante"
            alignment="right"
          />
          <Column
            key={nameOfGridHandler("estocagem")}
            dataField={nameOfGridHandler("estocagem")}
            {...obterConfiguracaoColuna("stringM")}
            caption="Estocagem"
            visible={false}
          />
          <Column
            key={nameOfGridHandler("reanalise")}
            dataField={nameOfGridHandler("reanalise")}
            {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
            caption="Reanálise"
            visible={false}
            width={115}
          />
          <Column
            key={nameOfGridHandler("vencimento")}
            dataField={nameOfGridHandler("vencimento")}
            {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
            caption="Vencimento"
            width={115}
          />
          <Column
            key={nameOfGridHandler("fabricacao")}
            dataField={nameOfGridHandler("fabricacao")}
            {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
            caption="Fabricação"
            visible={false}
            width={115}
          />
          <Column
            key={nameOfGridHandler("observacoes")}
            dataField={nameOfGridHandler("observacoes")}
            {...obterConfiguracaoColuna("stringGG")}
            caption="Observações"
          />
          <Column
            key={nameOfGridHandler("observacoesInternas")}
            dataField={nameOfGridHandler("observacoesInternas")}
            {...obterConfiguracaoColuna("stringGG")}
            caption="Observações internas"
            visible={false}
          />
          <Column
            key={nameOfGridHandler("estadoDecodificado")}
            dataField={nameOfGridHandler("estadoDecodificado")}
            {...obterConfiguracaoColuna("stringG")}
            caption="Estado"
          />
          {GetColunasDeAuditoria(true, false, [
            { ordenarPor: "criacaoData", ordem: "desc" },
          ])}
        </DataGrid>
      </ProvedorAjuda>

      <ModalMxp
        titulo={NormalizaTituloModal.Normalizar(
          idRegistroEmEdicao,
          NomesModais.lote
        )}
        idRegistro={idRegistroEmEdicao}
        visivel={modalVisivel}
        handleFechar={handleFecharModal}
        largura={"max(60vw, 800px)"}
        altura={"max(60vh, 625px)"}
      >
        <FormLote
          idRegistroEmEdicao={idRegistroEmEdicao}
          handleCallback={handleCallbackFormulario}
        />
      </ModalMxp>

      <ModalAccordionDetalhado
        modalAccordionVisivel={modalAcaoLotesAccordionVisivel}
        handlerFecharModalAccordion={() =>
          handleFecharModalAccordion(
            setModalAcaoLotesAccordionVisivel,
            setDetalhamentoAcaoLotesAccordion
          )
        }
        dataSource={detalhamentoAcaoLotesAccordion?.model}
        modalTitulo={
          detalhamentoAcaoLotesAccordion?.accordionTitulo ??
          JanelasDeNotificacaoTitulos.Atencao
        }
        accordionId="accordion-acao-lotes"
        itemDoAccordionAltura="auto"
      />

      <ImportadorDeRegistros
        titulo="Importar lotes de arquivo Excel"
        onFechar={() => setImportarVisivel(false)}
        nomeDaPlanilha="Lotes"
        schema={schema}
        visivel={importarVisivel}
        onImportar={handleImportarExcel}
        modeloUrl={"../modelosXlsx/Planilha_Modelo_Lotes_MAXIPROD.xlsx"}
        schemaYup={schemaYup}
      />
    </>
  );
}
