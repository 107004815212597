import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";

class AdicaoExclusaoCompensacaoApuracaoLalurIrpjService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.AdicaoExclusaoCompensacaoApuracaoLalurIrpj);
  }
}

export const AdicaoExclusaoCompensacaoApuracaoLalurIrpjServico =
  new AdicaoExclusaoCompensacaoApuracaoLalurIrpjService();
