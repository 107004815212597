import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";
import criarDatasourceGrid from "../../../../utils/grid/back-end-grid-utils";

class LancamentoNaParteBDoLacsService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.LancamentoNaParteBDoLacs);
  }

  public GetGridSourceFiltradaPorApuracao(idApuracao: number) {
    return criarDatasourceGrid(
      `${this._nomeEndpoint}/GridFiltradaPorApuracao?idApuracao=${idApuracao}`
    );
  }
}

export const LancamentoNaParteBDoLacsServico =
  new LancamentoNaParteBDoLacsService();
