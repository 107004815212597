import getApi from "../../../../configs/api";
import type {
  ResponseBase,
  ResponseModel,
} from "../../../../models/api/comum/response-base";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";
import { checarResponse, tratarErroApi } from "../../../../utils/api/api-utils";
import type { InserirReservaParaOrdemDeProducaoRequest } from "../models/reserva.api";
import { ReservaSelecionarGridModel } from "../models/selecionar-reservas";

class ReservaService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.Reserva);
  }

  public async InserirReservaOrdemProducaoAsync(
    request: InserirReservaParaOrdemDeProducaoRequest
  ) {
    const api = getApi();
    const response = await api.post<ResponseBase>(
      `${this._nomeEndpoint}/CriarParaOrdemDeProducao`,
      request
    );

    return response.data;
  }

  public async ObterQuantidadeDisponivelParaOrdemDeProducao(
    id: number
  ): Promise<ResponseModel<number>> {
    const api = getApi();
    const response = await api.get<ResponseModel<number>>(
      `${this._nomeEndpoint}/ObterQuantidadeDisponivelParaOrdemDeProducao?id=${id}`
    );

    return response.data;
  }

  public async ObterReservasParaSelecionarPorIdOrdemDeProducao(
    ordemDeProducaoId: number | undefined,
    quantidade: number
  ) {
    if (!ordemDeProducaoId) {
      return undefined;
    }

    const api = getApi();
    try {
      const response = await api.get<
        ResponseModel<ReservaSelecionarGridModel[]>
      >(
        `${this._nomeEndpoint}/ObterReservasASelecionarGridPorOrdemDeProducaoId?ordemDeProducaoId=${ordemDeProducaoId}`
      );

      checarResponse(response.data);

      const reservas = response.data.model.map(
        (x) => x as ReservaSelecionarGridModel
      );
      let quantidadeParaEstornar = quantidade;
      reservas.forEach((x) => {
        x.quantidadeConcluir = Math.min(x.quantidade, quantidadeParaEstornar);
        quantidadeParaEstornar -= x.quantidadeConcluir;
      });

      return reservas;
    } catch (error) {
      tratarErroApi(error);
    }

    return undefined;
  }
}

export const ReservaServico = new ReservaService();
