import getApi from "../../configs/api";
import { ResponseModel } from "../../models/api/comum/response-base";
import {
  DadosInicializacaoFiltros as DadosConfiguracoesDeEmpresa,
  DadosConfiguracoesEstoque,
  DadosConfiguracoesMdfe,
} from "../../models/api/tokens/configuracoes";
import { NomesEndpoints } from "../comum/nomesEndpoints";

class ConfiguracoesService {
  protected _nomeEndpoint: NomesEndpoints = NomesEndpoints.Configuracoes;

  constructor() {
    this._nomeEndpoint = NomesEndpoints.Configuracoes;
  }

  public async ObterConfiguracoesMdfe() {
    const api = getApi();
    const response = await api.get<ResponseModel<DadosConfiguracoesMdfe>>(
      `${this._nomeEndpoint}/ConfiguracoesMdfe`
    );

    return response.data.model;
  }

  public async ObterConfiguracoesDeEmpresa() {
    const api = getApi();
    const response = await api.get<ResponseModel<DadosConfiguracoesDeEmpresa>>(
      `${this._nomeEndpoint}/ConfiguracoesDeEmpresa`
    );

    return response.data.model;
  }

  public async ObterConfiguracoesDeEstoque() {
    const api = getApi();
    const response = await api.get<ResponseModel<DadosConfiguracoesEstoque>>(
      `${this._nomeEndpoint}/ConfiguracoesDeEstoque`
    );

    return response.data.model;
  }
}

export const ConfiguracoesServico = new ConfiguracoesService();
