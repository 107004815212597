import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "devextreme-react/button";
import {
  Column,
  DataGrid,
  DataGridRef,
  GroupItem,
  Summary,
} from "devextreme-react/data-grid";
import ArrayStore from "devextreme/data/array_store";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import {
  FormCheckBox,
  FormDateBox,
} from "../../../../../components/formularios";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import PaginaMxp from "../../../../../components/layout/pagina-mxp";
import PainelFiltros from "../../../../../components/layout/painel-filtros";
import TextoEntreCampos from "../../../../../components/layout/texto-entre-campos";
import ComboContaContabilMxp from "../../../../../components/mxp/inputs-custom/conta-contabil";
import RequerPermissao from "../../../../../components/seguranca/requer-permissao";
import { ConfiguracoesServico } from "../../../../../services/configuracoes/configuracoes.service";
import store from "../../../../../store";
import { tratarErroApi } from "../../../../../utils/api/api-utils";
import criarNameof from "../../../../../utils/common/cria-name-of";
import { obterDataLimite } from "../../../../../utils/common/date-utils";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import {
  verificaComNotificacaoSeUsuarioPossuiPermissoes,
  verificaSeUsuarioPossuiPermissoes,
} from "../../../../../utils/common/permissoes-utils";
import { formatarNumero } from "../../../../../utils/formatadores/formatador-de-numeros";
import {
  FormataDescricao,
  FormatadoresSelectBox,
} from "../../../../../utils/formatadores/formatador-de-selectbox";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import yup from "../../../../../utils/validacao/validacao";
import MultiSelectMinhasEmpresas from "../../../../sistema/empresa/componentes/multi-select-minhas-empresas";
import { EmpresaServico } from "../../../../sistema/empresa/servicos/empresa.service";
import { ContainerGrid } from "../../../balancete-comparativo/componentes/container-grid/styles";
import {
  FiltrosRazaoPorIntervaloDeContasViewModel,
  RazaoGridModel,
} from "../../models/razao-por-intervalo-de-contas.api";
import RazaoPorIntervaloDeContasConstantes from "../../models/razao-por-intervalo-de-contas.constantes";
import { PermissoesRazaoPorIntervaloDeContas } from "../../models/razao-por-intervalo-de-contas.permissoes";
import { RazaoPorIntervaloDeContasServico } from "../../servicos/razao-por-intervalo-de-contas.servico";

const filtroPadrao: FiltrosRazaoPorIntervaloDeContasViewModel = {
  contaContabilInicialId: null,
  contaContabilFinalId: null,
  dataInicial: null,
  dataFinal: null,
  considerarLancamentosDeEncerramento: true,
  minhasEmpresasIds: null,
};

const nameof = criarNameof<RazaoGridModel>();

export default function RazaoPorIntervaloDeContasPage() {
  const gridRef = useRef<DataGridRef>(null);
  const possuiPermissaoExportar = verificaSeUsuarioPossuiPermissoes(
    [PermissoesRazaoPorIntervaloDeContas.Exportar],
    "todas"
  );
  const schema = yup.object().shape({
    dataInicial: yup
      .string()
      .required()
      .test(
        "data_inicial_valida",
        "A data inicial está maior do que a final.",
        function (value) {
          const dtFinStr = getValues().dataFinal as string | null;
          if (value && dtFinStr) {
            const dataInicial = new Date(value);
            const dataFinal = new Date(dtFinStr);
            return dataInicial <= dataFinal;
          }
          return true;
        }
      )
      .test(
        "data_inicial_no_intervalo",
        `Período máximo de ${RazaoPorIntervaloDeContasConstantes.PeriodoMaximoEmAnos} anos.`,
        function (valor) {
          const dtFinStr = getValues().dataFinal as string | null;
          if (valor && dtFinStr) {
            const dataInicial = new Date(valor);
            const dataFinal = new Date(dtFinStr);
            const limite = obterDataLimite(
              dataInicial,
              RazaoPorIntervaloDeContasConstantes.PeriodoMaximoEmMeses
            );
            return dataFinal <= limite;
          }
          return true;
        }
      ),
    dataFinal: yup
      .string()
      .required()
      .test(
        "data_final_valida",
        "A data final está menor do que a inicial.",
        function (valor) {
          const dtIniStr = getValues().dataInicial as string | null;
          if (valor && dtIniStr) {
            const dataInicial = new Date(dtIniStr);
            const dataFinal = new Date(valor);
            return dataInicial <= dataFinal;
          }
          return true;
        }
      )
      .test(
        "data_final_no_intervalo",
        `Período máximo de ${RazaoPorIntervaloDeContasConstantes.PeriodoMaximoEmAnos} anos.`,
        function (valor) {
          const dtIniStr = getValues().dataInicial as string | null;
          if (valor && dtIniStr) {
            const dataInicial = new Date(dtIniStr);
            const dataFinal = new Date(valor);
            const limite = obterDataLimite(
              dataInicial,
              RazaoPorIntervaloDeContasConstantes.PeriodoMaximoEmMeses
            );
            return dataFinal <= limite;
          }
          return true;
        }
      ),
    contaContabilFinalId: yup.string().notRequired(),
    contaContabilInicialId: yup.string().required(),
    considerarLancamentosDeEncerramento: yup.boolean().required(),
    minhasEmpresasIds: yup.array(),
  });

  useEffect(() => {
    carregarDados();
    InicializarCampoMinhaEmpresa();
  }, []);

  async function carregarDados() {
    reset(filtroPadrao);
    gridRef.current
      ?.instance()
      .option("dataSource", new ArrayStore({ data: [] }));
  }

  async function InicializarCampoMinhaEmpresa() {
    const responseConfiguracoes =
      await ConfiguracoesServico.ObterConfiguracoesDeEmpresa();

    if (responseConfiguracoes.idEmpresaInicializacao) {
      setValue("minhasEmpresasIds", [
        responseConfiguracoes.idEmpresaInicializacao,
      ]);
      return;
    }

    const responseMinhasEmpresas =
      await EmpresaServico.ObterMinhasEmpresasQueOUsuarioLogadoPossuiAPermissao(
        PermissoesRazaoPorIntervaloDeContas.Consultar
      );

    const isMaster =
      store.getState().sessao.dadosSessao?.usuario.isUsuarioMaster ?? false;

    if (
      responseMinhasEmpresas.sucesso &&
      responseMinhasEmpresas.model &&
      !isMaster
    ) {
      setValue("minhasEmpresasIds", responseMinhasEmpresas.model);
    }
  }

  const { control, handleSubmit, getValues, reset, setValue, trigger } =
    useForm<FiltrosRazaoPorIntervaloDeContasViewModel>({
      mode: "onChange",
      reValidateMode: "onChange",
      resolver: yupResolver(schema),
    });

  const handleBuscar = useCallback(async () => {
    try {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesRazaoPorIntervaloDeContas.Consultar,
        ])
      ) {
        return;
      }

      const filtros = getValues();
      const response = await RazaoPorIntervaloDeContasServico.obterRegistros(
        filtros
      );
      gridRef.current?.instance().option(
        "dataSource",
        new ArrayStore({
          data: response.model,
        })
      );
    } catch (erro) {
      tratarErroApi(erro);
    }
  }, [getValues]);

  function revalidaDataFinal() {
    trigger("dataFinal");
  }

  function revalidaDataInicial() {
    trigger("dataInicial");
  }

  const configuracoesGrid = useMemo(() => {
    let builder = GridBuilder.criar(
      "razao-por-intervalo-de-contas",
      () => gridRef.current?.instance(),
      false,
      []
    )
      .definirStyles({ height: "100%" })
      .definirOperacoesNoLadoDoCliente()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao([50, 100, 200, 400], 400);

    if (possuiPermissaoExportar) {
      builder = builder.configurarExportacao(
        NomesTelas.razaoPorIntervaloDeContas,
        undefined,
        false
      );
    }
    return builder.build();
  }, [possuiPermissaoExportar]);

  return (
    <PaginaMxp
      id={"razao-por-intervalo-de-contas-page"}
      titulo={"Razão por intervalo de contas"}
      area={["Contabilidade"]}
    >
      <div>
        <RequerPermissao
          codigoPermissoes={[PermissoesRazaoPorIntervaloDeContas.Consultar]}
        >
          <PainelFiltros>
            <ProvedorAjuda id={"razao-por-intervalo-de-contas"}>
              <form onSubmit={handleSubmit(handleBuscar)}>
                <Linha>
                  <Coluna md={3}>
                    <ComboContaContabilMxp
                      control={control}
                      titulo={"Conta inicial"}
                      name={"contaContabilInicialId"}
                      incluirContasSinteticas
                    />
                  </Coluna>
                  <Coluna md={3}>
                    <ComboContaContabilMxp
                      control={control}
                      titulo={"Conta final"}
                      name={"contaContabilFinalId"}
                      requerido={false}
                      incluirContasSinteticas
                    />
                  </Coluna>
                  <Coluna md={4}>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "50%" }}>
                        <FormDateBox
                          control={control}
                          name="dataInicial"
                          titulo="Período"
                          requerido
                          aceitaValorCustomizado={true}
                          aceitaDigitacaoComMascara={true}
                          onChange={revalidaDataFinal}
                        />
                      </div>
                      <TextoEntreCampos>a</TextoEntreCampos>
                      <div style={{ marginLeft: "0.2em", width: "50%" }}>
                        <FormDateBox
                          control={control}
                          name="dataFinal"
                          aceitaValorCustomizado={true}
                          aceitaDigitacaoComMascara={true}
                          onChange={revalidaDataInicial}
                        />
                      </div>
                    </div>
                  </Coluna>
                  <Coluna md={2} centralizar>
                    <FormCheckBox
                      control={control}
                      titulo="Incluir lançamentos de encerramento"
                      name="considerarLancamentosDeEncerramento"
                    />
                  </Coluna>
                </Linha>
                <Linha>
                  <Coluna md={3}>
                    <MultiSelectMinhasEmpresas
                      control={control}
                      name={"minhasEmpresasIds"}
                    />
                  </Coluna>
                  <Coluna md={9}>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <div style={{ marginLeft: "auto", paddingTop: "1em" }}>
                        <Button
                          text="Filtrar"
                          type="success"
                          icon="filter"
                          useSubmitBehavior
                        />
                      </div>
                    </div>
                  </Coluna>
                </Linha>
              </form>
            </ProvedorAjuda>
          </PainelFiltros>
          <ContainerGrid>
            <DataGrid ref={gridRef} {...configuracoesGrid}>
              <Column
                dataField={nameof("contaContabilId")}
                key={nameof("contaContabilId")}
                caption="Conta"
                {...obterConfiguracaoColuna("stringGG")}
                groupIndex={0}
                calculateDisplayValue={(t: RazaoGridModel) => {
                  return FormataDescricao(
                    FormatadoresSelectBox.ClassificacaoCodigoDescricaoEspacoParenteses,
                    t.codigoEstruturadoContaContabil,
                    t.descricaoContaContabil,
                    t.codigoContaContabil
                  );
                }}
                allowSorting={false}
                showInColumnChooser={false}
              />
              <Column
                dataField={nameof("registroDeSaldoInicial")}
                key={nameof("registroDeSaldoInicial")}
                visible={false}
                sortIndex={0}
                sortOrder="desc"
                allowSorting={false}
                showInColumnChooser={false}
              />
              <Column
                dataField={nameof("data")}
                key={nameof("data")}
                {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
                allowSorting={false}
              />
              <Column
                dataField={nameof("numero")}
                key={nameof("numero")}
                {...obterConfiguracaoColuna("codigoNumerico")}
                caption="Nº do lançamento"
                allowSorting={false}
                width={130}
              />
              <Column
                dataField={nameof("linha")}
                key={nameof("linha")}
                {...obterConfiguracaoColuna("codigoNumerico")}
                caption="Linha"
                allowSorting={false}
              />
              <Column
                dataField={nameof("historico")}
                key={nameof("historico")}
                {...obterConfiguracaoColuna("stringGG")}
                width={600}
                caption="Histórico"
                allowSorting={false}
              />
              <Column
                dataField={nameof("valorDebito")}
                key={nameof("valorDebito")}
                {...obterConfiguracaoColuna("monetarioNulavel")}
                caption="Débito"
                allowSorting={false}
              />
              <Column
                dataField={nameof("valorCredito")}
                key={nameof("valorCredito")}
                {...obterConfiguracaoColuna("monetarioNulavel")}
                caption="Crédito"
                allowSorting={false}
              />
              <Column
                dataField={nameof("saldo")}
                key={nameof("saldo")}
                {...obterConfiguracaoColuna("monetarioBalanceteCD")}
                caption="Saldo"
                allowSorting={false}
              />
              <Column
                dataField={nameof("apelidoMinhaEmpresa")}
                key={nameof("apelidoMinhaEmpresa")}
                {...obterConfiguracaoColuna("stringGG")}
                caption="Empresa"
                allowSorting={false}
              />

              <Summary>
                <GroupItem
                  column={nameof("valorCredito")}
                  summaryType="sum"
                  showInGroupFooter
                  customizeText={(value) =>
                    formatarNumero(Number(value.value), 2)
                  }
                />
                <GroupItem
                  column={nameof("valorDebito")}
                  summaryType="sum"
                  showInGroupFooter
                  customizeText={(value) =>
                    formatarNumero(Number(value.value), 2)
                  }
                />
                {/* Por algum motivo, mesmo o saldo não estando configurado para fazer o sum,
            o devexpress colocava zero. Então forçei exibir nada */}
                <GroupItem
                  column={nameof("saldo")}
                  summaryType="sum"
                  showInGroupFooter
                  customizeText={() => ""}
                />
              </Summary>
            </DataGrid>
          </ContainerGrid>
        </RequerPermissao>
      </div>
    </PaginaMxp>
  );
}
