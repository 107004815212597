export function formatarTemplate(template: string, ...args: any[]) {
  return template.replace(/{(\d+)}/g, (match, number) => {
    return typeof args[number] != "undefined" ? args[number] : match;
  });
}

export function funcaoOrdernarAlfabeticamente(
  a: string | null | undefined,
  b: string | null | undefined
) {
  if ((a?.toLowerCase() ?? "") < (b?.toLowerCase() ?? "")) {
    return -1;
  }

  if ((a?.toLowerCase() ?? "") > (b?.toLowerCase() ?? "")) {
    return 1;
  }

  return 0;
}

export function capitalizarPrimeiraLetra(texto: string): string {
  if (!texto) return texto;
  return texto.charAt(0).toUpperCase() + texto.slice(1);
}

export function descapitalizarPrimeiraLetra(texto: string): string {
  if (!texto) return texto;
  return texto.charAt(0).toLowerCase() + texto.slice(1);
}
