import parseToHtml from "html-react-parser";
import { QuadroAmbienteHomologacaoCommon } from "../formulario/styles";

export default function QuadroAmbienteHomologacao() {
  const iconeRodaDentada: string =
    '<i class="fa-solid fa-gear" style="color: #a8a8a6"></i>';
  return (
    <>
      <QuadroAmbienteHomologacaoCommon>
        {parseToHtml(
          `Você está utilizando o ambiente de homologação do MDF-e. Os MDF-e´s enviados neste ambiente 
            não possuem validade jurídica. Para alterar o ambiente para produção, na tela principal acesse 
            o menu ${iconeRodaDentada} > Configurações, clique em 
            Configurações de NFS-e/MDF-e" > "Configurações para o MDF-e", altere o "Ambiente para o MDF-e" 
            para "Produção" e clique em "Salvar".`
        )}
      </QuadroAmbienteHomologacaoCommon>
    </>
  );
}
