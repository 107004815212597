import { useContext, useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import {
  FormCheckBox,
  FormDateBox,
  FormNumberBox,
  FormSelectBox,
  FormSelectBoxLazyMxp,
  FormTextBox,
} from "../../../../../../components/formularios";
import {
  assertConfiguracaoExibicaoEBuscaType,
  ItemSelectionChangedType,
} from "../../../../../../components/formularios/selectbox-lazy-mxp";
import FormSelectBoxSimples from "../../../../../../components/formularios/selectbox-simples";
import { Coluna, Linha } from "../../../../../../components/layout/grid-system";
import { useCarregarCombos } from "../../../../../../hooks/form.hooks";
import { NotaFiscalRecebidaSituacao } from "../../../../../../models/api/nota-fiscal-recebida/nota-fiscal-recebida";
import { PermissoesNotaFiscalRecebida } from "../../../../../../models/permissoes/compras/nota-fiscal-recebida/permissoes-nota-fiscal-recebida";
import SelectItem from "../../../../../../models/shared/ui/select-item";
import { UnidadeDeMedidaServico } from "../../../../../../services/unidade-medida/unidade-medida.service";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../../utils/api/api-utils";
import { previneDigitacaoDeCaracteres } from "../../../../../../utils/common/common-utils";
import { checarSeFormFoiTocado } from "../../../../../../utils/common/form-utils";
import { digitosNumericosSemEspaco } from "../../../../../../utils/common/regex-padrao";
import { obterFormatStringNumero } from "../../../../../../utils/formatadores/formatador-de-numeros";
import {
  FormataDescricao,
  FormatadoresSelectBox,
} from "../../../../../../utils/formatadores/formatador-de-selectbox";
import { formatarCpfCnpj } from "../../../../../../utils/formatadores/formatador-de-strings";
import { DataSourceFiltragem } from "../../../../../../utils/grid/data-source-factory";
import GridItemNotaFiscalRecebidaComImpostos from "../../../../../compras/item-nota-fiscal-recebida/componentes/grid";
import { ItemNotaFiscalRecebidaGridComImpostosGridModel } from "../../../../../compras/item-nota-fiscal-recebida/models/item-nota-fiscal-recebida";
import { ItemNotaFiscalRecebidaServico } from "../../../../../compras/item-nota-fiscal-recebida/servicos/item-nota-fiscal-recebida.service";
import ComboEmpresaMxp from "../../../../../sistema/empresa/componentes/select-box-lazy";
import { ValorOpcaoServico } from "../../../../../sistema/valor-opcao/servicos/valor-opcao.service";
import ImobilizadoConstantes from "../../../models/imobilizado.constantes";
import {
  EstadoDoCiapDoImobilizado,
  SelectItemEnumEstadoDoCiapDoImobilizado,
} from "../../../models/imobilizado.enums";
import { ImobilizadoViewModel } from "../../../models/imobilizado.viewmodel";
import { ContextoModelosDocumentos55e67CadastroImobilizado } from "../../formulario";

interface AbaCiapImobilizadoProps {
  hookForm: UseFormReturn<ImobilizadoViewModel, any, undefined>;
  idRegistroEdicao: number;
}

const previneDigitacaoDeCaracteresHandler = (e: any) =>
  previneDigitacaoDeCaracteres(e, digitosNumericosSemEspaco);

const filtrosItemNotaFiscalRecebida: DataSourceFiltragem<ItemNotaFiscalRecebidaGridComImpostosGridModel>[] =
  [
    {
      campo: "situacaoNota",
      operador: "=",
      valor: NotaFiscalRecebidaSituacao.Recebida,
    },
    {
      campo: "tipoDocumentoFiscalEhDaTabelaDoSped",
      operador: "=",
      valor: true,
    },
  ];

const itemNotaFiscalRecebidaDataSource =
  ItemNotaFiscalRecebidaServico.ObterDataSourceParaSelectBoxLazy<ItemNotaFiscalRecebidaGridComImpostosGridModel>(
    {
      camposRetorno: [
        "id",
        "sequenciaDoItemNaNota",
        "dataEmissaoNota",
        "numeroNota",
        "chaveAcessoNota",
        "serieNota",
        "apelidoFornecedorNota",
        "fornecedorIdNota",
        "tipoDocumentoFiscalId",
        "unidadeId",
        "baseDeCalculoIcms",
        "aliquotaIcms",
        "valorIcms",
        "valorIcmsSt",
        "valorIcmsDifal",
        "quantidade",
        "descricao",
        "codigoItem",
        "cpfCnpjFornecedorNota",
      ],
      camposOrdenacao: [
        {
          campo: "dataEmissaoNota",
          desc: true,
        },
      ],
      camposFiltro: [
        {
          campo: "situacaoNota",
          operador: "=",
          valor: NotaFiscalRecebidaSituacao.Recebida,
        },
        {
          campo: "tipoDocumentoFiscalEhDaTabelaDoSped",
          operador: "=",
          valor: true,
        },
      ],
    }
  );

const configuracoesExibicaoSelectItemNotaFiscalRecebida =
  assertConfiguracaoExibicaoEBuscaType<ItemNotaFiscalRecebidaGridComImpostosGridModel>(
    {
      expressaoDeBusca: [
        "numeroNota",
        "serieNota",
        "sequenciaDoItemNaNota",
        "apelidoFornecedorNota",
        "cpfCnpjFornecedorNota",
        "dataEmissaoNota",
        "codigoItem",
        "descricao",
      ],
      nomeCampoChave: "id",
      nomeCampoExibicao: (c) => {
        if (c) {
          return FormataDescricao(
            FormatadoresSelectBox.ItemNF,
            c.numeroNota,
            c.serieNota ? `/${c.serieNota}` : "",
            c.sequenciaDoItemNaNota,
            c.apelidoFornecedorNota,
            c.cpfCnpjFornecedorNota
              ? `(${formatarCpfCnpj(c.cpfCnpjFornecedorNota ?? "")})`
              : "",
            c.codigoItem,
            c.descricao
          );
        }

        return "";
      },
    }
  );

export default function AbaCiapImobilizado({
  hookForm,
  idRegistroEdicao,
}: AbaCiapImobilizadoProps) {
  const { control, watch, setValue, getValues, formState } = hookForm;
  const { modelos, setModelos } = useContext(
    ContextoModelosDocumentos55e67CadastroImobilizado
  );
  const [unidadesMedida, setUnidadesMedida] = useState<SelectItem[]>([]);
  const [tiposDocumentosFiscais, setTiposDocumentosFiscais] = useState<
    SelectItem[]
  >([]);

  useEffect(() => {
    const itemId = watch("itemId");

    filtrosItemNotaFiscalRecebida.forEach((item, index) => {
      if (item.campo === "itemId") {
        filtrosItemNotaFiscalRecebida.splice(index, 1);
      }
    });

    if (itemId) {
      filtrosItemNotaFiscalRecebida.push({
        campo: "itemId",
        operador: "=",
        valor: itemId,
      });
    }

    itemNotaFiscalRecebidaDataSource.filter(
      filtrosItemNotaFiscalRecebida.map((x) => [x.campo, x.operador, x.valor])
    );

    if (itemNotaFiscalRecebidaDataSource.isLoaded()) {
      itemNotaFiscalRecebidaDataSource.reload();
    }
  }, [watch("itemId")]);

  const chaveAcessoRequerida = modelos.some(
    (x) => x == watch("ciapDoImobilizado.tipoDeDocumentoFiscalId")
  );

  useCarregarCombos(idRegistroEdicao, carregarCombos);

  async function carregarCombos() {
    await carregarUnidadesMedidas();
    await carregarTiposDeDocumentosFiscais();
  }

  async function carregarUnidadesMedidas() {
    try {
      const resposta = await UnidadeDeMedidaServico.ObterListaSimples();
      checarResponse(resposta);
      setUnidadesMedida(
        resposta.model.map((x) => ({
          valor: x.valor,
          descricao: x.descricao,
        }))
      );
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  async function carregarTiposDeDocumentosFiscais() {
    try {
      const resposta =
        await ValorOpcaoServico.ObterListaSimplesDoTiposDeDocumentosFiscaisDoIcmsParaOSped();
      checarResponse(resposta);
      setTiposDocumentosFiscais(
        resposta.model.map((x) => ({
          valor: x.valor,
          descricao: x.descricao,
        }))
      );
      setModelos(
        resposta.model
          .filter(
            (x) => x.descricao.startsWith("55") || x.descricao.startsWith("57")
          )
          .map((x) => x.valor)
      );
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  const camposSomenteLeitura = !watch("sujeitoAoCiap");

  function impedirEdicaoDasParcelasApropriadas() {
    if (idRegistroEdicao == 0) {
      return camposSomenteLeitura;
    }

    const ciap = getValues("ciapDoImobilizado");

    if (ciap) {
      const valores = Object.values(ciap);
      const bloquear = valores.some((x) => x !== null);
      return (bloquear && hookForm.formState.isValid) || camposSomenteLeitura;
    }

    return !(!ciap && !camposSomenteLeitura);
  }

  function obterEstadoDoCiap() {
    const parcelas = watch("ciapDoImobilizado.parcelas");
    const parcelasApropriadas = watch("ciapDoImobilizado.parcelasApropriadas");

    if (parcelasApropriadas == 0) {
      return EstadoDoCiapDoImobilizado.NaoIniciado;
    } else if (parcelasApropriadas > 0 && parcelasApropriadas < parcelas) {
      return EstadoDoCiapDoImobilizado.EmAndamento;
    } else if (parcelasApropriadas == parcelas) {
      return EstadoDoCiapDoImobilizado.Concluido;
    }
  }

  function InicializarCamposAPartirDoItemDaNotaFiscal(
    e: ItemSelectionChangedType<ItemNotaFiscalRecebidaGridComImpostosGridModel>
  ) {
    if (!checarSeFormFoiTocado(formState)) {
      return;
    }

    if (!e.selectedItem) {
      return;
    }

    setValue("ciapDoImobilizado.dataEmissao", e.selectedItem.dataEmissaoNota);
    setValue("ciapDoImobilizado.numero", e.selectedItem.numeroNota ?? "");
    setValue("ciapDoImobilizado.chaveAcesso", e.selectedItem.chaveAcessoNota);
    setValue("ciapDoImobilizado.serie", e.selectedItem.serieNota);
    setValue("ciapDoImobilizado.quantidade", e.selectedItem.quantidade);
    setValue("ciapDoImobilizado.fornecedorId", e.selectedItem.fornecedorIdNota);
    setValue(
      "ciapDoImobilizado.tipoDeDocumentoFiscalId",
      e.selectedItem.tipoDocumentoFiscalId
    );
    setValue("ciapDoImobilizado.unidadeId", e.selectedItem.unidadeId);
    setValue(
      "ciapDoImobilizado.baseCalculoIcms",
      e.selectedItem.baseDeCalculoIcms ?? 0
    );
    setValue("ciapDoImobilizado.aliquotaIcms", e.selectedItem.aliquotaIcms);
    setValue("ciapDoImobilizado.valorIcms", e.selectedItem.valorIcms);
    setValue("ciapDoImobilizado.valorIcmsSt", e.selectedItem.valorIcmsSt);
    setValue("ciapDoImobilizado.valorIcmsDifal", e.selectedItem.valorIcmsDifal);
  }

  return (
    <>
      <div style={{ marginTop: "5px" }}></div>
      <Linha>
        <Coluna md={4} centralizar>
          <FormCheckBox
            titulo={"Sujeito ao CIAP"}
            name={"sujeitoAoCiap"}
            control={control}
          />
        </Coluna>
      </Linha>
      <Linha>
        <Coluna md={10}>
          <FormSelectBoxLazyMxp
            name="ciapDoImobilizado.itemNotaFiscalId"
            titulo="Item da nota fiscal recebida"
            control={control}
            requerido
            dataSource={itemNotaFiscalRecebidaDataSource}
            configuracoesExibicaoEBusca={
              configuracoesExibicaoSelectItemNotaFiscalRecebida
            }
            somenteLeitura={camposSomenteLeitura}
            labelSemDados="Sem dados"
            seletorConfig={{
              modo: "selecaoUnica",
              titulo: "Selecionar item da nota fiscal recebida",
              componenteGrid: (
                <GridItemNotaFiscalRecebidaComImpostos
                  filtrosNoServidor={filtrosItemNotaFiscalRecebida}
                />
              ),
            }}
            onSelectionChanged={InicializarCamposAPartirDoItemDaNotaFiscal}
            permissoesNecessarias={[PermissoesNotaFiscalRecebida.Consultar]}
          />
        </Coluna>
        <Coluna md={2}>
          <FormSelectBoxSimples
            titulo="Estado"
            dataSource={SelectItemEnumEstadoDoCiapDoImobilizado}
            requerido
            valor={obterEstadoDoCiap()}
            somenteLeitura
          />
        </Coluna>
      </Linha>
      <Linha>
        <Coluna md={5}>
          <FormSelectBox
            name="ciapDoImobilizado.tipoDeDocumentoFiscalId"
            titulo="Documento fiscal"
            control={control}
            dataSource={tiposDocumentosFiscais}
            habilitaBusca
            requerido
            somenteLeitura={camposSomenteLeitura}
          />
        </Coluna>
        <Coluna md={7}>
          <FormTextBox
            name="ciapDoImobilizado.chaveAcesso"
            titulo="Chave de acesso"
            control={control}
            tamanhoMaximo={ImobilizadoConstantes.ChaveDeAcessoTamanhoMaximo}
            onKeyDown={previneDigitacaoDeCaracteresHandler}
            somenteLeitura={camposSomenteLeitura}
            requerido={chaveAcessoRequerida}
            onChange={(chaveAcesso: string) => {
              setValue(
                "ciapDoImobilizado.numero",
                chaveAcesso.substring(26, 34)
              );
              setValue(
                "ciapDoImobilizado.serie",
                chaveAcesso.substring(23, 24)
              );
            }}
          />
        </Coluna>
      </Linha>
      <Linha>
        <Coluna md={2}>
          <FormTextBox
            name="ciapDoImobilizado.numero"
            titulo="Número"
            control={control}
            tamanhoMaximo={ImobilizadoConstantes.NumeroTamanhoMaximo}
            requerido
            somenteLeitura={camposSomenteLeitura}
          />
        </Coluna>
        <Coluna md={2}>
          <FormTextBox
            name="ciapDoImobilizado.serie"
            titulo="Série"
            control={control}
            tamanhoMaximo={ImobilizadoConstantes.SerieTamanhoMaximo}
            somenteLeitura={camposSomenteLeitura}
          />
        </Coluna>
        <Coluna md={3}>
          <FormDateBox
            name="ciapDoImobilizado.dataEmissao"
            titulo="Emissão"
            control={control}
            requerido
            aceitaValorCustomizado={true}
            aceitaDigitacaoComMascara={true}
            somenteLeitura={camposSomenteLeitura}
          />
        </Coluna>
        <Coluna md={5}>
          <ComboEmpresaMxp
            name="ciapDoImobilizado.fornecedorId"
            titulo="Remetente"
            control={control}
            requerido
            tituloSeletor="Selecionar remetente"
            somenteLeitura={camposSomenteLeitura}
          />
        </Coluna>
      </Linha>
      <Linha>
        <Coluna md={4}>
          <div style={{ display: "flex" }}>
            <div style={{ width: "70%" }}>
              <FormNumberBox
                name="ciapDoImobilizado.quantidade"
                titulo="Quantidade"
                control={control}
                formato={obterFormatStringNumero(2)}
                requerido
                somenteLeitura={camposSomenteLeitura}
                minimo={0}
              />
            </div>
            <div style={{ marginLeft: "0.2em", width: "50%" }}>
              <FormSelectBox
                name="ciapDoImobilizado.unidadeId"
                control={control}
                dataSource={unidadesMedida}
                somenteLeitura={camposSomenteLeitura}
              />
            </div>
          </div>
        </Coluna>
        <Coluna md={3}>
          <FormNumberBox
            name="ciapDoImobilizado.baseCalculoIcms"
            titulo="Base de cálculo do ICMS"
            control={control}
            requerido
            formato={obterFormatStringNumero(2)}
            somenteLeitura={camposSomenteLeitura}
            minimo={0}
          />
        </Coluna>
        <Coluna md={2}>
          <FormNumberBox
            name="ciapDoImobilizado.aliquotaIcms"
            titulo="Alíquota do ICMS"
            control={control}
            formato={obterFormatStringNumero(2)}
            somenteLeitura={camposSomenteLeitura}
            minimo={ImobilizadoConstantes.AliquotaICMS.Minimo}
            maximo={ImobilizadoConstantes.AliquotaICMS.Maximo}
          />
        </Coluna>
        <Coluna md={3}>
          <FormNumberBox
            name="ciapDoImobilizado.valorIcms"
            titulo="Valor do ICMS"
            control={control}
            formato={obterFormatStringNumero(2)}
            somenteLeitura={camposSomenteLeitura}
            minimo={0}
          />
        </Coluna>
      </Linha>
      <Linha>
        <Coluna md={3}>
          <FormNumberBox
            name="ciapDoImobilizado.valorIcmsSt"
            titulo="Valor do ICMS ST"
            control={control}
            formato={obterFormatStringNumero(2)}
            somenteLeitura={camposSomenteLeitura}
            minimo={0}
          />
        </Coluna>
        <Coluna md={3}>
          <FormNumberBox
            name="ciapDoImobilizado.valorIcmsFrete"
            titulo="Valor do ICMS do frete"
            control={control}
            formato={obterFormatStringNumero(2)}
            somenteLeitura={camposSomenteLeitura}
            minimo={0}
          />
        </Coluna>
        <Coluna md={3}>
          <FormNumberBox
            name="ciapDoImobilizado.valorIcmsDifal"
            titulo="Valor do ICMS DIFAL"
            control={control}
            formato={obterFormatStringNumero(2)}
            somenteLeitura={camposSomenteLeitura}
            minimo={0}
          />
        </Coluna>
      </Linha>
      <Linha>
        <Coluna md={3}>
          <FormNumberBox
            name="ciapDoImobilizado.parcelas"
            titulo="Parcelas"
            control={control}
            formato={obterFormatStringNumero(0)}
            requerido
            somenteLeitura={camposSomenteLeitura}
            minimo={ImobilizadoConstantes.ParcelasEVidaUtilEmMeses.Minimo}
            maximo={ImobilizadoConstantes.ParcelasEVidaUtilEmMeses.Maximo}
          />
        </Coluna>
        <Coluna md={3}>
          <FormNumberBox
            name="ciapDoImobilizado.parcelasApropriadas"
            titulo="Parcelas apropriadas"
            control={control}
            formato={obterFormatStringNumero(0)}
            requerido={idRegistroEdicao == 0}
            somenteLeitura={impedirEdicaoDasParcelasApropriadas()}
            minimo={ImobilizadoConstantes.ParcelasEVidaUtilEmMeses.Minimo}
            maximo={ImobilizadoConstantes.ParcelasEVidaUtilEmMeses.Maximo}
          />
        </Coluna>
      </Linha>
    </>
  );
}
