import DataGrid, { Column, DataGridRef } from "devextreme-react/data-grid";
import { useCallback, useMemo, useRef } from "react";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import GridColunaAcoes from "../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import { useRegistrarAtalhosGrid } from "../../../../../hooks/atalhos.hooks";
import { useMenuDeContextosGrid } from "../../../../../hooks/menus.hooks";
import { useSeletorGridMxp } from "../../../../../hooks/seletor.hooks";
import { useAppDispatch } from "../../../../../hooks/store.hooks";
import { decodificaBooleanoEmSimNao } from "../../../../../models/api/comum/decodificadores";
import { PermissoesUsuario } from "../../../../../models/permissoes/seguranca/usuario/permissoes-usuario";
import GetColunasDeAuditoria from "../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import { exibirModalGerenciamentoTokensAcessoUsuario } from "../../../../../store/usuario/usuario.slice";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import { ItemContextMenuMxp } from "../../../../../utils/context-menu/context-menu-utils";
import { GestorEventoClickUnicaLinha } from "../../../../../utils/context-menu/gestor-evento-click";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import { TiposTokenAcesso } from "../../../login/models/login.enums";
import { UsuarioGridModel } from "../../models/usuario";
import { UsuarioServico } from "../../servicos/usuario.service";
import ModalTokenAcessoUsuario from "../formulario";

const dataSource = UsuarioServico.ObterDataSourceParaGrid<UsuarioGridModel>([
  {
    campo: "ativo",
    operador: "=",
    valor: true,
  },
]);

const nameOfGridHandler = criarNameof<UsuarioGridModel>();

export default function GridUsuario() {
  const gridRef = useRef<DataGridRef>(null);

  const dispatch = useAppDispatch();

  useSeletorGridMxp(() => gridRef.current);

  const gerenciarTokenAcesso = useCallback(
    async (registro: UsuarioGridModel) => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesUsuario.GerenciarTokensAcessoApi,
        ])
      ) {
        return;
      }

      if (registro.id) {
        dispatch(
          exibirModalGerenciamentoTokensAcessoUsuario({
            idUsuario: registro.id,
            tipo: TiposTokenAcesso.GraphQl,
          })
        );
      }
    },
    []
  );

  const gridController = useMemo(
    () =>
      new GridController<UsuarioGridModel>(() => gridRef.current?.instance()),
    []
  );

  const acoesDeContexto: ItemContextMenuMxp[] = useMemo(() => {
    return [
      {
        text: "Token de acesso API GraphQL",
        icon: "fa fa-key",
        exibirNaLinhaDaGrid: "menuDeContexto",
        exibirNoMenuPrincipal: false,
        gestorEventoClick: new GestorEventoClickUnicaLinha(
          gerenciarTokenAcesso,
          () => gridController
        ),
      },
      {
        text: "Ações",
        icon: "ic-material-symbols-outlined ic-vertical",
        exibirNoMenuPrincipal: true,
        items: [
          {
            text: "Token de acesso API GraphQL",
            icon: "fa fa-key",
            gestorEventoClick: new GestorEventoClickUnicaLinha(
              gerenciarTokenAcesso,
              () => gridController
            ),
          },
        ],
      },
    ];
  }, [gridController]);

  useRegistrarAtalhosGrid<UsuarioGridModel>({
    controller: gridController,
  });

  useMenuDeContextosGrid(acoesDeContexto);

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar("usuario", () => gridRef.current?.instance())
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(NomesTelas.usuarios)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirSelecao()
      .definirOrdenacao()
      .definirMenuDeContexto(acoesDeContexto)
      .build();
  }, []);

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  useRegistrarAtalhosGrid<UsuarioGridModel>({
    controller: gridController,
  });

  return (
    <>
      <ProvedorAjuda id={"tooltips-grid-usuario"}>
        <DataGrid ref={gridRef} {...configuracoesGrid}>
          <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
          {GridColunaAcoes<UsuarioGridModel>({})}
          <Column
            key={nameOfGridHandler("apelido")}
            dataField={nameOfGridHandler("apelido")}
            {...obterConfiguracaoColuna("stringM")}
            caption="Apelido"
          />
          <Column
            key={nameOfGridHandler("nome")}
            dataField={nameOfGridHandler("nome")}
            {...obterConfiguracaoColuna("stringG")}
            caption="Nome"
          />
          <Column
            key={nameOfGridHandler("email")}
            dataField={nameOfGridHandler("email")}
            {...obterConfiguracaoColuna("stringG")}
            caption="E-mail"
          />
          <Column
            key={nameOfGridHandler("tipoAcesso")}
            dataField={nameOfGridHandler("tipoAcesso")}
            {...obterConfiguracaoColuna("stringM")}
            caption="Tipo de acesso"
          />
          <Column
            key={nameOfGridHandler("ativo")}
            dataField={nameOfGridHandler("ativo")}
            {...obterConfiguracaoColuna("boolSimNao")}
            caption="Ativo"
            visible={false}
            cellRender={({ data }) => {
              const dados = data as UsuarioGridModel;
              return decodificaBooleanoEmSimNao(dados.ativo);
            }}
          />
          {GetColunasDeAuditoria()}
        </DataGrid>
      </ProvedorAjuda>

      {/*Modal token acesso*/}
      <ModalTokenAcessoUsuario />
    </>
  );
}
