export function adicionarMeses(data: Date, meses: number) {
  const novaData = new Date(data);
  novaData.setMonth(data.getMonth() + meses);
  return novaData;
}

export function obterDataLimite(data: Date, meses: number) {
  return new Date(data.getFullYear(), data.getMonth() + meses, data.getDate());
}

export function mesPorExtenso(mes: number | string) {
  const ordem = typeof mes === "string" ? parseInt(mes) : mes;

  const meses = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  return meses[ordem - 1];
}

export class DateTimeMxp {
  private static timeZoneOffSetDeBrasilia = 180;

  public static Now() {
    const data = this.NowInternal();

    if (data.getTimezoneOffset() == this.timeZoneOffSetDeBrasilia) {
      return data;
    }

    return this.ConverterDataParaFusoBrasiliaInternal(data);
  }

  public static NowEmIso() {
    let data = this.Now();

    data = this.ConverterDataParaFusoBrasiliaInternal(data);

    return this.ConverterDateEmIso(data);
  }

  public static ConverterDataParaFusoBrasilia(dataString: string) {
    const data = new Date(dataString);

    if (data.getTimezoneOffset() == this.timeZoneOffSetDeBrasilia) {
      return data;
    }

    return this.ConverterDataParaFusoBrasiliaInternal(data);
  }

  private static ConverterDateEmIso(data: Date) {
    // Formata para o padrão yyyy-MM-ddTHH:mm:ss
    return data.toISOString().slice(0, 19);
  }

  private static ConverterDataParaFusoBrasiliaInternal(data: Date) {
    // Ajusta para o fuso horário de Brasília (UTC-3)
    return new Date(data.getTime() - data.getTimezoneOffset() * 60000);
  }

  private static NowInternal() {
    return new Date();
  }
}
