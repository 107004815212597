import SelectItem from "../../../../models/shared/ui/select-item";
import {
  gerarLookupDoDicionario,
  gerarSelectItemDoDicionario,
} from "../../../../utils/common/enum-utils";

export enum TipoDeEstocagemDoItem {
  CodigoDoItem = 78, // 'N'
  LoteInterno = 73, // 'I'
  LoteDoFabricante = 76, // 'L'
  NumeroDeSerie = 83, // 'S'
}

// Classe que encapsula o enum e suas descrições
export class EstocagemTipoHelper {
  public static tipoDescricao: {
    [key in TipoDeEstocagemDoItem]: string;
  } = {
    [TipoDeEstocagemDoItem.CodigoDoItem]: "Código do item",
    [TipoDeEstocagemDoItem.LoteInterno]: "Lote interno",
    [TipoDeEstocagemDoItem.LoteDoFabricante]: "Lote do fabricante",
    [TipoDeEstocagemDoItem.NumeroDeSerie]: "Número de série",
  };

  public static getDescricao(tipo: TipoDeEstocagemDoItem): string {
    return this.tipoDescricao[tipo] || "Descrição desconhecida";
  }

  public static AsSelectItems(): SelectItem[] {
    return gerarSelectItemDoDicionario(this.tipoDescricao);
  }

  public static AsLookup() {
    return gerarLookupDoDicionario(this.tipoDescricao);
  }
}

export enum Estado {
  Ativo = "Ativo",
  Inativo = "Inativo",
  Digitacao = "Digitação",
}

export enum ProcedenciaDecodificadaTipo {
  Comprado = "Comprado",
  Fabricado = "Fabricado",
  Conjunto = "Conjunto",
  Alternativo = "Alternativo",
  Agregado = "Fabricado agregado",
  ServicoManutencao = "Serviço/manutenção",
}
