import {
  ConfiguracoesErros,
  TipoErro,
} from "../../../../../models/api/comum/erros";
import { formataMensagemDeErroComoElementoHtmlDivPadrao } from "../../../../../utils/erros/erro.utils";
import { ErroEstoqueMovimentacao } from "./estoque-movimentacao.erros";

export const configuracoesErrosEstoqueMovimentacao = Object.freeze<
  ConfiguracoesErros<ErroEstoqueMovimentacao>
>({
  "MSG-005-0001": {
    tipo: TipoErro.PopUpAgrupado,
    formatador({ dadosExtras }) {
      //Como a mensagem do back vem com <br /> na string, é necessário fazer um split para quebrar a mensagem em linhas
      return (
        <div>
          {dadosExtras.map((resultado) => (
            <p key={resultado.movimentacaoId}>
              {resultado.mensagem.split("<br />").map((item, index) => (
                <span key={index}>
                  {item}
                  {index < resultado.mensagem.split("<br />").length - 1 && (
                    <br />
                  )}
                </span>
              ))}
            </p>
          ))}
        </div>
      );
    },
  },
  "MSG-005-0002": {
    tipo: TipoErro.PopUpAgrupado,
    formatador(erro) {
      return formataMensagemDeErroComoElementoHtmlDivPadrao(erro);
    },
  },
});
