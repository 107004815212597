import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../common/notificacoes-utils";

export default class UrlUtils {
  public static RemoverParametroId(id?: number) {
    const matches = location.pathname.match(/[^/]+$/);

    if (Number(matches?.[0]) == id) {
      window.history.replaceState(
        null,
        "",
        location.pathname.substring(0, location.pathname.lastIndexOf("/"))
      );
    }
  }

  public static GerarECopiarUrlEdicaoEntidade<T>(registro: T) {
    // @ts-expect-error: "registro: T" possui id.
    this.GerarECopiarUrlEdicao(registro.id);
  }

  public static GerarECopiarUrlEdicao(id?: number) {
    const urlCompleto = this.GerarUrlEdicao(id);

    if (urlCompleto.length == 0) {
      return;
    }

    navigator.clipboard.writeText(urlCompleto);

    exibirNotificacaoToast({
      mensagem: "Link copiado para a área de transferência.",
      tipo: TipoNotificacao.Informacao,
    });
  }

  public static GerarUrlEdicao(id?: number) {
    if (Number.isNaN(id)) {
      return "";
    }

    let fullURL = window.location.href;

    const matches = fullURL.match(/[^/]+$/);

    if (Number(matches?.[0]) == id || matches?.[0] === "novo") {
      fullURL = fullURL.substring(0, fullURL.lastIndexOf("/"));
    }

    fullURL = `${fullURL}/${id === 0 ? "novo" : id}`;

    return fullURL;
  }
}
