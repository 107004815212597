import { DataGridTypes } from "devextreme-react/data-grid";
import NumberBox, {
  NumberBoxRef,
  NumberBoxTypes,
} from "devextreme-react/number-box";
import ArrayStore from "devextreme/data/array_store";
import { custom } from "devextreme/ui/dialog";
import { useCallback, useContext, useEffect, useRef } from "react";
import { renderToString } from "react-dom/server";
import styled from "styled-components";
import { LinkButton } from "../../../../../components/templates-celulas-grid/celula-controle-edicao/styles";
import { GridNumberBoxProps } from "../../../../../components/templates-celulas-grid/inputs/number-box/number-box-grid.edit";
import { EstoqueGridModelSelecionar } from "../../../../../models/api/estoque/estoque";
import { PermissoesInsumosDasOrdensDeProducao } from "../../../../../models/permissoes/producao/insumo-da-ordem-de-producao/InsumoDaOrdemDeProducaoPermissoes";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import exibirNotificacaoToast, {
  GeradorMensagensNotificacao,
  JanelasDeNotificacaoTitulos,
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import { exibirAlerta, exibirConfirmacao } from "../../../../../utils/dialogos";
import { formatarNumeroQuantidade } from "../../../../../utils/formatadores/formatador-de-numeros";
import { renderToStringClient } from "../../../../../utils/react/react-utils";
import {
  InsumoDaOrdemDeProducaoGridModel,
  ObterDescricaoInsumo,
  UsaRastreabilidadeMasNaoSelecionouNS,
} from "../../models/insumo-da-ordem-de-producao";
import { InsumoDaOrdemDeProducaoServico } from "../../servicos/insumo-da-ordem-de-producao";
import { InsumoEstado } from "../../utils/enum/insumo-da-ordem-de-producao-enums";
import ContextoOperacoesInsumo from "../contexto-funcoes-insumo";
import {
  ConfirmacaoBaixarQuantidadeAcimaDaEsperadaComEstoquesIndisponiveis,
  ConfrimacaoBaixarQuantidadeAcimaDaEsperada,
} from "../modal-baixar-insumo";

const focarBaixaTrue = true;
const forcarBaixaFalse = false;

const mensagemBaixaParcial = (
  quantidadeABaixar: number,
  unidade: string,
  codigoDescricao: string
) => {
  return (
    <div style={{ maxWidth: "50vw" }}>
      <p>
        Você está baixando uma quantidade parcial de{" "}
        {formatarNumeroQuantidade(quantidadeABaixar)} {unidade} do item &quot;
        {codigoDescricao}&quot;.
      </p>
      <p>O que deseja fazer?</p>
    </div>
  );
};

const mensagemBaixarInsumosTerceirosNaoTotalmenteReservados = () => {
  return (
    <>
      <p>
        Este insumo é fornecido pelo cliente, mas a quantidade desta ordem de
        produção não está totalmente reservada para pedidos de um único cliente.
      </p>

      <p>Tem certeza de que deseja continuar com a baixa deste insumo?</p>

      <p>
        Esta verificação pode ser desativada em &quot;Configurações &gt;
        Planejamento e produção &gt;{" "}
        <b>
          Permitir a baixa de insumos de terceiros para OP que não estiver
          totalmente reservada para pedidos deste terceiro
        </b>
        &quot;.
      </p>
    </>
  );
};

const insumoNaoEstaBaixadoCompletamente = (
  data: DataGridTypes.ColumnEditCellTemplateData<
    InsumoDaOrdemDeProducaoGridModel,
    number
  >
) => {
  return (
    data.data != undefined &&
    data.data.estado != InsumoEstado.BaixadoCompletamente
  );
};

const ColunaBaixarInsumo = styled.div`
  display: flex;
  align-items: center;
  margin-block: -5px;
`;

export const QuantidadeABaixarEditavelComponent = ({
  data,
}: {
  data: DataGridTypes.ColumnEditCellTemplateData<
    InsumoDaOrdemDeProducaoGridModel,
    number
  >;
}) => {
  const numberBoxRef = useRef<NumberBoxRef>(null);

  const onValueChanged = useCallback((e: NumberBoxTypes.ValueChangedEvent) => {
    marcarInputComoInvalido();
    data.setValue(e.value);
  }, []);

  const {
    setRegistroEstornar,
    setModalBaixarInsumosProps,
    setInsumoPorNumeroDeSerie,
    atualizaGridsAoFechar: atualizaGridDeInsumos,
  } = useContext(ContextoOperacoesInsumo).funcoes;

  function getDados() {
    return data.data;
  }

  function marcarInputComoInvalido() {
    const deveEstarInvalido =
      (getDadosAtualizados()?.quantidadeABaixar ?? 0) >
      (getDadosAtualizados()?.estoqueDisponivel ?? 0);

    const instance = numberBoxRef.current?.instance();

    if (!instance) {
      return;
    }

    if (deveEstarInvalido) {
      instance.element().style.backgroundColor = "#ffe4e4";

      instance.element().title =
        "Fundo rosa: não há quantidade suficiente em estoque.";
    } else {
      instance.element().style.backgroundColor = "";
      instance.element().title = "";
    }
  }

  useEffect(() => {
    marcarInputComoInvalido();
  }, []);

  function getDadosAtualizados() {
    const keyComNumeroDeSerie = {
      id: data.data?.id,
      loteDoFabricanteOuNumeroDeSerieId:
        data.data?.loteDoFabricanteOuNumeroDeSerieId,
    };

    const visibleRows = data.component.getVisibleRows();

    const colunaEditada = visibleRows.find((x) =>
      typeof x.key === "number"
        ? x.key === keyComNumeroDeSerie.id
        : JSON.stringify(x.key) === JSON.stringify(keyComNumeroDeSerie)
    );
    const colunaEditataValor = colunaEditada?.data;

    // Se nenhuma coluna foi editada, retornar
    if (colunaEditada == undefined) {
      return;
    }

    // Cancelar edicao da celula mantendo o valor antigo
    const colunaEditadaAntiga = (colunaEditada as any).oldData;
    if (colunaEditadaAntiga && colunaEditataValor) {
      colunaEditadaAntiga.quantidadeABaixar =
        colunaEditataValor.quantidadeABaixar;
      data.component.closeEditCell();
      data.component.cancelEditData();
    }

    return colunaEditataValor;
  }

  const onClickBaixarInsumo = useCallback(() => {
    confirmarBaixarInsumos(getDadosAtualizados());
  }, [data]);

  const onClickForcarBaixaInsumo = useCallback(async () => {
    const confirmacao = await exibirConfirmacao(
      JanelasDeNotificacaoTitulos.Atencao,
      "Tem certeza de que deseja concluir a baixa deste insumo?"
    );

    if (!confirmacao) {
      return;
    }

    forcarBaixaInsumos(getDadosAtualizados());
  }, [data]);

  const onClickEstornarBaixaInsumo = useCallback(() => {
    estornarInsumo(getDados());
  }, [data]);

  async function forcarBaixaInsumos(dados?: InsumoDaOrdemDeProducaoGridModel) {
    if (!dados) {
      exibirNotificacaoToast({
        mensagem:
          "Não foi possivel encontrar o insumo selecionado para forçar a baixa.",
        tipo: TipoNotificacao.Erro,
      });
      return;
    }
    try {
      const resposta =
        await InsumoDaOrdemDeProducaoServico.ForcarConclusaoDeInsumo(dados?.id);

      checarResponse(resposta);
      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: GeradorMensagensNotificacao.RealizadoComSucessoFeminino(
            "Conclusão da baixa do insumo"
          ),
          tipo: TipoNotificacao.Sucesso,
        });
      }

      await InsumoDaOrdemDeProducaoServico.ForcarConclusaoDeInsumo(dados?.id);
      atualizaGridDeInsumos();
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  const MostrarSelecaoDeNumeroDeSerie = (
    dados: InsumoDaOrdemDeProducaoGridModel
  ) => {
    setInsumoPorNumeroDeSerie(dados);
  };

  async function estornarInsumo(dados?: InsumoDaOrdemDeProducaoGridModel) {
    if (!dados) {
      exibirNotificacaoToast({
        mensagem:
          "Não foi possivel encontrar o insumo selecionado para estornar.",
        tipo: TipoNotificacao.Erro,
      });
      return;
    }

    if (
      !verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesInsumosDasOrdensDeProducao.DesfazerEstornarBaixas,
      ])
    ) {
      return;
    }

    if (
      (dados.estado == InsumoEstado.ABaixar ||
        dados.estado == InsumoEstado.BaixadoParcialmente) &&
      dados.isProcedenciaAlternativo
    ) {
      const mensagem = renderToStringClient(
        `O insumo ${dados.codigo} (${dados.descricao}) possui a procedência "Alternativo" e não foi baixado. Por isso, a ação desejada não pode ser realizada para esse insumo.`
      );
      exibirAlerta(JanelasDeNotificacaoTitulos.Atencao, mensagem);
      return;
    }

    if (
      UsaRastreabilidadeMasNaoSelecionouNS(dados) &&
      dados.quantidadeBaixada > 0
    ) {
      MostrarSelecaoDeNumeroDeSerie(dados);
      return;
    }

    setRegistroEstornar(dados);
  }

  async function BaixarInsumos(
    dados: InsumoDaOrdemDeProducaoGridModel,
    forcar: boolean
  ) {
    if (forcar) {
      const confirmacao = await exibirConfirmacao(
        JanelasDeNotificacaoTitulos.Atencao,
        "Tem certeza de que deseja concluir a baixa deste insumo?"
      );

      if (!confirmacao) {
        return;
      }
    }

    try {
      const permiteBaixarInsumosTerceirosNaoTotalmenteReservados =
        await InsumoDaOrdemDeProducaoServico.VerificarSePermiteBaixarInsumosTerceirosNaoTotalmenteReservados(
          dados.id
        );

      if (permiteBaixarInsumosTerceirosNaoTotalmenteReservados == undefined) {
        return;
      }

      if (permiteBaixarInsumosTerceirosNaoTotalmenteReservados == false) {
        const confirmacao = await exibirConfirmacao(
          JanelasDeNotificacaoTitulos.Atencao,
          renderToStringClient(
            mensagemBaixarInsumosTerceirosNaoTotalmenteReservados()
          )
        );

        if (!confirmacao) {
          return;
        }
      }

      const resultado = await InsumoDaOrdemDeProducaoServico.BaixaAutomatica(
        dados.id,
        dados.quantidadeABaixar,
        dados.loteDoFabricanteOuNumeroDeSerieId,
        forcar
      );

      if (!resultado.sucesso) {
        return;
      }

      if (resultado.model.estoquesParaBaixar.length > 0) {
        setModalBaixarInsumosProps({
          popupVisivel: true,
          dados: new ArrayStore({
            data: resultado.model.estoquesParaBaixar,
            key: "id",
          }),
          quantidadeParaBaixar: dados.quantidadeABaixar,
          insumoId: dados.id,
          loteDoFabricanteOuNumeroDeSerieId:
            dados.loteDoFabricanteOuNumeroDeSerieId,
        });
      } else {
        if (resultado.model.quantidadeBaixada <= 0) {
          exibirNotificacaoToast({
            mensagem:
              "Não foi encontrado nenhum estoque disponível para baixar.",
            tipo: TipoNotificacao.Erro,
          });
          return;
        }

        exibirNotificacaoToast({
          mensagem:
            GeradorMensagensNotificacao.RealizadoComSucessoFeminino(
              "Baixa de insumo"
            ),
          tipo: TipoNotificacao.Sucesso,
        });
        atualizaGridDeInsumos();
      }
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  async function confirmarBaixarInsumos(
    dados?: InsumoDaOrdemDeProducaoGridModel
  ) {
    if (!dados) {
      exibirNotificacaoToast({
        mensagem: "Não foi possível carregar o insumo selecionado.",
        tipo: TipoNotificacao.Erro,
      });
      return;
    }

    const quantidadePrevista = dados.quantidadeTotal - dados.quantidadeBaixada;
    const quantidadeABaixar = dados.quantidadeABaixar;
    const unidade = dados.unidade ?? "un";
    const codigoDescricao = `${ObterDescricaoInsumo(dados)}`;

    if (quantidadeABaixar == 0) {
      exibirNotificacaoToast({
        mensagem: "A quantidade a baixar deve ser maior que zero.",
        tipo: TipoNotificacao.Advertencia,
      });
      return;
    }

    const usaRastreabilidadeMasNaoSelecionouNS =
      UsaRastreabilidadeMasNaoSelecionouNS(dados);
    if (usaRastreabilidadeMasNaoSelecionouNS) {
      MostrarSelecaoDeNumeroDeSerie(dados);
      return;
    }

    if (
      quantidadeABaixar < quantidadePrevista &&
      !dados.loteDoFabricanteOuNumeroDeSerieId
    ) {
      const mensagem = renderToStringClient(
        mensagemBaixaParcial(quantidadeABaixar, unidade, codigoDescricao)
      );

      const confirm = custom({
        title: "Confirmar baixa parcial",
        messageHtml: mensagem,
        buttons: [
          {
            text: "Baixar",
            onClick: () => BaixarInsumos(dados, forcarBaixaFalse),
          },
          ...(usaRastreabilidadeMasNaoSelecionouNS ||
          dados.loteDoFabricanteOuNumeroDeSerieId
            ? []
            : [
                {
                  text: "Baixar e forçar a conclusão do insumo",
                  onClick: () => BaixarInsumos(dados, focarBaixaTrue),
                },
              ]),
          { text: "Cancelar" },
        ],
      });

      confirm.show();
      return;
    }

    const estoquesResponse =
      await InsumoDaOrdemDeProducaoServico.obterEstoquesDisponiveisParaInsumo(
        dados.id ?? 0,
        quantidadeABaixar ?? 0
      );

    if (estoquesResponse.sucesso) {
      const estoquesDisponiveis =
        estoquesResponse.model as EstoqueGridModelSelecionar[];
      const quantidadeEstoquesDisponiveis = estoquesDisponiveis.reduce(
        (n, { quantidade }) => n + quantidade,
        0
      );

      if (quantidadeABaixar > quantidadePrevista) {
        const confirmacao = await exibirConfirmacao(
          JanelasDeNotificacaoTitulos.Atencao,
          renderToStringClient(
            ConfrimacaoBaixarQuantidadeAcimaDaEsperada(
              quantidadeABaixar,
              quantidadePrevista,
              codigoDescricao,
              unidade
            )
          )
        );

        if (!confirmacao) {
          return;
        }
      }

      if (
        estoquesDisponiveis.length > 0 &&
        quantidadeEstoquesDisponiveis < quantidadeABaixar
      ) {
        ExibirConfirmacaoSelecaoEstoqueAoBaixarInsumo(
          quantidadeABaixar,
          unidade,
          quantidadeEstoquesDisponiveis,
          estoquesDisponiveis,
          dados
        );
        return;
      }
    }
    BaixarInsumos(dados, forcarBaixaFalse);
  }

  const ExibirConfirmacaoSelecaoEstoqueAoBaixarInsumo = useCallback(
    async (
      quantidadeABaixar: number,
      unidade: string,
      quantidadeEstoquesDisponiveis: number,
      estoquesDisponiveis: any,
      insumo: InsumoDaOrdemDeProducaoGridModel
    ) => {
      const confirmacaoSelecionarEstoques = await exibirConfirmacao(
        JanelasDeNotificacaoTitulos.Atencao,
        renderToString(
          ConfirmacaoBaixarQuantidadeAcimaDaEsperadaComEstoquesIndisponiveis(
            quantidadeABaixar,
            unidade
          )
        )
      );

      if (!confirmacaoSelecionarEstoques) {
        return;
      }

      setModalBaixarInsumosProps({
        popupVisivel: true,
        dados: new ArrayStore({
          data: estoquesDisponiveis,
          key: "id",
        }),
        quantidadeParaBaixar: quantidadeEstoquesDisponiveis,
        insumoId: insumo.id,
        loteDoFabricanteOuNumeroDeSerieId:
          insumo.loteDoFabricanteOuNumeroDeSerieId,
      });
      return;
    },
    [setModalBaixarInsumosProps]
  );

  return (
    <ColunaBaixarInsumo>
      {insumoNaoEstaBaixadoCompletamente(data) && (
        <NumberBox
          ref={numberBoxRef}
          name="quantidadeABaixar"
          defaultValue={data.value}
          onValueChanged={onValueChanged}
          disabled={UsaRastreabilidadeMasNaoSelecionouNS(data?.data)}
          min={0}
          {...GridNumberBoxProps}
        />
      )}
      {!insumoNaoEstaBaixadoCompletamente(data) && (
        <div
          style={{
            width: "100%",
          }}
        ></div>
      )}
      <div style={{ width: "64px", display: "flex", flex: "none" }}>
        {insumoNaoEstaBaixadoCompletamente(data) && (
          <LinkButton onClick={onClickBaixarInsumo} title="Baixar">
            <i className="ic-material-symbols-outlined ic-success ic-arrow-download icone-linha-grid"></i>
          </LinkButton>
        )}
        {insumoNaoEstaBaixadoCompletamente(data) &&
          !data.data?.loteDoFabricanteOuNumeroDeSerieId && (
            <LinkButton
              onClick={onClickForcarBaixaInsumo}
              title="Concluir baixa"
            >
              <i className="ic-material-symbols-outlined ic-task-alt icone-linha-grid"></i>
            </LinkButton>
          )}
        {data.data != undefined && data.data.estado != InsumoEstado.ABaixar && (
          <LinkButton
            onClick={onClickEstornarBaixaInsumo}
            title="Desfazer/estornar baixa"
          >
            <i className="ic-material-symbols-outlined ic-undo icone-linha-grid"></i>
          </LinkButton>
        )}
      </div>
    </ColunaBaixarInsumo>
  );
};
