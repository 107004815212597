import criarNameof from "../../../../../utils/common/cria-name-of";
import { OrdemDeProducaoGridModel } from "../../models/ordem-de-producao.api";
import { OrdemDeProducaoEstado } from "../enums/ordem-de-producao-enums";

const nameof = criarNameof<OrdemDeProducaoGridModel>();

export default class FiltrosGridOrdemDeProducao {
  static readonly ordemDeProducaoEstado: any[] = [
    nameof("estado"),
    "anyof",
    [
      OrdemDeProducaoEstado.AProduzir,
      OrdemDeProducaoEstado.Sugerida,
      OrdemDeProducaoEstado.Suspensa,
    ],
  ];

  static readonly ordemDeProducaoEstadoAProduzir: any[] = [
    nameof("estado"),
    "=",
    OrdemDeProducaoEstado.AProduzir,
  ];
}
