import getApi from "../../configs/api";
import {
  IdStringResponse,
  ListaSimplesResponse,
  ResponseModel,
} from "../../models/api/comum/response-base";
import { NomesEndpoints } from "../comum/nomesEndpoints";

class UnidadeMedidaService {
  private _nomeEndpoint: NomesEndpoints;

  constructor() {
    this._nomeEndpoint = NomesEndpoints.UnidadeMedida;
  }

  public async obterIdsPorCodigos(codigos: string[]) {
    const api = getApi();
    const response = await api.post<ResponseModel<IdStringResponse[]>>(
      `${this._nomeEndpoint}/ObterIdsPorCodigos`,
      Array.from(new Set(codigos))
    );

    const map = {} as Record<string, number>;
    if (!response.data.sucesso) {
      return map;
    }
    for (const x of response.data.model) {
      map[x.valor] = x.id;
    }
    return map;
  }

  public async ObterListaSimples() {
    const response = await getApi().get<ListaSimplesResponse>(
      `${this._nomeEndpoint}/lista-simples`
    );

    return response.data;
  }
}

export const UnidadeDeMedidaServico = new UnidadeMedidaService();
