export enum RegimeTributarioTipo {
  SimplesNacional = 1,
  SimplesNacionalExcessoSublimiteReceitaBruta = 2,
  RegimeNormal = 3,
  LucroReal = 4,
  LucroPresumido = 5,
}

export enum PessoaFisicaOuJuridicaTipo {
  Fisica = 70,
  Juridica = 74,
}
