import { PermissoesEstoque } from "../../../../../../models/permissoes/estoque/estoque/permissoes-estoque";
import { navegarParaMxpAntigoEmNovaAbaComParametros } from "../../../../../../utils/common/menu-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../../utils/common/permissoes-utils";

async function consultarEstoqueEmElaboracao(ordemDeProducaoId: number) {
  if (
    !verificaComNotificacaoSeUsuarioPossuiPermissoes([
      PermissoesEstoque.Consultar,
    ])
  ) {
    return;
  }

  const rota = `/ItemEstoque`;
  const parametros = {
    visualizarEstoqueOutrasEmpresas: "True",
    idOrdemProducao: ordemDeProducaoId,
  };

  navegarParaMxpAntigoEmNovaAbaComParametros(rota, parametros);
}

export { consultarEstoqueEmElaboracao };
