import getApi from "../../../../../configs/api";
import { ResponseBase } from "../../../../../models/api/comum/response-base";
import { NomesEndpoints } from "../../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../../services/comum/serviceBase";

class EfdReinfApuracaoService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.EfdReinfApuracao);
  }

  public async RecalcularApuracao(id: number) {
    const api = getApi();
    const response = await api.post<ResponseBase>(
      `${this._nomeEndpoint}/Recalcular?id=${id}`
    );

    return response.data;
  }
}

export const EfdReinfApuracaoServico = new EfdReinfApuracaoService();
