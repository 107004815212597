import { HttpStatusCode } from "axios";

export const StatusCodesParaIgnorar: HttpStatusCode[] = [
  HttpStatusCode.Unauthorized,
  HttpStatusCode.PaymentRequired,
  HttpStatusCode.Forbidden,
  HttpStatusCode.Conflict,
  HttpStatusCode.UnprocessableEntity,
  HttpStatusCode.PreconditionRequired,
];
