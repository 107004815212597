import DataGrid, { Column, DataGridRef } from "devextreme-react/data-grid";
import { useMemo, useRef } from "react";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import { useSeletorGridMxp } from "../../../../../hooks/seletor.hooks";
import criarNameof from "../../../../../utils/common/cria-name-of";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import { GrupoDeCentroDeTrabalhoCalendarioGridModel } from "../../models/grupo-de-centro-de-trabalho-calendario";
import { GrupoDeCentroDeTrabalhoCalendarioServico } from "../../servicos/grupo-de-centro-de-trabalho-calendario.service";

const dataSource =
  GrupoDeCentroDeTrabalhoCalendarioServico.ObterDataSourceParaGrid();

const nameOfGridHandler =
  criarNameof<GrupoDeCentroDeTrabalhoCalendarioGridModel>();

export default function GridGrupoDeCentroDeTrabalhoCalendario() {
  const gridRef = useRef<DataGridRef>(null);

  useSeletorGridMxp(() => gridRef.current);

  const gridController =
    new GridController<GrupoDeCentroDeTrabalhoCalendarioGridModel>(() =>
      gridRef.current?.instance()
    );

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar("grupo-de-centro-de-trabalho-calendario", () =>
      gridRef.current?.instance()
    )
      .definirStyles({ height: "100%" })
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .definirSelecao()
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirOrdenacao()
      .build();
  }, []);

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  return (
    <>
      <ProvedorAjuda
        id={"tooltips-grid-grupo-de-centro-de-trabalho-calendario"}
      >
        <DataGrid ref={gridRef} {...configuracoesGrid}>
          <Column
            key={nameOfGridHandler("codigo")}
            dataField={nameOfGridHandler("codigo")}
            {...obterConfiguracaoColuna("stringP")}
            caption="Código"
          />
          <Column
            key={nameOfGridHandler("descricao")}
            dataField={nameOfGridHandler("descricao")}
            {...obterConfiguracaoColuna("stringM")}
            caption="Descrição"
          />
        </DataGrid>
      </ProvedorAjuda>
    </>
  );
}
