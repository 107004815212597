import { SelectBoxLazyOpcoes } from "../../../../../models/api/comum/selectboxlazy-options";
import { NomesEndpoints } from "../../../../../services/comum/nomesEndpoints";
import { criarDataSourceSelectBoxLazy } from "../../../../../utils/api/api-utils";
import criarDatasourceGrid from "../../../../../utils/grid/back-end-grid-utils";
import DataSourceFactory, {
  DataSourceFiltragem,
  DataSourceRawFilter,
} from "../../../../../utils/grid/data-source-factory";

class AdicaoExclusaoCompensacaoLacsCsllService {
  private _nomeEndpoint: NomesEndpoints;

  constructor() {
    this._nomeEndpoint = NomesEndpoints.AdicaoExclusaoCompensacaoLacsCsll;
  }

  public ObterDataSourceParaGrid<T, TKey = number>(
    filtroPadrao?: DataSourceFiltragem<T>[],
    filtroPadraoExato?: DataSourceRawFilter<T>
  ) {
    return DataSourceFactory.CriarParaGrid<T, TKey>(
      `${this._nomeEndpoint}/grid`,
      filtroPadrao,
      filtroPadraoExato
    );
  }

  public ObterDataSourceSelectBoxLazy(opcoes: SelectBoxLazyOpcoes) {
    const camposSelect = JSON.stringify(opcoes.camposRetorno);

    const dataSource = criarDatasourceGrid(
      `${this._nomeEndpoint}/grid?select=${camposSelect}`
    );

    return criarDataSourceSelectBoxLazy(dataSource, opcoes);
  }
}

export const AdicaoExclusaoCompensacaoLacsCsllServico =
  new AdicaoExclusaoCompensacaoLacsCsllService();
