import { Button } from "devextreme-react";
import { ButtonType } from "devextreme/common";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks/store.hooks";
import {
  abrirModalMxpAntigo,
  navegarParaMxpAntigoEmNovaAba,
} from "../../../utils/common/menu-utils";
import "./index.scss";

export default function AssinaturaEstadoSlide() {
  const [mostrarPopupEstadoAssinatura, setMostrarPopupEstadoAssinatura] =
    useState<boolean>(false);
  const [mensagem, setMensagem] = useState(<div></div>);

  const dadosAssinatura = useAppSelector(
    (state) => state.sessao.dadosSessao?.assinante
  );

  useEffect(() => {
    if (!dadosAssinatura) return;

    const estadoInvalido =
      dadosAssinatura.isInadimplenteAviso ||
      dadosAssinatura.isInadimplenteApenasConsulta ||
      dadosAssinatura.isCancelado ||
      dadosAssinatura.isPeriodoDeAvaliacao ||
      !dadosAssinatura.isAssinante;

    if (!estadoInvalido) return;

    setMostrarPopupEstadoAssinatura(true);

    const criarBotao = (
      texto: string,
      url: string,
      tipo: ButtonType = "normal"
    ) => (
      <Button
        className="btn-estado-assinatura"
        type={tipo}
        onClick={() => window.open(url, "_blank", "noopener,noreferrer")}
      >
        {texto}
      </Button>
    );

    const botoes = {
      assinar: (
        <Button
          className="btn-estado-assinatura"
          onClick={() => {
            navegarParaMxpAntigoEmNovaAba("/assinatura");
          }}
        >
          Assine agora
        </Button>
      ),
      demonstracao: criarBotao(
        "Solicite demonstração",
        "https://api.whatsapp.com/send?phone=5551993828270&text=Ol%C3%A1!%20Estou%20testando%20o%20ERP%20Maxiprod%20e%20desejo%20agendar%20uma%20demonstra%C3%A7%C3%A3o.",
        "success"
      ),
      faleConosco: criarBotao(
        "Fale conosco",
        "https://api.whatsapp.com/send?phone=5551993828270&text=Ol%C3%A1!%20Estou%20testando%20o%20ERP%20Maxiprod%20e%20gostaria%20de%20saber%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20sistema.",
        "success"
      ),
      renovarBoleto: criarBotao(
        "Atualizar o seu boleto",
        "https://banco.bradesco/html/classic/produtos-servicos/mais-produtos-servicos/segunda-via-boleto.shtm#segunda-via-boleto"
      ),
      faleConoscoInadimplencia: criarBotao(
        "Fale conosco",
        "https://api.whatsapp.com/send/?phone=555193406369&text=Gostaria+de+regularizar+meu+boleto&type=phone_number&app_absent=0",
        "success"
      ),
    };

    if (dadosAssinatura.isPeriodoDeAvaliacao) {
      const diasParaExpirar = () => {
        if (dadosAssinatura.diasParaAvaliacao === 0)
          return <strong>hoje</strong>;
        if (dadosAssinatura.diasParaAvaliacao === 1)
          return <strong>amanhã</strong>;
        return (
          <>
            em <strong>{dadosAssinatura.diasParaAvaliacao}</strong> dias
          </>
        );
      };

      setMensagem(
        <div id="periodoTeste">
          <span>Seu período de avaliação expira {diasParaExpirar()}.</span>
          {botoes.assinar}
          {botoes.demonstracao}
          {botoes.faleConosco}
        </div>
      );
    } else if (!dadosAssinatura.isAssinante) {
      setMensagem(
        <div id="periodoTeste">
          <table style={{ margin: "auto" }}>
            <tr>
              <td>
                <span>
                  Seu período de avaliação acabou. Para continuar utilizando o
                  ERP MAXIPROD, assine um de nossos planos.
                  {dadosAssinatura.autorizadoParaProrrogacaoPeriodoTeste && (
                    <>
                      <br />
                      Ainda não decidiu?{" "}
                      <a
                        onClick={() => {
                          abrirModalMxpAntigo(
                            {
                              rota: "ProrrogarPeriodoTeste",
                              tipo: "javaScriptMxpAntigo",
                            },
                            true
                          );
                        }}
                      >
                        Prorrogue seu teste por mais 2 dias.
                      </a>
                    </>
                  )}
                </span>
              </td>
              <td>
                {botoes.assinar}
                {botoes.demonstracao}
                {botoes.faleConosco}
              </td>
            </tr>
          </table>
        </div>
      );
    } else if (dadosAssinatura.isInadimplenteAviso) {
      setMensagem(
        <div id="periodoTeste">
          Sabemos que às vezes o boleto ou a linha digitável podem não estar
          facilmente à mão.
          {botoes.renovarBoleto}
          {botoes.faleConoscoInadimplencia}
        </div>
      );
    } else if (dadosAssinatura.isCancelado) {
      setMensagem(
        <div id="periodoTeste">
          <p>
            Sua assinatura foi cancelada, por isso seu acesso é somente de
            consulta. Entre em contato conosco para regularizar sua assinatura e
            voltar a utilizar o ERP MAXIPROD.
          </p>
        </div>
      );
    } else if (dadosAssinatura.isInadimplenteApenasConsulta) {
      setMensagem(
        <div id="periodoTeste">
          Você está inadimplente e seu acesso é somente de consulta. Regularize
          sua situação para continuar a utilizar o ERP MAXIPROD.
          {botoes.faleConoscoInadimplencia}
        </div>
      );
    }
  }, [dadosAssinatura]);

  return mostrarPopupEstadoAssinatura ? (
    <div className={"mxp-alerta mxp-estado-assinatura"}>
      <div className={"mxp-alerta-texto"}>{mensagem}</div>
    </div>
  ) : (
    <></>
  );
}
