import { Button } from "devextreme-react";

interface BotaoVisualizarPDFMxpProps {
  handleClick: () => void;
}

export default function BotaoVisualizarPDFMxp(
  props: BotaoVisualizarPDFMxpProps
) {
  return (
    <Button
      type="normal"
      icon="print"
      text="Visualizar PDF"
      onClick={props.handleClick}
    />
  );
}
