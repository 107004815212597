import { AxiosResponse } from "axios";
import getApi from "../../configs/api";
import { MinhaEmpresaListaRequest } from "../../features/sistema/empresa/models/minhas-empresas/minhas-empresas-request";
import { MinhaEmpresaItemResponse } from "../../features/sistema/empresa/models/minhas-empresas/minhas-empresas-response";
import {
  IdStringResponse,
  ListaSimplesResponse,
  ResponseModel,
} from "../../models/api/comum/response-base";
import { SelectBoxLazyOpcoes } from "../../models/api/comum/selectboxlazy-options";
import {
  criarDataSourceSelectBoxLazy,
  tratarErroApi,
} from "../../utils/api/api-utils";
import criarDatasourceGrid from "../../utils/grid/back-end-grid-utils";
import { NomesEndpoints } from "../comum/nomesEndpoints";
import APIBase from "../comum/serviceBase";

const nomeEndpoint = NomesEndpoints.Empresa;

async function getMinhasEmpresas(
  request: MinhaEmpresaListaRequest
): Promise<MinhaEmpresaItemResponse[] | undefined> {
  try {
    const api = getApi();
    const response = await api.post<
      MinhaEmpresaListaRequest,
      AxiosResponse<ResponseModel<MinhaEmpresaItemResponse[]>>
    >(`/${nomeEndpoint}/ListarMinhasEmpresas`, request);
    return response.data.model;
  } catch (error) {
    tratarErroApi(error);
  }

  return undefined;
}

function getAssinantesDosClientesDaMaxiprodDataSourceSelectBoxLazy(
  opcoes: SelectBoxLazyOpcoes,
  filtrarPorEmpresasInativas: boolean
) {
  const camposSelect = JSON.stringify(opcoes.camposRetorno);

  const dataSource = criarDatasourceGrid(
    `${NomesEndpoints.Empresa}/ListarAssinantesDosClientesDaMaxiprod?select=${camposSelect}&filtrarPorEmpresasInativas=${filtrarPorEmpresasInativas}`,
    "MinhaEmpresaId"
  );

  return criarDataSourceSelectBoxLazy(dataSource, opcoes);
}

function getMinhasEmpresasDataSourceSelectBoxLazy() {
  return APIBase.getDataSourceSelectBoxLazy(
    {
      camposRetorno: ["Id", "Apelido", "CnpjCpfCodigo"],
      filtros: [{ nomeCampo: "Propria", operador: "=", valor: true }],
      camposOrdenacao: [
        {
          nomeCampo: "Apelido",
          desc: false,
        },
      ],
    },
    NomesEndpoints.Empresa
  );
}

async function obterIdsPorApelidos(apelidos: string[]) {
  const api = getApi();
  const response = await api.post<ResponseModel<IdStringResponse[]>>(
    `${nomeEndpoint}/ObterIdsPorApelidos`,
    Array.from(new Set(apelidos))
  );

  const map = {} as Record<string, number>;
  if (!response.data.sucesso) {
    return map;
  }
  for (const x of response.data.model) {
    map[x.valor] = x.id;
  }
  return map;
}

async function obterRntrc(id: number) {
  const api = getApi();
  const response = await api.get<ResponseModel<string>>(
    `${nomeEndpoint}/RntrcProprietario?idProprietario=${id}`
  );

  return response.data;
}

async function ObterListaSimplesMinhasEmpresas(
  filtrarPorEmpresasInativas: boolean
) {
  const response = await getApi().get<ListaSimplesResponse>(
    `${nomeEndpoint}/ListaSimplesMinhasEmpresas?filtrarPorEmpresasInativas=${filtrarPorEmpresasInativas}`
  );

  return response.data;
}

export default {
  getMinhasEmpresas,
  obterRntrc,
  getAssinantesDosClientesDaMaxiprod:
    getAssinantesDosClientesDaMaxiprodDataSourceSelectBoxLazy,
  getMinhasEmpresasDataSourceSelectBoxLazy,
  obterIdsPorApelidos,
  ObterListaSimplesMinhasEmpresas,
};
