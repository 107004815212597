import { Popup } from "devextreme-react";
import { ToolbarItem } from "devextreme-react/autocomplete";
import { PopupRef } from "devextreme-react/popup";
import { EventInfo } from "devextreme/events";
import dxPopup from "devextreme/ui/popup";
import { useContext, useState } from "react";
import styled from "styled-components";
import { v4 as uuid } from "uuid";
import RenderOnDemand from "../../../parts/utils/load-on-demand";
import { ColecaoAtalhos } from "../../../utils/atalhos/colecao-atalhos";
import { PilhaModais } from "../../../utils/atalhos/pilha-modais";
import { UnidadeTamanhoElementos } from "../../../utils/tipos/unidade-tamanho";
import UrlUtils from "../../../utils/url/url-utils";
import ProvedorMenus from "../../menus/provedor-menus";
import ContextoModal from "../contexto-modal";

interface ModalMxpProps {
  titulo: string;
  children: React.ReactNode;
  visivel: boolean;
  handleFechar: () => void;
  larguraMaxima?: UnidadeTamanhoElementos;
  alturaMaxima?: UnidadeTamanhoElementos;
  largura?: UnidadeTamanhoElementos;
  altura?: UnidadeTamanhoElementos;
  componentesAdicionais?: () => React.ReactNode;
  idRegistro?: number;
}

const DivModal = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export function ModalMxp(props: ModalMxpProps) {
  const [identificador] = useState(uuid());
  const ehModalEncadeado = useContext(ContextoModal) != "";

  // @ts-expect-error: Foi necessário utilizar o isNan, pois o Number.isNan estava retornando false para undefined, mostrando o botão em locais em que deveria estar oculto.
  const ehRegistroEmEdicao = !isNaN(props.idRegistro) && props.idRegistro > 0;

  const habilitarCopia = !ehModalEncadeado && ehRegistroEmEdicao;

  let referencia: dxPopup;

  function rotinasAoAbriModal(e: EventInfo<dxPopup>) {
    PilhaModais.adicionar(identificador, referencia);

    // Busca pelo primeiro input que não seja readonly nem hidden(Por conta do id)
    const primeiroInputForm = e.component
      .content()
      .parentNode?.querySelector(
        "input:not([type=hidden]):not([type=undefined]):not([readonly]):not([disabled])"
      ) as HTMLElement;

    primeiroInputForm?.focus();

    ColecaoAtalhos.registrarAtalhos(identificador, false, {
      tecla: "escape",
      acao: () =>
        ColecaoAtalhos.invocarSeEstiverForaDeInput(props.handleFechar),
    });
  }

  function vincularReferencia(ref: PopupRef | null) {
    if (!ref) {
      return;
    }

    referencia = ref.instance();
  }

  return (
    <RenderOnDemand visivel={props.visivel}>
      <ContextoModal.Provider value={identificador}>
        <ProvedorMenus>
          <Popup
            ref={async (r) => {
              vincularReferencia(r);
            }}
            onShown={async (e) => rotinasAoAbriModal(e)}
            onHidden={async () => PilhaModais.remover(identificador)}
            focusStateEnabled={true}
            title={props.titulo}
            visible={props.visivel}
            shading={true}
            hideOnOutsideClick={false}
            dragEnabled={true}
            dragOutsideBoundary={true}
            animation={{
              show: {
                type: "slide",
                duration: 300,
                from: {
                  position: {
                    my: "top",
                    at: "bottom",
                  },
                  opacity: 0,
                },
                to: {
                  position: {
                    my: "center",
                    at: "center",
                  },
                  opacity: 1,
                },
              },
              hide: {
                type: "slide",
                duration: 300,
                to: {
                  position: {
                    my: "top",
                    at: "bottom",
                  },
                  opacity: 0,
                },
              },
            }}
            height={props.altura ?? "auto"}
            width={props.largura ?? "80vw"}
            maxWidth={props.larguraMaxima ?? "90vw"}
            maxHeight={props.alturaMaxima ?? "90vh"}
            showCloseButton={false}
          >
            {props.componentesAdicionais && props.componentesAdicionais()}

            {habilitarCopia && (
              <ToolbarItem
                toolbar="top"
                location="after"
                widget="dxButton"
                options={{
                  type: "close",
                  icon: "ic-link ic-material-symbols-outlined",
                  hint: "Copiar link",
                  onClick: () =>
                    UrlUtils.GerarECopiarUrlEdicao(props.idRegistro),
                }}
              ></ToolbarItem>
            )}

            <ToolbarItem
              toolbar="top"
              location="after"
              widget="dxButton"
              options={{
                type: "close",
                icon: "close",
                onClick: function () {
                  props.handleFechar();
                },
              }}
            ></ToolbarItem>

            <DivModal>{props.children}</DivModal>
          </Popup>
        </ProvedorMenus>
      </ContextoModal.Provider>
    </RenderOnDemand>
  );
}
