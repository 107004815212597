import { useContext } from "react";
import { LinkButtonStyleComponent } from "../../../../../components/botoes/link-button/styles";
import ContextoOperacoesInsumo from "../contexto-funcoes-insumo";

interface CelulaMultiplasOrigensInsumoProps {
  insumoId: number;
}

export function CelulaMultiplasOrigensInsumo(
  props: CelulaMultiplasOrigensInsumoProps
) {
  const { detalhamentoEstoqueInsumo } = useContext(ContextoOperacoesInsumo);
  return (
    <>
      <LinkButtonStyleComponent
        onClick={() =>
          detalhamentoEstoqueInsumo.setModalDetalharEstoqueInsumoProps({
            insumoId: props.insumoId,
            visivel: true,
          })
        }
        $sublinhado
      >
        Múltiplas origens
      </LinkButtonStyleComponent>
    </>
  );
}
