import { AxiosRequestConfig } from "axios";
import DataSource from "devextreme/data/data_source";
import {
  assertConfiguracaoExibicaoEBuscaType,
  ConfiguracaoExibicaoEBusca,
} from "../../../../components/formularios/selectbox-lazy-mxp";
import getApi from "../../../../configs/api";
import { ArquivoBaseModel } from "../../../../models/api/arquivos/arquivo";
import { InserirEmMassaResponse } from "../../../../models/api/comum/inserir-varios";
import {
  ResponseBase,
  ResponseBaseArquivo,
  ResponseModel,
} from "../../../../models/api/comum/response-base";
import { PermissoesLote } from "../../../../models/permissoes/estoque/lote/permissoes-lote";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";
import ApiEmpresa from "../../../../services/empresa/empresa.service";
import { tratarErroApi } from "../../../../utils/api/api-utils";
import { verificaSeUsuarioPossuiPermissoes } from "../../../../utils/common/permissoes-utils";
import { DataSourceOpcoesBuilder } from "../../../../utils/grid/data-source-factory";
import { ItemServico } from "../../../itens/item/servicos/item.service";
import {
  CancelarLotesResponse as LoteCancelarEmMassaResponse,
  LoteGridModel,
  LoteInserirVariosExcelRequest,
  LoteRequestDTO,
  LoteResponseDTO,
} from "../models/lote.api";

class LoteService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.Lote);
  }

  public GetDadosSelectBoxLote(
    dataSourceOpcoes?: DataSourceOpcoesBuilder<LoteGridModel>
  ): [
    DataSource<LoteGridModel, any>,
    ConfiguracaoExibicaoEBusca<LoteGridModel>
  ] {
    const dataSource = this.ObterDataSourceParaSelectBoxLazy<LoteGridModel>({
      camposRetorno: dataSourceOpcoes?.camposRetorno || [
        "id",
        "loteDoFabricanteOuNumeroDeSerie",
      ],
      camposOrdenacao: dataSourceOpcoes?.camposOrdenacao || [
        {
          campo: "loteDoFabricanteOuNumeroDeSerie",
          desc: false,
        },
      ],
      camposFiltro: dataSourceOpcoes?.camposFiltro,
    });

    const configs = assertConfiguracaoExibicaoEBuscaType<LoteGridModel>({
      expressaoDeBusca: ["loteDoFabricanteOuNumeroDeSerie"],
      nomeCampoChave: "id",
      nomeCampoExibicao: (c) => {
        if (c) {
          return c.loteDoFabricanteOuNumeroDeSerie ?? "";
        }

        return "";
      },
    });

    return [dataSource, configs];
  }

  public async Cancelar(id: number) {
    const api = getApi();
    const response = await api.patch<ResponseModel<LoteResponseDTO>>(
      `${this._nomeEndpoint}/CancelaLote?loteId=${id}`
    );

    return response.data;
  }

  public async CancelarLotes(ids: number[]) {
    const api = getApi();
    const response = await api.post<
      ResponseModel<LoteCancelarEmMassaResponse[]>
    >(`${this._nomeEndpoint}/CancelarLotes`, ids);

    return response.data;
  }

  public async InserirEmMassa(
    model: LoteRequestDTO[]
  ): Promise<ResponseModel<InserirEmMassaResponse[]>> {
    const api = getApi();
    const response = await api.post<ResponseModel<InserirEmMassaResponse[]>>(
      `${this._nomeEndpoint}/InserirEmMassa`,
      model
    );

    return response.data;
  }

  public async InserirEmMassaExcel(dados: LoteInserirVariosExcelRequest[]) {
    try {
      const [idsEmpresa, idsItem] = await Promise.all([
        ApiEmpresa.obterIdsPorApelidos(dados.map((m) => m.codigoFabricante)),
        ItemServico.obterIdsPorCodigos(dados.map((m) => m.codigoItem)),
      ]);

      dados.forEach((m) => {
        if (!idsItem[m.codigoItem]) {
          m.erros += `\nNão foi possível encontrar item com o código: "${m.codigoItem}"`;
        }
        if (!idsEmpresa[m.codigoFabricante]) {
          m.erros += `\nNão foi possível encontrar fabricante com o apelido: "${m.codigoFabricante}"`;
        }
      });

      const dadosInserir = dados
        .filter((x) => x.erros.length === 0)
        .map((m) => ({
          id: 0,
          itemId: idsItem[m.codigoItem],
          fabricanteId: idsEmpresa[m.codigoFabricante],
          historico: null,
          arquivos: [],
          utilizarPrefixo: false,
          ...m,
        }));

      const resultado = await this.InserirEmMassa(dadosInserir);

      if (!resultado.sucesso) {
        return resultado;
      }

      resultado.model.forEach(
        (m, i) => (m.indiceDoRegistro = dadosInserir.at(i)?.linha ?? 0)
      );
      dados
        .filter((x) => x.erros.length > 0)
        .forEach((x) =>
          resultado.model.push({
            indiceDoRegistro: x.linha,
            id: 0,
            erros: x.erros,
            mensagem: "",
          })
        );

      return resultado;
    } catch (error) {
      tratarErroApi(error);
      return {
        sucesso: false,
        model: [],
        mensagem: error,
        erros: [],
      } as ResponseModel<InserirEmMassaResponse[]>;
    }
  }

  public async ObterAnexos(id: number) {
    if (
      !verificaSeUsuarioPossuiPermissoes([
        PermissoesLote.VisualizarEBaixarAnexos,
      ])
    ) {
      return [];
    }

    const api = getApi();
    const response = await api.get<ResponseModel<ArquivoBaseModel[]>>(
      `${this._nomeEndpoint}/ObterAnexos?id=${id}`
    );

    return response.data.model;
  }

  public async AnexoEditarLista(id: number, anexos: ArquivoBaseModel[]) {
    const api = getApi();
    anexos.forEach((x) => (x.arquivo ??= ""));
    const response = await api.put<ResponseBase>(
      `${this._nomeEndpoint}/AnexoEditarLista`,
      { id: id, anexos: anexos }
    );

    return response.data;
  }

  public async AnexoObterDadosParaDownload(id: number, idAnexo: number) {
    const api = getApi();

    const resquestConfig: AxiosRequestConfig = {
      responseType: "blob",
    };

    const response = await api.get<ResponseBaseArquivo>(
      `${this._nomeEndpoint}/AnexoObterDadosParaDownload?id=${id}&idAnexo=${idAnexo}`,
      resquestConfig
    );
    return response;
  }
}

export const LoteServico = new LoteService();
