import { useMemo } from "react";
import { FieldValues, UseControllerProps } from "react-hook-form";
import { FormSelectBoxLazyMxp } from "../../../../../components/formularios";
import { DataSourceOpcoesBuilder } from "../../../../../utils/grid/data-source-factory";
import { LoteGridModel } from "../../models/lote.api";
import { LoteServico } from "../../servicos/lote.servico";
import GridLote from "../grid";

interface ComboLotesMxpProps<T extends FieldValues>
  extends UseControllerProps<T> {
  titulo: string;
  requerido?: boolean;
  somenteLeitura?: boolean;
  onSelectionChanged?: ((e: any) => Promise<void>) | (() => void);
  tituloSeletor?: string;
  filtrosNoCliente?: any[] | undefined;
  dataSourceOpcoes?: DataSourceOpcoesBuilder<LoteGridModel>;
}

interface ComboLotesPorItemMxpProps<T extends FieldValues>
  extends ComboLotesMxpProps<T> {
  idItem: number | null;
}

export default function ComboLotesPorItemMxp<T extends FieldValues>(
  props: ComboLotesPorItemMxpProps<T>
) {
  const dataSourceOpcoes = useMemo(() => {
    return {
      ...props.dataSourceOpcoes,
      camposFiltro: [
        ...(props.dataSourceOpcoes?.camposFiltro ?? []),
        {
          campo: "itemId",
          operador: "=",
          valor: props.idItem,
        },
      ],
    } as DataSourceOpcoesBuilder<LoteGridModel>;
  }, [props.idItem]);

  return <ComboLoteMxp {...props} dataSourceOpcoes={dataSourceOpcoes} />;
}

export function ComboLoteMxp<T extends FieldValues>(
  props: ComboLotesMxpProps<T>
) {
  const [dataSourceOperacao, configuracoesExibicaoEBuscaOperacao] = useMemo(
    () => LoteServico.GetDadosSelectBoxLote(props.dataSourceOpcoes),
    [props.dataSourceOpcoes]
  );

  return (
    <FormSelectBoxLazyMxp
      name={props.name}
      titulo={props.titulo}
      control={props.control}
      requerido={props.requerido}
      somenteLeitura={props.somenteLeitura}
      onSelectionChanged={props.onSelectionChanged}
      configuracoesExibicaoEBusca={configuracoesExibicaoEBuscaOperacao}
      dataSource={dataSourceOperacao}
      seletorConfig={{
        modo: "selecaoUnica",
        titulo: props.tituloSeletor ?? "Selecionar lote",
        componenteGrid: (
          <GridLote
            filtrosNoServidor={props.dataSourceOpcoes?.camposFiltro}
            filtrosNoCliente={props.filtrosNoCliente}
          />
        ),
      }}
      labelSemDados="Sem dados"
    />
  );
}
