import { useEffect, useState } from "react";
import ProvedorAjuda from "../../../../../../../../components/ajuda/provedor-ajuda";
import {
  FormCheckBox,
  FormNumberBox,
  FormSelectBox,
  FormSelectBoxLazyMxp,
} from "../../../../../../../../components/formularios";
import FormTextArea from "../../../../../../../../components/formularios/textarea";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import BotaoCancelarMxp from "../../../../../../../../components/botoes/botao-cancelar-mxp";
import BotaoSalvarMxp from "../../../../../../../../components/botoes/botao-salvar-mxp";
import { assertConfiguracaoExibicaoEBuscaType } from "../../../../../../../../components/formularios/selectbox-lazy-mxp";
import FormMxp from "../../../../../../../../components/layout/form";
import { ContainerFormMxp } from "../../../../../../../../components/layout/form/styles";
import {
  Coluna,
  Linha,
} from "../../../../../../../../components/layout/grid-system";
import ToolbarMxp from "../../../../../../../../components/layout/toolbar-mxp";
import AuditavelDTO from "../../../../../../../../models/api/comum/auditavel-dto";
import { IndicadorDoLancamento } from "../../../../../../../../models/api/lancamento-parte-b/lancamento-parte-b-comum";
import {
  LancamentoNaParteBLalurRequest,
  LancamentoNaParteBLalurResponse,
} from "../../../../../../../../models/api/lancamento-parte-b/lancamento-parte-b-lalur/lancamento-parte-b-lalur";
import { SelectItemEnumIndicadorDoLancamentoLancamentoNaParteB } from "../../../../../../../../models/const/dicionario-combos/lancamento-parte-b";
import { PermissoesContaParteB } from "../../../../../../../../models/permissoes/contabilidade/conta-parte-b/permissoes-conta-parte-b";
import { PermissoesApuracaoLalurIrpj } from "../../../../../../../../models/permissoes/fiscal/apuracao-lalur-irpj/permissoes-apuracao-lalur-irpj";
import {
  IFormularioEditavelBase,
  ResultadoAcaoFormulario,
} from "../../../../../../../../models/shared/ui/formularios";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../../../../utils/api/api-utils";
import criarNameof from "../../../../../../../../utils/common/cria-name-of";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../../../../utils/common/permissoes-utils";
import { obterFormatStringNumero } from "../../../../../../../../utils/formatadores/formatador-de-numeros";
import {
  FormataDescricao,
  FormatadoresSelectBox,
} from "../../../../../../../../utils/formatadores/formatador-de-selectbox";
import yup from "../../../../../../../../utils/validacao/validacao";
import GridContaDaParteB from "../../../../../../../contabilidade/conta-parte-b/componentes/grid";
import { ContaDaParteBGridModel } from "../../../../../../../contabilidade/conta-parte-b/models/conta-parte-b";
import { TipoTributoContaParteB } from "../../../../../../../contabilidade/conta-parte-b/models/conta-parte-b.enums";
import FiltrosGridContaParteB from "../../../../../../../contabilidade/conta-parte-b/models/conta-parte-b.filtros";
import { ContaParteBServico } from "../../../../../../../contabilidade/conta-parte-b/servicos/conta-parte-b.service";
import GridAdicaoExclusaoCompensacaoLalur from "../../../../../../adicao-exclusao-compensacao/lalur-irpj/componentes/grid";
import { AdicaoExclusaoCompensacaoLalurGridModel } from "../../../../../../adicao-exclusao-compensacao/lalur-irpj/models/adicao-exclusao-compensacao-lalur-irpj";
import { AdicaoExclusaoCompensacaoLalurIrpjServico } from "../../../../../../adicao-exclusao-compensacao/lalur-irpj/servicos/adicao-exclusao-compensacao-lalur-irpj.service";
import LancamentoDaParteBDoLalurConstantes from "../../../../../models/lancamento-parte-b-lalur.constantes";
import { LancamentoNaParteBDoLalurServico } from "../../../../../servicos/lancamento-parte-b-lalur";

const nameofContaParteBGrid = criarNameof<ContaDaParteBGridModel>();
const nameofAdicaoExclusaoCompensacaoGrid =
  criarNameof<AdicaoExclusaoCompensacaoLalurGridModel>();

let dadosAuditoria: AuditavelDTO | undefined = undefined;

function carregaContaParteB() {
  return ContaParteBServico.ObterDataSourceParaSelectBoxLazy<ContaDaParteBGridModel>(
    {
      camposRetorno: ["id", "codigo", "descricao"],
      camposFiltro: [
        { campo: "ativo", operador: "=", valor: true },
        {
          campo: "tipoTributo",
          operador: "=",
          valor: TipoTributoContaParteB.IRPJ,
        },
      ],
      camposOrdenacao: [
        {
          campo: "codigo",
          desc: false,
        },
      ],
    }
  );
}

function carregaAdicoesExclusoesCompensacoesLalur() {
  return AdicaoExclusaoCompensacaoLalurIrpjServico.ObterDataSourceSelectBoxLazy(
    {
      camposRetorno: [
        nameofAdicaoExclusaoCompensacaoGrid("id"),
        nameofAdicaoExclusaoCompensacaoGrid("codigo"),
        nameofAdicaoExclusaoCompensacaoGrid("descricao"),
        nameofAdicaoExclusaoCompensacaoGrid("tipoDecodificado"),
      ],
      camposOrdenacao: [
        {
          nomeCampo: nameofAdicaoExclusaoCompensacaoGrid("codigo"),
          desc: false,
        },
      ],
    }
  );
}

interface EditFormLancamentoNaParteBLalurProps extends IFormularioEditavelBase {
  idApuracaoDoLalurEDaIrpj: number;
}

const configuracoesExibicaoSelectContaParteB =
  assertConfiguracaoExibicaoEBuscaType<ContaDaParteBGridModel>({
    expressaoDeBusca: [
      nameofContaParteBGrid("codigo"),
      nameofContaParteBGrid("descricao"),
    ],
    nomeCampoChave: "id",
    nomeCampoExibicao: (c) => {
      if (c) {
        return c.descricao == null
          ? `${c.codigo}`
          : `${c.codigo} (${c.descricao})`;
      }

      return "";
    },
  });

const configuracoesExibicaoSelectAdicaoExclusaoCompensacao =
  assertConfiguracaoExibicaoEBuscaType<AdicaoExclusaoCompensacaoLalurGridModel>(
    {
      expressaoDeBusca: [
        nameofAdicaoExclusaoCompensacaoGrid("codigo"),
        nameofAdicaoExclusaoCompensacaoGrid("descricao"),
      ],
      nomeCampoChave: "id",
      nomeCampoExibicao: (c) => {
        if (c) {
          return c.descricao == null
            ? `${c.codigo}`
            : FormataDescricao(
                FormatadoresSelectBox.TipoCodigoDescricaoTracoEspaco,
                c.tipoDecodificado,
                c.codigo,
                c.descricao
              );
        }

        return "";
      },
    }
  );

export default function EditFormLancamentoNaParteBLalur(
  props: EditFormLancamentoNaParteBLalurProps
) {
  const novoRegistro: LancamentoNaParteBLalurRequest = {
    id: 0,
    apuracaoDoLalurEDoIrpjId: props.idApuracaoDoLalurEDaIrpj,
    contaDaParteBDoLalurOuDoLacsId: 0,
    incluirNaParteA: false,
    adicaoOuExclusaoOuCompensacaoDoLalurId: undefined,
    indicadorDoLancamento: undefined,
    historico: "",
    tributacaoDiferida: false,
    valor: 0,
  };
  const [contaParteB] = useState(carregaContaParteB);
  const [adicoesExclusoesCompensacoes] = useState(
    carregaAdicoesExclusoesCompensacoesLalur
  );
  const [incluirNaParteA, setIncluirNaParteA] = useState(false);

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    contaDaParteBDoLalurOuDoLacsId: yup
      .number()
      .required()
      .moreThan(0)
      .integer(),
    valor: yup.number().moreThan(0).required(),
    indicadorDoLancamento: yup
      .mixed<IndicadorDoLancamento>()
      .transform((v) => (v ? v : null))
      .oneOf(
        Object.values(IndicadorDoLancamento).map((x) => x as number),
        "Valor inválido"
      )
      .test("valida-indicador-do-lancamento", "Indicador inválido", (v) => {
        return incluirNaParteA
          ? v == IndicadorDoLancamento.CR || v == IndicadorDoLancamento.DB
          : true;
      })
      .required(),
    historico: yup
      .string()
      .required()
      .max(LancamentoDaParteBDoLalurConstantes.HistoricoTamanhoMaximo),
    tributacaoDiferida: yup.bool().oneOf([true, false]),
    incluirNaParteA: yup.bool().required(),
    adicaoOuExclusaoOuCompensacaoDoLalurId: yup
      .number()
      .integer()
      .nullable()
      .test(
        "valida-adicaoOuExclusaoOuCompensacaoDoLalurId",
        "Campo obrigatório.",
        (v) => {
          return incluirNaParteA ? v != null : true;
        }
      ),
    apuracaoDoLalurEDoIrpjId: yup.number().moreThan(-1).integer().required(),
  });

  const { register, control, handleSubmit, getValues, reset } =
    useForm<LancamentoNaParteBLalurRequest>({
      mode: "onChange",
      reValidateMode: "onChange",
      resolver: yupResolver(schema),
    });

  useEffect(() => limparTela(), []);

  //Hook usado para carregar os dados da tela
  useEffect(() => {
    if (Number.isNaN(props.idRegistroEmEdicao)) {
      return;
    }

    preencherTela();

    return () => limparTela();
  }, [props.idRegistroEmEdicao]);

  async function preencherTela() {
    if (props.idRegistroEmEdicao > 0) {
      await carregarRegistroDoId();
    } else {
      limparTela();
    }
  }

  async function carregarRegistroDoId() {
    try {
      const resposta =
        await LancamentoNaParteBDoLalurServico.ObterPorIdComDadosAuditoria<LancamentoNaParteBLalurResponse>(
          props.idRegistroEmEdicao
        );
      checarResponse(resposta);
      dadosAuditoria = resposta.model;
      reset(resposta.model);
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  function limparTela() {
    dadosAuditoria = undefined;
    reset(novoRegistro);
  }

  async function handleSalvar() {
    try {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesApuracaoLalurIrpj.InserirEditar,
        ])
      ) {
        return;
      }
      const model = getValues();
      const resposta =
        props.idRegistroEmEdicao > 0
          ? await LancamentoNaParteBDoLalurServico.Atualizar(model)
          : await LancamentoNaParteBDoLalurServico.Inserir(model);

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar(ResultadoAcaoFormulario.AcaoConcluida);
      }
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  async function onChangeIncluirNaParteA(e: any) {
    setIncluirNaParteA(e.value);
  }

  function handleCancelar() {
    fechar(ResultadoAcaoFormulario.AcaoCancelada);
  }

  function fechar(resultado: ResultadoAcaoFormulario) {
    limparTela();
    props.handleCallback(resultado);
  }

  return (
    <>
      <ContainerFormMxp>
        <FormMxp>
          <input type="hidden" {...register("id")} defaultValue={0} />
          <ProvedorAjuda id="edit-form-lancamento-parte-b-lalur">
            <Linha>
              <Coluna md={12}>
                <FormSelectBoxLazyMxp
                  name="contaDaParteBDoLalurOuDoLacsId"
                  titulo="Conta da parte B"
                  control={control}
                  requerido
                  dataSource={contaParteB}
                  configuracoesExibicaoEBusca={
                    configuracoesExibicaoSelectContaParteB
                  }
                  labelSemDados="Sem dados"
                  seletorConfig={{
                    modo: "selecaoUnica",
                    titulo: "Selecionar conta da parte B",
                    componenteGrid: (
                      <GridContaDaParteB
                        filtrosNoCliente={FiltrosGridContaParteB.ativo}
                        filtrosNoServidor={FiltrosGridContaParteB.SomenteIrpj}
                      />
                    ),
                  }}
                  permissoesNecessarias={[PermissoesContaParteB.Consultar]}
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={3}>
                <FormNumberBox
                  name="valor"
                  titulo="Valor"
                  minimo={0}
                  formato={obterFormatStringNumero(2)}
                  control={control}
                  requerido
                />
              </Coluna>
              <Coluna md={5}>
                <FormSelectBox
                  name="indicadorDoLancamento"
                  titulo="Indicador do lançamento"
                  control={control}
                  requerido
                  dataSource={
                    SelectItemEnumIndicadorDoLancamentoLancamentoNaParteB
                  }
                  defaultValue={null}
                />
              </Coluna>
              <Coluna md={4} centralizar>
                <FormCheckBox
                  name="tributacaoDiferida"
                  titulo="Realização de valores cuja tributação tenha sido diferida"
                  control={control}
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={12}>
                <FormTextArea
                  name="historico"
                  titulo="Histórico"
                  control={control}
                  requerido
                  tamanhoMaximo={
                    LancamentoDaParteBDoLalurConstantes.HistoricoTamanhoMaximo
                  }
                  valor={""}
                  height={10}
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={3} centralizar>
                <FormCheckBox
                  name="incluirNaParteA"
                  titulo="Tem reflexo na parte A"
                  control={control}
                  onValueChanged={onChangeIncluirNaParteA}
                />
              </Coluna>
              <Coluna md={9}>
                <FormSelectBoxLazyMxp
                  name="adicaoOuExclusaoOuCompensacaoDoLalurId"
                  titulo="Adição, exclusão ou compensação de prejuizo"
                  control={control}
                  desabilitado={!incluirNaParteA}
                  requerido={incluirNaParteA}
                  dataSource={adicoesExclusoesCompensacoes}
                  configuracoesExibicaoEBusca={
                    configuracoesExibicaoSelectAdicaoExclusaoCompensacao
                  }
                  labelSemDados="Sem dados"
                  seletorConfig={{
                    modo: "selecaoUnica",
                    titulo:
                      "Selecionar adição, exclusão ou compensação do LACS",
                    componenteGrid: <GridAdicaoExclusaoCompensacaoLalur />,
                  }}
                />
              </Coluna>
            </Linha>
          </ProvedorAjuda>
        </FormMxp>
        <ToolbarMxp dadosAuditoria={dadosAuditoria}>
          <BotaoSalvarMxp handleClick={handleSubmit(handleSalvar)} />
          <BotaoCancelarMxp handleClick={handleCancelar} />
        </ToolbarMxp>
      </ContainerFormMxp>
    </>
  );
}
