import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";

class ConjuntoVeiculoService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.VeiculoConjunto);
  }
}

export const ConjuntoVeiculoServico = new ConjuntoVeiculoService();
