import DataSource from "devextreme/data/data_source";
import {
  assertConfiguracaoExibicaoEBuscaType,
  ConfiguracaoExibicaoEBusca,
} from "../../../../components/formularios/selectbox-lazy-mxp";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";
import DataSourceFactory, {
  DataSourceOpcoesBuilder,
} from "../../../../utils/grid/data-source-factory";
import {
  OperacaoDaOrdemDeProducaoCriarBoletimDeInspecaoGridModel,
  OperacaoDaOrdemDeProducaoGridModel,
} from "../models/operacao-de-ordem-de-producao";

class OperacaoDeOrdemDeProducaoService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.OperacaoDeOrdemDeProducao);
  }

  public GetDadosSelectBoxOperacaoDaOrdemDeProducao(
    dataSourceOpcoes?: DataSourceOpcoesBuilder<OperacaoDaOrdemDeProducaoGridModel>
  ): [
    DataSource<OperacaoDaOrdemDeProducaoGridModel, any>,
    ConfiguracaoExibicaoEBusca<OperacaoDaOrdemDeProducaoGridModel>
  ] {
    const dataSource =
      this.ObterDataSourceParaSelectBoxLazy<OperacaoDaOrdemDeProducaoGridModel>(
        {
          camposRetorno: dataSourceOpcoes?.camposRetorno || [
            "id",
            "ordemDeProducaoId",
            "numero",
            "descricao",
          ],
          camposOrdenacao: dataSourceOpcoes?.camposOrdenacao || [
            { campo: "numero", desc: false },
          ],
          camposFiltro: dataSourceOpcoes?.camposFiltro,
        }
      );

    const configs =
      assertConfiguracaoExibicaoEBuscaType<OperacaoDaOrdemDeProducaoGridModel>({
        nomeCampoChave: "id",
        expressaoDeBusca: ["numero", "descricao"],
        nomeCampoExibicao: (c) => {
          if (c) {
            return c.descricao == null
              ? `${c.numero}`
              : `${c.numero} (${c.descricao})`;
          }

          return "";
        },
      });

    return [dataSource, configs];
  }

  public ObterQueryGridCriarBoletimDeInspecaoDaOrdemDeProducaoComOperacoes(
    ordemDeProducaoId: number
  ) {
    return DataSourceFactory.CriarParaGrid<
      OperacaoDaOrdemDeProducaoCriarBoletimDeInspecaoGridModel,
      number
    >(
      `${this._nomeEndpoint}/ObterQueryGridCriarBoletimDeInspecaoDaOrdemDeProducaoComOperacoes?ordemDeProducaoId=${ordemDeProducaoId}`
    );
  }
}

export const OperacaoDeOrdemDeProducaoServico =
  new OperacaoDeOrdemDeProducaoService();
