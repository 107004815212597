import DataGrid, { Column, DataGridRef } from "devextreme-react/data-grid";
import NumberBox, { NumberBoxTypes } from "devextreme-react/number-box";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import { useCallback, useMemo, useRef } from "react";
import { GridNumberBoxProps } from "../../../../../components/templates-celulas-grid/inputs/number-box/number-box-grid.edit";
import { useSeletorGridMxp } from "../../../../../hooks/seletor.hooks";
import { GridMxpProps } from "../../../../../models/shared/ui/grid";
import GetColunasDeAuditoria from "../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import { ConcluirOrdemDeProducaoRequest } from "../../../../producao/ordem-de-producao/models/ordem-de-producao.api";
import { ReservaSelecionarGridModel } from "../../models/selecionar-reservas";

export interface GridSelecionarReservaProps
  extends GridMxpProps<ReservaSelecionarGridModel> {
  dataSource: DataSource | ArrayStore;
  dadosConclusao: ConcluirOrdemDeProducaoRequest;
}

const nameOfGridHandler = criarNameof<ReservaSelecionarGridModel>();

const QuantidadeEditavelComponent = (props: any) => {
  const onValueChanged = useCallback(
    (e: NumberBoxTypes.ValueChangedEvent) => {
      props.data.setValue(e.value);
    },
    [props]
  );

  return (
    <NumberBox
      name={nameOfGridHandler("quantidadeConcluir")}
      defaultValue={props.data.value}
      onValueChanged={onValueChanged}
      min={0}
      {...GridNumberBoxProps}
    />
  );
};

export function GridSelecaoReserva(props: GridSelecionarReservaProps) {
  const gridRef = useRef<DataGridRef>(null);

  const gridController = useMemo(
    () =>
      new GridController<ReservaSelecionarGridModel>(() =>
        gridRef.current?.instance()
      ),
    []
  );

  useSeletorGridMxp(() => gridRef.current, props.filtrosNoCliente);

  const handleAtualizarGrid = useCallback(() => {
    gridController.atualizar();
  }, [gridController]);

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar(
      "grid-selecao-reserva",
      () => gridRef?.current?.instance(),
      false,
      props.filtrosNoCliente
    )
      .definirDataSource(props.dataSource)
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .definirBotaoRefresh(handleAtualizarGrid)
      .configurarExportacao(NomesTelas.reservas)
      .definirOrdenacao()
      .definirOperacoesNoLadoDoCliente()
      .definirEditavel()
      .definirStyles(props.style)
      .build();
  }, [
    gridRef,
    props.filtrosNoCliente,
    props.style,
    props.dataSource,
    handleAtualizarGrid,
  ]);

  return (
    <>
      <DataGrid ref={gridRef} {...configuracoesGrid}>
        <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
        <Column
          key={nameOfGridHandler("quantidadeConcluir")}
          dataField={nameOfGridHandler("quantidadeConcluir")}
          {...obterConfiguracaoColuna("quantidade")}
          allowEditing={true}
          showEditorAlways={true}
          width={150}
          visibleIndex={5}
          caption="Qt a selecionar"
          editCellComponent={QuantidadeEditavelComponent}
        />
        <Column
          key={nameOfGridHandler("itemCodigo")}
          dataField={nameOfGridHandler("itemCodigo")}
          {...obterConfiguracaoColuna("stringG")}
          allowEditing={false}
          width={150}
          caption="Item"
        />
        <Column
          key={nameOfGridHandler("itemDescricao")}
          dataField={nameOfGridHandler("itemDescricao")}
          {...obterConfiguracaoColuna("stringM")}
          width={300}
          allowEditing={false}
          caption="Descrição"
        />
        <Column
          key={nameOfGridHandler("oQue")}
          dataField={nameOfGridHandler("oQue")}
          {...obterConfiguracaoColuna("stringM")}
          allowEditing={false}
          width={150}
          caption="O quê?"
        />
        <Column
          key={nameOfGridHandler("para")}
          dataField={nameOfGridHandler("para")}
          {...obterConfiguracaoColuna("stringG")}
          allowEditing={false}
          width={300}
          caption="Para"
        />
        <Column
          key={nameOfGridHandler("quantidade")}
          dataField={nameOfGridHandler("quantidade")}
          {...obterConfiguracaoColuna("quantidade")}
          width={150}
          allowEditing={false}
          caption="Qt"
        />

        <Column
          key={nameOfGridHandler("dataDeEntrega")}
          dataField={nameOfGridHandler("dataDeEntrega")}
          {...obterConfiguracaoColuna("dataAnoCurtoComHoraMinutoSegundos")}
          allowEditing={false}
          width={150}
          caption="Data de entrega"
        />
        {GetColunasDeAuditoria()}
      </DataGrid>
    </>
  );
}
