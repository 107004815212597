import { useContext, useEffect, useMemo } from "react";
import { BotaoCopiarLinkContexto } from "../components/grid-mxp/grid-mxp-botao-copiar-link";
import ContextoModal from "../components/layout/contexto-modal";
import ContextoMenus from "../components/menus/contexto-menus";
import { ItemContextMenuMxp } from "../utils/context-menu/context-menu-utils";
import { GridController } from "../utils/grid/grid-controller";

export function useMenuDeContextosGrid<T>(
  itens: ItemContextMenuMxp[],
  habilitaCopiaLink?: boolean,
  controller?: GridController<T>
) {
  const { setItensMenu } = useContext(ContextoMenus);
  const ehModalEncadeado = useContext(ContextoModal) != "";

  // Array vazio em useMemo para evitar loop infinito em casos onde o array itens vem vazio
  const itensMenuDeContexto: ItemContextMenuMxp[] = useMemo(() => [], []);

  if (itens.length == 0) {
    itens = itensMenuDeContexto;
  }

  useEffect(() => {
    if (
      !ehModalEncadeado &&
      !!habilitaCopiaLink &&
      controller &&
      !itens.some((x) => x.text == "Copiar link")
    ) {
      itens.push(BotaoCopiarLinkContexto(controller));
    }

    setItensMenu([...itens]);
  }, [itens, controller]);

  return [...itens];
}
