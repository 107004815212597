import { ReactNode } from "react";

interface FormMxpProps {
  children: ReactNode;
}

export default function ContainerSemFormMxp(props: FormMxpProps) {
  return (
    <div
      style={{
        overflow: "auto",
        padding: "5px 15px",
        margin: "-15px -15px 0px -15px",
        flexGrow: 2,
        flexShrink: 2,
      }}
    >
      {props.children}
    </div>
  );
}
