import DataSource from "devextreme/data/data_source";
import {
  assertConfiguracaoExibicaoEBuscaType,
  ConfiguracaoExibicaoEBusca,
} from "../../../../components/formularios/selectbox-lazy-mxp";
import getApi from "../../../../configs/api";
import {
  ListaSimplesResponse,
  ResponseModel,
} from "../../../../models/api/comum/response-base";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";
import { CentroDeTrabalhoGridModel } from "../models/centro-de-trabalho.api";

class CentroDeTrabalhoService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.CentroDeTrabalho);
  }

  public GetDadosSelectBoxCentroDeTrabalho(): [
    DataSource<CentroDeTrabalhoGridModel, any>,
    ConfiguracaoExibicaoEBusca<CentroDeTrabalhoGridModel>
  ] {
    const dataSource =
      this.ObterDataSourceParaSelectBoxLazy<CentroDeTrabalhoGridModel>({
        camposRetorno: [
          "id",
          "codigo",
          "descricao",
          "tipo",
          "tipoSequenciamento",
          "operacaoDescricaoPreferencial",
        ],
        camposOrdenacao: [{ campo: "codigo", desc: false }],
        camposFiltro: [{ campo: "ativo", operador: "=", valor: true }],
      });
    const configs =
      assertConfiguracaoExibicaoEBuscaType<CentroDeTrabalhoGridModel>({
        nomeCampoChave: "id",
        expressaoDeBusca: ["codigo", "descricao"],
        nomeCampoExibicao: (c) => {
          if (c) {
            return c.descricao == null
              ? `${c.codigo}`
              : `${c.codigo} (${c.descricao})`;
          }

          return "";
        },
      });

    return [dataSource, configs];
  }

  async obterUnidadesMedida() {
    const api = getApi();
    const response = await api.get<ListaSimplesResponse>(
      `${this._nomeEndpoint}/unidades-medidas`
    );

    return response.data;
  }

  async existeVinculosEnderecoEstoqueCentroDeTrabalho(id: number) {
    const api = getApi();
    const response = await api.get<ResponseModel<boolean>>(
      `${this._nomeEndpoint}/existeVinculosEnderecoEstoqueCentroDeTrabalho?id=${id}`
    );

    return response.data;
  }
}

export const CentroDeTrabalhoServico = new CentroDeTrabalhoService();
