import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import BotaoCancelarMxp from "../../../../../components/botoes/botao-cancelar-mxp";
import BotaoSalvarMxp from "../../../../../components/botoes/botao-salvar-mxp";
import {
  FormCheckBox,
  FormGrupo,
  FormNumberBox,
  FormSelectBox,
  FormTextBox,
} from "../../../../../components/formularios";
import FormMxp from "../../../../../components/layout/form";
import { ContainerFormMxp } from "../../../../../components/layout/form/styles";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import ToolbarMxp from "../../../../../components/layout/toolbar-mxp";
import AuditavelDTO from "../../../../../models/api/comum/auditavel-dto";
import {
  IFormularioEditavelBase,
  ResultadoAcaoFormulario,
} from "../../../../../models/shared/ui/formularios";
import SelectItem from "../../../../../models/shared/ui/select-item";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import exibirNotificacaoToast, {
  GeradorMensagensNotificacao,
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { obterFormatStringNumero } from "../../../../../utils/formatadores/formatador-de-numeros";
import yup from "../../../../../utils/validacao/validacao";
import {
  CodigoTributacaoServicoRequestDTO,
  CodigoTributacaoServicoResponseDTO,
} from "../../models/codigo-tributacao-servico.api";
import CodigoTributacaoServicoConstantes from "../../models/codigo-tributacao-servico.constantes";
import { CodigoTributacaoServicoServico } from "../../servicos/codigo-tributacao-servico.servico";

const novoRegistro: CodigoTributacaoServicoRequestDTO = {
  id: 0,
  aliquotaIss: null,
  codigo: "",
  descricao: "",
  idCodigoDoServico: 0,
  gerarGrupoDeObraNoXmlDaNfse: false,
};

let dadosAuditoria: AuditavelDTO | undefined = undefined;

export default function FormCodigoTributacaoServico(
  props: IFormularioEditavelBase
) {
  const [codigosServico, setCodigosServico] = useState<SelectItem[]>([]);

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    codigo: yup
      .string()
      .required()
      .max(CodigoTributacaoServicoConstantes.CodigoTamanhoMaximo),
    descricao: yup
      .string()
      .required()
      .max(CodigoTributacaoServicoConstantes.DescricaoTamanhoMaximo),
    idCodigoDoServico: yup.number().required().positive().integer(),
    aliquotaIss: yup
      .number()
      .nullable()
      .positive()
      .max(CodigoTributacaoServicoConstantes.AliquotaIssValorMaximo)
      .transform((valor) => (valor ? valor : null)),
    gerarGrupoDeObraNoXmlDaNfse: yup.boolean().required(),
  });

  const { register, control, handleSubmit, getValues, reset } =
    useForm<CodigoTributacaoServicoRequestDTO>({
      resolver: yupResolver(schema),
    });

  //Hook usado para carregar os dados da tela
  useEffect(() => {
    if (Number.isNaN(props.idRegistroEmEdicao)) {
      return;
    }

    preencherTela();
  }, [props.idRegistroEmEdicao]);

  async function preencherTela() {
    await carregarCodigosServicos();

    if (props.idRegistroEmEdicao == 0) {
      limparTela();
    } else if (props.idRegistroEmEdicao > 0) {
      await carregarRegistroDoId();
    }
  }

  async function carregarCodigosServicos() {
    try {
      const resposta =
        await CodigoTributacaoServicoServico.ObterCodigosServico();
      checarResponse(resposta);
      setCodigosServico(
        resposta.model.map((x) => ({
          valor: x.valor,
          descricao: x.descricao,
        }))
      );
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  async function carregarRegistroDoId() {
    try {
      const resposta =
        await CodigoTributacaoServicoServico.ObterPorIdComDadosAuditoria<CodigoTributacaoServicoResponseDTO>(
          props.idRegistroEmEdicao
        );
      checarResponse(resposta);
      dadosAuditoria = resposta.model;
      reset(resposta.model);
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  function limparTela() {
    dadosAuditoria = undefined;
    reset(novoRegistro);
  }

  async function handleSalvar() {
    const model = getValues();
    try {
      const resposta =
        props.idRegistroEmEdicao > 0
          ? await CodigoTributacaoServicoServico.Atualizar(model)
          : await CodigoTributacaoServicoServico.Inserir(model);

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: GeradorMensagensNotificacao.SalvoComSucessoMasculino(
            "Código de tributação de serviço"
          ),
          tipo: TipoNotificacao.Sucesso,
        });
        fechar(ResultadoAcaoFormulario.AcaoConcluida);
      }
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  function handleCancelar() {
    fechar(ResultadoAcaoFormulario.AcaoCancelada);
  }

  function fechar(resultado: ResultadoAcaoFormulario) {
    limparTela();
    props.handleCallback(resultado);
  }

  return (
    <>
      <ContainerFormMxp>
        <FormMxp>
          <ProvedorAjuda id="edit-form-codigo-tributacao-servico">
            <input type="hidden" {...register("id")} defaultValue={0} />
            <FormGrupo>
              <Linha>
                <Coluna md={4}>
                  <FormTextBox
                    name="codigo"
                    titulo="Código"
                    toolTip="Código"
                    control={control}
                    requerido
                    tamanhoMaximo={
                      CodigoTributacaoServicoConstantes.CodigoTamanhoMaximo
                    }
                  />
                </Coluna>
              </Linha>
              <Linha>
                <Coluna md={12}>
                  <FormTextBox
                    name="descricao"
                    titulo="Descrição"
                    toolTip="Descrição"
                    control={control}
                    requerido
                    tamanhoMaximo={
                      CodigoTributacaoServicoConstantes.DescricaoTamanhoMaximo
                    }
                  />
                </Coluna>
              </Linha>
              <Linha>
                <Coluna md={12}>
                  <FormSelectBox
                    name="idCodigoDoServico"
                    titulo="Código do serviço"
                    toolTip="Código do serviço"
                    control={control}
                    requerido
                    dataSource={codigosServico}
                    habilitaBusca
                    tipoBusca="contains"
                  />
                </Coluna>
              </Linha>
              <Linha>
                <Coluna md={6}>
                  <FormCheckBox
                    control={control}
                    titulo={'Gerar o grupo de "Obra" no XML da NFS-e'}
                    name={"gerarGrupoDeObraNoXmlDaNfse"}
                    requerido
                  />
                </Coluna>
              </Linha>
              <Linha>
                <Coluna md={5}>
                  <FormNumberBox
                    name="aliquotaIss"
                    titulo="Alíquota de ISS"
                    toolTip="Alíquota de ISS"
                    control={control}
                    minimo={0}
                    maximo={
                      CodigoTributacaoServicoConstantes.AliquotaIssValorMaximo
                    }
                    formato={obterFormatStringNumero(4)}
                    exibirBotaoLimpar
                  />
                </Coluna>
              </Linha>
            </FormGrupo>
          </ProvedorAjuda>
        </FormMxp>
        <ToolbarMxp dadosAuditoria={dadosAuditoria}>
          <BotaoSalvarMxp handleClick={handleSubmit(handleSalvar)} />
          <BotaoCancelarMxp handleClick={handleCancelar} />
        </ToolbarMxp>
      </ContainerFormMxp>
    </>
  );
}
