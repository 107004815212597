import { DataGridRef } from "devextreme-react/data-grid";
import { OptionChangedEvent } from "devextreme/ui/data_grid";
import { useContext, useEffect } from "react";
import { JanelasDeNotificacaoTitulos } from "../../../utils/common/notificacoes-utils";
import { exibirAlerta } from "../../../utils/dialogos";
import BotaoCancelarMxp from "../../botoes/botao-cancelar-mxp";
import BotaoConfirmarMxp from "../../botoes/botao-confirmar-mxp";
import FormMxp from "../../layout/form";
import { ContainerFormMxp } from "../../layout/form/styles";
import { ModalMxp } from "../../layout/modal-mxp";
import ToolbarMxp from "../../layout/toolbar-mxp";
import { ContextoSeletor } from "../contexto-seletor";

export type ModoDeSelecaoSeletor = "selecaoUnica" | "selecaoMultipla";

interface SeletorMxpProps {
  visivel: boolean;
  modo: ModoDeSelecaoSeletor;
  handleFechar: () => void;
  componenteGrid: JSX.Element;
  handleConfirmar: (sucesso: boolean, dados: any[]) => void;
  titulo: string;
}

function definirAltura(elemento: HTMLElement) {
  elemento.style.height = "77vh";
}

function definirModoDeSelecaoGrid(
  refGrid: DataGridRef,
  modo: ModoDeSelecaoSeletor
) {
  switch (modo) {
    case "selecaoUnica":
      refGrid.instance().option("selection", {
        mode: "single",
        allowSelectAll: false,
        showCheckBoxesMode: "none",
      });
      break;
    case "selecaoMultipla":
      refGrid.instance().option("selection", {
        mode: "multiple",
        showCheckBoxesMode: "onClick",
        allowSelectAll: true,
      });
      break;
  }
}

export default function SeletorMxp(props: SeletorMxpProps) {
  const { refGrid, filtroPadraoNoCliente } = useContext(ContextoSeletor);

  function CarregarGrid() {
    if (!refGrid) {
      return;
    }

    resetarEstadoGrid();

    const gridInstance = refGrid.instance();
    definirAltura(gridInstance.element() as HTMLElement);
    definirModoDeSelecaoGrid(refGrid, props.modo);

    if (props.modo == "selecaoUnica") {
      gridInstance.option("onOptionChanged", (e: OptionChangedEvent<any>) => {
        if (e.name != "selection" || e.value.showCheckBoxesMode == "none") {
          return;
        }

        definirModoDeSelecaoGrid(e.component.instance(), props.modo);
      });
    }

    // Define a confirmação com o duplo clique da linha
    gridInstance.option("onRowDblClick", handleConfirmar);
  }

  useEffect(() => {
    CarregarGrid();
    () => resetarEstadoGrid();
  }, [refGrid, props.visivel]);

  function resetarEstadoGrid() {
    if (!refGrid) {
      return;
    }

    const instancia = refGrid.instance();
    instancia.clearSelection();
    if (filtroPadraoNoCliente) {
      instancia.option("filterValue", filtroPadraoNoCliente);
    }
    instancia.pageIndex(0);
  }

  async function handleConfirmar() {
    const selecao = (await refGrid?.instance().getSelectedRowsData()) ?? [];

    if (!selecao.length) {
      await exibirAlerta(
        JanelasDeNotificacaoTitulos.Atencao,
        `Selecione ${
          props.modo == "selecaoMultipla" ? "ao menos" : ""
        } um registro!`
      );
      return;
    }

    props.handleConfirmar(true, selecao);
  }

  return (
    <ModalMxp
      titulo={props.titulo}
      visivel={props.visivel}
      handleFechar={props.handleFechar}
      altura={"90vh"}
    >
      <ContainerFormMxp>
        <FormMxp>{props.componenteGrid}</FormMxp>
        <ToolbarMxp>
          <BotaoConfirmarMxp handleClick={handleConfirmar} />
          <BotaoCancelarMxp handleClick={props.handleFechar} />
        </ToolbarMxp>
      </ContainerFormMxp>
    </ModalMxp>
  );
}
