import {
  ConfiguracoesErros,
  TipoErro,
} from "../../../../../models/api/comum/erros";
import { ErroModeloDeDocumentoOuRelatorio } from "./modelo-de-documento-ou-relatorio.erros";

export const configuracoesErroModeloDeDocumentoOuRelatorio = Object.freeze<
  ConfiguracoesErros<ErroModeloDeDocumentoOuRelatorio>
>({
  "MSG-013-0002": {
    tipo: TipoErro.Popup,
  },
});
