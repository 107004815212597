import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import ProvedorAjuda from "../../../../../../../../components/ajuda/provedor-ajuda";
import BotaoCancelarMxp from "../../../../../../../../components/botoes/botao-cancelar-mxp";
import BotaoLadoDireitoInput from "../../../../../../../../components/botoes/botao-lado-direito-input";
import BotaoSalvarMxp from "../../../../../../../../components/botoes/botao-salvar-mxp";
import { FormNumberBox } from "../../../../../../../../components/formularios";
import FormMxp from "../../../../../../../../components/layout/form";
import { ContainerFormMxp } from "../../../../../../../../components/layout/form/styles";
import {
  Coluna,
  Linha,
} from "../../../../../../../../components/layout/grid-system";
import ToolbarMxp from "../../../../../../../../components/layout/toolbar-mxp";
import { useCarregarRegistro } from "../../../../../../../../hooks/form.hooks";
import AuditavelDTO from "../../../../../../../../models/api/comum/auditavel-dto";
import {
  DemonstrativoSaldosParteBLalurRequest,
  DemonstrativoSaldosParteBLalurResponse,
} from "../../../../../../../../models/api/demonstrativo-saldos/demonstrativo-saldos-parte-b-lalur/demonstrativo-saldos-parte-b-lalur";
import {
  IFormularioEditavelBase,
  ResultadoAcaoFormulario,
} from "../../../../../../../../models/shared/ui/formularios";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../../../../utils/api/api-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../../../../utils/common/notificacoes-utils";
import { exibirConfirmacao } from "../../../../../../../../utils/dialogos";
import { obterFormatStringNumero } from "../../../../../../../../utils/formatadores/formatador-de-numeros";
import yup from "../../../../../../../../utils/validacao/validacao";
import { DemonstrativoSaldosParteBLalurServico } from "../../../../../servicos/demonstrativo-saldos-parte-b-lalur";

const novoRegistro: DemonstrativoSaldosParteBLalurRequest = {
  id: 0,
  contaDaParteBId: 0,
  apuracaoLalurIrpjId: 0,
  saldoInicial: 0,
  saldoInicialEstaFixo: false,
  valorDaParteA: 0,
  valorDaParteB: 0,
  saldoFinal: 0,
};

let dadosAuditoria: AuditavelDTO | undefined = undefined;

export default function ModalEdicaoDemonstrativosSaldosLalur(
  props: IFormularioEditavelBase
) {
  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    contaDaParteBId: yup.number().required().moreThan(-1),
    apuracaoLalurIrpjId: yup.number().required().moreThan(-1).integer(),
    saldoInicialEstaFixo: yup.boolean().required(),
    valorDaParteA: yup.number(),
    valorDaParteB: yup.number(),
    saldoFinal: yup.number(),
    saldoInicial: yup.number(),
  });

  const { register, control, handleSubmit, getValues, reset, setValue, watch } =
    useForm<DemonstrativoSaldosParteBLalurRequest>({
      mode: "onChange",
      reValidateMode: "onChange",
      resolver: yupResolver(schema),
    });

  useCarregarRegistro(props.idRegistroEmEdicao, carregarTela);

  // Usado para limpar o formulário se for NaN
  useEffect(() => {
    if (Number.isNaN(props.idRegistroEmEdicao)) {
      reset(novoRegistro);
    }
  }, [props.idRegistroEmEdicao]);

  async function carregarTela() {
    try {
      if (props.idRegistroEmEdicao > 0) {
        await carregarModel();
      } else {
        reset(novoRegistro);
        dadosAuditoria = undefined;
      }
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  async function carregarModel() {
    try {
      const resposta =
        await DemonstrativoSaldosParteBLalurServico.ObterPorIdComDadosAuditoria<DemonstrativoSaldosParteBLalurResponse>(
          props.idRegistroEmEdicao
        );
      checarResponse(resposta);
      reset(resposta.model);
      dadosAuditoria = resposta.model;
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    }
  }

  function limparTela() {
    dadosAuditoria = undefined;
    reset(novoRegistro);
  }

  async function handleSalvar() {
    const model = getValues();

    try {
      const resposta = await DemonstrativoSaldosParteBLalurServico.Atualizar({
        id: model.id,
        saldoInicial: model.saldoInicial ?? 0,
      });

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar(ResultadoAcaoFormulario.AcaoConcluida);
      }
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    }
  }

  function callBackUnprocessableEntity() {
    fechar(ResultadoAcaoFormulario.AcaoComErro);
  }

  function handleCancelar() {
    fechar(ResultadoAcaoFormulario.AcaoCancelada);
  }

  function fechar(resultado: ResultadoAcaoFormulario) {
    limparTela();
    props.handleCallback(resultado);
  }

  async function handleTornarSaldoInicialLivre() {
    try {
      {
        const resposta = await exibirConfirmacao(
          "Confirmação",
          "Deseja tornar livre o saldo inicial?"
        );

        if (resposta) {
          const retorno =
            await DemonstrativoSaldosParteBLalurServico.TornarSaldoInicialLivre(
              props.idRegistroEmEdicao
            );
          checarResponse(retorno);

          if (retorno.sucesso) {
            setValue("saldoInicialEstaFixo", false);
          }
        }
      }
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    }
  }

  function onChangeSaldoInicial(valor: any) {
    if (valor != getValues("saldoInicial")) {
      setValue("saldoInicialEstaFixo", true);
    }
  }

  return (
    <>
      <ContainerFormMxp>
        <FormMxp>
          <ProvedorAjuda id="modal-edicao-demonstrativo-custos-lalur">
            <Coluna md={3}>
              <input
                type="hidden"
                {...register("contaDaParteBId")}
                defaultValue={0}
              />
              <input
                type="hidden"
                {...register("apuracaoLalurIrpjId")}
                defaultValue={0}
              />
              <input
                type="hidden"
                {...register("valorDaParteA")}
                defaultValue={0}
              />
              <input
                type="hidden"
                {...register("valorDaParteB")}
                defaultValue={0}
              />
              <input
                type="hidden"
                {...register("saldoFinal")}
                defaultValue={0}
              />
              <input
                type="hidden"
                {...register("saldoInicialEstaFixo")}
                defaultValue={0}
              />
            </Coluna>
            <Linha>
              <Coluna md={6}>
                <BotaoLadoDireitoInput
                  textoTooltip="Fixar saldo inicial"
                  icone="ic-keep"
                  onClick={() => handleTornarSaldoInicialLivre()}
                  visivel={watch("saldoInicialEstaFixo")}
                >
                  <FormNumberBox
                    name="saldoInicial"
                    titulo="Saldo inicial"
                    formato={obterFormatStringNumero(2)}
                    control={control}
                    requerido
                    onChange={(e) => onChangeSaldoInicial(e)}
                  />
                </BotaoLadoDireitoInput>
              </Coluna>
            </Linha>
          </ProvedorAjuda>
        </FormMxp>
        <ToolbarMxp dadosAuditoria={dadosAuditoria}>
          <BotaoSalvarMxp handleClick={handleSubmit(handleSalvar)} />
          <BotaoCancelarMxp handleClick={handleCancelar} />
        </ToolbarMxp>
      </ContainerFormMxp>
    </>
  );
}
