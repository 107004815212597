import { yupResolver } from "@hookform/resolvers/yup";
import ArrayStore from "devextreme/data/array_store";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import BotaoCancelarMxp from "../../../../../components/botoes/botao-cancelar-mxp";
import BotaoConfirmarMxp from "../../../../../components/botoes/botao-confirmar-mxp";
import {
  FormGrupo,
  FormNumberBox,
  FormSelectBox,
  FormTextBox,
} from "../../../../../components/formularios";
import { OrdenacaoSelectBox } from "../../../../../components/formularios/selectbox";
import FormMxp from "../../../../../components/layout/form";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import ToolbarMxp from "../../../../../components/layout/toolbar-mxp";
import { CaracterInvisivel } from "../../../../../models/const/comum";
import { tratarErroApi } from "../../../../../utils/api/api-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import {
  formatarNumeroQuantidade,
  obterFormatStringNumero,
} from "../../../../../utils/formatadores/formatador-de-numeros";
import yup from "../../../../../utils/validacao/validacao";
import { ModalSelecionarMovimentacoesParaEstornar } from "../../../../estoque/estoque-movimentacao/componentes/modal-selecionar-movimentacoes-para-estornar";
import {
  DadosModalEstornoDeInsumos,
  InsumoDaOrdemDeProducaoGridModel,
  ObterDescricaoInsumo,
  TipoDeEstornoInsumo,
  TipoDeEstornoInsumoSelectItems,
} from "../../models/insumo-da-ordem-de-producao";
import { InsumoDaOrdemDeProducaoServico } from "../../servicos/insumo-da-ordem-de-producao";
import { InsumoEstado } from "../../utils/enum/insumo-da-ordem-de-producao-enums";
import ContextoOperacoesInsumo from "../contexto-funcoes-insumo";

const schema = yup.object().shape({
  quantidadeAEstornar: yup
    .number()
    .required()
    .when("tipoDeEstorno", {
      is: TipoDeEstornoInsumo.Quantidade,
      then: (sch) => {
        return sch.moreThan(0);
      },
      otherwise: (sch) => {
        return sch;
      },
    }),
});

export const ModalEstornarInsumo = () => {
  const [insumoEstornar, setInsumoEstornar] = useState<
    InsumoDaOrdemDeProducaoGridModel | undefined
  >(undefined);
  const [dataSourceSelecionarEstoque, setDataSourceSelecionarEstoque] =
    useState<ArrayStore | undefined>(undefined);

  const { funcoes } = useContext(ContextoOperacoesInsumo);

  const { control, getValues, handleSubmit, setValue, reset, watch } =
    useForm<DadosModalEstornoDeInsumos>({
      resolver: yupResolver(schema),
    });

  const tipoDeEstorno = watch("tipoDeEstorno");
  const [quantidadeVisivel, setQuantidadeVisivel] = useState(true);
  const [comboTipoDeEstornoHabilitado, setComboTipoDeEstornoHabilitado] =
    useState(true);

  useEffect(() => {
    funcoes.definfirSetRegistroEstornar(
      (insumo: InsumoDaOrdemDeProducaoGridModel) => {
        setInsumoEstornar(insumo);
      }
    );
  }, []);

  // Controla a lógica do combo de estorno, junto com a quantidade de estorno
  useEffect(() => {
    reset();

    if (!insumoEstornar) {
      return;
    }

    let tipoDeEstorno: TipoDeEstornoInsumo = TipoDeEstornoInsumo.Quantidade;
    let comboTipoEstornoHabilitado = true;

    // Está estornando um insumo que possui número de série (modal de listagem de NS)
    if (insumoEstornar.loteDoFabricanteOuNumeroDeSerieId) {
      tipoDeEstorno = TipoDeEstornoInsumo.Quantidade;
      comboTipoEstornoHabilitado = false;
    } else if (insumoEstornar.estado == InsumoEstado.BaixadoCompletamente) {
      if (insumoEstornar.isProcedenciaAlternativo) {
        tipoDeEstorno = TipoDeEstornoInsumo.DesfazerConclusaoSemEstornarBaixas;
        comboTipoEstornoHabilitado = false;
      } else {
        tipoDeEstorno = TipoDeEstornoInsumo.Quantidade;
        comboTipoEstornoHabilitado = true;
      }

      if (insumoEstornar.quantidadeBaixada == 0) {
        tipoDeEstorno = TipoDeEstornoInsumo.DesfazerConclusaoSemEstornarBaixas;
        comboTipoEstornoHabilitado = true;
      }
    } else if (
      (insumoEstornar.estado == InsumoEstado.ABaixar ||
        insumoEstornar.estado == InsumoEstado.BaixadoParcialmente) &&
      !insumoEstornar.isProcedenciaAlternativo
    ) {
      tipoDeEstorno = TipoDeEstornoInsumo.Quantidade;
      comboTipoEstornoHabilitado = false;
    }

    setValue("quantidadeAEstornar", insumoEstornar?.quantidadeBaixada ?? 0);
    setValue("tipoDeEstorno", tipoDeEstorno);
    setComboTipoDeEstornoHabilitado(comboTipoEstornoHabilitado);
  }, [insumoEstornar, setValue, reset]);

  useEffect(() => {
    if (tipoDeEstorno == TipoDeEstornoInsumo.Quantidade) {
      setValue("quantidadeAEstornar", insumoEstornar?.quantidadeBaixada ?? 0);
      setQuantidadeVisivel(true);
    }

    if (
      tipoDeEstorno == TipoDeEstornoInsumo.DesfazerConclusaoSemEstornarBaixas
    ) {
      setValue("quantidadeAEstornar", 0);
      setQuantidadeVisivel(false);
    }
  }, [tipoDeEstorno]);

  function fechar() {
    setInsumoEstornar(undefined);
    funcoes.atualizaGridsAoFechar();
  }

  const fecharModal = () => {
    setDataSourceSelecionarEstoque(undefined);

    fechar();
  };

  async function confirmarEstorno() {
    const model = getValues();

    if (model.tipoDeEstorno == TipoDeEstornoInsumo.Quantidade) {
      await confirmarEstornoComQuantidade();
      return;
    }

    await confirmarDesfeitaDeConclusaoDeBaixa();
  }

  async function confirmarDesfeitaDeConclusaoDeBaixa() {
    try {
      const resposta =
        await InsumoDaOrdemDeProducaoServico.DesfazerConclusaoDeBaixaDoInsumo(
          insumoEstornar?.id ?? 0
        );

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: "Conclusão de baixa insumo desfeita com sucesso.",
          tipo: TipoNotificacao.Sucesso,
        });

        fechar();
      }
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  async function confirmarEstornoComQuantidade() {
    const model = getValues();
    const quantidadeAEstornar = model.quantidadeAEstornar;
    const quantidadeBaixada = insumoEstornar?.quantidadeBaixada ?? 0;

    if (quantidadeAEstornar > quantidadeBaixada) {
      const confirmacao = await exibirConfirmacao(
        "Aviso",
        `A quantidade a estornar (${formatarNumeroQuantidade(
          quantidadeAEstornar
        )}) é maior que a quantidade total 
            já baixada do insumo (${formatarNumeroQuantidade(
              quantidadeBaixada
            )}). 
            Deseja prosseguir com o estorno da quantidade total já baixada?`
      );

      if (!confirmacao) {
        return;
      }
    }
    try {
      const resposta = await InsumoDaOrdemDeProducaoServico.EstornarInsumo(
        insumoEstornar?.id ?? 0,
        quantidadeAEstornar ?? 0,
        insumoEstornar?.loteDoFabricanteOuNumeroDeSerieId
      );

      if (resposta.sucesso) {
        if (resposta.precisaSelecionarMovs) {
          setDataSourceSelecionarEstoque(
            new ArrayStore({
              data: resposta.movimentacoes,
              key: "id",
            })
          );
        } else {
          exibirNotificacaoToast({
            mensagem: "Insumo estornado com sucesso.",
            tipo: TipoNotificacao.Sucesso,
          });

          fechar();
        }
      }
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  return (
    <>
      <ModalMxp
        titulo={"Desfazer/estornar baixa de insumo"}
        visivel={insumoEstornar != undefined}
        handleFechar={fechar}
        larguraMaxima={"max(30vw, 400px)"}
      >
        <FormMxp>
          <FormGrupo>
            <Linha>
              <Coluna md={12}>
                <FormTextBox
                  name="descricaoInsumo"
                  titulo="Insumo"
                  control={control}
                  somenteLeitura
                  defaultValue={`${ObterDescricaoInsumo(insumoEstornar)}`}
                  style={{ marginBottom: "10px" }}
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={8}>
                <FormSelectBox
                  control={control}
                  dataSource={TipoDeEstornoInsumoSelectItems}
                  name={"tipoDeEstorno"}
                  desabilitado={!comboTipoDeEstornoHabilitado}
                  ordenacaoDosDados={OrdenacaoSelectBox.Desc}
                />
              </Coluna>
              <Coluna md={4}>
                {quantidadeVisivel && (
                  <FormNumberBox
                    name="quantidadeAEstornar"
                    titulo={CaracterInvisivel}
                    control={control}
                    formato={obterFormatStringNumero(5)}
                    minimo={0}
                  />
                )}
              </Coluna>
            </Linha>
          </FormGrupo>
        </FormMxp>
        <ToolbarMxp dadosAuditoria={undefined}>
          <BotaoConfirmarMxp handleClick={handleSubmit(confirmarEstorno)} />
          <BotaoCancelarMxp handleClick={fechar} />
        </ToolbarMxp>
      </ModalMxp>
      <ModalSelecionarMovimentacoesParaEstornar
        dados={dataSourceSelecionarEstoque}
        fecharModal={fecharModal}
        mensagens={{
          erro: "Ocorreu um erro ao estornar o insumo.",
          sucesso: "Insumo estornado com sucesso!",
        }}
      />
    </>
  );
};
