import { useMemo } from "react";
import { FieldValues, UseControllerProps } from "react-hook-form";
import { FormSelectBoxLazyMxp } from "../../../../../components/formularios";
import { ItemSelectionChangedType } from "../../../../../components/formularios/selectbox-lazy-mxp";
import { DataSourceOpcoesBuilder } from "../../../../../utils/grid/data-source-factory";
import { InsumoDaOrdemDeProducaoGridModel } from "../../models/insumo-da-ordem-de-producao";
import { InsumoDaOrdemDeProducaoServico } from "../../servicos/insumo-da-ordem-de-producao";

interface ComboInsumoMxpProps<T extends FieldValues>
  extends UseControllerProps<T> {
  titulo: string;
  requerido?: boolean;
  somenteLeitura?: boolean;
  dataSourceOpcoes?: DataSourceOpcoesBuilder<InsumoDaOrdemDeProducaoGridModel>;
  onSelectionChanged?:
    | ((e: ItemSelectionChangedType) => Promise<void>)
    | (() => void);
}

interface ComboInsumoPorOpMxpProps<T extends FieldValues>
  extends ComboInsumoMxpProps<T> {
  idOrdemDeProducao: number;
}

export default function ComboInsumoPorOpMxp<T extends FieldValues>(
  props: ComboInsumoPorOpMxpProps<T>
) {
  const dataSourceOpcoes = useMemo(() => {
    return {
      ...props.dataSourceOpcoes,
      camposFiltro: [
        ...(props.dataSourceOpcoes?.camposFiltro ?? []),
        {
          campo: "ordemDeProducaoId",
          operador: "=",
          valor: props.idOrdemDeProducao,
        },
      ],
    } as DataSourceOpcoesBuilder<InsumoDaOrdemDeProducaoGridModel>;
  }, [props.dataSourceOpcoes, props.idOrdemDeProducao]);

  return <ComboInsumoMxp {...props} dataSourceOpcoes={dataSourceOpcoes} />;
}

export function ComboInsumoMxp<T extends FieldValues>(
  props: ComboInsumoMxpProps<T>
) {
  const [dataSource, configuracoesExibicaoEBusca] = useMemo(
    () =>
      InsumoDaOrdemDeProducaoServico.GetDadosSelectBoxItem(
        props.dataSourceOpcoes
      ),
    [props.dataSourceOpcoes]
  );

  return (
    <FormSelectBoxLazyMxp
      name={props.name}
      titulo={props.titulo}
      control={props.control}
      requerido={props.requerido}
      somenteLeitura={props.somenteLeitura}
      onSelectionChanged={props.onSelectionChanged}
      configuracoesExibicaoEBusca={configuracoesExibicaoEBusca}
      dataSource={dataSource}
      labelSemDados="Sem dados"
    />
  );
}
