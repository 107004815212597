import { SituacaoMDFe } from "../../models/mdfe-enums";

export interface VisibilidadeBotoesMDfe {
  salvar: boolean;
  cancelar: boolean;
  validar: boolean;
  autorizar: boolean;
  encerrar: boolean;
  cancelarMdfe: boolean;
  visualizarPDF: boolean;
  baixarXML: boolean;
  desfazerValidacao: boolean;
  consultarSituacao: boolean;
}

export const VisibilidadeBotoesMDFe = new Map<
  SituacaoMDFe,
  VisibilidadeBotoesMDfe
>([
  [
    SituacaoMDFe.EmEdicao,
    {
      salvar: true,
      cancelar: true,
      validar: true,
      autorizar: false,
      encerrar: false,
      cancelarMdfe: false,
      visualizarPDF: false,
      baixarXML: false,
      desfazerValidacao: false,
      consultarSituacao: false,
    },
  ],
  [
    SituacaoMDFe.Validado,
    {
      salvar: true,
      cancelar: true,
      validar: false,
      autorizar: true,
      encerrar: false,
      cancelarMdfe: false,
      visualizarPDF: false,
      baixarXML: false,
      desfazerValidacao: true,
      consultarSituacao: false,
    },
  ],
  [
    SituacaoMDFe.Autorizado,
    {
      salvar: true,
      cancelar: true,
      validar: false,
      autorizar: false,
      encerrar: true,
      cancelarMdfe: true,
      visualizarPDF: true,
      baixarXML: true,
      desfazerValidacao: false,
      consultarSituacao: true,
    },
  ],
  [
    SituacaoMDFe.Encerrado,
    {
      salvar: false,
      cancelar: true,
      validar: false,
      autorizar: false,
      encerrar: false,
      cancelarMdfe: false,
      visualizarPDF: true,
      baixarXML: true,
      desfazerValidacao: false,
      consultarSituacao: true,
    },
  ],
  [
    SituacaoMDFe.Cancelado,
    {
      salvar: false,
      cancelar: true,
      validar: false,
      autorizar: false,
      encerrar: false,
      cancelarMdfe: false,
      visualizarPDF: true,
      baixarXML: true,
      desfazerValidacao: false,
      consultarSituacao: true,
    },
  ],
  [
    SituacaoMDFe.Rejeitado,
    {
      salvar: true,
      cancelar: true,
      validar: false,
      autorizar: true,
      encerrar: false,
      cancelarMdfe: false,
      visualizarPDF: false,
      baixarXML: false,
      desfazerValidacao: true,
      consultarSituacao: true,
    },
  ],
]);
