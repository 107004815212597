import { Fragment } from "react/jsx-runtime";
import { AccordionModel } from "../../../../../../components/dialogos/modal-accordion/modal-accordion";
import { ErroApi } from "../../../../../../models/api/comum/erros";
import { ResponseModel } from "../../../../../../models/api/comum/response-base";
import exibirNotificacaoToast, {
  JanelasDeNotificacaoTitulos,
  TipoNotificacao,
} from "../../../../../../utils/common/notificacoes-utils";
import { exibirAlerta } from "../../../../../../utils/dialogos";
import { renderToStringClient } from "../../../../../../utils/react/react-utils";
import {
  IAcaoEmMassa,
  formatarMensagensAcaoAccordion,
} from "../../../../../comum/utils/accordions/gerador-de-mensagens";
import { AcaoBaseOrdemDeProducaoResponse } from "../../../models/ordem-de-producao.api";

export interface AcoesOrdensDeProducao extends IAcaoEmMassa {
  acao: string;
}

async function handlerNotificacoesUnicoRegistro(
  tituloDaCaixaDeAlerta: string,
  mensagemInicial: string,
  listaDeErros: ErroApi[]
) {
  if (listaDeErros.length == 1) {
    await exibirAlerta(
      tituloDaCaixaDeAlerta,
      listaDeErros[0].mensagem.replace(/\n/g, "<br/>")
    );

    return;
  }

  const conteudoHtmlComErros = (
    <div style={{ textAlign: "left", marginLeft: "20px" }}>
      <p style={{ fontWeight: "bold" }}>{mensagemInicial}</p>
      <ul>
        {listaDeErros.map((erro, index) => (
          <li key={index} style={{ marginLeft: "20px" }}>
            {erro.mensagem.split("\n").map((line, idx) => (
              <Fragment key={idx}>
                {line}
                <br />
              </Fragment>
            ))}
          </li>
        ))}
      </ul>
    </div>
  );

  await exibirAlerta(
    tituloDaCaixaDeAlerta,
    renderToStringClient(conteudoHtmlComErros)
  );
}

function formatarMensagensAcaoOrdensDeProducaoAccordion<
  T extends AcaoBaseOrdemDeProducaoResponse
>(
  ordensDeProducaoAcaoSucesso: T[],
  ordensDeProducaoAcaoSemSucesso: T[],
  acoes: AcoesOrdensDeProducao
): AccordionModel[] {
  return formatarMensagensAcaoAccordion(
    ordensDeProducaoAcaoSucesso,
    ordensDeProducaoAcaoSemSucesso,
    {
      ...acoes,
      entidadeSingular: "ordem de produção",
      entidadePlural: "ordens de produção",
      formatarEntidade: (x) => x.ordemDeProducaoNumero ?? "sem número",
      verboPlural: "foram",
      verboSingular: "foi",
    }
  );
}

function handlerAcaoOrdensDeProducaoResponse<
  T extends AcaoBaseOrdemDeProducaoResponse
>(response: ResponseModel<T[]>, acoes: AcoesOrdensDeProducao) {
  if (response.sucesso) {
    const resultadosDaConfirmacao = response.model;
    const ordensDeProducaoAcaoSucesso = resultadosDaConfirmacao.filter(
      (x) => x.valido
    );
    const ordensDeProducaoAcaoSemSucesso = resultadosDaConfirmacao.filter(
      (x) => !x.valido
    );
    if (response.model.length == 1) {
      ordensDeProducaoAcaoSemSucesso.length > 0
        ? handlerNotificacoesUnicoRegistro(
            JanelasDeNotificacaoTitulos.Atencao,
            `Não foi possível ${acoes.acao} a ordem de produção. Veja os motivos abaixo:`,
            ordensDeProducaoAcaoSemSucesso[0].listaDeErros
          )
        : exibirNotificacaoToast({
            mensagem: `Ordem de produção ${acoes.acaoSingular} com sucesso.`,
            tipo: TipoNotificacao.Sucesso,
          });
    } else {
      return handlerNotificacoesConfirmarMultiplosRegistros(
        ordensDeProducaoAcaoSucesso,
        ordensDeProducaoAcaoSemSucesso,
        acoes
      );
    }
  }
}

function handlerNotificacoesConfirmarMultiplosRegistros<
  T extends AcaoBaseOrdemDeProducaoResponse
>(
  ordensDeProducaoAcaoSucesso: T[],
  ordensDeProducaoAcaoSemSucesso: T[],
  acoes: AcoesOrdensDeProducao
) {
  const mensagem = formatarMensagensAcaoOrdensDeProducaoAccordion(
    ordensDeProducaoAcaoSucesso,
    ordensDeProducaoAcaoSemSucesso,
    acoes
  );
  return {
    accordionVisivel: true,
    mensagem: mensagem,
  };
}

export {
  handlerAcaoOrdensDeProducaoResponse,
  handlerNotificacoesUnicoRegistro,
};
