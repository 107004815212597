import TabContainer from "../../../../../../../components/layout/tab-container";
import ProvedorMenus from "../../../../../../../components/menus/provedor-menus";
import RequerPermissao from "../../../../../../../components/seguranca/requer-permissao";
import { PermissoesEstoqueMovimentacao } from "../../../../../../../models/permissoes/estoque/estoque-movimentacao/permissoes-estoque-movimentacao";
import GridEmbutidaRetiradaDeApara from "../../../../../../estoque/estoque-movimentacao/componentes/grids/grid-retirada-de-apara";
import { OrdemDeProducaoEstado } from "../../../../utils/enums/ordem-de-producao-enums";

interface OrdemDeProducaoAbaRetiradaDeAparaProps {
  ordemDeProducaoId: number;
  ordemDeProducaoEstado?: OrdemDeProducaoEstado;
  ordemDeProducaoNumero?: number;
}

export default function OrdemDeProducaoAbaRetiradaDeApara(
  props: OrdemDeProducaoAbaRetiradaDeAparaProps
) {
  return (
    <TabContainer>
      <ProvedorMenus>
        <RequerPermissao
          codigoPermissoes={[PermissoesEstoqueMovimentacao.Consultar]}
        >
          <GridEmbutidaRetiradaDeApara {...props} style={{ height: "100%" }} />
        </RequerPermissao>
      </ProvedorMenus>
    </TabContainer>
  );
}
