import DataSource from "devextreme/data/data_source";
import {
  assertConfiguracaoExibicaoEBuscaType,
  ConfiguracaoExibicaoEBusca,
} from "../../../../components/formularios/selectbox-lazy-mxp";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";
import { EstacaoDeTrabalhoGridModel } from "../models/estacao-de-trabalho";

class EstacaoDeTrabalhoService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.EstacaoDeTrabalho);
  }

  public GetDadosSelectBoxEstacaoDeTrabalho(): [
    DataSource<EstacaoDeTrabalhoGridModel, any>,
    ConfiguracaoExibicaoEBusca<EstacaoDeTrabalhoGridModel>
  ] {
    const dataSource =
      this.ObterDataSourceParaSelectBoxLazy<EstacaoDeTrabalhoGridModel>({
        camposRetorno: ["id", "codigo", "descricao"],
        camposOrdenacao: [{ campo: "codigo", desc: false }],
        camposFiltro: [{ campo: "ativo", operador: "=", valor: true }],
      });

    const configs =
      assertConfiguracaoExibicaoEBuscaType<EstacaoDeTrabalhoGridModel>({
        nomeCampoChave: "id",
        expressaoDeBusca: ["codigo"],
        nomeCampoExibicao: (c) => {
          if (c) {
            return c.descricao == null
              ? `${c.codigo}`
              : `${c.codigo} (${c.descricao})`;
          }

          return "";
        },
      });

    return [dataSource, configs];
  }
}

export const EstacaoDeTrabalhoServico = new EstacaoDeTrabalhoService();
