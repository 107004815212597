import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";

class VinculoEnderecoEstoqueCentroDeTrabalhoService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.VinculoEnderecosDeEstoqueCentroDeTrabalho);
  }
}

export const VinculoEnderecoEstoqueCentroDeTrabalhoServico =
  new VinculoEnderecoEstoqueCentroDeTrabalhoService();
