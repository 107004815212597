export enum NomesEndpoints {
  Cfop = "Cfop",
  CentroDeTrabalho = "CentroDeTrabalho",
  SerieFiscal = "SerieFiscal",
  CentroDeTrabalhoGrupo = "CentroDeTrabalhoGrupo",
  PrefixoESufixoDeLote = "PrefixoESufixoDeLote",
  OrdemDeProducao = "OrdemDeProducao",
  VinculoEnderecosDeEstoqueCentroDeTrabalho = "VinculoEnderecoEstoqueCentroDeTrabalho",
  GrupoDeCentroDeTrabalhoCalendario = "GrupoDeCentroDeTrabalhoCalendario",
  TributacaoDeServicoCodigo = "TributacaoDeServicoCodigo",
  CentroDeCustos = "CentroDeCustos",
  Empresa = "Empresa",
  EnderecoEstoque = "EnderecoDeEstoque",
  Usuario = "Usuario",
  VeiculoConjunto = "VeiculoConjunto",
  Veiculo = "Veiculo",
  MDFe = "MDFe",
  Login = "Login",
  CertificadoDigital = "CertificadoDigital",
  Lote = "Lote",
  Item = "Item",
  Configuracoes = "Configuracoes",
  NaturezaDoRendimento = "NaturezaDoRendimento",
  Versao = "Versao",
  EfdReinfApuracao = "EfdReinfApuracao",
  BalanceteComparativo = "BalanceteComparativo",
  EfdReinf = "EfdReinf",
  Ecf = "Ecf",
  ContaParteB = "ContaDaParteB",
  ContaPadraoParteB = "ContaPadraoDaParteB",
  ApuracaoLacsCsll = "ApuracaoLacsCsll",
  ApuracaoLalurIrpj = "ApuracaoLalurIrpj",
  LancamentoNaParteBDoLalur = "LancamentoNaParteBDoLalur",
  LancamentoNaParteBDoLacs = "LancamentoNaParteBDoLacs",
  InformativoComposicaoCustosLucroReal = "ApuracaoDoInformativoDaComposicaoDeCustosDaEcf",
  AdicaoExclusaoCompensacaoApuracaoLalurIrpj = "AdicaoExclusaoCompensacaoApuracaoLalurIrpj",
  AdicaoExclusaoCompensacaoLalurIrpj = "AdicaoExclusaoCompensacaoLalur",
  AdicaoExclusaoCompensacaoApuracaoLacsCsll = "AdicaoExclusaoCompensacaoApuracaoLacsCsll",
  AdicaoExclusaoCompensacaoLacsCsll = "AdicaoExclusaoCompensacaoLacs",
  ApuracaoInformativoComposicaoCustosEcf = "ApuracaoDoInformativoDaComposicaoDeCustosDaEcf",
  LinhaApuracaoDoInformativoDaComposicaoDeCustosDaEcf = "LinhaApuracaoDoInformativoDaComposicaoDeCustosDaEcf",
  DemonstrativoDeSaldosDaParteBDoLalur = "DemonstrativoDeSaldosDaParteBDoLalur",
  DemonstrativoDeSaldosDaParteBDoLacs = "DemonstrativoDeSaldosDaParteBDoLacs",
  InsumoDaOrdemDeProducao = "InsumoDaOrdemDeProducao",
  OperacaoDeOrdemDeProducao = "OperacaoDeOrdemDeProducao",
  Estoque = "Estoque",
  EstoqueMovimentacao = "EstoqueMovimentacao",
  Imobilizado = "Imobilizado",
  ContaContabil = "ContaContabil",
  UnidadeMedida = "UnidadeMedida",
  ValorOpcao = "ValorOpcao",
  NotaFiscal = "NotaFiscal",
  ItemNotaFiscalRecebida = "ItemNotaFiscalRecebida",
  ApuracaoDoCiap = "ApuracaoDoCiap",
  MovimentacaoDoImobilizado = "MovimentacaoDoImobilizado",
  EstacaoDeTrabalho = "EstacaoDeTrabalho",
  ControleDeAcesso = "ControleDeAcesso",
  EstadoConfiguravelDaOrdemDeProducao = "EstadoConfiguravelDaOrdemDeProducao",
  Permissoes = "Permissoes",
  Assinatura = "Assinatura",
  PedidoDeVenda = "PedidoDeVenda",
  ItemPedidoDeVenda = "ItemPedidoDeVenda",
  Reserva = "Reserva",
  RelatorioPersonalizado = "RelatorioPersonalizado",
  TarefaOuAnotacao = "TarefaOuAnotacao",
  Contato = "Contato",
  LinkDaAjuda = "LinkDaAjuda",
  ModeloDeDocumentoOuRelatorio = "ModeloDeDocumentoOuRelatorio",
  EstruturaDeProduto = "EstruturaDeProduto",
  PreferenciasGrade = "PreferenciasGrade",
  ProjecaoDeUso = "ProjecaoDeUso",
  RazaoPorIntervaloDeContas = "RazaoPorIntervaloDeContas",
}
