import DataSource from "devextreme/data/data_source";
import {
  assertConfiguracaoExibicaoEBuscaType,
  ConfiguracaoExibicaoEBusca,
} from "../../../../components/formularios/selectbox-lazy-mxp";
import getApi from "../../../../configs/api";
import { ResponseModel } from "../../../../models/api/comum/response-base";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";
import { DataSourceFiltragem } from "../../../../utils/grid/data-source-factory";
import {
  PrefixoESufixoDeLoteGridModel,
  PrefixoOuSufixoParametros,
  VerificarDadosEncontradosNaTranscricaoDoPrefixoESufixoResponse,
} from "../models/prefixo-e-sufixo-de-lote";

class PrefixoESufixoDeLoteService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.PrefixoESufixoDeLote);
  }

  public GetDadosSelectBoxPrefixoESufixoDeLote(
    filtros:
      | DataSourceFiltragem<PrefixoESufixoDeLoteGridModel>[]
      | undefined = undefined
  ): [
    DataSource<PrefixoESufixoDeLoteGridModel, any>,
    ConfiguracaoExibicaoEBusca<PrefixoESufixoDeLoteGridModel>
  ] {
    const dataSource =
      this.ObterDataSourceParaSelectBoxLazy<PrefixoESufixoDeLoteGridModel>({
        camposRetorno: ["id"],
        camposOrdenacao: [{ campo: "prefixo", desc: false }],
        camposFiltro: filtros,
      });

    const configs =
      assertConfiguracaoExibicaoEBuscaType<PrefixoESufixoDeLoteGridModel>({
        nomeCampoChave: "id",
        expressaoDeBusca: ["prefixo"],
        nomeCampoExibicao: (c) => {
          if (c) {
            return `${c.prefixo}`;
          }

          return "";
        },
      });

    return [dataSource, configs];
  }

  public async ObterPrefixoESufixoDeLoteDoItem(itemId: number) {
    const api = getApi();
    const response = await api.get<ResponseModel<string>>(
      `${this._nomeEndpoint}/ObterPrefixoESufixoDeLoteDoItem?itemId=${itemId}`
    );

    return response.data.model;
  }

  public async VerificarSeTranscricaoDePrefixoOuSufixoNaoEncontrouAlgumDadoAsync(
    parametros: PrefixoOuSufixoParametros
  ) {
    const api = getApi();
    const response = await api.get<
      ResponseModel<VerificarDadosEncontradosNaTranscricaoDoPrefixoESufixoResponse>
    >(
      `${
        this._nomeEndpoint
      }/VerificarSeTranscricaoDePrefixoOuSufixoNaoEncontrouAlgumDado?prefixoOuSufixoId=${
        parametros.prefixoOuSufixoId
      }&prefixoOuSufixo=${parametros.prefixoOuSufixo ?? ""}&itemId=${
        parametros.itemId
      }&grupoId=${parametros.grupoId}&numeroDaOrdemDeProducao=${
        parametros.numeroDaOrdemDeProducao ?? ""
      }`
    );

    return response.data.model;
  }
}

export const PrefixoESufixoDeLoteServico = new PrefixoESufixoDeLoteService();
