import { Button } from "devextreme-react";

interface BotaoBaixarXmlMxpProps {
  handleClick: () => void;
}

export default function BotaoBaixarXmlMxp(props: BotaoBaixarXmlMxpProps) {
  return (
    <Button
      type="normal"
      text="Baixar XML"
      icon="download"
      onClick={props.handleClick}
    />
  );
}
