import { yupResolver } from "@hookform/resolvers/yup";
import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import {
  FormDateBox,
  FormNumberBox,
  FormSelectBox,
} from "../../../../../../components/formularios";
import { Coluna, Linha } from "../../../../../../components/layout/grid-system";
import TabContainer from "../../../../../../components/layout/tab-container";
import {
  SelectItemEnumModalidadeTransporteMDFe,
  SelectItemEnumTipoEmitenteMDFe,
  SelectItemEnumTipoTransportadorMDFe,
} from "../../../../../../models/const/dicionario-combos/mdfe";
import { IFormulario } from "../../../../../../models/shared/ui/formularios";
import SelectItem from "../../../../../../models/shared/ui/select-item";
import { MDFeAbaGeralViewModel } from "../../../../../../models/viewmodels/vendas/mdfe/mdfe-edit-form-view-model";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../../utils/api/api-utils";
import { checarSeFormFoiModificado } from "../../../../../../utils/common/form-utils";
import { obterFormatStringNumero } from "../../../../../../utils/formatadores/formatador-de-numeros";
import yup from "../../../../../../utils/validacao/validacao";
import ComboEmpresaMxp from "../../../../../sistema/empresa/componentes/select-box-lazy";
import { TipoDocumentoEletronico } from "../../../../serie-fiscal/models/serie-fiscal.enums";
import { SerieFiscalServico } from "../../../../serie-fiscal/servicos/serie-fiscal.service";
import MDFeEditFormContext from "../../../contexts/mdfe-editform.context";
import {
  ModalidadeTransporteMDFe,
  TipoEmitenteMDFe,
  TipoTransportadorMDFe,
} from "../../../models/mdfe-enums";

interface DadosGeraisProps {
  abaSomenteLeitura: boolean;
}

const schema = yup.object().shape({
  id: yup.number().required().moreThan(-1).integer(),
  serie: yup.string().required().max(3),
  numero: yup
    .number()
    .nullable()
    .positive()
    .integer()
    .max(9999999999)
    .transform((valor) => (valor ? valor : null)),
  dataHoraEmissao: yup
    .string() //Tratado como string pois o redux não lida com Date
    .required()
    .transform((valor) => (valor ? valor : null)),
  modalidadeTransporte: yup
    .mixed<ModalidadeTransporteMDFe>()
    .transform((v) => (v ? v : null))
    .oneOf(
      Object.values(ModalidadeTransporteMDFe).map((x) => x as number),
      "Valor inválido"
    )
    .required(),
  idContratante: yup.number().required().moreThan(0).integer(),
  tipoTransportador: yup
    .mixed<TipoTransportadorMDFe>()
    .nullable()
    .oneOf(
      Object.values(TipoTransportadorMDFe).map((x) => x as number),
      "Valor inválido"
    ),
  tipoEmitente: yup
    .mixed<TipoEmitenteMDFe>()
    .transform((v) => (v ? v : null))
    .oneOf(
      Object.values(TipoEmitenteMDFe).map((x) => x as number),
      "Valor inválido"
    )
    .required(),
  dataHoraInicioViagem: yup
    .string() //Tratado como string pois o redux não lida com Date
    .notRequired()
    .nullable()
    .transform((valor) => (valor ? valor : null)),
});

// Cria um componente referenciável
export const MDFeAbaDadosGerais = forwardRef(
  ({ abaSomenteLeitura }: DadosGeraisProps, ref) => {
    const {
      baseMdfe,
      dadosGerais,
      contratante,
      dadosPercurso,
      definirDadosGerais,
      definirTipoTransportador,
      definirTipoEmitente,
    } = useContext(MDFeEditFormContext);

    const model: MDFeAbaGeralViewModel = useMemo(() => {
      return {
        id: baseMdfe.id,
        serie: dadosGerais.serie,
        numero: dadosGerais.numero,
        dataHoraEmissao: dadosGerais.dataHoraEmissao,
        modalidadeTransporte: dadosGerais.modalidadeTransporte,
        tipoTransportador: dadosGerais.tipoTransportador,
        tipoEmitente: dadosGerais.tipoEmitente,
        dataHoraInicioViagem: dadosGerais.dataHoraInicioViagem,
        idUfInicio: dadosPercurso.idUfInicio,
        idUfFim: dadosPercurso.idUfFim,
        idContratante: contratante?.idContratante,
        cepLocalCarregamento: dadosPercurso.cepLocalCarregamento,
        cepLocalDescarregamento: dadosPercurso.cepLocalDescarregamento,
      };
    }, [baseMdfe, contratante, dadosGerais, dadosPercurso]);

    const [seriesFiscais, setSerieFiscal] = useState<SelectItem[]>([]);

    const carregarSeriesFiscais = useCallback(async () => {
      try {
        const resposta = await SerieFiscalServico.obterListaSimples(
          TipoDocumentoEletronico.MDFe
        );
        checarResponse(resposta);
        setSerieFiscal(
          resposta.model.map((x) => ({
            valor: x.descricao,
            descricao: x.descricao,
          }))
        );
      } catch (erro) {
        tratarErroApi(erro);
      }
    }, [setSerieFiscal]);

    const { setValue, reset, control, formState, handleSubmit } =
      useForm<MDFeAbaGeralViewModel>({
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: yupResolver(schema),
      });

    const form = useRef<HTMLFormElement | null>(null);

    useEffect(() => {
      // Adicionado pois o form não é atualizado conforme mudança no redux.
      setValue("numero", model.numero);
    }, [model.numero, setValue]);

    useEffect(() => {
      setValue("dataHoraEmissao", model.dataHoraEmissao);
    }, [model.dataHoraEmissao, setValue]);

    const carregarTela = useCallback(async () => {
      reset(model);
      carregarSeriesFiscais();
    }, [reset, carregarSeriesFiscais, model]);

    useEffect(() => {
      if (!Number.isNaN(baseMdfe.id)) {
        carregarTela();
      }
    }, [model.id]);

    const handleSubmitInterno = useCallback(
      (data: MDFeAbaGeralViewModel) => {
        definirDadosGerais(data);
      },
      [definirDadosGerais]
    );

    // Repassar referências para componente pai
    useImperativeHandle(
      ref,
      (): IFormulario => ({
        requestSubmit() {
          form.current?.requestSubmit();
        },
        valido() {
          form.current?.requestSubmit();
          return Object.keys(formState.errors).length == 0;
        },
        isDirty() {
          return checarSeFormFoiModificado(formState);
        },
      }),
      [formState, form]
    );

    const onChangeTipoTransportador = useCallback(
      (e: TipoTransportadorMDFe) => {
        definirTipoTransportador(e);
      },
      [definirTipoTransportador]
    );

    const onChangeTipoEmitente = useCallback(
      (e: TipoEmitenteMDFe) => {
        definirTipoEmitente(e);
      },
      [definirTipoEmitente]
    );

    return (
      <TabContainer>
        <form ref={form} onSubmit={handleSubmit(handleSubmitInterno)}>
          <Linha>
            <Coluna lg={3} md={6}>
              <FormNumberBox
                name="numero"
                titulo="Número"
                control={control}
                minimo={0}
                tipo="number"
                maximo={9999999999}
                formato={obterFormatStringNumero(0)}
                exibirBotaoLimpar
                somenteLeitura={true}
                alinharNaDireita={false}
              />
            </Coluna>
            <Coluna lg={2} md={6}>
              <FormSelectBox
                name="serie"
                titulo="Série"
                toolTip="Série"
                control={control}
                requerido
                dataSource={seriesFiscais}
                habilitaBusca
                tipoBusca="contains"
                somenteLeitura={abaSomenteLeitura}
              />
            </Coluna>
            <Coluna lg={4} md={6}>
              <FormDateBox
                name="dataHoraEmissao"
                titulo="Emissão"
                toolTip="Emissão"
                control={control}
                requerido
                somenteLeitura={abaSomenteLeitura}
                aceitaValorCustomizado={true}
                aceitaDigitacaoComMascara={true}
              />
            </Coluna>
            <Coluna lg={3} md={6}>
              <FormSelectBox
                name="modalidadeTransporte"
                titulo="Modalidade"
                toolTip="Modalidade"
                control={control}
                requerido
                dataSource={SelectItemEnumModalidadeTransporteMDFe}
                habilitaBusca
                tipoBusca="contains"
                somenteLeitura
              />
            </Coluna>
          </Linha>
          <Linha>
            <Coluna lg={6} md={8}>
              <ComboEmpresaMxp
                name="idContratante"
                titulo="Contratante"
                control={control}
                requerido
                somenteLeitura={abaSomenteLeitura}
              />
            </Coluna>
            <Coluna lg={3} md={4}>
              <FormSelectBox
                name="tipoTransportador"
                titulo="Tipo do transportador"
                toolTip="Tipo do transportador"
                control={control}
                dataSource={SelectItemEnumTipoTransportadorMDFe}
                habilitaBusca
                tipoBusca="contains"
                somenteLeitura={abaSomenteLeitura}
                onValueChange={onChangeTipoTransportador}
              />
            </Coluna>
            <Coluna lg={3} md={6}>
              <FormSelectBox
                name="tipoEmitente"
                titulo="Tipo do emitente"
                toolTip="Tipo do emitente"
                control={control}
                requerido
                dataSource={SelectItemEnumTipoEmitenteMDFe}
                habilitaBusca
                tipoBusca="contains"
                somenteLeitura={abaSomenteLeitura}
                onValueChange={onChangeTipoEmitente}
              />
            </Coluna>
            <Coluna lg={3} md={6}>
              <FormDateBox
                name="dataHoraInicioViagem"
                titulo="Início da viagem"
                toolTip="Início da viagem"
                control={control}
                somenteLeitura={abaSomenteLeitura}
                aceitaDigitacaoComMascara={true}
              />
            </Coluna>
          </Linha>
        </form>
      </TabContainer>
    );
  }
);

export default MDFeAbaDadosGerais;
