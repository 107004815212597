import { PermissoesOrdemDeProducao } from "../../../../../../models/permissoes/producao/ordem-de-producao/OrdemDeProducaoPermissoes";
import { tratarErroApi } from "../../../../../../utils/api/api-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../../utils/common/permissoes-utils";
import { exibirConfirmacao } from "../../../../../../utils/dialogos";
import { OrdemDeProducaoGridModel } from "../../../models/ordem-de-producao.api";
import { OrdemDeProducaoServico } from "../../../servicos/ordem-de-producao.service";
import {
  AcoesOrdensDeProducao,
  handlerAcaoOrdensDeProducaoResponse,
} from "./acoes-ordem-de-producao-helper";

async function cancelarOrdensDeProducaoHandler(
  registros: OrdemDeProducaoGridModel[],
  handleAtualizarGrid: () => void
) {
  if (
    !verificaComNotificacaoSeUsuarioPossuiPermissoes([
      PermissoesOrdemDeProducao.InserirEditar,
    ])
  ) {
    return;
  }

  const opsMensagem =
    registros.length > 1
      ? {
          opsSelecionadas: "as ordens de produção selecionadas",
          reservasOps: "as reservas existentes para essas ordens de produção",
        }
      : {
          opsSelecionadas: `a ordem de produção ${
            registros[0].numero ?? "sem número"
          }`,
          reservasOps: "as reservas existentes para essa ordem de produção",
        };

  const confimar = await exibirConfirmacao(
    "Confirmar cancelamento",
    `Tem certeza de que deseja cancelar ${opsMensagem.opsSelecionadas}? Ao cancelar, ${opsMensagem.reservasOps} serão excluídas.
          <br>Deseja continuar?`
  );

  if (!confimar) {
    return;
  }

  try {
    const response = await OrdemDeProducaoServico.CancelarOrdensDeProducao(
      registros.map((i) => i.id)
    );

    handleAtualizarGrid();

    const acoes: AcoesOrdensDeProducao = {
      acao: "cancelar",
      acaoPlural: "canceladas",
      acaoSingular: "cancelada",
    };
    return handlerAcaoOrdensDeProducaoResponse(response, acoes);
  } catch (erro) {
    tratarErroApi(erro);
  }
}

export { cancelarOrdensDeProducaoHandler };
