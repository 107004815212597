import {
  CiapDoImobilizadoRequest,
  CiapDoImobilizadoResponse,
  ImobilizadoRequest,
  ImobilizadoResponse,
} from "../../../features/contabilidade/imobilizado/models/imobilizado";
import {
  CiapDoImobilizadoViewModel,
  ImobilizadoViewModel,
} from "../../../features/contabilidade/imobilizado/models/imobilizado.viewmodel";

export default class ImobilizadoAdapter {
  public static ToViewModel(
    response: ImobilizadoResponse
  ): ImobilizadoViewModel {
    return {
      id: response.id,
      itemId: response.itemId,
      estado: response.estado,
      numeroDoPatrimonio: response.numeroDoPatrimonio,
      descricao: response.descricao,
      descricaoEstaFixa: response.descricaoEstaFixa,
      valorDaAquisicao: response.valorDaAquisicao,
      dataDaAquisicao: response.dataDaAquisicao,
      contaContabilId: response.contaContabilId,
      utilizacaoDoBem: response.utilizacaoDoBem,
      centroDeCustosId: response.centroDeCustosId,
      contaDeDebitoDaDepreciacaoId: response.contaDeDebitoDaDepreciacaoId,
      centroDeCustosDeDebitoDaDepreciacaoId:
        response.centroDeCustosDeDebitoDaDepreciacaoId,
      contaDeCreditoDaDepreciacaoId: response.contaDeCreditoDaDepreciacaoId,
      centroDeCustosDeCreditoDaDepreciacaoId:
        response.centroDeCustosDeCreditoDaDepreciacaoId,
      percentualDeDepreciacao: response.percentualDeDepreciacao,
      tipoDeDepreciacao: response.tipoDeDepreciacao,
      vidaUtilEmMeses: response.vidaUtilEmMeses,
      valorResidual: response.valorResidual,
      valorInicialDepreciado: response.valorInicialDepreciado,
      quantidadeInicialDeParcelasDepreciadas:
        response.quantidadeInicialDeParcelasDepreciadas,
      sujeitoAoCiap: response.sujeitoAoCiap,
      dataUltimaDepreciacao: response.dataUltimaDepreciacao,
      quantidadeParcelasDepreciadasPorLancamentoContabil:
        response.quantidadeParcelasDepreciadasPorLancamentoContabil,
      valorDepreciadoPorLancamentoContabil:
        response.valorDepreciadoPorLancamentoContabil,
      formaDeCalculoDaPrimeiraParcela: response.formaDeCalculoDaPrimeiraParcela,
      mesDeInicioDaDepreciacao: response.mesDeInicioDaDepreciacao,
      ciapDoImobilizado: this.CiapImobilizadoResponseToViewModel(
        response.ciapDoImobilizado
      ),
      valorParcelaDepreciacao: 0, //Calculado em tela
    };
  }

  public static ToRequest(viewModel: ImobilizadoViewModel): ImobilizadoRequest {
    return {
      id: viewModel.id,
      itemId: viewModel.itemId ?? 0,
      estado: viewModel.estado,
      numeroDoPatrimonio: viewModel.numeroDoPatrimonio,
      descricao: viewModel.descricao,
      descricaoEstaFixa: viewModel.descricaoEstaFixa,
      valorDaAquisicao: viewModel.valorDaAquisicao,
      dataDaAquisicao: viewModel.dataDaAquisicao,
      contaContabilId: viewModel.contaContabilId ?? 0,
      utilizacaoDoBem: viewModel.utilizacaoDoBem,
      centroDeCustosId: viewModel.centroDeCustosId ?? 0,
      contaDeDebitoDaDepreciacaoId: viewModel.contaDeDebitoDaDepreciacaoId ?? 0,
      centroDeCustosDeDebitoDaDepreciacaoId:
        viewModel.centroDeCustosDeDebitoDaDepreciacaoId,
      contaDeCreditoDaDepreciacaoId:
        viewModel.contaDeCreditoDaDepreciacaoId ?? 0,
      centroDeCustosDeCreditoDaDepreciacaoId:
        viewModel.centroDeCustosDeCreditoDaDepreciacaoId,
      percentualDeDepreciacao: viewModel.percentualDeDepreciacao,
      tipoDeDepreciacao: viewModel.tipoDeDepreciacao,
      vidaUtilEmMeses: viewModel.vidaUtilEmMeses,
      valorResidual: viewModel.valorResidual,
      valorInicialDepreciado: viewModel.valorInicialDepreciado,
      quantidadeInicialDeParcelasDepreciadas:
        viewModel.quantidadeInicialDeParcelasDepreciadas,
      formaDeCalculoDaPrimeiraParcela:
        viewModel.formaDeCalculoDaPrimeiraParcela,
      mesDeInicioDaDepreciacao: viewModel.mesDeInicioDaDepreciacao,
      sujeitoAoCiap: viewModel.sujeitoAoCiap,
      ciapDoImobilizado: this.CiapDoImobilizadoViewModelToRequest(
        viewModel.ciapDoImobilizado
      ),
    };
  }

  private static CiapDoImobilizadoViewModelToRequest(
    viewModel: CiapDoImobilizadoViewModel | null
  ): CiapDoImobilizadoRequest | null {
    if (!viewModel) {
      return null;
    }

    return {
      itemNotaFiscalId: viewModel.itemNotaFiscalId,
      parcelas: viewModel.parcelas,
      parcelasApropriadas: viewModel.parcelasApropriadas,
      tipoDeDocumentoFiscalId: viewModel.tipoDeDocumentoFiscalId ?? 0,
      fornecedorId: viewModel.fornecedorId ?? 0,
      numero: viewModel.numero,
      serie: viewModel.serie,
      chaveAcesso: viewModel.chaveAcesso,
      dataEmissao: viewModel.dataEmissao,
      quantidade: viewModel.quantidade,
      unidadeId: viewModel.unidadeId ?? 0,
      baseCalculoIcms: viewModel.baseCalculoIcms,
      aliquotaIcms: viewModel.aliquotaIcms,
      valorIcms: viewModel.valorIcms,
      valorIcmsSt: viewModel.valorIcmsSt,
      valorIcmsFrete: viewModel.valorIcmsFrete,
      valorIcmsDifal: viewModel.valorIcmsDifal,
    };
  }

  private static CiapImobilizadoResponseToViewModel(
    response: CiapDoImobilizadoResponse | null
  ): CiapDoImobilizadoViewModel | null {
    if (!response) {
      return null;
    }

    return {
      itemNotaFiscalId: response.itemNotaFiscalId,
      parcelas: response.parcelas,
      parcelasApropriadas: response.parcelasApropriadas,
      tipoDeDocumentoFiscalId: response.tipoDeDocumentoFiscalId,
      fornecedorId: response.fornecedorId,
      numero: response.numero,
      serie: response.serie,
      chaveAcesso: response.chaveAcesso,
      dataEmissao: response.dataEmissao,
      quantidade: response.quantidade,
      unidadeId: response.unidadeId,
      baseCalculoIcms: response.baseCalculoIcms,
      aliquotaIcms: response.aliquotaIcms,
      valorIcms: response.valorIcms,
      valorIcmsSt: response.valorIcmsSt,
      valorIcmsFrete: response.valorIcmsFrete,
      valorIcmsDifal: response.valorIcmsDifal,
    };
  }
}
