import DataGrid, { Column, DataGridRef } from "devextreme-react/data-grid";
import { useCallback, useEffect, useMemo, useRef } from "react";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import GridColunaAcoes from "../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import { useRegistrarAtalhosGrid } from "../../../../../hooks/atalhos.hooks";
import { useControlarFormDeEdicao } from "../../../../../hooks/form.hooks";
import { useParametroId } from "../../../../../hooks/route.hooks";
import { ResultadoAcaoFormulario } from "../../../../../models/shared/ui/formularios";
import {
  checarResponseExibeMensagemExclusaoDeSucesso,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesModais from "../../../../../utils/common/nomes-modais";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import NormalizaTituloModal from "../../../../../utils/common/normaliza-titulo";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import UrlUtils from "../../../../../utils/url/url-utils";
import { LinkAjudaGridModel } from "../../models/link-ajuda";
import { LinkDeAjudaServico } from "../../servicos/link-ajuda.service";
import EditFormLinkAjudaPagina from "../formulario/pagina-ajuda";

interface GridAjudaPaginasProps {
  codigo: string;
}

const nameOfGridHandler = criarNameof<LinkAjudaGridModel>();

export default function GridAjudaPaginas(props: GridAjudaPaginasProps) {
  const gridRef = useRef<DataGridRef>(null);
  const parametroId = useParametroId();

  const {
    idRegistroEmEdicao,
    setIdRegistroEmEdicao,
    modalVisivel,
    encerrarEdicao,
  } = useControlarFormDeEdicao(NaN);

  const dataSource = useMemo(
    () =>
      LinkDeAjudaServico.ObterDataSourceParaGrid<LinkAjudaGridModel>([
        {
          campo: "codigo",
          operador: "=",
          valor: props.codigo,
        },
      ]),
    [props.codigo]
  );

  function obterMensagemExclusao(registro: LinkAjudaGridModel) {
    return `Tem certeza que deseja excluir o registro ${registro.slug}?`;
  }

  const handleNovoRegistro = useCallback(() => {
    setIdRegistroEmEdicao(0);
  }, []);

  const handleEditarRegistro = useCallback((registro: LinkAjudaGridModel) => {
    setIdRegistroEmEdicao(registro.id);
  }, []);

  const handleExcluirRegistro = useCallback(
    async (registro: LinkAjudaGridModel) => {
      const excluir = await exibirConfirmacao(
        "Confirmar exclusão",
        obterMensagemExclusao(registro)
      );

      if (excluir) {
        try {
          const resposta = await LinkDeAjudaServico.Excluir(registro.codigo);

          if (resposta) {
            checarResponseExibeMensagemExclusaoDeSucesso(resposta);
            handleAtualizarGrid();
          }
        } catch (erro) {
          tratarErroApi(erro);
        }
      }
    },
    []
  );

  const gridController = new GridController<LinkAjudaGridModel>(() =>
    gridRef.current?.instance()
  );

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar("links-ajuda", () => gridRef.current?.instance())
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(NomesTelas.linkAjudaPaginaMaster)
      .definirBotaoNovo(handleNovoRegistro)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirSelecao()
      .definirOrdenacao()
      .definirDuploCliqueLinha(handleEditarRegistro)
      .build();
  }, [props.codigo]);

  useEffect(() => {
    if (parametroId) {
      setIdRegistroEmEdicao(parametroId);
    }
  }, []);

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  useRegistrarAtalhosGrid<LinkAjudaGridModel>({
    controller: gridController,
    handleNovo: handleNovoRegistro,
    handleEditar: handleEditarRegistro,
    handleExcluir: handleExcluirRegistro,
  });

  const handleCallbackFormulario = useCallback(
    (resultado: ResultadoAcaoFormulario) => {
      encerrarEdicao();

      if (resultado == ResultadoAcaoFormulario.AcaoConcluida) {
        handleAtualizarGrid();
      }

      UrlUtils.RemoverParametroId(parametroId);
    },
    [parametroId]
  );

  const handleFecharModal = useCallback(() => {
    encerrarEdicao();
    UrlUtils.RemoverParametroId(parametroId);
  }, [parametroId]);

  return (
    <>
      <ProvedorAjuda id={"tooltips-grid-links-ajuda"}>
        <DataGrid ref={gridRef} {...configuracoesGrid}>
          {GridColunaAcoes<LinkAjudaGridModel>({
            handleEditar: handleEditarRegistro,
            handleExcluir: handleExcluirRegistro,
          })}
          <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
          <Column
            key={nameOfGridHandler("textoExibicao")}
            dataField={nameOfGridHandler("textoExibicao")}
            {...obterConfiguracaoColuna("stringGG")}
            caption="Texto de exibição"
          />
          <Column
            key={nameOfGridHandler("slug")}
            dataField={nameOfGridHandler("slug")}
            {...obterConfiguracaoColuna("stringXXG")}
            caption="Link da ajuda"
          />
          <Column
            key={nameOfGridHandler("ordemExibicao")}
            dataField={nameOfGridHandler("ordemExibicao")}
            {...obterConfiguracaoColuna("codigoNumerico")}
            caption="Ordem"
          />
        </DataGrid>
      </ProvedorAjuda>

      <ModalMxp
        titulo={NormalizaTituloModal.Normalizar(
          idRegistroEmEdicao,
          NomesModais.linkDeAjuda
        )}
        visivel={modalVisivel}
        handleFechar={handleFecharModal}
        largura={"max(50vw, 800px)"}
        altura={"min(30vh, 400px)"}
      >
        <EditFormLinkAjudaPagina
          idRegistroEmEdicao={idRegistroEmEdicao}
          handleCallback={handleCallbackFormulario}
          codigo={props.codigo}
        />
      </ModalMxp>
    </>
  );
}
