import DataGrid, { Column, DataGridRef } from "devextreme-react/data-grid";
import { useCallback, useMemo, useRef } from "react";
import { useRegistrarAtalhosGrid } from "../../../../../hooks/atalhos.hooks";
import { useSeletorGridMxp } from "../../../../../hooks/seletor.hooks";
import { GridMxpProps } from "../../../../../models/shared/ui/grid";
import GetColunasDeAuditoria from "../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import { PedidoDeVendaGridModel } from "../../models/pedidos-de-venda.api";
import { EstadoPedidoDeVendaHelper } from "../../models/pedidos-de-venda.enum";
import { PedidosDeVendaServico } from "../../servicos/pedidos-de-venda.servico";

const nameOfGridHandler = criarNameof<PedidoDeVendaGridModel>();

export default function GridPedidoDeVenda(
  props: GridMxpProps<PedidoDeVendaGridModel>
) {
  const gridRef = useRef<DataGridRef>(null);

  const gridController = useMemo(
    () =>
      new GridController<PedidoDeVendaGridModel>(() =>
        gridRef.current?.instance()
      ),
    [gridRef]
  );

  const dataSource = useMemo(
    () =>
      PedidosDeVendaServico.ObterDataSourceParaGrid<PedidoDeVendaGridModel>(
        props.filtrosNoServidor
      ),
    [props.filtrosNoServidor]
  );

  const handleAtualizarGrid = useCallback(() => {
    gridController.atualizar();
  }, [gridController]);

  useSeletorGridMxp(() => gridRef.current, props.filtrosNoCliente);

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar(
      "pedidos-de-venda",
      () => gridRef.current?.instance(),
      false,
      props.filtrosNoCliente
    )
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(NomesTelas.pedidosDeVenda)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirSelecao()
      .definirOrdenacao()
      .build();
  }, [gridRef, props.filtrosNoCliente, dataSource, handleAtualizarGrid]);

  useRegistrarAtalhosGrid<PedidoDeVendaGridModel>({
    controller: gridController,
  });

  return (
    <>
      <DataGrid ref={gridRef} {...configuracoesGrid}>
        <Column
          key={nameOfGridHandler("dataEmissao")}
          dataField={nameOfGridHandler("dataEmissao")}
          {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
          caption="Emissão"
          width={150}
        />
        <Column
          key={nameOfGridHandler("numero")}
          dataField={nameOfGridHandler("numero")}
          {...obterConfiguracaoColuna("codigoNumerico")}
          caption="Nº"
          width={70}
          sortIndex={0}
          sortOrder="desc"
        />
        <Column
          key={nameOfGridHandler("numeroPedidoDeVendaDoCliente")}
          dataField={nameOfGridHandler("numeroPedidoDeVendaDoCliente")}
          {...obterConfiguracaoColuna("stringMG")}
          caption="Pedido do cliente"
          width={165}
          defaultVisible={false}
        />
        <Column
          key={nameOfGridHandler("apelidoCliente")}
          dataField={nameOfGridHandler("apelidoCliente")}
          {...obterConfiguracaoColuna("stringGG")}
          caption="Cliente"
        />
        <Column
          key={nameOfGridHandler("cnpjCpfCliente")}
          dataField={nameOfGridHandler("cnpjCpfCliente")}
          {...obterConfiguracaoColuna("cpfCnpj")}
          caption="CPF/CNPJ do cliente"
          width={180}
        />
        <Column
          key={nameOfGridHandler("valorTotal")}
          dataField={nameOfGridHandler("valorTotal")}
          {...obterConfiguracaoColuna("monetario")}
          caption="Vl total"
          width={120}
        />
        <Column
          key={nameOfGridHandler("estado")}
          dataField={nameOfGridHandler("estado")}
          {...obterConfiguracaoColuna("stringM")}
          caption="Estado"
          lookup={EstadoPedidoDeVendaHelper.asLookup()}
        />
        <Column
          key={nameOfGridHandler("estadoConfiguravel")}
          dataField={nameOfGridHandler("estadoConfiguravel")}
          {...obterConfiguracaoColuna("stringM")}
          caption="Estado configurável"
          defaultVisible={false}
        />
        <Column
          key={nameOfGridHandler("dataEntrega")}
          dataField={nameOfGridHandler("dataEntrega")}
          {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
          caption="Entrega"
          width={150}
        />
        <Column
          key={nameOfGridHandler("dataEntregaPrevista")}
          dataField={nameOfGridHandler("dataEntregaPrevista")}
          {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
          caption="Previsão de entrega"
          width={150}
        />
        <Column
          key={nameOfGridHandler("observacoes")}
          dataField={nameOfGridHandler("observacoes")}
          {...obterConfiguracaoColuna("stringXXG")}
          caption="Observações"
          width={500}
          defaultVisible={false}
        />
        <Column
          key={nameOfGridHandler("observacoesInternas")}
          dataField={nameOfGridHandler("observacoesInternas")}
          {...obterConfiguracaoColuna("stringXXG")}
          caption="Observações internas"
          width={500}
          defaultVisible={false}
        />
        {GetColunasDeAuditoria(true, true)}
      </DataGrid>
    </>
  );
}
