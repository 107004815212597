import DataGrid, { Column, DataGridRef } from "devextreme-react/data-grid";
import { useCallback, useEffect, useMemo, useRef } from "react";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import GridColunaAcoes from "../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import { useRegistrarAtalhosGrid } from "../../../../../hooks/atalhos.hooks";
import { useControlarFormDeEdicao } from "../../../../../hooks/form.hooks";
import { useMenuDeContextosGrid } from "../../../../../hooks/menus.hooks";
import { useParametroId } from "../../../../../hooks/route.hooks";
import { PermissoesSerieFiscal } from "../../../../../models/permissoes/fiscal/serie-fiscal/permissoes-serie-fiscal";
import { ResultadoAcaoFormulario } from "../../../../../models/shared/ui/formularios";
import GetColunasDeAuditoria from "../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import {
  checarResponseExibeMensagemExclusaoDeSucesso,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesModais from "../../../../../utils/common/nomes-modais";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import NormalizaTituloModal from "../../../../../utils/common/normaliza-titulo";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import UrlUtils from "../../../../../utils/url/url-utils";
import { SerieFiscalGridModel } from "../../models/serie-fiscal";
import { SerieFiscalServico } from "../../servicos/serie-fiscal.service";
import EditFormSerieFiscal from "../formulario";

const dataSource =
  SerieFiscalServico.ObterDataSourceParaGrid<SerieFiscalGridModel>();

const nameOfGridHandler = criarNameof<SerieFiscalGridModel>();

export default function GridSerieFiscal() {
  const gridRef = useRef<DataGridRef>(null);
  const parametroId = useParametroId();

  const {
    idRegistroEmEdicao,
    setIdRegistroEmEdicao,
    modalVisivel,
    encerrarEdicao,
  } = useControlarFormDeEdicao(NaN);

  const handleNovoRegistro = useCallback(() => {
    if (
      verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesSerieFiscal.InserirEditar,
      ])
    ) {
      setIdRegistroEmEdicao(0);
    }
  }, []);

  const handleEditarRegistro = useCallback((registro: SerieFiscalGridModel) => {
    if (
      verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesSerieFiscal.InserirEditar,
      ])
    ) {
      setIdRegistroEmEdicao(registro.id);
    }
  }, []);

  const handleExcluirRegistro = useCallback(
    async (registro: SerieFiscalGridModel) => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesSerieFiscal.Excluir,
        ])
      ) {
        return;
      }

      const excluir = await exibirConfirmacao(
        "Confirmar exclusão",
        obterMensagemExclusao(registro)
      );

      if (excluir) {
        try {
          const resposta = await SerieFiscalServico.Excluir(registro.id);

          if (resposta) {
            checarResponseExibeMensagemExclusaoDeSucesso(resposta);
            handleAtualizarGrid();
          }
        } catch (erro) {
          tratarErroApi(erro);
        }
      }
    },
    []
  );

  function obterMensagemExclusao(registro: SerieFiscalGridModel) {
    return `Tem certeza de que deseja excluir a série fiscal ${registro.serie}?`;
  }

  const gridController = useMemo(
    () =>
      new GridController<SerieFiscalGridModel>(() =>
        gridRef.current?.instance()
      ),
    []
  );

  const menuContexto = useMenuDeContextosGrid([], true, gridController);

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar("serie-fiscal", () => gridRef.current?.instance())
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(NomesTelas.serieDeDocumentosFiscais)
      .definirBotaoNovo(handleNovoRegistro)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirMenuDeContexto(menuContexto)
      .definirSelecao()
      .definirOrdenacao()
      .definirDuploCliqueLinha(handleEditarRegistro)
      .build();
  }, [menuContexto]);

  useEffect(() => {
    if (parametroId || parametroId === 0) {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesSerieFiscal.InserirEditar,
        ])
      ) {
        return;
      }

      setIdRegistroEmEdicao(parametroId);
    }
  }, []);

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  useRegistrarAtalhosGrid<SerieFiscalGridModel>({
    controller: gridController,
    handleNovo: handleNovoRegistro,
    handleEditar: handleEditarRegistro,
    handleExcluir: handleExcluirRegistro,
  });

  const handleCallbackFormulario = useCallback(
    (resultado: ResultadoAcaoFormulario) => {
      encerrarEdicao();

      if (resultado == ResultadoAcaoFormulario.AcaoConcluida) {
        handleAtualizarGrid();
      }

      UrlUtils.RemoverParametroId(parametroId);
    },
    [parametroId]
  );

  const handleFecharModal = useCallback(() => {
    encerrarEdicao();
    UrlUtils.RemoverParametroId(parametroId);
  }, [parametroId]);

  return (
    <>
      <ProvedorAjuda id={"tooltips-grid-serie-fiscal"}>
        <DataGrid ref={gridRef} {...configuracoesGrid}>
          {GridColunaAcoes<SerieFiscalGridModel>({
            handleEditar: handleEditarRegistro,
            handleExcluir: handleExcluirRegistro,
          })}
          <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
          <Column
            key={nameOfGridHandler("serie")}
            dataField={nameOfGridHandler("serie")}
            {...obterConfiguracaoColuna("stringP")}
            sortOrder="asc"
            caption="Série"
          />
          <Column
            key={nameOfGridHandler("proximoNumero")}
            dataField={nameOfGridHandler("proximoNumero")}
            {...obterConfiguracaoColuna("stringM")}
            caption="Próximo número"
          />
          <Column
            key={nameOfGridHandler("tipoDocumentoEletronicoDecodificado")}
            dataField={nameOfGridHandler("tipoDocumentoEletronicoDecodificado")}
            {...obterConfiguracaoColuna("stringG")}
            caption="Tipo de documento fiscal"
          />
          <Column
            key={nameOfGridHandler("observacoes")}
            dataField={nameOfGridHandler("observacoes")}
            {...obterConfiguracaoColuna("stringGG")}
            caption="Observações"
            allowFiltering={false}
            allowSorting={false}
            visible={false}
          />
          {GetColunasDeAuditoria()}
        </DataGrid>
      </ProvedorAjuda>

      <ModalMxp
        titulo={NormalizaTituloModal.Normalizar(
          idRegistroEmEdicao,
          NomesModais.serieDeDocumentosFiscais
        )}
        idRegistro={idRegistroEmEdicao}
        visivel={modalVisivel}
        handleFechar={handleFecharModal}
        largura={"max(50vw, 800px)"}
      >
        <EditFormSerieFiscal
          idRegistroEmEdicao={idRegistroEmEdicao}
          handleCallback={handleCallbackFormulario}
        />
      </ModalMxp>
    </>
  );
}
