import DataGrid, { Column, DataGridRef } from "devextreme-react/data-grid";
import NumberBox, { NumberBoxTypes } from "devextreme-react/number-box";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import { useCallback, useMemo, useRef } from "react";
import { GridNumberBoxProps } from "../../../../../../components/templates-celulas-grid/inputs/number-box/number-box-grid.edit";
import { useSeletorGridMxp } from "../../../../../../hooks/seletor.hooks";
import { GridMxpProps } from "../../../../../../models/shared/ui/grid";
import GetColunasDeAuditoria from "../../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import criarNameof from "../../../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../../../utils/common/nomes-telas";
import GridBuilder from "../../../../../../utils/grid/grid-builder";
import obterConfiguracaoColuna from "../../../../../../utils/grid/padroes-colunas";
import { EstoqueMovimentacaoSelecionarGridModel } from "../../../models/estoque-movimentacao";

export interface GridSelecionarEstoqueMovimentacaoProps
  extends GridMxpProps<EstoqueMovimentacaoSelecionarGridModel> {
  dataSource: DataSource | ArrayStore;
}

const nameOfGridHandler = criarNameof<EstoqueMovimentacaoSelecionarGridModel>();

const QuantidadeEditavelComponent = (props: any) => {
  const onValueChanged = useCallback(
    (e: NumberBoxTypes.ValueChangedEvent) => {
      props.data.setValue(e.value);
    },
    [props]
  );

  return (
    <NumberBox
      name="quantidadeMovimentar"
      defaultValue={props.data.value}
      onValueChanged={onValueChanged}
      min={0}
      {...GridNumberBoxProps}
    />
  );
};

export function GridSelecaoEstoqueMovimentacao(
  props: GridSelecionarEstoqueMovimentacaoProps
) {
  const gridRef = useRef<DataGridRef>(null);

  useSeletorGridMxp(() => gridRef.current, props.filtrosNoCliente);

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar(
      "grid-selecao-estoque-movimentacao",
      () => gridRef?.current?.instance(),
      false,
      props.filtrosNoCliente
    )
      .definirDataSource(props.dataSource)
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(NomesTelas.estoqueMovimentacao)
      .definirOrdenacao()
      .definirOperacoesNoLadoDoCliente()
      .definirEditavel()
      .definirStyles(props.style)
      .build();
  }, [gridRef, props.filtrosNoCliente, props.style, props.dataSource]);

  return (
    <>
      <DataGrid ref={gridRef} {...configuracoesGrid}>
        <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
        <Column
          key={nameOfGridHandler("quantidadeParaEstornar")}
          dataField={nameOfGridHandler("quantidadeParaEstornar")}
          {...obterConfiguracaoColuna("quantidade")}
          allowEditing={true}
          showEditorAlways={true}
          width={200}
          visibleIndex={8}
          caption="Quantidade para estornar"
          editCellComponent={QuantidadeEditavelComponent}
        />
        <Column
          key={nameOfGridHandler("dataDaMovimentacao")}
          dataField={nameOfGridHandler("dataDaMovimentacao")}
          {...obterConfiguracaoColuna("dataAnoCurtoComHoraMinutoSegundos")}
          allowEditing={false}
          width={175}
          caption="Data da movimentação"
        />
        <Column
          key={nameOfGridHandler("itemCodigo")}
          dataField={nameOfGridHandler("itemCodigo")}
          {...obterConfiguracaoColuna("stringM")}
          allowEditing={false}
          caption="Item"
        />
        <Column
          key={nameOfGridHandler("itemDescricao")}
          dataField={nameOfGridHandler("itemDescricao")}
          {...obterConfiguracaoColuna("stringGG")}
          allowEditing={false}
          caption="Descrição"
        />
        <Column
          key={nameOfGridHandler("quantidade")}
          dataField={nameOfGridHandler("quantidade")}
          {...obterConfiguracaoColuna("quantidade")}
          width={120}
          allowEditing={false}
          caption="Quantidade"
        />
        <Column
          key={nameOfGridHandler("loteOuNumeroDeSerie")}
          dataField={nameOfGridHandler("loteOuNumeroDeSerie")}
          {...obterConfiguracaoColuna("stringM")}
          allowEditing={false}
          width={150}
          caption="Número de série"
        />
        <Column
          key={nameOfGridHandler("pesoBruto")}
          dataField={nameOfGridHandler("pesoBruto")}
          {...obterConfiguracaoColuna("peso")}
          allowEditing={false}
          width={100}
          caption="Peso bruto"
        />
        <Column
          key={nameOfGridHandler("pesoLiquido")}
          dataField={nameOfGridHandler("pesoLiquido")}
          {...obterConfiguracaoColuna("peso")}
          allowEditing={false}
          width={100}
          caption="Peso líquido"
        />
        {GetColunasDeAuditoria()}
      </DataGrid>
    </>
  );
}
