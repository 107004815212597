import { Popover } from "devextreme-react";
import { ReactNode } from "react";
import PartialPermissoesFaltando from "../../dialogos/requer-permissao/parts/listagem-permissoes-faltantes";

interface IconeRequerPermissaoProps {
  children: ReactNode;
  exibirIcone: boolean;
  inputName: string;
  permissoesFaltantesFormatada: string[];
}

export default function IconeRequerPermissao(props: IconeRequerPermissaoProps) {
  return (
    <>
      {!props.exibirIcone && <div>{props.children}</div>}
      {props.exibirIcone && (
        <div>
          <div style={{ display: "flex", alignItems: "end" }}>
            <div style={{ flexGrow: 1 }}>{props.children}</div>
            <div style={{ marginLeft: "4px" }}>
              <span
                id={props.inputName}
                className={`ic-material-symbols-outlined ic-privacy-tip`}
              ></span>
            </div>
          </div>
          <Popover
            target={`#${props.inputName}`}
            showEvent="dxhoverstart"
            hideEvent="dxhoverend"
          >
            <PartialPermissoesFaltando
              permissoesFormatadas={props.permissoesFaltantesFormatada}
            />
          </Popover>
        </div>
      )}
    </>
  );
}
