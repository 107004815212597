import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useRef, useState } from "react";
import { FieldValues, UseFormReturn, useForm } from "react-hook-form";
import { aguardar } from "../utils/common/common-utils";
import yup from "../utils/validacao/validacao";

export function useLimparFormSeIdForNaN<T extends FieldValues>(
  form: UseFormReturn,
  novoRegistro: T,
  idEmEdicao: number
) {
  useEffect(() => {
    if (Number.isNaN(idEmEdicao)) {
      form.reset(novoRegistro);
    }
  }, [idEmEdicao]);
}

export function useCarregarCombos(idEmEdicao: number, callback: () => void) {
  useEffect(() => {
    if (!Number.isNaN(idEmEdicao)) {
      callback();
    }
  }, [idEmEdicao]);
}

export function useCarregarRegistro(
  idEmEdicao: number,
  handleCarregarRegistro: () => void
) {
  useEffect(() => {
    if (idEmEdicao > 0) {
      handleCarregarRegistro();
    }
  }, [idEmEdicao]);
}

export function useHookForms<T extends FieldValues>(
  schemaYup: yup.ObjectSchema<T>
) {
  return useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    shouldUseNativeValidation: true,
    resolver: yupResolver(schemaYup),
  });
}

export function useControlarFormDeEdicao(valorPadrao: number) {
  const [idRegistroEmEdicao, setIdRegistroEmEdicao] = useState(valorPadrao);
  const [modalVisivel, setModalVisivel] = useState(false);

  function encerrarEdicao() {
    setModalVisivel(false);
    // Esse tempo apenas para aguardar a animação do modal ao fechar.
    aguardar(100).then(() => setIdRegistroEmEdicao(NaN));
  }

  useEffect(() => {
    if (!Number.isNaN(idRegistroEmEdicao)) {
      setModalVisivel(true);
    }
  }, [idRegistroEmEdicao]);

  return {
    modalVisivel,
    idRegistroEmEdicao,
    setIdRegistroEmEdicao,
    encerrarEdicao,
  };
}

export function useSincronousUseState(effect: () => void) {
  const [timeSpan, setTimeSpan] = useState(NaN);
  const running = useRef(false);

  useEffect(() => {
    if (Number.isNaN(timeSpan)) {
      return;
    }

    if (running.current) {
      return;
    }
    running.current = true;

    try {
      effect();
    } finally {
      running.current = false;
    }
  }, [timeSpan]);

  const call = useCallback(() => {
    setTimeSpan(new Date().getTime());
  }, []);

  return call;
}
