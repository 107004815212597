import DataGrid, { Column, DataGridRef } from "devextreme-react/data-grid";
import { useCallback, useMemo, useRef } from "react";
import GridColunaAcoes from "../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import { useRegistrarAtalhosGrid } from "../../../../../hooks/atalhos.hooks";
import { useSeletorGridMxp } from "../../../../../hooks/seletor.hooks";
import { GridMxpProps } from "../../../../../models/shared/ui/grid";
import GetColunasDeAuditoria from "../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import { InsumoDaOrdemDeProducaoGridModel } from "../../models/insumo-da-ordem-de-producao";
import { InsumoDaOrdemDeProducaoServico } from "../../servicos/insumo-da-ordem-de-producao";
import { colunasInsumos } from "../colunas-insumos/colunas-insumos";

const nameOfGridHandler = criarNameof<InsumoDaOrdemDeProducaoGridModel>();

const colunasDosInsumosQueNaoDevemAparecerNaGridPadrao = [
  nameOfGridHandler("estoqueDisponivel").toString(),
  nameOfGridHandler("estoqueDisponivelExplosao").toString(),
  nameOfGridHandler("enderecoDeEstoque").toString(),
  nameOfGridHandler("loteInterno").toString(),
  nameOfGridHandler("loteDoFabricante").toString(),
];

export default function GridInsumoDaOrdemDeProducao(
  props: GridMxpProps<InsumoDaOrdemDeProducaoGridModel>
) {
  const gridRef = useRef<DataGridRef>(null);
  useSeletorGridMxp(() => gridRef.current);

  const dataSource =
    InsumoDaOrdemDeProducaoServico.ObterDataSourceParaGrid<InsumoDaOrdemDeProducaoGridModel>(
      props.filtrosNoServidor
    );

  const gridController = useMemo(
    () =>
      new GridController<InsumoDaOrdemDeProducaoGridModel>(() =>
        gridRef.current?.instance()
      ),
    [gridRef]
  );

  useRegistrarAtalhosGrid<InsumoDaOrdemDeProducaoGridModel>({
    controller: gridController,
  });

  const handleAtualizarGrid = useCallback(() => {
    gridController.atualizar();
  }, [gridController]);

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar(
      "insumo-da-ordem-de-producao",
      () => gridRef.current?.instance(),
      false,
      props.filtrosNoCliente
    )
      .definirStyles(props.style)
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(NomesTelas.insumosDaOrdemDeProducao)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirSelecao()
      .definirOrdenacao()
      .build();
  }, [props.filtrosNoCliente, dataSource, handleAtualizarGrid, props.style]);

  return (
    <>
      <DataGrid ref={gridRef} {...configuracoesGrid}>
        {GridColunaAcoes<InsumoDaOrdemDeProducaoGridModel>({})}
        <Column
          key={nameOfGridHandler("ordemDeProducao")}
          dataField={nameOfGridHandler("ordemDeProducao")}
          {...obterConfiguracaoColuna("stringPP")}
          caption="Ordem de produção"
          width={160}
          sortIndex={0}
          sortOrder="desc"
        />
        <Column
          key={nameOfGridHandler("ordemDeProducaoEstado")}
          dataField={nameOfGridHandler("ordemDeProducaoEstado")}
          {...obterConfiguracaoColuna("stringM")}
          caption="Estado da ordem de produção"
        />
        <Column
          key={nameOfGridHandler("operacao")}
          dataField={nameOfGridHandler("operacao")}
          {...obterConfiguracaoColuna("stringM")}
          caption="Operação"
          width={160}
          sortIndex={1}
          sortOrder="asc"
        />
        {colunasInsumos.filter(
          (x) =>
            !colunasDosInsumosQueNaoDevemAparecerNaGridPadrao.includes(
              x.key ?? ""
            )
        )}
        <Column
          key={nameOfGridHandler("descricao")}
          dataField={nameOfGridHandler("descricao")}
          {...obterConfiguracaoColuna("stringM")}
          caption="Descrição"
          visibleIndex={4}
          width={350}
        />
        <Column
          key={nameOfGridHandler("quantidadeBaixada")}
          dataField={nameOfGridHandler("quantidadeBaixada")}
          {...obterConfiguracaoColuna(
            "quantidadeComNoMaximoCincoCasasDecimais"
          )}
          caption="Qt baixada"
          alignment="right"
          visibleIndex={11}
        />
        <Column
          key={nameOfGridHandler("quantidadeABaixar")}
          dataField={nameOfGridHandler("quantidadeABaixar")}
          {...obterConfiguracaoColuna(
            "quantidadeComNoMaximoCincoCasasDecimais"
          )}
          caption="Qt a baixar"
          visibleIndex={12}
        />
        {GetColunasDeAuditoria()}
      </DataGrid>
    </>
  );
}
