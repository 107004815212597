import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";

class GrupoDeCentroDeTrabalhoService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.CentroDeTrabalhoGrupo);
  }
}

export const GrupoDeCentroDeTrabalhoServico =
  new GrupoDeCentroDeTrabalhoService();
