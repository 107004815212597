import { AxiosResponse } from "axios";
import getApi from "../../../../configs/api";
import {
  ResponseBase,
  ResponseBaseArquivo,
  ResponseModel,
} from "../../../../models/api/comum/response-base";
import APIAnexos, {
  IAnexosService,
} from "../../../../services/comum/anexos/anexos.service";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";
import DataSourceFactory, {
  DataSourceFiltragem,
} from "../../../../utils/grid/data-source-factory";
import { ArquivoBaseModel } from "../../../sistema/arquivo/models/arquivo.api";
import {
  TarefaOuAnotacaoGridModel,
  TarefaOuAnotacaoResponse,
} from "../models/tarefa-ou-anotacao.api";

class TarefaOuAnotacaoService extends ServiceBase implements IAnexosService {
  constructor() {
    super(NomesEndpoints.TarefaOuAnotacao);
  }

  public async FinalizarTarefaOuAnotacao(id: number) {
    const api = getApi();
    const response = await api.patch<ResponseModel<TarefaOuAnotacaoResponse>>(
      `${this._nomeEndpoint}/FinalizarTarefaOuAnotacao?tarefaOuAnotacaoId=${id}`
    );

    return response.data;
  }

  public ObterDataSourceDaTarefaGeradoraParaGridTarefasEAnotacoes(
    id?: number,
    filtroPadrao?: DataSourceFiltragem<TarefaOuAnotacaoGridModel>[]
  ) {
    return DataSourceFactory.CriarParaGrid<TarefaOuAnotacaoGridModel, number>(
      `${this._nomeEndpoint}/GridTarefasEAnotacoesDaTarefaGeradora?id=${id}`,
      filtroPadrao
    );
  }

  public async obterAnexos(id: number): Promise<ArquivoBaseModel[]> {
    return await APIAnexos.obterAnexos(id, this._nomeEndpoint);
  }

  public async anexoEditarLista(
    id: number,
    anexos: ArquivoBaseModel[]
  ): Promise<ResponseBase> {
    return await APIAnexos.anexoEditarLista(id, anexos, this._nomeEndpoint);
  }

  public async anexoObterDadosParaDownload(
    id: number,
    idAnexo: number
  ): Promise<AxiosResponse<ResponseBaseArquivo, any>> {
    return await APIAnexos.anexoObterDadosParaDownload(
      id,
      idAnexo,
      this._nomeEndpoint
    );
  }
}

export const TarefaOuAnotacaoServico = new TarefaOuAnotacaoService();
