import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";
import DataSourceFactory, {
  DataSourceFiltragem,
} from "../../../../utils/grid/data-source-factory";
import { InsumoDaOrdemDeProducaoResponseDTO } from "../../insumo-da-ordem-de-producao/models/insumo-da-ordem-de-producao";
import { InsumosFilhosDoInsumoAlternativoGridModel } from "../models/estrutura-de-produto.api";

class EstruturaDeProdutoService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.EstruturaDeProduto);
  }

  public ObterDataSourceDaDaEstruturaDoItemPaiAlternativo(
    insumoItemPaiId: number,
    quantidadeInsumo: number,
    quantidadeASelecionarInsumoAlternativo: number,
    insumosFilhos: InsumoDaOrdemDeProducaoResponseDTO[],
    filtroPadrao?: DataSourceFiltragem<InsumosFilhosDoInsumoAlternativoGridModel>[]
  ) {
    return DataSourceFactory.CriarParaGrid<
      InsumosFilhosDoInsumoAlternativoGridModel,
      number
    >(
      `${this._nomeEndpoint}/GridSelecaoItensFilhosDoInsumoItemPaiAlternativo?insumoItemPaiId=${insumoItemPaiId}&quantidadeInsumo=${quantidadeInsumo}`,
      filtroPadrao,
      undefined,
      (data: InsumosFilhosDoInsumoAlternativoGridModel[]) => {
        data.forEach((item) => {
          // Encontrando a quantidade do insumo filho correspondente
          const insumoFilho = insumosFilhos.find(
            (x) => x.itemId === item.itemId
          );
          const quantidadeDoInsumoFilho = insumoFilho?.quantidadeLiquida ?? 0;

          // Se for primeiro item, calculamos a quantidade a selecionar com a quantidade do insumo existente
          // mais a quantidade a selecionar do insumo alternativo
          if (data[0].itemId === item.itemId) {
            const proporcaoDoInsumoFilho = item?.proporcaoParaItemPai ?? 1;

            data[0].quantidadeASelecionar =
              quantidadeASelecionarInsumoAlternativo * proporcaoDoInsumoFilho +
              quantidadeDoInsumoFilho;
          } else {
            // Para os outros itens, simplesmente atribuímos a quantidade encontrada
            item.quantidadeASelecionar = quantidadeDoInsumoFilho;
          }
        });

        return data;
      }
    );
  }
}

export const EstruturaDeProdutoServico = new EstruturaDeProdutoService();
