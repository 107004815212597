import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import ProvedorAjuda from "../../../../../../../components/ajuda/provedor-ajuda";
import BotaoCancelarMxp from "../../../../../../../components/botoes/botao-cancelar-mxp";
import BotaoSalvarMxp from "../../../../../../../components/botoes/botao-salvar-mxp";
import {
  FormGrupo,
  FormNumberBox,
  FormSelectBoxLazyMxp,
} from "../../../../../../../components/formularios";
import {
  assertConfiguracaoExibicaoEBuscaType,
  ItemSelectionChangedType,
} from "../../../../../../../components/formularios/selectbox-lazy-mxp";
import FormMxp from "../../../../../../../components/layout/form";
import { ContainerFormMxp } from "../../../../../../../components/layout/form/styles";
import {
  Coluna,
  Linha,
} from "../../../../../../../components/layout/grid-system";
import ToolbarMxp from "../../../../../../../components/layout/toolbar-mxp";
import {
  IFormularioEditavelBase,
  ResultadoAcaoFormulario,
} from "../../../../../../../models/shared/ui/formularios";
import { EstoqueMovimentacaoServico } from "../../../../../../../services/estoque-movimentacoes/estoque-movimentacoes-service";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../../../utils/api/api-utils";
import { quantidadeValorMaximo } from "../../../../../../../utils/common/constantes";
import exibirNotificacaoToast, {
  GeradorMensagensNotificacao,
  TipoNotificacao,
} from "../../../../../../../utils/common/notificacoes-utils";
import { formatarNumeroQuantidade } from "../../../../../../../utils/formatadores/formatador-de-numeros";
import { DataSourceFiltragem } from "../../../../../../../utils/grid/data-source-factory";
import yup from "../../../../../../../utils/validacao/validacao";
import { FinalidadeTipo } from "../../../../../../contabilidade/conta-contabil/models/conta-contabil.enum";
import SelecionarEstoqueDestino, {
  yupCampoEmpresaDonaId,
  yupCampoEmpresaPosseId,
} from "../../../../../../estoque/estoque-movimentacao/componentes/partes/selecionar-estoque-destino/selecionar-estoque-destino";
import { EstoqueMovimentacaoTipo } from "../../../../../../estoque/estoque-movimentacao/models/estoque-movimentacao.enums";
import RastreabilidadePorLote, {
  novaRastreabilidadeLote,
  yupRastreabilidadeLote,
} from "../../../../../../estoque/lote/componentes/formulario-cadastro-de-rastreabilidade";
import { ItemAparaGridModel } from "../../../../../../itens/item/models/item.api";
import {
  Estado,
  ProcedenciaDecodificadaTipo,
  TipoDeEstocagemDoItem,
} from "../../../../../../itens/item/models/item.enums";
import ComboOperacaoPorOpMxp from "../../../../../operacao-da-ordem-de-producao/componentes/select-box-operacao-por-op";
import { RetiradaDeAparaRequest } from "../../../../models/ordem-de-producao.api";
import { OrdemDeProducaoServico } from "../../../../servicos/ordem-de-producao.service";
import { RetiradaDeMaterialOuAparaValorTipo } from "../../../../utils/enums/retirada-de-material-ou-apara.enums";
import ComboOrdemDeProducaoPorIdMxp from "../../../select-box-lazy/select-box-por-id";
import ComponenteValorUnitario from "../valor-unitario";

const novoRegistro: RetiradaDeAparaRequest = {
  id: 0,
  idOrdemDeProducao: null,
  operacaoDeOrdemDeProducaoId: null,
  quantidade: 0,
  valorUnitario: 0,
  valorUnitarioTipoOrigem: RetiradaDeMaterialOuAparaValorTipo.CadastroDoItem,
  destino: EstoqueMovimentacaoTipo.Estoque,
  finalidadeContaContabil: FinalidadeTipo.Estoque,
  idInsumoOrigemValor: null,
  idContaContabilDestino: null,
  idEnderecoDeEstoque: null,
  itemId: null,
  rastreabilidadeLote: novaRastreabilidadeLote,
  empresaDonaId: undefined,
  empresaPosseId: undefined,
};

interface FormRetirarAparaProps extends IFormularioEditavelBase {
  ordemDeProducaoId: number;
}

const configuracoesExibicaoEBuscaAparasDaOrdemDeProducao =
  assertConfiguracaoExibicaoEBuscaType<ItemAparaGridModel>({
    nomeCampoChave: "id",
    expressaoDeBusca: ["codigo", "descricao"],
    nomeCampoExibicao: (c) => {
      if (c) {
        const operacao =
          c.operacaoDoItemDescricao == null
            ? `${c.operacaoDoItemNumero}`
            : `${c.operacaoDoItemNumero} (${c.operacaoDoItemDescricao})`;
        return c.descricao == null
          ? `${c.codigo} - ${operacao}`
          : `${c.codigo} (${c.descricao}) - ${operacao}`;
      }

      return "";
    },
  });

const filtroComboAparasDaOrdemDeProducao: DataSourceFiltragem<ItemAparaGridModel>[] =
  [
    {
      campo: "estado",
      operador: "=",
      valor: Estado.Ativo,
    },
    {
      campo: "procedencia",
      operador: "<>",
      valor: ProcedenciaDecodificadaTipo.Agregado,
    },
    {
      campo: "procedencia",
      operador: "<>",
      valor: ProcedenciaDecodificadaTipo.Alternativo,
    },
    {
      campo: "procedencia",
      operador: "<>",
      valor: ProcedenciaDecodificadaTipo.Conjunto,
    },
  ];

export default function FormRetirarApara(props: FormRetirarAparaProps) {
  const [itemSelecionado, setItemSelecionado] = useState<
    ItemAparaGridModel | undefined
  >();

  const isRegistroEmEdicao = props.idRegistroEmEdicao > 0;
  const dataSourceAparasDaOrdemDeProducao = useMemo(
    () =>
      OrdemDeProducaoServico.ObterDataSourceParaGridDeAparas(
        props.ordemDeProducaoId,
        filtroComboAparasDaOrdemDeProducao
      ),
    [props.ordemDeProducaoId]
  );

  const schema = yup.object<RetiradaDeAparaRequest>().shape({
    quantidade: yup.number().required().moreThan(0),
    operacaoDeOrdemDeProducaoId: yup.number().required().positive().integer(),
    destino: yup
      .number()
      .oneOf([
        EstoqueMovimentacaoTipo.Estoque,
        EstoqueMovimentacaoTipo.ContaContabil,
      ]),
    itemId: yup.number().required().positive().integer(),
    idContaContabilDestino: yup.number().required().positive().integer(),
    rastreabilidadeLote: yupRastreabilidadeLote(
      () => itemSelecionado?.estocagemPor
    ),
    empresaDonaId: yupCampoEmpresaDonaId(),
    empresaPosseId: yupCampoEmpresaPosseId(),
  });

  const hookForm = useForm<RetiradaDeAparaRequest>({
    resolver: yupResolver(schema),
  });

  const { control, getValues, register, handleSubmit, setValue, reset } =
    hookForm;

  const carregarRegistroDoId = useCallback(async () => {
    try {
      const resposta = await EstoqueMovimentacaoServico.ObterRetiradaDeApara(
        props.idRegistroEmEdicao
      );

      checarResponse(resposta);
      reset(resposta.model);
    } catch (erro) {
      tratarErroApi(erro);
    }
  }, [props.idRegistroEmEdicao, reset]);

  const limparTela = useCallback(() => {
    setItemSelecionado(undefined);
    reset(novoRegistro);
  }, [reset]);

  const preencherTela = useCallback(async () => {
    if (props.idRegistroEmEdicao > 0) {
      await carregarRegistroDoId();
    } else {
      limparTela();
    }
  }, [carregarRegistroDoId, limparTela, props.idRegistroEmEdicao]);

  //Hook usado para carregar os dados da tela
  useEffect(() => {
    if (Number.isNaN(props.idRegistroEmEdicao)) {
      return;
    }

    preencherTela();

    return () => limparTela();
  }, [limparTela, preencherTela, props.idRegistroEmEdicao]);

  useEffect(() => {
    setValue("idOrdemDeProducao", props.ordemDeProducaoId);
    novoRegistro.idOrdemDeProducao = props.ordemDeProducaoId;
  }, [props.ordemDeProducaoId, setValue]);

  async function handleSalvar() {
    const model = getValues();
    try {
      const resposta = await OrdemDeProducaoServico.RetirarApara(model);
      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem:
            GeradorMensagensNotificacao.RealizadoComSucessoFeminino(
              "Retirada de apara"
            ),
          tipo: TipoNotificacao.Sucesso,
        });

        fechar(ResultadoAcaoFormulario.AcaoConcluida);
      }
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    }
  }

  function fechar(resultado: ResultadoAcaoFormulario) {
    limparTela();
    props.handleCallback(resultado);
  }

  function callBackUnprocessableEntity() {
    fechar(ResultadoAcaoFormulario.AcaoConcluida);
  }

  function handleCancelar() {
    fechar(ResultadoAcaoFormulario.AcaoCancelada);
  }

  async function onItemChanged(
    e: ItemSelectionChangedType<ItemAparaGridModel>
  ) {
    setItemSelecionado(e.selectedItem);

    if (!e.selectedItem) {
      return;
    }

    try {
      const resposta =
        await OrdemDeProducaoServico.DadosDoItemParaRetiradaDeApara(
          getValues("idOrdemDeProducao") as number,
          e.selectedItem?.id,
          e.selectedItem?.operacaoDoItemId ?? 0
        );

      checarResponse(resposta);
      if (!resposta.sucesso) {
        return;
      }

      if (resposta.model.operacaoDeOrdemDeProducaoId) {
        setValue(
          "operacaoDeOrdemDeProducaoId",
          resposta.model.operacaoDeOrdemDeProducaoId
        );
      }

      if (resposta.model.idContaContabilDestino) {
        setValue(
          "idContaContabilDestino",
          resposta.model.idContaContabilDestino
        );
      }
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  return (
    <ContainerFormMxp>
      <FormMxp>
        <ProvedorAjuda id="edit-form-retirada-de-aparas-ordem-de-producao">
          <input type="hidden" {...register("id")} />
          <FormGrupo>
            <Linha>
              <Coluna md={8}>
                <ComboOrdemDeProducaoPorIdMxp
                  name="idOrdemDeProducao"
                  titulo="Ordem de producão"
                  somenteLeitura
                  ordemDeProducaoId={props.ordemDeProducaoId}
                  control={control}
                />
              </Coluna>
              <Coluna md={4}>
                <ComboOperacaoPorOpMxp
                  name="operacaoDeOrdemDeProducaoId"
                  titulo="Operação"
                  somenteLeitura={isRegistroEmEdicao}
                  requerido
                  idOrdemDeProducao={props.ordemDeProducaoId}
                  control={control}
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={8}>
                <FormSelectBoxLazyMxp
                  name={"itemId"}
                  titulo="Item"
                  control={control}
                  requerido
                  somenteLeitura={isRegistroEmEdicao}
                  dataSource={dataSourceAparasDaOrdemDeProducao}
                  configuracoesExibicaoEBusca={
                    configuracoesExibicaoEBuscaAparasDaOrdemDeProducao
                  }
                  labelSemDados="Sem dados"
                  onSelectionChanged={onItemChanged}
                  selecionarPrimeiroElementoAposCarregamento
                />
              </Coluna>
              <Coluna md={4}>
                <FormNumberBox
                  name="quantidade"
                  titulo="Quantidade"
                  control={control}
                  formato={formatarNumeroQuantidade}
                  minimo={0}
                  maximo={quantidadeValorMaximo.toNumber()}
                  somenteLeitura={isRegistroEmEdicao}
                  requerido
                />
              </Coluna>
            </Linha>
            <ComponenteValorUnitario
              hookForms={hookForm}
              ordemDeProducaoId={props.ordemDeProducaoId}
              valorDoItem={itemSelecionado?.custo || 0}
              somenteLeitura={isRegistroEmEdicao}
            />
            <SelecionarEstoqueDestino
              hookForms={hookForm as any}
              somenteLeitura={isRegistroEmEdicao}
            />
            {itemSelecionado?.estocagemPor ==
              TipoDeEstocagemDoItem.LoteDoFabricante &&
              props.idRegistroEmEdicao == 0 &&
              !isRegistroEmEdicao && (
                <RastreabilidadePorLote hookForms={hookForm as any} />
              )}
          </FormGrupo>
        </ProvedorAjuda>
      </FormMxp>
      {!isRegistroEmEdicao && (
        <ToolbarMxp>
          <BotaoSalvarMxp handleClick={handleSubmit(handleSalvar)} />
          <BotaoCancelarMxp handleClick={handleCancelar} />
        </ToolbarMxp>
      )}
    </ContainerFormMxp>
  );
}
