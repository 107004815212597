import PaginaMxp from "../../../../../components/layout/pagina-mxp";
import ProvedorMenus from "../../../../../components/menus/provedor-menus";
import RequerPermissao from "../../../../../components/seguranca/requer-permissao";
import { PermissoesOrdemDeProducao } from "../../../../../models/permissoes/producao/ordem-de-producao/OrdemDeProducaoPermissoes";
import NomesDasCategoriasDoMenuPrincipal from "../../../../../utils/common/nome-categorias-menu-principal";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import ProvedorRelatorioPersonalizado from "../../../../relatorio/relatorio-personalizado/componentes/provedor-contexto";
import { FinalidadeModeloRelatorio } from "../../../../relatorio/relatorio-personalizado/models/relatorio-personalizado.enums";
import GanttOrdemDeProducao from "../../componentes/gantt";

export default function OrdemDeProducaoGanttPage() {
  return (
    <ProvedorMenus>
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdn3.devexpress.com/jslib/22.2.3/css/dx-gantt.css"
      />

      <ProvedorRelatorioPersonalizado
        finalidade={FinalidadeModeloRelatorio.OrdemDeProducao}
      >
        <PaginaMxp
          id="ordem-de-producao-gantt-page"
          titulo={NomesTelas.ordensProducao}
          area={[NomesDasCategoriasDoMenuPrincipal.producao]}
        >
          <RequerPermissao
            codigoPermissoes={[PermissoesOrdemDeProducao.Consultar]}
          >
            <GanttOrdemDeProducao />
          </RequerPermissao>
        </PaginaMxp>
      </ProvedorRelatorioPersonalizado>
    </ProvedorMenus>
  );
}
