import getApi from "../../../../configs/api";
import {
  ResponseBase,
  ResponseModel,
} from "../../../../models/api/comum/response-base";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import DataSourceFactory, {
  DataSourceFiltragem,
  DataSourceRawFilter,
} from "../../../../utils/grid/data-source-factory";
import { EfdReinfNumeracaoTipo } from "../models/efd-reinf.enums";

class EfdReinfService {
  protected _nomeEndpoint: NomesEndpoints;
  protected _tipoRegistroReinf: EfdReinfNumeracaoTipo;

  constructor(tipoRegistroReinf: EfdReinfNumeracaoTipo) {
    this._nomeEndpoint = NomesEndpoints.EfdReinf;
    this._tipoRegistroReinf = tipoRegistroReinf;
  }

  public ObterDataSourceParaGrid<T, TKey = number>(
    filtroPadrao?: DataSourceFiltragem<T>[],
    filtroPadraoExato?: DataSourceRawFilter<T>
  ) {
    return DataSourceFactory.CriarParaGrid<T, TKey>(
      `${this._nomeEndpoint}/${this._tipoRegistroReinf}/grid`,
      filtroPadrao,
      filtroPadraoExato
    );
  }

  public async Excluir(id: number) {
    const api = getApi();
    const response = await api.delete<ResponseBase>(
      `${this._nomeEndpoint}/${this._tipoRegistroReinf}?id=${id}`
    );

    return response.data;
  }

  public async Consultar(id: number) {
    const api = getApi();
    const response = await api.get<ResponseBase>(
      `${this._nomeEndpoint}/${this._tipoRegistroReinf}/Consultar?id=${id}`
    );

    return response.data;
  }

  public async Enviar<T>(ids: number[]) {
    const api = getApi();
    const response = await api.post<ResponseModel<T>>(
      `${this._nomeEndpoint}/${this._tipoRegistroReinf}/Enviar`,
      ids
    );

    return response.data;
  }
}

export const EfdReinfServicoR2010 = new EfdReinfService(
  EfdReinfNumeracaoTipo.R2010
);
export const EfdReinfServicoR2020 = new EfdReinfService(
  EfdReinfNumeracaoTipo.R2020
);
export const EfdReinfServicoR4010 = new EfdReinfService(
  EfdReinfNumeracaoTipo.R4010
);
export const EfdReinfServicoR4020 = new EfdReinfService(
  EfdReinfNumeracaoTipo.R4020
);
export const EfdReinfServicoR4040 = new EfdReinfService(
  EfdReinfNumeracaoTipo.R4040
);
export const EfdReinfServicoR4080 = new EfdReinfService(
  EfdReinfNumeracaoTipo.R4080
);
