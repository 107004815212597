import DataGrid, { Column, DataGridRef } from "devextreme-react/data-grid";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import PdfViewer from "../../../../../components/arquivo/pdf-viewer";
import GridColunaAcoes from "../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import { Modal } from "../../../../../components/layout/modal";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import CelulaSituacaoMdfe from "../../../../../components/templates-celulas-grid/celula-situacao-mdfe";
import { useRegistrarAtalhosGrid } from "../../../../../hooks/atalhos.hooks";
import { useControlarFormDeEdicao } from "../../../../../hooks/form.hooks";
import { useMenuDeContextosGrid } from "../../../../../hooks/menus.hooks";
import { useParametroId } from "../../../../../hooks/route.hooks";
import { useAppDispatch } from "../../../../../hooks/store.hooks";
import { PermissoesMDF } from "../../../../../models/permissoes/fiscal/mdfe/permissoes-mdf";
import { ResultadoAcaoFormulario } from "../../../../../models/shared/ui/formularios";
import GetColunasDeAuditoria from "../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import { bloquearUi, desbloquearUi } from "../../../../../store/ui/ui.slice";
import {
  checarResponse,
  checarResponseExibeMensagemExclusaoDeSucesso,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesModais from "../../../../../utils/common/nomes-modais";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import NormalizaTituloModal from "../../../../../utils/common/normaliza-titulo";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import { ItemContextMenuMxp } from "../../../../../utils/context-menu/context-menu-utils";
import { GestorEventoClickUnicaLinha } from "../../../../../utils/context-menu/gestor-evento-click";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import {
  baixarXmlMdfe,
  toastSituacaoInvalida,
  verificaSituacaoMDFeParaImpressaoDamdfeBaixarXml,
  visualizarImpressaoPdfDamdfe,
} from "../../../../../utils/especifico/mdfe/mdfe-utils";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import UrlUtils from "../../../../../utils/url/url-utils";
import { MDFeGridModel } from "../../models/mdfe";
import {
  AmbienteMDFe,
  SituacaoMDFe,
  situacaoMDFeDecodificada,
} from "../../models/mdfe-enums";
import { MDFeServico } from "../../servicos/mdfe.service";
import EditFormMDFe from "../formulario";
import MDFeEditFormContextProvider from "../provedor-contexto";

const dataSource = MDFeServico.ObterDataSourceParaGrid();
const nameof = criarNameof<MDFeGridModel>();

function obterMensagemExclusao(registro: MDFeGridModel) {
  const dataLocalizada = new Date(registro.dataEmissao).toLocaleString();
  const mensagemNumeroMdfe = registro.numeroMdfe
    ? `Nº ${registro.numeroMdfe}`
    : "sem N°";
  return `Tem certeza de que deseja excluir o MDF-e ${mensagemNumeroMdfe} de ${dataLocalizada}?`;
}

export default function GridMDFe() {
  const gridRef = useRef<DataGridRef>(null);
  const parametroId = useParametroId();
  const dispatch = useAppDispatch();
  const [modalPdfViewerVisivel, setModalPdfViewerVisivel] = useState(false);
  const [documentoPdfDamdfe, setDocumentoPdfDamdfe] = useState("");

  const {
    idRegistroEmEdicao,
    setIdRegistroEmEdicao,
    modalVisivel,
    encerrarEdicao,
  } = useControlarFormDeEdicao(NaN);

  const gridController = useMemo(
    () => new GridController<MDFeGridModel>(() => gridRef.current?.instance()),
    []
  );

  const handleAtualizarGrid = useCallback(() => {
    gridController.atualizar();
  }, [gridController]);

  const handleNovoRegistro = useCallback(() => {
    if (
      !verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesMDF.InserirEditar,
      ])
    ) {
      return;
    }
    setIdRegistroEmEdicao(0);
  }, [setIdRegistroEmEdicao]);

  const handleEditarRegistro = useCallback(
    (registro: MDFeGridModel) => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesMDF.InserirEditar,
        ])
      ) {
        return;
      }
      setIdRegistroEmEdicao(registro.id);
    },
    [setIdRegistroEmEdicao]
  );

  const handleExcluirRegistro = useCallback(
    async (registro: MDFeGridModel) => {
      try {
        dispatch(bloquearUi());
        if (
          !verificaComNotificacaoSeUsuarioPossuiPermissoes([
            PermissoesMDF.Excluir,
          ])
        ) {
          return;
        }

        const excluir = await exibirConfirmacao(
          "Confirmar exclusão",
          obterMensagemExclusao(registro)
        );

        if (!excluir) {
          return;
        }

        if (registro?.id && registro?.dataEmissao) {
          if (
            registro?.situacao != SituacaoMDFe.EmEdicao &&
            registro?.ambiente === AmbienteMDFe.Producao
          ) {
            exibirNotificacaoToast({
              mensagem: `Não é possível excluir MDF-e no estado diferente de "${
                situacaoMDFeDecodificada[SituacaoMDFe.EmEdicao]
              }}".`,
              tipo: TipoNotificacao.Erro,
            });
            return;
          }

          const resposta = await MDFeServico.Excluir(registro.id);

          if (resposta) {
            checarResponseExibeMensagemExclusaoDeSucesso(resposta);
            handleAtualizarGrid();
          }
        }
      } catch (erro) {
        tratarErroApi(erro);
      } finally {
        dispatch(desbloquearUi());
      }
    },
    [dispatch, handleAtualizarGrid]
  );

  const toastElementoNaoEncontrado = useCallback(() => {
    exibirNotificacaoToast({
      mensagem: "Elemento não encontrado.",
      tipo: TipoNotificacao.Erro,
    });
  }, []);

  async function downloadXmlMdfe({ id, situacao }: MDFeGridModel) {
    if (id && situacao) {
      if (!verificaSituacaoMDFeParaImpressaoDamdfeBaixarXml(situacao)) {
        toastSituacaoInvalida();
        return;
      }

      await baixarXmlMdfe(id, situacao);
    } else {
      toastElementoNaoEncontrado();
    }
  }

  async function imprimirDamdfeMdfe({ id, situacao }: MDFeGridModel) {
    if (id && situacao) {
      if (!verificaSituacaoMDFeParaImpressaoDamdfeBaixarXml(situacao)) {
        toastSituacaoInvalida();
        return;
      }

      await visualizarImpressaoPdfDamdfe(
        id,
        situacao,
        setModalPdfViewerVisivel,
        setDocumentoPdfDamdfe
      );
    } else {
      toastElementoNaoEncontrado();
    }
  }

  async function consultarSituacao({ id }: MDFeGridModel) {
    if (id) {
      try {
        dispatch(bloquearUi());
        const resposta = await MDFeServico.consultarSituacao(id);
        checarResponse(resposta);

        if (resposta.sucesso) {
          handleAtualizarGrid();

          exibirNotificacaoToast({
            mensagem: resposta.mensagem,
            tipo: TipoNotificacao.Sucesso,
          });
        }
      } catch (erro) {
        tratarErroApi(erro);
      } finally {
        dispatch(desbloquearUi());
      }
    }
  }

  async function duplicar({ id }: MDFeGridModel) {
    if (id) {
      try {
        dispatch(bloquearUi());
        const resposta = await MDFeServico.duplicar(id);
        checarResponse(resposta);

        if (resposta.sucesso) {
          handleAtualizarGrid();

          exibirNotificacaoToast({
            mensagem: resposta.mensagem,
            tipo: TipoNotificacao.Sucesso,
          });
        }
      } catch (erro) {
        tratarErroApi(erro);
      } finally {
        dispatch(desbloquearUi());
      }
    }
  }

  const handlePdfViewerModalCallBack = useCallback(() => {
    setModalPdfViewerVisivel(false);
    setDocumentoPdfDamdfe("");
  }, []);

  const acoesDeContexto: ItemContextMenuMxp[] = useMemo(
    () => [
      {
        text: "Consultar situação",
        icon: "ic-material-symbols-outlined ic-refresh",
        exibirNaLinhaDaGrid: "menuDeContexto",
        gestorEventoClick: new GestorEventoClickUnicaLinha(
          consultarSituacao,
          () => gridController
        ),
      },
      {
        text: "Visualizar PDF",
        icon: "ic-material-symbols-outlined ic-print",
        gestorEventoClick: new GestorEventoClickUnicaLinha(
          imprimirDamdfeMdfe,
          () => gridController
        ),
        exibirNaLinhaDaGrid: "sempre",
        propriedadesComputadas: [
          [
            "disabledIcon",
            (data: MDFeGridModel) =>
              !verificaSituacaoMDFeParaImpressaoDamdfeBaixarXml(data?.situacao),
          ],
        ],
      },
      {
        text: "Baixar XML",
        icon: "ic-material-symbols-outlined ic-download",
        gestorEventoClick: new GestorEventoClickUnicaLinha(
          downloadXmlMdfe,
          () => gridController
        ),
        exibirNaLinhaDaGrid: "sempre",
        propriedadesComputadas: [
          [
            "disabledIcon",
            (data: MDFeGridModel) =>
              !verificaSituacaoMDFeParaImpressaoDamdfeBaixarXml(data?.situacao),
          ],
        ],
      },
      {
        text: "Duplicar",
        icon: "fa-regular fa-clone",
        gestorEventoClick: new GestorEventoClickUnicaLinha(
          duplicar,
          () => gridController
        ),
        exibirNaLinhaDaGrid: "menuDeContexto",
      },
    ],
    [gridController]
  );

  const menuContexto = useMenuDeContextosGrid(
    acoesDeContexto,
    true,
    gridController
  );

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar("mdfe", () => gridRef.current?.instance())
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(NomesTelas.mdfe)
      .definirBotaoNovo(handleNovoRegistro)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirSelecao()
      .definirOrdenacao()
      .definirMenuDeContexto(menuContexto)
      .definirDuploCliqueLinha(handleEditarRegistro)
      .build();
  }, [menuContexto]);

  useEffect(() => {
    if (parametroId || parametroId === 0) {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesMDF.InserirEditar,
        ])
      ) {
        return;
      }

      setIdRegistroEmEdicao(parametroId);
    }
  }, [parametroId, setIdRegistroEmEdicao]);

  useRegistrarAtalhosGrid<MDFeGridModel>({
    controller: gridController,
    handleNovo: handleNovoRegistro,
    handleEditar: handleEditarRegistro,
    handleExcluir: handleExcluirRegistro,
  });

  const handleCallbackFormulario = useCallback(
    (resultado: ResultadoAcaoFormulario) => {
      encerrarEdicao();

      if (resultado == ResultadoAcaoFormulario.AcaoConcluida) {
        handleAtualizarGrid();
      }

      UrlUtils.RemoverParametroId(parametroId);
    },
    [parametroId, encerrarEdicao, handleAtualizarGrid]
  );

  const handleFecharModal = useCallback(() => {
    encerrarEdicao();
    UrlUtils.RemoverParametroId(parametroId);
  }, [parametroId, encerrarEdicao]);

  return (
    <>
      <ProvedorAjuda id="tooltips-grid-mdfe">
        <DataGrid ref={gridRef} {...configuracoesGrid}>
          {GridColunaAcoes<MDFeGridModel>({
            handleEditar: handleEditarRegistro,
            handleExcluir: handleExcluirRegistro,
          })}
          <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
          <Column
            key={nameof("dataEmissao")}
            dataField={nameof("dataEmissao")}
            {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
            sortIndex={0}
            sortOrder="desc"
            caption="Emissão"
          />
          <Column
            key={nameof("numeroMdfe")}
            dataField={nameof("numeroMdfe")}
            {...obterConfiguracaoColuna("codigo")}
            caption="Nº"
          />
          <Column
            key={nameof("serieFiscal")}
            dataField={nameof("serieFiscal")}
            {...obterConfiguracaoColuna("serie")}
          />
          <Column
            key={nameof("contratantes")}
            dataField={nameof("contratantes")}
            {...obterConfiguracaoColuna("razaoSocial")}
            caption="Contratante"
          />
          <Column
            key={nameof("contratantesCnpj")}
            dataField={nameof("contratantesCnpj")}
            {...obterConfiguracaoColuna("cpfCnpj")}
            caption="CNPJ/CPF do contratante"
          />
          <Column
            key={nameof("chaveDeAcessoMdfe")}
            dataField={nameof("chaveDeAcessoMdfe")}
            {...obterConfiguracaoColuna("chaveAcesso")}
            caption="Chave de acesso"
          />
          <Column
            key={nameof("situacaoDescricao")}
            dataField={nameof("situacaoDescricao")}
            {...obterConfiguracaoColuna("stringP")}
            caption="Situação"
            cellRender={CelulaSituacaoMdfe}
          />
          <Column
            key={nameof("siglaUfOrigem")}
            dataField={nameof("siglaUfOrigem")}
            {...obterConfiguracaoColuna("uf")}
            caption="UF de origem"
          />
          <Column
            key={nameof("siglaUfDestino")}
            dataField={nameof("siglaUfDestino")}
            {...obterConfiguracaoColuna("uf")}
            caption="UF de destino"
          />
          <Column
            key={nameof("placasVeiculosPrincipais")}
            dataField={nameof("placasVeiculosPrincipais")}
            {...obterConfiguracaoColuna("stringG")}
            caption="Placa da tração"
            width={140}
          />
          <Column
            key={nameof("placaReboque1")}
            dataField={nameof("placaReboque1")}
            {...obterConfiguracaoColuna("stringG")}
            caption="Placa do reboque 1"
            visible={false}
            width={160}
          />
          <Column
            key={nameof("placaReboque2")}
            dataField={nameof("placaReboque2")}
            {...obterConfiguracaoColuna("stringG")}
            caption="Placa do reboque 2"
            visible={false}
            width={160}
          />
          <Column
            key={nameof("placaReboque3")}
            dataField={nameof("placaReboque3")}
            {...obterConfiguracaoColuna("stringG")}
            caption="Placa do reboque 3"
            visible={false}
            width={160}
          />
          <Column
            key={nameof("nomesCondutores")}
            dataField={nameof("nomesCondutores")}
            caption="Condutores"
            {...obterConfiguracaoColuna("stringG")}
            width={180}
          />
          {GetColunasDeAuditoria()}
        </DataGrid>
      </ProvedorAjuda>
      <div>
        <MDFeEditFormContextProvider>
          <ModalMxp
            titulo={NormalizaTituloModal.Normalizar(
              idRegistroEmEdicao,
              NomesModais.mdfe
            )}
            idRegistro={idRegistroEmEdicao}
            visivel={modalVisivel}
            handleFechar={handleFecharModal}
            altura="96vh"
            alturaMaxima={"96vh"}
          >
            <EditFormMDFe
              idRegistroEmEdicao={idRegistroEmEdicao}
              handleCallback={handleCallbackFormulario}
            />
          </ModalMxp>
        </MDFeEditFormContextProvider>

        <Modal
          titulo="Visualizar PDF"
          visivel={modalPdfViewerVisivel}
          onFechar={handlePdfViewerModalCallBack}
          altura="92vh"
          largura="90vw"
        >
          <PdfViewer documento={documentoPdfDamdfe} />
        </Modal>
      </div>
    </>
  );
}
