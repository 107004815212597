import Notify from "devextreme/ui/notify";
import store from "../../store";

export enum TipoNotificacao {
  Erro = "error",
  Informacao = "info",
  Sucesso = "success",
  Advertencia = "warning",
}

export enum PosicaoNotificacao {
  SuperiorEsquerdo = "top left",
  SuperiorCentro = "top center",
  SuperiorDireito = "top right",
  InferiorEsquerdo = "bottom left",
  InferiorCentro = "bottom center",
  InferiorDireito = "bottom right",
  EsquerdaCentro = "left center",
  Centro = "center",
  DireitaCentro = "right center",
}

export enum DirecaoPilha {
  EmpurrarParaBaixo = "down-push",
  EmpilharParaBaixo = "down-stack",
  EmpurrarParaCima = "up-push",
  EmpilharParaCima = "up-stack",
  EmpurrarParaEsquerda = "left-push",
  EmpilharParaEsquerda = "left-stack",
  EmpurrarParaDireita = "right-push",
  EmpilharParaDireita = "right-stack",
}

export interface OpcoesNotificao {
  mensagem: string;
  tipo?: TipoNotificacao;
  posicao?: PosicaoNotificacao;
  direcao?: DirecaoPilha;
  tempo?: number;
  largura?: number | string;
  altura?: number | string;
}

export enum MensagensParaNotificacao {
  EhNecessarioSelecionarUmRegistro = "É necessário selecionar um registro.",
  EhNecessarioSelecionarApenasUmRegistro = "É necessário selecionar apenas um registro.",
  ExcluidoComSucesso = "Excluído com sucesso",
}

export enum JanelasDeNotificacaoTitulos {
  Atencao = "Atenção",
  Aviso = "Aviso",
  Motivo = "Motivo",
}

export default function exibirNotificacaoToast(opcoes: OpcoesNotificao) {
  if (store.getState().estadoUI.desabilitarToasts || !opcoes.mensagem) {
    return;
  }

  const dados = {
    message: opcoes.mensagem,
    height: opcoes.altura,
    width: opcoes.largura,
    type: opcoes.tipo ?? TipoNotificacao.Informacao,
    displayTime: opcoes.tempo ?? 5000,
  };

  Notify(dados, {
    position: opcoes.posicao ?? { top: 50, right: 10 },
    direction: opcoes.direcao ?? DirecaoPilha.EmpurrarParaBaixo,
  });
}

export function exibirListaDeMensagensEmNotificacoesToast(
  mensagens: string[],
  tipoDeNotificacao: TipoNotificacao
) {
  mensagens.forEach((mensagem) =>
    exibirNotificacaoToast({
      mensagem: mensagem,
      tipo: tipoDeNotificacao,
    })
  );
}

export class GeradorMensagensNotificacao {
  // Funções privadas para gerar as mensagens genéricas
  private static gerarMensagemSucesso(
    operacao: string,
    nomeEntidade: string,
    generoMasculino: boolean
  ) {
    const genero = generoMasculino ? "o" : "a";
    return `${nomeEntidade} ${operacao}${genero} com sucesso.`;
  }

  // Funções públicas para masculino e feminino
  static ExcluidoComSucessoMasculino(nomeEntidade: string) {
    return this.ExcluidoComSucesso(nomeEntidade, true);
  }

  static ExcluidoComSucessoFeminino(nomeEntidade: string) {
    return this.ExcluidoComSucesso(nomeEntidade, false);
  }

  static SalvoComSucessoMasculino(nomeEntidade: string) {
    return this.SalvoComSucesso(nomeEntidade, true);
  }

  static SalvoComSucessoFeminino(nomeEntidade: string) {
    return this.SalvoComSucesso(nomeEntidade, false);
  }

  static RealizadoComSucessoMasculino(nomeEntidade: string) {
    return this.RealizadoComSucesso(nomeEntidade, true);
  }

  static RealizadoComSucessoFeminino(nomeEntidade: string) {
    return this.RealizadoComSucesso(nomeEntidade, false);
  }

  static CanceladoComSucessoMasculino(nomeEntidade: string) {
    return this.CanceladoComSucesso(nomeEntidade, true);
  }

  static CanceladoComSucessoFeminino(nomeEntidade: string) {
    return this.CanceladoComSucesso(nomeEntidade, false);
  }

  // Funções privadas para a lógica genérica de "salvo" e "excluído"
  private static ExcluidoComSucesso(
    nomeEntidade: string,
    generoMasculino: boolean
  ) {
    return this.gerarMensagemSucesso("excluíd", nomeEntidade, generoMasculino);
  }

  private static SalvoComSucesso(
    nomeEntidade: string,
    generoMasculino: boolean
  ) {
    return this.gerarMensagemSucesso("salv", nomeEntidade, generoMasculino);
  }

  private static RealizadoComSucesso(
    nomeEntidade: string,
    generoMasculino: boolean
  ) {
    return this.gerarMensagemSucesso("realizad", nomeEntidade, generoMasculino);
  }

  private static CanceladoComSucesso(
    nomeEntidade: string,
    generoMasculino: boolean
  ) {
    return this.gerarMensagemSucesso("cancelad", nomeEntidade, generoMasculino);
  }
}
