import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";

class ContaParteBService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.ContaParteB);
  }
}

export const ContaParteBServico = new ContaParteBService();
