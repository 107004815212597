import { AxiosError } from "axios";
import getApi, {
  processarRequestComPossivelArquivo,
} from "../../../../configs/api";
import {
  ResponseBase,
  ResponseModel,
} from "../../../../models/api/comum/response-base";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";
import {
  GerarRelatorioPersonalizadoFavoritoRequestDTO,
  GerarRelatorioPersonalizadoRequestDTO,
  GerarRelatorioPersonalizadoResponse,
  SchemaTags,
} from "../models/relatorio-personalizado.api";
import { FinalidadeModeloRelatorio } from "../models/relatorio-personalizado.enums";

class RelatorioPersonalizadoService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.RelatorioPersonalizado);
  }

  public async gerarRelatorio(
    request: GerarRelatorioPersonalizadoRequestDTO
  ): Promise<GerarRelatorioPersonalizadoResponse> {
    try {
      const api = getApi();
      const response = await api.get(
        `${this._nomeEndpoint}/GerarRelatorio?relatorioId=${
          request.relatorioId
        }&filtroGrid=${encodeURIComponent(request.filtroGrid ?? "")}&ids=${
          request.ids
        }&pdf=${request.pdf}&dimensionamento=${request.dimensionamento ?? ""}`,
        { responseType: "blob" }
      );

      const possivelArquivo = await processarRequestComPossivelArquivo(
        response
      );

      return {
        arquivoGerado: true,
        ...possivelArquivo,
      };
    } catch (erro: any) {
      return this.ObterErroGerarRelatorio(erro as AxiosError<Blob>);
    }
  }

  public async gerarRelatorioFavorito(
    request: GerarRelatorioPersonalizadoFavoritoRequestDTO
  ): Promise<GerarRelatorioPersonalizadoResponse> {
    try {
      const api = getApi();
      const response = await api.get(
        `${this._nomeEndpoint}/GerarRelatorioFavorito?finalidade=${request.finalidade}&ids=${request.ids}`,
        { responseType: "blob" }
      );

      const possivelArquivo = await processarRequestComPossivelArquivo(
        response
      );

      return {
        arquivoGerado: true,
        ...possivelArquivo,
      };
    } catch (erro) {
      return this.ObterErroGerarRelatorio(erro as AxiosError<Blob>);
    }
  }

  public async obterSchema(tipo: FinalidadeModeloRelatorio) {
    const api = getApi();
    const response = await api.get<ResponseModel<SchemaTags>>(
      `${this._nomeEndpoint}/ObterSchema?tipo=${tipo}`
    );
    return response.data;
  }

  private async ObterErroGerarRelatorio(
    erro: AxiosError<Blob>
  ): Promise<GerarRelatorioPersonalizadoResponse> {
    const responseData = await erro.response?.data.text();
    const responseBase = JSON.parse(responseData!) as ResponseBase;

    return {
      arquivoGerado: false,
      mensagemDeErroArquivoNaoGerado: responseBase.mensagem,
    } as GerarRelatorioPersonalizadoResponse;
  }
}

export const RelatorioPersonalizadoServico =
  new RelatorioPersonalizadoService();
