import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";

class CentroDeCustosService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.CentroDeCustos);
  }
}

export const CentroDeCustosServico = new CentroDeCustosService();
