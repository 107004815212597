import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";

class ProjecaoDeUsoService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.ProjecaoDeUso);
  }
}

export const ProjecaoDeUsoServico = new ProjecaoDeUsoService();
