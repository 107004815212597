import { yupResolver } from "@hookform/resolvers/yup";
import { FileUploaderRef } from "devextreme-react/file-uploader";
import { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import BotaoCancelarMxp from "../../../../../components/botoes/botao-cancelar-mxp";
import BotaoSalvarMxp from "../../../../../components/botoes/botao-salvar-mxp";
import {
  FormCheckBox,
  FormFile,
  FormGrupo,
  FormTextBox,
} from "../../../../../components/formularios";
import FormMxp from "../../../../../components/layout/form";
import { ContainerFormMxp } from "../../../../../components/layout/form/styles";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import ToolbarMxp from "../../../../../components/layout/toolbar-mxp";
import AuditavelDTO from "../../../../../models/api/comum/auditavel-dto";
import {
  IFormularioEditavelBase,
  ResultadoAcaoFormulario,
} from "../../../../../models/shared/ui/formularios";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import { getFilenameWithoutExtension } from "../../../../../utils/file/file-utils";
import yup from "../../../../../utils/validacao/validacao";
import { StringsComum } from "../../../../comum/strings";
import RelatorioPersonalizadoContexto from "../../../../relatorio/relatorio-personalizado/contexts/relatorio-personalizado.context";
import { FinalidadeModeloRelatorio } from "../../../../relatorio/relatorio-personalizado/models/relatorio-personalizado.enums";
import { ModeloDeDocumentoOuRelatorioRequest } from "../../models/modelo-de-documento-ou-relatorio.api";
import ModeloDeDocumentoOuRelatorioConstantes from "../../models/modelo-de-documento-ou-relatorio.constantes";
import { ModeloDeDocumentoOuRelatorioPermissoes } from "../../models/modelo-de-documento-ou-relatorio.enums";
import { ModeloDeDocumentoOuRelatorioServico } from "../../servicos/modelo-de-documento-ou-relatorio.servico";

const novoRegistro: ModeloDeDocumentoOuRelatorioRequest = {
  id: 0,
  descricaoNoMenuDeImpressao: "",
  utilizarDescricaoPersonalizadaNoMenuDeImpressao: false,
  finalidade: undefined,
  arquivo: undefined,
};

let dadosAuditoria: AuditavelDTO | undefined = undefined;

interface FormModeloDeDocumentoOuRelatorioProps
  extends IFormularioEditavelBase {
  finalidade: FinalidadeModeloRelatorio;
}

export default function FormModeloDeDocumentoOuRelatorio(
  props: FormModeloDeDocumentoOuRelatorioProps
) {
  const { carregarMenu } = useContext(RelatorioPersonalizadoContexto);

  const [nomeDoArquivoNaEdicao, setNomeDoArquivoNaEdicao] = useState<
    string | undefined
  >(undefined);
  const refUploader = useRef<FileUploaderRef>(null);

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    utilizarDescricaoPersonalizadaNoMenuDeImpressao: yup.boolean().required(),
    descricaoNoMenuDeImpressao: yup
      .string()
      .required()
      .max(ModeloDeDocumentoOuRelatorioConstantes.DescricaoTamanhoMaximo),
    finalidade: yup
      .mixed<FinalidadeModeloRelatorio>()
      .oneOf(
        Object.values(FinalidadeModeloRelatorio).map((x) => x as number),
        StringsComum.valorInvalido
      )
      .required(),
    arquivo: yup
      .mixed<File>()
      .when(["id"], (valores, schema) => {
        if (valores[0] === 0) {
          // Obrigatório na inserção
          return schema.required();
        } else {
          return schema.notRequired();
        }
      })
      .test(
        "fileSize",
        "Tamanho máximo aceito do arquivo é de 10MB.",
        (value) => {
          if (!value) {
            return true;
          }

          return (
            value.size <=
            ModeloDeDocumentoOuRelatorioConstantes.TamanhoMaximoDoArquivoEmBytes
          );
        }
      )
      .test("fileType", "Extensões aceitas: .docx e .xlsx.", (value) => {
        if (!value) {
          return true;
        }

        return (
          value.type ==
            ModeloDeDocumentoOuRelatorioConstantes.ContentTypeWord ||
          value.type == ModeloDeDocumentoOuRelatorioConstantes.ContentTypeExcel
        );
      }),
  });

  const { register, control, handleSubmit, getValues, reset, setValue, watch } =
    useForm<ModeloDeDocumentoOuRelatorioRequest>({
      resolver: yupResolver(schema),
    });

  function limparTela() {
    dadosAuditoria = undefined;
    setNomeDoArquivoNaEdicao(undefined);
    reset(novoRegistro);
    refUploader?.current?.instance().reset();
  }
  const devePersonalizarDescricao = watch(
    "utilizarDescricaoPersonalizadaNoMenuDeImpressao"
  );

  useEffect(() => {
    const arquivo = getValues("arquivo");
    const nome = getFilenameWithoutExtension(
      arquivo?.name ?? nomeDoArquivoNaEdicao ?? ""
    );
    const descricaoEmTela = getValues("descricaoNoMenuDeImpressao");

    if (devePersonalizarDescricao) {
      setValue(
        "descricaoNoMenuDeImpressao",
        descricaoEmTela !== nome ? descricaoEmTela : ""
      );

      return;
    }

    setValue("descricaoNoMenuDeImpressao", nome);
  }, [devePersonalizarDescricao, watch("arquivo")]);

  //Hook usado para carregar os dados da tela
  useEffect(() => {
    if (Number.isNaN(props.idRegistroEmEdicao)) {
      return;
    }

    preencherTela();
  }, [props.idRegistroEmEdicao]);

  async function preencherTela() {
    if (props.idRegistroEmEdicao == 0) {
      limparTela();
      setValue("finalidade", props.finalidade);
    } else if (props.idRegistroEmEdicao > 0) {
      await carregarRegistroDoId();
    }
  }

  async function carregarRegistroDoId() {
    try {
      const resposta =
        await ModeloDeDocumentoOuRelatorioServico.ObterPorIdComDadosAuditoria(
          props.idRegistroEmEdicao
        );
      checarResponse(resposta);
      dadosAuditoria = resposta.model;
      setNomeDoArquivoNaEdicao(resposta.model.nomeDoArquivo);
      reset(resposta.model);
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  async function handleSalvar() {
    const model = getValues();
    try {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          ModeloDeDocumentoOuRelatorioPermissoes.InserirEditar,
        ])
      ) {
        return;
      }

      const resposta =
        props.idRegistroEmEdicao > 0
          ? await ModeloDeDocumentoOuRelatorioServico.Atualizar(model)
          : await ModeloDeDocumentoOuRelatorioServico.Inserir(model);

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        await carregarMenu();
        fechar(ResultadoAcaoFormulario.AcaoConcluida);
      }
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  function fechar(resultado: ResultadoAcaoFormulario) {
    limparTela();
    props.handleCallback(resultado);
  }

  function handleCancelar() {
    fechar(ResultadoAcaoFormulario.AcaoCancelada);
  }

  return (
    <>
      <ContainerFormMxp>
        <FormMxp>
          <ProvedorAjuda id="edit-form-modelo-de-documento-ou-relatorio">
            <input type="hidden" {...register("id")} defaultValue={0} />
            <input type="hidden" {...register("finalidade")} />

            <FormGrupo>
              <Linha>
                <Coluna md={12}>
                  <FormCheckBox
                    name="utilizarDescricaoPersonalizadaNoMenuDeImpressao"
                    titulo="Utilizar descrição personalizada na exibição do menu de impressão"
                    control={control}
                  />
                </Coluna>
              </Linha>
              <Linha>
                <Coluna md={12}>
                  <FormTextBox
                    name="descricaoNoMenuDeImpressao"
                    titulo="Descrição no menu de impressão"
                    control={control}
                    requerido
                    tamanhoMaximo={
                      ModeloDeDocumentoOuRelatorioConstantes.DescricaoTamanhoMaximo
                    }
                    somenteLeitura={!devePersonalizarDescricao}
                  />
                </Coluna>
              </Linha>
              <Linha>
                <Coluna md={5}>
                  <FormFile
                    refExterna={refUploader}
                    name="arquivo"
                    control={control}
                    extensoesAceitas={
                      ModeloDeDocumentoOuRelatorioConstantes.ExtensoesAceitas
                    }
                  />
                </Coluna>
              </Linha>
            </FormGrupo>
          </ProvedorAjuda>
        </FormMxp>
        <ToolbarMxp dadosAuditoria={dadosAuditoria}>
          <BotaoSalvarMxp handleClick={handleSubmit(handleSalvar)} />
          <BotaoCancelarMxp handleClick={handleCancelar} />
        </ToolbarMxp>
      </ContainerFormMxp>
    </>
  );
}
