import { useCallback } from "react";
import ImportadorDeRegistros, {
  colunaParaBoolean,
  colunaParaNumero,
  MxpSchemaImportar,
} from "../../../../../components/formularios/importador";
import { InserirEmMassaResponse } from "../../../../../models/api/comum/inserir-varios";
import { ResponseModel } from "../../../../../models/api/comum/response-base";
import {
  quantidadeValorMaximo,
  quantidadeValorMinimo,
} from "../../../../../utils/common/constantes";
import yup from "../../../../../utils/validacao/validacao";
import { InsumoDaOrdemDeProducaoServico } from "../../../insumo-da-ordem-de-producao/servicos/insumo-da-ordem-de-producao";
import { OrdemDeProducaoBaixarVariasExcelRequest } from "../../models/ordem-de-producao.api";

const schema: MxpSchemaImportar<OrdemDeProducaoBaixarVariasExcelRequest> = {
  "Ordem de produção *": {
    prop: "numeroOrdemDeProducao",
    type: colunaParaNumero,
    dataType: "number",
  },
  "Insumo *": {
    prop: "codigoInsumo",
    type: String,
    dataType: "string",
  },
  "Operação *": {
    prop: "numeroOperacaoDaOrdemDeProducao",
    type: colunaParaNumero,
    dataType: "number",
  },
  "Lote/número de série": {
    prop: "loteOuNumeroDeSerie",
    type: String,
    dataType: "string",
  },
  "Conta de estoque": {
    prop: "apelidoContaDeEstoque",
    type: String,
    dataType: "number",
  },
  "Endereço de estoque": {
    prop: "codigoEnderecoDeEstoque",
    type: String,
    dataType: "number",
  },
  Qualidade: {
    prop: "codigoQualidade",
    type: String,
    dataType: "string",
  },
  Dono: {
    prop: "apelidoDonoEstoque",
    type: String,
    dataType: "string",
  },
  Quantidade: {
    prop: "quantidade",
    type: colunaParaNumero,
    dataType: "number",
  },
  "Baixar qtde maior do que o previsto": {
    prop: "baixarQuantidadeMaiorQuePrevisto",
    type: colunaParaBoolean,
    dataType: "boolean",
  },
  "Consumir para qual número de série": {
    prop: "numeroDeSerieDestino",
    type: String,
    dataType: "string",
  },
  "Manter em estoque separado *": {
    prop: "manterEmEstoqueSeparado",
    type: colunaParaBoolean,
    dataType: "boolean",
  },
  "Forçar conclusão da baixa se qtde consumida menor que a prevista": {
    prop: "forcarConclusaoSeQuantidadeMenorQuePrevista",
    type: colunaParaBoolean,
    dataType: "boolean",
  },
};

const schemaYup = yup.object({
  numeroOrdemDeProducao: yup
    .number()
    .default(undefined)
    .transform((parsedValue, originalValue) =>
      originalValue === "" ? null : parsedValue
    )
    .required('O preenchimento da coluna "Ordem de produção" é obrigatório.')
    .integer()
    .typeError("Número da ordem de produção inválido")
    .label("Ordem de produção"),
  codigoInsumo: yup
    .string()
    .required('O preenchimento da coluna "Insumo" é obrigatório.')
    .min(1)
    .label("Insumo"),
  numeroOperacaoDaOrdemDeProducao: yup
    .number()
    .default(undefined)
    .transform((parsedValue, originalValue) =>
      originalValue === "" ? null : parsedValue
    )
    .required('O preenchimento da coluna "Operação" é obrigatório.')
    .integer()
    .typeError("Número da operação inválido")
    .label("Operação"),
  quantidade: yup
    .number()
    .required()
    .transform((parsedValue, originalValue) =>
      originalValue === "" ? null : parsedValue
    )
    .moreThan(
      quantidadeValorMinimo.toNumber(),
      `O valor do campo "Quantidade" deve ser maior que ${quantidadeValorMinimo.toNumber()}.`
    )
    .lessThan(
      quantidadeValorMaximo.toNumber(),
      `O valor do campo "Quantidade" deve ser menor que ${quantidadeValorMaximo.toNumber()}.`
    )
    .label("Quantidade"),
  manterEmEstoqueSeparado: yup
    .bool()
    .required(
      'O preenchimento da coluna "Manter em estoque separado" é obrigatório.'
    )
    .label("Manter em estoque separado"),
});

export interface ModalBaixaExcelProps {
  visivel: boolean;
  onFechar: () => void;
  onImportar: (resultado: ResponseModel<InserirEmMassaResponse[]>) => void;
}

export default function ModalBaixaExcel({
  visivel,
  onFechar,
  onImportar,
}: ModalBaixaExcelProps) {
  const handleImportarExcel = useCallback(
    async (registros: OrdemDeProducaoBaixarVariasExcelRequest[]) => {
      const resultado = await InsumoDaOrdemDeProducaoServico.baixarEmMassaExcel(
        registros
      );
      onImportar(resultado);
      return resultado;
    },
    [onImportar]
  );

  return (
    <ImportadorDeRegistros
      titulo="Importar baixas para ordens de produção de arquivo Excel"
      onFechar={onFechar}
      nomeDaPlanilha="Baixas Ordem de Produção"
      visivel={visivel}
      onImportar={handleImportarExcel}
      modeloUrl={"../modelosXlsx/Planilha_Modelo_Baixa_para_OP_MAXIPROD.xlsx"}
      schema={schema}
      schemaYup={schemaYup}
    />
  );
}
