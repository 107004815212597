import { Button } from "devextreme-react";

interface BotaoValidarMxpProps {
  handleClick: () => void;
}

export default function BotaoValidarMxp(props: BotaoValidarMxpProps) {
  return (
    <Button
      type="normal"
      text="Validar"
      icon="check"
      onClick={props.handleClick}
    />
  );
}
