import { NomesEndpoints } from "../../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../../services/comum/serviceBase";

class NaturezaDoRendimentoService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.NaturezaDoRendimento);
  }
}

export const NaturezaDoRendimentoServico = new NaturezaDoRendimentoService();
