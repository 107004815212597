import { DataGrid } from "devextreme-react";
import { Column, DataGridRef } from "devextreme-react/data-grid";
import { useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import LinkButton from "../../../../../components/botoes/link-button";
import { atualizarToken } from "../../../../../configs/api";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../hooks/store.hooks";
import { PermissoesUsuario } from "../../../../../models/permissoes/seguranca/usuario/permissoes-usuario";
import { definirEstadoAtualizacaoToken } from "../../../../../store/ui/ui.slice";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import { GravarSessaoReduxELocalStorage } from "../../../../../utils/oauth/oauth-utils";
import { renderToStringClient } from "../../../../../utils/react/react-utils";
import GestorLocalStorage, {
  ChavesLocalstorage,
} from "../../../../../utils/storage/gestor-storage";
import { decodificarTipoDeAcesso } from "../../../../sistema/usuario/models/usuario.enum";
import { TokenResponse } from "../../../login/models/login.api";
import { ControleAcessoServico } from "../../../login/servicos/controle-acesso.servico";
import { TokenServico } from "../../../login/servicos/token.servico";
import { UsuariosLogadosGrid } from "../../models/usuarios-logados";
import { MensagemSuperiorGridUsuariosLogados } from "./styles";

const dataSource = ControleAcessoServico.obterDataSourceUsuariosLogadoGrid();
const nameOfGridHandler = criarNameof<UsuariosLogadosGrid>();

export function GridUsuariosLogados() {
  const gridRef = useRef<DataGridRef>(null);

  const limiteAtingido: boolean = useAppSelector(
    (state) => !!state.sessao.dadosSessao?.simplificado
  );

  const dispatch = useAppDispatch();

  const gridController = new GridController<UsuariosLogadosGrid>(() =>
    gridRef.current?.instance()
  );

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  async function forcarLogoff(
    idUsuario: number,
    nome: string,
    protegerLogoffForcado: boolean
  ) {
    try {
      if (
        protegerLogoffForcado &&
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesUsuario.ForcarLogoffMesmoComProtecao,
        ])
      ) {
        return;
      }

      const mensagem = renderToStringClient(
        <>
          Tem certeza de que deseja forçar o logoff do usuário{" "}
          <strong>
            <i>{nome}</i>
          </strong>
          ?
        </>
      );

      const excluir = await exibirConfirmacao("Confirmar", mensagem);

      if (excluir) {
        const resposta = await ControleAcessoServico.forcarLogoff(idUsuario);
        checarResponse(resposta);

        if (resposta.sucesso) {
          exibirNotificacaoToast({
            mensagem: resposta.mensagem,
            tipo: TipoNotificacao.Sucesso,
          });

          /* Força atualizar a sessão para que caso haja sessões disponíveis,
           após derrubar, libera o usuário para acessar outras telas. */
          dispatch(definirEstadoAtualizacaoToken("ocioso"));
          await atualizarToken("atualizando", {}, true);
          const dadosToken = GestorLocalStorage.LerItem<TokenResponse>(
            ChavesLocalstorage.DadosSessao
          );
          const dados = await TokenServico.getDadosSessao(dadosToken!);
          GravarSessaoReduxELocalStorage({ dadosSessao: dados }, dadosToken!);

          handleAtualizarGrid();
        }
      }
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  const linkForcarLogoff = (dados: UsuariosLogadosGrid) => {
    return (
      <LinkButton
        sublinhado
        onClick={async () =>
          await forcarLogoff(
            dados.idUsuario,
            dados.agrupamentoUsuario,
            dados.protegerLogoffForcado
          )
        }
      >
        Forçar logoff
      </LinkButton>
    );
  };

  const urlMxp1 = process.env.REACT_APP_BACKEND_ANTIGO_ENDPOINT as string;

  const tipoAcessoUsuario = useAppSelector(
    (state) => state.sessao.dadosSessao?.usuario.tipoDeAcesso
  );

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar("usuarios-logados", () =>
      gridRef.current?.instance()
    )
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(NomesTelas.usuariosLogados)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirSelecao("none")
      .definirOrdenacao()
      .build();
  }, []);

  return (
    <>
      {limiteAtingido && (
        <MensagemSuperiorGridUsuariosLogados>
          Limite de usuários simultâneos do tipo:{" "}
          {decodificarTipoDeAcesso(tipoAcessoUsuario!)} excedido. Você pode
          desconectar um usuário da lista ou, se preferir,
          <a href={`${urlMxp1}/Assinatura`}> alterar a sua assinatura</a>.
        </MensagemSuperiorGridUsuariosLogados>
      )}
      {!limiteAtingido && (
        <MensagemSuperiorGridUsuariosLogados>
          Você pode usar o sistema normalmente.
          <Link to="/">Clique aqui</Link> para ir para a tela inicial.
        </MensagemSuperiorGridUsuariosLogados>
      )}
      <DataGrid ref={gridRef} {...configuracoesGrid}>
        <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
        <Column
          dataField={nameOfGridHandler("agrupamentoUsuario")}
          {...obterConfiguracaoColuna("stringM")}
          caption="Usuário"
          allowSorting={false}
        />
        <Column
          dataField={nameOfGridHandler("idUsuario")}
          {...obterConfiguracaoColuna("stringP")}
          caption=""
          allowSorting={false}
          allowFiltering={false}
          cellRender={({ data }) =>
            linkForcarLogoff(data as UsuariosLogadosGrid)
          }
          alignment="left"
          showInColumnChooser={false}
        />
        <Column
          dataField={nameOfGridHandler("tipoDeAcesso")}
          {...obterConfiguracaoColuna("stringM")}
          caption="Tipo de acesso"
        />
      </DataGrid>
    </>
  );
}
