import getApi from "../../../../configs/api";
import { ResponseModel } from "../../../../models/api/comum/response-base";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { criarDatasourceGridComParametros } from "../../../../utils/grid/back-end-grid-utils";
import {
  FiltrosRazaoPorIntervaloDeContasViewModel,
  RazaoGridModel,
} from "../models/razao-por-intervalo-de-contas.api";

class RazaoPorIntervaloDeContasService {
  private _nomeEndpoint: NomesEndpoints;

  constructor() {
    this._nomeEndpoint = NomesEndpoints.RazaoPorIntervaloDeContas;
  }

  public GetGridSource() {
    return criarDatasourceGridComParametros(`${this._nomeEndpoint}/grid`, {
      teste: 123,
    });
  }

  public async obterRegistros(
    filtros: FiltrosRazaoPorIntervaloDeContasViewModel
  ) {
    const api = getApi();

    const parametros = new URLSearchParams();

    if (filtros.contaContabilInicialId) {
      parametros.append(
        "contaContabilInicialId",
        filtros.contaContabilInicialId.toString()
      );
    }

    if (filtros.contaContabilFinalId) {
      parametros.append(
        "contaContabilFinalId",
        filtros.contaContabilFinalId.toString()
      );
    }

    if (filtros.dataInicial) {
      parametros.append("dataInicial", filtros.dataInicial);
    }

    if (filtros.dataFinal) {
      parametros.append("dataFinal", filtros.dataFinal);
    }

    if (filtros.minhasEmpresasIds && filtros.minhasEmpresasIds.length > 0) {
      filtros.minhasEmpresasIds.forEach((id) =>
        parametros.append("minhasEmpresasIds", String(id))
      );
    }

    if (filtros.considerarLancamentosDeEncerramento) {
      parametros.append(
        "considerarLancamentosDeEncerramento",
        String(filtros.considerarLancamentosDeEncerramento)
      );
    }

    const queryString = parametros.toString();

    const response = await api.get<ResponseModel<RazaoGridModel[]>>(
      `${this._nomeEndpoint}?${queryString}`
    );

    return response.data;
  }
}

export const RazaoPorIntervaloDeContasServico =
  new RazaoPorIntervaloDeContasService();
