import { AxiosRequestConfig } from "axios";
import getApi from "../../../../configs/api";
import { ResponseBaseArquivo } from "../../../../models/api/comum/response-base";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import DataSourceFactory, {
  DataSourceFiltragem,
  DataSourceRawFilter,
} from "../../../../utils/grid/data-source-factory";

class UsuarioService {
  private _nomeEndpoint: string;

  constructor() {
    this._nomeEndpoint = NomesEndpoints.Usuario;
  }

  public ObterDataSourceParaGrid<T, TKey = number>(
    filtroPadrao?: DataSourceFiltragem<T>[],
    filtroPadraoExato?: DataSourceRawFilter<T>
  ) {
    return DataSourceFactory.CriarParaGrid<T, TKey>(
      `${this._nomeEndpoint}/condutor-selecao-grid`,
      filtroPadrao,
      filtroPadraoExato
    );
  }

  public async Imprimir(loadOptions: string) {
    const api = getApi();

    const resquestConfig: AxiosRequestConfig = {
      responseType: "blob",
    };

    const response = await api.get<ResponseBaseArquivo>(
      `${this._nomeEndpoint}/Imprimir?${loadOptions}`,
      resquestConfig
    );

    return response;
  }
}

export const UsuarioServico = new UsuarioService();
