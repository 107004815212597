import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";

class UsuarioService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.Usuario);
  }
}

export const UsuarioServico = new UsuarioService();
