import DataGrid, { Column, DataGridRef } from "devextreme-react/data-grid";
import { ReactNode, useCallback, useContext, useMemo, useRef } from "react";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import BotaoEditarColunaAcaoGridMxp from "../../../../../components/grid-mxp/grid-mxp-botao-editar";
import BotaoExcluirColunaAcaoGridMxp from "../../../../../components/grid-mxp/grid-mxp-botao-excluir";
import GridColunaAcoes from "../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import MenuMxp from "../../../../../components/layout/menu-mxp";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import { LinkButton } from "../../../../../components/templates-celulas-grid/celula-controle-edicao-mxp/styles";
import { QuebrarLinhas } from "../../../../../components/texto/quebrar-linhas";
import { useControlarFormDeEdicao } from "../../../../../hooks/form.hooks";
import { useMenuDeContextosGrid } from "../../../../../hooks/menus.hooks";
import { useAppSelector } from "../../../../../hooks/store.hooks";
import { ResultadoAcaoFormulario } from "../../../../../models/shared/ui/formularios";
import GetColunasDeAuditoria from "../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import {
  checarResponse,
  checarResponseBaixarArquivo,
  checarResponseExibeMensagemExclusaoDeSucesso,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import criarNameof from "../../../../../utils/common/cria-name-of";
import { navegarParaMxpAntigoEmNovaAba } from "../../../../../utils/common/menu-utils";
import NomesModais from "../../../../../utils/common/nomes-modais";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import NormalizaTituloModal from "../../../../../utils/common/normaliza-titulo";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import { ItemContextMenuMxp } from "../../../../../utils/context-menu/context-menu-utils";
import {
  GestorEventoClickSimples,
  GestorEventoClickUnicaLinha,
} from "../../../../../utils/context-menu/gestor-evento-click";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import { renderToStringClient } from "../../../../../utils/react/react-utils";
import { StringsComum } from "../../../../comum/strings";
import RelatorioPersonalizadoContexto from "../../../../relatorio/relatorio-personalizado/contexts/relatorio-personalizado.context";
import {
  FinalidadeModeloRelatorio,
  FinalidadesPerfilAcessoRelatoriosMxp1,
} from "../../../../relatorio/relatorio-personalizado/models/relatorio-personalizado.enums";
import { ModeloDeDocumentoOuRelatorioGridModel } from "../../models/modelo-de-documento-ou-relatorio.api";
import { ModeloDeDocumentoOuRelatorioPermissoes } from "../../models/modelo-de-documento-ou-relatorio.enums";
import { ModeloDeDocumentoOuRelatorioServico } from "../../servicos/modelo-de-documento-ou-relatorio.servico";
import { StringsModeloDeDocumentoOuRelatorio } from "../../strings";
import FormModeloDeDocumentoOuRelatorio from "../formulario";

const nameOfGridHandler = criarNameof<ModeloDeDocumentoOuRelatorioGridModel>();

interface GridModeloDeDocumentoOuRelatorioProps {
  finalidade: FinalidadeModeloRelatorio;
}

export default function GridModeloDeDocumentoOuRelatorio(
  props: GridModeloDeDocumentoOuRelatorioProps
) {
  const gridRef = useRef<DataGridRef>(null);
  const dataSource =
    ModeloDeDocumentoOuRelatorioServico.ObterDataSourceParaGrid(
      props.finalidade
    );
  const {
    idRegistroEmEdicao,
    setIdRegistroEmEdicao,
    modalVisivel,
    encerrarEdicao,
  } = useControlarFormDeEdicao(NaN);

  const { carregarMenu } = useContext(RelatorioPersonalizadoContexto);

  const { definirModalDicionarioTagsVisivel, finalidade } = useContext(
    RelatorioPersonalizadoContexto
  );

  const handleAbrirDicionarioTags = useCallback(() => {
    definirModalDicionarioTagsVisivel(true);
  }, [definirModalDicionarioTagsVisivel]);

  const handleAbrirPerfilDeAcesso = useCallback(() => {
    if (finalidade) {
      const finalidadeMxp1 =
        FinalidadesPerfilAcessoRelatoriosMxp1.get(finalidade);

      navegarParaMxpAntigoEmNovaAba(
        `/PerfilRelatorio?finalidade=${encodeURIComponent(finalidadeMxp1!)}`
      );
    }
  }, [finalidade]);

  const assinanteId = useAppSelector(
    (state) => state.sessao.dadosSessao!.assinanteId
  );

  function verificarImpedimentoDeEdicao(
    registro: ModeloDeDocumentoOuRelatorioGridModel
  ): boolean {
    return registro.assinanteId == 2 && assinanteId != 2;
  }

  const handleNovoRegistro = useCallback(() => {
    if (
      verificaComNotificacaoSeUsuarioPossuiPermissoes([
        ModeloDeDocumentoOuRelatorioPermissoes.InserirEditar,
      ])
    ) {
      setIdRegistroEmEdicao(0);
    }
  }, []);

  const handleEditarRegistro = useCallback(
    (registro: ModeloDeDocumentoOuRelatorioGridModel) => {
      if (verificarImpedimentoDeEdicao(registro)) {
        return;
      }

      if (
        verificaComNotificacaoSeUsuarioPossuiPermissoes([
          ModeloDeDocumentoOuRelatorioPermissoes.InserirEditar,
        ])
      ) {
        setIdRegistroEmEdicao(registro.id);
      }
    },
    []
  );

  const handleFavoritar = useCallback(
    async (registro: ModeloDeDocumentoOuRelatorioGridModel) => {
      try {
        const response = await ModeloDeDocumentoOuRelatorioServico.Favoritar({
          id: registro.id,
        });

        const sucesso = await checarResponse(response);

        if (sucesso) {
          gridController.atualizar();
          exibirNotificacaoToast({
            mensagem: response.mensagem,
            tipo: TipoNotificacao.Sucesso,
          });
        }
      } catch (erro) {
        tratarErroApi(erro);
      }
    },
    []
  );

  const handleRestaurarFavorito = useCallback(async () => {
    try {
      const response =
        await ModeloDeDocumentoOuRelatorioServico.RestaurarFavoritoPadrao({
          finalidade: props.finalidade,
        });

      const sucesso = await checarResponse(response);

      if (sucesso) {
        gridController.atualizar();
        exibirNotificacaoToast({
          mensagem: response.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
      }
    } catch (erro) {
      tratarErroApi(erro);
    }
  }, []);

  const handleDownload = useCallback(
    async (registro: ModeloDeDocumentoOuRelatorioGridModel) => {
      try {
        const { response, nomeArquivo, extensao } =
          await ModeloDeDocumentoOuRelatorioServico.RealizarDownload(
            registro.id
          );

        const sucesso = await checarResponseBaixarArquivo(
          response,
          nomeArquivo ?? NomesTelas.ModeloDeDocumentoOuRelatorio,
          extensao
        );

        if (sucesso) {
          exibirNotificacaoToast({
            mensagem:
              StringsModeloDeDocumentoOuRelatorio.mensagemDownloadDeModeloComSucesso,
            tipo: TipoNotificacao.Sucesso,
          });
        }
      } catch (erro) {
        tratarErroApi(erro);
      }
    },
    []
  );

  const handleExcluirRegistro = useCallback(
    async (registro: ModeloDeDocumentoOuRelatorioGridModel) => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          ModeloDeDocumentoOuRelatorioPermissoes.Excluir,
        ])
      ) {
        return;
      }

      const mensagemConfirmacao = `Tem certeza de que deseja excluir o modelo "${registro.nomeOriginalSemExtensao}"? \nAo excluir um modelo de impressão de documentos/relatórios, os registros vinculados a ele também serão excluídos: perfis de acesso x relatórios.`;

      const excluir = await exibirConfirmacao(
        StringsComum.exclusaoTitulo,
        renderToStringClient(<QuebrarLinhas texto={mensagemConfirmacao} />)
      );

      if (excluir) {
        try {
          const resposta = await ModeloDeDocumentoOuRelatorioServico.Excluir(
            registro.id
          );

          if (resposta) {
            await carregarMenu();
            checarResponseExibeMensagemExclusaoDeSucesso(resposta);
            handleAtualizarGrid();
          }
        } catch (erro) {
          tratarErroApi(erro);
        }
      }
    },
    []
  );

  const gridController = useMemo(
    () =>
      new GridController<ModeloDeDocumentoOuRelatorioGridModel>(() =>
        gridRef.current?.instance()
      ),
    []
  );

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  function gerarBotoesAdicionais(
    registro: ModeloDeDocumentoOuRelatorioGridModel
  ): ReactNode[] {
    const botoesAdicionais: ReactNode[] = [];

    const botaoDesabilitado = verificarImpedimentoDeEdicao(registro);

    botoesAdicionais.push(
      <BotaoEditarColunaAcaoGridMxp
        key={`btn-editar-${registro.id}`}
        onClick={() => handleEditarRegistro(registro)}
        tooltipCustom={
          botaoDesabilitado
            ? StringsModeloDeDocumentoOuRelatorio.tooltipBotaoEditarModeloPadrao
            : undefined
        }
        desabilitado={botaoDesabilitado}
      />
    );

    botoesAdicionais.push(
      <BotaoExcluirColunaAcaoGridMxp
        key={`btn-excluir-${registro.id}`}
        onClick={() => handleExcluirRegistro(registro)}
        tooltipCustom={
          botaoDesabilitado
            ? StringsModeloDeDocumentoOuRelatorio.tooltipBotaoExcluirModeloPadrao
            : undefined
        }
        desabilitado={botaoDesabilitado}
      />
    );

    botoesAdicionais.push(
      <LinkButton
        key={`btn-download-${registro.id}`}
        onClick={() => handleDownload(registro)}
        title={StringsModeloDeDocumentoOuRelatorio.tooltipBotaoBaixarModelo}
      >
        <i className="ic-material-symbols-outlined ic-download icone-linha-grid"></i>
      </LinkButton>
    );

    return botoesAdicionais;
  }

  const menusDeContexto: ItemContextMenuMxp[] = useMemo(() => {
    return [
      {
        text: "Ações",
        icon: "ic-material-symbols-outlined ic-vertical",
        exibirNoMenuPrincipal: true,
        items: [
          {
            text: "Definir como favorito",
            icon: "ic-material-symbols-outlined ic-star",
            exibirNaLinhaDaGrid: "menuDeContexto",
            exibirNoMenuPrincipal: true,
            gestorEventoClick: new GestorEventoClickUnicaLinha(
              handleFavoritar,
              () => gridController
            ),
          },
          {
            text: "Restaurar o favorito",
            icon: "ic-material-symbols-outlined ic-undo",
            exibirNaLinhaDaGrid: "menuDeContexto",
            exibirNoMenuPrincipal: true,
            gestorEventoClick: new GestorEventoClickSimples(
              handleRestaurarFavorito
            ),
          },
        ],
      },
      {
        text: "Consultar",
        icon: "ic-material-symbols-outlined ic-manage-search",
        exibirNoMenuPrincipal: true,
        items: [
          {
            text: "Dicionário de tags",
            icon: "ic-material-symbols-outlined ic-data-array",
            gestorEventoClick: new GestorEventoClickSimples(
              handleAbrirDicionarioTags
            ),
          },
          {
            text: "Perfis de acesso x relatórios",
            icon: "ic-material-symbols-outlined ic-settings-account-box",
            gestorEventoClick: new GestorEventoClickSimples(
              handleAbrirPerfilDeAcesso
            ),
          },
        ],
      },
      {
        text: "Definir como favorito",
        hint: 'O modelo de relatório favorito será utilizado ao clicar no ícone de impressão ou no botão "Imprimir". Somente um modelo pode ser selecionado como favorito.',
        icon: "ic-material-symbols-outlined ic-star",
        exibirNaLinhaDaGrid: "menuDeContexto",
        gestorEventoClick: new GestorEventoClickUnicaLinha(
          handleFavoritar,
          () => gridController
        ),
      },
      {
        text: "Restaurar o favorito",
        hint: 'Restaura o modelo favorito, utilizado no ícone de impressão e no botão "Imprimir" para o padrão do sistema.',
        icon: "ic-material-symbols-outlined ic-undo",
        exibirNaLinhaDaGrid: "menuDeContexto",
        gestorEventoClick: new GestorEventoClickUnicaLinha(
          handleRestaurarFavorito,
          () => gridController
        ),
      },
    ];
  }, [gridController]);

  useMenuDeContextosGrid(menusDeContexto);

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar(
      "modelo-de-documento-ou-relatorio",
      () => gridRef.current?.instance(),
      false
    )
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(NomesTelas.ModeloDeDocumentoOuRelatorio)
      .definirBotaoNovo(handleNovoRegistro)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirSelecao()
      .definirOrdenacao()
      .definirDuploCliqueLinha(handleEditarRegistro)
      .definirStyles({ height: "70vh" })
      .definirMenuDeContexto(menusDeContexto)
      .build();
  }, []);

  const handleCallbackFormulario = useCallback(
    (resultado: ResultadoAcaoFormulario) => {
      encerrarEdicao();

      if (resultado == ResultadoAcaoFormulario.AcaoConcluida) {
        handleAtualizarGrid();
      }
    },
    []
  );

  const handleFecharModal = useCallback(() => {
    encerrarEdicao();
  }, []);

  return (
    <>
      <MenuMxp />
      <ProvedorAjuda id="tooltips-grid-modelo-de-documento-ou-relatorio">
        <DataGrid ref={gridRef} {...configuracoesGrid}>
          <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
          {GridColunaAcoes<ModeloDeDocumentoOuRelatorioGridModel>({
            gerarBotoesAdicionais: gerarBotoesAdicionais,
          })}
          <Column
            key={nameOfGridHandler("nomeOriginalSemExtensao")}
            dataField={nameOfGridHandler("nomeOriginalSemExtensao")}
            {...obterConfiguracaoColuna("stringXG")}
            caption={"Arquivo"}
            cellRender={({ data }) => {
              const dados = data as ModeloDeDocumentoOuRelatorioGridModel;
              return (
                <>
                  {dados.nomeOriginalSemExtensao + dados.extensao}
                  {dados.favorito && (
                    <i
                      className="ic-material-symbols-outlined ic-star"
                      title='Modelo de relatório favorito. Será utilizado ao clicar no ícone de impressão ou ao clicar no botão "Imprimir"'
                      style={{
                        fontSize: "9pt",
                        paddingLeft: "2px",
                        paddingTop: "2px",
                      }}
                    ></i>
                  )}
                </>
              );
            }}
          />
          <Column
            key={nameOfGridHandler("descricaoNoMenuDeImpressao")}
            dataField={nameOfGridHandler("descricaoNoMenuDeImpressao")}
            {...obterConfiguracaoColuna("stringXG")}
            caption={"Descrição no menu de impressão"}
          />
          <Column
            key={nameOfGridHandler("extensao")}
            dataField={nameOfGridHandler("extensao")}
            {...obterConfiguracaoColuna("stringP")}
            caption="Extensão"
            visible={false}
          />
          <Column
            key={nameOfGridHandler("finalidadeDecodificada")}
            dataField={nameOfGridHandler("finalidadeDecodificada")}
            {...obterConfiguracaoColuna("stringMG")}
            caption="Finalidade"
            visible={false}
          />
          {GetColunasDeAuditoria()}
        </DataGrid>

        <ModalMxp
          titulo={NormalizaTituloModal.Normalizar(
            idRegistroEmEdicao,
            NomesModais.ModeloDeDocumentoOuRelatorio
          )}
          visivel={modalVisivel}
          handleFechar={handleFecharModal}
          largura={"max(50vw, 800px)"}
        >
          <FormModeloDeDocumentoOuRelatorio
            finalidade={props.finalidade}
            idRegistroEmEdicao={idRegistroEmEdicao}
            handleCallback={handleCallbackFormulario}
          />
        </ModalMxp>
      </ProvedorAjuda>
    </>
  );
}
