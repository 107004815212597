import differenceInHours from "date-fns/differenceInHours";
import format from "date-fns/format";
import TabPanel, { Item, TabPanelRef } from "devextreme-react/tab-panel";
import {
  Fragment,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import PdfViewer from "../../../../../components/arquivo/pdf-viewer";
import BotaoAutorizarMxp from "../../../../../components/botoes/botao-autorizar-mxp";
import BotaoBaixarXmlMxp from "../../../../../components/botoes/botao-baixar-xml-mxp";
import BotaoCancelarMDFeMxp from "../../../../../components/botoes/botao-cancelar-mdfe-mxp";
import BotaoCancelarMxp from "../../../../../components/botoes/botao-cancelar-mxp";
import BotaoConsultarSituacaoMxp from "../../../../../components/botoes/botao-consultar-situacao-mxp";
import BotaoDesfazerValidacaoMxp from "../../../../../components/botoes/botao-defazer-validacao-mxp";
import BotaoEncerrarMxp from "../../../../../components/botoes/botao-encerrar-mxp";
import BotaoSalvarMxp from "../../../../../components/botoes/botao-salvar-mxp";
import BotaoValidarMxp from "../../../../../components/botoes/botao-validar-mxp";
import BotaoVisualizarPDFMxp from "../../../../../components/botoes/botao-visualizar-pdf-mxp";
import ContainerSemFormMxp from "../../../../../components/layout/container-sem-form";
import { ContainerFormMDFe } from "../../../../../components/layout/form/styles";
import { Modal } from "../../../../../components/layout/modal";
import TabContainer from "../../../../../components/layout/tab-container";
import ToolbarMxp from "../../../../../components/layout/toolbar-mxp";
import { useSincronousUseState } from "../../../../../hooks/form.hooks";
import AuditavelDTO from "../../../../../models/api/comum/auditavel-dto";
import { AmbienteMDFeOpcoes } from "../../../../../models/api/tokens/configuracoes";
import { PermissoesMDF } from "../../../../../models/permissoes/fiscal/mdfe/permissoes-mdf";
import {
  IFormulario,
  IFormularioEditavelBase,
  ResultadoAcaoFormulario,
} from "../../../../../models/shared/ui/formularios";
import { ComponentAsyncLoader } from "../../../../../parts/utils/load-on-demand";
import { NomesEndpoints } from "../../../../../services/comum/nomesEndpoints";
import APIBase from "../../../../../services/comum/serviceBase";
import { ConfiguracoesServico } from "../../../../../services/configuracoes/configuracoes.service";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import { aguardar } from "../../../../../utils/common/common-utils";
import {
  JanelasDeNotificacaoTitulos,
  TipoNotificacao,
  default as exibirNotificacaoToast,
  default as exibirNotificaoToast,
} from "../../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import { exibirAlerta, exibirConfirmacao } from "../../../../../utils/dialogos";
import {
  baixarXmlMdfe,
  visualizarImpressaoPdfDamdfe,
} from "../../../../../utils/especifico/mdfe/mdfe-utils";
import { renderToStringClient } from "../../../../../utils/react/react-utils";
import { definirIndiceSelecionadoTabPanel } from "../../../../../utils/tab-panel";
import MDFeEditFormContext from "../../contexts/mdfe-editform.context";
import { MDFeCompletoDTO } from "../../models/mdfe";
import {
  SituacaoMDFe,
  situacaoMDFeDecodificada,
} from "../../models/mdfe-enums";
import MDFeConstantes from "../../models/mdfe.constantes";
import { MDFeServico } from "../../servicos/mdfe.service";
import MDFeAbaCondutor from "../abas/condutores";
import MDFeAbaDadosGerais from "../abas/dados-gerais";
import MDFeAbaDocumentosFiscais from "../abas/documentos-fiscais";
import { MDFeAbaObservacoes } from "../abas/observacoes";
import MDFeAbaPercurso from "../abas/percurso";
import { MDFeAbaSeguro } from "../abas/seguro";
import MDFeAbaDadosVeiculos from "../abas/veiculos";
import InfosCabecalhoMDFe from "../infos-cabecalho-mdfe";
import QuadroAmbienteHomologacao from "../quadro-ambiente-homologacao";
import { EditFormCancelamentoMDFe } from "./formularios-acessorios/edit-form-cancelamento";
import { EditFormEncerramentoMDFe } from "./formularios-acessorios/edit-form-encerramento";
import { CabecadoMDFe } from "./styles";

const nomeEndpoint = NomesEndpoints.MDFe;

const rederizarTitulo = (item: any) => item.text;

export const EditFormMDFe = memo(
  ({ idRegistroEmEdicao, handleCallback }: IFormularioEditavelBase) => {
    const [modalPdfViewerVisivel, setModalPdfViewerVisivel] = useState(false);
    const [documentoPdfDamdfe, setDocumentoPdfDamdfe] = useState("");

    const [modalEncerramentoVisivel, setModalEncerramentoVisivel] =
      useState(false);

    const [modalCancelamentoVisivel, setModalCancelamentoVisivel] =
      useState(false);

    const [dadosAuditoria, setDadosAuditoria] = useState<AuditavelDTO>();
    const [ambienteMdfe, setAmbienteMdfe] = useState<AmbienteMDFeOpcoes | null>(
      null
    );
    // Gera referência de um componente filho
    const refDadosGerais = useRef<IFormulario>();
    const refDocumentosFiscais = useRef<IFormulario>();
    const refVeiculos = useRef<IFormulario>();
    const refPercursos = useRef<IFormulario>();
    const refCondutores = useRef<IFormulario>();
    const refSeguros = useRef<IFormulario>();
    const refObservacoes = useRef<IFormulario>();

    const forms = useMemo(() => {
      return [
        refDadosGerais,
        refDocumentosFiscais,
        refVeiculos,
        refPercursos,
        refCondutores,
        refSeguros,
        refObservacoes,
      ];
    }, [
      refDadosGerais,
      refDocumentosFiscais,
      refVeiculos,
      refPercursos,
      refCondutores,
      refSeguros,
      refObservacoes,
    ]);

    const {
      baseMdfe,
      dadosGerais,
      descarregamentos,
      carregamentos,
      condutores,
      averbacoes,
      visibilidadeBotoes,
      telaSomenteLeitura,
      obterDocumentoAtual,
      novoDocumento,
      carregar,
      definirDataEmissao,
      limparContexto,
    } = useContext(MDFeEditFormContext);

    useEffect(() => {
      ConfiguracoesServico.ObterConfiguracoesMdfe().then((resposta) => {
        setAmbienteMdfe(resposta.ambiente);
      });
    }, []);

    const tabPanelRef = useRef<TabPanelRef>(null);

    useEffect(() => {
      if (!Number.isNaN(idRegistroEmEdicao)) {
        carregarTela();
      } else {
        limparContexto();
      }
    }, [idRegistroEmEdicao]);

    const fechar = useCallback(
      (info: ResultadoAcaoFormulario) => {
        if (handleCallback) {
          handleCallback(info);
        }
      },
      [handleCallback]
    );

    const callBackUnprocessableEntity = useCallback(() => {
      fechar(ResultadoAcaoFormulario.AcaoComErro);
    }, [fechar]);

    const carregarModel = useCallback(async () => {
      try {
        const resposta = await APIBase.obterPorId<MDFeCompletoDTO>(
          idRegistroEmEdicao,
          nomeEndpoint
        );
        checarResponse(resposta);
        carregar(resposta.model);
        setDadosAuditoria({
          criacaoUsuarioApelido: resposta.model.criacaoUsuarioApelido,
          criacaoData: resposta.model.criacaoData,
          alteracaoUsuarioApelido: resposta.model.alteracaoUsuarioApelido,
          ultimaAlteracaoData: resposta.model.ultimaAlteracaoData,
        });
      } catch (erro) {
        tratarErroApi(erro, callBackUnprocessableEntity);
      }
    }, [callBackUnprocessableEntity]);

    const carregarTela = useCallback(async () => {
      try {
        if (idRegistroEmEdicao > 0) {
          await carregarModel();
        } else {
          novoDocumento();
          setDadosAuditoria(undefined);
        }
      } catch (erro) {
        tratarErroApi(erro);
      } finally {
        definirIndiceSelecionadoTabPanel(tabPanelRef.current, 0);
      }
    }, [novoDocumento, carregarModel, idRegistroEmEdicao]);

    const handleCancelar = useCallback(async () => {
      if (forms.some((f) => f.current?.isDirty())) {
        const confirmacao = await exibirConfirmacao(
          "Aviso",
          "Há dados não salvos. Deseja cancelar?"
        );

        if (!confirmacao) {
          return;
        }
      }

      fechar(ResultadoAcaoFormulario.AcaoCancelada);
    }, [fechar, forms]);

    const formsValidos = useCallback(async (): Promise<boolean> => {
      forms.forEach((f) => {
        f.current?.requestSubmit();
      });

      await aguardar(50); //Apenas para garantir que a store esteja atualizada

      const mensagens: string[] = [];

      let valido = true;

      for (let i = 0; i < forms.length; i++) {
        valido = forms[i].current?.valido() ?? false;

        if (!valido) {
          definirIndiceSelecionadoTabPanel(tabPanelRef.current, i);
          break;
        }
      }

      const haDocumentos =
        descarregamentos.length > 0 &&
        descarregamentos.every(
          (x) => (x.documentosVinculados?.length ?? 0) > 0
        );

      if (!haDocumentos) {
        if (valido) {
          definirIndiceSelecionadoTabPanel(
            tabPanelRef.current,
            forms.indexOf(refDocumentosFiscais)
          );
        }

        valido = false;
        mensagens.push(
          'Não é possível salvar MDF-e sem um documento fiscal informado. Vincule ao menos um documento fiscal na aba "Documentos fiscais"'
        );
      }

      const haMunicipios = carregamentos.length > 0;

      if (!haMunicipios) {
        if (valido) {
          definirIndiceSelecionadoTabPanel(
            tabPanelRef.current,
            forms.indexOf(refPercursos)
          );
        }

        valido = false;
        mensagens.push(
          'Não é possível salvar MDF-e sem um município de carregamento informado. Vincule ao menos um município de carregamento na aba de "Percurso"'
        );
      }

      const haCondutores = condutores.length > 0;

      if (!haCondutores) {
        if (valido) {
          definirIndiceSelecionadoTabPanel(
            tabPanelRef.current,
            forms.indexOf(refCondutores)
          );
        }

        valido = false;
        mensagens.push(
          'Não é possível salvar MDF-e sem um condutor informado. Vincule ao menos um condutor na aba de "Condutores"'
        );
      }

      const quantidadeAverbacoes = averbacoes.length;

      if (quantidadeAverbacoes > 0) {
        if (valido) {
          definirIndiceSelecionadoTabPanel(
            tabPanelRef.current,
            forms.indexOf(refSeguros)
          );
        }

        if (quantidadeAverbacoes > MDFeConstantes.QuantidadeMaximaAverbacoes) {
          valido = false;
          mensagens.push(
            `Não é possível adicionar mais averbações, pois possui o limite é de ${MDFeConstantes.QuantidadeMaximaAverbacoes} registros.`
          );
        }
      }

      if (mensagens.length > 0) {
        const html = renderToStringClient(
          <ul>
            {mensagens.map((x, i) => (
              <Fragment key={`li_${i}`}>
                {x}
                <br />
              </Fragment>
            ))}
          </ul>
        );

        exibirAlerta(JanelasDeNotificacaoTitulos.Atencao, html);
      }

      return valido;
    }, [carregamentos, descarregamentos, condutores, averbacoes, forms]);

    const inserirOuAtualizarMdfe = useCallback(async () => {
      try {
        const model = obterDocumentoAtual();

        const registroJaGravado = baseMdfe.id > 0;
        const resposta = registroJaGravado
          ? await MDFeServico.atualizarEObterMDFe(model)
          : await MDFeServico.inserirEObterMDFe(model);

        if (checarResponse(resposta)) {
          exibirNotificacaoToast({
            mensagem: resposta.mensagem,
            tipo: TipoNotificacao.Sucesso,
          });
          fechar(ResultadoAcaoFormulario.AcaoConcluida);
        }
        fechar(ResultadoAcaoFormulario.AcaoComErro);
      } catch (erro) {
        tratarErroApi(erro, callBackUnprocessableEntity);
      }
    }, [callBackUnprocessableEntity, fechar, baseMdfe.id, obterDocumentoAtual]);

    const callInserirOuAtualizarMdfe = useSincronousUseState(
      inserirOuAtualizarMdfe
    );

    const verificarEAtualizarDataEmissao = useCallback(async () => {
      const dataEmissao = new Date(dadosGerais.dataHoraEmissao);

      const dataAtual = new Date();

      if (differenceInHours(dataAtual, dataEmissao) >= 24) {
        const mensagem = renderToStringClient(
          <>
            O campo data/hora de emissão (
            {format(dataEmissao, "dd/MM/yy HH:mm:ss")}) está mais de 24 horas
            atrasada em relação à data/hora atual, isso pode <br /> resultar na
            rejeição 228 - Data de emissão muito atrasada. Deseja atualizar a
            data/hora de emissão para atual?
          </>
        );

        const atualizarData = await exibirConfirmacao(
          JanelasDeNotificacaoTitulos.Atencao,
          mensagem
        );

        if (atualizarData) {
          const dataAtualFormatada = format(
            new Date(),
            "yyyy-MM-dd'T'HH:mm:ss"
          );
          definirDataEmissao(dataAtualFormatada);
        }
      }
    }, [baseMdfe, definirDataEmissao]);

    const onClickSalvar = useCallback(async () => {
      if (await formsValidos()) {
        callInserirOuAtualizarMdfe();
      }
    }, [formsValidos, callInserirOuAtualizarMdfe]);

    const callValidar = useSincronousUseState(async () => {
      try {
        const model = obterDocumentoAtual();

        const resposta = await MDFeServico.validar(model);

        if (checarResponse(resposta)) {
          carregar(resposta.model);

          exibirNotificaoToast({
            mensagem: resposta.mensagem,
            tipo: TipoNotificacao.Sucesso,
          });
        }
      } catch (erro) {
        tratarErroApi(erro, callBackUnprocessableEntity);
      }
    });

    const onClickValidar = useCallback(async () => {
      const possuiPermissao = verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesMDF.Validar,
      ]);

      if (!possuiPermissao) {
        return;
      }

      // Sanity check
      if (idRegistroEmEdicao == 0) {
        exibirAlerta("Atenção", "Grave o registro antes de validá-lo.");
        return;
      }

      if (await formsValidos()) {
        await verificarEAtualizarDataEmissao();
        callValidar();
      }
    }, [
      callValidar,
      formsValidos,
      idRegistroEmEdicao,
      verificarEAtualizarDataEmissao,
    ]);

    const onClickAutorizar = useCallback(async () => {
      try {
        const permissao =
          ambienteMdfe == AmbienteMDFeOpcoes.Homologacao
            ? PermissoesMDF.EnviarEmHomologacao
            : PermissoesMDF.EnviarEmProducao;

        const possuiPermissao = verificaComNotificacaoSeUsuarioPossuiPermissoes(
          [permissao]
        );

        if (!possuiPermissao) {
          return;
        }

        const resposta = await MDFeServico.autorizar(baseMdfe.id);
        checarResponse(resposta);
        if (resposta.sucesso) {
          if (resposta.model.rejeicao) {
            exibirNotificacaoToast({
              mensagem: resposta.model.rejeicao,
              tipo: TipoNotificacao.Erro,
            });
          }
          carregar(resposta.model.documento);
        }
      } catch (erro) {
        tratarErroApi(erro, callBackUnprocessableEntity);
      }
    }, [baseMdfe, ambienteMdfe, carregar, callBackUnprocessableEntity]);

    const onClickEncerrar = useCallback(() => {
      if (
        verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesMDF.Encerrar,
        ])
      ) {
        setModalEncerramentoVisivel(true);
      }
    }, [setModalEncerramentoVisivel]);

    const onClickCancelarMDFe = useCallback(() => {
      if (
        verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesMDF.Cancelar,
        ])
      ) {
        setModalCancelamentoVisivel(true);
      }
    }, [setModalCancelamentoVisivel]);

    const onClickVisualizarPDF = useCallback(async () => {
      await visualizarImpressaoPdfDamdfe(
        baseMdfe.id,
        baseMdfe.situacao,
        setModalPdfViewerVisivel,
        setDocumentoPdfDamdfe
      );
    }, [baseMdfe, setModalPdfViewerVisivel, setDocumentoPdfDamdfe]);

    const handlePdfViewerModalCallBack = useCallback(() => {
      setModalPdfViewerVisivel(false);
      setDocumentoPdfDamdfe("");
    }, [setModalPdfViewerVisivel, setDocumentoPdfDamdfe]);

    const onClickBaixarXML = useCallback(async () => {
      await baixarXmlMdfe(baseMdfe.id, baseMdfe.situacao);
    }, [baseMdfe]);

    const onClickDesfazerValidacao = useCallback(async () => {
      if (
        baseMdfe.situacao != SituacaoMDFe.Validado &&
        baseMdfe.situacao != SituacaoMDFe.Rejeitado
      ) {
        exibirAlerta(
          "Atenção",
          `Só é possível desfazer validação de MDF-e na situação "${
            situacaoMDFeDecodificada[SituacaoMDFe.Validado]
          }" ou "${situacaoMDFeDecodificada[SituacaoMDFe.Rejeitado]}".`
        );

        return;
      }
      const confirmacao = await exibirConfirmacao(
        "Desfazer validação",
        "Deseja desfazer a validação do MDF-e?"
      );

      if (!confirmacao) {
        return;
      }

      try {
        const resposta = await MDFeServico.desfazerValidacao(baseMdfe.id);
        checarResponse(resposta);
        if (resposta.sucesso) {
          carregar(resposta.model);
        }
      } catch (erro) {
        tratarErroApi(erro, callBackUnprocessableEntity);
      }
    }, [baseMdfe, carregar, callBackUnprocessableEntity]);

    const onClickConsultarSituacao = useCallback(async () => {
      try {
        const resposta = await MDFeServico.consultarSituacao(baseMdfe.id);
        checarResponse(resposta);
        if (resposta.sucesso) {
          carregar(resposta.model);

          exibirNotificaoToast({
            mensagem: resposta.mensagem,
            tipo: TipoNotificacao.Sucesso,
          });
        }
      } catch (erro) {
        tratarErroApi(erro, callBackUnprocessableEntity);
      }
    }, [baseMdfe, callBackUnprocessableEntity, carregar]);

    const handleModalEncerramentoCallBack = useCallback(() => {
      setModalEncerramentoVisivel(false);
    }, [setModalEncerramentoVisivel]);

    const handleModalCancelamentoCallBack = useCallback(() => {
      setModalCancelamentoVisivel(false);
    }, [setModalCancelamentoVisivel]);

    const quadroVisivel = ambienteMdfe == AmbienteMDFeOpcoes.Homologacao;

    return (
      <>
        <ContainerFormMDFe data-testid="edit-form-mdfe">
          <ProvedorAjuda id="edit-form-mdfe">
            <CabecadoMDFe>
              <InfosCabecalhoMDFe
                situacao={baseMdfe.situacao}
                serie={dadosGerais.serie}
                numero={dadosGerais.numero}
                motivoRejeicao={baseMdfe.motivoRejeicao}
                chaveAcesso={baseMdfe.chaveAcesso}
              />
            </CabecadoMDFe>
            <ContainerSemFormMxp>
              <TabPanel
                deferRendering={false}
                ref={tabPanelRef}
                showNavButtons
                swipeEnabled={false}
                itemTitleRender={rederizarTitulo}
              >
                <Item text="Dados gerais">
                  <MDFeAbaDadosGerais
                    ref={refDadosGerais}
                    abaSomenteLeitura={telaSomenteLeitura}
                  />
                </Item>
                <Item text="Documentos fiscais">
                  <ComponentAsyncLoader>
                    <MDFeAbaDocumentosFiscais
                      ref={refDocumentosFiscais}
                      abaSomenteLeitura={telaSomenteLeitura}
                    />
                  </ComponentAsyncLoader>
                </Item>
                <Item text="Veículos">
                  <MDFeAbaDadosVeiculos
                    ref={refVeiculos}
                    abaSomenteLeitura={telaSomenteLeitura}
                  />
                </Item>
                <Item text="Percurso">
                  <MDFeAbaPercurso
                    ref={refPercursos}
                    somenteLeitura={telaSomenteLeitura}
                    idRegistroEmEdicao={idRegistroEmEdicao}
                    isModal
                  />
                </Item>
                <Item text="Condutores">
                  <ComponentAsyncLoader>
                    <MDFeAbaCondutor
                      ref={refCondutores}
                      somenteLeitura={telaSomenteLeitura}
                      isModal
                      style={{
                        minHeight: "18em",
                        maxHeight: "calc(100vh - 30em)",
                      }}
                    />
                  </ComponentAsyncLoader>
                </Item>
                <Item text="Seguros">
                  <ComponentAsyncLoader>
                    <MDFeAbaSeguro
                      ref={refSeguros}
                      somenteLeitura={telaSomenteLeitura}
                    />
                  </ComponentAsyncLoader>
                </Item>
                <Item text="Observações">
                  <TabContainer>
                    <MDFeAbaObservacoes
                      ref={refObservacoes}
                      abaSomenteLeitura={telaSomenteLeitura}
                    />
                  </TabContainer>
                </Item>
              </TabPanel>
            </ContainerSemFormMxp>
            {quadroVisivel && <QuadroAmbienteHomologacao />}
          </ProvedorAjuda>

          <ToolbarMxp dadosAuditoria={dadosAuditoria}>
            {visibilidadeBotoes?.salvar && (
              <BotaoSalvarMxp handleClick={onClickSalvar} />
            )}
            {visibilidadeBotoes?.cancelar && (
              <BotaoCancelarMxp handleClick={handleCancelar} />
            )}
            {visibilidadeBotoes?.consultarSituacao && (
              <BotaoConsultarSituacaoMxp
                handleClick={onClickConsultarSituacao}
              />
            )}
            {visibilidadeBotoes?.validar && idRegistroEmEdicao > 0 && (
              <BotaoValidarMxp handleClick={onClickValidar} />
            )}
            {visibilidadeBotoes?.autorizar && (
              <BotaoAutorizarMxp handleClick={onClickAutorizar} />
            )}
            {visibilidadeBotoes?.desfazerValidacao && (
              <BotaoDesfazerValidacaoMxp
                handleClick={onClickDesfazerValidacao}
              />
            )}
            {visibilidadeBotoes?.encerrar && (
              <BotaoEncerrarMxp handleClick={onClickEncerrar} />
            )}
            {visibilidadeBotoes?.cancelarMdfe && (
              <BotaoCancelarMDFeMxp handleClick={onClickCancelarMDFe} />
            )}
            {visibilidadeBotoes?.visualizarPDF && (
              <BotaoVisualizarPDFMxp handleClick={onClickVisualizarPDF} />
            )}
            {visibilidadeBotoes?.baixarXML && (
              <BotaoBaixarXmlMxp handleClick={onClickBaixarXML} />
            )}
          </ToolbarMxp>
        </ContainerFormMDFe>

        <div>
          <div>
            {/*Modal Encerramento*/}
            <EditFormEncerramentoMDFe
              visivel={modalEncerramentoVisivel}
              idRegistroEmEdicao={idRegistroEmEdicao}
              handleCallback={handleModalEncerramentoCallBack}
            />
          </div>

          <div>
            {/*Modal Cancelamento*/}
            <EditFormCancelamentoMDFe
              visivel={modalCancelamentoVisivel}
              idRegistroEmEdicao={idRegistroEmEdicao}
              handleCallback={handleModalCancelamentoCallBack}
            />
          </div>

          <div>
            {/*Modal*/}
            <Modal
              titulo={"Visualizar PDF"}
              visivel={modalPdfViewerVisivel}
              largura={1366}
              altura={768}
              onFechar={handlePdfViewerModalCallBack}
            >
              <PdfViewer documento={documentoPdfDamdfe} />
            </Modal>
          </div>
        </div>
      </>
    );
  }
);

export default EditFormMDFe;
