import AuditavelDTO from "../../../../models/api/comum/auditavel-dto";
import AuditavelGridModel from "../../../../models/api/comum/auditavel-grid";
import { ErroApi } from "../../../../models/api/comum/erros";
import { EstoqueGridModelSelecionar } from "../../../../models/api/estoque/estoque";
import SelectItem from "../../../../models/shared/ui/select-item";
import { IOperacaoEmMassaResponse } from "../../../comum/utils/accordions/gerador-de-mensagens";
import { EstoqueMovimentacaoSelecionarGridModel } from "../../../estoque/estoque-movimentacao/models/estoque-movimentacao";
import {
  InsumoEstado,
  InsumoTipoBaixa,
  OrigemInsumo,
  TipoDoEstoqueInsumo,
} from "../utils/enum/insumo-da-ordem-de-producao-enums";

export interface InsumoDaOrdemDeProducaoGridModelBase {
  id: number;
  estado: InsumoEstado;
  codigo: string;
  descricao: string | null;
  operacao: string;
  ordemDeProducaoId: number;
  quantidadeSemPercentualDePerda: number;
  perdaEmPercentual: number;
  quantidadeTotal: number;
  quantidadeBaixada: number;
  quantidadeABaixar: number;
  loteDoFabricanteOuNumeroDeSerieId?: number;
  possuiRastreabilidade: boolean;
  itemId: number;
  loteInterno: string;
  possuiMaisDeUmEstoqueComLoteInterno: boolean;
  loteDoFabricante: string;
  possuiMaisDeUmEstoqueComLoteDofabricante: boolean;
  enderecoDeEstoque: string;
  possuiMaisDeUmEstoqueComEnderecoDeEstoque: boolean;
  estoqueDisponivel: number;
  estoqueDisponivelExplosao: number;
}

export function UsaRastreabilidadeMasNaoSelecionouNS(
  dados: InsumoDaOrdemDeProducaoGridModelBase | undefined
) {
  if (!dados) return false;

  return (
    dados.possuiRastreabilidade && !dados.loteDoFabricanteOuNumeroDeSerieId
  );
}

export interface InsumoPorNumeroDeSerieGridModel
  extends InsumoDaOrdemDeProducaoGridModelBase {
  loteDoFabricanteOuNumeroDeSerie: string;
}

export interface InsumoDaOrdemDeProducaoGridModel
  extends InsumoDaOrdemDeProducaoGridModelBase,
    AuditavelGridModel {
  estadoDecodificado: string;
  ordemDeProducao: string;
  ordemDeProducaoEstado: string;
  descricaoComplementar: string | null;
  unidade: string;
  quantidadeParaUmaUnidade: number;
  quantidadeReservada: number;
  tipoDeBaixa: InsumoTipoBaixa;
  fornecidoPeloClienteEmProducaoParaTerceiro: boolean;
  tipoDoEstoque: string;
  isProcedenciaAlternativo: boolean;
  quantidadeASelecionarInsumoAlternativo: number | null;
  insumoPaiAlternativo: string | null;
}

export const ObterDescricaoInsumo = (
  insumo?: InsumoDaOrdemDeProducaoGridModel
) => {
  if (!insumo) {
    return "(insumo não encontrado)";
  }

  return `${insumo.codigo} (${insumo.descricao}) - ${insumo.operacao}`;
};

export interface InsumoDaOrdemDeProducaoRequest {
  id: number;
  ordemDeProducaoId: number;
  operacaoId: number;
  itemId: number;
  quantidadeLiquida: number;
  observacoes: string | null;
  origem: OrigemInsumo;
  ordem: number;
  perdaEmPercentual: number;
  estoqueId: number | null;
  numeroDeSerieId: number | null;
  tipoDoEstoque: TipoDoEstoqueInsumo | null;
  fornecidoPeloClienteEmProducaoParaTerceiro: boolean;
  baixarEstoque: boolean;
}

export const InsumosTipoEstoque: SelectItem[] = [
  { valor: TipoDoEstoqueInsumo.ConsumirEstoque, descricao: "Consumir estoque" },
  {
    valor: TipoDoEstoqueInsumo.ManterInsumo,
    descricao: "Manter insumo consumido em estoque separado",
  },
];

export interface InsumoDaOrdemDeProducaoBaixarResponse {
  quantidadeBaixada: number;
  estoquesParaBaixar: EstoqueGridModelSelecionar[];
}
export interface InsumoDaOrdemDeProducaoResponseDTO extends AuditavelDTO {
  id: number;
  ordemDeProducaoId: number;
  operacaoId: number;
  itemId: number;
  quantidadeLiquida: number;
  observacoes: string | null;
  origem: OrigemInsumo;
  ordem: number;
  perdaEmPercentual: number;
  estoqueId: number | null;
  numeroDeSerieId: number | null;
  tipoDoEstoque: TipoDoEstoqueInsumo | null;
  fornecidoPeloClienteEmProducaoParaTerceiro: boolean;
  baixarEstoque: boolean;
}

export interface EstornarBaixasDoInsumoResponse {
  sucesso: boolean;
  mensagem: string;
  erros: ErroApi[];
  precisaSelecionarMovs: boolean;
  movimentacoes: EstoqueMovimentacaoSelecionarGridModel[];
}

export interface InsumosEstornarEmMassaResponse {
  id: number;
  item: string;
  listaDeErros: ErroApi[];
  valido: boolean;
}

export interface DesfazerConclusaoDeBaixaDosInsumosResponse {
  id: number;
  item: string;
  listaDeErros: ErroApi[];
  valido: boolean;
}

export interface DadosModalSelecaoInsumosFilhosDoInsumoAlternativo {
  insumoPaiId: number;
  quantidadeTotalInsumoPai: number;
}

export interface DadosModalEstornoDeInsumos {
  descricaoInsumo: string;
  quantidadeAEstornar: number;
  tipoDeEstorno: TipoDeEstornoInsumo;
}

export enum TipoDeEstornoInsumo {
  Quantidade = 1,
  DesfazerConclusaoSemEstornarBaixas = 2,
}

export const TipoDeEstornoInsumoSelectItems: SelectItem[] = [
  { valor: TipoDeEstornoInsumo.Quantidade, descricao: "Quantidade a estornar" },
  {
    valor: TipoDeEstornoInsumo.DesfazerConclusaoSemEstornarBaixas,
    descricao: "Desfazer conclusão, sem estornar as baixas",
  },
];

export class InsumoAlternativoSelecionado {
  itemId: number;
  quantidade: number;
  proporcaoDoItemAlternativo: number;

  constructor(
    itemId: number,
    quantidade: number,
    proporcaoDoItemAlternativo: number
  ) {
    this.itemId = itemId;
    this.quantidade = quantidade;
    this.proporcaoDoItemAlternativo = proporcaoDoItemAlternativo;
  }
}

export interface BaixarInsumosEmMassaRequest {
  InsumoId: number;
  QuantidadeABaixar: number;
  InsumoItemId: number;
}

export interface BaixarInsumosEmMassaResponse extends IOperacaoEmMassaResponse {
  insumoId: number;
  insumoItemId: number;
  quantidadeABaixar: number;
  quantidadeBaixada: number;
  itemCodigo?: string;
  itemDescricao?: string;
}
