import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import BotaoCancelarMxp from "../../../../../components/botoes/botao-cancelar-mxp";
import BotaoSalvarMxp from "../../../../../components/botoes/botao-salvar-mxp";
import {
  FormCheckBox,
  FormDateBox,
  FormGrupo,
  FormSelectBox,
  FormTextBox,
} from "../../../../../components/formularios";
import FormMxp from "../../../../../components/layout/form";
import { ContainerFormMxp } from "../../../../../components/layout/form/styles";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import ToolbarMxp from "../../../../../components/layout/toolbar-mxp";
import AuditavelDTO from "../../../../../models/api/comum/auditavel-dto";
import { TipoTributoContaPadraoParteB } from "../../../../../models/api/conta-padrao-parte-b/conta-padrao-parte-b";
import { PermissoesContaParteB } from "../../../../../models/permissoes/contabilidade/conta-parte-b/permissoes-conta-parte-b";
import {
  IFormularioEditavelBase,
  ResultadoAcaoFormulario,
} from "../../../../../models/shared/ui/formularios";
import SelectItem from "../../../../../models/shared/ui/select-item";
import { ContaPadraoParteBServico } from "../../../../../services/conta-padrao-parte-b/conta-padrao-parte-b.service";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import yup from "../../../../../utils/validacao/validacao";
import {
  ContaDaParteBRequest,
  ContaDaParteBResponse,
} from "../../models/conta-parte-b";
import ContaParteBConstantes from "../../models/conta-parte-b.constantes";
import {
  SelectItemEnumTipoTributoContaParteB,
  TipoTributoContaParteB,
} from "../../models/conta-parte-b.enums";
import { ContaParteBServico } from "../../servicos/conta-parte-b.service";

const novoRegistro: ContaDaParteBRequest = {
  id: 0,
  codigo: "",
  descricao: "",
  ativo: true,
  tipoTributo: TipoTributoContaParteB.IRPJ,
  contaPadraoDaParteBId: 0,
  dataCriacaoDaConta: undefined,
};

let dadosAuditoria: AuditavelDTO | undefined = undefined;

export default function EditFormContaParteB(props: IFormularioEditavelBase) {
  const [contasPadroesParteB, setContasPadroesParteB] = useState<SelectItem[]>(
    []
  );

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    codigo: yup.string().required().max(ContaParteBConstantes.Codigo.Maximo),
    descricao: yup
      .string()
      .required()
      .max(ContaParteBConstantes.Descricao.Maximo),
    tipoTributo: yup
      .mixed<TipoTributoContaParteB>()
      .transform((v) => (v ? v : null))
      .oneOf(
        Object.values(TipoTributoContaParteB).map((x) => x as number),
        "Valor inválido"
      )
      .required(),
    dataCriacaoDaConta: yup
      .date()
      .required()
      .transform((valor) => (valor ? valor : null)),
    contaPadraoDaParteBId: yup
      .number()
      .required()
      .positive()
      .integer()
      .transform((v) => (v ? v : null))
      .oneOf(
        contasPadroesParteB.map((x) => x.valor as number),
        "Valor inválido"
      ),
    ativo: yup.boolean().required(),
  });

  const { register, control, handleSubmit, getValues, reset, watch } =
    useForm<ContaDaParteBRequest>({
      resolver: yupResolver(schema),
    });

  //Hook usado para carregar os dados da tela
  useEffect(() => {
    if (Number.isNaN(props.idRegistroEmEdicao)) {
      return;
    }

    preencherTela();
  }, [props.idRegistroEmEdicao]);

  async function preencherTela() {
    if (props.idRegistroEmEdicao == 0) {
      limparTela();
    } else if (props.idRegistroEmEdicao > 0) {
      await carregarRegistroDoId();
    }

    carregarCombo();
  }

  async function carregarRegistroDoId() {
    try {
      const resposta =
        await ContaParteBServico.ObterPorIdComDadosAuditoria<ContaDaParteBResponse>(
          props.idRegistroEmEdicao
        );
      checarResponse(resposta);
      dadosAuditoria = resposta.model;
      reset(resposta.model);
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  async function carregarCombo() {
    await carregarComboContaPadraoDaParteB(watch("tipoTributo"));
  }

  async function carregarComboContaPadraoDaParteB(
    tipoTributo: TipoTributoContaParteB
  ) {
    const tiposConsulta: TipoTributoContaPadraoParteB[] = [
      TipoTributoContaPadraoParteB.Ambos,
    ];

    tiposConsulta.push(
      tipoTributo == TipoTributoContaParteB.CSLL
        ? TipoTributoContaPadraoParteB.CSLL
        : TipoTributoContaPadraoParteB.IRPJ
    );

    try {
      const resposta = await ContaPadraoParteBServico.ObterListaSimplesDosTipos(
        tiposConsulta
      );
      checarResponse(resposta);
      setContasPadroesParteB(
        resposta.model.map((x) => ({
          valor: x.valor,
          descricao: x.descricao,
        }))
      );
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  function limparTela() {
    dadosAuditoria = undefined;
    reset(novoRegistro);
  }

  async function handleSalvar() {
    try {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesContaParteB.InserirEditar,
        ])
      ) {
        return;
      }
      const model = getValues();
      const resposta =
        props.idRegistroEmEdicao > 0
          ? await ContaParteBServico.Atualizar(model)
          : await ContaParteBServico.Inserir(model);

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar(ResultadoAcaoFormulario.AcaoConcluida);
      }
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  async function onChangeTipoTributo(e: TipoTributoContaParteB) {
    await carregarComboContaPadraoDaParteB(e);
  }

  function handleCancelar() {
    fechar(ResultadoAcaoFormulario.AcaoCancelada);
  }

  function fechar(resultado: ResultadoAcaoFormulario) {
    limparTela();
    props.handleCallback(resultado);
  }

  return (
    <>
      <ContainerFormMxp>
        <FormMxp>
          <ProvedorAjuda id="edit-form-conta-parte-b">
            <input type="hidden" {...register("id")} defaultValue={0} />
            <FormGrupo>
              <Linha>
                <Coluna md={3}>
                  <FormTextBox
                    name="codigo"
                    titulo="Código"
                    control={control}
                    requerido
                    tamanhoMaximo={ContaParteBConstantes.Codigo.Maximo}
                  />
                </Coluna>
                <Coluna md={3}>
                  <FormSelectBox
                    name="tipoTributo"
                    titulo="Tipo do tributo"
                    control={control}
                    requerido
                    dataSource={SelectItemEnumTipoTributoContaParteB}
                    onValueChange={onChangeTipoTributo}
                  />
                </Coluna>
                <Coluna md={6} classe="float-right">
                  <FormCheckBox name="ativo" titulo="Ativo" control={control} />
                </Coluna>
              </Linha>
              <Linha>
                <Coluna md={12}>
                  <FormTextBox
                    name="descricao"
                    titulo="Descrição"
                    control={control}
                    requerido
                    tamanhoMaximo={ContaParteBConstantes.Descricao.Maximo}
                  />
                </Coluna>
              </Linha>
              <Linha>
                <Coluna md={6}>
                  <FormDateBox
                    name="dataCriacaoDaConta"
                    titulo="Data de criação"
                    control={control}
                    requerido
                    aceitaValorCustomizado
                    aceitaDigitacaoComMascara
                  />
                </Coluna>
              </Linha>
              <Linha>
                <Coluna md={12}>
                  <FormSelectBox
                    name="contaPadraoDaParteBId"
                    titulo="Código padrão da parte B"
                    control={control}
                    requerido
                    dataSource={contasPadroesParteB}
                    habilitaBusca
                    tipoBusca="contains"
                  />
                </Coluna>
              </Linha>
            </FormGrupo>
          </ProvedorAjuda>
        </FormMxp>
        <ToolbarMxp dadosAuditoria={dadosAuditoria}>
          <BotaoSalvarMxp handleClick={handleSubmit(handleSalvar)} />
          <BotaoCancelarMxp handleClick={handleCancelar} />
        </ToolbarMxp>
      </ContainerFormMxp>
    </>
  );
}
