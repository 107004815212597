import DataSource from "devextreme/data/data_source";
import {
  assertConfiguracaoExibicaoEBuscaType,
  ConfiguracaoExibicaoEBusca,
} from "../../../../components/formularios/selectbox-lazy-mxp";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";
import { EstadoConfiguravelDaOrdemDeProducaoGridModel } from "../models/estado-configuravel-da-ordem-de-producao";

class EstadoConfiguravelDaOrdemDeProducaoService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.EstadoConfiguravelDaOrdemDeProducao);
  }

  public GetDadosSelectBox(): [
    DataSource<EstadoConfiguravelDaOrdemDeProducaoGridModel, any>,
    ConfiguracaoExibicaoEBusca<EstadoConfiguravelDaOrdemDeProducaoGridModel>
  ] {
    const dataSource =
      this.GetDataSourceSelectBoxLazyMxp<EstadoConfiguravelDaOrdemDeProducaoGridModel>(
        {
          camposRetorno: ["id", "descricao"],
          camposOrdenacao: [
            {
              nomeCampo: "ordem",
              desc: false,
            },
          ],
          filtros: [{ nomeCampo: "ativo", operador: "=", valor: true }],
        }
      );

    const configs =
      assertConfiguracaoExibicaoEBuscaType<EstadoConfiguravelDaOrdemDeProducaoGridModel>(
        {
          expressaoDeBusca: ["descricao"],
          nomeCampoChave: "id",
          nomeCampoExibicao: (c) => {
            if (c) {
              return c.descricao ?? "";
            }

            return "";
          },
        }
      );

    return [dataSource, configs];
  }
}

export const EstadoConfiguravelDaOrdemDeProducaoServico =
  new EstadoConfiguravelDaOrdemDeProducaoService();
