import getApi from "../../../../configs/api";
import {
  ResponseBase,
  ResponseModel,
} from "../../../../models/api/comum/response-base";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import DataSourceFactory from "../../../../utils/grid/data-source-factory";
import { UsuariosLogadosGrid } from "../../usuarios-logados/models/usuarios-logados";
import {
  RecuperarSenhaViaEmailRequest,
  VerificacaoLinkRecuperacaoSenhaRequest,
} from "../models/login.api";

class ControleAcessoService {
  private _nomeEndpoint: NomesEndpoints;

  constructor() {
    this._nomeEndpoint = NomesEndpoints.ControleDeAcesso;
  }

  public async enviarEmailRecuperacaoSenha(usuarioEmail: string) {
    const api = getApi();
    const response = await api.post<ResponseBase>(
      `${this._nomeEndpoint}/EnviarEmailRecuperacaoSenha?email=${usuarioEmail}`
    );

    return response.data;
  }

  public async configurarMfa(usuarioEmail: string) {
    const api = getApi();
    const response = await api.post<ResponseBase>(
      `${this._nomeEndpoint}/ConfigurarMFA?email=${usuarioEmail}`
    );

    return response.data;
  }

  public async verificarSeHashDeRecuperacaoDeSenhaEstaValido(
    request: VerificacaoLinkRecuperacaoSenhaRequest
  ) {
    const api = getApi();

    const response = await api.post<ResponseModel<boolean>>(
      `${this._nomeEndpoint}/VerificarHashEmailRecuperacaoSenha`,
      request
    );
    return response.data;
  }

  public async recuperarSenha(request: RecuperarSenhaViaEmailRequest) {
    const api = getApi();
    const response = await api.post<ResponseBase>(
      `${this._nomeEndpoint}/RecuperarSenha`,
      request
    );
    return response.data;
  }

  public obterDataSourceUsuariosLogadoGrid() {
    return DataSourceFactory.CriarParaGrid<UsuariosLogadosGrid>(
      `${this._nomeEndpoint}/UsuariosLogadosGrid`
    );
  }

  public async forcarLogoff(idUsuario: number) {
    const api = getApi();

    const response = await api.post<ResponseBase>(
      `${this._nomeEndpoint}/ForcarLogoff?idUsuario=${idUsuario}`
    );
    return response.data;
  }
}

export const ControleAcessoServico = new ControleAcessoService();
