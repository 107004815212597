import DataGrid, { Column, DataGridRef } from "devextreme-react/data-grid";
import ArrayStore from "devextreme/data/array_store";
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import GridColunaAcoes from "../../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import TituloGrid from "../../../../../../components/texto/titulo-grid";
import { useRegistrarAtalhosGrid } from "../../../../../../hooks/atalhos.hooks";
import { GridAverbacoesViewModel } from "../../../../../../models/viewmodels/vendas/mdfe/mdfe-edit-form-view-model";
import exibirNotificacaoToast, {
  MensagensParaNotificacao,
  TipoNotificacao,
} from "../../../../../../utils/common/notificacoes-utils";
import { exibirAlerta } from "../../../../../../utils/dialogos";
import GridBuilder from "../../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../../utils/grid/grid-controller";
import { GridProps } from "../../../../../../utils/grid/grid-utils";
import obterConfiguracaoColuna from "../../../../../../utils/grid/padroes-colunas";
import MDFeEditFormContext from "../../../contexts/mdfe-editform.context";
import { SituacaoMDFe } from "../../../models/mdfe-enums";
import MDFeConstantes from "../../../models/mdfe.constantes";
import ModalAdicionarAverbacao from "./modal-adicionar-averbacao";

const gridId = "mdfe-lista-averbacao";
export function GridAverbacoes(props: GridProps) {
  const { baseMdfe, averbacoes, removerAverbacao } =
    useContext(MDFeEditFormContext);
  const [modalAddVisivel, setModalAddVisivel] = useState(false);

  const model = useMemo(() => {
    return averbacoes.map((p) => {
      return {
        id: p.id,
        numeroAverbacao: p.numero,
      } as GridAverbacoesViewModel;
    });
  }, [averbacoes]);

  const dataSource = useMemo(
    () => new ArrayStore({ data: model, key: "ufId" }),
    [model]
  );

  const novoRegistroAverbacao = useCallback(() => {
    if (props.somenteLeitura) {
      return;
    }

    if (averbacoes.length >= MDFeConstantes.QuantidadeMaximaAverbacoes) {
      exibirAlerta(
        "Atenção",
        `Não é possível adicionar mais averbações, pois o limite é de ${MDFeConstantes.QuantidadeMaximaAverbacoes} registros.`
      );
      return;
    }

    setModalAddVisivel(true);
  }, [setModalAddVisivel, props.somenteLeitura, averbacoes]);

  const excluirRegistro = useCallback(
    (averbacao: GridAverbacoesViewModel) => {
      if (averbacao) {
        removerAverbacao({
          id: averbacao.id,
          numero: averbacao.numeroAverbacao,
        });

        exibirNotificacaoToast({
          mensagem: MensagensParaNotificacao.ExcluidoComSucesso,
          tipo: TipoNotificacao.Sucesso,
        });
      }
    },
    [removerAverbacao]
  );

  const gridRef = useRef<DataGridRef>(null);

  const gridController = new GridController<GridAverbacoesViewModel>(() =>
    gridRef.current?.instance()
  );

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  useRegistrarAtalhosGrid({
    controller: gridController,
    handleNovo: novoRegistroAverbacao,
    handleExcluir: excluirRegistro,
  });

  const configuracoesGrid = useMemo(() => {
    let builder = GridBuilder.criar(gridId, () => gridRef?.current?.instance())
      .definirDataSource(dataSource)
      .definirRolagem()
      .definirGravacaoPreferenciasGrid()
      .configurarExportacao("Averbações")
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirOrdenacao();

    if (!props.somenteLeitura) {
      builder = builder.definirBotaoNovo(novoRegistroAverbacao);
    }

    return builder.build();
  }, [
    dataSource,
    handleAtualizarGrid,
    novoRegistroAverbacao,
    props.somenteLeitura,
  ]);

  return (
    <>
      <TituloGrid texto="Averbações" />
      <DataGrid ref={gridRef} {...configuracoesGrid}>
        {baseMdfe.situacao == SituacaoMDFe.EmEdicao &&
          GridColunaAcoes<GridAverbacoesViewModel>({
            handleExcluir: excluirRegistro,
          })}
        <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
        <Column
          dataField="numeroAverbacao"
          caption="Número da averbação"
          {...obterConfiguracaoColuna("stringGG")}
          allowResizing={true}
          allowReordering={false}
        />
      </DataGrid>

      <div>
        <ModalAdicionarAverbacao
          idRegistroEmEdicao={baseMdfe.id}
          visivel={modalAddVisivel}
          handleCallback={() => {
            setModalAddVisivel(false);
          }}
        />
      </div>
    </>
  );
}
