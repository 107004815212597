import { AccordionModel } from "../../../../../../components/dialogos/modal-accordion/modal-accordion";
import { ResponseModel } from "../../../../../../models/api/comum/response-base";
import { PermissoesReserva } from "../../../../../../models/permissoes/estoque/reserva/permissoes-reserva";
import { tratarErroApi } from "../../../../../../utils/api/api-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../../utils/common/permissoes-utils";
import { descapitalizarPrimeiraLetra } from "../../../../../../utils/strings/string-utils";
import {
  OrdemDeProducaoGridModel,
  ReservarOrdemDeProducaoComProjecoesDeUsoParaItemDoPedidoDeVendaResponse,
} from "../../../models/ordem-de-producao.api";
import { OrdemDeProducaoServico } from "../../../servicos/ordem-de-producao.service";

async function reservarOrdensDeProducaoComProjecoesDeUsoHandler(
  registros: OrdemDeProducaoGridModel[],
  handleAtualizarGrid: () => void
) {
  if (
    !verificaComNotificacaoSeUsuarioPossuiPermissoes([
      PermissoesReserva.CriarReservas,
    ])
  ) {
    return;
  }

  try {
    const response =
      await OrdemDeProducaoServico.ReservarOrdensDeProducaoComProjecoesDeUso(
        registros.map((i) => i.id)
      );

    handleAtualizarGrid();

    return handleResponse(response);
  } catch (erro) {
    tratarErroApi(erro);
  }
}

function handleResponse(
  response: ResponseModel<
    ReservarOrdemDeProducaoComProjecoesDeUsoParaItemDoPedidoDeVendaResponse[]
  >
) {
  if (response.sucesso) {
    const resultadosDaConfirmacao = response.model;
    const ordensDeProducaoAcaoSucesso = resultadosDaConfirmacao.filter(
      (x) => x.valido
    );
    const ordensDeProducaoAcaoSemSucesso = resultadosDaConfirmacao.filter(
      (x) => !x.valido
    );

    return handlerNotificacoes(
      ordensDeProducaoAcaoSucesso,
      ordensDeProducaoAcaoSemSucesso
    );
  }
}

function handlerNotificacoes(
  ordensDeProducaoAcaoSucesso: ReservarOrdemDeProducaoComProjecoesDeUsoParaItemDoPedidoDeVendaResponse[],
  ordensDeProducaoAcaoSemSucesso: ReservarOrdemDeProducaoComProjecoesDeUsoParaItemDoPedidoDeVendaResponse[]
) {
  const mensagem = formatarMensagensAccordion(
    ordensDeProducaoAcaoSucesso,
    ordensDeProducaoAcaoSemSucesso
  );

  return {
    accordionVisivel: true,
    mensagem: mensagem,
  };
}

export function formatarMensagensAccordion(
  entidadesAcaoSucesso: ReservarOrdemDeProducaoComProjecoesDeUsoParaItemDoPedidoDeVendaResponse[],
  entidadesAcaoSemSucesso: ReservarOrdemDeProducaoComProjecoesDeUsoParaItemDoPedidoDeVendaResponse[]
): AccordionModel[] {
  const accordionModel: AccordionModel[] = [];

  if (entidadesAcaoSucesso.length > 0) {
    let mensagem = "";

    if (entidadesAcaoSucesso.length == 1) {
      mensagem = `${entidadesAcaoSucesso.length} ordem de produção reservada com sucesso. Clique para ver mais:`;
    } else {
      mensagem = `${entidadesAcaoSucesso.length} ordens de produção reservadas com sucesso. Clique para ver mais:`;
    }

    accordionModel.push({
      grupoDeMensagensTitulo: mensagem,
      mensagens: entidadesAcaoSucesso.map((x) => {
        return `Ordem de produção ${descapitalizarPrimeiraLetra(
          x.ordemDeProducaoNumero
        )} - ${x.itemCodigo} (${
          x.itemDescricao
        }) reservada para pedido de venda ${x.numeroDoPedidoDeVenda} #${
          x.posicaoDoItemDoPedidoDeVenda
        }.`;
      }),
    });
  }

  if (entidadesAcaoSemSucesso.length > 0) {
    let mensagem = "";

    if (entidadesAcaoSemSucesso.length == 1) {
      mensagem = `${entidadesAcaoSemSucesso.length} ordem de produção não foi reservada. Clique para ver mais:`;
    } else {
      mensagem = `${entidadesAcaoSemSucesso.length} ordens de produção não foram reservadas. Clique para ver mais:`;
    }

    accordionModel.push({
      grupoDeMensagensTitulo: mensagem,
      mensagens: entidadesAcaoSemSucesso.map((x) => {
        return `Ordem de produção ${descapitalizarPrimeiraLetra(
          x.ordemDeProducaoNumero
        )}: ${x.listaDeErros.map((y) => y.mensagem).join(" \n")}`;
      }),
    });
  }

  return accordionModel;
}

export { reservarOrdensDeProducaoComProjecoesDeUsoHandler };
