import { Button } from "devextreme-react";

interface BotaoConsultarSituacaoMxpProps {
  handleClick: () => void;
}

export default function BotaoConsultarSituacaoMxp(
  props: BotaoConsultarSituacaoMxpProps
) {
  return (
    <Button
      type="normal"
      text="Consultar situação"
      icon="refresh"
      onClick={props.handleClick}
    />
  );
}
