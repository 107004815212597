import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";

class CfopService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.Cfop);
  }
}

export const CfopServico = new CfopService();
