import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";

class ApuracaoDoCiapService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.ApuracaoDoCiap);
  }
}

export const ApuracaoDoCiapServico = new ApuracaoDoCiapService();
