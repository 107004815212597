import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";

class AdicaoExclusaoCompensacaoApuracaoLacsCsllService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.AdicaoExclusaoCompensacaoApuracaoLacsCsll);
  }
}

export const AdicaoExclusaoCompensacaoApuracaoLacsCsllServico =
  new AdicaoExclusaoCompensacaoApuracaoLacsCsllService();
