import getApi from "../../configs/api";
import { Notificacao } from "../../models/api/notificacoes/notificacao";

const nomeEndpoint: string = "Notificacoes";

async function obterNotificacoes(empresaId: number, dataUltimaConsulta: Date) {
  const api = getApi(undefined, false);

  const dataLocal = new Date(
    dataUltimaConsulta.getTime() -
      dataUltimaConsulta.getTimezoneOffset() * 60000
  );
  const response = await api.get<Notificacao[]>(`${nomeEndpoint}`, {
    params: {
      empresaId: empresaId,
      dataUltimaConsulta: dataLocal.toISOString(),
    },
  });

  return response.data;
}

export default {
  obterNotificacoes,
};
