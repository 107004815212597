import { PermissoesOrdemDeProducao } from "../../../../../../models/permissoes/producao/ordem-de-producao/OrdemDeProducaoPermissoes";
import {
  checarResponseExibeMensagemExclusaoDeSucesso,
  tratarErroApi,
} from "../../../../../../utils/api/api-utils";
import { GeradorMensagensNotificacao } from "../../../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../../utils/common/permissoes-utils";
import { exibirConfirmacao } from "../../../../../../utils/dialogos";
import { renderToStringClient } from "../../../../../../utils/react/react-utils";
import { OrdemDeProducaoGridModel } from "../../../models/ordem-de-producao.api";
import { OrdemDeProducaoServico } from "../../../servicos/ordem-de-producao.service";
import {
  AcoesOrdensDeProducao,
  handlerAcaoOrdensDeProducaoResponse,
} from "./acoes-ordem-de-producao-helper";

async function excluirOrdensDeProducaoHandler(
  registros: OrdemDeProducaoGridModel[],
  handleAtualizarGrid: () => void
) {
  if (
    !verificaComNotificacaoSeUsuarioPossuiPermissoes([
      PermissoesOrdemDeProducao.Excluir,
    ])
  ) {
    return;
  }

  const mensagemDeConfirmacao =
    registros.length == 1
      ? obterMensagemExclusaoParaRegistroUnico(registros[0])
      : obterMensagemExclusaoParaMultiplosRegistros();

  const excluir = await exibirConfirmacao(
    "Confirmar exclusão",
    mensagemDeConfirmacao
  );

  if (!excluir) {
    return;
  }

  return registros.length == 1
    ? excluirOrdemDeProducao(registros[0].id, handleAtualizarGrid)
    : excluirOrdensDeProducao(registros, handleAtualizarGrid);
}

async function excluirOrdemDeProducao(
  ordemDeProducaoId: number,
  handleAtualizarGrid: () => void
) {
  try {
    const resposta = await OrdemDeProducaoServico.Excluir(ordemDeProducaoId);

    if (resposta) {
      checarResponseExibeMensagemExclusaoDeSucesso(
        resposta,
        GeradorMensagensNotificacao.ExcluidoComSucessoFeminino(
          "Ordem de produção"
        )
      );
      handleAtualizarGrid();
    }
  } catch (erro) {
    tratarErroApi(erro);
  }
}

async function excluirOrdensDeProducao(
  registros: OrdemDeProducaoGridModel[],
  handleAtualizarGrid: () => void
) {
  try {
    const resposta = await OrdemDeProducaoServico.ExcluirOrdensDeProducao(
      registros.map((x) => x.id)
    );

    handleAtualizarGrid();

    const acoes: AcoesOrdensDeProducao = {
      acao: "excluir",
      acaoPlural: "excluídas",
      acaoSingular: "excluída",
    };

    return handlerAcaoOrdensDeProducaoResponse(resposta, acoes);
  } catch (erro) {
    tratarErroApi(erro);
  }
}

function obterMensagemExclusaoParaRegistroUnico(
  registro: OrdemDeProducaoGridModel
) {
  const elementoHtmlDaMensagem = (
    <div>
      Tem certeza de que deseja excluir a ordem de produção{" "}
      {registro.numero ?? "sem número"}?
      <br />
      Ao excluir uma ordem de produção, os registros a seguir que forem
      vinculados a ela também serão excluídos: reservas, tarefas e anotações,
      números de série e anexos.
    </div>
  );

  return renderToStringClient(<>{elementoHtmlDaMensagem}</>);
}

function obterMensagemExclusaoParaMultiplosRegistros() {
  const elementoHtmlDaMensagem = (
    <div>
      Tem certeza de que deseja excluir as ordens de produção selecionadas?
      <br />
      Ao excluir uma ordem de produção, os registros a seguir que forem
      vinculados a ela também serão excluídos: reservas, tarefas e anotações,
      números de série e anexos.
    </div>
  );

  return renderToStringClient(<>{elementoHtmlDaMensagem}</>);
}

export { excluirOrdensDeProducaoHandler };
