import { Accordion } from "devextreme-react";
import { Fragment } from "react/jsx-runtime";
import { JanelasDeNotificacaoTitulos } from "../../../utils/common/notificacoes-utils";
import { Modal } from "../../layout/modal";
import { AccordionModel, AccordionModelDetalhado } from "./modal-accordion";
import { AccordionWrapper } from "./style";

/**
 * Propriedades dos modais do tipo accordion.
 *
 * @interface ModalAccordionProps
 * @member {boolean} modalAccordionVisivel Variável de controle utilizada para exibir ou esconder o modal.
 * @member {() => void} handlerFecharModalAccordion Função executada ao clicar no botão de fechar do modal.
 * @member {AccordionModel[] | undefined} dataSource Lista com os dados para serem exibidos no accordion.
 * @member {string?} modalTitulo Título do modal do accordion.
 * @member {string} accordionId String de identificação do elemento HTML do accordion.
 * @member {string} accordionGrupoTituloTamanhoFonte Tamanho da fonte do título dos itens do accordion que agrupam as mensagens.
 * @member {string} accordionGrupoTituloPesoFonte Peso da fonte do título dos itens do accordion que agrupam as mensagens.
 * @member {number | string} itemDoAccordionAltura? Altura dos itens do accordion que agrupam as mensagens.
 */
export interface ModalAccordionBaseProps {
  modalAccordionVisivel: boolean;
  handlerFecharModalAccordion: () => void;
  modalTitulo?: string;
  accordionId: string;
  tituloTamanhoFonte?: string;
  tituloPesoFonte?: string;
  itemDoAccordionAltura?: number | string;
}

export interface ModalAccordionProps extends ModalAccordionBaseProps {
  dataSource: AccordionModel[] | undefined;
}

export interface ModalAccordionDetalhadoProps extends ModalAccordionBaseProps {
  dataSource: AccordionModelDetalhado[] | undefined;
}

const customTitleRender = (
  item: AccordionModel,
  tituloTamanhoFonte: string | undefined,
  tituloPesoFonte: string | undefined
) => {
  return (
    <div
      style={{
        fontSize: tituloTamanhoFonte ?? "13px",
        fontWeight: tituloPesoFonte ?? "500",
      }}
    >
      {item.grupoDeMensagensTitulo}
    </div>
  );
};

const customItemRender = (item: AccordionModel) => {
  return item.mensagens.map((i, ix) => (
    <Fragment key={`p-${ix}`}>
      {item.conteudoHtml ? (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <p style={{ paddingRight: "5px" }}>&#x2022; </p>
          <span dangerouslySetInnerHTML={{ __html: i }} />
        </div>
      ) : (
        <p>&#x2022; {i}</p>
      )}
    </Fragment>
  ));
};

const customItemRenderDetalhado = (item: AccordionModelDetalhado) => {
  return item.mensagensDetalhadas.map((item, ix) => (
    <div key={`p-${ix}`}>
      <p>&#x2022; {item.mensagemDetalhadaTitulo}</p>
      {item.listaDeErrosTitulo !== undefined &&
        item.listaDeErrosTitulo !== "" &&
        item.mensagensDeErro !== undefined &&
        item.mensagensDeErro.length > 0 && (
          <p style={{ marginLeft: "20px" }}>
            <b>
              {item.listaDeErrosTitulo}
              {item.mensagensDeErro.length > 1 ? "s" : ""}:
            </b>{" "}
            {item.mensagensDeErro.map((erro, index) =>
              index === 0 ? `${erro} ` : `${erro} `
            )}
          </p>
        )}
    </div>
  ));
};

export default function ModalAccordion({
  modalAccordionVisivel,
  handlerFecharModalAccordion,
  dataSource,
  modalTitulo,
  accordionId,
  tituloTamanhoFonte,
  tituloPesoFonte,
  itemDoAccordionAltura,
}: ModalAccordionProps) {
  return (
    <Modal
      visivel={modalAccordionVisivel}
      arrastarAtivado={false}
      fecharAoClicarFora={false}
      titulo={modalTitulo ?? JanelasDeNotificacaoTitulos.Atencao}
      altura="auto"
      largura={"max(50vw, 750px)"}
      onFechar={() => handlerFecharModalAccordion()}
    >
      <AccordionWrapper itemDoAccordionAltura={itemDoAccordionAltura ?? 32}>
        <Accordion
          dataSource={dataSource}
          collapsible={true}
          multiple={false}
          animationDuration={150}
          itemTitleRender={(item) =>
            customTitleRender(item, tituloTamanhoFonte, tituloPesoFonte)
          }
          itemRender={customItemRender}
          id={accordionId}
        />
      </AccordionWrapper>
    </Modal>
  );
}

export function ModalAccordionDetalhado({
  modalAccordionVisivel,
  handlerFecharModalAccordion,
  dataSource,
  modalTitulo,
  accordionId,
  tituloTamanhoFonte,
  tituloPesoFonte,
  itemDoAccordionAltura,
}: ModalAccordionDetalhadoProps) {
  return (
    <Modal
      visivel={modalAccordionVisivel}
      arrastarAtivado={false}
      fecharAoClicarFora={false}
      titulo={modalTitulo ?? JanelasDeNotificacaoTitulos.Atencao}
      altura="auto"
      largura={"max(50vw, 750px)"}
      onFechar={() => handlerFecharModalAccordion()}
    >
      <AccordionWrapper itemDoAccordionAltura={itemDoAccordionAltura ?? 32}>
        <Accordion
          dataSource={dataSource}
          collapsible={true}
          multiple={false}
          animationDuration={150}
          itemTitleRender={(item) =>
            customTitleRender(item, tituloTamanhoFonte, tituloPesoFonte)
          }
          itemRender={customItemRenderDetalhado}
          id={accordionId}
        />
      </AccordionWrapper>
    </Modal>
  );
}
