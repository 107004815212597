import DataGrid, {
  Column,
  DataGridRef,
  DataGridTypes,
} from "devextreme-react/data-grid";
import NumberBox, { NumberBoxTypes } from "devextreme-react/number-box";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { GridNumberBoxProps } from "../../../../../../components/templates-celulas-grid/inputs/number-box/number-box-grid.edit";
import { useSeletorGridMxp } from "../../../../../../hooks/seletor.hooks";
import {
  EstoqueGridModelSelecionar,
  EstoqueSituacao,
} from "../../../../../../models/api/estoque/estoque";
import { GridMxpProps } from "../../../../../../models/shared/ui/grid";
import criarNameof from "../../../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../../../utils/common/nomes-telas";
import GridBuilder from "../../../../../../utils/grid/grid-builder";
import obterConfiguracaoColuna from "../../../../../../utils/grid/padroes-colunas";
import ContextoOperacoesInsumo from "../../../../../producao/insumo-da-ordem-de-producao/componentes/contexto-funcoes-insumo";
import { colunasEstoques } from "../colunasEstoque";

export interface GridSelecionarEstoqueProps
  extends GridMxpProps<EstoqueGridModelSelecionar> {
  dataSource: DataSource | ArrayStore;
  acaoAtualizaGrid: () => void;
  deveExibirColunaEditavelDeQuantidade?: boolean;
}

const nameOfGridHandler = criarNameof<EstoqueGridModelSelecionar>();

const QuantidadeEditavelComponent = ({
  data,
}: {
  data: DataGridTypes.ColumnEditCellTemplateData<
    EstoqueGridModelSelecionar,
    number
  >;
}) => {
  const onValueChanged = useCallback(
    (e: NumberBoxTypes.ValueChangedEvent) => {
      data.setValue(e.value);
    },
    [data]
  );

  return (
    <NumberBox
      name="quantidadeMovimentar"
      defaultValue={data.value}
      onValueChanged={onValueChanged}
      disabled={data.data?.situacaoEstoque == EstoqueSituacao.Indisponivel}
      min={0}
      max={data.data?.quantidade ?? 0}
      {...GridNumberBoxProps}
    />
  );
};

export default function GridSelecaoEstoque(props: GridSelecionarEstoqueProps) {
  const gridRef = useRef<DataGridRef>(null);
  const { referenciasGridInsumos } = useContext(ContextoOperacoesInsumo);

  useEffect(() => {
    referenciasGridInsumos.definirReferenciaGridSelecaoEstoqueBaixaInsumo(
      gridRef
    );
  }, []);

  useSeletorGridMxp(() => gridRef.current, props.filtrosNoCliente);

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar(
      "grid-selecao-estoque",
      () => gridRef?.current?.instance(),
      false,
      props.filtrosNoCliente
    )
      .definirStyles(props.style)
      .definirDataSource(props.dataSource)
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(NomesTelas.estoque)
      .definirOrdenacao()
      .definirOperacoesNoLadoDoCliente()
      .definirEditavel()
      .definirBotaoRefresh(props.acaoAtualizaGrid)
      .build();
  }, [props.filtrosNoCliente, props.dataSource]);

  // Evento disparado enquanto a celula está trocando o seu foco
  // usamos ela para manter o foco sempre na coluna de quantidade a baixar ao apertar tab
  const onFocusedCellChanging = useCallback(
    (data: DataGridTypes.FocusedCellChangingEvent) => {
      const indexColunaQuantidadeABaixar = data.columns.findIndex(
        (x) => x.name === "quantidadeMovimentar"
      );

      if (
        data.prevColumnIndex == indexColunaQuantidadeABaixar &&
        (data.newColumnIndex == indexColunaQuantidadeABaixar + 1 ||
          data.newColumnIndex == data.columns.length - 1)
      ) {
        let novaColunaIndex = data.prevRowIndex + 1;

        if (data.prevRowIndex == data.rows.length - 1) {
          novaColunaIndex = data.prevRowIndex - 1;
        }

        data.newColumnIndex = indexColunaQuantidadeABaixar;
        data.newRowIndex = novaColunaIndex;
      }
    },
    []
  );

  const onFocusedCellChanged = useCallback(
    (data: DataGridTypes.FocusedCellChangedEvent) => {
      if (data.column?.name != "quantidadeMovimentar") return;

      // Coloca-se um timeout para dar tempo para o componente ser renderizado para conseguir focar o input
      setTimeout(() => {
        const input = data.cellElement.getElementsByClassName(
          "dx-texteditor-input"
        )[0] as HTMLInputElement;
        if (!input) return;

        input.focus();
      }, 0);
    },
    []
  );

  return (
    <>
      <DataGrid
        ref={gridRef}
        {...configuracoesGrid}
        onFocusedCellChanged={onFocusedCellChanged}
        onFocusedCellChanging={onFocusedCellChanging}
      >
        <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
        {(props.deveExibirColunaEditavelDeQuantidade ?? true) && (
          <Column
            key={nameOfGridHandler("quantidadeMovimentar")}
            dataField={nameOfGridHandler("quantidadeMovimentar")}
            {...obterConfiguracaoColuna("quantidade")}
            allowEditing={true}
            showEditorAlways={true}
            visibleIndex={8}
            caption="Qt a baixar"
            editCellComponent={QuantidadeEditavelComponent}
          />
        )}
        <Column
          key={nameOfGridHandler("situacaoEstoqueDecodificada")}
          dataField={nameOfGridHandler("situacaoEstoqueDecodificada")}
          {...obterConfiguracaoColuna("stringP")}
          allowFiltering={false}
          visible
          allowEditing={false}
          visibleIndex={8}
          width={110}
          caption="Situação"
        />
        {colunasEstoques}
      </DataGrid>
    </>
  );
}
