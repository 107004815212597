import {
  type ConfiguracoesErros,
  TipoErro,
} from "../../../../../models/api/comum/erros";
import {
  formatarItem,
  formatarNumeroOrdemDeProducao,
} from "../../../../../utils/formatadores/formatador-de-identidades";
import { formatarNumeroQuantidade } from "../../../../../utils/formatadores/formatador-de-numeros";
import type { ErroReserva } from "./reserva.erros";

export const configuracoesErrosReserva = Object.freeze<
  ConfiguracoesErros<ErroReserva>
>({
  "MSG-007-0001": {
    tipo: TipoErro.PopUpAgrupado,
    formatador({
      dadosExtras: {
        ordemDeProducao: { numero: numeroOrdemDeProducao },
        itemDaOrdemDeProducao: { item },
        quantidadeDesejada,
        quantidadeDisponivel,
      },
    }) {
      if (numeroOrdemDeProducao) {
        return (
          <>
            Não foi possível criar reserva para o item {formatarItem(item)},
            pois a quantidade a ser reservada (
            {formatarNumeroQuantidade(quantidadeDesejada)}) ultrapassa a
            quantidade livre ({formatarNumeroQuantidade(quantidadeDisponivel)})
            da ordem de produção{" "}
            {formatarNumeroOrdemDeProducao(numeroOrdemDeProducao)}.
          </>
        );
      } else {
        return (
          <>
            Não foi possível criar reserva para o item {formatarItem(item)},
            pois a quantidade a ser reservada (
            {formatarNumeroQuantidade(quantidadeDesejada)}) ultrapassa a
            quantidade livre ({formatarNumeroQuantidade(quantidadeDisponivel)})
            da ordem de produção.
          </>
        );
      }
    },
  },
  "MSG-007-0002": {
    tipo: TipoErro.PopUpAgrupado,
    formatador({ dadosExtras: { numero = "" } }) {
      return (
        <>
          O estado da ordem de produção {numero} precisa ser &quot;A
          produzir&quot;, &quot;Sugerida&quot; ou &quot;Suspensa sugerida&quot;
          para poder criar uma reserva.
        </>
      );
    },
  },
  "MSG-007-0003": {
    tipo: TipoErro.PopUpAgrupado,
    formatador({ dadosExtras: { item } }) {
      return (
        <>
          O estado do item {formatarItem(item)} precisa ser &quot;Ativo&quot; ou
          &quot;Digitação&quot; para poder criar uma reserva.
        </>
      );
    },
  },
  "MSG-007-0004": {
    tipo: TipoErro.PopUpAgrupado,
    formatador({ dadosExtras: { numero = "" } }) {
      return (
        <>
          O estado do pedido de venda {numero} precisa ser diferente de
          &quot;Faturado&quot; e &quot;Cancelado&quot; para poder criar uma
          reserva.
        </>
      );
    },
  },
  "MSG-007-0005": {
    tipo: TipoErro.PopUpAgrupado,
    formatador({
      dadosExtras: {
        numero: numeroItemPedidoDeVenda = "",
        pedidoDeVenda: { numero: numeroPedidoDeVenda = "" },
      },
    }) {
      return (
        <>
          O estado do item {numeroItemPedidoDeVenda} do pedido de venda{" "}
          {numeroPedidoDeVenda} precisa ser diferente de &quot;Concluído&quot; e
          &quot;Cancelado&quot; para poder criar uma reserva.
        </>
      );
    },
  },
});
