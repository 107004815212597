import { produce } from "immer";
import { PropsWithChildren, useCallback, useMemo, useState } from "react";
import DocumentoFiscalSelecaoMdfeGridModel from "../../../../../models/api/nota-fiscal/nota-fiscal-selecao-mdfe";
import {
  GridCarregamentoViewModel,
  GridCondutorViewModel,
  GridPercursoViewModel,
  MDFeAbaDocumentosFiscaisViewModel,
  MDFeAbaGeralViewModel,
  MDFeAbaObservacoesViewModel,
  MDFeAbaPercursoViewModel,
  MDFeAbaSeguroViewModel,
  MDFeAbaVeiculoViewModel,
} from "../../../../../models/viewmodels/vendas/mdfe/mdfe-edit-form-view-model";
import {
  calcularPesoBrutoTotalMDFe,
  calcularValorTotalMDFe,
} from "../../../../../utils/especifico/mdfe/mdfe-utils";
import MDFeEditFormContext, {
  BaseMDFe,
  DadosNotasMDFe,
  EstadoInicialMDFe,
  UfsAtuais,
} from "../../contexts/mdfe-editform.context";
import {
  MDFeAutorizacaoDTO,
  MdfeAverbacaoDTO,
  MDFeCarregamentoDTO,
  MDFeCompletoDTO,
  MDFeCondutorDTO,
  MDFeContratanteDTO,
  MDFeCPFCNPJAutorizadoDTO,
  MDFeDescarregamentoDTO,
  MDFeEmitenteDTO,
  MDFeModalRodoviarioDTO,
  MDFePercursoDTO,
  MDFeProdutoPredominanteDTO,
  MDFeSeguroDTO,
  MDFeVeiculoDTO,
} from "../../models/mdfe";
import {
  SituacaoMDFe,
  TipoEmitenteMDFe,
  TipoMDFeVeiculo,
  TipoTransportadorMDFe,
  UnidadeMedidaPesoBrutoMDFe,
} from "../../models/mdfe-enums";
import { VisibilidadeBotoesMDFe } from "../formulario/constantes";

const obterBase = (completo: MDFeCompletoDTO): BaseMDFe => {
  return {
    chaveAcesso: completo.chaveAcesso,
    alteracaoUsuarioApelido: completo.alteracaoUsuarioApelido,
    ambiente: completo.ambiente,
    codigoAgendamentoPorto: completo.codigoAgendamentoPorto,
    criacaoData: completo.criacaoData,
    criacaoUsuarioApelido: completo.criacaoUsuarioApelido,
    id: completo.id,
    idArquivoDamdfeAutorizado: completo.idArquivoDamdfeAutorizado,
    modelo: completo.modelo,
    motivoRejeicao: completo.motivoRejeicao,
    situacao: completo.situacao,
    tipoEmissao: completo.tipoEmissao,
    ultimaAlteracaoData: completo.ultimaAlteracaoData,
  };
};

function criarObjetoSeguroMdfe(
  seguro: MDFeAbaSeguroViewModel,
  averbacoes: MdfeAverbacaoDTO[]
): MDFeSeguroDTO | null {
  if (seguro.numeroApolice && seguro.responsavelSeguro) {
    const dados: MDFeSeguroDTO = {
      id: seguro.id,
      responsavelSeguro: seguro.responsavelSeguro,
      idEmpresaSeguradora: seguro.idEmpresaSeguradora,
      numeroApolice: seguro.numeroApolice,
      averbacoes: [...averbacoes],
    };

    return dados;
  }
  return null;
}

export default function MDFeEditFormContextProvider({
  children,
}: PropsWithChildren) {
  const [baseMdfe, setBaseMdfe] = useState<BaseMDFe>(
    obterBase(EstadoInicialMDFe)
  );
  const [modalRodoviario, setModalRodoviario] =
    useState<MDFeModalRodoviarioDTO>(EstadoInicialMDFe.modalRodoviario);
  const [descarregamentos, setDescarregamentos] = useState<
    MDFeDescarregamentoDTO[]
  >(EstadoInicialMDFe.descarregamentos);
  const [carregamentos, setCarregamentos] = useState<MDFeCarregamentoDTO[]>(
    EstadoInicialMDFe.carregamentos
  );
  const [cpfCnpjAutorizados, setCpfCnpjAutorizados] = useState<
    MDFeCPFCNPJAutorizadoDTO[]
  >(EstadoInicialMDFe.cpfCnpjAutorizados);
  const [percursos, setPercursos] = useState<MDFePercursoDTO[]>(
    EstadoInicialMDFe.percursos
  );
  const [produtoPredominante, setProdutoPredominante] =
    useState<MDFeProdutoPredominanteDTO | null>(
      EstadoInicialMDFe.produtoPredominante
    );
  const [emitente, setEmitente] = useState<MDFeEmitenteDTO | null>(
    EstadoInicialMDFe.emitente
  );
  const [autorizacao, setAutorizacao] = useState<MDFeAutorizacaoDTO | null>(
    EstadoInicialMDFe.autorizacao
  );
  const [veiculos, setVeiculos] = useState<MDFeVeiculoDTO[]>(
    EstadoInicialMDFe.modalRodoviario.veiculos
  );
  const [condutores, setCondutores] = useState<MDFeCondutorDTO[]>(
    EstadoInicialMDFe.modalRodoviario.condutores
  );
  const [contratante, setContratante] = useState<MDFeContratanteDTO>(
    EstadoInicialMDFe.modalRodoviario.contratante
  );
  const [ufsAtuais, setUfsAtuais] = useState<UfsAtuais>({ inicio: 0, fim: 0 });

  const [dadosPercurso, setDadosPercurso] = useState<MDFeAbaPercursoViewModel>({
    id: EstadoInicialMDFe.id,
    cepLocalCarregamento: EstadoInicialMDFe.cepLocalCarregamento,
    cepLocalDescarregamento: EstadoInicialMDFe.cepLocalDescarregamento,
    idUfFim: EstadoInicialMDFe.idUfFim,
    idUfInicio: EstadoInicialMDFe.idUfInicio,
  });

  const [dadosNotas, setDadosNotas] = useState<DadosNotasMDFe>({
    pesoBruto: EstadoInicialMDFe.pesoBruto,
    valorTotalNfe: EstadoInicialMDFe.valorTotalNfe,
    tipoCarregamento: EstadoInicialMDFe.tipoCarregamento,
    unidadePesoBruto: EstadoInicialMDFe.unidadePesoBruto,
  });

  const [dadosObservacoes, setDadosObservacoes] =
    useState<MDFeAbaObservacoesViewModel>({
      id: EstadoInicialMDFe.id,
      fisco: EstadoInicialMDFe.informacoesAdicionais,
      contribuinte: EstadoInicialMDFe.informacoesAdicionaisContibuinte,
      internas: EstadoInicialMDFe.observacoesInternas,
    });

  const [dadosGerais, setDadosGerais] = useState<MDFeAbaGeralViewModel>({
    dataHoraEmissao: EstadoInicialMDFe.dataHoraEmissao,
    dataHoraInicioViagem: EstadoInicialMDFe.dataHoraInicioViagem,
    id: EstadoInicialMDFe.id,
    idContratante: EstadoInicialMDFe.modalRodoviario.contratante.idContratante,
    modalidadeTransporte: EstadoInicialMDFe.modalidadeTransporte,
    numero: EstadoInicialMDFe.numero,
    serie: EstadoInicialMDFe.serie,
    tipoEmitente: EstadoInicialMDFe.tipoEmitente,
    tipoTransportador: EstadoInicialMDFe.tipoTransportador,
  });

  const [seguro, setSeguro] = useState<MDFeAbaSeguroViewModel>({
    id: 0,
    idEmpresaSeguradora: null,
    numeroApolice: "",
    responsavelSeguro: null,
  });

  const [averbacoes, setAverbacoes] = useState<MdfeAverbacaoDTO[]>([]);

  const obterDocumentoAtual = useCallback((): MDFeCompletoDTO => {
    return {
      id: baseMdfe.id,
      ambiente: baseMdfe.ambiente,
      alteracaoUsuarioApelido: baseMdfe.alteracaoUsuarioApelido,
      criacaoUsuarioApelido: baseMdfe.criacaoUsuarioApelido,
      ultimaAlteracaoData: baseMdfe.ultimaAlteracaoData,
      criacaoData: baseMdfe.criacaoData,
      cepLocalCarregamento: dadosPercurso.cepLocalCarregamento,
      cepLocalDescarregamento: dadosPercurso.cepLocalDescarregamento,
      chaveAcesso: baseMdfe.chaveAcesso,
      codigoAgendamentoPorto: baseMdfe.codigoAgendamentoPorto,
      dataHoraEmissao: dadosGerais.dataHoraEmissao,
      dataHoraInicioViagem: dadosGerais.dataHoraInicioViagem,
      idArquivoDamdfeAutorizado: baseMdfe.idArquivoDamdfeAutorizado,
      modelo: baseMdfe.modelo,
      numero: dadosGerais.numero,
      serie: dadosGerais.serie,
      situacao: baseMdfe.situacao,
      idUfFim: dadosPercurso.idUfFim,
      idUfInicio: dadosPercurso.idUfInicio,
      informacoesAdicionais: dadosObservacoes.fisco,
      informacoesAdicionaisContibuinte: dadosObservacoes.contribuinte,
      observacoesInternas: dadosObservacoes.internas,
      modalidadeTransporte: dadosGerais.modalidadeTransporte,
      motivoRejeicao: baseMdfe.motivoRejeicao,
      pesoBruto: dadosNotas.pesoBruto,
      tipoEmissao: baseMdfe.tipoEmissao,
      tipoEmitente: dadosGerais.tipoEmitente,
      tipoTransportador: dadosGerais.tipoTransportador,
      valorTotalNfe: dadosNotas.valorTotalNfe,
      unidadePesoBruto: dadosNotas.unidadePesoBruto,
      tipoCarregamento: dadosNotas.tipoCarregamento,
      modalRodoviario: {
        id: modalRodoviario.id,
        condutores,
        contratante,
        veiculos,
      },
      seguro: criarObjetoSeguroMdfe(seguro, averbacoes),
      descarregamentos,
      carregamentos,
      cpfCnpjAutorizados,
      percursos,
      produtoPredominante,
      emitente,
      autorizacao,
    };
  }, [
    baseMdfe,
    dadosGerais,
    dadosNotas,
    dadosObservacoes,
    dadosPercurso,
    modalRodoviario,
    descarregamentos,
    carregamentos,
    cpfCnpjAutorizados,
    percursos,
    produtoPredominante,
    emitente,
    autorizacao,
    condutores,
    contratante,
    veiculos,
    seguro,
    averbacoes,
  ]);

  const carregar = useCallback(
    (mdfe: MDFeCompletoDTO) => {
      const bmdfe = obterBase(mdfe);
      setDadosGerais({
        id: mdfe.id,
        dataHoraEmissao: mdfe.dataHoraEmissao,
        dataHoraInicioViagem: mdfe.dataHoraInicioViagem,
        idContratante: mdfe.modalRodoviario.contratante.idContratante,
        modalidadeTransporte: mdfe.modalidadeTransporte,
        numero: mdfe.numero,
        serie: mdfe.serie,
        tipoEmitente: mdfe.tipoEmitente,
        tipoTransportador: mdfe.tipoTransportador,
      });
      setDadosNotas({
        pesoBruto: mdfe.pesoBruto,
        tipoCarregamento: mdfe.tipoCarregamento,
        unidadePesoBruto: mdfe.unidadePesoBruto,
        valorTotalNfe: mdfe.valorTotalNfe,
      });
      setDadosPercurso({
        id: mdfe.id,
        idUfInicio: mdfe.idUfInicio,
        idUfFim: mdfe.idUfFim,
        cepLocalCarregamento: mdfe.cepLocalCarregamento,
        cepLocalDescarregamento: mdfe.cepLocalDescarregamento,
      });
      setDadosObservacoes({
        id: mdfe.id,
        contribuinte: mdfe.informacoesAdicionaisContibuinte,
        fisco: mdfe.informacoesAdicionais,
        internas: mdfe.observacoesInternas,
      });
      setUfsAtuais({ inicio: mdfe.idUfInicio, fim: mdfe.idUfFim });
      setModalRodoviario({ ...mdfe.modalRodoviario });
      setCondutores([...mdfe.modalRodoviario.condutores]);
      setContratante({ ...mdfe.modalRodoviario.contratante });
      setVeiculos([...mdfe.modalRodoviario.veiculos]);
      setDescarregamentos([...mdfe.descarregamentos]);
      setCarregamentos([...mdfe.carregamentos]);
      setCpfCnpjAutorizados([...mdfe.cpfCnpjAutorizados]);
      setPercursos([...mdfe.percursos]);
      setProdutoPredominante(
        mdfe.produtoPredominante ? { ...mdfe.produtoPredominante } : null
      );
      setEmitente(mdfe.emitente ? { ...mdfe.emitente } : null);
      setAutorizacao(mdfe.autorizacao ? { ...mdfe.autorizacao } : null);
      setSeguro({
        id: mdfe.seguro?.id ?? EstadoInicialMDFe.seguro?.id ?? 0,
        idEmpresaSeguradora:
          mdfe?.seguro?.idEmpresaSeguradora ??
          EstadoInicialMDFe.seguro?.idEmpresaSeguradora ??
          null,
        numeroApolice:
          mdfe?.seguro?.numeroApolice ??
          EstadoInicialMDFe.seguro?.numeroApolice ??
          "",
        responsavelSeguro:
          mdfe.seguro?.responsavelSeguro ??
          EstadoInicialMDFe.seguro?.responsavelSeguro ??
          null,
      });
      setAverbacoes(
        mdfe.seguro?.averbacoes
          ? [...mdfe.seguro.averbacoes]
          : EstadoInicialMDFe.seguro?.averbacoes ?? []
      );
      setBaseMdfe(bmdfe);
    },
    [
      setBaseMdfe,
      setModalRodoviario,
      setCondutores,
      setContratante,
      setVeiculos,
      setDescarregamentos,
      setCarregamentos,
      setCpfCnpjAutorizados,
      setPercursos,
      setProdutoPredominante,
      setEmitente,
      setAutorizacao,
    ]
  );

  const novoDocumento = useCallback(() => {
    carregar(EstadoInicialMDFe);
  }, [carregar]);

  const adicionarCarregamento = useCallback(
    (view: MDFeCarregamentoDTO) => {
      const novoArray = produce(carregamentos, (draft) => {
        draft.push(view);
      });

      setCarregamentos(novoArray);
    },
    [carregamentos, setCarregamentos]
  );

  const removerCarregamento = useCallback(
    (view: GridCarregamentoViewModel) => {
      const novo = carregamentos.filter(
        (x) => x.idMunicipio != view.idMunicipio
      );
      setCarregamentos(novo);
    },
    [carregamentos, setCarregamentos]
  );

  const adicionarCondutor = useCallback(
    (view: GridCondutorViewModel[]) => {
      const novoArray = produce(condutores, (draft) => {
        for (const v of view) {
          draft.push({
            id: 0,
            idCondutor: v.idCondutor,
            condutor: {
              nome: v.nome,
              cpfCnpj: v.cpfCnpj,
              apelido: v.apelido,
            },
          });
        }
      });

      setCondutores(novoArray);
    },
    [condutores, setCondutores]
  );

  const removerCondutor = useCallback(
    (view: GridCondutorViewModel) => {
      const novosCondutores = produce(condutores, (draft) => {
        const i = draft.findIndex(
          (x) => x.idCondutor == view.idCondutor && x.id == view.id
        );

        if (i > -1) {
          draft.splice(i, 1);
        }
      });

      setCondutores(novosCondutores);
    },
    [condutores, setCondutores]
  );

  function adicionarDocumentoFiscalInterno(
    view: DocumentoFiscalSelecaoMdfeGridModel[],
    descarreg: MDFeDescarregamentoDTO[],
    dados: DadosNotasMDFe
  ): [MDFeDescarregamentoDTO[], DadosNotasMDFe] {
    let novoArray = descarreg;

    for (const v of view) {
      novoArray = produce(novoArray, (draft) => {
        if (!draft.some((x) => x.idMunicipio == v.idMunicipioDestino)) {
          let proximaOrdem = Math.max(...draft.map((x) => x.ordem));
          proximaOrdem = proximaOrdem == Infinity * -1 ? 1 : proximaOrdem + 1;
          draft.push({
            id: 0,
            idMunicipio: v.idMunicipioDestino,
            ordem: proximaOrdem,
            documentosVinculados: [],
          });
        }

        const i = draft.findIndex((x) => x.idMunicipio == v.idMunicipioDestino);

        draft[i].documentosVinculados!.push({
          id: 0,
          idNotaFiscal: v.id,
          documentoFiscal: {
            chaveAcesso: v.chaveAcesso,
            dataEmissao: v.dataEmissao,
            idDestinatario: v.idDestinatario,
            destinatario: v.destinatario,
            idMunicipioDestino: v.idMunicipioDestino,
            municipioDestino: v.municipioDestino,
            numero: v.numero,
            pesoBruto: v.pesoBruto,
            pesoLiquido: v.pesoLiquido,
            serie: v.serie,
            valor: v.valor,
            idUfDestino: v.idUfDestino,
            ufDestino: v.ufDestino,
          },
        });
      });
    }

    //Recalcular totais
    const novosDados = produce(dados, (draft) => {
      draft.valorTotalNfe = calcularValorTotalMDFe(novoArray);
      draft.pesoBruto = calcularPesoBrutoTotalMDFe(
        novoArray,
        UnidadeMedidaPesoBrutoMDFe.KG
      );
    });

    return [novoArray, novosDados];
  }

  function removerDocumentoFiscalInterno(
    view: DocumentoFiscalSelecaoMdfeGridModel,
    descarreg: MDFeDescarregamentoDTO[],
    dados: DadosNotasMDFe
  ): [MDFeDescarregamentoDTO[], DadosNotasMDFe] {
    let novoArray = produce(descarreg, (draft) => {
      const i = draft.findIndex(
        (x) => x.idMunicipio == view.idMunicipioDestino
      );

      // Verifica se é um documento fiscal avulso ou possui nota fiscal vinculada.
      if (view.idNotaFiscal > 0) {
        draft[i].documentosVinculados = draft[i].documentosVinculados!.filter(
          (x) => x.idNotaFiscal != view.idNotaFiscal
        );
      } else {
        draft[i].documentosVinculados = draft[i].documentosVinculados!.filter(
          (x) => x.documentoFiscal?.chaveAcesso != view.chaveAcesso
        );
      }

      if (!draft[i].documentosVinculados) {
        draft = draft.filter((x) => x.idMunicipio != view.idMunicipioDestino);
      }
    });

    novoArray = novoArray.filter(
      (x) => x.documentosVinculados && x.documentosVinculados.length > 0
    );

    //Recalcular totais
    const novosDados = produce(dados, (draft) => {
      draft.valorTotalNfe = calcularValorTotalMDFe(novoArray);
      draft.pesoBruto = calcularPesoBrutoTotalMDFe(
        novoArray,
        UnidadeMedidaPesoBrutoMDFe.KG
      );
    });

    return [novoArray, novosDados];
  }

  const adicionarDocumentoFiscal = useCallback(
    (view: DocumentoFiscalSelecaoMdfeGridModel[]) => {
      const [novoArray, novosDados] = adicionarDocumentoFiscalInterno(
        view,
        descarregamentos,
        dadosNotas
      );

      setDadosNotas(novosDados);
      setDescarregamentos(novoArray);
    },
    [descarregamentos, dadosNotas, setDadosNotas, setDescarregamentos]
  );

  const removerDocumentoFiscal = useCallback(
    (view: DocumentoFiscalSelecaoMdfeGridModel) => {
      const [novoArray, novosDados] = removerDocumentoFiscalInterno(
        view,
        descarregamentos,
        dadosNotas
      );

      setDadosNotas(novosDados);
      setDescarregamentos(novoArray);
    },
    [descarregamentos, dadosNotas, setDescarregamentos, setDadosNotas]
  );

  const editarDocumentoFiscal = useCallback(
    (view: DocumentoFiscalSelecaoMdfeGridModel) => {
      const novosDescarregamentos = produce(descarregamentos, (draft) => {
        if (!draft.some((x) => x.idMunicipio == view.idMunicipioDestino)) {
          let proximaOrdem = Math.max(...draft.map((x) => x.ordem));
          proximaOrdem = proximaOrdem == Infinity * -1 ? 1 : proximaOrdem + 1;
          draft.push({
            id: 0,
            idMunicipio: view.idMunicipioDestino,
            ordem: proximaOrdem,
            documentosVinculados: [],
          });

          const avulsoIndex = draft.findIndex((x) =>
            x.documentosVinculados?.some((x) => x.id == view.id)
          );
          if (avulsoIndex !== -1) {
            if (draft[avulsoIndex].documentosVinculados) {
              draft[avulsoIndex].documentosVinculados = draft[
                avulsoIndex
              ].documentosVinculados!.filter((x) => x.id != view.id);

              draft[avulsoIndex].documentosVinculados = undefined;
            }
          }

          const idMunicipioDestino = view.idMunicipioDestino;
          const descarregamentoIndex = draft.findIndex(
            (x) => x.idMunicipio === idMunicipioDestino
          );

          if (descarregamentoIndex !== -1) {
            draft[descarregamentoIndex].documentosVinculados?.push({
              id: 0,
              idNotaFiscal: view.idNotaFiscal,
              documentoFiscal: {
                chaveAcesso: view.chaveAcesso,
                dataEmissao: view.dataEmissao,
                idDestinatario: view.idDestinatario,
                destinatario: view.destinatario,
                idMunicipioDestino: view.idMunicipioDestino,
                municipioDestino: view.municipioDestino,
                numero: view.numero,
                pesoBruto: view.pesoBruto,
                pesoLiquido: view.pesoLiquido,
                serie: view.serie,
                valor: view.valor,
                idUfDestino: view.idUfDestino,
                ufDestino: view.ufDestino,
              },
            });
          }

          draft = draft.filter(
            (x) => (x.documentosVinculados?.length ?? 0) > 0
          );
        } else {
          const idMunicipioDestino = view.idMunicipioDestino;
          const descarregamentoIndex = draft.findIndex(
            (x) => x.idMunicipio === idMunicipioDestino
          );

          if (descarregamentoIndex !== -1) {
            const documentosVinculados =
              draft[descarregamentoIndex].documentosVinculados;

            if (documentosVinculados) {
              const documentoIndex = documentosVinculados.findIndex(
                (x) => x.id === view.id
              );

              if (documentoIndex !== -1) {
                draft[descarregamentoIndex].documentosVinculados![
                  documentoIndex
                ] = {
                  id: view.id,
                  idNotaFiscal: view.idNotaFiscal,
                  documentoFiscal: {
                    chaveAcesso: view.chaveAcesso,
                    dataEmissao: view.dataEmissao,
                    idDestinatario: view.idDestinatario,
                    destinatario: view.destinatario,
                    idMunicipioDestino: view.idMunicipioDestino,
                    municipioDestino: view.municipioDestino,
                    numero: view.numero,
                    pesoBruto: view.pesoBruto,
                    pesoLiquido: view.pesoLiquido,
                    serie: view.serie,
                    valor: view.valor,
                    idUfDestino: view.idUfDestino,
                    ufDestino: view.ufDestino,
                  },
                };
              }
            }
          }
        }
      });

      //Recalcular totais
      const novosDados = produce(dadosNotas, (draft) => {
        draft.valorTotalNfe = calcularValorTotalMDFe(novosDescarregamentos);
        draft.pesoBruto = calcularPesoBrutoTotalMDFe(
          novosDescarregamentos,
          UnidadeMedidaPesoBrutoMDFe.KG
        );
      });

      setDescarregamentos(novosDescarregamentos);
      setDadosNotas(novosDados);
    },
    [descarregamentos, dadosNotas, setDescarregamentos, setDadosNotas]
  );

  const removerEAdicionarDocumentoFiscal = useCallback(
    (
      viewRemover: DocumentoFiscalSelecaoMdfeGridModel,
      viewAdd: DocumentoFiscalSelecaoMdfeGridModel[]
    ) => {
      const [novoArrayRemover, novosDadosRemover] =
        removerDocumentoFiscalInterno(
          viewRemover,
          descarregamentos,
          dadosNotas
        );

      const [novoArray, novosDados] = adicionarDocumentoFiscalInterno(
        viewAdd,
        novoArrayRemover,
        novosDadosRemover
      );

      setDadosNotas(novosDados);
      setDescarregamentos(novoArray);
    },
    [descarregamentos, dadosNotas, setDescarregamentos, setDadosNotas]
  );

  const adicionarPercurso = useCallback(
    (view: MDFePercursoDTO) => {
      const novoArray = produce(percursos, (draft) => {
        draft.push(view);
      });

      setPercursos(novoArray);
    },
    [percursos, setPercursos]
  );

  const removerPercurso = useCallback(
    (view: GridPercursoViewModel) => {
      const percursosNovos = percursos.filter((x) => x.idUf != view.idUf);

      setPercursos(percursosNovos);
    },
    [percursos, setPercursos]
  );

  const definirDadosGerais = useCallback(
    (view: MDFeAbaGeralViewModel) => {
      const novosDadosGerais = produce(dadosGerais, (draft) => {
        draft.id = view.id;
        draft.serie = view.serie;
        draft.numero = view.numero;
        draft.dataHoraEmissao = view.dataHoraEmissao;
        draft.modalidadeTransporte = view.modalidadeTransporte;
        draft.tipoEmitente = view.tipoEmitente;
        draft.dataHoraInicioViagem = view.dataHoraInicioViagem;
        draft.tipoTransportador = view.tipoTransportador;
        draft.idContratante = view.idContratante;
      });

      const novoContratante = produce(contratante, (draft) => {
        draft.idContratante = view.idContratante;
      });

      setDadosGerais(novosDadosGerais);
      setContratante(novoContratante);
    },
    [dadosGerais, setDadosGerais, contratante, setContratante]
  );

  const definirDadosNotas = useCallback(
    (view: MDFeAbaDocumentosFiscaisViewModel) => {
      const novosDados = produce(dadosNotas, (draft) => {
        draft.unidadePesoBruto = view.unidadeMedidaPesoBruto;
        draft.tipoCarregamento = view.tipoCarregamento;
        draft.pesoBruto = view.pesoBrutoTotal;
        draft.unidadePesoBruto = view.unidadeMedidaPesoBruto;
        draft.valorTotalNfe = view.valorTotal;
      });

      const novoProdutoPredominante = produce(produtoPredominante, (draft) => {
        if (draft && view.descricaoProdutoPredominante) {
          draft.descricao = view.descricaoProdutoPredominante;
        } else if (!draft && view.descricaoProdutoPredominante) {
          draft = {
            id: 0,
            descricao: view.descricaoProdutoPredominante,
          };
        } else {
          draft = null;
        }
      });

      setDadosNotas(novosDados);
      setProdutoPredominante(novoProdutoPredominante);
    },
    [produtoPredominante, setProdutoPredominante, dadosNotas, setDadosNotas]
  );

  const definirDadosObservacoes = useCallback(
    (view: MDFeAbaObservacoesViewModel) => {
      const novosDados = produce(dadosObservacoes, (draft) => {
        draft.id = view.id;
        draft.contribuinte = view.contribuinte;
        draft.fisco = view.fisco;
        draft.internas = view.internas;
      });

      setDadosObservacoes(novosDados);
    },
    [dadosObservacoes, setDadosObservacoes]
  );

  const definirDadosPercurso = useCallback(
    (view: MDFeAbaPercursoViewModel) => {
      const novosDados = produce(dadosPercurso, (draft) => {
        draft.idUfFim = view.idUfFim;
        draft.idUfInicio = view.idUfInicio;
        draft.cepLocalCarregamento = view.cepLocalCarregamento;
        draft.cepLocalDescarregamento = view.cepLocalDescarregamento;
      });

      setDadosPercurso(novosDados);
    },
    [dadosPercurso, setDadosPercurso]
  );

  const definirDadosVeiculos = useCallback(
    (view: MDFeAbaVeiculoViewModel) => {
      const listaVeiculos: MDFeVeiculoDTO[] = [];

      if (view.idVeiculoTracao) {
        listaVeiculos.push({
          id: view.idTracao ?? 0,
          idVeiculo: view.idVeiculoTracao,
          tipo: TipoMDFeVeiculo.Tracao,
        });
      }

      if (view.idVeiculoReboque1) {
        listaVeiculos.push({
          id: view.idReboque1 ?? 0,
          idVeiculo: view.idVeiculoReboque1,
          tipo: TipoMDFeVeiculo.Reboque,
        });
      }

      if (view.idVeiculoReboque2) {
        listaVeiculos.push({
          id: view.idReboque2 ?? 0,
          idVeiculo: view.idVeiculoReboque2,
          tipo: TipoMDFeVeiculo.Reboque,
        });
      }

      if (view.idVeiculoReboque3) {
        listaVeiculos.push({
          id: view.idReboque3 ?? 0,
          idVeiculo: view.idVeiculoReboque3,
          tipo: TipoMDFeVeiculo.Reboque,
        });
      }

      setVeiculos(listaVeiculos);
    },
    [setVeiculos]
  );

  const recalcularTotaisNotasMDFe = useCallback(() => {
    const novosDados = produce(dadosNotas, (draft) => {
      draft.valorTotalNfe = calcularValorTotalMDFe(descarregamentos);
      draft.pesoBruto = calcularPesoBrutoTotalMDFe(
        descarregamentos,
        UnidadeMedidaPesoBrutoMDFe.KG
      );
    });

    setDadosNotas(novosDados);
  }, [dadosNotas, setDadosNotas, descarregamentos]);

  const definirDataEmissao = useCallback(
    (data: string) => {
      const novosDados = produce(dadosGerais, (draft) => {
        draft.dataHoraEmissao = data;
      });

      setDadosGerais(novosDados);
    },
    [dadosGerais, setDadosGerais]
  );

  const definirTipoTransportador = useCallback(
    (data: TipoTransportadorMDFe) => {
      const novosDados = produce(dadosGerais, (draft) => {
        draft.tipoTransportador = data;
      });

      setDadosGerais(novosDados);
    },
    [dadosGerais, setDadosGerais]
  );

  const definirTipoEmitente = useCallback(
    (data: TipoEmitenteMDFe) => {
      const novosDados = produce(dadosGerais, (draft) => {
        draft.tipoEmitente = data;
      });

      setDadosGerais(novosDados);
    },
    [dadosGerais, setDadosGerais]
  );

  const visibilidadeBotoes = useMemo(
    () => VisibilidadeBotoesMDFe.get(baseMdfe.situacao),
    [baseMdfe.situacao]
  );

  const telaSomenteLeitura = useMemo(() => {
    return baseMdfe.situacao != SituacaoMDFe.EmEdicao;
  }, [baseMdfe.situacao]);

  const limparContexto = useCallback(() => {
    carregar({ ...EstadoInicialMDFe, id: NaN });
  }, [carregar]);

  const definirDadosUfs = useCallback(
    (inicio: number, fim: number) => {
      const novasUfs = { inicio, fim };
      setUfsAtuais(novasUfs);
    },
    [setUfsAtuais]
  );

  const definirDadosSeguro = useCallback(
    (view: MDFeAbaSeguroViewModel) => {
      const novoObjeto = produce(seguro, (draft) => {
        draft.id = view.id;
        draft.idEmpresaSeguradora = view.idEmpresaSeguradora;
        draft.numeroApolice = view.numeroApolice;
        draft.responsavelSeguro = view.responsavelSeguro;
      });

      setSeguro(novoObjeto);
    },
    [seguro, setSeguro]
  );

  const adicionarAverbacao = useCallback(
    (view: MdfeAverbacaoDTO) => {
      const novoArray = produce(averbacoes, (draft) => {
        draft.push(view);
      });
      setAverbacoes(novoArray);
    },
    [averbacoes, setAverbacoes]
  );

  const removerAverbacao = useCallback(
    (view: MdfeAverbacaoDTO) => {
      const novoArray = produce(averbacoes, (draft) => {
        const i = draft.findIndex((x) => x.numero == view.numero);

        if (i > -1) {
          draft.splice(i, 1);
        }
      });
      setAverbacoes(novoArray);
    },
    [averbacoes, setAverbacoes]
  );

  return (
    <MDFeEditFormContext.Provider
      value={{
        obterDocumentoAtual,
        dadosGerais,
        dadosNotas,
        dadosObservacoes,
        dadosPercurso,
        autorizacao,
        baseMdfe,
        carregamentos,
        condutores,
        contratante,
        cpfCnpjAutorizados,
        descarregamentos,
        emitente,
        modalRodoviario,
        percursos,
        produtoPredominante,
        seguro,
        averbacoes,
        veiculos,
        visibilidadeBotoes,
        telaSomenteLeitura,
        ufsAtuais,
        carregar,
        novoDocumento,
        adicionarCarregamento,
        removerCarregamento,
        adicionarCondutor,
        removerCondutor,
        adicionarDocumentoFiscal,
        removerDocumentoFiscal,
        removerEAdicionarDocumentoFiscal,
        adicionarPercurso,
        removerPercurso,
        definirDadosGerais,
        definirDadosNotas,
        definirDadosObservacoes,
        definirDadosPercurso,
        definirDadosVeiculos,
        recalcularTotaisNotasMDFe,
        editarDocumentoFiscal,
        definirDataEmissao,
        definirTipoTransportador,
        definirTipoEmitente,
        limparContexto,
        definirDadosUfs,
        definirDadosSeguro,
        adicionarAverbacao,
        removerAverbacao,
      }}
    >
      {children}
    </MDFeEditFormContext.Provider>
  );
}
