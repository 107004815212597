import { GridController } from "../grid/grid-controller";

export abstract class GestorEventoClick {
  public abstract eventoParaMenuContextoBotao(dados: any): (x: any) => void;
  public abstract eventoParaMenuSuperior(): () => void;
  public abstract eventoParaMenuContextoNativo(): () => void;
}

export class GestorEventoClickSimples extends GestorEventoClick {
  private _acao: () => void;

  constructor(acao: () => void) {
    super();
    this._acao = acao;
  }

  public eventoParaMenuContextoBotao(): (x: any) => void {
    return () => this._acao;
  }

  public eventoParaMenuSuperior(): () => void {
    return () => this._acao();
  }

  public eventoParaMenuContextoNativo(): () => void {
    return () => this._acao();
  }
}

export class GestorEventoClickUnicaLinha<T> extends GestorEventoClick {
  private _acao: (x: T) => void;
  private _controller: () => GridController<T>;

  constructor(acao: (x: T) => void, controller: () => GridController<T>) {
    super();
    this._acao = acao;
    this._controller = controller;
  }

  public eventoParaMenuContextoBotao(dados: any): (x: any) => void {
    return () => this._acao(dados);
  }

  public eventoParaMenuSuperior(): () => void {
    return () => this._controller().requerUmRegistrosSelecionado(this._acao);
  }

  public eventoParaMenuContextoNativo(): () => void {
    return () => this._controller().requerUmRegistrosSelecionado(this._acao);
  }
}

export class GestorEventoClickMultiplasLinhas<T> extends GestorEventoClick {
  private _acao: (x: T[]) => void;
  private _controller: () => GridController<T>;

  constructor(acao: (x: T[]) => void, controller: () => GridController<T>) {
    super();
    this._acao = acao;
    this._controller = controller;
  }

  public eventoParaMenuContextoBotao(dados: any): (x: any) => void {
    return () => this._acao([dados]);
  }

  public eventoParaMenuSuperior(): () => void {
    return () =>
      this._controller().requerUmOuMaisRegistrosSelecionado(this._acao);
  }

  public eventoParaMenuContextoNativo(): () => void {
    return () =>
      this._controller().requerUmOuMaisRegistrosSelecionado(this._acao);
  }
}

export class GestorEventoClickMultiplasLinhasComValoresEditaveis<
  T
> extends GestorEventoClick {
  private _acao: (x: T[]) => void;
  private _controller: () => GridController<T>;

  constructor(acao: (x: T[]) => void, controller: () => GridController<T>) {
    super();
    this._acao = acao;
    this._controller = controller;
  }

  public eventoParaMenuContextoBotao(dados: any): (x: any) => void {
    return () => this._acao([dados]);
  }

  public eventoParaMenuSuperior(): () => void {
    return () =>
      this._controller().requerUmOuMaisRegistrosSelecionadoComDadosAtualizados(
        this._acao
      );
  }

  public eventoParaMenuContextoNativo(): () => void {
    return () =>
      this._controller().requerUmOuMaisRegistrosSelecionadoComDadosAtualizados(
        this._acao
      );
  }
}
