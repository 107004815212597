import getApi from "../../../../configs/api";
import { ResponseModel } from "../../../../models/api/comum/response-base";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";
import {
  BuscaNotaImportacaoImobilizadoRequest,
  BuscaNotaImportacaoImobilizadoResponse,
} from "../../../contabilidade/imobilizado/models/imobilizado";

class ItemNotaFiscalRecebidaService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.ItemNotaFiscalRecebida);
  }

  public async BuscarItensParaImportacao(
    dados: BuscaNotaImportacaoImobilizadoRequest[]
  ) {
    const api = getApi();
    const response = await api.post<
      ResponseModel<BuscaNotaImportacaoImobilizadoResponse[]>
    >(`${this._nomeEndpoint}/BuscarItensParaImportacao`, dados);

    return response.data.model;
  }
}

export const ItemNotaFiscalRecebidaServico =
  new ItemNotaFiscalRecebidaService();
