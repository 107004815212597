import { useMemo } from "react";
import { FieldValues, UseControllerProps } from "react-hook-form";
import GridContaContabil from "../../../../features/contabilidade/conta-contabil/componentes/grid";
import {
  ContaContabilGridModel,
  ContaContabilTipo,
} from "../../../../features/contabilidade/conta-contabil/models/conta-contabil";
import { ContaContabilServico } from "../../../../features/contabilidade/conta-contabil/servicos/conta-contabil.service";
import { PermissoesContaContabil } from "../../../../models/permissoes/contabilidade/conta-contabil/permissoes-conta-contabil-b";
import {
  FormataDescricao,
  FormatadoresSelectBox,
} from "../../../../utils/formatadores/formatador-de-selectbox";
import { DataSourceFiltragem } from "../../../../utils/grid/data-source-factory";
import { FormSelectBoxLazyMxp } from "../../../formularios";
import { assertConfiguracaoExibicaoEBuscaType } from "../../../formularios/selectbox-lazy-mxp";

interface ComboContaContabilMxpProps<T extends FieldValues>
  extends UseControllerProps<T> {
  titulo: string;
  onSelectionChanged?: (e: any) => void;
  requerido?: boolean;
  incluirContasSinteticas?: boolean;
}

const configuracoesExibicaoSelectContaContabil =
  assertConfiguracaoExibicaoEBuscaType<ContaContabilGridModel>({
    expressaoDeBusca: ["codigo", "descricao", "classificacao"],
    nomeCampoChave: "id",
    nomeCampoExibicao: (c) => {
      if (c) {
        return FormataDescricao(
          FormatadoresSelectBox.ClassificacaoCodigoDescricaoEspacoParenteses,
          c.classificacao,
          c.descricao,
          c.codigo
        );
      }

      return "";
    },
  });

export default function ComboContaContabilMxp<T extends FieldValues>(
  props: ComboContaContabilMxpProps<T>
) {
  const filtrosPadroes = useMemo(() => {
    const filtros: DataSourceFiltragem<ContaContabilGridModel>[] = [];

    if (!props.incluirContasSinteticas) {
      filtros.push({
        campo: "tipo",
        operador: "=",
        valor: ContaContabilTipo.Analitica,
      });
    }

    return filtros;
  }, [props.incluirContasSinteticas]);

  const dataSource = useMemo(() => {
    return ContaContabilServico.ObterDataSourceParaSelectBoxLazy<ContaContabilGridModel>(
      {
        camposRetorno: [
          "id",
          "codigo",
          "descricao",
          "classificacao",
          "obrigatoriedadeCentroDeCustos",
        ],
        camposOrdenacao: [
          {
            campo: "classificacao",
            desc: false,
          },
        ],
        camposFiltro: [...filtrosPadroes],
      }
    );
  }, [filtrosPadroes]);

  return (
    <>
      <FormSelectBoxLazyMxp
        name={props.name}
        titulo={props.titulo}
        control={props.control}
        requerido={props.requerido ?? true}
        dataSource={dataSource}
        configuracoesExibicaoEBusca={configuracoesExibicaoSelectContaContabil}
        labelSemDados="Sem dados"
        seletorConfig={{
          modo: "selecaoUnica",
          titulo: "Selecionar conta contábil",
          componenteGrid: (
            <GridContaContabil filtrosNoServidor={filtrosPadroes} />
          ),
        }}
        onSelectionChanged={props.onSelectionChanged}
        permissoesNecessarias={[PermissoesContaContabil.Consultar]}
      />
    </>
  );
}
