import { DataGrid } from "devextreme-react";
import { Column, DataGridRef } from "devextreme-react/data-grid";
import { useMemo, useRef } from "react";
import GetColunasDeAuditoria from "../../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import criarNameof from "../../../../../../utils/common/cria-name-of";
import GridBuilder from "../../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../../utils/grid/padroes-colunas";
import { MovimentacaoDoImobilizadoGridModel } from "../../../models/movimentacao-imobilizado";
import { MovimentacaoDoImobilizadoServico } from "../../../servicos/movimentacao-imobilizado.service";

const nameOfGridHandler = criarNameof<MovimentacaoDoImobilizadoGridModel>();

interface GridInternalMovimentacaoDoImobilizadoProps {
  apuracaoId: number;
}

export default function GridInternalMovimentacaoDoImobilizado(
  props: GridInternalMovimentacaoDoImobilizadoProps
) {
  const gridRef = useRef<DataGridRef>(null);

  const gridController = new GridController<MovimentacaoDoImobilizadoGridModel>(
    () => gridRef.current?.instance()
  );

  const dataSource = useMemo(
    () =>
      MovimentacaoDoImobilizadoServico.ObterDataSourceParaGrid<MovimentacaoDoImobilizadoGridModel>(
        [
          {
            campo: "apuracaoId",
            operador: "=",
            valor: props.apuracaoId,
          },
        ]
      ),
    [props.apuracaoId]
  );

  const configuracoesGrid = useMemo(() => {
    if (!props.apuracaoId) {
      return;
    }

    return GridBuilder.criar("grid-interno-movimentacao-do-imobilizado", () =>
      gridRef.current?.instance()
    )
      .definirStyles({ height: "100%" })
      .definirDataSource(dataSource)
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirOrdenacao()
      .build();
  }, [props.apuracaoId]);

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  return (
    <>
      <DataGrid ref={gridRef} {...configuracoesGrid}>
        <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
        <Column
          dataField={nameOfGridHandler("apuracaoId")}
          {...obterConfiguracaoColuna("idPropridadeDeNavegacaoNaoVisivel")}
        />
        <Column
          dataField={nameOfGridHandler("imobilizado")}
          {...obterConfiguracaoColuna("stringGG")}
          caption="Imobilizado"
        />
        <Column
          dataField={nameOfGridHandler("tipoDaMovimentacao")}
          {...obterConfiguracaoColuna("stringG")}
          caption="Tipo da Movimentação"
        />
        <Column
          dataField={nameOfGridHandler("valorDoIcms")}
          {...obterConfiguracaoColuna("monetario")}
          caption="Valor do ICMS"
          width={115}
        />
        <Column
          dataField={nameOfGridHandler("valorDoIcmsSt")}
          {...obterConfiguracaoColuna("monetario")}
          caption="Valor do ICMS ST"
          width={135}
        />
        <Column
          dataField={nameOfGridHandler("valorDoIcmsDoFrete")}
          {...obterConfiguracaoColuna("monetario")}
          caption="Valor do ICMS do frete"
          width={160}
        />
        <Column
          dataField={nameOfGridHandler("valorDoIcmsDifal")}
          {...obterConfiguracaoColuna("monetario")}
          caption="Valor do ICMS difal"
          width={140}
        />
        <Column
          dataField={nameOfGridHandler("numeroDaParcela")}
          {...obterConfiguracaoColuna("codigoNumerico")}
          caption="Numero da parcela"
          width={140}
        />
        <Column
          dataField={nameOfGridHandler("valorDaParcelaDoIcms")}
          {...obterConfiguracaoColuna("monetario")}
          caption="Valor da parcela do ICMS"
          width={175}
        />
        {GetColunasDeAuditoria()}
      </DataGrid>
    </>
  );
}
