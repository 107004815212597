import PaginaMxp from "../../../../../components/layout/pagina-mxp";
import ProvedorMenus from "../../../../../components/menus/provedor-menus";
import RequerPermissao from "../../../../../components/seguranca/requer-permissao";
import { PermissoesMDF } from "../../../../../models/permissoes/fiscal/mdfe/permissoes-mdf";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import GridMDFe from "../../componentes/grid";

export default function MdfePage() {
  return (
    <ProvedorMenus>
      <PaginaMxp id="mdfe-page" titulo={NomesTelas.mdfe} area={["Fiscal"]}>
        <RequerPermissao codigoPermissoes={[PermissoesMDF.Consultar]}>
          <GridMDFe />
        </RequerPermissao>
      </PaginaMxp>
    </ProvedorMenus>
  );
}
