import { DataGridTypes } from "devextreme-react/data-grid";
import { InsumoDaOrdemDeProducaoGridModel } from "../../models/insumo-da-ordem-de-producao";
import { InsumoTipoBaixa } from "../../utils/enum/insumo-da-ordem-de-producao-enums";

export const ColunaInsumoFormataDescricao = (
  data: DataGridTypes.ColumnCellTemplateData
) => {
  const dadosLinha: InsumoDaOrdemDeProducaoGridModel = data.row.data;
  if (dadosLinha.tipoDeBaixa == InsumoTipoBaixa.NaoExigeBaixa) {
    return (
      <>
        <div className={`ic-text-cell-with-icon`}>
          <span className={`ic-text-cell`}>{dadosLinha.descricao}</span>
          <span
            className={`ic-column-icon ic-2x ic-material-symbols-outlined ic-block ic-warning`}
            title="O item não exige baixa"
          ></span>
        </div>
      </>
    );
  }

  if (dadosLinha.insumoPaiAlternativo) {
    return (
      <>
        <div className={`ic-text-cell-with-icon`}>
          <span className={`ic-text-cell`}>{dadosLinha.descricao}</span>
          <span
            className={`ic-column-icon ic-2x ic-material-symbols-outlined ic-alt-route ic-warning`}
            title="Insumo selecionado do item alternativo"
          ></span>
        </div>
      </>
    );
  }

  return <>{dadosLinha.descricao}</>;
};
