import { useFormContext } from "react-hook-form";
import {
  FormDateBox,
  FormNumberBox,
  FormSelectBox,
  FormTextBoxSimples,
} from "../../../../../../../components/formularios";
import FormTextArea from "../../../../../../../components/formularios/textarea";
import {
  Coluna,
  Linha,
} from "../../../../../../../components/layout/grid-system";
import TabContainer from "../../../../../../../components/layout/tab-container";
import {
  formatarNumero,
  obterFormatStringNumero,
} from "../../../../../../../utils/formatadores/formatador-de-numeros";
import {
  OrdemDeProducaoRequest,
  quantidadeDePerdaPrevistaMaxima,
  quantidadeDePerdaPrevistaMinima,
} from "../../../../models/ordem-de-producao.api";
import {
  EstocagemDoProdutoHelper,
  OrdemDeProducaoEstado,
} from "../../../../utils/enums/ordem-de-producao-enums";

export default function OrdemDeProducaoAbaOutrosDados() {
  const { control, watch } = useFormContext<OrdemDeProducaoRequest>();

  const quantidade = watch("quantidade");
  const quantidadeDePerdaPrevista = watch("quantidadeDePerdaPrevista");
  const quantidadeBruta =
    (quantidade == null || Number.isNaN(quantidade) ? 0 : quantidade) +
    (quantidadeDePerdaPrevista == null ||
    Number.isNaN(quantidadeDePerdaPrevista)
      ? 0
      : quantidadeDePerdaPrevista);

  return (
    <TabContainer>
      <Linha>
        <Coluna md={3}>
          <FormDateBox
            name={"inicioPrevistoData"}
            titulo="Início previsto"
            formatoExibicao="dd/MM/yyyy HH:mm:ss"
            somenteLeitura
            control={control}
          />
        </Coluna>
        <Coluna md={3}>
          <FormDateBox
            name={"fimPrevistoData"}
            titulo="Fim previsto"
            formatoExibicao="dd/MM/yyyy HH:mm:ss"
            somenteLeitura
            control={control}
          />
        </Coluna>
        <Coluna md={3}>
          <FormDateBox
            name={"inicioRealizadoData"}
            titulo="Início realizado"
            formatoExibicao="dd/MM/yyyy HH:mm:ss"
            somenteLeitura
            control={control}
          />
        </Coluna>
        <Coluna md={3}>
          <FormDateBox
            name={"fimRealizadoData"}
            titulo="Fim realizado"
            formatoExibicao="dd/MM/yyyy HH:mm:ss"
            somenteLeitura
            control={control}
          />
        </Coluna>
      </Linha>
      <Linha>
        <Coluna md={4}>
          <FormNumberBox
            name={"quantidadeDePerdaPrevista"}
            titulo="Perda prevista"
            formato={obterFormatStringNumero(5)}
            defaultValue={0}
            somenteLeitura={watch("estado") == OrdemDeProducaoEstado.Fechada}
            control={control}
            minimo={quantidadeDePerdaPrevistaMinima.toNumber()}
            maximo={quantidadeDePerdaPrevistaMaxima.toNumber()}
          />
        </Coluna>
        <Coluna md={4}>
          <FormTextBoxSimples
            titulo="Quantidade bruta"
            somenteLeitura
            valor={formatarNumero(quantidadeBruta, 5).toString()}
            alinharNaDireita={true}
          />
        </Coluna>
      </Linha>
      <Linha>
        <Coluna md={4}>
          <FormSelectBox
            name={"estocagemPor"}
            titulo="Estocagem por"
            labelSemDados="Selecione um item..."
            somenteLeitura={true}
            control={control}
            dataSource={EstocagemDoProdutoHelper.AsSelectItems()}
          />
        </Coluna>
      </Linha>
      <Linha>
        <Coluna md={12}>
          <FormTextArea
            name="observacoes"
            titulo={"Observações"}
            valor={""}
            control={control}
            height={25}
          />
        </Coluna>
      </Linha>
    </TabContainer>
  );
}
