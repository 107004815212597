import { TabPanelRef } from "devextreme-react/tab-panel";

export function definirIndiceSelecionadoTabPanel(
  tabPanel: TabPanelRef | null | undefined,
  indice: number
) {
  tabPanel?.instance()?.option("selectedIndex", indice);
}

export function obterIndiceSelecionadoTabPanel(
  tabPanel: TabPanelRef | null | undefined
) {
  return tabPanel?.instance()?.option("selectedIndex") ?? -1;
}
