import Button from "devextreme-react/button";
import { Template } from "devextreme-react/core/template";
import Toolbar, { Item } from "devextreme-react/toolbar";

import { Menu, MenuRef } from "devextreme-react/menu";
import "../../../themes/personalizacoes/header-menu/header-menu.scss";
import UserPanel from "../user-panel/user-panel";

import { TextBox } from "devextreme-react";
import { ItemRenderedEvent } from "devextreme/ui/menu";
import { EnterKeyEvent } from "devextreme/ui/text_box";
import { memo, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/img/logo_menu.svg";
import useEffectOnLoad from "../../../hooks/effect.hooks";
import { useAppSelector } from "../../../hooks/store.hooks";
import { Notificacao } from "../../../models/api/notificacoes/notificacao";
import { ColecaoAtalhos } from "../../../utils/atalhos/colecao-atalhos";
import { aguardar } from "../../../utils/common/common-utils";
import { adicionarMeses } from "../../../utils/common/date-utils";
import {
  abrirModalMxpAntigo,
  DadosLink,
  MenuItem,
  navegarParaMxpAntigo,
  navegarParaPaginaExterna,
} from "../../../utils/common/menu-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../utils/common/notificacoes-utils";
import AlertaSlide from "../../../utils/dialogos/renderAlerta";
import AssinaturaEstadoSlide from "../assinatura-estado";
import NotificacoesIcone from "../notificacoes/notificacoes";
import "./header-menu.scss";

interface IProps {
  menuToggleEnabled: boolean;
  userMenuItems?: any;
  title: string;
  irParaPaginaInicial: () => void;
}

const UserPanelMemo = memo(
  UserPanel,
  (prev, next) => prev.menuMode == next.menuMode
);

function handleEnterPesquisa(e: EnterKeyEvent) {
  const valor = e.component.option("value");

  const valorCodificado = encodeURIComponent(valor ?? "");

  window.open(`https://maxiprod.com.br/?s=${valorCodificado}&post_type=ajuda`);
}

function adicionarTextoAtalhoAoMenu(e: ItemRenderedEvent<any>) {
  if (!e) {
    return;
  }

  const dadosItemMenu = e.itemData as MenuItem;

  const divItem = e.itemElement.querySelector(
    ".dx-item-content.dx-menu-item-content"
  ) as HTMLElement;

  if (!divItem) {
    return;
  }

  const link = divItem.querySelector(".dx-item-url") as HTMLElement;

  if (!link) {
    return;
  }
  const textoExistente = link.querySelector(
    ".dx-menu-item-text.dx-menu-item-text-with-url"
  ) as HTMLElement;

  if (!textoExistente) {
    return;
  }

  if (
    dadosItemMenu.criadoEm &&
    adicionarMeses(dadosItemMenu.criadoEm, 1) > new Date()
  ) {
    const etiquetaNovo = document.createElement("span");
    etiquetaNovo.textContent = "novo!";
    etiquetaNovo.classList.add("novo-menu");
    textoExistente.appendChild(etiquetaNovo);
  }

  if (!dadosItemMenu.atalho) {
    return;
  }

  const textoExistenteCopia = textoExistente.cloneNode(true) as HTMLElement;
  textoExistente.remove();

  const textoAtalho = textoExistente.cloneNode(true) as HTMLElement;
  textoAtalho.innerText = ColecaoAtalhos.obterTextoAtalho(dadosItemMenu.atalho);

  textoExistenteCopia.setAttribute("style", "margin-right: 40px;");

  const novaDiv = document.createElement("div");
  novaDiv.classList.add("containerItemMenu");
  novaDiv.appendChild(textoExistenteCopia);
  novaDiv.appendChild(textoAtalho);

  link.appendChild(novaDiv);
}

export default function HeaderMenu({
  menuToggleEnabled,
  userMenuItems,
  title,
  irParaPaginaInicial,
}: IProps) {
  const [notificacaoSlide, setNotificacaoSlide] = useState<
    Notificacao | undefined
  >(undefined);

  const navigate = useNavigate();

  const apelidoUsuario = useAppSelector(
    (state) => state.sessao.dadosSessao?.usuario.codigo
  );
  const apelidoEmpresa = useAppSelector(
    (state) => state.sessao.dadosSessao?.empresa.apelido
  );
  const assinanteId = useAppSelector(
    (state) => state.sessao.dadosSessao?.assinanteId
  );

  const isUsuarioMaster = useAppSelector(
    (state) => state.sessao.dadosSessao?.usuario.isUsuarioMaster
  );

  const user = {
    email: apelidoUsuario,
    empresa: apelidoEmpresa,
    assinanteId: assinanteId,
  };

  const [
    botaoCopiarInformacoesAssinanteVisivel,
    setBotaoCopiarInformacoesAssinanteVisivel,
  ] = useState(false);

  function obterAcaoDeNavegacao(link: DadosLink | undefined): () => void {
    let acao: () => void;

    switch (link!.tipo) {
      case "linkLocal":
        acao = () => navigate(link!.rota);
        break;
      case "linkMxpAntigo":
        acao = () => navegarParaMxpAntigo(link!.rota);
        break;
      case "javaScriptMxpAntigo":
        acao = () => abrirModalMxpAntigo(link!);
        break;
      case "linkPaginaExterna":
        acao = () => navegarParaPaginaExterna(link!.rota);
        break;
    }

    return acao;
  }

  function VincularAtalhos(itens: MenuItem[]) {
    for (const item of itens) {
      if (item.items) {
        VincularAtalhos(item.items);
        continue;
      }

      if (item.link) {
        const acao = obterAcaoDeNavegacao(item.link!);
        if (item.atalho) {
          ColecaoAtalhos.registarAtalhosGlobais({
            ...item.atalho,
            acao: acao,
          });
        }
      }
    }
  }

  useEffectOnLoad(() => {
    VincularAtalhos(userMenuItems);
  });

  function realizarAjusteLinks(e: ItemRenderedEvent<any>) {
    if (!e) {
      return;
    }

    const dadosItemMenu = e.itemData as MenuItem;

    if (!dadosItemMenu.url || !dadosItemMenu.link) {
      return;
    }

    // Remove o link gerado pelo devexpress
    const divItem = e.itemElement.querySelector(
      ".dx-item-content.dx-menu-item-content"
    ) as HTMLElement;

    const linkGerado = divItem.querySelector(".dx-item-url") as HTMLElement;
    linkGerado.remove();

    const rota = dadosItemMenu.url;

    const link = document.createElement("a");
    link.classList.add("dx-item-url");
    link.href = rota;
    link.innerHTML = `<span class="dx-menu-item-text dx-menu-item-text-with-url">${e.itemData?.text}</span>`;

    link.addEventListener("click", function (e) {
      if (!e.isTrusted) {
        e.preventDefault();
        return;
      }

      if (!e.ctrlKey) {
        e.preventDefault();
        const acao = obterAcaoDeNavegacao(dadosItemMenu.link);
        acao();
      }
    });

    divItem.appendChild(link);
  }

  // O DevExpress não está gerando os links para o menu no modo adaptativo.
  // Esta função é um workaround para colocar um <a> com a url correta
  // ao redor do item do menu.
  function handleItemRenderedMenuHamburger(
    renderEvent: ItemRenderedEvent<any>
  ) {
    if (!renderEvent) {
      return;
    }

    const dadosItemMenu = renderEvent.itemData as MenuItem;
    if (!dadosItemMenu.link) {
      return;
    }

    const parent = renderEvent.itemElement.parentElement as HTMLElement;

    const divItem = renderEvent.itemElement;

    divItem.remove();

    const link = document.createElement("a");
    link.classList.add("dx-item-url");
    link.appendChild(divItem);

    const acao = obterAcaoDeNavegacao(dadosItemMenu.link);

    const rota = dadosItemMenu.link.rota;
    const tipo = dadosItemMenu.link.tipo;

    link.href =
      (tipo == "linkMxpAntigo"
        ? process.env.REACT_APP_BACKEND_ANTIGO_ENDPOINT
        : "") + rota;

    link.addEventListener("click", function (e) {
      if (!e.isTrusted) {
        e.preventDefault();
        return;
      }

      if (!e.ctrlKey) {
        e.preventDefault();
        acao();
      }
    });

    parent.appendChild(link);
  }

  function handleItemRenderedMenu(e: ItemRenderedEvent<any>) {
    if (menuToggleEnabled) {
      handleItemRenderedMenuHamburger(e);
      return;
    }

    const el = e.itemElement.closest(".dx-submenu") as HTMLElement;
    if (!el) {
      return;
    }

    realizarAjusteLinks(e);
    adicionarTextoAtalhoAoMenu(e);
  }

  const handleUserButtonAuthorizationContainerOnMouseEnter = (event: any) => {
    const target = event.target as HTMLElement;

    if (target.closest(".user-button.authorization")) {
      setBotaoCopiarInformacoesAssinanteVisivel(true);
    }
  };

  const handleUserButtonAuthorizationContainerOnMouseLeave = (event: any) => {
    const target = event.target as HTMLElement;

    if (target.closest(".user-button.authorization")) {
      setBotaoCopiarInformacoesAssinanteVisivel(false);
    }
  };

  const menuRef = useRef<MenuRef>(null);
  const containerMenuRef = useRef<HTMLDivElement>(null);

  // Tratativa para responsividade do menu, que por algum motivo, parou de funcionar nativamente na devexpress.
  useEffect(() => {
    aguardar(50).then(() => {
      const menuPrincipal =
        containerMenuRef.current?.querySelector("#menu-principal");

      if (!menuPrincipal) {
        return;
      }

      const menuHorizontal = menuPrincipal.querySelector(
        ".dx-menu-horizontal"
      ) as HTMLElement;
      const menuHamburger = menuPrincipal.querySelector(
        ".dx-menu-adaptive-mode"
      ) as HTMLElement;

      if (!menuHorizontal || !menuHamburger) {
        return;
      }
      if (menuToggleEnabled) {
        menuHorizontal.classList.add("dx-state-invisible");
        menuHamburger.classList.remove("dx-state-invisible");
      } else {
        menuHorizontal.classList.remove("dx-state-invisible");
        menuHamburger.classList.add("dx-state-invisible");
      }
    });
  }, [menuToggleEnabled, menuRef.current]);

  return (
    <>
      <AssinaturaEstadoSlide></AssinaturaEstadoSlide>
      <header
        className={`header-component ${
          menuToggleEnabled ? "" : "header-component-desktop"
        }`}
      >
        <Toolbar className={"header-toolbar"}>
          <Item
            location={"before"}
            template={"logoTemplate"}
            visible={!menuToggleEnabled}
          />
          <Item location={"before"} template={"menuTemplate"} />
          <Item
            location={"before"}
            template={"logoTemplate"}
            visible={menuToggleEnabled}
          />
          {!menuToggleEnabled && (
            <Item visible={!menuToggleEnabled} location={"before"}>
              <TextBox
                placeholder="Pesquisar na ajuda"
                mode="search"
                showClearButton={true}
                className="header-pesquisar-na-ajuda"
                onEnterKey={handleEnterPesquisa}
              ></TextBox>
            </Item>
          )}
          <Item location={"after"} locateInMenu={"auto"}>
            <NotificacoesIcone
              notificacaoSlide={notificacaoSlide}
              setNotificacaoSlide={setNotificacaoSlide}
            ></NotificacoesIcone>
          </Item>
          <Item
            location={"after"}
            locateInMenu={"auto"}
            menuItemTemplate={"userPanelTemplate"}
          >
            <div
              className={"user-button authorization-container"}
              onMouseEnter={handleUserButtonAuthorizationContainerOnMouseEnter}
              onMouseLeave={handleUserButtonAuthorizationContainerOnMouseLeave}
            >
              <Button
                type="normal"
                text={""}
                width={"fit-content"}
                height={"100%"}
                icon={"ic-copy-content ic-material-symbols-outlined"}
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${user?.empresa} (ID assinante: ${user.assinanteId})`
                  );
                  exibirNotificacaoToast({
                    mensagem: "Informações do assinante copiadas com sucesso!",
                    tipo: TipoNotificacao.Sucesso,
                  });
                }}
                visible={
                  isUsuarioMaster && botaoCopiarInformacoesAssinanteVisivel
                }
              />
              <Button
                className={"user-button authorization"}
                width={"fit-content"}
                height={"100%"}
                stylingMode={"text"}
              >
                <UserPanelMemo
                  menuMode={"context"}
                  irParaPaginaInicial={irParaPaginaInicial}
                />
              </Button>
            </div>
          </Item>
          <Template name={"userPanelTemplate"}>
            <UserPanelMemo
              menuMode={"list"}
              irParaPaginaInicial={irParaPaginaInicial}
            />
          </Template>
          <Template name="menuTemplate">
            <div ref={containerMenuRef}>
              <Menu
                ref={menuRef}
                dataSource={userMenuItems}
                showFirstSubmenuMode="onHover"
                showSubmenuMode="onHover"
                adaptivityEnabled={true}
                onItemRendered={handleItemRenderedMenu}
                id="menu-principal"
              />
            </div>
          </Template>
          <Template name="logoTemplate">
            <a href="#" title={title} onClick={() => irParaPaginaInicial()}>
              <img
                src={logo}
                style={{
                  paddingLeft: menuToggleEnabled ? "0" : "10px",
                  height: "20px",
                  margin: "10px",
                }}
                alt={title}
              />
            </a>
          </Template>
        </Toolbar>
      </header>
      <AlertaSlide
        notificacaoSlide={notificacaoSlide}
        setNotificacaoSlide={setNotificacaoSlide}
      ></AlertaSlide>
    </>
  );
}
