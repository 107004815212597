import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "devextreme-react/button";
import { Item, TabPanel } from "devextreme-react/tab-panel";
import ArrayStore from "devextreme/data/array_store";
import { ValueChangedEvent } from "devextreme/ui/check_box_types";
import { useCallback, useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import ProvedorAjuda from "../../../../../../components/ajuda/provedor-ajuda";
import BotaoCancelarMxp from "../../../../../../components/botoes/botao-cancelar-mxp";
import {
  FormCheckBox,
  FormDateBox,
  FormGrupo,
  FormNumberBox,
  FormTextBoxSimples,
} from "../../../../../../components/formularios";
import FormMxp from "../../../../../../components/layout/form";
import { ContainerFormMxp } from "../../../../../../components/layout/form/styles";
import { Coluna, Linha } from "../../../../../../components/layout/grid-system";
import ToolbarMxp from "../../../../../../components/layout/toolbar-mxp";
import { PermissoesReserva } from "../../../../../../models/permissoes/estoque/reserva/permissoes-reserva";
import { PermissoesOrdemDeProducao } from "../../../../../../models/permissoes/producao/ordem-de-producao/OrdemDeProducaoPermissoes";
import {
  IFormularioEditavelBase,
  ResultadoAcaoFormulario,
} from "../../../../../../models/shared/ui/formularios";
import {
  checarErrosDeConfirmacaoResponse,
  checarResponse,
  tratarErroApi,
  verificarSePossuiErroApiPorCodigo,
} from "../../../../../../utils/api/api-utils";
import {
  quantidadeValorMaximo,
  quantidadeValorMinimo,
} from "../../../../../../utils/common/constantes";
import { DateTimeMxp } from "../../../../../../utils/common/date-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../../utils/common/permissoes-utils";
import {
  FormataDescricao,
  FormatadoresSelectBox,
} from "../../../../../../utils/formatadores/formatador-de-selectbox";
import yup from "../../../../../../utils/validacao/validacao";
import { FinalidadeTipo } from "../../../../../contabilidade/conta-contabil/models/conta-contabil.enum";
import SelecionarEstoqueDestino, {
  yupCampoEmpresaDonaId,
  yupCampoEmpresaPosseId,
} from "../../../../../estoque/estoque-movimentacao/componentes/partes/selecionar-estoque-destino/selecionar-estoque-destino";
import { EstoqueMovimentacaoTipo } from "../../../../../estoque/estoque-movimentacao/models/estoque-movimentacao.enums";
import RastreabilidadePorLote, {
  novaRastreabilidadeLote,
  yupRastreabilidadeLote,
} from "../../../../../estoque/lote/componentes/formulario-cadastro-de-rastreabilidade";
import { ModalSelecionarReservas } from "../../../../../estoque/reserva/componentes/modal-selecionar-reservas";
import { ReservaServico } from "../../../../../estoque/reserva/service/reserva.service";
import { TipoDeEstocagemDoItem } from "../../../../../itens/item/models/item.enums";
import { ConcluirOrdemDeProducaoRequest } from "../../../models/ordem-de-producao.api";
import { OrdemDeProducaoServico } from "../../../servicos/ordem-de-producao.service";
import ContextoOrdemDeProducaoGrid from "../../provedor-contexto-ordem-de-producao/contexto-ordem-de-producao-grid";
import RastreabilidadePorNumeroDeSerie from "./selecionar-numeros-de-serie";

const novoRegistro: ConcluirOrdemDeProducaoRequest = {
  idOrdemDeProducao: 0,
  itemId: 0,
  quantidade: 0,
  itemEstocagemTipo: TipoDeEstocagemDoItem.CodigoDoItem,
  itemUsaQuantidadeInteira: false,
  fecharOp: false,
  forcarConclusao: false,
  destino: EstoqueMovimentacaoTipo.Estoque,
  idContaContabilDestino: undefined,
  finalidadeContaContabil: FinalidadeTipo.Estoque,
  empresaDonaId: undefined,
  empresaPosseId: undefined,
  codigoItem: "",
  codigoOrdemDeProducao: "",
  rastreabilidadeLote: novaRastreabilidadeLote,
  rastreabilidadeNumeroDeSerie: [],
  informarDataDeConclusao: false,
  dataDeConclusao: undefined,
  rastreabilidadeReserva: [],
};

let fecharOp: boolean = true;
let quantidadeDaOp: number = 0;

export default function FormConcluirOrdemDeProducao(
  props: IFormularioEditavelBase
) {
  const schema = yup.object<ConcluirOrdemDeProducaoRequest>().shape({
    idOrdemDeProducao: yup.number().required().positive().integer(),
    itemId: yup.number().required().positive().integer(),
    quantidade: yup
      .number()
      .required()
      .lessThan(quantidadeValorMaximo.toNumber())
      .test(
        "is-integer",
        "Quantidade deve ser um número inteiro",
        function (value) {
          const { itemUsaQuantidadeInteira } = this.parent;
          return itemUsaQuantidadeInteira ? Number.isInteger(value) : true;
        }
      )
      .when(["fecharOp"], (valores, schema) => {
        const [fecharOp] = valores as [boolean];
        if (fecharOp) {
          return schema.min(quantidadeValorMinimo.toNumber());
        } else {
          return schema.moreThan(quantidadeValorMinimo.toNumber());
        }
      }),
    destino: yup
      .number()
      .oneOf([
        EstoqueMovimentacaoTipo.Estoque,
        EstoqueMovimentacaoTipo.ContaContabil,
      ]),
    idContaContabilDestino: yup.number().required().positive().integer(),
    empresaDonaId: yupCampoEmpresaDonaId(),
    empresaPosseId: yupCampoEmpresaPosseId(),
    rastreabilidadeLote: yup
      .object()
      .when(["fecharOp", "quantidade"], (valores, schema) => {
        const [fecharOp, quantidade] = valores;
        if (fecharOp && quantidade == 0) {
          return schema.notRequired();
        } else {
          return yupRastreabilidadeLote(
            (): TipoDeEstocagemDoItem => getValues("itemEstocagemTipo")
          );
        }
      }),
    dataDeConclusao: yup
      .string()
      .test(
        "data_valida",
        "Não é possível informar uma data futura.",
        function (data) {
          if (data) {
            const dataAtual = DateTimeMxp.Now();
            const dataDeConclusao =
              DateTimeMxp.ConverterDataParaFusoBrasilia(data);

            return dataDeConclusao <= dataAtual;
          }
          return true;
        }
      )
      .when("informarDataDeConclusao", {
        is: true,
        then: (sch) => {
          return sch.required();
        },
        otherwise: (sch) => {
          return sch.notRequired();
        },
      }),
  });

  const hookForm = useForm<ConcluirOrdemDeProducaoRequest>({
    resolver: yupResolver(schema),
  });

  const { register, control, getValues, handleSubmit, reset, watch, setValue } =
    hookForm;

  const { atualizaGridDeOrdemDeProducao } = useContext(
    ContextoOrdemDeProducaoGrid
  );

  const [dataSourceSelecionarReserva, setDataSourceSelecionarReserva] =
    useState<ArrayStore | undefined>(undefined);

  //Hook usado para carregar os dados da tela
  useEffect(() => {
    if (Number.isNaN(props.idRegistroEmEdicao)) {
      return;
    }

    preencherTela();

    return () => limparTela();
  }, [props.idRegistroEmEdicao]);

  async function preencherTela() {
    if (props.idRegistroEmEdicao > 0) {
      await carregarRegistroDoId();
    } else {
      limparTela();
    }
  }

  async function carregarRegistroDoId() {
    try {
      // Obter dados para concluir a ordem de produção
      const resposta =
        await OrdemDeProducaoServico.ObterDadosParaConcluirOrdemDeProducao(
          props.idRegistroEmEdicao
        );
      checarResponse(resposta);

      if (!resposta.sucesso) {
        return;
      }

      const model = resposta.model;

      // Atualizar rastreabilidade do lote
      model.rastreabilidadeLote = novaRastreabilidadeLote;
      if (model.rastreabilidadeLoteId !== undefined) {
        model.rastreabilidadeLote.criarNovo = false;
        model.rastreabilidadeLote.loteId = model.rastreabilidadeLoteId;
      }

      // Atualizar variáveis globais
      quantidadeDaOp = model.quantidade;
      fecharOp = model.fecharOp;

      // Resetar o formulário com o modelo atualizado
      reset(model);
      setValue("informarDataDeConclusao", false);
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  function limparTela() {
    reset(novoRegistro);
  }

  async function handleSalvar() {
    const model = getValues();
    try {
      const podeConcluir =
        await OrdemDeProducaoServico.VerificarSePodeConcluirOpSemForcarConclusao(
          model
        );
      if (!podeConcluir.sucesso) {
        const confirmacaoForcar = await checarErrosDeConfirmacaoResponse(
          podeConcluir
        );
        if (!confirmacaoForcar) {
          return;
        }
        model.forcarConclusao = true;
      }
      const resposta = await OrdemDeProducaoServico.Concluir(model);
      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: "Conclusão de ordem de produção realizada com sucesso.",
          tipo: TipoNotificacao.Sucesso,
        });

        atualizaGridDeOrdemDeProducao();

        fechar(ResultadoAcaoFormulario.AcaoConcluida);
      }
    } catch (erro) {
      const precisaSelecionarEstoques = verificarSePossuiErroApiPorCodigo(
        erro,
        "MSG-012-0028"
      );

      if (precisaSelecionarEstoques) {
        if (
          !verificaComNotificacaoSeUsuarioPossuiPermissoes([
            PermissoesReserva.ConsultarReservasEProjecoesDeUso,
          ])
        ) {
          return;
        }

        const reservasDaOp =
          await ReservaServico.ObterReservasParaSelecionarPorIdOrdemDeProducao(
            model.idOrdemDeProducao,
            model.quantidade
          );

        if (!reservasDaOp) {
          return;
        }

        setDataSourceSelecionarReserva(
          new ArrayStore({
            data: reservasDaOp,
            key: "id",
          })
        );
        return;
      }

      tratarErroApi(erro, callBackUnprocessableEntity);
    }
  }

  function fechar(resultado: ResultadoAcaoFormulario) {
    limparTela();
    props.handleCallback(resultado);
  }

  function callBackUnprocessableEntity() {
    fechar(ResultadoAcaoFormulario.AcaoConcluida);
  }

  function handleCancelar() {
    fechar(ResultadoAcaoFormulario.AcaoCancelada);
  }

  useEffect(() => {
    if (!fecharOp) {
      return;
    }

    setValue("fecharOp", getValues("quantidade") >= quantidadeDaOp);
  }, [setValue, getValues, watch("quantidade")]);

  const handleValueChangeInformarDataDeConclusao = useCallback(
    async (event: ValueChangedEvent) => {
      if (!event.value) {
        setValue("dataDeConclusao", undefined);
        return;
      }

      if (event.value == true) {
        setValue("dataDeConclusao", DateTimeMxp.NowEmIso());
      }
    },
    [setValue]
  );

  const fecharModal = () => {
    setDataSourceSelecionarReserva(undefined);
    fechar(ResultadoAcaoFormulario.AcaoConcluida);
  };

  return (
    <ContainerFormMxp>
      <FormMxp>
        <FormProvider {...hookForm}>
          <ProvedorAjuda id="edit-form-concluir-ordem-de-producao">
            <input
              type="hidden"
              {...register("idOrdemDeProducao")}
              defaultValue={0}
            />
            <input type="hidden" {...register("itemId")} defaultValue={0} />
            <input type="hidden" {...register("codigoItem")} />
            <input type="hidden" {...register("codigoOrdemDeProducao")} />
            <FormGrupo>
              <Linha>
                <Coluna md={7}>
                  <FormTextBoxSimples
                    titulo="Ordem de produção"
                    requerido
                    valor={FormataDescricao(
                      FormatadoresSelectBox.CodigoDescricaoTraco,
                      getValues("codigoOrdemDeProducao"),
                      getValues("codigoItem")
                    )}
                    somenteLeitura
                  />
                </Coluna>
                <Coluna md={3}>
                  <FormNumberBox
                    name="quantidade"
                    titulo="Quantidade"
                    control={control}
                    minimo={quantidadeValorMinimo.toNumber()}
                    maximo={quantidadeValorMaximo.toNumber()}
                    somenteLeitura={
                      watch("itemEstocagemTipo") ==
                      TipoDeEstocagemDoItem.NumeroDeSerie
                    }
                    requerido
                  />
                </Coluna>
                <Coluna md={2} centralizar>
                  <FormCheckBox
                    name="fecharOp"
                    titulo="Fechar"
                    control={control}
                  />
                </Coluna>
              </Linha>
              <SelecionarEstoqueDestino hookForms={hookForm as any} />
              <Linha style={{ paddingTop: ".5rem" }}>
                <Coluna md={3} centralizar>
                  <FormCheckBox
                    control={control}
                    name="informarDataDeConclusao"
                    titulo="Informar data de conclusão"
                    onValueChanged={handleValueChangeInformarDataDeConclusao}
                    permissoesNecessarias={[
                      PermissoesOrdemDeProducao.ConcluirComDataRetroativa,
                    ]}
                  />
                </Coluna>
                <Coluna md={4}>
                  <FormDateBox
                    name="dataDeConclusao"
                    titulo="Data e hora da conclusão"
                    requerido={watch("informarDataDeConclusao")}
                    control={control}
                    exibirBotaoLimpar
                    tipo="datetime"
                    aceitaValorCustomizado={true}
                    formatoExibicao="dd/MM/yyyy HH:mm:ss"
                    aceitaDigitacaoComMascara={true}
                    visivel={watch("informarDataDeConclusao")}
                  />
                </Coluna>
              </Linha>
              {(watch("itemEstocagemTipo") ==
                TipoDeEstocagemDoItem.LoteDoFabricante ||
                watch("itemEstocagemTipo") ==
                  TipoDeEstocagemDoItem.NumeroDeSerie) && (
                <TabPanel
                  deferRendering={false}
                  showNavButtons
                  swipeEnabled={false}
                  itemTitleRender={(item) => item.text}
                >
                  {watch("itemEstocagemTipo") ==
                    TipoDeEstocagemDoItem.LoteDoFabricante && (
                    <Item text="Rastreabilidade">
                      <RastreabilidadePorLote hookForms={hookForm as any} />
                    </Item>
                  )}
                  {watch("itemEstocagemTipo") ==
                    TipoDeEstocagemDoItem.NumeroDeSerie && (
                    <Item text="Rastreabilidade">
                      <RastreabilidadePorNumeroDeSerie />
                    </Item>
                  )}
                </TabPanel>
              )}
            </FormGrupo>
          </ProvedorAjuda>
        </FormProvider>
      </FormMxp>
      <ModalSelecionarReservas
        dados={dataSourceSelecionarReserva}
        dadosConclusao={getValues() as ConcluirOrdemDeProducaoRequest}
        fecharModal={fecharModal}
        callBackUnprocessableEntity={callBackUnprocessableEntity}
      />
      <ToolbarMxp>
        <Button
          type="success"
          text={"Concluir"}
          icon={"save"}
          onClick={handleSubmit(handleSalvar) as any}
        />
        <BotaoCancelarMxp handleClick={handleCancelar} />
      </ToolbarMxp>
    </ContainerFormMxp>
  );
}
