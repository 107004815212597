import { yupResolver } from "@hookform/resolvers/yup";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import BotaoCancelarMxp from "../../../../../../components/botoes/botao-cancelar-mxp";
import BotaoSalvarMxp from "../../../../../../components/botoes/botao-salvar-mxp";
import {
  FormGrupo,
  FormNumberBox,
  FormSelectBoxLazy,
} from "../../../../../../components/formularios";
import { Coluna, Linha } from "../../../../../../components/layout/grid-system";
import { ModalMxp } from "../../../../../../components/layout/modal-mxp";
import { PainelCarregando } from "../../../../../../components/layout/painel-carregamento";
import ToolbarMxp from "../../../../../../components/layout/toolbar-mxp";
import {
  IFormularioEditavelBase,
  ResultadoAcaoFormulario,
} from "../../../../../../models/shared/ui/formularios";
import { AdicionarCarregamentoViewModel } from "../../../../../../models/viewmodels/vendas/mdfe/mdfe-edit-form-view-model";
import APIMunicipio from "../../../../../../services/municipio/municipio.service";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../../utils/common/notificacoes-utils";
import { obterFormatStringNumero } from "../../../../../../utils/formatadores/formatador-de-numeros";
import {
  FormataDescricao,
  FormatadoresSelectBox,
} from "../../../../../../utils/formatadores/formatador-de-selectbox";
import yup from "../../../../../../utils/validacao/validacao";
import MDFeEditFormContext from "../../../contexts/mdfe-editform.context";
import { MDFeCarregamentoDTO } from "../../../models/mdfe";

interface ModalAdicionarCarregamentoProps extends IFormularioEditavelBase {
  visivel: boolean;
}

const exibeMunicipio = (c: any) => {
  if (c) {
    return FormataDescricao(
      FormatadoresSelectBox.CodigoDescricaoParenteses,
      c.Descricao,
      c.SiglaUf
    );
  }

  return "";
};

const municipioExpressaoDeBusca = ["Descricao", "SiglaUf"];

export default function ModalAdicionarCarregamento({
  handleCallback,
  visivel,
}: ModalAdicionarCarregamentoProps) {
  const [carregando, setCarregando] = useState(false);
  const form = useRef<HTMLFormElement | null>(null);

  const { carregamentos, ufsAtuais, adicionarCarregamento } =
    useContext(MDFeEditFormContext);

  const dataSourceMunicipio = useMemo(
    () =>
      APIMunicipio.getDataSourceSelectBoxLazy({
        camposRetorno: ["Id", "Descricao", "SiglaUf"],
        filtros: ufsAtuais.inicio
          ? [
              {
                nomeCampo: "IdUf",
                operador: "=",
                valor: ufsAtuais.inicio.toString(),
              },
            ]
          : undefined,
        camposOrdenacao: [
          {
            nomeCampo: "Descricao",
            desc: false,
          },
        ],
      }),
    [ufsAtuais.inicio]
  );

  const dataSourceMunicipioConsulta = useMemo(
    () =>
      APIMunicipio.getDataSourceSelectBoxLazy({
        camposRetorno: ["Id", "Descricao", "SiglaUf"],
        filtros: ufsAtuais.inicio
          ? [
              {
                nomeCampo: "IdUf",
                operador: "=",
                valor: ufsAtuais.inicio.toString(),
              },
            ]
          : undefined,
        camposOrdenacao: [
          {
            nomeCampo: "Descricao",
            desc: false,
          },
        ],
      }),
    [ufsAtuais.inicio]
  );

  const schema = useMemo(
    () =>
      yup.object().shape({
        idMunicipio: yup
          .number()
          .required()
          .positive()
          .integer()
          .transform((v) => (v ? v : null))
          .test({
            message:
              "O município informado já existe na lista de carregamentos.",
            test: (v) => {
              return carregamentos.every((x) => x.idMunicipio != v);
            },
          }),
        ordem: yup
          .number()
          .required()
          .integer()
          .moreThan(0)
          .max(255)
          .test({
            message: "A ordem já existe na lista de carregamentos.",
            test: (v) => {
              return carregamentos.every((x) => x.ordem != v);
            },
          }),
      }),
    [carregamentos]
  );

  const { getValues, setValue, reset, control, handleSubmit } =
    useForm<AdicionarCarregamentoViewModel>({
      mode: "all",
      reValidateMode: "onChange",
      resolver: yupResolver(schema),
    });

  useEffect(() => {
    reset();
    let novaOrdem = Math.max(...carregamentos.map((x) => x.ordem)) + 1;

    novaOrdem = novaOrdem == Infinity * -1 ? 1 : novaOrdem;

    setValue("ordem", novaOrdem);
  }, [visivel]);

  const handleSalvar = useCallback(async () => {
    try {
      setCarregando(true);
      const model = getValues();
      dataSourceMunicipioConsulta.filter(["Id", "=", model.idMunicipio]);
      await dataSourceMunicipioConsulta.load();
      const dado = dataSourceMunicipioConsulta.items()[0];

      const addmodel: MDFeCarregamentoDTO = {
        id: 0,
        idMunicipio: model.idMunicipio,
        ordem: model.ordem,
        municipio: {
          uf: dado.SiglaUf,
          nome: dado.Descricao,
        },
      };

      adicionarCarregamento(addmodel);
      exibirNotificacaoToast({
        mensagem: `Município ${addmodel.municipio.nome} adicionado com sucesso. Clique em "Salvar" para confirmar a operação.`,
        tipo: TipoNotificacao.Advertencia,
      });

      if (handleCallback) {
        handleCallback(ResultadoAcaoFormulario.AcaoConcluida);
      }
    } finally {
      setCarregando(false);
    }
  }, [
    handleCallback,
    adicionarCarregamento,
    dataSourceMunicipioConsulta,
    getValues,
  ]);

  const efetuarSubmit = useCallback(() => {
    form.current?.requestSubmit();
  }, [form]);

  const handleCancelar = useCallback(() => {
    if (handleCallback) {
      handleCallback(ResultadoAcaoFormulario.AcaoCancelada);
    }
  }, [handleCallback]);

  return (
    <>
      <ModalMxp
        visivel={visivel}
        handleFechar={handleCancelar}
        titulo="Adicionar carregamento"
        largura={"70%"}
        altura={"auto"}
      >
        <form
          style={{
            overflow: "auto",
            padding: "5px 15px",
            margin: "-15px -15px 0px -15px",
            flexGrow: 2,
            flexShrink: 2,
          }}
          ref={form}
          onSubmit={handleSubmit(handleSalvar)}
        >
          <PainelCarregando visivel={carregando} />
          <FormGrupo>
            <Linha>
              <Coluna md={9}>
                <FormSelectBoxLazy
                  name="idMunicipio"
                  titulo="Município"
                  toolTip="Município"
                  control={control}
                  dataSource={dataSourceMunicipio}
                  nomeCampoChave="Id"
                  nomeCampoExibicao={exibeMunicipio}
                  expressaoDeBusca={municipioExpressaoDeBusca}
                />
              </Coluna>
              <Coluna md={3}>
                <FormNumberBox
                  name="ordem"
                  titulo="Ordem"
                  toolTip="Ordem"
                  control={control}
                  minimo={1}
                  maximo={255}
                  formato={obterFormatStringNumero(0)}
                  alinharNaDireita={false}
                />
              </Coluna>
            </Linha>
          </FormGrupo>
        </form>
        <ToolbarMxp>
          <BotaoSalvarMxp handleClick={efetuarSubmit} />
          <BotaoCancelarMxp handleClick={handleCancelar} />
        </ToolbarMxp>
      </ModalMxp>
    </>
  );
}
