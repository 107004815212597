import { DataGridRef } from "devextreme-react/data-grid";
import { createContext } from "react";
import { InsumoDaOrdemDeProducaoGridModel } from "../../models/insumo-da-ordem-de-producao";
import { ModalBaixarInsumosProps } from "../modal-baixar-insumo";
import { ModalDetalharEstoqueInsumoProps } from "../modal-detalhar-estoque-insumo";

export type FuncaoDeInsumo = (insumo: InsumoDaOrdemDeProducaoGridModel) => void;

export class FuncoesColunaQuantidadeABaixarInsumo {
  public setRegistroEstornar: FuncaoDeInsumo;
  public setInsumoPorNumeroDeSerie: FuncaoDeInsumo;
  public setModalBaixarInsumosProps: (x: ModalBaixarInsumosProps) => void;
  private atualizaGridInsumoAoFechar: () => void;
  private atualizaGridInsumoPorNumeroDeSerieAoFechar: () => void;
  public atualizaGridsAoFechar: () => void;

  constructor() {
    this.setModalBaixarInsumosProps = () => {
      undefined;
    };
    this.setModalBaixarInsumosProps = (x: ModalBaixarInsumosProps) => {
      x;
    };
    this.setRegistroEstornar = (insumo: InsumoDaOrdemDeProducaoGridModel) => {
      insumo;
    };
    this.setInsumoPorNumeroDeSerie = (
      insumo: InsumoDaOrdemDeProducaoGridModel
    ) => {
      insumo;
    };

    this.atualizaGridInsumoAoFechar = () => {
      undefined;
    };

    this.atualizaGridInsumoPorNumeroDeSerieAoFechar = () => {
      undefined;
    };

    this.atualizaGridsAoFechar = () => {
      this.atualizaGridInsumoAoFechar();
      this.atualizaGridInsumoPorNumeroDeSerieAoFechar();
    };
  }

  definfirSetRegistroEstornar(x: FuncaoDeInsumo) {
    this.setRegistroEstornar = x;
  }

  definfirSetInsumoPorNumeroDeSerie(x: FuncaoDeInsumo) {
    this.setInsumoPorNumeroDeSerie = x;
  }

  definirSetModalBaixarInsumosProps(y: (x: ModalBaixarInsumosProps) => void) {
    this.setModalBaixarInsumosProps = y;
  }

  definirAtualizaGridInsumoAoFechar(x: () => void) {
    this.atualizaGridInsumoAoFechar = x;
  }

  definirAtualizaGridInsumoPorNumeroDeSerieAoFechar(x: () => void) {
    this.atualizaGridInsumoPorNumeroDeSerieAoFechar = x;
  }
}

export class ReferenciasGridsSelecaoInsumo {
  public referenciaGridSelecaoEstoqueBaixaInsumo:
    | React.RefObject<DataGridRef>
    | undefined;

  constructor() {
    this.referenciaGridSelecaoEstoqueBaixaInsumo = undefined;
  }
  definirReferenciaGridSelecaoEstoqueBaixaInsumo(
    x: React.RefObject<DataGridRef>
  ) {
    this.referenciaGridSelecaoEstoqueBaixaInsumo = x;
  }
}

export class DetalhamentoEstoqueInsumo {
  public setModalDetalharEstoqueInsumoProps: (
    x: ModalDetalharEstoqueInsumoProps
  ) => void;

  constructor() {
    this.setModalDetalharEstoqueInsumoProps = () => {
      undefined;
    };
  }

  definirsetModalDetalharEstoqueInsumoProps(
    y: (x: ModalDetalharEstoqueInsumoProps) => void
  ) {
    this.setModalDetalharEstoqueInsumoProps = y;
  }
}

interface ContextoOperacoesInsumoProps {
  funcoes: FuncoesColunaQuantidadeABaixarInsumo;
  referenciasGridInsumos: ReferenciasGridsSelecaoInsumo;
  detalhamentoEstoqueInsumo: DetalhamentoEstoqueInsumo;
}

const ContextoOperacoesInsumo = createContext<ContextoOperacoesInsumoProps>(
  {} as ContextoOperacoesInsumoProps
);

export default ContextoOperacoesInsumo;
