import ArrayStore from "devextreme/data/array_store";
import { useCallback, useContext, useEffect, useState } from "react";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import GridSelecaoEstoque from "../../../../estoque/estoque/componentes/grids/grid-selecao-de-estoque";
import { InsumoDaOrdemDeProducaoServico } from "../../servicos/insumo-da-ordem-de-producao";
import ContextoOperacoesInsumo from "../contexto-funcoes-insumo";

export interface ModalDetalharEstoqueInsumoProps {
  visivel: boolean;
  insumoId: number;
}

const ArrayStoreVazio = new ArrayStore({
  data: [],
  key: "id",
});

export default function ModalDetalharEstoqueInsumo() {
  const [modalDetalharEstoqueInsumoProps, setModalDetalharEstoqueInsumoProps] =
    useState<ModalDetalharEstoqueInsumoProps | null>(null);

  const { detalhamentoEstoqueInsumo } = useContext(ContextoOperacoesInsumo);

  const acaoAtualizarGrid = useCallback(async () => {
    const estoquesResponse =
      await InsumoDaOrdemDeProducaoServico.obterEstoquesDisponiveisParaInsumo(
        modalDetalharEstoqueInsumoProps?.insumoId ?? 0,
        0
      );

    if (estoquesResponse.sucesso) {
      const dados = new ArrayStore({
        data: estoquesResponse.model,
        key: "id",
      });
      setDataSource(dados);
    } else {
      setDataSource(ArrayStoreVazio);
    }
  }, [modalDetalharEstoqueInsumoProps?.insumoId]);

  useEffect(() => {
    detalhamentoEstoqueInsumo.definirsetModalDetalharEstoqueInsumoProps(
      (prop) => {
        setModalDetalharEstoqueInsumoProps(prop);
      }
    );

    if (modalDetalharEstoqueInsumoProps?.visivel == true) {
      acaoAtualizarGrid();
    } else {
      setDataSource(ArrayStoreVazio);
    }
  }, [
    modalDetalharEstoqueInsumoProps?.insumoId,
    modalDetalharEstoqueInsumoProps?.visivel,
    acaoAtualizarGrid,
    detalhamentoEstoqueInsumo,
  ]);

  const handleFechar = useCallback(() => {
    setModalDetalharEstoqueInsumoProps(null);
  }, []);

  const [dataSource, setDataSource] = useState<ArrayStore>(ArrayStoreVazio);

  return (
    <ModalMxp
      titulo={"Múltiplas origens do estoque do insumo"}
      visivel={modalDetalharEstoqueInsumoProps?.visivel ?? false}
      handleFechar={handleFechar}
    >
      {modalDetalharEstoqueInsumoProps?.visivel && (
        <GridSelecaoEstoque
          dataSource={dataSource}
          acaoAtualizaGrid={acaoAtualizarGrid}
          deveExibirColunaEditavelDeQuantidade={false}
        ></GridSelecaoEstoque>
      )}
    </ModalMxp>
  );
}
