import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";

class MovimentacaoDoImobilizadoService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.MovimentacaoDoImobilizado);
  }
}

export const MovimentacaoDoImobilizadoServico =
  new MovimentacaoDoImobilizadoService();
