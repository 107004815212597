import TabContainer from "../../../../../../../components/layout/tab-container";
import ProvedorMenus from "../../../../../../../components/menus/provedor-menus";
import { gridEmbutidaStyle } from "../../../../../../comum/utils/styles/grid";
import GridEmbutidaInsumoDaOrdemDeProducao from "../../../../../insumo-da-ordem-de-producao/componentes/grid-embutida/insumo-da-ordem-de-producao-grid-embutida";
import { ProvedorInsumo } from "../../../../../insumo-da-ordem-de-producao/componentes/provedor-insumo";
import { OrdemDeProducaoEstado } from "../../../../utils/enums/ordem-de-producao-enums";

interface OrdemDeProducaoAbaInsumosProps {
  idRegistro: number;
  ordemDeProducaoEstado: OrdemDeProducaoEstado | undefined;
}

export default function OrdemDeProducaoAbaInsumos({
  idRegistro,
  ordemDeProducaoEstado,
}: OrdemDeProducaoAbaInsumosProps) {
  return (
    <TabContainer>
      <ProvedorInsumo>
        <ProvedorMenus>
          <GridEmbutidaInsumoDaOrdemDeProducao
            ordemDeProducaoId={idRegistro}
            ordemDeProducaoEstado={ordemDeProducaoEstado}
            style={gridEmbutidaStyle}
          />
        </ProvedorMenus>
      </ProvedorInsumo>
    </TabContainer>
  );
}
