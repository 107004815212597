import { Control } from "react-hook-form";
import {
  FormSelectBox,
  FormSelectBoxLazyMxp,
} from "../../../../../../components/formularios";
import { assertConfiguracaoExibicaoEBuscaType } from "../../../../../../components/formularios/selectbox-lazy-mxp";
import { Coluna, Linha } from "../../../../../../components/layout/grid-system";
import TabContainer from "../../../../../../components/layout/tab-container";
import { PermissoesCentroDeTrabalhoGrupo } from "../../../../../../models/permissoes/producao/grupo-centro-de-trabalho/permissoes-grupo-centro-de-trabalho";
import {
  FormataDescricao,
  FormatadoresSelectBox,
} from "../../../../../../utils/formatadores/formatador-de-selectbox";
import GridGrupoDeCentroDeTrabalho from "../../../../grupo-de-centro-de-trabalho/componentes/grid";
import { CentroDeTrabalhoGrupoGridModel } from "../../../../grupo-de-centro-de-trabalho/models/grupo-de-centro-de-trabalho.api";
import { GrupoDeCentroDeTrabalhoServico } from "../../../../grupo-de-centro-de-trabalho/servicos/grupo-de-centro-de-trabalho.service";
import { CentroDeTrabalhoRequestDTO } from "../../../models/centro-de-trabalho.api";
import { TipoSequenciamentoHelper } from "../../../models/centro-de-trabalho.enums";

interface CapacidadeFinitaProps {
  control: Control<CentroDeTrabalhoRequestDTO, any>;
}

const tipoSequenciamentoSelectItem =
  TipoSequenciamentoHelper.tipoSequenciamentoAsSelectItem;

const gruposCentroDeTrabalhoDataSource =
  GrupoDeCentroDeTrabalhoServico.ObterDataSourceParaSelectBoxLazy<CentroDeTrabalhoGrupoGridModel>(
    {
      camposRetorno: ["id", "codigo", "descricao"],
      camposOrdenacao: [
        {
          campo: "codigo",
          desc: false,
        },
        {
          campo: "descricao",
          desc: false,
        },
      ],
    }
  );

const exibicaoEBuscaGrupoCentroDeTrabalho =
  assertConfiguracaoExibicaoEBuscaType<CentroDeTrabalhoGrupoGridModel>({
    nomeCampoChave: "id",
    nomeCampoExibicao: (c) => {
      if (c) {
        return c.descricao == null
          ? FormataDescricao(FormatadoresSelectBox.SomenteDescricao, c.codigo)
          : FormataDescricao(
              FormatadoresSelectBox.CodigoDescricaoParenteses,
              c.codigo,
              c.descricao
            );
      }

      return "";
    },
    expressaoDeBusca: ["codigo", "descricao"],
  });

export default function CentroDeTrabalhoAbaCapacidadeFinita({
  control,
}: CapacidadeFinitaProps) {
  return (
    <TabContainer>
      <Linha>
        <Coluna md={6}>
          <FormSelectBoxLazyMxp
            name="centroDeTrabalhoGrupoId"
            titulo="Grupo do centro de trabalho"
            control={control}
            configuracoesExibicaoEBusca={exibicaoEBuscaGrupoCentroDeTrabalho}
            dataSource={gruposCentroDeTrabalhoDataSource}
            seletorConfig={{
              modo: "selecaoUnica",
              titulo: "Selecionar o grupo de centro de trabalho",
              componenteGrid: <GridGrupoDeCentroDeTrabalho />,
            }}
            labelSemDados="Sem dados"
            permissoesNecessarias={[PermissoesCentroDeTrabalhoGrupo.Consultar]}
          />
        </Coluna>
        <Coluna md={6}>
          <FormSelectBox
            name="sequenciamentoEstaFixo"
            titulo="Sequenciamento"
            control={control}
            dataSource={tipoSequenciamentoSelectItem}
            habilitaBusca
            tipoBusca="contains"
          />
        </Coluna>
      </Linha>
    </TabContainer>
  );
}
