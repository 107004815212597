import { yupResolver } from "@hookform/resolvers/yup";
import TabPanel, { Item } from "devextreme-react/tab-panel";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import ProvedorAjuda from "../../../../../../components/ajuda/provedor-ajuda";
import BotaoCancelarMxp from "../../../../../../components/botoes/botao-cancelar-mxp";
import {
  FormDateBox,
  FormTextBoxSimples,
} from "../../../../../../components/formularios";
import FormMxp from "../../../../../../components/layout/form";
import { ContainerFormMxp } from "../../../../../../components/layout/form/styles";
import { Coluna, Linha } from "../../../../../../components/layout/grid-system";
import TabContainer from "../../../../../../components/layout/tab-container";
import ToolbarMxp from "../../../../../../components/layout/toolbar-mxp";
import { useAppSelector } from "../../../../../../hooks/store.hooks";
import AuditavelDTO from "../../../../../../models/api/comum/auditavel-dto";
import {
  IFormularioEditavelBase,
  ResultadoAcaoFormulario,
} from "../../../../../../models/shared/ui/formularios";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../../utils/api/api-utils";
import { formatarCpfCnpj } from "../../../../../../utils/formatadores/formatador-de-strings";
import yup from "../../../../../../utils/validacao/validacao";
import { ApuracaoDoInformativoDaComposicaoDeCustosDaEcfDTO } from "../../../models/apuracao-informativo-composicao-custos-ecf";
import { ApuracaoDoInformativoDaComposicaoDeCustosDaEcfServico } from "../../../servicos/apuracao-informativo-composicao-custos-ecf";
import GridInternaGridInternaLinhaApuracaoInformativoComposicaoCustosEcf from "./grid";

const novoRegistro: ApuracaoDoInformativoDaComposicaoDeCustosDaEcfDTO = {
  id: 0,
  dataInicial: "",
  dataFinal: "",
};

const rederizarTitulo = (item: any) => item.text;

let dadosAuditoria: AuditavelDTO | undefined = undefined;

export default function EditFormEdicaoApuracaoDoInformativoDaComposicaoDeCustosDaEcf(
  props: IFormularioEditavelBase
) {
  const empresaLogada = useAppSelector((state) => {
    return {
      Apelido: state.sessao.dadosSessao?.empresa.apelido,
      CpfCpj: state.sessao.dadosSessao?.empresa.cnpjCpfCodigo,
    };
  });

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
  });

  const { register, control, reset } =
    useForm<ApuracaoDoInformativoDaComposicaoDeCustosDaEcfDTO>({
      mode: "onChange",
      reValidateMode: "onChange",
      resolver: yupResolver(schema),
    });

  // Usado para limpar o formulário se for NaN
  useEffect(() => {
    if (Number.isNaN(props.idRegistroEmEdicao)) {
      reset(novoRegistro);
    }
    preencherTela();
  }, [props.idRegistroEmEdicao]);

  function limparTela() {
    dadosAuditoria = undefined;
    reset(novoRegistro);
  }

  function handleCancelar() {
    fechar(ResultadoAcaoFormulario.AcaoCancelada);
  }

  function fechar(resultado: ResultadoAcaoFormulario) {
    limparTela();
    props.handleCallback(resultado);
  }

  async function preencherTela() {
    if (props.idRegistroEmEdicao > 0) {
      await carregarRegistroDoId();
    }
  }

  async function carregarRegistroDoId() {
    try {
      const resposta =
        await ApuracaoDoInformativoDaComposicaoDeCustosDaEcfServico.ObterPorIdComDadosAuditoria(
          props.idRegistroEmEdicao
        );
      checarResponse(resposta);
      dadosAuditoria = resposta.model;
      reset(resposta.model);
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  return (
    <>
      <ContainerFormMxp>
        <FormMxp>
          <ProvedorAjuda id="edit-form-apuracao-lacs-csll">
            <input type="hidden" {...register("id")} defaultValue={0} />
            <Linha>
              <Coluna md={8}>
                <FormTextBoxSimples
                  titulo={"Minha empresa"}
                  valor={`${empresaLogada.Apelido} - ${formatarCpfCnpj(
                    empresaLogada.CpfCpj!
                  )}`}
                  somenteLeitura
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={3}>
                <FormDateBox
                  name="dataInicial"
                  titulo="Período de"
                  control={control}
                  requerido
                  aceitaValorCustomizado={true}
                  aceitaDigitacaoComMascara={true}
                  somenteLeitura
                />
              </Coluna>
              <Coluna md={3}>
                <FormDateBox
                  name="dataFinal"
                  titulo="a"
                  control={control}
                  requerido
                  aceitaValorCustomizado={true}
                  aceitaDigitacaoComMascara={true}
                  somenteLeitura
                />
              </Coluna>
            </Linha>
            <TabPanel
              showNavButtons
              swipeEnabled={false}
              itemTitleRender={rederizarTitulo}
              height={"max(40vh, 485px)"}
            >
              <Item text="Informativo da composição de custos">
                <TabContainer>
                  <GridInternaGridInternaLinhaApuracaoInformativoComposicaoCustosEcf
                    idApuracaoDoDoInformativoDaComposicaoDeCustosDaEcf={
                      props.idRegistroEmEdicao
                    }
                  />
                </TabContainer>
              </Item>
            </TabPanel>
          </ProvedorAjuda>
        </FormMxp>
        <ToolbarMxp dadosAuditoria={dadosAuditoria}>
          <BotaoCancelarMxp handleClick={handleCancelar} />
        </ToolbarMxp>
      </ContainerFormMxp>
    </>
  );
}
