import getApi from "../../../../configs/api";
import {
  ItemListaSimplesStringDTO,
  ResponseBase,
  ResponseModel,
} from "../../../../models/api/comum/response-base";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { criarDatasourceGridComParametros } from "../../../../utils/grid/back-end-grid-utils";
import DataSourceFactory, {
  DataSourceFiltragem,
  DataSourceRawFilter,
} from "../../../../utils/grid/data-source-factory";
import {
  LinkAjudaRequestDTO,
  LinkAjudaResponseDTO,
} from "../models/link-ajuda";

class LinkDeAjudaService {
  protected _nomeEndpoint: NomesEndpoints;

  constructor() {
    this._nomeEndpoint = NomesEndpoints.LinkDaAjuda;
  }

  public getGridSource(codigo: string) {
    return criarDatasourceGridComParametros(`${this._nomeEndpoint}/grid`, {
      codigo,
    });
  }

  public ObterDataSourceParaGrid<T, TKey = number>(
    filtroPadrao?: DataSourceFiltragem<T>[],
    filtroPadraoExato?: DataSourceRawFilter<T>
  ) {
    return DataSourceFactory.CriarParaGrid<T, TKey>(
      `${this._nomeEndpoint}/grid`,
      filtroPadrao,
      filtroPadraoExato
    );
  }

  public async Obter(codigo: string) {
    const api = getApi(undefined, false);
    const response = await api.get<ResponseModel<LinkAjudaResponseDTO>>(
      `${this._nomeEndpoint}?codigo=${codigo}`
    );

    return response.data;
  }

  public async ObterPorId(id: number) {
    const api = getApi();
    const response = await api.get<ResponseModel<LinkAjudaResponseDTO>>(
      `${this._nomeEndpoint}/por-id?id=${id}`
    );

    return response.data;
  }

  public async InserirOuAtualizar(model: LinkAjudaRequestDTO) {
    const api = getApi();
    const response = await api.post<ResponseModel<LinkAjudaResponseDTO>>(
      this._nomeEndpoint,
      model
    );

    return response.data;
  }

  public async InserirPorId(model: LinkAjudaRequestDTO) {
    const api = getApi();
    const response = await api.post<ResponseModel<LinkAjudaResponseDTO>>(
      `${this._nomeEndpoint}/por-id`,
      model
    );

    return response.data;
  }

  public async EditarPorId(model: LinkAjudaRequestDTO) {
    const api = getApi();
    const response = await api.put<ResponseModel<LinkAjudaResponseDTO>>(
      `${this._nomeEndpoint}/por-id`,
      model
    );

    return response.data;
  }

  public async Excluir(codigo: string) {
    const api = getApi();
    const response = await api.delete<ResponseBase>(
      `${this._nomeEndpoint}?codigo=${codigo}`
    );

    return response.data;
  }

  public async ExcluirPorId(id: number) {
    const api = getApi();
    const response = await api.delete<ResponseBase>(
      `${this._nomeEndpoint}/por-id?id=${id}`
    );

    return response.data;
  }

  public async ObterListaSimples() {
    const api = getApi(undefined, false);
    const response = await api.get<ResponseModel<ItemListaSimplesStringDTO[]>>(
      `${this._nomeEndpoint}/lista-simples`
    );

    return response.data;
  }

  public async ObterListaLinksComCache(renovar: boolean) {
    const api = getApi(undefined, false);
    const response = await api.get<ResponseModel<LinkAjudaResponseDTO[]>>(
      `${this._nomeEndpoint}/lista-links-com-cache?renovar=${renovar}`
    );

    return response.data;
  }
}

export const LinkDeAjudaServico = new LinkDeAjudaService();
