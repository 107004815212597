import { ReactNode, RefObject, memo, useEffect, useRef } from "react";
import { IGridSelecao } from "../../../models/shared/ui/formularios";
import { JanelasDeNotificacaoTitulos } from "../../../utils/common/notificacoes-utils";
import { exibirAlerta } from "../../../utils/dialogos";
import { UnidadeTamanhoElementos } from "../../../utils/tipos/unidade-tamanho";
import FormBase from "../../layout/form-base";
import { Modal } from "../../layout/modal";

interface SeletorProps {
  visivel: boolean;
  modo: "selecaoUnica" | "selecaoMultipla";
  titulo: string;
  larguraMaxima?: UnidadeTamanhoElementos;
  alturaMaxima?: UnidadeTamanhoElementos;
  onClose: () => void;
  callBack: (concluido: boolean, dados: any[]) => void;
  componente: (refGrid: RefObject<IGridSelecao>) => ReactNode;
}
export const Seletor = memo((props: SeletorProps) => {
  const ref = useRef<IGridSelecao>(null);

  function handleCancelar() {
    props.callBack(false, []);
  }

  function setCurrentGridProps() {
    ref.current?.definirCallBackDoubleClick(handleSalvar);
    ref.current?.definirModo(props.modo);
    ref.current?.limparSelecao();
  }

  useEffect(() => setCurrentGridProps(), [props.callBack]);

  async function handleSalvar(dadosLinhaDoubleClick?: any) {
    const selecao = dadosLinhaDoubleClick
      ? [dadosLinhaDoubleClick]
      : ref.current?.obterSelecao() ?? [];

    if (!selecao.length) {
      await exibirAlerta(
        JanelasDeNotificacaoTitulos.Atencao,
        `Selecione ${
          props.modo == "selecaoMultipla" ? "ao menos" : ""
        } um registro!`
      );
      return;
    }

    props.callBack(true, selecao);
    ref.current?.limparSelecao();
  }

  //Evita passar os argumentos do click como playload opcional da função.
  const handleSalvarClickBotaoConfirmar = () => handleSalvar();

  return (
    <>
      <Modal
        titulo={props.titulo}
        visivel={props.visivel}
        onFechar={props.onClose}
        alturaMaxima={props.alturaMaxima}
        larguraMaxima={props.larguraMaxima}
      >
        <FormBase
          formId={"form-seletor"}
          modal
          onClickCancelar={handleCancelar}
          onClickSalvar={handleSalvarClickBotaoConfirmar}
          onContentReady={setCurrentGridProps}
          botaoSalvar={{ texto: "Confirmar", icon: "check" }}
        >
          {props.componente(ref)}
        </FormBase>
      </Modal>
    </>
  );
});
