import getApi from "../../../../configs/api";
import { ResponseModel } from "../../../../models/api/comum/response-base";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { EstadoContaContabil } from "../../conta-contabil/models/conta-contabil.enum";
import { RegistroBalanceteComparativo } from "../models/balancete-comparativo.api";
import { FiltrosBalanceteComparativoViewModel } from "../models/balancete-comparativo.viewmodel";

class BalanceteComparativoService {
  private _nomeEndpoint: NomesEndpoints;

  constructor() {
    this._nomeEndpoint = NomesEndpoints.BalanceteComparativo;
  }

  public async obterBalanceteComparativo(
    filtros: FiltrosBalanceteComparativoViewModel
  ) {
    const api = getApi();

    const parametros = new URLSearchParams();

    if (filtros.dataInicial) {
      parametros.append("dataInicial", filtros.dataInicial);
    }

    if (filtros.dataFinal) {
      parametros.append("dataFinal", filtros.dataFinal);
    }

    if (filtros.minhaEmpresaId && filtros.minhaEmpresaId.length > 0) {
      filtros.minhaEmpresaId.forEach((id) =>
        parametros.append("minhaEmpresaId", String(id))
      );
    }

    if (filtros.incluirLancamentosDeEncerramento) {
      parametros.append(
        "incluirLancamentosDeEncerramento",
        String(filtros.incluirLancamentosDeEncerramento)
      );
    }

    if (filtros.exibirContasZeradas) {
      parametros.append(
        "exibirContasZeradas",
        String(filtros.exibirContasZeradas)
      );
    }

    if (filtros.estadoDaConta.length > 0) {
      const estado =
        filtros.estadoDaConta.length === 2
          ? EstadoContaContabil.Ambas
          : filtros.estadoDaConta[0];
      parametros.append("estadoConta", String(estado));
    }

    parametros.append("agrupamento", String(filtros.agrupamento));

    const queryString = parametros.toString();

    const response = await api.get<
      ResponseModel<RegistroBalanceteComparativo[]>
    >(`${this._nomeEndpoint}?${queryString}`);

    return response.data;
  }
}

export const BalanceteComparativoServico = new BalanceteComparativoService();
