import { Template } from "devextreme-react";
import { Button, TagBox, TagBoxRef } from "devextreme-react/tag-box";
import { LabelMode, SimplifiedSearchMode } from "devextreme/common";
import { useCallback, useRef, useState } from "react";
import { Controller, FieldValues, UseControllerProps } from "react-hook-form";
import SelectItem from "../../../models/shared/ui/select-item";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../utils/common/permissoes-utils";
import IconeAjuda from "../../ajuda/icone-ajuda";
import LinkAjuda from "../../ajuda/link-ajuda";
import ProvedorSeletor from "../../selecao/provedor-seletor";
import SeletorMxp from "../../selecao/seletor-mxp";
import { LabelErro } from "../label-erro";
import { SeletorConfig } from "../selectbox-lazy-mxp";

export interface MulltiSelectDataSource<T extends FieldValues> {
  dataSource?: T[];
  displayExpr?: string;
  valueExpr?: string;
}

export function getDataSourceMulltiSelectBox(dataSource: SelectItem[]) {
  return {
    dataSource: dataSource,
    valueExpr: "valor",
    displayExpr: "descricao",
  } as MulltiSelectDataSource<SelectItem>;
}

const dropDownIconRender = () => {
  return <div className="dx-dropdowneditor-icon"></div>;
};

export interface FormMultiSelectBoxProps<T extends FieldValues>
  extends UseControllerProps<T> {
  tabIndex?: number;
  titulo?: string;
  toolTip?: string;
  placeholder?: string;
  tipoPlaceholder?: LabelMode;
  exibirBotaoLimpar?: boolean;
  somenteLeitura?: boolean;
  desabilitado?: boolean;
  requerido?: boolean;
  labelSemDados?: string;
  dataSource: MulltiSelectDataSource<any>;
  habilitaBusca?: boolean;
  tipoBusca?: SimplifiedSearchMode;
  onValueChange?: (value: any) => void;
  exibirLinkAjuda?: boolean;
  maxDisplayedTags?: number;
  onEnter?: (e: any) => void;
  modoSelecao?: "lista" | "umPorUm" | "listaComBotaoOk";
  seletorConfig?: Omit<SeletorConfig, "modo">;
  permissoesNecessarias?: string[];
}

export default function <T extends FieldValues>(
  props: FormMultiSelectBoxProps<T>
) {
  const ref = useRef<TagBoxRef>(null);

  const [seletorVisivel, setSeletorVisivel] = useState(false);

  const onClickLupa = useCallback(() => {
    if (
      props.permissoesNecessarias &&
      !verificaComNotificacaoSeUsuarioPossuiPermissoes(
        props.permissoesNecessarias
      )
    ) {
      return;
    }

    setSeletorVisivel(true);
  }, [props.permissoesNecessarias]);

  return (
    <ProvedorSeletor>
      <Controller
        {...props}
        render={({ field, fieldState }) => (
          <>
            <label htmlFor={field.name}>
              <span className="dx-field-item-label-content">
                <span className="dx-field-item-label-text">
                  {props.titulo ? props.titulo : <br />}
                </span>
                {props.requerido && (
                  <span className="dx-field-item-required-mark">&nbsp;*</span>
                )}
                {(props.exibirLinkAjuda ?? true) && (
                  <LinkAjuda keyAjuda={field.name}>
                    <IconeAjuda />
                  </LinkAjuda>
                )}
              </span>
            </label>
            <TagBox
              ref={ref}
              tabIndex={props.tabIndex}
              dataSource={props.dataSource.dataSource}
              valueExpr={props.dataSource.valueExpr}
              displayExpr={props.dataSource.displayExpr}
              noDataText={props.labelSemDados}
              hint={props.toolTip}
              label={props.placeholder}
              labelMode={props.tipoPlaceholder}
              readOnly={props.somenteLeitura}
              disabled={props.desabilitado}
              showClearButton={props.exibirBotaoLimpar}
              showMultiTagOnly={false}
              onEnterKey={props.onEnter}
              maxFilterQueryLength={10000}
              maxDisplayedTags={props.maxDisplayedTags}
              onValueChange={(value) => {
                props.onValueChange?.(value);
                field.onChange(value);
              }}
              onFocusOut={() => {
                field.onBlur();
              }}
              onSelectionChanged={(e) => {
                /* Serve para que o texto da pesquisa seja limpo após inserir um item. 
              A própria dev-express sugeriu isso em seus fóruns de suporte.*/
                if (e.addedItems.length > 0) {
                  const textbox = e.component.field() as HTMLInputElement;
                  textbox.value = "";

                  if (document.activeElement?.id == textbox.id) {
                    e.component.getDataSource().searchValue("");
                    textbox.blur();
                    textbox.focus();
                  }
                }
              }}
              value={field.value}
              validationStatus={fieldState.invalid ? "invalid" : "valid"}
              searchEnabled={props.habilitaBusca}
              searchMode={props.tipoBusca ?? "startswith"}
              inputAttr={{ id: field.name }}
              showSelectionControls={props.modoSelecao != "umPorUm"}
              applyValueMode={
                props.modoSelecao != "listaComBotaoOk"
                  ? "instantly"
                  : "useButtons"
              }
              showDropDownButton
            >
              {props.seletorConfig && (
                <Button
                  name="dropDownIconCustom"
                  location="after"
                  options={{
                    stylingMode: "text",
                    template: "dropDownIcon",
                    activeStateEnabled: false,
                    elementAttr: {
                      class:
                        "dx-dropdowneditor-button mxp-dropdowneditor-button",
                    },
                  }}
                >
                  <Template name="dropDownIcon" render={dropDownIconRender} />
                </Button>
              )}
              {props.seletorConfig && (
                <Button
                  name="prevDate"
                  location="after"
                  options={{
                    icon: "search",
                    type: "normal",
                    stylingMode: "outlined",
                    elementAttr: {
                      class: "dx-shape-standard mxp-botao-pesquisa",
                    },
                    onClick: onClickLupa,
                  }}
                />
              )}
            </TagBox>
            <LabelErro>{fieldState.error?.message}</LabelErro>
          </>
        )}
      />
      {props.seletorConfig && (
        <SeletorMxp
          modo={"selecaoMultipla"}
          visivel={seletorVisivel}
          handleConfirmar={(sucesso, dados) => {
            if (!sucesso) {
              return;
            }

            ref.current?.instance().option(
              "value",
              dados.map((x) => x.id)
            );
            setSeletorVisivel(false);
          }}
          handleFechar={() => setSeletorVisivel(false)}
          componenteGrid={props.seletorConfig.componenteGrid}
          titulo={props.seletorConfig.titulo}
        />
      )}
    </ProvedorSeletor>
  );
}
