import DataGrid, { Column, DataGridRef } from "devextreme-react/data-grid";
import { ColumnCellTemplateData } from "devextreme/ui/data_grid";
import {
  memo,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ModalAccordion from "../../../../../components/dialogos/modal-accordion";
import { AccordionDataSource } from "../../../../../components/dialogos/modal-accordion/modal-accordion";
import GridColunaAcoes from "../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import { MxpChip } from "../../../../../components/mxp/chip";
import {
  MenuEdicaoOrdemDeProducao,
  NomesMenuEdicaoOrdemDeProducao,
} from "../../../../../components/ordem-de-producao/menu-edicao-op";
import { ProvedorMenuEdicaoOrdemDeProducao } from "../../../../../components/ordem-de-producao/provedor-menu-op";
import { useRegistrarAtalhosGrid } from "../../../../../hooks/atalhos.hooks";
import { useMenuDeContextosGrid } from "../../../../../hooks/menus.hooks";
import { useParametroId } from "../../../../../hooks/route.hooks";
import { useSeletorGridMxp } from "../../../../../hooks/seletor.hooks";
import { InserirEmMassaResponse } from "../../../../../models/api/comum/inserir-varios";
import { ResponseModel } from "../../../../../models/api/comum/response-base";
import { PermissoesEstoqueMovimentacao } from "../../../../../models/permissoes/estoque/estoque-movimentacao/permissoes-estoque-movimentacao";
import { PermissoesInsumosDasOrdensDeProducao } from "../../../../../models/permissoes/producao/insumo-da-ordem-de-producao/InsumoDaOrdemDeProducaoPermissoes";
import { PermissoesOrdemDeProducao } from "../../../../../models/permissoes/producao/ordem-de-producao/OrdemDeProducaoPermissoes";
import { ResultadoAcaoFormulario } from "../../../../../models/shared/ui/formularios";
import { GridMxpProps } from "../../../../../models/shared/ui/grid";
import GetColunasDeAuditoria from "../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import {
  checarResponseExibeToastSucesso,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import criarNameof from "../../../../../utils/common/cria-name-of";
import {
  abrirModalMxpAntigo,
  abrirModalMxpAntigoEmOutraAbaComParametrosAdicionais,
  DadosLink,
  navegarParaMxpAntigoEmNovaAba,
  navegarParaMxpAntigoEmNovaAbaComParametros,
} from "../../../../../utils/common/menu-utils";
import NomesModais from "../../../../../utils/common/nomes-modais";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import NormalizaTituloModal from "../../../../../utils/common/normaliza-titulo";
import { JanelasDeNotificacaoTitulos } from "../../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import { ItemContextMenuMxp } from "../../../../../utils/context-menu/context-menu-utils";
import {
  GestorEventoClickMultiplasLinhas,
  GestorEventoClickSimples,
  GestorEventoClickUnicaLinha,
} from "../../../../../utils/context-menu/gestor-evento-click";
import { exibirAlerta } from "../../../../../utils/dialogos";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import UrlUtils from "../../../../../utils/url/url-utils";
import FormIncluirReservaOrdemProducao from "../../../../estoque/reserva/componentes/formulario/form-incluir-reserva-ordem-producao";
import { podeReservarOp } from "../../../../estoque/reserva/utils/reserva.helpers";
import {
  DadosFiltroRelacionamentos,
  OrdemDeProducaoGridModel,
} from "../../models/ordem-de-producao.api";
import {
  EstadoDecodificado,
  EstocagemDoProdutoHelper,
  OrdemDeProducaoEstadoHelper,
  OrdemDeProducaoTipoRelacionamento,
} from "../../utils/enums/ordem-de-producao-enums";
import FormOrdemDeProducao from "../formularios";
import FormConcluirOrdemDeProducao from "../formularios/concluir-ordem-de-producao";
import { ModalDesconcluirOrdemDeProducao } from "../modal-desfazer-conclusao";
import ContextoOrdemDeProducaoGrid from "../provedor-contexto-ordem-de-producao/contexto-ordem-de-producao-grid";
import { ProvedorOrdemDeProducaoGrid } from "../provedor-contexto-ordem-de-producao/provedor-ordem-de-producao-grid";

import ModalBaixaExcel from "./modal-baixa-excel";
import ModalImportacaoExcel from "./modal-importacao-excel";

import { SortDescriptor } from "devextreme/data";
import {
  ChipCellClass,
  renderChips,
} from "../../../../../components/grid-mxp/chips/chip-reserva";
import { PermissoesFichaFutura } from "../../../../../models/permissoes/estoque/ficha-futura/permissoes-ficha-futura";
import { PermissoesLote } from "../../../../../models/permissoes/estoque/lote/permissoes-lote";
import { PermissoesReserva } from "../../../../../models/permissoes/estoque/reserva/permissoes-reserva";
import { PermissoesItem } from "../../../../../models/permissoes/itens/item/permissoes-item";
import { PermissoesBoletimInspecao } from "../../../../../models/permissoes/qualidade/boletim-de-inspecao/permissoes-boletim-de-inspecao";
import { gerarHeaderFilterAninhado } from "../../../../../parts/layout/grid-defaults/headerFilterAninhado";
import { DataSourceRawFilter } from "../../../../../utils/grid/data-source-factory";
import { useRelatorioPersonalizado } from "../../../../relatorio/relatorio-personalizado/hooks/relatorio-personalizado.hooks";
import GridOperacaoDaOrdemDeProducaoCriarBoletimDeInspecao from "../../../operacao-da-ordem-de-producao/componentes/grid/grid-criar-boletim-de-inspecao-da-ordem-de-producao";
import { OrdemDeProducaoServico } from "../../servicos/ordem-de-producao.service";
import { abrirTelaDeApontamentoDaDuracaoDaSessaoNoMxp1 } from "../../utils/handlers/apontar-duracao-da-sessao";
import { confirmarRoteiro } from "../../utils/handlers/confirmar-roteiro";
import {
  verificacoesParaRetiradaDeApara,
  verificacoesParaRetiradaDeMaterial,
} from "../../utils/handlers/retirada-de-material-ou-apara";
import FormRetirarApara from "../formularios/retiradas/retirar-apara";
import FormRetirarMaterial from "../formularios/retiradas/retirar-material";
import { cancelarOrdensDeProducaoHandler } from "./helpers/cancelamento-ordem-de-producao-helper";
import { confirmarOrdensDeProducaoHandler } from "./helpers/confirmacao-ordem-de-producao-helper";
import { consultarEstoqueEmElaboracao } from "./helpers/consultar-estoque-em-elaboracao-helper";
import { verificarSeOrdemDeProducaoPodeCriarBoletimDeInspecaoComOperacoes } from "./helpers/criar-boletim-de-inspecao-com-operacoes-helper";
import { excluirOrdensDeProducaoHandler } from "./helpers/exclusao-ordem-de-producao-helper";
import { reservarOrdensDeProducaoComProjecoesDeUsoHandler } from "./helpers/reservar-com-projecoes-de-uso-helper";
import { suspenderOrdensDeProducaoHandler } from "./helpers/suspensao-ordem-de-producao-helper";

const nameOfGridHandler = criarNameof<OrdemDeProducaoGridModel>();
const nameOfFiltrosRelacionamentos = criarNameof<DadosFiltroRelacionamentos>();

export function OrdemDeProducaoEstadoAsCellRender({
  data: { estadoDecodificado },
}: ColumnCellTemplateData) {
  return (
    <MxpChip
      color={
        OrdemDeProducaoEstadoHelper.coresPorEstado[
          estadoDecodificado as EstadoDecodificado
        ]
      }
    >
      {estadoDecodificado}
    </MxpChip>
  );
}

export default function GridOrdemDeProducao(
  props: GridMxpProps<OrdemDeProducaoGridModel>
) {
  const gridRef = useRef<DataGridRef>(null);
  const parametroId = useParametroId();

  const gridController = useMemo(
    () =>
      new GridController<OrdemDeProducaoGridModel>(() =>
        gridRef.current?.instance()
      ),
    []
  );

  const handleAtualizarGrid = useCallback(() => {
    gridController.atualizar();
  }, [gridController]);

  const [idConcluirOp, setIdConcluirOp] = useState(NaN);
  const [modalImportacaoVisivel, setModalImportacaoVisivel] = useState(false);
  const [modalBaixaExcelVisivel, setModalBaixaExcelVisivel] = useState(false);
  const [idRegistroEdicao, setIdRegistroEdicao] = useState(NaN);
  const [ordemDeProducaoDaReserva, setOrdemDeProducaoDaReserva] = useState<
    OrdemDeProducaoGridModel | undefined
  >(undefined);
  const [
    modalAcaoOrdensDeProducaoAccordionVisivel,
    setModalAcaoOrdensDeProducaoAccordionVisivel,
  ] = useState(false);
  const [
    detalhamentoAcaoOrdensDeProducaoAccordion,
    setDetalhamentoAcaoOrdensDeProducaoAccordion,
  ] = useState<AccordionDataSource>();

  const [
    ordemDeProducaoIdParaRetiradaDeMaterial,
    setOrdemDeProducaoIdParaRetiradaDeMaterial,
  ] = useState(NaN);

  const [
    ordemDeProducaoIdParaRetiradaDeApara,
    setOrdemDeProducaoIdParaRetiradaDeApara,
  ] = useState(NaN);

  const [
    criarBoletimDeInspecaoComOperacoesOrdemDeProducaoId,
    setCriarBoletimDeInspecaoComOperacoesOrdemDeProducaoId,
  ] = useState(NaN);

  const { definirIdDoRegistroParaImpressaoDoModeloFavorito } =
    useRelatorioPersonalizado(() => gridRef.current?.instance());

  useEffect(() => {
    if (parametroId || parametroId === 0) {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesOrdemDeProducao.InserirEditar,
        ])
      ) {
        return;
      }

      setIdRegistroEdicao(parametroId);
    }
  }, [parametroId]);

  const handleNovoRegistro = useCallback(() => setIdRegistroEdicao(0), []);

  const handleEditarRegistro = useCallback(
    (registro: OrdemDeProducaoGridModel) => setIdRegistroEdicao(registro.id),
    []
  );

  const handleExcluirOrdensDeProducaoClick = useCallback(
    async (registros: OrdemDeProducaoGridModel[]) => {
      const result = await excluirOrdensDeProducaoHandler(
        registros,
        handleAtualizarGrid
      );

      if (result) {
        const { accordionVisivel, mensagem } = result;
        setModalAcaoOrdensDeProducaoAccordionVisivel(accordionVisivel);
        setDetalhamentoAcaoOrdensDeProducaoAccordion({
          model: mensagem,
        });
      }
    },
    [handleAtualizarGrid]
  );

  const handleImprimirCallback = useCallback(async (id: number) => {
    if (id) {
      definirIdDoRegistroParaImpressaoDoModeloFavorito(id);
    }
  }, []);

  const handleImportarExcelClick = useCallback(
    () => setModalImportacaoVisivel(true),
    []
  );

  const handleBaixarExcelClick = useCallback(
    () => setModalBaixaExcelVisivel(true),
    []
  );

  const handleConcluirClick = useCallback(
    async (registro: OrdemDeProducaoGridModel) => {
      if (
        verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesOrdemDeProducao.ConcluirEDesconcluir,
        ])
      ) {
        if (registro.estadoDecodificado !== EstadoDecodificado.AProduzir) {
          exibirAlerta(
            JanelasDeNotificacaoTitulos.Atencao,
            'Somente ordens de produção no estado "A produzir" podem ser concluídas.'
          );
          return;
        }

        setIdConcluirOp(registro.id);
      }
    },
    []
  );

  const handleRetirarMaterialClick = useCallback(
    async (registro: OrdemDeProducaoGridModel) => {
      if (await verificacoesParaRetiradaDeMaterial(registro.estado)) {
        setOrdemDeProducaoIdParaRetiradaDeMaterial(registro.id);
      }
    },
    []
  );

  const handleRetirarAparaClick = useCallback(
    async (registro: OrdemDeProducaoGridModel) => {
      if (await verificacoesParaRetiradaDeApara(registro.estado, registro.id)) {
        setOrdemDeProducaoIdParaRetiradaDeApara(registro.id);
      }
    },
    []
  );

  const fecharConcluirOp = useCallback(async () => {
    setIdConcluirOp(NaN);
    handleAtualizarGrid();
  }, [handleAtualizarGrid]);

  const fecharModalRetiradaDeMaterial = useCallback(async () => {
    setOrdemDeProducaoIdParaRetiradaDeMaterial(NaN);
    handleAtualizarGrid();
  }, [handleAtualizarGrid]);

  const fecharModalRetiradaDeApara = useCallback(async () => {
    setOrdemDeProducaoIdParaRetiradaDeApara(NaN);
    handleAtualizarGrid();
  }, [handleAtualizarGrid]);

  const fecharModalBoletimDeInspecaoComOperacoesVisivel =
    useCallback(async () => {
      setCriarBoletimDeInspecaoComOperacoesOrdemDeProducaoId(NaN);
      handleAtualizarGrid();
    }, [handleAtualizarGrid]);

  const handleReservarClick = useCallback(
    async (registro: OrdemDeProducaoGridModel) => {
      if (await podeReservarOp(registro.id)) {
        setOrdemDeProducaoDaReserva(registro);
      }
    },
    []
  );

  const handleReservarOrdensDeProducaoComProjecoesDeUsoClick = useCallback(
    async (registros: OrdemDeProducaoGridModel[]) => {
      const result = await reservarOrdensDeProducaoComProjecoesDeUsoHandler(
        registros,
        handleAtualizarGrid
      );

      if (result) {
        const { accordionVisivel, mensagem } = result;
        setModalAcaoOrdensDeProducaoAccordionVisivel(accordionVisivel);
        setDetalhamentoAcaoOrdensDeProducaoAccordion({
          model: mensagem,
        });
      }
    },
    [handleAtualizarGrid]
  );

  const handleCancelarClick = useCallback(
    async (registros: OrdemDeProducaoGridModel[]) => {
      const result = await cancelarOrdensDeProducaoHandler(
        registros,
        handleAtualizarGrid
      );

      if (result) {
        const { accordionVisivel, mensagem } = result;
        setModalAcaoOrdensDeProducaoAccordionVisivel(accordionVisivel);
        setDetalhamentoAcaoOrdensDeProducaoAccordion({
          model: mensagem,
        });
      }
    },
    [handleAtualizarGrid]
  );

  const handleModalReservaCallback = useCallback(() => {
    setOrdemDeProducaoDaReserva(undefined);
  }, [setOrdemDeProducaoDaReserva]);

  const handleCallbackFormularioEdicao = useCallback(
    (resultado: ResultadoAcaoFormulario) => {
      setIdRegistroEdicao(NaN);

      if (
        resultado == ResultadoAcaoFormulario.AcaoConcluida ||
        resultado == ResultadoAcaoFormulario.AcaoCancelada
      ) {
        handleAtualizarGrid();
      }

      UrlUtils.RemoverParametroId(parametroId);
    },
    [parametroId, handleAtualizarGrid]
  );

  const handleFecharModal = useCallback(() => {
    setIdRegistroEdicao(NaN);
    handleAtualizarGrid();
    UrlUtils.RemoverParametroId(parametroId);
  }, [parametroId, handleAtualizarGrid]);

  const handleImportarExcelResult = useCallback(
    async (resultados: ResponseModel<InserirEmMassaResponse[]>) => {
      // Atualiza a grid se algum item foi importado com sucesso.
      if (
        resultados.sucesso &&
        resultados.model.findIndex((x) => !x.erros || x.erros.length === 0)
      ) {
        handleAtualizarGrid();
      }
    },
    [handleAtualizarGrid]
  );

  const handleSuspenderClick = useCallback(
    async (
      registros: OrdemDeProducaoGridModel[],
      exibeConfirmacao: boolean
    ) => {
      const result = await suspenderOrdensDeProducaoHandler(
        registros,
        handleAtualizarGrid,
        exibeConfirmacao
      );

      if (result) {
        const { accordionVisivel, mensagem } = result;
        setModalAcaoOrdensDeProducaoAccordionVisivel(accordionVisivel);
        setDetalhamentoAcaoOrdensDeProducaoAccordion({
          model: mensagem,
        });
      }
    },
    [handleAtualizarGrid]
  );

  const handleConfirmarClick = useCallback(
    async (
      registros: OrdemDeProducaoGridModel[],
      exibeConfirmacao: boolean
    ) => {
      if (
        verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesOrdemDeProducao.InserirEditar,
        ])
      ) {
        const result = await confirmarOrdensDeProducaoHandler(
          registros,
          handleAtualizarGrid,
          exibeConfirmacao
        );

        if (result) {
          const { accordionVisivel, mensagem } = result;
          setModalAcaoOrdensDeProducaoAccordionVisivel(accordionVisivel);
          setDetalhamentoAcaoOrdensDeProducaoAccordion({
            model: mensagem,
          });
        }
      }
    },
    [handleAtualizarGrid]
  );

  const handleAtualizarRoteiroClick = useCallback(
    async (registro: OrdemDeProducaoGridModel) => {
      if (await confirmarRoteiro()) {
        try {
          const resposta =
            await OrdemDeProducaoServico.AtualizarRoteiroDaOrdemDeProducao(
              registro.id
            );

          if (resposta) {
            checarResponseExibeToastSucesso(
              resposta,
              "Roteiro atualizado com sucesso."
            );
            handleAtualizarGrid();
          }
        } catch (erro) {
          tratarErroApi(erro);
        }
      }
    },
    [handleAtualizarGrid]
  );

  const handleCriarBoletimClick = useCallback(
    async (registro: OrdemDeProducaoGridModel) => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesLote.Consultar,
          PermissoesBoletimInspecao.InserirEditar,
        ])
      ) {
        return;
      }

      try {
        const resposta =
          await OrdemDeProducaoServico.VerificarSeOrdemDeProducaoCriaBoletimDeInspecao(
            registro.id
          );

        if (resposta.sucesso) {
          await exibirAlerta(
            "Aviso",
            `A funcionalidade de criação de boletins de inspeção ainda não foi adaptada para a nova versão do MAXIPROD.</br>` +
              `Ao clicar em "Ok", você será direcionado à tela na versão antiga, onde o boletim poderá ser criado.`
          );

          const rota = "BoletimInspecao";
          const dadosLink: DadosLink = {
            rota: "_BoletimInspecao_CriarBIMLote_Pelo_Mxp2",
            tipo: "javaScriptMxpAntigo",
            paramFunction: () => `(${registro.id.toString()})`,
          };

          const abrirEmNovaAba = true;
          abrirModalMxpAntigo(dadosLink, abrirEmNovaAba, rota);
        }
      } catch (erro) {
        tratarErroApi(erro);
      }
    },
    []
  );

  const handleFecharModalAccordion = useCallback(
    (
      setModalAccordionVisivel: (value: React.SetStateAction<boolean>) => void,
      setDetalhamentoModalAccordion: (
        value: React.SetStateAction<AccordionDataSource | undefined>
      ) => void
    ) => {
      setModalAccordionVisivel(false);
      setDetalhamentoModalAccordion(undefined);
    },
    []
  );

  const handleApontarDuracaoDaSessaoClick = useCallback(
    async (registro: OrdemDeProducaoGridModel) => {
      abrirTelaDeApontamentoDaDuracaoDaSessaoNoMxp1(registro.id);
    },
    []
  );

  const handleCriarBoletimDeInspecaoComOperacoesClick = useCallback(
    async (registro: OrdemDeProducaoGridModel) => {
      if (
        !(await verificarSeOrdemDeProducaoPodeCriarBoletimDeInspecaoComOperacoes(
          registro
        ))
      ) {
        return;
      }
      setCriarBoletimDeInspecaoComOperacoesOrdemDeProducaoId(registro.id);
    },
    []
  );

  return (
    <ProvedorOrdemDeProducaoGrid>
      <GridInternaOrdemDeProducao
        {...props}
        gridRef={gridRef}
        gridController={gridController}
        onInserirRegistroClick={handleNovoRegistro}
        onEditarRegistroClick={handleEditarRegistro}
        onExcluirRegistroClick={handleExcluirOrdensDeProducaoClick}
        onImportacaoExcelClick={handleImportarExcelClick}
        onBaixaPorExcelClick={handleBaixarExcelClick}
        onConfirmarClick={handleConfirmarClick}
        onConcluirClick={handleConcluirClick}
        onReservarClick={handleReservarClick}
        onReservarOrdensDeProducaoComProjecoesDeUsoClick={
          handleReservarOrdensDeProducaoComProjecoesDeUsoClick
        }
        onAtualizarRoteiroClick={handleAtualizarRoteiroClick}
        onCriarBoletimClick={handleCriarBoletimClick}
        onCancelarClick={handleCancelarClick}
        onSuspenderClick={handleSuspenderClick}
        onApontarDuracaoDaSessaoClick={handleApontarDuracaoDaSessaoClick}
        onRetirarMaterialClick={handleRetirarMaterialClick}
        onRetirarAparaClick={handleRetirarAparaClick}
        onCriarBoletimDeInspecaoComOperacoesClick={
          handleCriarBoletimDeInspecaoComOperacoesClick
        }
        imprimir={handleImprimirCallback}
      />

      {/*Modal Formulário*/}
      <ProvedorMenuEdicaoOrdemDeProducao>
        <ModalMxp
          titulo={NormalizaTituloModal.Normalizar(
            idRegistroEdicao,
            NomesModais.ordemDeProducao,
            true
          )}
          idRegistro={idRegistroEdicao}
          visivel={!Number.isNaN(idRegistroEdicao)}
          handleFechar={handleFecharModal}
          largura={"99vw"}
          altura={"99vh"}
          alturaMaxima={"92vh"}
          larguraMaxima={"98vw"}
          componentesAdicionais={
            idRegistroEdicao !== 0 ? MenuEdicaoOrdemDeProducao : undefined
          }
        >
          <FormOrdemDeProducao
            idRegistroEmEdicao={idRegistroEdicao}
            setIdRegistroEmEdicao={setIdRegistroEdicao}
            handleCallback={handleCallbackFormularioEdicao}
            handleImpressaoCallback={handleImprimirCallback}
          />
        </ModalMxp>
      </ProvedorMenuEdicaoOrdemDeProducao>

      <ModalDesconcluirOrdemDeProducao />

      <ModalMxp
        titulo={"Nova reserva"}
        visivel={!!ordemDeProducaoDaReserva}
        handleFechar={handleModalReservaCallback}
      >
        <FormIncluirReservaOrdemProducao
          idOrdemDeProducao={ordemDeProducaoDaReserva?.id ?? 0}
          codigoOrdemDeProducao={ordemDeProducaoDaReserva?.numero ?? undefined}
          ordemDeProducaoEhSugerida={
            ordemDeProducaoDaReserva?.estadoDecodificado ==
            EstadoDecodificado.Sugerida
          }
          idItem={ordemDeProducaoDaReserva?.itemId ?? 0}
          handleCallback={handleModalReservaCallback}
        />
      </ModalMxp>

      <ModalMxp
        titulo={`Concluir ${NomesModais.ordemDeProducao}`}
        visivel={!Number.isNaN(idConcluirOp)}
        handleFechar={fecharConcluirOp}
        largura={"max(50vw, 800px)"}
        altura={"auto"}
      >
        <FormConcluirOrdemDeProducao
          idRegistroEmEdicao={idConcluirOp}
          handleCallback={fecharConcluirOp}
        />
      </ModalMxp>

      <ModalMxp
        titulo={`Nova ${NomesModais.retiradaDeMaterial}`}
        visivel={!Number.isNaN(ordemDeProducaoIdParaRetiradaDeMaterial)}
        handleFechar={fecharModalRetiradaDeMaterial}
        largura={"max(65vw, 900px)"}
        altura={"auto"}
        alturaMaxima={"95vh"}
      >
        <FormRetirarMaterial
          ordemDeProducaoId={ordemDeProducaoIdParaRetiradaDeMaterial}
          idRegistroEmEdicao={0}
          handleCallback={fecharModalRetiradaDeMaterial}
        />
      </ModalMxp>

      <ModalMxp
        titulo={`Nova ${NomesModais.retiradaDeApara}`}
        visivel={!Number.isNaN(ordemDeProducaoIdParaRetiradaDeApara)}
        handleFechar={fecharModalRetiradaDeApara}
        largura={"max(65vw, 900px)"}
        altura={"auto"}
        alturaMaxima={"95vh"}
      >
        <FormRetirarApara
          ordemDeProducaoId={ordemDeProducaoIdParaRetiradaDeApara}
          idRegistroEmEdicao={0}
          handleCallback={fecharModalRetiradaDeApara}
        />
      </ModalMxp>

      <ModalAccordion
        modalAccordionVisivel={modalAcaoOrdensDeProducaoAccordionVisivel}
        handlerFecharModalAccordion={() =>
          handleFecharModalAccordion(
            setModalAcaoOrdensDeProducaoAccordionVisivel,
            setDetalhamentoAcaoOrdensDeProducaoAccordion
          )
        }
        dataSource={detalhamentoAcaoOrdensDeProducaoAccordion?.model}
        modalTitulo={
          detalhamentoAcaoOrdensDeProducaoAccordion?.accordionTitulo ??
          JanelasDeNotificacaoTitulos.Atencao
        }
        accordionId="accordion-acao-ordens-de-producao"
        itemDoAccordionAltura="auto"
      />

      <ModalImportacaoExcel
        visivel={modalImportacaoVisivel}
        onFechar={() => setModalImportacaoVisivel(false)}
        onImportar={handleImportarExcelResult}
      />
      <ModalBaixaExcel
        visivel={modalBaixaExcelVisivel}
        onFechar={() => setModalBaixaExcelVisivel(false)}
        onImportar={handleImportarExcelResult}
      />

      <ModalMxp
        titulo={NomesModais.criarBoletimDeInspecaoComOperacoesDaOrdemDeProducao}
        visivel={
          !Number.isNaN(criarBoletimDeInspecaoComOperacoesOrdemDeProducaoId)
        }
        handleFechar={fecharModalBoletimDeInspecaoComOperacoesVisivel}
        largura={"max(65vw, 900px)"}
        altura={"auto"}
        alturaMaxima={"95vh"}
      >
        <GridOperacaoDaOrdemDeProducaoCriarBoletimDeInspecao
          ordemDeProducaoId={
            criarBoletimDeInspecaoComOperacoesOrdemDeProducaoId
          }
          fecharModal={fecharModalBoletimDeInspecaoComOperacoesVisivel}
        />
      </ModalMxp>
    </ProvedorOrdemDeProducaoGrid>
  );
}

interface GridInternaOrdemDeProducaoProps
  extends GridMxpProps<OrdemDeProducaoGridModel> {
  gridRef: RefObject<DataGridRef>;
  gridController: GridController<OrdemDeProducaoGridModel>;
  onInserirRegistroClick(): void;
  onEditarRegistroClick(registro: OrdemDeProducaoGridModel): void;
  onExcluirRegistroClick(registros: OrdemDeProducaoGridModel[]): void;
  onImportacaoExcelClick(): void;
  onBaixaPorExcelClick(): void;
  onConfirmarClick(
    registro: OrdemDeProducaoGridModel[],
    exibeConfirmacao: boolean
  ): void;
  onConcluirClick(registros: OrdemDeProducaoGridModel): void;
  onReservarClick(registro: OrdemDeProducaoGridModel): void;
  onReservarOrdensDeProducaoComProjecoesDeUsoClick(
    registros: OrdemDeProducaoGridModel[]
  ): void;
  onCancelarClick(registro: OrdemDeProducaoGridModel[]): void;
  onSuspenderClick(
    registros: OrdemDeProducaoGridModel[],
    exibeConfirmacao: boolean
  ): Promise<void>;
  onAtualizarRoteiroClick(registro: OrdemDeProducaoGridModel): void;
  onCriarBoletimClick(registro: OrdemDeProducaoGridModel): void;
  onApontarDuracaoDaSessaoClick(registro: OrdemDeProducaoGridModel): void;
  onRetirarMaterialClick(registro: OrdemDeProducaoGridModel): void;
  onRetirarAparaClick(registro: OrdemDeProducaoGridModel): void;
  onCriarBoletimDeInspecaoComOperacoesClick(
    registro: OrdemDeProducaoGridModel
  ): void;
  imprimir(id: number): void;
}

function gerarHeaderFilterRelacionamento(
  campoBase: string[],
  tipo?: OrdemDeProducaoTipoRelacionamento
) {
  const filtro = gerarHeaderFilterAninhado<DadosFiltroRelacionamentos>({
    dataStore: dataStoreFiltroChips,
    campoColecao: campoBase,
    camposBusca: [
      nameOfFiltrosRelacionamentos("apelidoEmpresa"),
      nameOfFiltrosRelacionamentos("numeroPedidoDeVenda"),
      nameOfFiltrosRelacionamentos("numeroItemDoPedidoDeVenda"),
    ],
    niveis: [
      { campoChave: "idEmpresa", campoDescricao: "apelidoEmpresa" },
      {
        campoChave: "idPedidoDeVenda",
        formatarDescricao: (obj) => `PV ${obj.numeroPedidoDeVenda}`,
      },
      {
        campoChave: "idItemDoPedidoDeVenda",
        formatarDescricao: (obj) =>
          `${obj.numeroPedidoDeVenda} #${obj.numeroItemDoPedidoDeVenda}`,
      },
    ],
  });

  filtro.headerFilter!.dataSource.sort = [
    { selector: nameOfFiltrosRelacionamentos("apelidoEmpresa"), desc: false },
    {
      selector: nameOfFiltrosRelacionamentos("numeroPedidoDeVenda"),
      desc: true,
    },
    {
      selector: nameOfFiltrosRelacionamentos("numeroItemDoPedidoDeVenda"),
      desc: true,
    },
  ] satisfies SortDescriptor<DadosFiltroRelacionamentos>[];

  if (tipo !== undefined) {
    filtro.headerFilter!.dataSource.filter = [
      ["tipo", "=", tipo],
    ] satisfies DataSourceRawFilter<DadosFiltroRelacionamentos>;
  }

  return filtro;
}

const dataStoreFiltroChips =
  OrdemDeProducaoServico.ObterDataStoreParaFiltrosRelacionamentos();

const GridInternaOrdemDeProducao = memo(
  ({
    filtrosNoCliente,
    filtrosNoServidor,
    style,
    gridRef,
    gridController,
    onInserirRegistroClick,
    onEditarRegistroClick,
    onExcluirRegistroClick,
    onImportacaoExcelClick,
    onBaixaPorExcelClick,
    onConcluirClick,
    onConfirmarClick,
    onReservarClick,
    onReservarOrdensDeProducaoComProjecoesDeUsoClick,
    onAtualizarRoteiroClick,
    onCriarBoletimClick,
    onCancelarClick,
    onSuspenderClick,
    onApontarDuracaoDaSessaoClick,
    onRetirarMaterialClick,
    onRetirarAparaClick,
    onCriarBoletimDeInspecaoComOperacoesClick,
    imprimir,
  }: GridInternaOrdemDeProducaoProps) => {
    const dataSource = useMemo(
      () =>
        OrdemDeProducaoServico.ObterDataSourceParaGrid<OrdemDeProducaoGridModel>(
          filtrosNoServidor
        ),
      [filtrosNoServidor]
    );

    const {
      definirAtualizaGridDeOrdemDeProducao,
      definfirOrdemDeProducaoDesfazerConclusao,
    } = useContext(ContextoOrdemDeProducaoGrid);

    const handleAtualizarGrid = useCallback(() => {
      gridController.atualizar();
    }, [gridController]);

    useEffect(() => {
      definirAtualizaGridDeOrdemDeProducao(() => handleAtualizarGrid);
    }, [definirAtualizaGridDeOrdemDeProducao, handleAtualizarGrid]);

    const handleNovoRegistro = useCallback(() => {
      if (
        verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesOrdemDeProducao.InserirEditar,
        ])
      ) {
        onInserirRegistroClick();
      }
    }, [onInserirRegistroClick]);

    const handleEditarRegistro = useCallback(
      (registro: OrdemDeProducaoGridModel) => {
        if (
          verificaComNotificacaoSeUsuarioPossuiPermissoes([
            PermissoesOrdemDeProducao.InserirEditar,
          ])
        ) {
          onEditarRegistroClick(registro);
        }
      },
      [onEditarRegistroClick]
    );

    const handleExcluirRegistro = useCallback(
      async (registro: OrdemDeProducaoGridModel) => {
        onExcluirRegistroClick([registro]);
      },
      [onExcluirRegistroClick]
    );

    const consultarOperacoesRoteiroGantt = useCallback(
      async (registro: OrdemDeProducaoGridModel) => {
        if (registro.id) {
          const rota = `/OperacaoOrdemProducao/Index?of=${registro.id}`;
          navegarParaMxpAntigoEmNovaAba(rota);
        }
      },
      []
    );

    const consultarApontamentosDeOperacoes = useCallback(
      async (registro: OrdemDeProducaoGridModel) => {
        const rota = `/ApontamentoHora/Index?IdOp=${registro.id}`;
        navegarParaMxpAntigoEmNovaAba(rota);
      },
      []
    );

    const consultarItem = useCallback(
      async (registro: OrdemDeProducaoGridModel) => {
        if (registro.itemCodigo) {
          const rota = `Item/PorCodigo`;
          const dadosLink: DadosLink = {
            rota: "_Itens_AbreModalEdicaoItem",
            tipo: "javaScriptMxpAntigo",
            paramFunction: () => `(${registro.itemId})`,
          };
          const parametrosAdicionais = { cod: registro.itemCodigo };
          abrirModalMxpAntigoEmOutraAbaComParametrosAdicionais(
            dadosLink,
            parametrosAdicionais,
            rota
          );
        }
      },
      []
    );

    const consultarFichaFuturaDoItem = useCallback(
      async (registro: OrdemDeProducaoGridModel) => {
        if (
          !verificaComNotificacaoSeUsuarioPossuiPermissoes([
            PermissoesFichaFutura.Consultar,
          ])
        ) {
          return;
        }

        if (registro.id) {
          const rota = `/PerfilEstoque`;
          const parametros = { idItem: registro.itemId };
          navegarParaMxpAntigoEmNovaAbaComParametros(rota, parametros);
        }
      },
      []
    );

    const consultarMovimentacoes = useCallback(
      async (registro: OrdemDeProducaoGridModel) => {
        if (
          !verificaComNotificacaoSeUsuarioPossuiPermissoes([
            PermissoesEstoqueMovimentacao.Consultar,
          ])
        ) {
          return;
        }

        if (registro.id) {
          const rota = `/Movimentacao`;
          const parametros = {
            exibicao: "Fisicos",
            op: registro.id,
          };
          navegarParaMxpAntigoEmNovaAbaComParametros(rota, parametros);
        }
      },
      []
    );

    const consultarRoteiroDoItem = useCallback(
      async (registro: OrdemDeProducaoGridModel) => {
        if (
          !verificaComNotificacaoSeUsuarioPossuiPermissoes([
            PermissoesItem.ConsultarRoteiroDoItem,
          ])
        ) {
          return;
        }

        if (registro.id) {
          const rota = `Item/PorCodigo`;
          const dadosLink: DadosLink = {
            rota: "_Item_Consultar_Roteiro_Por_MXP2",
            tipo: "javaScriptMxpAntigo",
          };
          const parametrosAdicionais = { cod: registro.itemCodigo };
          abrirModalMxpAntigoEmOutraAbaComParametrosAdicionais(
            dadosLink,
            parametrosAdicionais,
            rota
          );
        }
      },
      []
    );

    const consultarReservas = useCallback(
      async (registro: OrdemDeProducaoGridModel) => {
        if (
          !verificaComNotificacaoSeUsuarioPossuiPermissoes([
            PermissoesReserva.ConsultarReservasEProjecoesDeUso,
          ])
        ) {
          return;
        }

        if (registro.id) {
          const rota = `/Reserva`;
          const parametros = {
            idOf: registro.id,
          };
          navegarParaMxpAntigoEmNovaAbaComParametros(rota, parametros);
        }
      },
      []
    );

    const suspenderOrdemDeProducaoItemDoMenuDeContexto = useCallback(
      (exibeConfirmacao: boolean) => {
        const gestorEventoClick = exibeConfirmacao
          ? new GestorEventoClickMultiplasLinhas(
              (registros) => onSuspenderClick(registros, exibeConfirmacao),
              () => gridController
            )
          : new GestorEventoClickUnicaLinha(
              (registro) => onSuspenderClick([registro], exibeConfirmacao),
              () => gridController
            );

        return {
          text: NomesMenuEdicaoOrdemDeProducao.acoes.suspender,
          icon: "ic-material-symbols-outlined ic-block",
          exibirNoMenuPrincipal: exibeConfirmacao,
          gestorEventoClick: gestorEventoClick,
        };
      },
      [gridController, onSuspenderClick]
    );

    const confirmarOrdemDeProducaoItemDoMenuDeContexto = useCallback(
      (exibeConfirmacao: boolean) => {
        const gestorEventoClick = exibeConfirmacao
          ? new GestorEventoClickMultiplasLinhas(
              (registros) => onConfirmarClick(registros, exibeConfirmacao),
              () => gridController
            )
          : new GestorEventoClickUnicaLinha(
              (registro) => onConfirmarClick([registro], exibeConfirmacao),
              () => gridController
            );

        return {
          text: NomesMenuEdicaoOrdemDeProducao.acoes.confirmar,
          icon: "ic-material-symbols-outlined ic-check-circle",
          exibirNoMenuPrincipal: exibeConfirmacao,
          gestorEventoClick: gestorEventoClick,
        };
      },
      [gridController, onConfirmarClick]
    );

    const reservarOrdemDeProducaoItemDoMenuDeContexto = useCallback(
      (exibirNoMenuPrincipal: boolean) => {
        return {
          text: NomesMenuEdicaoOrdemDeProducao.acoes.reservar,
          icon: "ic-material-symbols-outlined ic-keep",
          exibirNoMenuPrincipal: exibirNoMenuPrincipal,
          gestorEventoClick: new GestorEventoClickUnicaLinha(
            (registro) => onReservarClick(registro),
            () => gridController
          ),
        };
      },
      [gridController, onReservarClick]
    );

    const reservarOrdensDeProducaoComProjecoesDeUsoItemMenuDeContexto =
      useCallback(() => {
        const gestorEventoClick = new GestorEventoClickMultiplasLinhas(
          (registros) =>
            onReservarOrdensDeProducaoComProjecoesDeUsoClick(registros),
          () => gridController
        );

        return {
          text: NomesMenuEdicaoOrdemDeProducao.acoes
            .reservarOrdensDeProducaoComProjecoesDeUso,
          icon: "ic-material-symbols-outlined ic-keep",
          exibirNoMenuPrincipal: true,
          gestorEventoClick: gestorEventoClick,
        };
      }, [gridController, onReservarOrdensDeProducaoComProjecoesDeUsoClick]);

    const cancelarOrdemDeProducaoItemDoMenuDeContexto = useCallback(() => {
      return {
        text: NomesMenuEdicaoOrdemDeProducao.acoes.cancelar,
        icon: "ic-material-symbols-outlined ic-cancel",
        exibirNoMenuPrincipal: false,
        gestorEventoClick: new GestorEventoClickMultiplasLinhas(
          (registros) => onCancelarClick(registros),
          () => gridController
        ),
      };
    }, [gridController, onCancelarClick]);

    const atualizarRoteiroOrdemDeProducaoItemDoMenuDeContexto =
      useCallback(() => {
        return {
          text: NomesMenuEdicaoOrdemDeProducao.acoes.atualizarRoteiro,
          icon: "ic-material-symbols-outlined ic-sync",
          exibirNoMenuPrincipal: false,
          gestorEventoClick: new GestorEventoClickUnicaLinha(
            (registro) => onAtualizarRoteiroClick(registro),
            () => gridController
          ),
        };
      }, [gridController, onAtualizarRoteiroClick]);

    const criarBoletimDeInspecaoItemDoMenuDeContexto = useCallback(() => {
      return {
        text: NomesMenuEdicaoOrdemDeProducao.acoes.criarBoletimDeInspecao,
        icon: "ic-material-symbols-outlined ic-lab-profile",
        exibirNoMenuPrincipal: false,
        gestorEventoClick: new GestorEventoClickUnicaLinha(
          (registro) => onCriarBoletimClick(registro),
          () => gridController
        ),
      };
    }, [gridController, onCriarBoletimClick]);

    const desfazerConclusaoItemDoMenuDeContexto = useCallback(() => {
      return {
        text: NomesMenuEdicaoOrdemDeProducao.acoes.desfazerConclusao,
        icon: "ic-material-symbols-outlined ic-remove",
        gestorEventoClick: new GestorEventoClickUnicaLinha(
          definfirOrdemDeProducaoDesfazerConclusao,
          () => gridController
        ),
      };
    }, [definfirOrdemDeProducaoDesfazerConclusao, gridController]);

    const handleImportarBaixasExcelClick = useCallback(() => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesInsumosDasOrdensDeProducao.BaixarEConcluirBaixas,
          PermissoesEstoqueMovimentacao.BaixarInsumosForaDaEstrutura,
        ])
      ) {
        return;
      }

      onBaixaPorExcelClick();
    }, [onBaixaPorExcelClick]);

    const apontarDuracaoDaSessaoItemDoMenuDeContexto = useCallback(() => {
      const gestorEventoClick = new GestorEventoClickUnicaLinha(
        (registro) => onApontarDuracaoDaSessaoClick(registro),
        () => gridController
      );

      return {
        icon: "ic-material-symbols-outlined ic-timer",
        text: NomesMenuEdicaoOrdemDeProducao.acoes.apontarDuracaoDaSessao,
        exibirNoMenuPrincipal: false,
        gestorEventoClick: gestorEventoClick,
      };
    }, [gridController, onApontarDuracaoDaSessaoClick]);

    const criarBoletimDeInspecaoComOperacoesItemDoMenuDeContexto =
      useCallback(() => {
        const gestorEventoClick = new GestorEventoClickUnicaLinha(
          (registro) => onCriarBoletimDeInspecaoComOperacoesClick(registro),
          () => gridController
        );

        return {
          icon: "ic-material-symbols-outlined ic-lab-profile",
          text: NomesMenuEdicaoOrdemDeProducao.acoes
            .criarBoletimDeInspecaoComOperacoes,
          exibirNoMenuPrincipal: false,
          gestorEventoClick: gestorEventoClick,
        };
      }, [gridController, onCriarBoletimDeInspecaoComOperacoesClick]);

    const handleConsultarEstoqueEmElaboracaoClick = useCallback(
      async (registro: OrdemDeProducaoGridModel) => {
        consultarEstoqueEmElaboracao(registro.id);
      },
      []
    );

    const itensMenuDeContexto: ItemContextMenuMxp[] = useMemo(
      () => [
        {
          text: "Imprimir",
          exibirNaLinhaDaGrid: "sempre",
          icon: "ic-material-symbols-outlined ic-print",
          gestorEventoClick: new GestorEventoClickUnicaLinha(
            (registro) => imprimir(registro.id),
            () => gridController
          ),
        },
        {
          ...confirmarOrdemDeProducaoItemDoMenuDeContexto(false),
          exibirNaLinhaDaGrid: "menuDeContexto",
        },
        {
          ...suspenderOrdemDeProducaoItemDoMenuDeContexto(false),
          exibirNaLinhaDaGrid: "menuDeContexto",
        },
        {
          ...cancelarOrdemDeProducaoItemDoMenuDeContexto(),
          exibirNaLinhaDaGrid: "menuDeContexto",
        },
        {
          text: NomesMenuEdicaoOrdemDeProducao.acoes.concluir,
          exibirNaLinhaDaGrid: "menuDeContexto",
          icon: "ic-material-symbols-outlined ic-check",
          gestorEventoClick: new GestorEventoClickUnicaLinha(
            (registros) => onConcluirClick(registros),
            () => gridController
          ),
          beginGroup: true,
        },
        {
          ...desfazerConclusaoItemDoMenuDeContexto(),
          exibirNaLinhaDaGrid: "menuDeContexto",
        },
        {
          ...reservarOrdemDeProducaoItemDoMenuDeContexto(false),
          exibirNaLinhaDaGrid: "menuDeContexto",
          beginGroup: true,
        },
        {
          ...apontarDuracaoDaSessaoItemDoMenuDeContexto(),
          exibirNaLinhaDaGrid: "menuDeContexto",
        },
        {
          ...atualizarRoteiroOrdemDeProducaoItemDoMenuDeContexto(),
          exibirNaLinhaDaGrid: "menuDeContexto",
        },
        {
          ...criarBoletimDeInspecaoComOperacoesItemDoMenuDeContexto(),
          exibirNaLinhaDaGrid: "menuDeContexto",
          beginGroup: true,
        },
        {
          ...criarBoletimDeInspecaoItemDoMenuDeContexto(),
          exibirNaLinhaDaGrid: "menuDeContexto",
        },
        {
          text: NomesMenuEdicaoOrdemDeProducao.acoes.name,
          icon: "ic-material-symbols-outlined ic-vertical",
          exibirNoMenuPrincipal: true,
          items: [
            {
              ...confirmarOrdemDeProducaoItemDoMenuDeContexto(true),
            },
            {
              ...suspenderOrdemDeProducaoItemDoMenuDeContexto(true),
            },
            {
              ...cancelarOrdemDeProducaoItemDoMenuDeContexto(),
            },
            {
              text: NomesMenuEdicaoOrdemDeProducao.acoes.concluir,
              icon: "ic-material-symbols-outlined ic-check",
              gestorEventoClick: new GestorEventoClickUnicaLinha(
                (registros) => onConcluirClick(registros),
                () => gridController
              ),
              beginGroup: true,
            },
            {
              ...desfazerConclusaoItemDoMenuDeContexto(),
            },
            {
              text: NomesMenuEdicaoOrdemDeProducao.acoes.excluir,
              icon: "ic-material-symbols-outlined ic-delete",
              exibirNoMenuPrincipal: true,
              gestorEventoClick: new GestorEventoClickMultiplasLinhas(
                (registros) => onExcluirRegistroClick(registros),
                () => gridController
              ),
              beginGroup: true,
            },
            {
              text: NomesMenuEdicaoOrdemDeProducao.acoes
                .retirarAparaDaOrdemDeProducao,
              icon: "ic-material-symbols-outlined ic-recycling",
              exibirNoMenuPrincipal: false,
              gestorEventoClick: new GestorEventoClickUnicaLinha(
                (registro) => onRetirarAparaClick(registro),
                () => gridController
              ),
              beginGroup: true,
            },
            {
              text: NomesMenuEdicaoOrdemDeProducao.acoes
                .retirarMaterialDaOrdemDeProducao,
              icon: "ic-material-symbols-outlined ic-inventory",
              exibirNoMenuPrincipal: false,
              gestorEventoClick: new GestorEventoClickUnicaLinha(
                (registro) => onRetirarMaterialClick(registro),
                () => gridController
              ),
            },
            {
              ...reservarOrdemDeProducaoItemDoMenuDeContexto(true),
              beginGroup: true,
            },
            {
              ...reservarOrdensDeProducaoComProjecoesDeUsoItemMenuDeContexto(),
            },
            {
              ...apontarDuracaoDaSessaoItemDoMenuDeContexto(),
            },
            {
              ...atualizarRoteiroOrdemDeProducaoItemDoMenuDeContexto(),
            },
            {
              ...criarBoletimDeInspecaoComOperacoesItemDoMenuDeContexto(),
              beginGroup: true,
            },
            {
              ...criarBoletimDeInspecaoItemDoMenuDeContexto(),
            },
            {
              text: NomesMenuEdicaoOrdemDeProducao.acoes
                .importarOrdensDeProducaoDeArquivoExcel,
              icon: "ic-material-symbols-outlined ic-file-upload",
              gestorEventoClick: new GestorEventoClickSimples(
                onImportacaoExcelClick
              ),
              beginGroup: true,
            },
            {
              text: NomesMenuEdicaoOrdemDeProducao.acoes
                .importarBaixasDeInsumosDeArquivoExcel,
              icon: "ic-material-symbols-outlined ic-file-upload",
              gestorEventoClick: new GestorEventoClickSimples(
                handleImportarBaixasExcelClick
              ),
            },
          ],
        },
        {
          text: NomesMenuEdicaoOrdemDeProducao.consultar.name,
          icon: "ic-material-symbols-outlined ic-manage-search",
          exibirNaLinhaDaGrid: "menuDeContexto",
          beginGroup: true,
          exibirNoMenuPrincipal: true,
          items: [
            {
              text: NomesMenuEdicaoOrdemDeProducao.consultar
                .operacoesRoteiroGantt,
              icon: "ic-material-symbols-outlined ic-format-list-bulleted",
              gestorEventoClick: new GestorEventoClickUnicaLinha(
                consultarOperacoesRoteiroGantt,
                () => gridController
              ),
              beginGroup: true,
            },
            {
              text: NomesMenuEdicaoOrdemDeProducao.consultar
                .apontamentoDeOperacoes,
              icon: "ic-material-symbols-outlined ic-pending-actions",
              gestorEventoClick: new GestorEventoClickUnicaLinha(
                consultarApontamentosDeOperacoes,
                () => gridController
              ),
            },
            {
              text: NomesMenuEdicaoOrdemDeProducao.consultar.item,
              icon: "ic-material-symbols-outlined ic-app-registration",
              beginGroup: true,
              gestorEventoClick: new GestorEventoClickUnicaLinha(
                consultarItem,
                () => gridController
              ),
            },
            {
              text: NomesMenuEdicaoOrdemDeProducao.consultar.roteiroDoItem,
              icon: "ic-material-symbols-outlined ic-format-list-bulleted",
              gestorEventoClick: new GestorEventoClickUnicaLinha(
                consultarRoteiroDoItem,
                () => gridController
              ),
            },
            {
              icon: "ic-material-symbols-outlined ic-shelves",
              text: NomesMenuEdicaoOrdemDeProducao.consultar
                .estoqueEmElaboracao,
              name: NomesMenuEdicaoOrdemDeProducao.consultar
                .estoqueEmElaboracao,
              gestorEventoClick: new GestorEventoClickUnicaLinha(
                handleConsultarEstoqueEmElaboracaoClick,
                () => gridController
              ),
              beginGroup: true,
            },
            {
              text: NomesMenuEdicaoOrdemDeProducao.consultar.fichaFuturaDoItem,
              icon: "ic-material-symbols-outlined ic-overview",
              gestorEventoClick: new GestorEventoClickUnicaLinha(
                consultarFichaFuturaDoItem,
                () => gridController
              ),
            },
            {
              text: NomesMenuEdicaoOrdemDeProducao.consultar.movimentacoes,
              icon: "ic-material-symbols-outlined ic-list-alt-check",
              gestorEventoClick: new GestorEventoClickUnicaLinha(
                consultarMovimentacoes,
                () => gridController
              ),
            },
            {
              text: NomesMenuEdicaoOrdemDeProducao.consultar.reservas,
              icon: "ic-material-symbols-outlined ic-keep",
              gestorEventoClick: new GestorEventoClickUnicaLinha(
                consultarReservas,
                () => gridController
              ),
            },
          ],
        },
      ],
      [
        confirmarOrdemDeProducaoItemDoMenuDeContexto,
        suspenderOrdemDeProducaoItemDoMenuDeContexto,
        cancelarOrdemDeProducaoItemDoMenuDeContexto,
        desfazerConclusaoItemDoMenuDeContexto,
        reservarOrdemDeProducaoItemDoMenuDeContexto,
        apontarDuracaoDaSessaoItemDoMenuDeContexto,
        atualizarRoteiroOrdemDeProducaoItemDoMenuDeContexto,
        criarBoletimDeInspecaoComOperacoesItemDoMenuDeContexto,
        criarBoletimDeInspecaoItemDoMenuDeContexto,
        reservarOrdensDeProducaoComProjecoesDeUsoItemMenuDeContexto,
        onImportacaoExcelClick,
        handleImportarBaixasExcelClick,
        consultarOperacoesRoteiroGantt,
        consultarApontamentosDeOperacoes,
        consultarItem,
        handleConsultarEstoqueEmElaboracaoClick,
        imprimir,
        gridController,
        onConcluirClick,
        onExcluirRegistroClick,
        onRetirarAparaClick,
        onRetirarMaterialClick,
        consultarFichaFuturaDoItem,
        consultarMovimentacoes,
        consultarRoteiroDoItem,
        consultarReservas,
      ]
    );

    const menuContexto = useMenuDeContextosGrid(
      itensMenuDeContexto,
      true,
      gridController
    );

    const configuracoesGrid = useMemo(() => {
      return GridBuilder.criar(
        "ordem-de-producao",
        () => gridRef.current?.instance(),
        false,
        filtrosNoCliente
      )
        .definirStyles(style)
        .definirDataSource(dataSource)
        .definirFiltros()
        .definirRolagem()
        .configurarAgrupamento()
        .configurarSelecionadorDeColunas()
        .definirGravacaoPreferenciasGrid()
        .definirPaginacao()
        .configurarExportacao(NomesTelas.ordensProducao)
        .definirBotaoNovo(handleNovoRegistro)
        .definirBotaoRefresh(handleAtualizarGrid)
        .definirSelecao()
        .definirOrdenacao()
        .definirMenuDeContexto(menuContexto)
        .definirDuploCliqueLinha(handleEditarRegistro)
        .build();
    }, [
      dataSource,
      filtrosNoCliente,
      gridRef,
      handleAtualizarGrid,
      handleEditarRegistro,
      handleNovoRegistro,
      itensMenuDeContexto,
      style,
      menuContexto,
    ]);

    const headerFilterRelacionamentos =
      gerarHeaderFilterRelacionamento(nomesRelacionamentos);

    const headerFilterProjecoesDeUso = gerarHeaderFilterRelacionamento(
      nomesProjecoesDeUso,
      OrdemDeProducaoTipoRelacionamento.ProjecaoDeUso
    );

    const headerFilterReservas = gerarHeaderFilterRelacionamento(
      nomesReservas,
      OrdemDeProducaoTipoRelacionamento.Reserva
    );

    const headerFilterReservasAtendidas = gerarHeaderFilterRelacionamento(
      nomesReservasAtendidas,
      OrdemDeProducaoTipoRelacionamento.ReservaAtendida
    );

    useSeletorGridMxp(() => gridRef.current, filtrosNoCliente);

    useRegistrarAtalhosGrid<OrdemDeProducaoGridModel>({
      controller: gridController,
      handleNovo: handleNovoRegistro,
      handleEditar: handleEditarRegistro,
      handleExcluir: handleExcluirRegistro,
    });

    return (
      <>
        <DataGrid ref={gridRef} {...configuracoesGrid}>
          <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
          {GridColunaAcoes<OrdemDeProducaoGridModel>({
            handleEditar: onEditarRegistroClick,
            handleExcluir: handleExcluirRegistro,
          })}
          <Column
            key={nameOfGridHandler("numero")}
            dataField={nameOfGridHandler("numero")}
            {...obterConfiguracaoColuna("codigo")}
            caption="Número"
            width={120}
            alignment="right"
            sortOrder="desc"
            sortIndex={0}
          />
          <Column
            key={nameOfGridHandler("itemCodigo")}
            dataField={nameOfGridHandler("itemCodigo")}
            {...obterConfiguracaoColuna("stringG")}
            caption="Item"
          />
          <Column
            key={nameOfGridHandler("itemDescricao")}
            dataField={nameOfGridHandler("itemDescricao")}
            {...obterConfiguracaoColuna("stringXG")}
            caption="Descrição"
          />
          <Column
            key={nameOfGridHandler("itemDescricaoComplementar")}
            dataField={nameOfGridHandler("itemDescricaoComplementar")}
            {...obterConfiguracaoColuna("stringXG")}
            caption="Descrição complementar"
            visible={false}
          />
          <Column
            key={nameOfGridHandler("grupoCodigo")}
            dataField={nameOfGridHandler("grupoCodigo")}
            {...obterConfiguracaoColuna("stringG")}
            caption="Grupo"
            visible={false}
          />
          <Column
            key={nameOfGridHandler("grupoDescricao")}
            dataField={nameOfGridHandler("grupoDescricao")}
            {...obterConfiguracaoColuna("stringXG")}
            caption="Descrição do grupo"
            visible={false}
          />
          <Column
            key={nameOfGridHandler("itemDesenho")}
            dataField={nameOfGridHandler("itemDesenho")}
            {...obterConfiguracaoColuna("stringG")}
            caption="Desenho"
            visible={false}
          />
          <Column
            key={nameOfGridHandler("itemDesenhoRevisao")}
            dataField={nameOfGridHandler("itemDesenhoRevisao")}
            {...obterConfiguracaoColuna("stringG")}
            caption="Revisão do desenho"
            visible={false}
          />
          <Column
            key={nameOfGridHandler("quantidade")}
            dataField={nameOfGridHandler("quantidade")}
            {...obterConfiguracaoColuna("quantidade")}
          />
          <Column
            key={nameOfGridHandler("quantidadeDePerdaPrevista")}
            dataField={nameOfGridHandler("quantidadeDePerdaPrevista")}
            {...obterConfiguracaoColuna("quantidade")}
            caption="Qt de perda prevista"
            visible={false}
            width={180}
          />
          <Column
            key={nameOfGridHandler("quantidadeBruta")}
            dataField={nameOfGridHandler("quantidadeBruta")}
            {...obterConfiguracaoColuna("quantidade")}
            caption="Qt bruta"
            visible={false}
          />
          <Column
            key={nameOfGridHandler("itemUnidadePrincipalCodigo")}
            dataField={nameOfGridHandler("itemUnidadePrincipalCodigo")}
            {...obterConfiguracaoColuna("stringPP")}
            caption="Unid"
            width={95}
          />
          <Column
            key={nameOfGridHandler("quantidadeNaUnidadeDeVenda")}
            dataField={nameOfGridHandler("quantidadeNaUnidadeDeVenda")}
            {...obterConfiguracaoColuna("quantidade")}
            caption="Qt na unidade de venda"
            visible={false}
            width={195}
          />
          <Column
            key={nameOfGridHandler("itemUnidadeDeVendaCodigo")}
            dataField={nameOfGridHandler("itemUnidadeDeVendaCodigo")}
            {...obterConfiguracaoColuna("stringP")}
            caption="Unid de venda"
            visible={false}
            width={145}
          />
          <Column
            key={nameOfGridHandler("necessidadeData")}
            dataField={nameOfGridHandler("necessidadeData")}
            {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
            caption="Necessidade"
            width={130}
          />
          <Column
            key={nameOfGridHandler("estado")}
            dataField={nameOfGridHandler("estado")}
            {...obterConfiguracaoColuna("stringM")}
            dataType="number"
            lookup={OrdemDeProducaoEstadoHelper.AsLookup()}
            cellRender={OrdemDeProducaoEstadoAsCellRender}
            caption="Estado"
          />
          <Column
            key={nameOfGridHandler("estadoConfiguravel")}
            dataField={nameOfGridHandler("estadoConfiguravel")}
            {...obterConfiguracaoColuna("stringMG")}
            caption="Estado configurável"
            visible={false}
          />
          <Column
            key={nameOfGridHandler("quantidadeConcluida")}
            dataField={nameOfGridHandler("quantidadeConcluida")}
            {...obterConfiguracaoColuna("quantidade")}
            caption="Qt concluída"
          />
          <Column
            key={nameOfGridHandler("itemPesoLiquidoUnitario")}
            dataField={nameOfGridHandler("itemPesoLiquidoUnitario")}
            {...obterConfiguracaoColuna("peso")}
            caption="Peso líquido unitário (Kg)"
            visible={false}
            width={200}
          />
          <Column
            key={nameOfGridHandler("pesoLiquidoTotal")}
            dataField={nameOfGridHandler("pesoLiquidoTotal")}
            {...obterConfiguracaoColuna("peso")}
            caption="Peso líquido total (Kg)"
            visible={false}
            width={195}
          />
          <Column
            key={nameOfGridHandler("itemPesoBrutoUnitario")}
            dataField={nameOfGridHandler("itemPesoBrutoUnitario")}
            {...obterConfiguracaoColuna("peso")}
            caption="Peso bruto unitário (kg)"
            visible={false}
            width={180}
          />
          <Column
            key={nameOfGridHandler("pesoBrutoTotal")}
            dataField={nameOfGridHandler("pesoBrutoTotal")}
            {...obterConfiguracaoColuna("peso")}
            caption="Peso bruto total (kg)"
            visible={false}
            width={180}
          />
          <Column
            key={nameOfGridHandler("quantidadeAConcluir")}
            dataField={nameOfGridHandler("quantidadeAConcluir")}
            {...obterConfiguracaoColuna("quantidade")}
            caption="Qt a concluir"
            visible={false}
          />
          <Column
            key={nameOfGridHandler("emissaoData")}
            dataField={nameOfGridHandler("emissaoData")}
            {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
            caption="Emissão"
            visible={false}
            width={100}
          />
          <Column
            key={nameOfGridHandler("emitidoPorApelido")}
            dataField={nameOfGridHandler("emitidoPorApelido")}
            {...obterConfiguracaoColuna("stringM")}
            caption="Emitido por"
            visible={false}
          />
          <Column
            key={nameOfGridHandler("quantidadeSobra")}
            dataField={nameOfGridHandler("quantidadeSobra")}
            {...obterConfiguracaoColuna("quantidade")}
            caption="Qt sobra"
            visible={false}
          />
          <Column
            key={nameOfGridHandler("origemDecodificada")}
            dataField={nameOfGridHandler("origemDecodificada")}
            {...obterConfiguracaoColuna("stringM")}
            caption="Origem"
            visible={false}
          />
          <Column
            key="relacionamentos" // Não usa nameOfGridHandler por ser uma coluna de combinação
            name="relacionamentos"
            {...obterConfiguracaoColuna("chips")}
            caption="Item do pedido / cliente"
            width={350}
            cellRender={({
              data,
            }: ColumnCellTemplateData<OrdemDeProducaoGridModel>) =>
              data
                ? renderChips([
                    // Queremos somente as projeções de uso que não tem reservas.
                    ...(data.projecoesDeUso?.filter(
                      (x) =>
                        !data.reservas.find(
                          (y) =>
                            y.idItemDoPedidoDeVenda == x.idItemDoPedidoDeVenda
                        )
                    ) ?? []),
                    ...(data.reservas ?? []),
                    ...(data.reservasAtendidas ?? []),
                  ])
                : null
            }
            cssClass={ChipCellClass}
            allowFiltering={true}
            headerFilter={headerFilterRelacionamentos.headerFilter}
            calculateFilterExpression={FuncFilterExpressionReservas(
              nomesRelacionamentos
            )}
          />
          <Column
            key={nameOfGridHandler("projecoesDeUso")}
            dataField={nameOfGridHandler("projecoesDeUso")}
            {...obterConfiguracaoColuna("chips")}
            caption="Projeções de uso"
            visible={false}
            cellRender={({ value }: ColumnCellTemplateData) =>
              renderChips(value)
            }
            cssClass={ChipCellClass}
            allowFiltering={true}
            headerFilter={headerFilterProjecoesDeUso.headerFilter}
            calculateFilterExpression={FuncFilterExpressionReservas(
              nomesProjecoesDeUso
            )}
          />
          <Column
            key={nameOfGridHandler("reservas")}
            dataField={nameOfGridHandler("reservas")}
            {...obterConfiguracaoColuna("chips")}
            caption="Reservas"
            visible={false}
            cellRender={({ value }: ColumnCellTemplateData) =>
              renderChips(value)
            }
            cssClass={ChipCellClass}
            allowFiltering={true}
            headerFilter={headerFilterReservas.headerFilter}
            calculateFilterExpression={FuncFilterExpressionReservas(
              nomesReservas
            )}
          />
          <Column
            key={nameOfGridHandler("reservasAtendidas")}
            dataField={nameOfGridHandler("reservasAtendidas")}
            {...obterConfiguracaoColuna("chips")}
            caption="Reservas atendidas"
            visible={false}
            cellRender={({ value }: ColumnCellTemplateData) =>
              renderChips(value)
            }
            cssClass={ChipCellClass}
            allowFiltering={true}
            headerFilter={headerFilterReservasAtendidas.headerFilter}
            calculateFilterExpression={FuncFilterExpressionReservas(
              nomesReservasAtendidas
            )}
          />
          <Column
            key={nameOfGridHandler("inicioPrevistoData")}
            dataField={nameOfGridHandler("inicioPrevistoData")}
            {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
            width={137}
            caption="Início previsto"
            visible={false}
          />
          <Column
            key={nameOfGridHandler("fimPrevistoData")}
            dataField={nameOfGridHandler("fimPrevistoData")}
            {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
            width={137}
            caption="Fim previsto"
            visible={false}
          />
          <Column
            key={nameOfGridHandler("inicioRealizadoData")}
            dataField={nameOfGridHandler("inicioRealizadoData")}
            {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
            width={137}
            caption="Início realizado"
            visible={false}
          />
          <Column
            key={nameOfGridHandler("fimRealizadoData")}
            dataField={nameOfGridHandler("fimRealizadoData")}
            {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
            width={137}
            caption="Fim realizado"
            visible={false}
          />
          <Column
            key={nameOfGridHandler("tempoRealizadoEmHoras")}
            dataField={nameOfGridHandler("tempoRealizadoEmHoras")}
            {...obterConfiguracaoColuna("quantidade")}
            caption="Tempo realizado (h)"
            visible={false}
            width={160}
          />
          <Column
            key={nameOfGridHandler("tempoPrevistoEmHoras")}
            dataField={nameOfGridHandler("tempoPrevistoEmHoras")}
            {...obterConfiguracaoColuna("quantidade")}
            caption="Tempo previsto (h)"
            visible={false}
            width={160}
          />
          <Column
            key={nameOfGridHandler("quantidadeOuTempoRealizadoEmPercentual")}
            dataField={nameOfGridHandler(
              "quantidadeOuTempoRealizadoEmPercentual"
            )}
            {...obterConfiguracaoColuna("percentual")}
            caption="Tempo realizado (%)"
            visible={false}
            width={180}
          />
          <Column
            key={nameOfGridHandler("quantidadeConcluidaEmPercentual")}
            dataField={nameOfGridHandler("quantidadeConcluidaEmPercentual")}
            {...obterConfiguracaoColuna("percentual")}
            caption="Qt concluída (%)"
            visible={false}
            width={148}
          />
          <Column
            key={nameOfGridHandler("maoDeObraCusto")}
            dataField={nameOfGridHandler("maoDeObraCusto")}
            {...obterConfiguracaoColuna("monetario")}
            caption="Custo da mão de obra"
            visible={false}
            width={180}
          />
          <Column
            key={nameOfGridHandler("observacoes")}
            dataField={nameOfGridHandler("observacoes")}
            {...obterConfiguracaoColuna("stringXXG")}
            caption="Observações"
            visible={false}
          />
          <Column
            key={nameOfGridHandler("insumosDisponibilidadeDecodificada")}
            dataField={nameOfGridHandler("insumosDisponibilidadeDecodificada")}
            {...obterConfiguracaoColuna("stringM")}
            caption="Disponibilidade dos insumos"
            visible={false}
            width={230}
          />
          <Column
            key={nameOfGridHandler("insumosBaixasEstadoDecodificado")}
            dataField={nameOfGridHandler("insumosBaixasEstadoDecodificado")}
            {...obterConfiguracaoColuna("stringM")}
            caption="Estado das baixas dos insumos"
            visible={false}
            width={230}
          />
          <Column
            key={nameOfGridHandler("itemPontoDeRessuprimento")}
            dataField={nameOfGridHandler("itemPontoDeRessuprimento")}
            {...obterConfiguracaoColuna("quantidade")}
            caption="Ponto de ressuprimento"
            visible={false}
            width={180}
          />
          <Column
            key={nameOfGridHandler("quantidadeDeItemEmEstoqueProprio")}
            dataField={nameOfGridHandler("quantidadeDeItemEmEstoqueProprio")}
            {...obterConfiguracaoColuna("quantidade")}
            caption="Estoque próprio"
            visible={false}
            width={160}
          />
          <Column
            key={nameOfGridHandler("estocagemDoProduto")}
            dataField={nameOfGridHandler("estocagemDoProduto")}
            {...obterConfiguracaoColuna("quantidade")}
            caption="Estocagem por"
            lookup={EstocagemDoProdutoHelper.AsLookup()}
            visible={false}
            width={160}
          />
          {headerFilterRelacionamentos.colunas}
          {headerFilterProjecoesDeUso.colunas}
          {headerFilterReservas.colunas}
          {headerFilterReservasAtendidas.colunas}
          {GetColunasDeAuditoria(true, false, [
            { ordenarPor: "criacaoData", ordem: "desc" },
          ])}
        </DataGrid>
      </>
    );
  }
);

const nomesProjecoesDeUso = [nameOfGridHandler("projecoesDeUso")];

const nomesReservas = [nameOfGridHandler("reservas")];

const nomesReservasAtendidas = [nameOfGridHandler("reservasAtendidas")];

const nomesRelacionamentos = [
  ...nomesProjecoesDeUso,
  ...nomesReservas,
  ...nomesReservasAtendidas,
];

const FuncFilterExpressionReservas =
  (nomesPropriedades: string[]) =>
  (filterValue: any, selectedFilterOperation: string | null) => {
    if (!filterValue) return [] as Array<any>;

    return nomesPropriedades.reduce((acc, nome, index) => {
      const filterExpression = [
        `${nome}.relacionamentoDecodificado`,
        selectedFilterOperation,
        filterValue,
      ];

      if (index === 0) {
        return filterExpression;
      }

      return [acc, "or", filterExpression];
    }, [] as any[]);
  };
