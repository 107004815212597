import DataGrid, { Column, DataGridRef } from "devextreme-react/data-grid";
import { useCallback, useMemo, useRef } from "react";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import GridColunaAcoes from "../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import { useRegistrarAtalhosGrid } from "../../../../../hooks/atalhos.hooks";
import { useExcluirRegistroGrid } from "../../../../../hooks/grid.hooks";
import { useSeletorGridMxp } from "../../../../../hooks/seletor.hooks";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import { JanelasDeNotificacaoTitulos } from "../../../../../utils/common/notificacoes-utils";
import { exibirAlerta } from "../../../../../utils/dialogos";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import CondutorSelecaoGridModel from "../../models/usuario";
import { UsuarioServico } from "../../servicos/usuario.service";

const dataSource =
  UsuarioServico.ObterDataSourceParaGrid<CondutorSelecaoGridModel>();

const nameOfGridHandler = criarNameof<CondutorSelecaoGridModel>();

export default function GridSelecaoCondutor() {
  const gridRef = useRef<DataGridRef>(null);

  useSeletorGridMxp(() => gridRef.current);

  const handleNovoRegistro = useCallback(() => {
    exibirNaoImplementado();
  }, []);

  const handleEditarRegistro = useCallback(() => {
    exibirNaoImplementado();
  }, []);

  const handleExcluirRegistro = useExcluirRegistroGrid(() => {
    exibirNaoImplementado();
  });

  function exibirNaoImplementado() {
    exibirAlerta(
      JanelasDeNotificacaoTitulos.Atencao,
      "Funcionalidade não implementada"
    );
  }

  const gridController = useMemo(
    () =>
      new GridController<CondutorSelecaoGridModel>(() =>
        gridRef.current?.instance()
      ),
    []
  );

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar("grid-selecao-condutor", () =>
      gridRef.current?.instance()
    )
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(NomesTelas.condutores)
      .definirBotaoNovo(handleNovoRegistro)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirSelecao("multiple")
      .definirOrdenacao()
      .definirDuploCliqueLinha(handleEditarRegistro)
      .build();
  }, []);

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  useRegistrarAtalhosGrid<CondutorSelecaoGridModel>({
    controller: gridController,
    handleNovo: handleNovoRegistro,
    handleEditar: handleEditarRegistro,
    handleExcluir: handleExcluirRegistro,
  });

  return (
    <>
      <ProvedorAjuda id={"tooltips-grid-conjunto-veiculo"}>
        <DataGrid ref={gridRef} {...configuracoesGrid}>
          {GridColunaAcoes<CondutorSelecaoGridModel>({
            handleEditar: handleEditarRegistro,
            handleExcluir: handleExcluirRegistro,
          })}
          <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
          <Column
            key={nameOfGridHandler("apelido")}
            dataField={nameOfGridHandler("apelido")}
            {...obterConfiguracaoColuna("apelido")}
          />
          ,
          <Column
            key={nameOfGridHandler("nome")}
            dataField={nameOfGridHandler("nome")}
            {...obterConfiguracaoColuna("razaoSocial")}
            caption="Nome"
            width={400}
          />
          ,
          <Column
            key={nameOfGridHandler("cpfCnpj")}
            dataField={nameOfGridHandler("cpfCnpj")}
            {...obterConfiguracaoColuna("cpfCnpj")}
            caption="CPF"
          />
          ,
        </DataGrid>
      </ProvedorAjuda>
    </>
  );
}
