import { yupResolver } from "@hookform/resolvers/yup";
import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { useForm } from "react-hook-form";
import {
  FormSelectBox,
  FormTextBox,
} from "../../../../../../components/formularios";
import { Coluna, Linha } from "../../../../../../components/layout/grid-system";
import TabContainer from "../../../../../../components/layout/tab-container";
import Sessao from "../../../../../../components/organizacao/sessao";
import { SelectItemEnumResponsavelSeguroTipo } from "../../../../../../models/const/dicionario-combos/mdfe";
import { IFormulario } from "../../../../../../models/shared/ui/formularios";
import { MDFeAbaSeguroViewModel } from "../../../../../../models/viewmodels/vendas/mdfe/mdfe-edit-form-view-model";
import { ComponentAsyncLoader } from "../../../../../../parts/utils/load-on-demand";
import { previneDigitacaoDeCaracteres } from "../../../../../../utils/common/common-utils";
import { checarSeFormFoiModificado } from "../../../../../../utils/common/form-utils";
import { digitosNumericosSemEspaco } from "../../../../../../utils/common/regex-padrao";
import { GridProps } from "../../../../../../utils/grid/grid-utils";
import yup from "../../../../../../utils/validacao/validacao";
import { ComboEmpresaPessoaJuridicaMxp } from "../../../../../sistema/empresa/componentes/select-box-lazy";
import MDFeEditFormContext from "../../../contexts/mdfe-editform.context";
import {
  ResponsavelSeguroTipo,
  TipoEmitenteMDFe,
} from "../../../models/mdfe-enums";
import MDFeConstantes from "../../../models/mdfe.constantes";
import { GridAverbacoes } from "./grid-averbacao";

interface SeguroProps extends GridProps {
  somenteLeitura: boolean;
}

export const MDFeAbaSeguro = forwardRef(
  ({ somenteLeitura }: SeguroProps, ref) => {
    const { baseMdfe, dadosGerais, seguro, averbacoes, definirDadosSeguro } =
      useContext(MDFeEditFormContext);

    const model: MDFeAbaSeguroViewModel = useMemo(() => {
      return {
        id: seguro.id,
        idEmpresaSeguradora: seguro.idEmpresaSeguradora,
        responsavelSeguro: seguro.responsavelSeguro,
        numeroApolice: seguro.numeroApolice,
      };
    }, [seguro]);

    const obrigadoInformarSeguro =
      dadosGerais.tipoEmitente == TipoEmitenteMDFe.PrestadorServico;

    const averbacaoInformada = averbacoes.length > 0;

    const schema = useMemo(
      () =>
        yup.object().shape({
          id: yup.number().required().moreThan(-1).integer(),
          idEmpresaSeguradora: yup
            .number()
            .moreThan(-1)
            .integer()
            .when("responsavelSeguro", {
              is: ResponsavelSeguroTipo.Contratante,
              then: (sch) => sch.required(),
              otherwise: (sch) => sch.nullable(),
            }),
          responsavelSeguro: yup
            .mixed<ResponsavelSeguroTipo>()
            .oneOf(
              Object.values(ResponsavelSeguroTipo).map((x) => x as number),
              "Valor inválido"
            )
            .when({
              is: () => obrigadoInformarSeguro,
              then: (sch) => sch.required(),
              otherwise: (sch) => sch.nullable(),
            })
            .test(
              "responsavel_seguro_obrigatorio_1",
              "Obrigatório, pois a apólice está preenchida.",
              function (responsavelSeguro, contexto) {
                const numeroApolice = contexto.parent.numeroApolice as string;
                if (
                  !responsavelSeguro &&
                  numeroApolice &&
                  !averbacaoInformada
                ) {
                  return false;
                }
                return true;
              }
            )
            .test(
              "responsavel_seguro_obrigatorio_2",
              "Obrigatório, pois dados de averbação estão preenchidos.",
              function (responsavelSeguro, contexto) {
                const numeroApolice = contexto.parent.numeroApolice as string;
                if (
                  !responsavelSeguro &&
                  !numeroApolice &&
                  averbacaoInformada
                ) {
                  return false;
                }
                return true;
              }
            )
            .test(
              "responsavel_seguro_obrigatorio_3",
              "Obrigatório, pois dados de averbação e a apólice estão preenchidas.",
              function (responsavelSeguro, contexto) {
                const numeroApolice = contexto.parent.numeroApolice as string;
                if (!responsavelSeguro && numeroApolice && averbacaoInformada) {
                  return false;
                }
                return true;
              }
            ),
          numeroApolice: yup
            .string()
            .max(MDFeConstantes.ApoliceTamanhoMaximo)
            .when({
              is: () => obrigadoInformarSeguro,
              then: (sch) => sch.required(),
              otherwise: (sch) => sch.notRequired(),
            })
            .test(
              "numeroApolice_obrigatorio_1",
              "Obrigatório, pois o responsável do seguro está preenchido.",
              function (numeroApolice, contexto) {
                const responsavelSeguro = contexto.parent
                  .responsavelSeguro as string;
                if (
                  !numeroApolice &&
                  responsavelSeguro &&
                  !averbacaoInformada
                ) {
                  return false;
                }
                return true;
              }
            )
            .test(
              "numeroApolice_obrigatorio_2",
              "Obrigatório, pois dados de averbação estão preenchidos.",
              function (numeroApolice, contexto) {
                const responsavelSeguro = contexto.parent
                  .responsavelSeguro as string;
                if (
                  !numeroApolice &&
                  !responsavelSeguro &&
                  averbacaoInformada
                ) {
                  return false;
                }
                return true;
              }
            )
            .test(
              "numeroApolice_obrigatorio_3",
              "Obrigatório, pois dados de averbação e o responsável do seguro estão preenchidos.",
              function (numeroApolice, contexto) {
                const responsavelSeguro = contexto.parent
                  .responsavelSeguro as string;
                if (!numeroApolice && responsavelSeguro && averbacaoInformada) {
                  return false;
                }
                return true;
              }
            ),
        }),
      [obrigadoInformarSeguro, averbacaoInformada]
    );

    const { formState, control, handleSubmit, reset, watch, register } =
      useForm<MDFeAbaSeguroViewModel>({
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: yupResolver(schema),
      });

    const seguradoraObrigatoria =
      watch("responsavelSeguro") == ResponsavelSeguroTipo.Contratante;

    const carregarTela = useCallback(async () => {
      reset(model);
    }, [reset, model]);

    useEffect(() => {
      if (!Number.isNaN(baseMdfe.id)) {
        carregarTela();
      }
    }, [baseMdfe.id]);

    const form = useRef<HTMLFormElement | null>(null);

    // Repassar referências para componente pai
    useImperativeHandle(
      ref,
      (): IFormulario => ({
        requestSubmit() {
          form.current?.requestSubmit();
        },
        valido() {
          form.current?.requestSubmit();
          return Object.keys(formState.errors).length == 0;
        },
        isDirty() {
          return checarSeFormFoiModificado(formState);
        },
      }),
      [formState, form]
    );

    const handleSubmitInterno = useCallback(
      (data: MDFeAbaSeguroViewModel) => {
        definirDadosSeguro(data);
      },
      [definirDadosSeguro]
    );

    const previneDigitacaoDeCaracteresHandler = (e: any) =>
      previneDigitacaoDeCaracteres(e, digitosNumericosSemEspaco);

    return (
      <TabContainer>
        <Sessao>
          <form ref={form} onSubmit={handleSubmit(handleSubmitInterno)}>
            <input type="hidden" {...register("id")} defaultValue={0} />
            <Linha>
              <Coluna md={3}>
                <FormSelectBox
                  name="responsavelSeguro"
                  titulo="Responsável"
                  toolTip="Responsável"
                  control={control}
                  requerido={obrigadoInformarSeguro}
                  dataSource={SelectItemEnumResponsavelSeguroTipo}
                  habilitaBusca
                  tipoBusca="contains"
                  somenteLeitura={somenteLeitura}
                />
              </Coluna>
              <Coluna md={6}>
                <ComboEmpresaPessoaJuridicaMxp
                  name="idEmpresaSeguradora"
                  titulo="Seguradora"
                  control={control}
                  requerido={seguradoraObrigatoria}
                  somenteLeitura={somenteLeitura}
                />
              </Coluna>
              <Coluna md={3}>
                <FormTextBox
                  name="numeroApolice"
                  titulo="Apólice"
                  toolTip="Apólice"
                  control={control}
                  requerido={obrigadoInformarSeguro}
                  somenteLeitura={somenteLeitura}
                  tamanhoMaximo={MDFeConstantes.ApoliceTamanhoMaximo}
                  onKeyDown={previneDigitacaoDeCaracteresHandler}
                />
              </Coluna>
            </Linha>
          </form>
        </Sessao>
        <ComponentAsyncLoader>
          <GridAverbacoes somenteLeitura={somenteLeitura} />
        </ComponentAsyncLoader>
      </TabContainer>
    );
  }
);
