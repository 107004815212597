import DataGrid, { DataGridRef } from "devextreme-react/data-grid";
import { useCallback, useMemo, useRef } from "react";
import ProvedorAjuda from "../../../../../../components/ajuda/provedor-ajuda";
import { useSeletorGridMxp } from "../../../../../../hooks/seletor.hooks";
import { EstoqueGridModel } from "../../../../../../models/api/estoque/estoque";
import { GridMxpProps } from "../../../../../../models/shared/ui/grid";
import NomesTelas from "../../../../../../utils/common/nomes-telas";
import GridBuilder from "../../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../../utils/grid/grid-controller";
import { EstoqueServico } from "../../../service/estoque.service";
import { colunasEstoques } from "../colunasEstoque";

export default function GridEstoque(props: GridMxpProps<EstoqueGridModel>) {
  const gridRef = useRef<DataGridRef>(null);

  const dataSource = useMemo(
    () =>
      EstoqueServico.ObterDataSourceParaGrid<EstoqueGridModel>(
        props.filtrosNoServidor
      ),
    [props.filtrosNoServidor]
  );

  useSeletorGridMxp(() => gridRef.current, props.filtrosNoCliente);

  const gridController = useMemo(
    () =>
      new GridController<EstoqueGridModel>(() => gridRef.current?.instance()),
    []
  );

  const handleAtualizarGrid = useCallback(() => {
    gridController.atualizar();
  }, [gridController]);

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar(
      "estoque",
      () => gridRef.current?.instance(),
      false,
      props.filtrosNoCliente
    )
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(NomesTelas.estoque)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirSelecao()
      .definirOrdenacao()
      .build();
  }, [props.filtrosNoCliente, dataSource, handleAtualizarGrid]);

  return (
    <ProvedorAjuda id={"tooltips-grid-estoque"}>
      <DataGrid ref={gridRef} {...configuracoesGrid}>
        {colunasEstoques}
      </DataGrid>
    </ProvedorAjuda>
  );
}
