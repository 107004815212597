import { FieldValues, UseControllerProps } from "react-hook-form";
import { FormSelectBoxLazyMxp } from "../../../../../../components/formularios";
import { assertConfiguracaoExibicaoEBuscaType } from "../../../../../../components/formularios/selectbox-lazy-mxp";
import { UsuarioGridModel } from "../../../models/usuario";
import { UsuarioServico } from "../../../servicos/usuario.service";
import GridUsuario from "../../grid";

interface ComboUsuarioMxpProps<T extends FieldValues>
  extends UseControllerProps<T> {
  titulo: string;
  tituloSeletor?: string;
  requerido?: boolean;
  somenteLeitura?: boolean;
}

const dataSourceUsuarioGrid = UsuarioServico.GetDataSourceSelectBoxLazy({
  camposRetorno: ["id", "apelido"],
  camposOrdenacao: [
    {
      nomeCampo: "apelido",
      desc: false,
    },
  ],
});

const usuarioGridExibicaoEBusca =
  assertConfiguracaoExibicaoEBuscaType<UsuarioGridModel>({
    expressaoDeBusca: ["apelido"],
    nomeCampoChave: "id",
    nomeCampoExibicao: (c) => {
      if (c) {
        return `${c.apelido}`;
      }

      return "";
    },
  });

export default function ComboUsuarioMxp<T extends FieldValues>(
  props: ComboUsuarioMxpProps<T>
) {
  return (
    <FormSelectBoxLazyMxp
      name={props.name}
      titulo={props.titulo}
      control={props.control}
      dataSource={dataSourceUsuarioGrid}
      configuracoesExibicaoEBusca={usuarioGridExibicaoEBusca}
      seletorConfig={{
        modo: "selecaoUnica",
        titulo: props.tituloSeletor || "Selecione um usuário",
        componenteGrid: <GridUsuario />,
      }}
      labelSemDados="Sem dados"
      requerido={props.requerido}
      somenteLeitura={props.somenteLeitura}
    />
  );
}
