import { DataGrid } from "devextreme-react";
import { Column, DataGridRef } from "devextreme-react/data-grid";
import { useCallback, useMemo, useRef, useState } from "react";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import GridColunaAcoes from "../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import { useRegistrarAtalhosGrid } from "../../../../../hooks/atalhos.hooks";
import { useSeletorGridMxp } from "../../../../../hooks/seletor.hooks";
import { GridMxpProps } from "../../../../../models/shared/ui/grid";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesModais from "../../../../../utils/common/nomes-modais";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import NormalizaTituloModal from "../../../../../utils/common/normaliza-titulo";
import { JanelasDeNotificacaoTitulos } from "../../../../../utils/common/notificacoes-utils";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import { EmpresaGridModel } from "../../models/empresa-grid";
import { EmpresaServico } from "../../servicos/empresa.service";

const nameOfGridHandler = criarNameof<EmpresaGridModel>();

export default function GridEmpresa(props: GridMxpProps<EmpresaGridModel>) {
  const gridRef = useRef<DataGridRef>(null);
  const [idRegistroEdicao, setIdRegistroEdicao] = useState(NaN);

  const dataSource = EmpresaServico.ObterDataSourceParaGrid<EmpresaGridModel>(
    props.filtrosNoServidor
  );

  useSeletorGridMxp(() => gridRef.current, props.filtrosNoCliente);

  const gridController = new GridController<EmpresaGridModel>(() =>
    gridRef.current?.instance()
  );

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  const handleFecharModal = useCallback(() => {
    setIdRegistroEdicao(NaN);
  }, []);

  const handleNovoRegistro = useCallback(() => {
    setIdRegistroEdicao(0);
  }, []);

  const handleEditarRegistro = useCallback((registro: EmpresaGridModel) => {
    setIdRegistroEdicao(registro.id);
  }, []);

  const handleExcluirRegistro = useCallback(() => {
    exibirConfirmacao(
      JanelasDeNotificacaoTitulos.Atencao,
      "Funcionalidade não implementada"
    );
  }, []);

  useRegistrarAtalhosGrid<EmpresaGridModel>({
    controller: gridController,
    handleNovo: handleNovoRegistro,
    handleEditar: handleEditarRegistro,
    handleExcluir: handleExcluirRegistro,
  });

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar(
      "empresa",
      () => gridRef.current?.instance(),
      false,
      props.filtrosNoCliente
    )
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(NomesTelas.certificadoDigital)
      .definirBotaoNovo(handleNovoRegistro)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirSelecao()
      .definirOrdenacao()
      .definirDuploCliqueLinha(handleEditarRegistro)
      .build();
  }, [props.filtrosNoCliente]);

  return (
    <>
      <ProvedorAjuda id={"tooltips-grid-empresas"}>
        <DataGrid ref={gridRef} {...configuracoesGrid}>
          <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
          {GridColunaAcoes<EmpresaGridModel>({
            handleEditar: handleEditarRegistro,
            handleExcluir: handleExcluirRegistro,
          })}
          <Column
            key={nameOfGridHandler("id")}
            dataField={nameOfGridHandler("id")}
            {...obterConfiguracaoColuna("id")}
          />
          <Column
            key={nameOfGridHandler("apelido")}
            dataField={nameOfGridHandler("apelido")}
            {...obterConfiguracaoColuna("apelido")}
          />
          <Column
            key={nameOfGridHandler("nomeFantasia")}
            dataField={nameOfGridHandler("nomeFantasia")}
            {...obterConfiguracaoColuna("nomeFantasia")}
          />
          <Column
            key={nameOfGridHandler("razaoSocial")}
            dataField={nameOfGridHandler("razaoSocial")}
            {...obterConfiguracaoColuna("razaoSocial")}
          />
          <Column
            key={nameOfGridHandler("cnpjCpfCodigo")}
            dataField={nameOfGridHandler("cnpjCpfCodigo")}
            {...obterConfiguracaoColuna("cpfCnpj")}
          />
          <Column
            key={nameOfGridHandler("municipio")}
            dataField={nameOfGridHandler("municipio")}
            {...obterConfiguracaoColuna("stringM")}
            caption="Município"
          />
          <Column
            key={nameOfGridHandler("uf")}
            dataField={nameOfGridHandler("uf")}
            {...obterConfiguracaoColuna("stringM")}
            caption="UF"
          />
          <Column
            key={nameOfGridHandler("descricaoRegiao")}
            dataField={nameOfGridHandler("descricaoRegiao")}
            {...obterConfiguracaoColuna("stringM")}
            caption="Região"
          />
          <Column
            key={nameOfGridHandler("codigoEstado")}
            dataField={nameOfGridHandler("codigoEstado")}
            {...obterConfiguracaoColuna("stringM")}
            caption="Estado"
          />
          <Column
            key={nameOfGridHandler("fornecedor")}
            dataField={nameOfGridHandler("fornecedor")}
            {...obterConfiguracaoColuna("boolSimNao")}
            showInColumnChooser={false}
            visible={false}
          />
        </DataGrid>
      </ProvedorAjuda>

      <ModalMxp
        titulo={NormalizaTituloModal.Normalizar(
          idRegistroEdicao,
          NomesModais.empresa
        )}
        visivel={!isNaN(idRegistroEdicao)}
        handleFechar={handleFecharModal}
        largura={"max(50vw, 800px)"}
      >
        <div>Funcionalidade não implementada</div>
      </ModalMxp>
    </>
  );
}
