import { useMemo } from "react";
import { FieldValues } from "react-hook-form";
import { FormSelectBoxLazyMxp } from "../../../../../components/formularios";
import { ComboEntidadePropsBase } from "../../../../../components/formularios/selectbox-lazy-mxp";
import { DataSourceOpcoesBuilder } from "../../../../../utils/grid/data-source-factory";
import { ItemPedidoDeVendaGridModel } from "../../models/item-pedido-de-venda.api";
import { ItemPedidoDeVendaServico } from "../../servicos/item-pedido-de-venda.service";
import GridItemPedidoDeVenda from "../grid";

export interface ComboItemPedidoDeVendaProps<T extends FieldValues>
  extends ComboEntidadePropsBase<T, ItemPedidoDeVendaGridModel> {
  tituloSeletor?: string;
  filtrosNoCliente?: any[] | undefined;
  filtrosNoServidor?: any[] | undefined;
  dataSourceOpcoes?: DataSourceOpcoesBuilder<ItemPedidoDeVendaGridModel>;
}

export default function ComboItemPedidoDeVenda<T extends FieldValues>({
  tituloSeletor,
  filtrosNoCliente,
  filtrosNoServidor,
  dataSourceOpcoes,
  ...selectBoxProps
}: ComboItemPedidoDeVendaProps<T>) {
  const [
    dataSourceItemPedidoDeVenda,
    configuracoesExibicaoEBuscaItemPedidoDeVenda,
  ] = useMemo(
    () =>
      ItemPedidoDeVendaServico.GetDadosSelectBoxItemPedidoDeVenda(
        dataSourceOpcoes
      ),
    [dataSourceOpcoes]
  );

  return (
    <FormSelectBoxLazyMxp
      {...selectBoxProps}
      configuracoesExibicaoEBusca={configuracoesExibicaoEBuscaItemPedidoDeVenda}
      dataSource={dataSourceItemPedidoDeVenda}
      seletorConfig={{
        modo: "selecaoUnica",
        titulo: tituloSeletor ?? "Selecionar item",
        componenteGrid: (
          <GridItemPedidoDeVenda
            filtrosNoServidor={
              filtrosNoServidor ?? dataSourceOpcoes?.camposFiltro
            }
            filtrosNoCliente={filtrosNoCliente}
          />
        ),
      }}
      labelSemDados="Sem dados"
    />
  );
}
