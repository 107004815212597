import { format } from "date-fns";
import { createContext } from "react";
import DocumentoFiscalSelecaoMdfeGridModel from "../../../../models/api/nota-fiscal/nota-fiscal-selecao-mdfe";
import {
  GridCarregamentoViewModel,
  GridCondutorViewModel,
  GridPercursoViewModel,
  MDFeAbaDocumentosFiscaisViewModel,
  MDFeAbaGeralViewModel,
  MDFeAbaObservacoesViewModel,
  MDFeAbaPercursoViewModel,
  MDFeAbaSeguroViewModel,
  MDFeAbaVeiculoViewModel,
} from "../../../../models/viewmodels/vendas/mdfe/mdfe-edit-form-view-model";
import { VisibilidadeBotoesMDfe } from "../componentes/formulario/constantes";
import {
  MDFeAutorizacaoDTO,
  MdfeAverbacaoDTO,
  MDFeCarregamentoDTO,
  MDFeCompletoDTO,
  MDFeCondutorDTO,
  MDFeContratanteDTO,
  MDFeCPFCNPJAutorizadoDTO,
  MDFeDescarregamentoDTO,
  MDFeEmitenteDTO,
  MDFeModalRodoviarioDTO,
  MDFePercursoDTO,
  MDFeProdutoPredominanteDTO,
  MDFeVeiculoDTO,
} from "../models/mdfe";
import {
  AmbienteMDFe,
  ModalidadeTransporteMDFe,
  SituacaoMDFe,
  TipoCarregamentoMDFe,
  TipoEmissaoMDFe,
  TipoEmitenteMDFe,
  TipoTransportadorMDFe,
  UnidadeMedidaPesoBrutoMDFe,
} from "../models/mdfe-enums";

export interface UfsAtuais {
  inicio: number;
  fim: number;
}

export interface BaseMDFe {
  id: number;
  chaveAcesso: string | null;
  modelo: string;
  situacao: SituacaoMDFe;
  ambiente: AmbienteMDFe;
  tipoEmissao: TipoEmissaoMDFe;
  codigoAgendamentoPorto: string | null;
  motivoRejeicao: string | null;
  idArquivoDamdfeAutorizado: number | null;
  criacaoUsuarioApelido: string | null;
  criacaoData: string | null;
  alteracaoUsuarioApelido: string | null;
  ultimaAlteracaoData: string | null;
}

export interface DadosNotasMDFe {
  pesoBruto: number;
  valorTotalNfe: number;
  unidadePesoBruto: UnidadeMedidaPesoBrutoMDFe;
  tipoCarregamento: TipoCarregamentoMDFe;
}

interface MDFeEditFormContextProps {
  obterDocumentoAtual: () => MDFeCompletoDTO;
  baseMdfe: BaseMDFe;
  dadosPercurso: MDFeAbaPercursoViewModel;
  dadosNotas: DadosNotasMDFe;
  dadosObservacoes: MDFeAbaObservacoesViewModel;
  dadosGerais: MDFeAbaGeralViewModel;
  modalRodoviario: MDFeModalRodoviarioDTO;
  descarregamentos: MDFeDescarregamentoDTO[];
  carregamentos: MDFeCarregamentoDTO[];
  cpfCnpjAutorizados: MDFeCPFCNPJAutorizadoDTO[];
  percursos: MDFePercursoDTO[];
  produtoPredominante: MDFeProdutoPredominanteDTO | null;
  emitente: MDFeEmitenteDTO | null;
  autorizacao: MDFeAutorizacaoDTO | null;
  veiculos: MDFeVeiculoDTO[];
  condutores: MDFeCondutorDTO[];
  contratante: MDFeContratanteDTO;
  seguro: MDFeAbaSeguroViewModel;
  averbacoes: MdfeAverbacaoDTO[];
  visibilidadeBotoes?: VisibilidadeBotoesMDfe;
  telaSomenteLeitura: boolean;
  ufsAtuais: UfsAtuais;
  carregar: (mdfe: MDFeCompletoDTO) => void;
  novoDocumento: () => void;
  definirDadosGerais: (view: MDFeAbaGeralViewModel) => void;
  definirDadosObservacoes: (view: MDFeAbaObservacoesViewModel) => void;
  definirDadosVeiculos: (view: MDFeAbaVeiculoViewModel) => void;
  definirDadosNotas: (view: MDFeAbaDocumentosFiscaisViewModel) => void;
  definirDadosPercurso: (view: MDFeAbaPercursoViewModel) => void;
  adicionarCondutor: (view: GridCondutorViewModel[]) => void;
  removerCondutor: (view: GridCondutorViewModel) => void;
  adicionarDocumentoFiscal: (
    view: DocumentoFiscalSelecaoMdfeGridModel[]
  ) => void;
  removerDocumentoFiscal: (view: DocumentoFiscalSelecaoMdfeGridModel) => void;
  removerEAdicionarDocumentoFiscal: (
    viewRemover: DocumentoFiscalSelecaoMdfeGridModel,
    viewAdd: DocumentoFiscalSelecaoMdfeGridModel[]
  ) => void;
  editarDocumentoFiscal: (view: DocumentoFiscalSelecaoMdfeGridModel) => void;
  recalcularTotaisNotasMDFe: () => void;
  adicionarPercurso: (view: MDFePercursoDTO) => void;
  removerPercurso: (view: GridPercursoViewModel) => void;
  adicionarCarregamento: (view: MDFeCarregamentoDTO) => void;
  removerCarregamento: (view: GridCarregamentoViewModel) => void;
  definirDataEmissao: (data: string) => void;
  definirTipoTransportador: (data: TipoTransportadorMDFe) => void;
  definirTipoEmitente: (data: TipoEmitenteMDFe) => void;
  definirDadosUfs: (inicio: number, fim: number) => void;
  limparContexto: () => void;
  definirDadosSeguro: (view: MDFeAbaSeguroViewModel) => void;
  adicionarAverbacao: (view: MdfeAverbacaoDTO) => void;
  removerAverbacao: (view: MdfeAverbacaoDTO) => void;
}

const funcaoDefault = function <T>() {
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  return (view: T) => {};
};
// eslint-disable-next-line @typescript-eslint/no-empty-function
const funcaoDefaultSemParametro = () => {};

export const EstadoInicialMDFe: MDFeCompletoDTO = {
  id: 0,
  ambiente: AmbienteMDFe.Homologacao,
  carregamentos: [],
  descarregamentos: [],
  chaveAcesso: null,
  codigoAgendamentoPorto: null,
  cpfCnpjAutorizados: [],
  dataHoraEmissao: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
  dataHoraInicioViagem: null,
  emitente: null,
  autorizacao: null,
  idUfFim: 0,
  idUfInicio: 0,
  informacoesAdicionais: null,
  informacoesAdicionaisContibuinte: null,
  observacoesInternas: null,
  modalidadeTransporte: ModalidadeTransporteMDFe.Rodoviario,
  modelo: "",
  motivoRejeicao: null,
  numero: null,
  serie: "",
  percursos: [],
  pesoBruto: 0,
  unidadePesoBruto: UnidadeMedidaPesoBrutoMDFe.KG,
  produtoPredominante: {
    id: 0,
    descricao: "",
  },
  situacao: SituacaoMDFe.EmEdicao,
  tipoCarregamento: TipoCarregamentoMDFe.CargaGeral,
  tipoEmissao: TipoEmissaoMDFe.Normal,
  tipoEmitente: TipoEmitenteMDFe.TransportadorCargaPropria,
  tipoTransportador: null,
  valorTotalNfe: 0,
  seguro: null,
  modalRodoviario: {
    id: 0,
    condutores: [],
    contratante: {
      id: 0,
      idContratante: 0,
    },
    veiculos: [],
  },
  idArquivoDamdfeAutorizado: null,
  cepLocalCarregamento: null,
  cepLocalDescarregamento: null,
  criacaoUsuarioApelido: null,
  criacaoData: null,
  alteracaoUsuarioApelido: null,
  ultimaAlteracaoData: null,
};

export const MDFeEstadoInicialPropsDefault: MDFeEditFormContextProps = {
  obterDocumentoAtual: () => EstadoInicialMDFe,
  autorizacao: EstadoInicialMDFe.autorizacao,
  baseMdfe: {
    id: EstadoInicialMDFe.id,
    alteracaoUsuarioApelido: EstadoInicialMDFe.alteracaoUsuarioApelido,
    ambiente: EstadoInicialMDFe.ambiente,
    chaveAcesso: EstadoInicialMDFe.chaveAcesso,
    codigoAgendamentoPorto: EstadoInicialMDFe.codigoAgendamentoPorto,
    criacaoData: EstadoInicialMDFe.criacaoData,
    criacaoUsuarioApelido: EstadoInicialMDFe.criacaoUsuarioApelido,
    idArquivoDamdfeAutorizado: EstadoInicialMDFe.idArquivoDamdfeAutorizado,
    modelo: EstadoInicialMDFe.modelo,
    motivoRejeicao: EstadoInicialMDFe.motivoRejeicao,
    situacao: EstadoInicialMDFe.situacao,
    tipoEmissao: EstadoInicialMDFe.tipoEmissao,
    ultimaAlteracaoData: EstadoInicialMDFe.ultimaAlteracaoData,
  },
  dadosGerais: {
    id: EstadoInicialMDFe.id,
    dataHoraEmissao: EstadoInicialMDFe.dataHoraEmissao,
    dataHoraInicioViagem: EstadoInicialMDFe.dataHoraInicioViagem,
    idContratante: EstadoInicialMDFe.modalRodoviario.contratante.idContratante,
    modalidadeTransporte: EstadoInicialMDFe.modalidadeTransporte,
    numero: EstadoInicialMDFe.numero,
    serie: EstadoInicialMDFe.serie,
    tipoEmitente: EstadoInicialMDFe.tipoEmitente,
    tipoTransportador: EstadoInicialMDFe.tipoTransportador,
  },
  dadosNotas: {
    pesoBruto: EstadoInicialMDFe.pesoBruto,
    tipoCarregamento: EstadoInicialMDFe.tipoCarregamento,
    unidadePesoBruto: EstadoInicialMDFe.unidadePesoBruto,
    valorTotalNfe: EstadoInicialMDFe.valorTotalNfe,
  },
  dadosObservacoes: {
    id: EstadoInicialMDFe.id,
    internas: EstadoInicialMDFe.observacoesInternas,
    contribuinte: EstadoInicialMDFe.informacoesAdicionaisContibuinte,
    fisco: EstadoInicialMDFe.informacoesAdicionais,
  },
  dadosPercurso: {
    id: EstadoInicialMDFe.id,
    cepLocalCarregamento: EstadoInicialMDFe.cepLocalCarregamento,
    cepLocalDescarregamento: EstadoInicialMDFe.cepLocalDescarregamento,
    idUfFim: EstadoInicialMDFe.idUfFim,
    idUfInicio: EstadoInicialMDFe.idUfInicio,
  },
  carregamentos: EstadoInicialMDFe.carregamentos,
  descarregamentos: EstadoInicialMDFe.descarregamentos,
  condutores: EstadoInicialMDFe.modalRodoviario.condutores,
  contratante: EstadoInicialMDFe.modalRodoviario.contratante,
  veiculos: EstadoInicialMDFe.modalRodoviario.veiculos,
  cpfCnpjAutorizados: EstadoInicialMDFe.cpfCnpjAutorizados,
  emitente: EstadoInicialMDFe.emitente,
  modalRodoviario: EstadoInicialMDFe.modalRodoviario,
  percursos: EstadoInicialMDFe.percursos,
  produtoPredominante: EstadoInicialMDFe.produtoPredominante,
  seguro: {
    id: 0,
    idEmpresaSeguradora: null,
    numeroApolice: "",
    responsavelSeguro: null,
  },
  averbacoes: [],
  visibilidadeBotoes: undefined,
  telaSomenteLeitura: false,
  ufsAtuais: { inicio: 0, fim: 0 },
  adicionarCarregamento: funcaoDefault(),
  adicionarCondutor: funcaoDefault(),
  adicionarDocumentoFiscal: funcaoDefault(),
  adicionarPercurso: funcaoDefault(),
  carregar: funcaoDefault(),
  definirDadosGerais: funcaoDefault(),
  definirDadosNotas: funcaoDefault(),
  definirDadosObservacoes: funcaoDefault(),
  definirDadosPercurso: funcaoDefault(),
  definirDadosVeiculos: funcaoDefault(),
  editarDocumentoFiscal: funcaoDefault(),
  novoDocumento: funcaoDefaultSemParametro,
  recalcularTotaisNotasMDFe: funcaoDefaultSemParametro,
  removerCarregamento: funcaoDefault(),
  removerCondutor: funcaoDefault(),
  removerDocumentoFiscal: funcaoDefault(),
  removerEAdicionarDocumentoFiscal: funcaoDefault(),
  removerPercurso: funcaoDefault(),
  definirDataEmissao: funcaoDefault(),
  definirTipoTransportador: funcaoDefault(),
  definirDadosUfs: funcaoDefaultSemParametro,
  limparContexto: funcaoDefaultSemParametro,
  definirDadosSeguro: funcaoDefault(),
  adicionarAverbacao: funcaoDefault(),
  removerAverbacao: funcaoDefault(),
  definirTipoEmitente: funcaoDefault(),
};

const MDFeEditFormContext = createContext<MDFeEditFormContextProps>(
  MDFeEstadoInicialPropsDefault
);

export default MDFeEditFormContext;
