import { Button } from "devextreme-react";

interface BotaoCancelarMDFeMxpProps {
  handleClick: () => void;
}

export default function BotaoCancelarMDFeMxp(props: BotaoCancelarMDFeMxpProps) {
  return (
    <Button type="normal" text="Cancelar MDF-e" onClick={props.handleClick} />
  );
}
