import { useMemo } from "react";
import { FieldValues, UseControllerProps } from "react-hook-form";
import { FormSelectBoxLazyMxp } from "../../../../../components/formularios";
import { DataSourceOpcoesBuilder } from "../../../../../utils/grid/data-source-factory";
import GridContato from "../../componentes/grid-padrao";
import { ContatoGridModel } from "../../models/contato";
import { ContatoServico } from "../../servicos/contato.servico";

interface ComboContatoMxpProps<T extends FieldValues>
  extends UseControllerProps<T> {
  titulo: string;
  tituloSeletor?: string;
  requerido?: boolean;
  somenteLeitura?: boolean;
  onSelectionChanged?: ((e: any) => Promise<void>) | (() => void);
  filtrosNoCliente?: any[];
  dataSourceOpcoes?: DataSourceOpcoesBuilder<ContatoGridModel>;
}

interface ComboContatoPorEmpresaMxpProps<T extends FieldValues>
  extends ComboContatoMxpProps<T> {
  empresaId: number;
}

export function ComboContatoPorEmpresaMxp<T extends FieldValues>(
  props: ComboContatoPorEmpresaMxpProps<T>
) {
  const dataSourceOpcoes = useMemo(() => {
    return {
      ...props.dataSourceOpcoes,
      camposFiltro: [
        ...(props.dataSourceOpcoes?.camposFiltro ?? []),
        {
          campo: "empresaId",
          operador: "=",
          valor: props.empresaId,
        },
      ],
    } as DataSourceOpcoesBuilder<ContatoGridModel>;
  }, [props.dataSourceOpcoes, props.empresaId]);

  return <ComboContatoMxp {...props} dataSourceOpcoes={dataSourceOpcoes} />;
}

export function ComboContatoMxp<T extends FieldValues>(
  props: ComboContatoMxpProps<T>
) {
  const [dataSourceOperacao, configuracoesExibicaoEBusca] = useMemo(
    () => ContatoServico.GetDadosSelectBoxContato(props.dataSourceOpcoes),
    [props.dataSourceOpcoes]
  );

  return (
    <FormSelectBoxLazyMxp
      name={props.name}
      titulo={props.titulo}
      control={props.control}
      dataSource={dataSourceOperacao}
      configuracoesExibicaoEBusca={configuracoesExibicaoEBusca}
      seletorConfig={{
        modo: "selecaoUnica",
        titulo: props.tituloSeletor || "Selecionar contato",
        componenteGrid: (
          <GridContato
            filtrosNoServidor={props.dataSourceOpcoes?.camposFiltro}
            filtrosNoCliente={props.filtrosNoCliente}
          />
        ),
      }}
      labelSemDados="Sem dados"
      requerido={props.requerido}
      somenteLeitura={props.somenteLeitura}
    />
  );
}
