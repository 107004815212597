import { DataGrid } from "devextreme-react";
import { Column, DataGridRef } from "devextreme-react/data-grid";
import { useCallback, useMemo, useRef, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import ProvedorAjuda from "../../../../../../../components/ajuda/provedor-ajuda";
import GridColunaAcoes from "../../../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import { ModalMxp } from "../../../../../../../components/layout/modal-mxp";
import { useRegistrarAtalhosGrid } from "../../../../../../../hooks/atalhos.hooks";
import { PermissoesVinculoEnderecoEstoqueCentroDeTrabalho } from "../../../../../../../models/permissoes/producao/vinculo-endereco-estoque-centro-de-trabalho/permissoes-vinculo-endereco-estoque-centro-de-trabalho";
import { ResultadoAcaoFormulario } from "../../../../../../../models/shared/ui/formularios";
import {
  checarResponseExibeMensagemExclusaoDeSucesso,
  tratarErroApi,
} from "../../../../../../../utils/api/api-utils";
import criarNameof from "../../../../../../../utils/common/cria-name-of";
import NomesModais from "../../../../../../../utils/common/nomes-modais";
import NormalizaTituloModal from "../../../../../../../utils/common/normaliza-titulo";
import { GeradorMensagensNotificacao } from "../../../../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../../../utils/common/permissoes-utils";
import { exibirConfirmacao } from "../../../../../../../utils/dialogos";
import GridBuilder from "../../../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../../../utils/grid/padroes-colunas";
import { VinculoEnderecoEstoqueCentroDeTrabalhoGridModel } from "../../../../../vinculo-endereco-estoque-centro-de-trabalho/models/vinculo-endereco-estoque-centro-de-trabalho.api";
import { VinculoEnderecoEstoqueCentroDeTrabalhoServico } from "../../../../../vinculo-endereco-estoque-centro-de-trabalho/servicos/vinculo-endereco-estoque-centro-de-trabalho.service";
import { CentroDeTrabalhoRequestDTO } from "../../../../models/centro-de-trabalho.api";
import FormAdicionarVinculoEnderecoDeEstoqueCentroDeTrabalho from "../form-adicionar-vinculo-centro-de-trabalho";

const nameOfGridHandler =
  criarNameof<VinculoEnderecoEstoqueCentroDeTrabalhoGridModel>();

interface GridInternalVinculoEnderecoEstoqueProps {
  idCentroDeTrabalho: number;
  hookForm: UseFormReturn<CentroDeTrabalhoRequestDTO, any, undefined>;
}

let codigoDoCentroDeTrabalhoEmEdicao: string = "";

export default function GridInternalVinculoEnderecoEstoque(
  props: GridInternalVinculoEnderecoEstoqueProps
) {
  const gridRef = useRef<DataGridRef>(null);
  const [idRegistroEdicao, setIdRegistroEdicao] = useState(NaN);

  const { getValues } = props.hookForm;

  const dataSource =
    VinculoEnderecoEstoqueCentroDeTrabalhoServico.ObterDataSourceParaGrid<VinculoEnderecoEstoqueCentroDeTrabalhoGridModel>(
      [
        {
          campo: "centroDeTrabalhoId",
          operador: "=",
          valor: props.idCentroDeTrabalho,
        },
      ]
    );

  const gridController = useMemo(
    () =>
      new GridController<VinculoEnderecoEstoqueCentroDeTrabalhoGridModel>(() =>
        gridRef.current?.instance()
      ),
    []
  );

  const handleNovoRegistro = useCallback(() => {
    if (
      !verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesVinculoEnderecoEstoqueCentroDeTrabalho.InserirEditar,
      ])
    ) {
      return;
    }

    codigoDoCentroDeTrabalhoEmEdicao = getValues().codigo;
    setIdRegistroEdicao(0);
  }, [getValues]);

  const handleEditarRegistro = useCallback(
    (registro: VinculoEnderecoEstoqueCentroDeTrabalhoGridModel) => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesVinculoEnderecoEstoqueCentroDeTrabalho.InserirEditar,
        ])
      ) {
        return;
      }
      codigoDoCentroDeTrabalhoEmEdicao = registro.centroDeTrabalhoCodigo;
      setIdRegistroEdicao(registro.id);
    },
    []
  );

  const handleAtualizarGrid = useCallback(() => {
    gridController.atualizar();
  }, [gridController]);

  const handleExcluirRegistro = useCallback(
    async (registro: VinculoEnderecoEstoqueCentroDeTrabalhoGridModel) => {
      const possuiPermissao = verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesVinculoEnderecoEstoqueCentroDeTrabalho.Excluir,
      ]);

      if (!possuiPermissao) {
        return;
      }

      const mensagem =
        "Tem certeza de que deseja excluir o vínculo do endereco de estoque?";

      const excluir = await exibirConfirmacao("Confirmar exclusão", mensagem);

      if (excluir) {
        try {
          const resposta =
            await VinculoEnderecoEstoqueCentroDeTrabalhoServico.Excluir(
              registro.id
            );
          checarResponseExibeMensagemExclusaoDeSucesso(
            resposta,
            GeradorMensagensNotificacao.ExcluidoComSucessoMasculino(
              "Vínculo com endereço de estoque"
            )
          );

          handleAtualizarGrid();
        } catch (erro) {
          tratarErroApi(erro);
        }
      }
    },
    [handleAtualizarGrid]
  );

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar(
      "grid-editform-vinculo-endereco-de-estoque-centro-de-trabalho",
      () => gridRef.current?.instance()
    )
      .definirStyles({ height: "100%" })
      .definirDataSource(dataSource)
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirBotaoNovo(handleNovoRegistro)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirOrdenacao()
      .definirDuploCliqueLinha(handleEditarRegistro)
      .build();
  }, [
    dataSource,
    handleAtualizarGrid,
    handleEditarRegistro,
    handleNovoRegistro,
  ]);

  useRegistrarAtalhosGrid<VinculoEnderecoEstoqueCentroDeTrabalhoGridModel>({
    controller: gridController,
    handleNovo: handleNovoRegistro,
    handleEditar: handleEditarRegistro,
    handleExcluir: handleExcluirRegistro,
  });

  const handleFecharModal = useCallback(() => {
    setIdRegistroEdicao(NaN);
  }, []);

  const handleCallbackFormulario = useCallback(
    (resultado: ResultadoAcaoFormulario) => {
      setIdRegistroEdicao(NaN);

      if (resultado == ResultadoAcaoFormulario.AcaoConcluida) {
        handleAtualizarGrid();
      }
    },
    [handleAtualizarGrid]
  );

  return (
    <>
      <ProvedorAjuda
        id={"tooltips-grid-interna-endereco-estoque-centro-trabalho"}
      >
        <DataGrid ref={gridRef} {...configuracoesGrid}>
          <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
          {GridColunaAcoes<VinculoEnderecoEstoqueCentroDeTrabalhoGridModel>({
            handleEditar: handleEditarRegistro,
            handleExcluir: handleExcluirRegistro,
          })}
          <Column
            dataField={nameOfGridHandler("centroDeTrabalhoId")}
            {...obterConfiguracaoColuna("idPropridadeDeNavegacaoNaoVisivel")}
          />
          <Column
            dataField={nameOfGridHandler("enderecoEstoque")}
            {...obterConfiguracaoColuna("stringGG")}
            caption="Endereço de estoque"
          />
          <Column
            dataField={nameOfGridHandler("minhaEmpresa")}
            {...obterConfiguracaoColuna("stringGG")}
            caption="Minha empresa"
          />
        </DataGrid>
      </ProvedorAjuda>
      <ModalMxp
        titulo={NormalizaTituloModal.Normalizar(
          idRegistroEdicao,
          NomesModais.vinculoDoCentroDeTrabalhoComEnderecoDeEstoque
        )}
        visivel={!Number.isNaN(idRegistroEdicao)}
        handleFechar={handleFecharModal}
        largura={"max(50vw, 800px)"}
      >
        <FormAdicionarVinculoEnderecoDeEstoqueCentroDeTrabalho
          idCentroDeTrabalho={props.idCentroDeTrabalho}
          codigoCentroDeTrabalhoVinculado={codigoDoCentroDeTrabalhoEmEdicao}
          idRegistroEmEdicao={idRegistroEdicao}
          handleCallback={handleCallbackFormulario}
        />
      </ModalMxp>
    </>
  );
}
