import { Column, DataGridTypes } from "devextreme-react/data-grid";
import { ColumnCellTemplateData } from "devextreme/ui/data_grid";
import { MxpChip } from "../../../../../components/mxp/chip";
import { decodificaBooleanoEmSimNao } from "../../../../../models/api/comum/decodificadores";
import criarNameof from "../../../../../utils/common/cria-name-of";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import { InsumoDaOrdemDeProducaoGridModel } from "../../models/insumo-da-ordem-de-producao";
import {
  InsumoEstado,
  InsumoEstadoHelper,
} from "../../utils/enum/insumo-da-ordem-de-producao-enums";
import { CelulaMultiplasOrigensInsumo } from "../celula-multiplas-origens";

const nameOfGridHandler = criarNameof<InsumoDaOrdemDeProducaoGridModel>();

const formatarColunaFornecidoPeloClienteEmProducaoParaTerceiro = (
  data: DataGridTypes.ColumnCellTemplateData
) => {
  const dadosLinha: InsumoDaOrdemDeProducaoGridModel = data.row.data;

  if (dadosLinha.fornecidoPeloClienteEmProducaoParaTerceiro) {
    return (
      <>
        <div className={`ic-text-cell-with-icon`}>
          <span className={`ic-text-cell`}>
            {decodificaBooleanoEmSimNao(
              dadosLinha.fornecidoPeloClienteEmProducaoParaTerceiro
            )}
          </span>
          <span
            className={`ic-column-icon ic-2x ic-material-symbols-outlined ic-turn-down ic-error`}
            title="Item fornecido pelo cliente em produção para terceiro"
          ></span>
        </div>
      </>
    );
  }

  return (
    <>
      {decodificaBooleanoEmSimNao(
        dadosLinha.fornecidoPeloClienteEmProducaoParaTerceiro
      )}
    </>
  );
};

const formatarColunaLoteDoFabricante = (
  data: DataGridTypes.ColumnCellTemplateData
) => {
  const dados: InsumoDaOrdemDeProducaoGridModel = data.row.data;

  if (dados.estoqueDisponivel == 0) {
    return <></>;
  }

  if (dados.possuiMaisDeUmEstoqueComLoteDofabricante) {
    return <CelulaMultiplasOrigensInsumo insumoId={dados.id} />;
  }

  return <>{dados.loteDoFabricante}</>;
};

const formatarColunaLoteInterno = (
  data: DataGridTypes.ColumnCellTemplateData
) => {
  const dados: InsumoDaOrdemDeProducaoGridModel = data.row.data;

  if (dados.estoqueDisponivel == 0) {
    return <></>;
  }

  if (dados.possuiMaisDeUmEstoqueComLoteInterno) {
    return <CelulaMultiplasOrigensInsumo insumoId={dados.id} />;
  }

  return <>{dados.loteInterno}</>;
};

const formatarColunaEnderecoDeEstoque = (
  data: DataGridTypes.ColumnCellTemplateData
) => {
  const dados: InsumoDaOrdemDeProducaoGridModel = data.row.data;

  if (dados.possuiMaisDeUmEstoqueComEnderecoDeEstoque) {
    return <CelulaMultiplasOrigensInsumo insumoId={dados.id} />;
  }

  return <>{dados.enderecoDeEstoque}</>;
};

export function InsumoEstadoAsCellRender(
  params: ColumnCellTemplateData
): React.ReactNode {
  const estado = params.data.estado as InsumoEstado;

  return (
    <MxpChip color={InsumoEstadoHelper.coresPorEstado[estado]}>
      {InsumoEstadoHelper.getDescricao(estado)}
    </MxpChip>
  );
}

export const colunasQuantidadeInsumo = [
  <Column
    key={nameOfGridHandler("quantidadeSemPercentualDePerda")}
    dataField={nameOfGridHandler("quantidadeSemPercentualDePerda")}
    {...obterConfiguracaoColuna("quantidadeComNoMaximoCincoCasasDecimais")}
    caption="Qt prevista sem % de perda"
    width={230}
    allowEditing={false}
  />,
  <Column
    key={nameOfGridHandler("perdaEmPercentual")}
    dataField={nameOfGridHandler("perdaEmPercentual")}
    {...obterConfiguracaoColuna("percentualComMinimoDuasCasasDecimais")}
    width={130}
    allowEditing={false}
    alignment="right"
    caption="Perda (%)"
  />,
  <Column
    key={nameOfGridHandler("quantidadeTotal")}
    dataField={nameOfGridHandler("quantidadeTotal")}
    {...obterConfiguracaoColuna("quantidadeComNoMaximoCincoCasasDecimais")}
    caption="Qt total prevista"
    allowEditing={false}
  />,
];

export const colunasInsumosBase = [
  <Column
    key={nameOfGridHandler("estado")}
    dataField={nameOfGridHandler("estado")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Estado"
    lookup={InsumoEstadoHelper.AsLookup()}
    allowEditing={false}
    cellRender={InsumoEstadoAsCellRender}
  />,
  <Column
    key={nameOfGridHandler("estoqueDisponivel")}
    dataField={nameOfGridHandler("estoqueDisponivel")}
    {...obterConfiguracaoColuna("quantidadeComNoMaximoCincoCasasDecimais")}
    caption="Estoque disponível"
    allowEditing={false}
  />,
  <Column
    key={nameOfGridHandler("estoqueDisponivelExplosao")}
    dataField={nameOfGridHandler("estoqueDisponivelExplosao")}
    {...obterConfiguracaoColuna("quantidadeComNoMaximoCincoCasasDecimais")}
    caption="Estoque disponível para explosão"
    visible={false}
    allowEditing={false}
  />,
  <Column
    key={nameOfGridHandler("loteInterno")}
    dataField={nameOfGridHandler("loteInterno")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Lote interno"
    allowEditing={false}
    cellRender={formatarColunaLoteInterno}
  />,
  <Column
    key={nameOfGridHandler("loteDoFabricante")}
    dataField={nameOfGridHandler("loteDoFabricante")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Lote do fabricante"
    allowEditing={false}
    cellRender={formatarColunaLoteDoFabricante}
  />,
  <Column
    key={nameOfGridHandler("enderecoDeEstoque")}
    dataField={nameOfGridHandler("enderecoDeEstoque")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Endereço de estoque"
    allowEditing={false}
    cellRender={formatarColunaEnderecoDeEstoque}
  />,
];

export const colunasInsumos = [
  <Column
    key={nameOfGridHandler("codigo")}
    dataField={nameOfGridHandler("codigo")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Item"
    allowEditing={false}
    width={160}
  />,
  <Column
    key={nameOfGridHandler("descricaoComplementar")}
    dataField={nameOfGridHandler("descricaoComplementar")}
    {...obterConfiguracaoColuna("stringG")}
    caption="Descrição complementar"
    allowEditing={false}
    width={400}
    visible={false}
  />,
  ...colunasQuantidadeInsumo,
  <Column
    key={nameOfGridHandler("unidade")}
    dataField={nameOfGridHandler("unidade")}
    {...obterConfiguracaoColuna("stringP")}
    caption="Unid"
    width={85}
    visible={false}
    allowEditing={false}
  />,
  <Column
    key={nameOfGridHandler("quantidadeParaUmaUnidade")}
    dataField={nameOfGridHandler("quantidadeParaUmaUnidade")}
    {...obterConfiguracaoColuna("quantidadeComNoMaximoCincoCasasDecimais")}
    caption="Qt para uma unidade"
    visible={false}
    allowEditing={false}
  />,
  ...colunasInsumosBase,
  <Column
    key={nameOfGridHandler("quantidadeReservada")}
    dataField={nameOfGridHandler("quantidadeReservada")}
    {...obterConfiguracaoColuna("decimalComMaximoQuatroCasasDecimais")}
    caption="Reservado"
    allowEditing={false}
  />,
  <Column
    key={nameOfGridHandler("fornecidoPeloClienteEmProducaoParaTerceiro")}
    dataField={nameOfGridHandler("fornecidoPeloClienteEmProducaoParaTerceiro")}
    {...obterConfiguracaoColuna("boolSimNao")}
    caption="Fornecido pelo cliente"
    width={200}
    cellRender={formatarColunaFornecidoPeloClienteEmProducaoParaTerceiro}
    allowEditing={false}
  />,
  <Column
    key={nameOfGridHandler("tipoDoEstoque")}
    dataField={nameOfGridHandler("tipoDoEstoque")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Tipo do estoque"
    visible={false}
    allowEditing={false}
  />,
];
