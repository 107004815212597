import DataGrid, { Column, DataGridRef } from "devextreme-react/data-grid";
import ArrayStore from "devextreme/data/array_store";
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import ProvedorAjuda from "../../../../../../components/ajuda/provedor-ajuda";
import GridColunaAcoes from "../../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import TituloGrid from "../../../../../../components/texto/titulo-grid";
import { GridMxpProps } from "../../../../../../models/shared/ui/grid";
import { GridPercursoViewModel } from "../../../../../../models/viewmodels/vendas/mdfe/mdfe-edit-form-view-model";
import exibirNotificacaoToast, {
  MensagensParaNotificacao,
  TipoNotificacao,
} from "../../../../../../utils/common/notificacoes-utils";
import GridBuilder from "../../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../../utils/grid/grid-controller";
import MDFeEditFormContext from "../../../contexts/mdfe-editform.context";
import ModalAdicionarPercurso from "./modal-adicionar-percurso";

interface GridEmbutidaPercursoProps
  extends GridMxpProps<GridPercursoViewModel> {
  somenteLeitura?: boolean;
}

export default function GridPercursos(props: GridEmbutidaPercursoProps) {
  const gridRef = useRef<DataGridRef>(null);

  const { baseMdfe, removerPercurso, percursos } =
    useContext(MDFeEditFormContext);
  const [modalAddPercursoVisivel, setModalAddPercursoVisivel] = useState(false);

  const modelPercurso = useMemo(() => {
    return percursos.map((p) => {
      return {
        id: p.id,
        nome: p.estado.nome,
        ordem: p.ordem,
        uf: p.estado.nome,
        idUf: p.idUf,
      } as GridPercursoViewModel;
    });
  }, [percursos]);

  const dataSourcePercurso = useMemo(
    () => new ArrayStore({ data: modelPercurso, key: "ufId" }),
    [modelPercurso]
  );

  const novoRegistroPercurso = useCallback(() => {
    setModalAddPercursoVisivel(true);
  }, [setModalAddPercursoVisivel]);

  const excluirRegistro = useCallback(
    (percurso: GridPercursoViewModel | undefined) => {
      if (percurso) {
        removerPercurso(percurso);

        exibirNotificacaoToast({
          mensagem: MensagensParaNotificacao.ExcluidoComSucesso,
          tipo: TipoNotificacao.Sucesso,
        });
      }
    },
    [removerPercurso]
  );

  const gridController = new GridController<GridPercursoViewModel>(() =>
    gridRef.current?.instance()
  );

  const configuracoesGrid = useMemo(() => {
    let builder = GridBuilder.criar(
      "mdfe-lista-percurso",
      () => gridRef.current?.instance(),
      true
    )
      .definirStyles({ height: "100%" })
      .definirDataSource(dataSourcePercurso)
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .configurarExportacao("Percursos")
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirOrdenacao();

    if (!props.somenteLeitura) {
      builder = builder.definirBotaoNovo(novoRegistroPercurso);
    }

    return builder.build();
  }, [dataSourcePercurso, props.somenteLeitura]);

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  return (
    <>
      <TituloGrid texto="Percurso" />

      <ProvedorAjuda id={"ajuda-mdfe-lista-percurso"}>
        <DataGrid ref={gridRef} {...configuracoesGrid}>
          {GridColunaAcoes<GridPercursoViewModel>({
            handleExcluir: props.somenteLeitura ? undefined : excluirRegistro,
          })}
          <Column
            key="ordem"
            dataField="ordem"
            caption="Ordem"
            dataType="number"
            allowResizing
            width={70}
          />
          ,
          <Column
            key="uf"
            dataField="uf"
            caption="Descrição da UF"
            dataType="string"
            allowResizing
            width={150}
          />
          ,
        </DataGrid>
      </ProvedorAjuda>

      <div>
        <ModalAdicionarPercurso
          idRegistroEmEdicao={baseMdfe.id}
          visivel={modalAddPercursoVisivel}
          handleCallback={() => {
            setModalAddPercursoVisivel(false);
          }}
        />
      </div>
    </>
  );
}
