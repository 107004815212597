import { yupResolver } from "@hookform/resolvers/yup";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import BotaoCancelarMxp from "../../../../../../components/botoes/botao-cancelar-mxp";
import BotaoSalvarMxp from "../../../../../../components/botoes/botao-salvar-mxp";
import {
  FormGrupo,
  FormTextBox,
} from "../../../../../../components/formularios";
import { Coluna, Linha } from "../../../../../../components/layout/grid-system";
import { ModalMxp } from "../../../../../../components/layout/modal-mxp";
import { PainelCarregando } from "../../../../../../components/layout/painel-carregamento";
import ToolbarMxp from "../../../../../../components/layout/toolbar-mxp";
import {
  IFormularioEditavelBase,
  ResultadoAcaoFormulario,
} from "../../../../../../models/shared/ui/formularios";
import { AdicionarAverbacaoViewModel } from "../../../../../../models/viewmodels/vendas/mdfe/mdfe-edit-form-view-model";
import { previneDigitacaoDeCaracteres } from "../../../../../../utils/common/common-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../../utils/common/notificacoes-utils";
import { digitosNumericosSemEspaco } from "../../../../../../utils/common/regex-padrao";
import { exibirAlerta } from "../../../../../../utils/dialogos";
import yup from "../../../../../../utils/validacao/validacao";
import MDFeEditFormContext from "../../../contexts/mdfe-editform.context";
import { MdfeAverbacaoDTO } from "../../../models/mdfe";
import MDFeConstantes from "../../../models/mdfe.constantes";

interface ModalAdicionarAverbacaoProps extends IFormularioEditavelBase {
  visivel: boolean;
}

export default function ModalAdicionarAverbacao({
  visivel,
  handleCallback,
}: ModalAdicionarAverbacaoProps) {
  const form = useRef<HTMLFormElement | null>(null);
  const [carregando, setCarregando] = useState(false);
  const { averbacoes, adicionarAverbacao } = useContext(MDFeEditFormContext);

  const schema = useMemo(
    () =>
      yup.object().shape({
        numero: yup
          .string()
          .required()
          .max(MDFeConstantes.AverbacaoTamanhoMaximo)
          .test(
            "averbacao_duplicidade",
            "Esse número já foi informado.",
            function (numero) {
              if (numero) {
                return !averbacoes.some((x) => x.numero === numero);
              }
              return true;
            }
          ),
      }),
    [averbacoes]
  );

  const { control, handleSubmit, reset, getValues } =
    useForm<AdicionarAverbacaoViewModel>({
      mode: "all",
      reValidateMode: "onChange",
      resolver: yupResolver(schema),
    });

  useEffect(() => {
    reset();
  }, [visivel]);

  const handleCancelar = useCallback(() => {
    handleCallback(ResultadoAcaoFormulario.AcaoCancelada);
  }, [handleCallback]);

  const handleSalvar = useCallback(() => {
    if (averbacoes.length >= MDFeConstantes.QuantidadeMaximaAverbacoes) {
      exibirAlerta(
        "Atenção",
        `Não é possível adicionar mais averbações, pois o limite é de ${MDFeConstantes.QuantidadeMaximaAverbacoes} registros.`
      );
      return;
    }

    try {
      setCarregando(true);
      const model = getValues();

      const addmodel: MdfeAverbacaoDTO = {
        id: 0,
        numero: model.numero,
      };

      adicionarAverbacao(addmodel);

      exibirNotificacaoToast({
        mensagem: `Averbação número ${addmodel.numero} adicionado com sucesso. Clique em "Salvar" para confirmar a operação.`,
        tipo: TipoNotificacao.Advertencia,
      });

      if (handleCallback) {
        handleCallback(ResultadoAcaoFormulario.AcaoConcluida);
      }
    } finally {
      setCarregando(false);
    }
  }, [handleCallback, adicionarAverbacao, getValues, averbacoes]);

  const efetuarSubmit = useCallback(() => {
    form.current?.requestSubmit();
  }, [form]);

  const previneDigitacaoDeCaracteresHandler = (e: any) =>
    previneDigitacaoDeCaracteres(e, digitosNumericosSemEspaco);

  return (
    <>
      <ModalMxp
        visivel={visivel}
        handleFechar={handleCancelar}
        titulo="Adicionar averbação"
        largura={"70%"}
        altura={"auto"}
      >
        <form
          style={{
            overflow: "auto",
            padding: "5px 15px",
            margin: "-15px -15px 0px -15px",
            flexGrow: 2,
            flexShrink: 2,
          }}
          ref={form}
          onSubmit={handleSubmit(handleSalvar)}
        >
          <PainelCarregando visivel={carregando} />
          <FormGrupo>
            <Linha>
              <Coluna md={12}>
                <FormTextBox
                  name="numero"
                  titulo="Número da averbação"
                  toolTip="Número da averbação"
                  control={control}
                  requerido
                  tamanhoMaximo={MDFeConstantes.AverbacaoTamanhoMaximo}
                  onKeyDown={previneDigitacaoDeCaracteresHandler}
                />
              </Coluna>
            </Linha>
          </FormGrupo>
        </form>
        <ToolbarMxp>
          <BotaoSalvarMxp handleClick={efetuarSubmit} />
          <BotaoCancelarMxp handleClick={handleCancelar} />
        </ToolbarMxp>
      </ModalMxp>
    </>
  );
}
