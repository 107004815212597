import { HttpStatusCode } from "axios";
import Decimal from "decimal.js";
import { MDFeDescarregamentoDTO } from "../../../features/fiscal/mdfe/models/mdfe";
import {
  SituacaoMDFe,
  TipoDocumentoFiscal,
  UnidadeMedidaPesoBrutoMDFe,
  situacaoMDFeDecodificada,
  tiposCTe,
  tiposNFe,
} from "../../../features/fiscal/mdfe/models/mdfe-enums";
import { MDFeServico } from "../../../features/fiscal/mdfe/servicos/mdfe.service";
import { checarResponse, tratarErroApi } from "../../api/api-utils";
import exibirNotificaoToast, {
  TipoNotificacao,
} from "../../common/notificacoes-utils";
import {
  createUrlFromBlobAndDownload,
  extractFileNameFromContentDisposition,
} from "../../file/file-utils";

export function calcularQuantidadeTotalNFeMDFe(
  descarregamentos: MDFeDescarregamentoDTO[]
) {
  return calcularQuantidadeTotalPorTipoDocumento(descarregamentos, tiposNFe);
}

export function calcularQuantidadeTotalCTeMDFe(
  descarregamentos: MDFeDescarregamentoDTO[]
) {
  return calcularQuantidadeTotalPorTipoDocumento(descarregamentos, tiposCTe);
}

function calcularQuantidadeTotalPorTipoDocumento(
  descarregamentos: MDFeDescarregamentoDTO[],
  tipoDocumentoFiscal: TipoDocumentoFiscal[]
) {
  return descarregamentos
    .map((x) =>
      x.documentosVinculados
        ? x.documentosVinculados.filter((documento) =>
            tipoDocumentoFiscal.includes(
              extraiTipoDocumentoChaveAcesso(
                documento.documentoFiscal?.chaveAcesso
              )
            )
          ).length
        : 0
    )
    .reduce((x, y) => x + y, 0);
}

export function calcularValorTotalMDFe(
  descarregamentos: MDFeDescarregamentoDTO[]
) {
  return (
    descarregamentos
      .map((d) =>
        d.documentosVinculados
          ?.map((n) => new Decimal(n.documentoFiscal?.valor ?? 0))
          ?.reduce((x, y) => x.add(y), new Decimal(0))
      )
      .reduce((x, y) => x?.add(y ?? 0), new Decimal(0)) ?? new Decimal(0)
  ).toNumber();
}

export function calcularPesoBrutoTotalMDFe(
  descarregamentos: MDFeDescarregamentoDTO[],
  unidadeMedida: UnidadeMedidaPesoBrutoMDFe
) {
  const peso =
    descarregamentos
      .map((d) =>
        d.documentosVinculados
          ?.map((n) => new Decimal(n.documentoFiscal?.pesoBruto ?? 0))
          ?.reduce((x, y) => x.add(y), new Decimal(0))
      )
      .reduce((x, y) => x?.add(y ?? 0), new Decimal(0)) ?? new Decimal(0);

  return (
    unidadeMedida == UnidadeMedidaPesoBrutoMDFe.TON
      ? peso.dividedBy(1000)
      : peso
  ).toNumber();
}

export function verificaSituacaoMDFeParaImpressaoDamdfeBaixarXml(
  situacao?: SituacaoMDFe
) {
  return (
    situacao &&
    (situacao == SituacaoMDFe.Autorizado ||
      situacao == SituacaoMDFe.Encerrado ||
      situacao == SituacaoMDFe.Cancelado)
  );
}

export function toastSituacaoInvalida() {
  exibirNotificaoToast({
    mensagem: `Ação indisponível para MDFe nas situações "${
      situacaoMDFeDecodificada[SituacaoMDFe.EmEdicao]
    }" , "${situacaoMDFeDecodificada[SituacaoMDFe.Validado]}" ou "${
      situacaoMDFeDecodificada[SituacaoMDFe.Rejeitado]
    }".`,
    tipo: TipoNotificacao.Erro,
  });
}

export async function visualizarImpressaoPdfDamdfe(
  id: number,
  situacao: SituacaoMDFe,
  setModalPdfViewerVisivel: (value: React.SetStateAction<boolean>) => void,
  setDocumentoPdfDamdfe: (value: React.SetStateAction<string>) => void
) {
  if (situacao == SituacaoMDFe.EmEdicao || situacao == SituacaoMDFe.Validado) {
    exibirNotificaoToast({
      mensagem: `Não é possível visualizar o DAMDFE nas seguintes situações: "${
        situacaoMDFeDecodificada[SituacaoMDFe.EmEdicao]
      }" e "${situacaoMDFeDecodificada[SituacaoMDFe.Validado]}".`,
      tipo: TipoNotificacao.Erro,
    });
    return;
  }

  try {
    const resposta = await MDFeServico.consultarDamdfeMdfe(id);

    if (resposta.status != HttpStatusCode.Ok) {
      checarResponse(resposta.data);
    }
    const url = URL.createObjectURL(resposta.data);

    setModalPdfViewerVisivel(true);
    setDocumentoPdfDamdfe(url);
  } catch (erro) {
    tratarErroApi(erro);
  }
}

export async function baixarXmlMdfe(id: number, situacao: SituacaoMDFe) {
  if (situacao == SituacaoMDFe.EmEdicao || situacao == SituacaoMDFe.Validado) {
    exibirNotificaoToast({
      mensagem: `Não é possível gerar XML do MDF-e nas seguintes situações: "${
        situacaoMDFeDecodificada[SituacaoMDFe.EmEdicao]
      }" e "${situacaoMDFeDecodificada[SituacaoMDFe.Validado]}".`,
      tipo: TipoNotificacao.Erro,
    });
    return;
  }

  try {
    const resposta = await MDFeServico.downloadXmlMdfe(id, situacao);
    if (resposta.status != HttpStatusCode.Ok) {
      checarResponse(resposta.data);
    }
    const filename = extractFileNameFromContentDisposition(resposta.headers);

    createUrlFromBlobAndDownload(resposta, filename);
  } catch (erro) {
    tratarErroApi(erro);
  }
}

export function extraiTipoDocumentoChaveAcesso(
  chaveAcesso: string | undefined
) {
  return (chaveAcesso?.substring(20, 22) as TipoDocumentoFiscal) || "";
}
