import { ContextMenu } from "devextreme-react";
import { memo, ReactNode, useState } from "react";
import { v4 } from "uuid";
import {
  eventoTooltipContextMenu,
  ItemContextMenuMxp,
  ItemContextMenuNativo,
} from "../../../utils/context-menu/context-menu-utils";
import BotaoEditarColunaAcaoGridMxp from "../../grid-mxp/grid-mxp-botao-editar";
import BotaoExcluirColunaAcaoGridMxp from "../../grid-mxp/grid-mxp-botao-excluir";
import { Container, LinkButton } from "./styles";

interface CelulaControleEdicaoProps<T> {
  editar?: (dados: any) => void;
  excluir?: (dados: any) => void;
  gerarBotoesAdicionais?: (dados: any) => ReactNode[];
  itens?: ItemContextMenuMxp[];
  dados: T;
}

function computarPropriedades(itens: ItemContextMenuMxp[], dados: any) {
  for (const item of itens) {
    if (item.propriedadesComputadas) {
      for (const [key, value] of item.propriedadesComputadas) {
        (item as any)[key] = value(dados);
      }
    }
    if (item.items) {
      item.items = computarPropriedades(item.items, dados);
    }
  }

  return itens;
}

function aplicarCssCorIcones(itens: ItemContextMenuMxp[]) {
  for (const item of itens) {
    if (!item.icon?.includes(" icone-linha-grid")) {
      item.icon += " icone-linha-grid";
    }

    item.name = item.text;

    if (item.disabledIcon && !item.icon?.includes(" ic-material-disabled")) {
      item.icon += " ic-material-disabled";
    } else if (
      !item.disabledIcon &&
      item.icon?.includes(" ic-material-disabled")
    ) {
      item.icon = item.icon?.replace(" ic-material-disabled", "");
    }

    if (item.items) {
      item.items = aplicarCssCorIcones(item.items);
    }
  }

  return itens;
}

const CelulaControleEdicaoMxp = memo(CelulaControleEdicaoMxpRender);

export default CelulaControleEdicaoMxp;

function CelulaControleEdicaoMxpRender<T>({
  editar,
  excluir,
  gerarBotoesAdicionais,
  itens,
  dados,
}: CelulaControleEdicaoProps<T>) {
  const [contextMenuVisivel, setContextMenuVisivel] = useState(false);
  const linkId = v4();

  let itensTratados = computarPropriedades(itens ?? [], dados);
  itensTratados = aplicarCssCorIcones(itensTratados);

  const itensColunaAcoes = itensTratados
    ?.filter((x) => x.exibirNaLinhaDaGrid == "sempre")
    .map(convertItemContextMenu);

  const itensMenuDeContexto = itensTratados
    ?.filter((x) => x.exibirNaLinhaDaGrid == "menuDeContexto")
    .map(convertItemContextMenu);

  function convertItemContextMenu(x: ItemContextMenuMxp) {
    const item: ItemContextMenuNativo = {
      ...x,
      onClick: x.gestorEventoClick?.eventoParaMenuContextoBotao(dados),
      items: x.items?.map(convertItemContextMenu),
    };

    return item;
  }

  return (
    <Container>
      {editar && <BotaoEditarColunaAcaoGridMxp onClick={() => editar(dados)} />}
      {excluir && (
        <BotaoExcluirColunaAcaoGridMxp onClick={() => excluir(dados)} />
      )}
      {gerarBotoesAdicionais && gerarBotoesAdicionais(dados)}
      {itensColunaAcoes?.map((element, index) => {
        if (element.visivel && !element.visivel(dados)) {
          return;
        }

        return (
          <LinkButton
            key={`${index}-${element.text}`}
            onClick={element.onClick}
            title={element.hint}
          >
            <i className={element.icon}></i>
          </LinkButton>
        );
      })}

      {itensMenuDeContexto?.length != 0 && (
        <>
          <LinkButton
            id={`opc-row-${linkId}`}
            onClick={() => setContextMenuVisivel(!contextMenuVisivel)}
            title="Mais opções"
          >
            <i className="ic-material-symbols-outlined ic-vertical icone-linha-grid"></i>
          </LinkButton>
          <ContextMenu
            items={itensMenuDeContexto}
            target={`#opc-row-${linkId}`}
            visible={contextMenuVisivel}
            onHidden={() => setContextMenuVisivel(false)}
            onItemRendered={eventoTooltipContextMenu}
          />
        </>
      )}
    </Container>
  );
}
