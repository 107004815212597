import ArrayStore from "devextreme/data/array_store";
import { useContext } from "react";
import BotaoCancelarMxp from "../../../../../components/botoes/botao-cancelar-mxp";
import BotaoConfirmarMxp from "../../../../../components/botoes/botao-confirmar-mxp";
import FormMxp from "../../../../../components/layout/form";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import ToolbarMxp from "../../../../../components/layout/toolbar-mxp";
import { ContextoSeletor } from "../../../../../components/selecao/contexto-seletor";
import ProvedorSeletor from "../../../../../components/selecao/provedor-seletor";
import { EstoqueMovimentacaoServico } from "../../../../../services/estoque-movimentacoes/estoque-movimentacoes-service";
import { checarResponse } from "../../../../../utils/api/api-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { EstoqueMovimentacaoSelecionarGridModel } from "../../models/estoque-movimentacao";
import { GridSelecaoEstoqueMovimentacao } from "../grids/grid-estoque-movimentacao";

interface ModalSelecionarMovimentacoesMensagens {
  erro?: string;
  sucesso?: string;
}

interface ModalSelecionarMovimentacoesProps {
  dados: ArrayStore | undefined;
  fecharModal: () => void;
  onEstornado?: () => void;
  mensagens?: ModalSelecionarMovimentacoesMensagens;
}

export function ModalSelecionarMovimentacoesParaEstornar(
  props: ModalSelecionarMovimentacoesProps
) {
  return (
    <ProvedorSeletor>
      <ModalSelecionarMovimentacoesParaEstornarInterna {...props} />
    </ProvedorSeletor>
  );
}

function ModalSelecionarMovimentacoesParaEstornarInterna(
  props: ModalSelecionarMovimentacoesProps
) {
  const { refGrid } = useContext(ContextoSeletor);
  const fecharModal = () => {
    refGrid?.instance().cancelEditData();
    props.fecharModal();
  };

  async function estornarMovsSelecionadas() {
    if (!refGrid) {
      exibirNotificacaoToast({
        mensagem:
          props.mensagens?.erro ??
          "Ocorreu um erro ao estornar as movimentações.",
        tipo: TipoNotificacao.Erro,
      });
      return;
    }

    try {
      const movsParaEstornar = refGrid
        .instance()
        .getVisibleRows()
        .map((row) => row.data as EstoqueMovimentacaoSelecionarGridModel)
        .filter((data) => data.quantidadeParaEstornar > 0);

      const resposta = await EstoqueMovimentacaoServico.EstornarMovimentacoes(
        movsParaEstornar
      );

      checarResponse(resposta);
      if (resposta.sucesso) {
        if (props.onEstornado) {
          props.onEstornado();
        }

        exibirNotificacaoToast({
          mensagem:
            props.mensagens?.sucesso ?? "Movimentações estornadas com sucesso!",
          tipo: TipoNotificacao.Sucesso,
        });
      }
    } catch (error) {
      exibirNotificacaoToast({
        mensagem:
          props.mensagens?.erro ??
          "Ocorreu um erro ao estornar as movimentações.",
        tipo: TipoNotificacao.Erro,
      });
    } finally {
      fecharModal();
    }
  }

  return (
    <ModalMxp
      titulo={"Seleção de movimentações para estornar"}
      visivel={props.dados != undefined}
      handleFechar={fecharModal}
      largura={"auto"}
      altura={"90vh"}
    >
      <FormMxp>
        <GridSelecaoEstoqueMovimentacao
          dataSource={props.dados ?? new ArrayStore({})}
          style={{ height: "100%" }}
        />
      </FormMxp>
      <ToolbarMxp>
        <BotaoConfirmarMxp handleClick={estornarMovsSelecionadas} />
        <BotaoCancelarMxp handleClick={fecharModal} />
      </ToolbarMxp>
    </ModalMxp>
  );
}
