export default class ModeloDeDocumentoOuRelatorioConstantes {
  static readonly DescricaoTamanhoMaximo = 60;

  static readonly ExtensoesAceitas: string = ".docx, .xlsx";

  static readonly TamanhoMaximoDoArquivoEmBytes = 10_485_760; // 10MB

  static readonly ContentTypeWord =
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

  static readonly ContentTypeExcel =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
}
