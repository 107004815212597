import { DataGrid } from "devextreme-react";
import { Column, DataGridRef } from "devextreme-react/data-grid";
import { useCallback, useMemo, useRef } from "react";
import GridColunaAcoes from "../../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import { useRegistrarAtalhosGrid } from "../../../../../../hooks/atalhos.hooks";
import { PermissoesEstoqueMovimentacao } from "../../../../../../models/permissoes/estoque/estoque-movimentacao/permissoes-estoque-movimentacao";
import { GridMxpProps } from "../../../../../../models/shared/ui/grid";
import GetColunasDeAuditoria from "../../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import { EstoqueMovimentacaoServico } from "../../../../../../services/estoque-movimentacoes/estoque-movimentacoes-service";
import {
  checarResponseExibeMensagemExclusaoDeSucesso,
  tratarErroApi,
} from "../../../../../../utils/api/api-utils";
import NomesTelas from "../../../../../../utils/common/nomes-telas";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../../utils/common/permissoes-utils";
import { exibirConfirmacao } from "../../../../../../utils/dialogos";
import GridBuilder from "../../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../../utils/grid/padroes-colunas";
import { OrdemDeProducaoServico } from "../../../../../producao/ordem-de-producao/servicos/ordem-de-producao.service";
import { MovimentacaoBaseGridModel } from "../../../models/estoque-movimentacao.api";
import GetColunasMovimentacao from "../colunas/colunas-movimentacao";

interface GridConclusoesDeOpProps
  extends GridMxpProps<MovimentacaoBaseGridModel> {
  ordemDeProducaoId: number;
}

export default function GridEmbutidaConslusoesDeOp(
  props: GridConclusoesDeOpProps
) {
  const gridRef = useRef<DataGridRef>(null);

  const dataSource =
    EstoqueMovimentacaoServico.ObterDataSourceParaConclusoesOrdemDeProducao(
      props.ordemDeProducaoId,
      props.filtrosNoServidor
    );

  const handleExcluirRegistro = useCallback(
    async (registro: MovimentacaoBaseGridModel) => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesEstoqueMovimentacao.BaixarInsumosForaDaEstrutura,
        ])
      ) {
        return;
      }

      const excluir = await exibirConfirmacao(
        "Confirmar exclusão",
        `Tem certeza de que deseja excluir a conclusão da ordem de produção?`
      );

      if (!excluir) {
        return;
      }

      try {
        const resposta =
          await OrdemDeProducaoServico.DesfazerMovimentacaoDeConclusao(
            registro.id
          );

        if (resposta) {
          checarResponseExibeMensagemExclusaoDeSucesso(resposta);
          handleAtualizarGrid();
        }
      } catch (erro) {
        tratarErroApi(erro);
      }
    },
    [handleAtualizarGrid]
  );

  const gridController = new GridController<MovimentacaoBaseGridModel>(() =>
    gridRef.current?.instance()
  );

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  useRegistrarAtalhosGrid<MovimentacaoBaseGridModel>({
    controller: gridController,
    handleExcluir: handleExcluirRegistro,
  });

  const isGridInterna = true;
  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar(
      "conclusoes-op",
      () => gridRef.current?.instance(),
      isGridInterna,
      props.filtrosNoCliente
    )
      .definirStyles(props.style)
      .definirDataSource(dataSource)
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirFiltros()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(NomesTelas.conclusoesDaOrdemDeProducao)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirSelecao()
      .definirOrdenacao()
      .build();
  }, [gridRef, props.filtrosNoCliente, props.style]);

  return (
    <>
      <DataGrid ref={gridRef} {...configuracoesGrid}>
        <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
        {GridColunaAcoes<MovimentacaoBaseGridModel>({
          handleExcluir: handleExcluirRegistro,
        })}

        {GetColunasMovimentacao([
          { ordenarPor: "dataDaMovimentacao", ordem: "desc" },
        ])}

        {GetColunasDeAuditoria(true, false, [
          { ordenarPor: "criacaoData", ordem: "desc" },
        ])}
      </DataGrid>
    </>
  );
}
