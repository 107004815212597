import { useCallback, useContext, useEffect } from "react";
import BotaoCancelarMxp from "../../../../../../../components/botoes/botao-cancelar-mxp";
import BotaoSalvarMxp from "../../../../../../../components/botoes/botao-salvar-mxp";
import {
  FormGrupo,
  FormTextBoxSimples,
} from "../../../../../../../components/formularios";
import FormTextArea from "../../../../../../../components/formularios/textarea";
import FormMxp from "../../../../../../../components/layout/form";
import {
  Coluna,
  Linha,
} from "../../../../../../../components/layout/grid-system";
import { ModalMxp } from "../../../../../../../components/layout/modal-mxp";
import ToolbarMxp from "../../../../../../../components/layout/toolbar-mxp";
import { useHookForms } from "../../../../../../../hooks/form.hooks";
import {
  IFormularioEditavelBase,
  ResultadoAcaoFormulario,
} from "../../../../../../../models/shared/ui/formularios";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../../../utils/api/api-utils";
import NomesModais from "../../../../../../../utils/common/nomes-modais";
import NormalizaTituloModal from "../../../../../../../utils/common/normaliza-titulo";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../../../utils/common/notificacoes-utils";
import yup from "../../../../../../../utils/validacao/validacao";
import MDFeEditFormContext from "../../../../contexts/mdfe-editform.context";
import { MDFeCancelamentoRequestDTO } from "../../../../models/mdfe";
import { MDFeServico } from "../../../../servicos/mdfe.service";

const novoRegistro: MDFeCancelamentoRequestDTO = {
  justificativa: "",
};

interface EditFormCancelamentoMDFeProps extends IFormularioEditavelBase {
  visivel: boolean;
}

export const EditFormCancelamentoMDFe = ({
  handleCallback,
  idRegistroEmEdicao,
  visivel,
}: EditFormCancelamentoMDFeProps) => {
  const { baseMdfe, dadosGerais, carregar } = useContext(MDFeEditFormContext);

  const schema = yup.object().shape({
    justificativa: yup.string().required().min(15),
  });

  const hookForm = useHookForms(schema);

  const { control, handleSubmit, getValues, reset } = hookForm;

  const carregarTela = useCallback(() => {
    reset(novoRegistro);
  }, [reset]);

  const fechar = useCallback(
    (info: ResultadoAcaoFormulario) => {
      if (handleCallback) {
        handleCallback(info);
      }
    },
    [handleCallback]
  );

  const handleCancelar = useCallback(() => {
    fechar(ResultadoAcaoFormulario.AcaoCancelada);
  }, [fechar]);

  const callBackUnprocessableEntity = useCallback(() => {
    fechar(ResultadoAcaoFormulario.AcaoComErro);
  }, [fechar]);

  useEffect(() => {
    if (visivel) {
      carregarTela();
    }
  }, [visivel]);

  const handleCancelarMDFe = useCallback(async () => {
    try {
      const model = getValues();
      const resposta = await MDFeServico.cancelarMDFe(
        baseMdfe.id,
        model.justificativa
      );
      checarResponse(resposta);
      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar(ResultadoAcaoFormulario.AcaoConcluida);
        carregar(resposta.model.documento);
      }
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    }
  }, [baseMdfe, callBackUnprocessableEntity, carregar, fechar, getValues]);

  return (
    <>
      <ModalMxp
        visivel={visivel}
        handleFechar={handleCancelar}
        titulo={NormalizaTituloModal.Normalizar(
          idRegistroEmEdicao,
          NomesModais.cancelamentoMDFe
        )}
        larguraMaxima="400px"
      >
        <FormMxp>
          <FormGrupo>
            <Linha>
              <Coluna md={6}>
                <FormTextBoxSimples
                  titulo="Número"
                  valor={dadosGerais.numero?.toString() ?? ""}
                  toolTip="Campo referente ao número da MDF-e"
                  tipo="text"
                  somenteLeitura={true}
                />
              </Coluna>
              <Coluna md={6}>
                <FormTextBoxSimples
                  titulo="Série"
                  valor={dadosGerais.serie}
                  toolTip="Campo referente a série do documento MDF-e"
                  tipo="text"
                  somenteLeitura={true}
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={12}>
                <FormTextArea
                  name={"justificativa"}
                  titulo="Justificativa"
                  toolTip="Justificativa do cancelamento da MDF-e"
                  requerido
                  valor=""
                  control={control}
                  tamanhoMaximo={255}
                  height={25}
                />
              </Coluna>
            </Linha>
          </FormGrupo>
        </FormMxp>
        <ToolbarMxp>
          <BotaoSalvarMxp handleClick={handleSubmit(handleCancelarMDFe)} />
          <BotaoCancelarMxp handleClick={handleCancelar} />
        </ToolbarMxp>
      </ModalMxp>
    </>
  );
};
