import DataGrid, { Column, DataGridRef } from "devextreme-react/data-grid";
import ArrayStore from "devextreme/data/array_store";
import {
  forwardRef,
  useCallback,
  useContext,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import ProvedorAjuda from "../../../../../../components/ajuda/provedor-ajuda";
import GridColunaAcoes from "../../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import { Coluna, Linha } from "../../../../../../components/layout/grid-system";
import TabContainer from "../../../../../../components/layout/tab-container";
import ProvedorSeletor from "../../../../../../components/selecao/provedor-seletor";
import SeletorMxp from "../../../../../../components/selecao/seletor-mxp";
import { PermissoesUsuario } from "../../../../../../models/permissoes/seguranca/usuario/permissoes-usuario";
import { IFormulario } from "../../../../../../models/shared/ui/formularios";
import { GridCondutorViewModel } from "../../../../../../models/viewmodels/vendas/mdfe/mdfe-edit-form-view-model";
import criarNameof from "../../../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../../../utils/common/nomes-telas";
import exibirNotificacaoToast, {
  MensagensParaNotificacao,
  TipoNotificacao,
} from "../../../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../../utils/common/permissoes-utils";
import { exibirConfirmacao } from "../../../../../../utils/dialogos";
import GridBuilder from "../../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../../utils/grid/grid-controller";
import { GridProps } from "../../../../../../utils/grid/grid-utils";
import { renderToStringClient } from "../../../../../../utils/react/react-utils";
import GridSelecaoCondutor from "../../../../../sistema/usuario/componentes/grid-selecao-condutor";
import CondutorSelecaoGridModel from "../../../../../sistema/usuario/models/usuario";
import MDFeEditFormContext from "../../../contexts/mdfe-editform.context";

type DadosCondutorProps = GridProps;

const nameof = criarNameof<GridCondutorViewModel>();

export const MDFeAbaCondutor = forwardRef((props: DadosCondutorProps, ref) => {
  const { condutores, adicionarCondutor, removerCondutor } =
    useContext(MDFeEditFormContext);

  const [seletorVisivel, setSeletorVisivel] = useState(false);

  const listaCondutores = useMemo(() => {
    return condutores?.map((c) => {
      const condutor: GridCondutorViewModel = {
        id: c.id,
        cpfCnpj: c.condutor?.cpfCnpj,
        nome: c.condutor?.nome,
        apelido: c.condutor?.apelido,
        idCondutor: c.idCondutor,
      };

      return condutor;
    });
  }, [condutores]);

  console;

  const adicionarSelecaoCondutores = useCallback(
    (dados: CondutorSelecaoGridModel[]) => {
      if (
        !VerificaSeQuantidadeMaximaCondutoresEstaNoPermitido(
          listaCondutores?.length ?? 0,
          dados?.length ?? 0
        )
      ) {
        return;
      }

      const condutoresAdd: GridCondutorViewModel[] = [];

      for (const condutor of dados) {
        if (listaCondutores.some((n) => n.idCondutor == condutor.id)) {
          const mensagem = `O condutor ${condutor.apelido}, CPF nº ${condutor.cpfCnpj} já foi adicionado.`;
          exibirNotificacaoToast({
            mensagem,
            tipo: TipoNotificacao.Advertencia,
          });
        } else {
          condutoresAdd.push({
            id: 0,
            cpfCnpj: condutor.cpfCnpj,
            nome: condutor.nome,
            idCondutor: condutor.id,
            apelido: condutor.apelido,
          });
          exibirNotificacaoToast({
            mensagem: `Condutor ${condutor.nome} adicionado com sucesso. Clique em "Salvar" para confirmar a operação.`,
            tipo: TipoNotificacao.Advertencia,
          });
        }

        adicionarCondutor(condutoresAdd);
      }
    },
    [listaCondutores, adicionarCondutor]
  );

  useImperativeHandle(
    ref,
    (): IFormulario => ({
      requestSubmit() {
        return true;
      },
      valido() {
        return true;
      },
      isDirty() {
        return false;
      },
    })
  );

  function VerificaSeQuantidadeMaximaCondutoresEstaNoPermitido(
    quantidadeJaNaGrade: number,
    quantidaSelecionada: number
  ): boolean {
    const total = quantidadeJaNaGrade + quantidaSelecionada;

    if (total > 10) {
      exibirNotificacaoToast({
        mensagem: `Não é possível ter mais de 10 condutores em um MDF-e. Existem ${quantidadeJaNaGrade} na grade e ${quantidaSelecionada} selecionados.`,
        tipo: TipoNotificacao.Erro,
      });

      return false;
    }

    return true;
  }

  const callBackSeletor = useCallback(
    (ok: boolean, dados: any[]) => {
      if (
        !VerificaSeQuantidadeMaximaCondutoresEstaNoPermitido(
          listaCondutores?.length ?? 0,
          dados?.length ?? 0
        )
      ) {
        return;
      }
      setSeletorVisivel(false);

      if (ok) {
        adicionarSelecaoCondutores(dados as CondutorSelecaoGridModel[]);
      }
    },
    [listaCondutores, setSeletorVisivel, adicionarSelecaoCondutores]
  );

  const callBackFecharSeletor = useCallback(
    () => setSeletorVisivel(false),
    [setSeletorVisivel]
  );

  const dataSourceCondutores = useMemo(
    () => new ArrayStore({ data: listaCondutores, key: "Id" }),
    [listaCondutores]
  );

  const gridRef = useRef<DataGridRef>(null);

  const novoRegistro = () => {
    if (
      !verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesUsuario.Consultar,
      ])
    ) {
      return;
    }

    setSeletorVisivel(true);
  };

  const excluirRegistro = useCallback(
    async (condutor: GridCondutorViewModel | undefined) => {
      if (condutor) {
        const mensagem = renderToStringClient(
          <>
            Tem certeza de que deseja excluir o condutor{" "}
            <strong>
              {condutor.nome}, CPF nº {condutor.cpfCnpj}
            </strong>
            ?
          </>
        );

        const excluir = await exibirConfirmacao("Confirmar exclusão", mensagem);

        if (excluir) {
          removerCondutor(condutor);

          exibirNotificacaoToast({
            mensagem: MensagensParaNotificacao.ExcluidoComSucesso,
            tipo: TipoNotificacao.Sucesso,
          });
        }
      }
    },
    [removerCondutor]
  );

  const gridController = new GridController<GridCondutorViewModel>(() =>
    gridRef.current?.instance()
  );

  const configuracoesGrid = useMemo(() => {
    let builder = GridBuilder.criar(
      "mdfe-lista-condutores",
      () => gridRef.current?.instance(),
      true
    )
      .definirStyles({ height: "100%" })
      .definirDataSource(dataSourceCondutores)
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .configurarExportacao(NomesTelas.condutores)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirOrdenacao();

    if (!props.somenteLeitura) {
      builder = builder.definirBotaoNovo(novoRegistro);
    }

    return builder.build();
  }, [dataSourceCondutores, props.somenteLeitura]);

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  const form = useRef<HTMLFormElement | null>(null);

  return (
    <TabContainer>
      <form ref={form}>
        <Linha>
          <Coluna md={12}>
            <ProvedorAjuda id={"ajuda-mdfe-lista-condutores"}>
              <DataGrid ref={gridRef} {...configuracoesGrid}>
                {GridColunaAcoes<GridCondutorViewModel>({
                  handleExcluir: props.somenteLeitura
                    ? undefined
                    : excluirRegistro,
                })}
                <Column
                  key={nameof("nome")}
                  dataField={nameof("nome")}
                  caption="Nome"
                  allowResizing
                  width={150}
                />
                ,
                <Column
                  key={nameof("cpfCnpj")}
                  dataField={nameof("cpfCnpj")}
                  caption="CPF"
                  allowResizing
                  width={150}
                />
                ,
              </DataGrid>
            </ProvedorAjuda>
          </Coluna>
        </Linha>
      </form>

      <div>
        <ProvedorSeletor>
          <SeletorMxp
            modo="selecaoMultipla"
            visivel={seletorVisivel}
            handleConfirmar={callBackSeletor}
            handleFechar={callBackFecharSeletor}
            componenteGrid={<GridSelecaoCondutor />}
            titulo="Selecionar condutores"
          />
        </ProvedorSeletor>
      </div>
    </TabContainer>
  );
});

export default MDFeAbaCondutor;
