import { DataGridTypes } from "devextreme-react/data-grid";
import { formatarNumero } from "../../../../../utils/formatadores/formatador-de-numeros";
import { InsumoDaOrdemDeProducaoGridModel } from "../../models/insumo-da-ordem-de-producao";

export const ColunaInsumoQuantidadeBaixada = (
  data: DataGridTypes.ColumnCellTemplateData
) => {
  const dadosLinha: InsumoDaOrdemDeProducaoGridModel = data.row.data;
  const quantidadeBaixada = formatarNumero(dadosLinha.quantidadeBaixada, 2, 5);

  if (dadosLinha.quantidadeBaixada > dadosLinha.quantidadeTotal) {
    return (
      <>
        {quantidadeBaixada}
        <span
          className={`ic-column-icon ic-2x ic-material-symbols-outlined ic-keyboard-arrow-right ic-warning`}
          title="A quantidade baixada do insumo é maior do que a quantidade prevista."
        ></span>
      </>
    );
  }

  return <>{quantidadeBaixada}</>;
};
