import {
  ItemContextMenuMxp,
  ItemContextMenuNativo,
} from "../../../utils/context-menu/context-menu-utils";
import { GestorEventoClickUnicaLinha } from "../../../utils/context-menu/gestor-evento-click";
import { GridController } from "../../../utils/grid/grid-controller";
import UrlUtils from "../../../utils/url/url-utils";

export function BotaoCopiarLink(idRegistro: number): ItemContextMenuNativo {
  return {
    text: "Copiar link",
    icon: "ic-material-symbols-outlined ic-link",
    onClick: () => UrlUtils.GerarECopiarUrlEdicao(idRegistro),
  };
}

export function BotaoCopiarLinkContexto<T>(
  controller: GridController<T>
): ItemContextMenuMxp {
  return {
    text: "Copiar link",
    icon: "ic-material-symbols-outlined ic-link",
    exibirNaLinhaDaGrid: "menuDeContexto",
    gestorEventoClick: new GestorEventoClickUnicaLinha(
      // @ts-expect-error: "registro: T" possui id.
      (registro) => UrlUtils.GerarECopiarUrlEdicao(registro.id),
      () => controller
    ),
  };
}
