import { TabPanelRef } from "devextreme-react/tab-panel";
import { definirIndiceSelecionadoTabPanel } from "../tab-panel";

/* Por algum motivo, na primeira exibição do form em um modal, a proprieda "fomrState.isDirty" do react-hook-forms
 * retorna false mesmo com o form alterado, Esse código é um by-pass para isso. */
export function checarSeFormFoiModificado(formState: any) {
  const keys = Object.keys(formState.dirtyFields);
  const dirt = keys
    .map((x) => formState.dirtyFields[x] as boolean | undefined)
    .some((x) => x);
  return formState.isDirty || dirt;
}

export async function verificarValidacaoDasAbasComRedirecionamento<Request>(
  tabPanelRef: React.RefObject<TabPanelRef<any, any>>,
  errors: object,
  dicionarioDosIndicesDasAbasPorCampo: {
    [key in keyof Request]?: number;
  },
  indiceDaAbaPadrao: number
) {
  const nomeDoPrimeiroCampoNaListaDeErrosDeValidacao = Object.keys(
    errors
  )[0] as keyof Request;

  const indiceDaAba =
    dicionarioDosIndicesDasAbasPorCampo[
      nomeDoPrimeiroCampoNaListaDeErrosDeValidacao
    ] ?? indiceDaAbaPadrao;

  definirIndiceSelecionadoTabPanel(tabPanelRef.current, indiceDaAba);
}

export function checarSeFormFoiTocado(formState: any) {
  return Object.keys(formState.touchedFields).length > 0;
}
