import { DataGridRef } from "devextreme-react/data-grid";
import { ReactNode, useState } from "react";
import { ContextoSeletor } from "../contexto-seletor";

interface ProvedorSeletorProps {
  children: ReactNode;
}

export default function ProvedorSeletor(props: ProvedorSeletorProps) {
  const [refGrid, setRefGrid] = useState<DataGridRef | null | undefined>(null);
  const [filtroPadrao, setFiltroPadrao] = useState<any>(undefined);

  return (
    <ContextoSeletor.Provider
      value={{
        refGrid: refGrid,
        setRefGrid: setRefGrid,
        setFiltroPadraoNoCliente: setFiltroPadrao,
        filtroPadraoNoCliente: filtroPadrao,
      }}
    >
      {props.children}
    </ContextoSeletor.Provider>
  );
}
