import { AxiosRequestConfig } from "axios";
import getApi from "../../../../configs/api";
import {
  ResponseBaseArquivo,
  ResponseModel,
} from "../../../../models/api/comum/response-base";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";
import {
  MDFeCancelamentoResponseDTO,
  MDFeCompletoDTO,
  MDFeEncerramentoResponseDTO,
  MDFeEnvioResponseDTO,
} from "../models/mdfe";
import { SituacaoMDFe } from "../models/mdfe-enums";

class MDFeService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.MDFe);
  }

  public async downloadXmlMdfe(id: number, situacao: SituacaoMDFe) {
    const api = getApi();

    const situacaoEndpoints: any = {
      [SituacaoMDFe.Autorizado]: `${this._nomeEndpoint}/XMLAutorizacao`,
      [SituacaoMDFe.Cancelado]: `${this._nomeEndpoint}/XMLCancelamento`,
      [SituacaoMDFe.Encerrado]: `${this._nomeEndpoint}/XMLEncerramento`,
    };

    const endpoint: string = situacaoEndpoints[situacao];
    const resquestConfig: AxiosRequestConfig = {
      responseType: "blob",
    };

    if (endpoint) {
      const response = await api.get<ResponseBaseArquivo>(
        `${endpoint}?id=${id}`,
        resquestConfig
      );
      return response;
    } else {
      throw new Error(
        "Situação da MDFe precisa ser Autorizado, Cancelado ou Encerrado."
      );
    }
  }

  public async consultarDamdfeMdfe(id: number) {
    const api = getApi();
    const resquestConfig: AxiosRequestConfig = {
      responseType: "blob",
    };
    const response = await api.get<ResponseBaseArquivo>(
      `${this._nomeEndpoint}/DAMDFE?id=${id}`,
      resquestConfig
    );

    return response;
  }

  public async desfazerValidacao(id: number) {
    const api = getApi();
    const response = await api.patch<ResponseModel<MDFeCompletoDTO>>(
      `${this._nomeEndpoint}/DesfazerValidacao?id=${id}`
    );

    return response.data;
  }

  public async encerrarMDFe(idMdfeDocumento: number, dataEncerramento: string) {
    const api = getApi();
    const response = await api.post<ResponseModel<MDFeEncerramentoResponseDTO>>(
      `${this._nomeEndpoint}/Encerrar?id=${idMdfeDocumento}&dataEncerramento=${dataEncerramento}`
    );

    return response.data;
  }

  public async autorizar(id: number) {
    const api = getApi();
    const response = await api.post<ResponseModel<MDFeEnvioResponseDTO>>(
      `${this._nomeEndpoint}/Enviar?id=${id}`
    );

    return response.data;
  }

  public async validar(model: MDFeCompletoDTO) {
    const api = getApi();
    const response = await api.put<ResponseModel<MDFeCompletoDTO>>(
      `${this._nomeEndpoint}/Validar`,
      model
    );

    return response.data;
  }

  public async cancelarMDFe(idMDFeDocumento: number, justificativa: string) {
    const api = getApi();
    const response = await api.post<ResponseModel<MDFeCancelamentoResponseDTO>>(
      `${this._nomeEndpoint}/Cancelar?id=${idMDFeDocumento}&motivoCancelamento=${justificativa}`
    );

    return response.data;
  }

  public async consultarSituacao(id: number) {
    const api = getApi();
    const response = await api.post<ResponseModel<MDFeCompletoDTO>>(
      `${this._nomeEndpoint}/ConsultarSituacao?id=${id}`
    );

    return response.data;
  }

  public async duplicar(id: number) {
    const api = getApi();
    const response = await api.post<ResponseModel<MDFeCompletoDTO>>(
      `${this._nomeEndpoint}/Duplicar?id=${id}`
    );

    return response.data;
  }

  async inserirEObterMDFe(model: MDFeCompletoDTO) {
    return await super.InserirComRetorno<
      MDFeCompletoDTO,
      ResponseModel<MDFeCompletoDTO>
    >(model);
  }

  async atualizarEObterMDFe(model: MDFeCompletoDTO) {
    return await super.AtualizarComRetorno<
      MDFeCompletoDTO,
      ResponseModel<MDFeCompletoDTO>
    >(model);
  }
}

export const MDFeServico = new MDFeService();
