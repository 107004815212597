import DataSource from "devextreme/data/data_source";
import {
  assertConfiguracaoExibicaoEBuscaType,
  ConfiguracaoExibicaoEBusca,
} from "../../../../components/formularios/selectbox-lazy-mxp";
import getApi from "../../../../configs/api";
import {
  IdStringResponse,
  ResponseModel,
} from "../../../../models/api/comum/response-base";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";
import { EstoqueMovimentacaoTipo } from "../../../estoque/estoque-movimentacao/models/estoque-movimentacao.enums";
import { ContaContabilGridModel } from "../models/conta-contabil";
import { FinalidadeTipo } from "../models/conta-contabil.enum";

class ContaContabilService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.ContaContabil);
  }

  public async obterIdsPorCodigos(codigos: string[]) {
    const api = getApi();
    const response = await api.post<ResponseModel<IdStringResponse[]>>(
      `${this._nomeEndpoint}/ObterIdsPorCodigos`,
      Array.from(new Set(codigos))
    );

    const map = {} as Record<string, number>;
    if (!response.data.sucesso) {
      return map;
    }
    for (const x of response.data.model) {
      map[x.valor] = x.id;
    }
    return map;
  }

  public GetDataSourceSelectBoxContasDeEstoque(
    tipo?: EstoqueMovimentacaoTipo
  ): DataSource<ContaContabilGridModel, any> {
    const dataSource =
      this.GetDataSourceSelectBoxLazyMxp<ContaContabilGridModel>({
        camposRetorno: ["id", "codigo", "apelido", "finalidade"],
        camposOrdenacao: [
          {
            nomeCampo: "apelido",
            desc: false,
          },
        ],
        filtros: [
          { nomeCampo: "ativo", operador: "=", valor: true },
          { nomeCampo: "podeMovimentarEstoque", operador: "=", valor: true },
        ],
      });

    const filter = dataSource.loadOptions().filter;
    const filtroDeEstoque = [
      ["finalidade", "=", FinalidadeTipo.Estoque],
      "or",
      ["finalidade", "=", FinalidadeTipo.EstoqueDeTerceirosEmNossoPoder],
      "or",
      ["finalidade", "=", FinalidadeTipo.EstoqueDeTerceirosEmPoderDeTerceiros],
      "or",
      ["finalidade", "=", FinalidadeTipo.EstoqueEmPoderDeTerceiros],
    ];
    if (tipo == EstoqueMovimentacaoTipo.Estoque) {
      dataSource.filter([...filter, filtroDeEstoque]);
    } else if (tipo == EstoqueMovimentacaoTipo.ContaContabil) {
      dataSource.filter([...filter, ["!", filtroDeEstoque]]);
    }

    return dataSource;
  }

  public GetDadosSelectBoxContasDeEstoque(): [
    DataSource<ContaContabilGridModel, any>,
    ConfiguracaoExibicaoEBusca<ContaContabilGridModel>
  ] {
    const dataSource = this.GetDataSourceSelectBoxContasDeEstoque();

    const configs =
      assertConfiguracaoExibicaoEBuscaType<ContaContabilGridModel>({
        expressaoDeBusca: ["codigo", "apelido"],
        nomeCampoChave: "id",
        nomeCampoExibicao: (c: ContaContabilGridModel) => {
          if (c) {
            return c.apelido;
          }

          return "";
        },
      });

    return [dataSource, configs];
  }
}

export const ContaContabilServico = new ContaContabilService();
