import { NumberBox } from "devextreme-react";
import { LabelMode } from "devextreme/common";
import { Format } from "devextreme/localization";
import { NumberBoxType } from "devextreme/ui/number_box";
import { Controller, FieldValues, UseControllerProps } from "react-hook-form";
import { selecionarInputAoFocarNoElemento } from "../../../utils/inputs/inputs-utils";
import IconeAjuda from "../../ajuda/icone-ajuda";
import LinkAjuda from "../../ajuda/link-ajuda";
import { LabelErro } from "../label-erro";

interface FormNumberBoxProps<T extends FieldValues>
  extends UseControllerProps<T> {
  tabIndex?: number;
  titulo: string;
  toolTip?: string;
  tipo?: NumberBoxType;
  placeholder?: string;
  tipoPlaceholder?: LabelMode;
  exibirBotaoLimpar?: boolean;
  somenteLeitura?: boolean;
  desabilitado?: boolean;
  requerido?: boolean;
  formato?: Format;
  maximo?: number;
  minimo?: number;
  alinharNaDireita?: boolean;
  exibirLinkAjuda?: boolean;
  onChange?: (e: number) => void;
  valueChangeEvent?: string;
  onEnter?: (e: any) => void;
}

export default function <T extends FieldValues>(props: FormNumberBoxProps<T>) {
  const alinhamento = props.alinharNaDireita ?? true ? "right" : "left";
  return (
    <Controller
      {...props}
      render={({ field, fieldState }) => (
        <>
          <label htmlFor={field.name}>
            <span className="dx-field-item-label-content">
              <span className="dx-field-item-label-text">{props.titulo}</span>
              {props.requerido && (
                <span className="dx-field-item-required-mark">&nbsp;*</span>
              )}
              {(props.exibirLinkAjuda ?? true) && (
                <LinkAjuda keyAjuda={field.name}>
                  <IconeAjuda />
                </LinkAjuda>
              )}
            </span>
          </label>
          <NumberBox
            tabIndex={props.tabIndex}
            hint={props.toolTip}
            mode={props.tipo ?? "number"}
            label={props.placeholder}
            labelMode={props.tipoPlaceholder}
            format={props.formato}
            readOnly={props.somenteLeitura}
            disabled={props.desabilitado}
            showClearButton={props.exibirBotaoLimpar}
            max={props.maximo}
            min={props.minimo}
            step={0}
            valueChangeEvent={props.valueChangeEvent ?? "blur"}
            onEnterKey={props.onEnter}
            onFocusIn={selecionarInputAoFocarNoElemento}
            onValueChange={(value) => {
              if (props.onChange) {
                props.onChange(value);
              }
              field.onChange(value);
            }}
            onFocusOut={() => {
              field.onBlur();
            }}
            value={field.value}
            validationStatus={fieldState.invalid ? "invalid" : "valid"}
            inputAttr={{
              id: field.name,
              style: `text-align:${alinhamento};`,
            }}
          />
          <LabelErro>{fieldState.error?.message}</LabelErro>
        </>
      )}
    />
  );
}
