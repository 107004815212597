import DataGrid, { Column, DataGridRef } from "devextreme-react/data-grid";
import DataSource from "devextreme/data/data_source";
import dxPopup from "devextreme/ui/popup";
import {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { renderToString } from "react-dom/server";
import styled from "styled-components";
import ModalAccordion from "../../../../../components/dialogos/modal-accordion";
import { AccordionDataSource } from "../../../../../components/dialogos/modal-accordion/modal-accordion";
import GridColunaAcoes from "../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import ContextoMenuEdicaoOrdemDeProducao from "../../../../../components/ordem-de-producao/contexto-menu-op";
import { NomesMenuEdicaoOrdemDeProducao } from "../../../../../components/ordem-de-producao/menu-edicao-op";
import { LinkButton } from "../../../../../components/templates-celulas-grid/celula-controle-edicao-mxp/styles";
import {
  DadosModalSelecaoInsumosFilhosDoInsumoAlternativo,
  DesfazerConclusaoDeBaixaDosInsumosResponse,
  InsumoDaOrdemDeProducaoGridModel,
  InsumoDaOrdemDeProducaoResponseDTO,
  InsumosEstornarEmMassaResponse,
  ObterDescricaoInsumo,
} from "../../../../../features/producao/insumo-da-ordem-de-producao/models/insumo-da-ordem-de-producao";
import { OrdemDeProducaoEstado } from "../../../../../features/producao/ordem-de-producao/utils/enums/ordem-de-producao-enums";
import { useRegistrarAtalhosGrid } from "../../../../../hooks/atalhos.hooks";
import { useExcluirRegistroGrid } from "../../../../../hooks/grid.hooks";
import { PermissoesEstoqueMovimentacao } from "../../../../../models/permissoes/estoque/estoque-movimentacao/permissoes-estoque-movimentacao";
import { PermissoesEstoque } from "../../../../../models/permissoes/estoque/estoque/permissoes-estoque";
import { PermissoesFichaFutura } from "../../../../../models/permissoes/estoque/ficha-futura/permissoes-ficha-futura";
import { PermissoesItem } from "../../../../../models/permissoes/itens/item/permissoes-item";
import { PermissoesInsumosDasOrdensDeProducao } from "../../../../../models/permissoes/producao/insumo-da-ordem-de-producao/InsumoDaOrdemDeProducaoPermissoes";
import { ResultadoAcaoFormulario } from "../../../../../models/shared/ui/formularios";
import { GridMxpProps } from "../../../../../models/shared/ui/grid";
import {
  checarResponseExibeMensagemExclusaoDeSucesso,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import criarNameof from "../../../../../utils/common/cria-name-of";
import {
  abrirModalMxpAntigoEmOutraAbaComParametrosAdicionais,
  DadosLink,
  navegarParaMxpAntigoEmNovaAbaComParametros,
} from "../../../../../utils/common/menu-utils";
import NomesModais from "../../../../../utils/common/nomes-modais";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import NormalizaTituloModal from "../../../../../utils/common/normaliza-titulo";
import {
  GeradorMensagensNotificacao,
  JanelasDeNotificacaoTitulos,
} from "../../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import { ItemContextMenuMxp } from "../../../../../utils/context-menu/context-menu-utils";
import {
  GestorEventoClickMultiplasLinhas,
  GestorEventoClickMultiplasLinhasComValoresEditaveis,
  GestorEventoClickUnicaLinha,
} from "../../../../../utils/context-menu/gestor-evento-click";
import { exibirAlerta, exibirConfirmacao } from "../../../../../utils/dialogos";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import { renderToStringClient } from "../../../../../utils/react/react-utils";
import { formatarMensagensAcaoAccordion } from "../../../../comum/utils/accordions/gerador-de-mensagens";
import { ItemResponse } from "../../../../itens/item/models/item.api";
import { ItemServico } from "../../../../itens/item/servicos/item.service";
import { ModalSelecaoFilhosDoInsumoAlternativo } from "../../../estrutura-de-produto/componentes/modal-selecao-estrutura-insumo-alternativo";
import { EstruturaDeProdutoServico } from "../../../estrutura-de-produto/servicos/estrutura-de-produto.service";
import { InsumoDaOrdemDeProducaoServico } from "../../servicos/insumo-da-ordem-de-producao";
import { InsumoEstado } from "../../utils/enum/insumo-da-ordem-de-producao-enums";
import { ColunaInsumoFormataDescricao } from "../colunas-insumos/colunaFormataDescricao";
import { ColunaInsumoQuantidadeBaixada } from "../colunas-insumos/colunaQuantidadeBaixada";
import { colunasInsumos } from "../colunas-insumos/colunas-insumos";
import ContextoOperacoesInsumo from "../contexto-funcoes-insumo";
import FormInsumoDaOrdemDeProducao from "../formulario";
import { ModalBaixarInsumo } from "../modal-baixar-insumo";
import ModalDetalharEstoqueInsumo from "../modal-detalhar-estoque-insumo";
import { ModalEstornarInsumo } from "../modal-estornar-insumo";
import { ModalInsumoPorNumeroDeSerie } from "../modal-insumo-por-numero-de-serie";
import { QuantidadeABaixarEditavelComponent } from "../quantidade-a-baixar-editavel/quantidade-a-baixar-editavel";
import { baixarInsumosSelecionadosHandler } from "./helper/baixar-insumos-em-massa-helper";

const nameOfGridHandler = criarNameof<InsumoDaOrdemDeProducaoGridModel>();

interface GridEmbutidaInsumoDaOrdemDeProducaoProps
  extends GridMxpProps<InsumoDaOrdemDeProducaoGridModel> {
  ordemDeProducaoId: number;
  ordemDeProducaoEstado: OrdemDeProducaoEstado | undefined;
}

const ItemDaListaEstornoBaixa = styled.li`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  min-width: 0;
  color: rgba(0, 0, 0, 0.87);
  border-top: 1px solid #e0e0e0;

  &:hover {
    background-color: whitesmoke;
    border-radius: 4px;
  }
`;

const ListaItensEstornoBaixa = styled.ul`
  max-height: 200px;
  padding-inline-start: 0px;
  list-style-type: none;
  overflow-y: auto;
  height: 100%;
  margin: 0;
`;

const ConfirmarEstornarSelecionados = (
  dataSource: InsumoDaOrdemDeProducaoGridModel[],
  mensagem: string
) => {
  // Quando o popUp de confirmar e tiver o componente lista-insumos-estorno, ele adiciona o listener de rolagem
  // para conseguir rolar a lista
  if (typeof window !== "undefined") {
    dxPopup.defaultOptions({
      options: {
        onInitialized: function (e: any) {
          e.component?.on("shown", function () {
            // Obtem o compontente de lista, se não existir, não insere o listener
            const lista = document.getElementById("lista-insumos-estorno");
            if (lista === null) return;

            // Adiciona o listener para o evento de rolagem
            lista.addEventListener("wheel", function (e: WheelEvent) {
              e.stopPropagation();
            });
          });
        },
      },
    });
  }

  return (
    <div>
      <p>{mensagem}</p>
      <div>
        <ListaItensEstornoBaixa id="lista-insumos-estorno">
          {dataSource.map((item) => (
            <ItemDaListaEstornoBaixa key={item.id}>
              {ObterDescricaoInsumo(item)}
            </ItemDaListaEstornoBaixa>
          ))}
        </ListaItensEstornoBaixa>
      </div>
    </div>
  );
};

function gerarMensagemAccordion(insumos: InsumosEstornarEmMassaResponse[]) {
  const comSucesso = insumos.filter((x) => x.valido);
  const semSucesso = insumos.filter((x) => !x.valido);

  return formatarMensagensAcaoAccordion(comSucesso, semSucesso, {
    acaoSingular: "estornado",
    acaoPlural: "estornados",
    entidadeSingular: "insumo",
    entidadePlural: "insumos",
    formatarEntidade: (entidade) => entidade.item,
    verboPlural: "foram",
    verboSingular: "foi",
  });
}

function gerarAccordionDesfazerConclusaoDeBaixa(
  insumos: DesfazerConclusaoDeBaixaDosInsumosResponse[]
) {
  const comSucesso = insumos.filter((x) => x.valido);
  const semSucesso = insumos.filter((x) => !x.valido);

  return formatarMensagensAcaoAccordion(comSucesso, semSucesso, {
    acaoSingular: "sua conclusão desfeita",
    acaoPlural: "suas conclusões desfeitas",
    entidadeSingular: "insumo",
    entidadePlural: "insumos",
    formatarEntidade: (entidade) => entidade.item,
    verboPlural: "tiveram",
    verboSingular: "teve",
  });
}

export default function GridEmbutidaInsumoDaOrdemDeProducao(
  props: GridEmbutidaInsumoDaOrdemDeProducaoProps
) {
  const gridRef = useRef<DataGridRef>(null);

  const [idRegistroEdicao, setIdRegistroEdicao] = useState(NaN);
  const [modalAccordionVisivel, setModalAccordionVisivel] = useState(false);
  const [detalhamentoAccordion, setDetalhamentoAccordion] =
    useState<AccordionDataSource>();

  const [dataSourceInsumoAlternativo, setDataSourceInsumoAlternativo] =
    useState<DataSource | undefined>(undefined);
  const [
    modalSelecaoInsumosFilhosDoAlternativoVisivel,
    setmodalSelecaoInsumosFilhosDoAlternativoVisivel,
  ] = useState(false);
  const [
    dadosModalSelecaoInsumosFilhosDoAlternativo,
    setDadosModalSelecaoInsumosFilhosDoAlternativo,
  ] = useState<DadosModalSelecaoInsumosFilhosDoInsumoAlternativo>();

  const dataSource =
    InsumoDaOrdemDeProducaoServico.ObterDataSourceParaGridDaOrdemDeProducao(
      props.ordemDeProducaoId,
      props.filtrosNoServidor
    );

  const gridController = useMemo(
    () =>
      new GridController<InsumoDaOrdemDeProducaoGridModel>(() =>
        gridRef.current?.instance()
      ),
    []
  );

  const { addItensMenu } = useContext(ContextoMenuEdicaoOrdemDeProducao);

  const { funcoes } = useContext(ContextoOperacoesInsumo);

  const handleAtualizarGrid = useCallback(() => {
    gridController.atualizar();
  }, [gridController]);

  const handleFecharModalAccordion = useCallback(
    (
      setModalAccordionVisivel: (value: React.SetStateAction<boolean>) => void,
      setDetalhamentoModalAccordion: (
        value: React.SetStateAction<AccordionDataSource | undefined>
      ) => void
    ) => {
      setModalAccordionVisivel(false);
      setDetalhamentoModalAccordion(undefined);
    },
    []
  );

  const handleEstornarSelecionados = useCallback(
    async (insumosSelecionados: InsumoDaOrdemDeProducaoGridModel[]) => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesInsumosDasOrdensDeProducao.DesfazerEstornarBaixas,
        ])
      ) {
        return;
      }

      const htmlConfirmarEstorno = renderToString(
        ConfirmarEstornarSelecionados(
          insumosSelecionados,
          "Tem certeza de que deseja estornar as baixas do(s) insumo(s) selecionados?"
        )
      );
      const confirmar = await exibirConfirmacao(
        JanelasDeNotificacaoTitulos.Atencao,
        htmlConfirmarEstorno
      );

      if (!confirmar) {
        return;
      }

      const resultado = await InsumoDaOrdemDeProducaoServico.EstornarInsumos(
        insumosSelecionados.map((i) => i.id)
      );

      if (resultado.sucesso) {
        setModalAccordionVisivel(true);
        setDetalhamentoAccordion({
          model: gerarMensagemAccordion(resultado.model),
        });

        handleAtualizarGrid();
      }
    },
    [handleAtualizarGrid]
  );

  const handleDesfazerConclusaoDeBaixa = useCallback(
    async (insumosSelecionados: InsumoDaOrdemDeProducaoGridModel[]) => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesInsumosDasOrdensDeProducao.DesfazerEstornarBaixas,
        ])
      ) {
        return;
      }

      const htmlConfirmarDesfazerConclusao = renderToStringClient(
        ConfirmarEstornarSelecionados(
          insumosSelecionados,
          "Tem certeza de que deseja desfazer a conclusão de baixa do(s) insumo(s) selecionados?"
        )
      );
      const confirmar = await exibirConfirmacao(
        JanelasDeNotificacaoTitulos.Atencao,
        htmlConfirmarDesfazerConclusao
      );

      if (!confirmar) {
        return;
      }

      const resultado =
        await InsumoDaOrdemDeProducaoServico.DesfazerConclusaoDeBaixa(
          insumosSelecionados.map((i) => i.id)
        );

      if (resultado.sucesso) {
        setModalAccordionVisivel(true);
        setDetalhamentoAccordion({
          model: gerarAccordionDesfazerConclusaoDeBaixa(resultado.model),
        });

        handleAtualizarGrid();
      }
    },
    [handleAtualizarGrid]
  );

  const handleBaixarSelecionadosClick = useCallback(
    async (registros: InsumoDaOrdemDeProducaoGridModel[]) => {
      const result = await baixarInsumosSelecionadosHandler(
        registros,
        props.ordemDeProducaoEstado,
        handleAtualizarGrid
      );

      if (result) {
        const { accordionVisivel, mensagem } = result;
        setModalAccordionVisivel(accordionVisivel);
        setDetalhamentoAccordion({
          model: mensagem,
        });
      }
    },
    [handleAtualizarGrid, props.ordemDeProducaoEstado]
  );

  const handleConsultarFichaFutura = useCallback(
    async (insumoSelecionado: InsumoDaOrdemDeProducaoGridModel) => {
      if (insumoSelecionado.itemId) {
        if (
          !verificaComNotificacaoSeUsuarioPossuiPermissoes([
            PermissoesFichaFutura.Consultar,
          ])
        ) {
          return;
        }

        const rota = `/PerfilEstoque`;
        const parametros = { idItem: insumoSelecionado.itemId };
        navegarParaMxpAntigoEmNovaAbaComParametros(rota, parametros);
      }
    },
    []
  );

  const handleConsultarEstoque = useCallback(
    async (insumoSelecionado: InsumoDaOrdemDeProducaoGridModel) => {
      if (insumoSelecionado.itemId) {
        if (
          !verificaComNotificacaoSeUsuarioPossuiPermissoes([
            PermissoesEstoque.Consultar,
          ])
        ) {
          return;
        }

        const rota = `/ItemEstoque`;
        const parametros = {
          visualizarEstoqueOutrasEmpresas: "True",
          idItem: insumoSelecionado.itemId,
        };
        navegarParaMxpAntigoEmNovaAbaComParametros(rota, parametros);
      }
    },
    []
  );

  const handleConsultarRoteiroDeProducao = useCallback(
    async (insumoSelecionado: InsumoDaOrdemDeProducaoGridModel) => {
      if (insumoSelecionado.itemId) {
        if (
          !verificaComNotificacaoSeUsuarioPossuiPermissoes([
            PermissoesItem.ConsultarRoteiroDoItem,
          ])
        ) {
          return;
        }

        const rota = `Item/PorCodigo`;
        const dadosLink: DadosLink = {
          rota: "_Item_Consultar_Roteiro_Por_MXP2",
          tipo: "javaScriptMxpAntigo",
        };
        const parametrosAdicionais = { cod: insumoSelecionado.codigo };
        abrirModalMxpAntigoEmOutraAbaComParametrosAdicionais(
          dadosLink,
          parametrosAdicionais,
          rota
        );
      }
    },
    []
  );

  const handleConsultarMovimentacoesDeBaixa = useCallback(
    async (insumoSelecionado: InsumoDaOrdemDeProducaoGridModel) => {
      if (insumoSelecionado.itemId) {
        if (
          !verificaComNotificacaoSeUsuarioPossuiPermissoes([
            PermissoesEstoqueMovimentacao.Consultar,
          ])
        ) {
          return;
        }

        const rota = `/Movimentacao`;
        const parametros = {
          exibicao: "Fisicos",
          idItem: insumoSelecionado.itemId,
          operacao: "BaixaInsumo",
          op: insumoSelecionado.ordemDeProducaoId,
        };
        navegarParaMxpAntigoEmNovaAbaComParametros(rota, parametros);
      }
    },
    []
  );

  const handleConsultarItem = useCallback(
    async (insumoSelecionado: InsumoDaOrdemDeProducaoGridModel) => {
      if (insumoSelecionado.itemId) {
        if (
          !verificaComNotificacaoSeUsuarioPossuiPermissoes([
            PermissoesItem.Consultar,
          ])
        ) {
          return;
        }

        const item = await ItemServico.ObterPorId<ItemResponse>(
          insumoSelecionado.itemId
        );
        const rota = `Item/PorCodigo`;
        const dadosLink: DadosLink = {
          rota: "_Itens_AbreModalEdicaoItem",
          tipo: "javaScriptMxpAntigo",
          paramFunction: () => `(${insumoSelecionado.itemId})`,
        };
        const parametrosAdicionais = { cod: item.model.codigo };
        abrirModalMxpAntigoEmOutraAbaComParametrosAdicionais(
          dadosLink,
          parametrosAdicionais,
          rota
        );
      }
    },
    []
  );

  const menus: ItemContextMenuMxp[] = useMemo(
    () => [
      {
        icon: "ic-material-symbols-outlined ic-category",
        text: NomesMenuEdicaoOrdemDeProducao.insumos.name,
        name: NomesMenuEdicaoOrdemDeProducao.insumos.name,
        items: [
          {
            icon: "ic-material-symbols-outlined ic-arrow-downward",
            text: NomesMenuEdicaoOrdemDeProducao.insumos.baixarSelecionados,
            name: NomesMenuEdicaoOrdemDeProducao.insumos.baixarSelecionados,
            gestorEventoClick:
              new GestorEventoClickMultiplasLinhasComValoresEditaveis(
                handleBaixarSelecionadosClick,
                () => gridController
              ),
          },
          {
            icon: "ic-material-symbols-outlined ic-undo",
            text: NomesMenuEdicaoOrdemDeProducao.insumos.estornarBaixas,
            name: NomesMenuEdicaoOrdemDeProducao.insumos.estornarBaixas,
            gestorEventoClick: new GestorEventoClickMultiplasLinhas(
              handleEstornarSelecionados,
              () => gridController
            ),
          },
          {
            icon: "ic-material-symbols-outlined ic-undo",
            text: NomesMenuEdicaoOrdemDeProducao.insumos
              .desfazerConclusaoSemEstornarBaixas,
            name: NomesMenuEdicaoOrdemDeProducao.insumos
              .desfazerConclusaoSemEstornarBaixas,
            gestorEventoClick: new GestorEventoClickMultiplasLinhas(
              handleDesfazerConclusaoDeBaixa,
              () => gridController
            ),
          },
          {
            icon: "ic-material-symbols-outlined ic-manage-search",
            text: NomesMenuEdicaoOrdemDeProducao.consultar.name,
            name: NomesMenuEdicaoOrdemDeProducao.consultar.name,
            items: [
              {
                icon: "ic-material-symbols-outlined ic-overview",
                text: NomesMenuEdicaoOrdemDeProducao.consultar.fichaFutura,
                name: NomesMenuEdicaoOrdemDeProducao.consultar.fichaFutura,
                gestorEventoClick: new GestorEventoClickUnicaLinha(
                  handleConsultarFichaFutura,
                  () => gridController
                ),
              },
              {
                icon: "ic-material-symbols-outlined ic-shelves",
                text: NomesMenuEdicaoOrdemDeProducao.insumos.consultar.estoque,
                name: NomesMenuEdicaoOrdemDeProducao.insumos.consultar.estoque,
                gestorEventoClick: new GestorEventoClickUnicaLinha(
                  handleConsultarEstoque,
                  () => gridController
                ),
              },
              {
                icon: "ic-material-symbols-outlined ic-format-list-bulleted",
                text: NomesMenuEdicaoOrdemDeProducao.insumos.consultar
                  .roteiroDeProducao,
                name: NomesMenuEdicaoOrdemDeProducao.insumos.consultar
                  .roteiroDeProducao,
                gestorEventoClick: new GestorEventoClickUnicaLinha(
                  handleConsultarRoteiroDeProducao,
                  () => gridController
                ),
              },
              {
                icon: "ic-material-symbols-outlined ic-list-alt-check",
                text: NomesMenuEdicaoOrdemDeProducao.insumos.consultar
                  .movimentacoesDeBaixa,
                name: NomesMenuEdicaoOrdemDeProducao.insumos.consultar
                  .movimentacoesDeBaixa,
                gestorEventoClick: new GestorEventoClickUnicaLinha(
                  handleConsultarMovimentacoesDeBaixa,
                  () => gridController
                ),
              },
              {
                icon: "ic-material-symbols-outlined ic-app-registration",
                text: NomesMenuEdicaoOrdemDeProducao.insumos.consultar.item,
                name: NomesMenuEdicaoOrdemDeProducao.insumos.consultar.item,
                gestorEventoClick: new GestorEventoClickUnicaLinha(
                  handleConsultarItem,
                  () => gridController
                ),
              },
            ],
          },
        ],
      },
    ],
    [
      handleBaixarSelecionadosClick,
      handleEstornarSelecionados,
      handleDesfazerConclusaoDeBaixa,
      handleConsultarFichaFutura,
      handleConsultarEstoque,
      handleConsultarRoteiroDeProducao,
      handleConsultarMovimentacoesDeBaixa,
      handleConsultarItem,
      gridController,
    ]
  );

  useEffect(() => {
    if (!gridRef || !gridController) return;

    funcoes.definirAtualizaGridInsumoAoFechar(() => {
      handleAtualizarGrid();
    });

    addItensMenu(menus);
  }, [
    addItensMenu,
    funcoes,
    gridController,
    gridRef,
    handleAtualizarGrid,
    menus,
  ]);

  const handleNovoRegistro = useCallback(() => {
    if (
      !verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesInsumosDasOrdensDeProducao.InserirEditar,
      ])
    ) {
      return;
    }

    setIdRegistroEdicao(0);
  }, []);

  const handleEditarRegistro = useCallback(
    (registro: InsumoDaOrdemDeProducaoGridModel) => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesInsumosDasOrdensDeProducao.InserirEditar,
        ])
      ) {
        return;
      }

      if (registro.estado != InsumoEstado.ABaixar) {
        exibirAlerta(
          JanelasDeNotificacaoTitulos.Atencao,
          "Não é possível editar este insumo, pois ele possui baixas de estoque.<br> Para prosseguir com a edição, estorne as baixas deste insumo e tente novamente."
        );
        return;
      }

      if (props.ordemDeProducaoEstado == OrdemDeProducaoEstado.Cancelada) {
        exibirAlerta(
          JanelasDeNotificacaoTitulos.Atencao,
          "Não é possível editar os insumos desta ordem de produção, pois ela está cancelada."
        );
        return;
      }

      setIdRegistroEdicao(registro.id);
    },
    [props.ordemDeProducaoEstado]
  );

  const handleExcluirRegistro = useExcluirRegistroGrid(
    async (registro: InsumoDaOrdemDeProducaoGridModel) => {
      try {
        if (
          !verificaComNotificacaoSeUsuarioPossuiPermissoes([
            PermissoesInsumosDasOrdensDeProducao.Excluir,
          ])
        ) {
          return;
        }

        const excluir = await exibirConfirmacao(
          "Confirmar exclusão",
          `Tem certeza de que deseja excluir o insumo ${ObterDescricaoInsumo(
            registro
          )}?`
        );

        if (excluir) {
          const resposta = await InsumoDaOrdemDeProducaoServico.Excluir(
            registro.id
          );

          if (resposta) {
            checarResponseExibeMensagemExclusaoDeSucesso(
              resposta,
              GeradorMensagensNotificacao.ExcluidoComSucessoMasculino("Insumo")
            );
            handleAtualizarGrid();
          }
        }
      } catch (erro) {
        tratarErroApi(erro);
      }
    }
  );

  const handleFecharModal = useCallback(() => {
    setIdRegistroEdicao(NaN);
  }, []);

  const handleCallbackFormulario = useCallback(
    (resultado: ResultadoAcaoFormulario) => {
      setIdRegistroEdicao(NaN);

      if (resultado == ResultadoAcaoFormulario.AcaoConcluida) {
        handleAtualizarGrid();
      }
    },
    [handleAtualizarGrid]
  );

  useRegistrarAtalhosGrid<InsumoDaOrdemDeProducaoGridModel>({
    controller: gridController,
    handleNovo:
      props.ordemDeProducaoEstado != OrdemDeProducaoEstado.AProduzir
        ? handleNovoRegistro
        : undefined,
    handleEditar: handleEditarRegistro,
    handleExcluir: handleExcluirRegistro,
  });

  const botaoNovoVisivel =
    props.ordemDeProducaoEstado === OrdemDeProducaoEstado.AProduzir;

  const abrirModalSelecaoInsumosFilhosDoAlternativo = useCallback(
    async (
      data: InsumoDaOrdemDeProducaoGridModel,
      isBaixadoCompletamente: boolean
    ) => {
      if (isBaixadoCompletamente) {
        await exibirAlerta(
          JanelasDeNotificacaoTitulos.Atencao,
          "Esse item foi totalmente baixado e não é mais possível selecionar os itens alternativos.<br /> " +
            "Caso deseje realizar alterações na seleção dos itens-filhos, realize o estorno da baixa."
        );
        return;
      }
      const quantidadeASelecionarFaltante =
        data.quantidadeASelecionarInsumoAlternativo ?? data.quantidadeTotal;

      const respostaFilhosDoInsumoAlternativo =
        await InsumoDaOrdemDeProducaoServico.ObterInsumosFilhosPorInsumoAlternativoId<InsumoDaOrdemDeProducaoResponseDTO>(
          data.id
        );

      if (respostaFilhosDoInsumoAlternativo.sucesso) {
        const modelInsumosFilhosDoInsumoAlternativo =
          respostaFilhosDoInsumoAlternativo.model;
        const dataSourceInsumoAlternativo =
          EstruturaDeProdutoServico.ObterDataSourceDaDaEstruturaDoItemPaiAlternativo(
            data.itemId,
            data.quantidadeTotal,
            quantidadeASelecionarFaltante,
            modelInsumosFilhosDoInsumoAlternativo
          );

        const dadosModalSelecaoInsumoAlternativo: DadosModalSelecaoInsumosFilhosDoInsumoAlternativo =
          {
            insumoPaiId: data.id,
            quantidadeTotalInsumoPai: data.quantidadeTotal,
          };

        setDadosModalSelecaoInsumosFilhosDoAlternativo(
          dadosModalSelecaoInsumoAlternativo
        );
        setDataSourceInsumoAlternativo(dataSourceInsumoAlternativo);

        setmodalSelecaoInsumosFilhosDoAlternativoVisivel(true);
      }
    },
    []
  );

  const fecharModalSelecaoInsumosFilhosDoAlternativo = () => {
    setmodalSelecaoInsumosFilhosDoAlternativoVisivel(false);
    setDataSourceInsumoAlternativo(undefined);
  };

  function gerarBotoesAdicionais(
    data: InsumoDaOrdemDeProducaoGridModel
  ): ReactNode[] {
    const botoesAdicionais: ReactNode[] = [];

    if (data.isProcedenciaAlternativo) {
      const isBaixadoCompletamente =
        data.estado == InsumoEstado.BaixadoCompletamente;
      botoesAdicionais.push(
        <LinkButton
          key={"btn-selecionar-insumos-filhos-do-alternativo"}
          onClick={() =>
            abrirModalSelecaoInsumosFilhosDoAlternativo(
              data,
              isBaixadoCompletamente
            )
          }
          title="Abrir modal de seleção de insumos filhos do alternativo"
          className="btn-selecionar-insumos-filhos-do-alternativo"
          isDisabled={isBaixadoCompletamente}
        >
          <i className="ic-material-symbols-outlined ic-alt-route icone-linha-grid"></i>
        </LinkButton>
      );
    }

    return botoesAdicionais;
  }

  const configuracoesGrid = useMemo(() => {
    let configsGrid = GridBuilder.criar(
      "insumo-daordem-de-producao-grid-embutida",
      () => gridRef.current?.instance(),
      false,
      props.filtrosNoCliente
    )
      .definirStyles(props.style)
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .definirDuploCliqueLinha(handleEditarRegistro)
      .configurarSelecionadorDeColunas()
      .definirSelecao()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(NomesTelas.insumosDaOrdemDeProducao)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirOrdenacao()
      .definirEditavel()
      .definirOperacoesNoLadoDoCliente();

    if (botaoNovoVisivel) {
      configsGrid = configsGrid.definirBotaoNovo(handleNovoRegistro);
    }

    return configsGrid.build();
  }, [
    props.filtrosNoCliente,
    props.style,
    dataSource,
    handleEditarRegistro,
    handleAtualizarGrid,
    botaoNovoVisivel,
    handleNovoRegistro,
  ]);

  return (
    <>
      <DataGrid ref={gridRef} {...configuracoesGrid}>
        <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
        {GridColunaAcoes<InsumoDaOrdemDeProducaoGridModel>({
          handleEditar: handleEditarRegistro,
          handleExcluir: handleExcluirRegistro,
          gerarBotoesAdicionais: gerarBotoesAdicionais,
        })}
        <Column
          key={nameOfGridHandler("operacao")}
          dataField={nameOfGridHandler("operacao")}
          {...obterConfiguracaoColuna("stringM")}
          allowEditing={false}
          sortOrder="asc"
          sortIndex={1}
          caption="Operação"
        />
        <Column
          key={nameOfGridHandler("descricao")}
          dataField={nameOfGridHandler("descricao")}
          {...obterConfiguracaoColuna("stringM")}
          allowEditing={false}
          width={350}
          caption="Descrição"
          visibleIndex={3}
          cellRender={ColunaInsumoFormataDescricao}
        />
        <Column
          key={nameOfGridHandler("insumoPaiAlternativo")}
          dataField={nameOfGridHandler("insumoPaiAlternativo")}
          {...obterConfiguracaoColuna("stringM")}
          caption="Insumo pai alternativo"
          visibleIndex={4}
          width={350}
          allowEditing={false}
          visible={false}
        />
        <Column
          key={nameOfGridHandler("quantidadeASelecionarInsumoAlternativo")}
          dataField={nameOfGridHandler(
            "quantidadeASelecionarInsumoAlternativo"
          )}
          {...obterConfiguracaoColuna(
            "quantidadeComNoMaximoCincoCasasDecimais"
          )}
          caption="Qt a selecionar"
          visibleIndex={9}
          width={150}
          allowEditing={false}
          visible={false}
        />
        <Column
          key={nameOfGridHandler("quantidadeBaixada")}
          dataField={nameOfGridHandler("quantidadeBaixada")}
          {...obterConfiguracaoColuna(
            "quantidadeComNoMaximoCincoCasasDecimais"
          )}
          allowEditing={false}
          visibleIndex={10}
          caption="Qt baixada"
          cellRender={ColunaInsumoQuantidadeBaixada}
        />
        <Column
          key={nameOfGridHandler("quantidadeABaixar")}
          dataField={nameOfGridHandler("quantidadeABaixar")}
          {...obterConfiguracaoColuna("quantidade")}
          allowEditing={true}
          showEditorAlways={true}
          width={200}
          visibleIndex={11}
          caption="Qt a baixar"
          editCellComponent={QuantidadeABaixarEditavelComponent}
        ></Column>
        {colunasInsumos}
      </DataGrid>

      <ModalBaixarInsumo />

      <ModalInsumoPorNumeroDeSerie />

      <ModalEstornarInsumo />

      <ModalDetalharEstoqueInsumo />

      <ModalAccordion
        modalAccordionVisivel={modalAccordionVisivel}
        handlerFecharModalAccordion={() =>
          handleFecharModalAccordion(
            setModalAccordionVisivel,
            setDetalhamentoAccordion
          )
        }
        dataSource={detalhamentoAccordion?.model}
        modalTitulo={detalhamentoAccordion?.accordionTitulo}
        accordionId="accordion-acao-insumo"
        itemDoAccordionAltura="auto"
      />

      <ModalMxp
        titulo={NormalizaTituloModal.Normalizar(
          idRegistroEdicao,
          NomesModais.insumoDaOrdemDeProducao
        )}
        visivel={!Number.isNaN(idRegistroEdicao)}
        handleFechar={handleFecharModal}
        largura={"max(30vw, 800px)"}
        altura={" auto"}
      >
        <FormInsumoDaOrdemDeProducao
          idRegistroEmEdicao={idRegistroEdicao}
          ordemDeProducaoId={props.ordemDeProducaoId}
          handleCallback={handleCallbackFormulario}
        />
      </ModalMxp>
      {dadosModalSelecaoInsumosFilhosDoAlternativo && (
        <ModalSelecaoFilhosDoInsumoAlternativo
          dados={dataSourceInsumoAlternativo}
          dadosModal={dadosModalSelecaoInsumosFilhosDoAlternativo}
          visivel={modalSelecaoInsumosFilhosDoAlternativoVisivel}
          fecharModal={fecharModalSelecaoInsumosFilhosDoAlternativo}
        />
      )}
    </>
  );
}
