import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HomeBackground } from "../../../../../components/layout/login-bg/styles";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import RequerPermissao from "../../../../../components/seguranca/requer-permissao";
import VerificacaoBackend from "../../../../../components/seguranca/verificacao-backend";
import { PermissoesLucroPresumido } from "../../../../../models/permissoes/contabilidade/ecf/permissoes-lucro-presumido";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import { EcfServico } from "../../servicos/ecf.service";
import EditFormEcfLucroPresumido from "../formulario";

export const ModalEcf = () => {
  const [visivel, setVisivel] = useState(true);
  const navigate = useNavigate();

  const handleModalCallback = useCallback(() => {
    setVisivel(false);
    navigate("/", {
      replace: true,
    });
  }, [navigate]);

  const checkarGeracaoEcf = async () => {
    const resposta = await EcfServico.PodeGerarEcf();
    return resposta;
  };

  return (
    <VerificacaoBackend checagem={checkarGeracaoEcf}>
      <RequerPermissao codigoPermissoes={[PermissoesLucroPresumido.Gerar]}>
        <HomeBackground>
          <ModalMxp
            titulo={NomesTelas.ecf}
            visivel={visivel}
            handleFechar={handleModalCallback}
            largura={"max(30vw, 600px)"}
            altura={"auto"}
          >
            <EditFormEcfLucroPresumido
              idRegistroEmEdicao={0}
              handleCallback={handleModalCallback}
            />
          </ModalMxp>
        </HomeBackground>
      </RequerPermissao>
    </VerificacaoBackend>
  );
};
