import { useCallback, useContext } from "react";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import RenderOnDemand from "../../../../../parts/utils/load-on-demand";
import { FinalidadeModeloDeDocumentoOuRelatorioTituloHelper } from "../../../../sistema/modelo-de-documento-ou-relatorio/models/modelo-de-documento-ou-relatorio.enums";
import RelatorioPersonalizadoContexto from "../../contexts/relatorio-personalizado.context";
import DicionarioTagsSchemaProvider from "../dicionario-tags-schema-provider";
import PainelPrincipalDicionarioTags from "../painel-principal-dicionario-tags";

export default function ModalDicionarioTags() {
  const {
    finalidade,
    modalDicionarioTagsVisivel,
    definirModalDicionarioTagsVisivel,
  } = useContext(RelatorioPersonalizadoContexto);

  const handleFechar = useCallback(() => {
    definirModalDicionarioTagsVisivel(false);
  }, []);

  return (
    <>
      <ModalMxp
        titulo={`Dicionário de tags de ${FinalidadeModeloDeDocumentoOuRelatorioTituloHelper.obterTitulo(
          finalidade!
        )}`}
        visivel={modalDicionarioTagsVisivel}
        handleFechar={handleFechar}
      >
        <RenderOnDemand visivel={modalDicionarioTagsVisivel}>
          <DicionarioTagsSchemaProvider>
            <PainelPrincipalDicionarioTags tipo={finalidade!} modal />
          </DicionarioTagsSchemaProvider>
        </RenderOnDemand>
      </ModalMxp>
    </>
  );
}
