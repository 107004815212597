import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";
import { AccordionModel } from "../../components/dialogos/modal-accordion/modal-accordion";
import {
  DetalhesAssinaturaOpcionaisAssinaturaResponse,
  DetalhesPermissoesResponse,
} from "../../features/seguranca/login/models/login.api";
import {
  ItemListaSimplesStringDTO,
  ItemOpcionalAssinaturaModel,
  NotificationsResponse,
} from "../../models/api/comum/response-base";
import EstadoUI, {
  AccordionModelErro,
  EstadosAtualizacaoToken,
} from "../../models/shared/ui/estado-ui";

const INITIAL_STATE: EstadoUI = {
  atualizandoToken: "ocioso",
  loginReturnUrl: null,
  accordionNotifications: {
    visivel: false,
    titulo: "Erro",
    notificacoes: {
      sucesso: false,
      mensagem: "",
      model: [],
      erros: [],
    },
  },
  errosAccordion: [],
  necessitaOpcionais: {
    visivel: false,
    dados: [],
  },
  linksAjuda: [],
  detalhesPermissoes: [],
  detalhesOpcionaisAssinatura: [],
  modalRequerPermissao: {
    visivel: false,
    permissoes: [],
  },
  mensagemLogoff: null,
  desabilitarToasts: false,
  redirecionando: false,
  modalSobre: {
    visivel: false,
  },
  quantidadeDeRequisicoesSendoExecutadas: 0,
};

const estadoUISlice = createSlice({
  name: "ui",
  initialState: INITIAL_STATE,
  reducers: {
    definirEstadoAtualizacaoToken: definirEstadoAtualizacaoTokenAction,
    definirLoginReturnUrl: definirLoginReturnUrlAction,
    exibirAccordionNotifications: exibirAccordionNotificationsAction,
    ocultarAccordionNotifications: ocultarAccordionNotificationsAction,
    exibirNecessitaOpcionais: exibirNecessitaOpcionaisAction,
    ocultarNecessitaOpcionais: ocultarNecessitaOpcionaisAction,
    definirLinksAjuda: definirLinksAjudaAction,
    definirDetalhesPermissoes: definirDetalhesPermissoesAction,
    definirDetalhesOpcionaisAssinatura:
      definirDetalhesOpcionaisAssinaturaAction,
    exibirModalRequerPermissao: exibirModalRequerPermissaoAction,
    ocultarModalRequerPermissao: ocultarModalRequerPermissaoAction,
    definirMensagemLogoff: definirMensagemLogoffAction,
    limparMensagemLogoff: limparMensagemLogoffAction,
    habilitarToasts: habilitarToastsAction,
    desabilitarToasts: desabilitarToastsAction,
    definirEstadoRedirecionando: definirEstadoRedirecionandoAction,
    ocultarModalSobre: ocultarModalSobreAction,
    exibirModalSobre: exibirModalSobreAction,
    bloquearUi: bloquearUiAction,
    desbloquearUi: desbloquearUiAction,
    enfileirarErroAccordion: enfileirarErroAccordionAction,
    removerErroAccordion: removerErroAccordionAction,
    limparErrosAccordion: limparErrosAccordionAction,
  },
});

function desabilitarToastsAction(state: EstadoUI) {
  state.desabilitarToasts = true;
}

function habilitarToastsAction(state: EstadoUI) {
  state.desabilitarToasts = false;
}

function ocultarModalRequerPermissaoAction(state: EstadoUI) {
  state.modalRequerPermissao = INITIAL_STATE.modalRequerPermissao;
}

function exibirModalRequerPermissaoAction(
  state: EstadoUI,
  action: PayloadAction<string[]>
) {
  state.modalRequerPermissao.visivel = true;
  state.modalRequerPermissao.permissoes = action.payload;
}

function definirEstadoAtualizacaoTokenAction(
  state: EstadoUI,
  action: PayloadAction<EstadosAtualizacaoToken>
) {
  state.atualizandoToken = action.payload;
}

function definirLoginReturnUrlAction(
  state: EstadoUI,
  action: PayloadAction<string | null>
) {
  state.loginReturnUrl = action.payload;
}

function exibirAccordionNotificationsAction(
  state: EstadoUI,
  action: PayloadAction<NotificationsResponse>
) {
  state.accordionNotifications.notificacoes = action.payload;
  state.accordionNotifications.visivel = true;
  state.accordionNotifications.titulo = action.payload.titulo ?? "Erro";
}

function ocultarAccordionNotificationsAction(state: EstadoUI) {
  state.accordionNotifications = INITIAL_STATE.accordionNotifications;
}

function exibirNecessitaOpcionaisAction(
  state: EstadoUI,
  action: PayloadAction<ItemOpcionalAssinaturaModel[]>
) {
  state.necessitaOpcionais.dados = action.payload;
  state.necessitaOpcionais.visivel = true;
}

function ocultarNecessitaOpcionaisAction(state: EstadoUI) {
  state.necessitaOpcionais.visivel = false;
  state.necessitaOpcionais.dados = [];
}

function definirLinksAjudaAction(
  state: EstadoUI,
  action: PayloadAction<ItemListaSimplesStringDTO[]>
) {
  state.linksAjuda = action.payload;
}

function definirDetalhesPermissoesAction(
  state: EstadoUI,
  action: PayloadAction<DetalhesPermissoesResponse[]>
) {
  state.detalhesPermissoes = action.payload;
}

function definirDetalhesOpcionaisAssinaturaAction(
  state: EstadoUI,
  action: PayloadAction<DetalhesAssinaturaOpcionaisAssinaturaResponse[]>
) {
  state.detalhesOpcionaisAssinatura = action.payload;
}

function definirMensagemLogoffAction(
  state: EstadoUI,
  action: PayloadAction<string>
) {
  state.mensagemLogoff = action.payload;
}

function limparMensagemLogoffAction(state: EstadoUI) {
  state.mensagemLogoff = null;
}

function definirEstadoRedirecionandoAction(
  state: EstadoUI,
  action: PayloadAction<boolean>
) {
  state.redirecionando = action.payload;
}

function ocultarModalSobreAction(state: EstadoUI) {
  state.modalSobre = INITIAL_STATE.modalSobre;
}

function exibirModalSobreAction(state: EstadoUI) {
  state.modalSobre.visivel = true;
}

function bloquearUiAction(state: EstadoUI) {
  // Foi proposital fazer incremental, para evitar a tela piscar
  state.quantidadeDeRequisicoesSendoExecutadas =
    state.quantidadeDeRequisicoesSendoExecutadas + 1;
}

function desbloquearUiAction(state: EstadoUI) {
  if (state.quantidadeDeRequisicoesSendoExecutadas - 1 < 1) {
    state.quantidadeDeRequisicoesSendoExecutadas = 0;
  } else {
    state.quantidadeDeRequisicoesSendoExecutadas =
      state.quantidadeDeRequisicoesSendoExecutadas - 1;
  }
}

function enfileirarErroAccordionAction(
  state: EstadoUI,
  action: PayloadAction<AccordionModel[]>
) {
  const novoItem: AccordionModelErro = {
    id: uuid(),
    model: action.payload,
  };
  state.errosAccordion.push(novoItem);
}

function removerErroAccordionAction(
  state: EstadoUI,
  action: PayloadAction<string>
) {
  const ix = state.errosAccordion.findIndex((x) => x.id == action.payload);

  if (ix >= 0) {
    state.errosAccordion.splice(ix, 1);
  }
}

function limparErrosAccordionAction(state: EstadoUI) {
  state.errosAccordion = [];
}

export const {
  definirEstadoAtualizacaoToken,
  definirLoginReturnUrl,
  exibirAccordionNotifications,
  ocultarAccordionNotifications,
  exibirNecessitaOpcionais,
  ocultarNecessitaOpcionais,
  definirLinksAjuda,
  definirDetalhesPermissoes,
  definirDetalhesOpcionaisAssinatura,
  exibirModalRequerPermissao,
  ocultarModalRequerPermissao,
  definirMensagemLogoff,
  limparMensagemLogoff,
  habilitarToasts,
  desabilitarToasts,
  definirEstadoRedirecionando,
  ocultarModalSobre,
  exibirModalSobre,
  bloquearUi,
  desbloquearUi,
  enfileirarErroAccordion,
  removerErroAccordion,
  limparErrosAccordion,
} = estadoUISlice.actions;
export default estadoUISlice;
