import { yupResolver } from "@hookform/resolvers/yup";
import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { useForm } from "react-hook-form";
import FormTextArea from "../../../../../../components/formularios/textarea";
import { Coluna, Linha } from "../../../../../../components/layout/grid-system";
import { IFormulario } from "../../../../../../models/shared/ui/formularios";
import { MDFeAbaObservacoesViewModel } from "../../../../../../models/viewmodels/vendas/mdfe/mdfe-edit-form-view-model";
import { checarSeFormFoiModificado } from "../../../../../../utils/common/form-utils";
import yup from "../../../../../../utils/validacao/validacao";
import MDFeEditFormContext from "../../../contexts/mdfe-editform.context";

interface DadosObservacoesProps {
  abaSomenteLeitura: boolean;
}

const schema = yup.object().shape({
  id: yup.number().required().moreThan(-1).integer(),
  contribuinte: yup.string().nullable().max(5000),
  fisco: yup.string().nullable().max(2000),
  internas: yup.string().nullable().max(5000),
});

export const MDFeAbaObservacoes = forwardRef(
  ({ abaSomenteLeitura }: DadosObservacoesProps, ref) => {
    const { baseMdfe, dadosObservacoes, definirDadosObservacoes } =
      useContext(MDFeEditFormContext);

    const model: MDFeAbaObservacoesViewModel = useMemo(() => {
      return {
        id: baseMdfe.id,
        contribuinte: dadosObservacoes.contribuinte,
        fisco: dadosObservacoes.fisco,
        internas: dadosObservacoes.internas,
      };
    }, [baseMdfe, dadosObservacoes]);

    const form = useRef<HTMLFormElement | null>(null);

    const { reset, control, formState, handleSubmit } =
      useForm<MDFeAbaObservacoesViewModel>({
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: yupResolver(schema),
      });

    const carregarTela = useCallback(async () => {
      reset(model);
    }, [reset, model]);

    useEffect(() => {
      if (!Number.isNaN(baseMdfe.id)) {
        carregarTela();
      }
    }, [baseMdfe.id]);

    function handleSubmitInterno(data: MDFeAbaObservacoesViewModel) {
      definirDadosObservacoes(data);
    }

    // Repassar referências para componente pai
    useImperativeHandle(
      ref,
      (): IFormulario => ({
        requestSubmit() {
          form.current?.requestSubmit();
        },
        valido() {
          form.current?.requestSubmit();
          return Object.keys(formState.errors).length == 0;
        },
        isDirty() {
          return checarSeFormFoiModificado(formState);
        },
      }),
      [formState, form]
    );

    return (
      <form ref={form} onSubmit={handleSubmit(handleSubmitInterno)}>
        <Linha>
          <Coluna md={6}>
            <FormTextArea
              name={"fisco"}
              titulo={"Informações adicionais de interesse do fisco"}
              valor={""}
              control={control}
              tamanhoMaximo={2000}
              height={25}
              somenteLeitura={abaSomenteLeitura}
            />
          </Coluna>
          <Coluna md={6}>
            <FormTextArea
              name={"contribuinte"}
              titulo={"Informações adicionais de interesse do contribuinte"}
              valor={""}
              control={control}
              tamanhoMaximo={5000}
              height={25}
              somenteLeitura={abaSomenteLeitura}
            />
          </Coluna>
        </Linha>
        <Linha>
          <Coluna md={6}>
            <FormTextArea
              name={"internas"}
              titulo={"Observações internas"}
              valor={""}
              control={control}
              tamanhoMaximo={5000}
              height={25}
              somenteLeitura={abaSomenteLeitura}
            />
          </Coluna>
        </Linha>
      </form>
    );
  }
);
