import PaginaMxp from "../../../../../components/layout/pagina-mxp";
import ProvedorMenus from "../../../../../components/menus/provedor-menus";
import RequerPermissao from "../../../../../components/seguranca/requer-permissao";
import { PermissoesProjecaoDeUso } from "../../../../../models/permissoes/estoque/projecao-de-uso/permissoes-projecao-de-uso";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import GridProjecaoDeUso from "../../componentes/grids";

export default function ProjecaoDeUsoPage() {
  return (
    <ProvedorMenus>
      <PaginaMxp
        id="projecao-de-uso-page"
        titulo={NomesTelas.projecaoDeUso}
        area={["Estoque"]}
      >
        <RequerPermissao codigoPermissoes={[PermissoesProjecaoDeUso.Consultar]}>
          <GridProjecaoDeUso />
        </RequerPermissao>
      </PaginaMxp>
    </ProvedorMenus>
  );
}
