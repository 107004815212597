import { PermissoesOperacaoDeOrdemDeProducao } from "../../../../../../models/permissoes/producao/operacao-de-ordem-de-producao/permissoes-operacao-de-ordem-de-producao";
import { tratarErroApi } from "../../../../../../utils/api/api-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../../utils/common/permissoes-utils";
import { OrdemDeProducaoGridModel } from "../../../models/ordem-de-producao.api";
import { OrdemDeProducaoServico } from "../../../servicos/ordem-de-producao.service";

async function verificarSeOrdemDeProducaoPodeCriarBoletimDeInspecaoComOperacoes(
  registro: OrdemDeProducaoGridModel
) {
  if (
    !verificaComNotificacaoSeUsuarioPossuiPermissoes([
      PermissoesOperacaoDeOrdemDeProducao.Consultar,
    ])
  ) {
    return;
  }

  try {
    const response =
      await OrdemDeProducaoServico.VerificarSeOrdemDeProducaoPodeCriarBoletimDeInspecaoComOperacoes(
        registro.id
      );
    return response.model;
  } catch (erro) {
    tratarErroApi(erro);
  }
}

export { verificarSeOrdemDeProducaoPodeCriarBoletimDeInspecaoComOperacoes };
