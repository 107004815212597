import { ValueChangedEvent } from "devextreme/ui/check_box";
import { useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { FormCheckBox } from "../../../../../../components/formularios";
import { Coluna, Linha } from "../../../../../../components/layout/grid-system";
import TabContainer from "../../../../../../components/layout/tab-container";
import TituloGrid from "../../../../../../components/texto/titulo-grid";
import { useCarregarCombos } from "../../../../../../hooks/form.hooks";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../../utils/api/api-utils";
import NomesTelas from "../../../../../../utils/common/nomes-telas";
import { JanelasDeNotificacaoTitulos } from "../../../../../../utils/common/notificacoes-utils";
import { exibirAlerta } from "../../../../../../utils/dialogos";
import { renderToStringClient } from "../../../../../../utils/react/react-utils";
import { CentroDeTrabalhoRequestDTO } from "../../../models/centro-de-trabalho.api";
import { CentroDeTrabalhoServico } from "../../../servicos/centro-de-trabalho.servico";
import GridInternalVinculoEnderecoEstoque from "./grid-vinculo-endereco-estoque";
interface CentroDeTrabalhoAbaVinculoEnderecoEstoqueProps {
  idCentroDeTrabalhoVinculado: number;
  hookForm: UseFormReturn<CentroDeTrabalhoRequestDTO, any, undefined>;
}

export default function CentroDeTrabalhoAbaVinculoEnderecoEstoque(
  props: CentroDeTrabalhoAbaVinculoEnderecoEstoqueProps
) {
  const isRegistroEdicao = props.idCentroDeTrabalhoVinculado != 0;

  const { getValues, setValue, control } = props.hookForm;

  const [
    isGridVinculoEnderecoDeEstoqueVisivel,
    setGridVinculoEnderecoDeEstoqueVisivel,
  ] = useState(false);
  const [
    limitarBaixasPorEnderecoDeEstoqueCheckBoxValue,
    setLimitarBaixasPorEnderecoDeEstoqueCheckBoxValue,
  ] = useState(false);

  useCarregarCombos(props.idCentroDeTrabalhoVinculado, carregarDados);

  async function carregarDados() {
    if (!isRegistroEdicao) {
      return;
    }

    const valores = getValues();
    setValue(
      "baixasLimitarPorEnderecoDeEstoque",
      valores?.baixasLimitarPorEnderecoDeEstoque ?? false
    );
    setLimitarBaixasPorEnderecoDeEstoqueCheckBoxValue(
      valores?.baixasLimitarPorEnderecoDeEstoque ?? false
    );
    setGridVinculoEnderecoDeEstoqueVisivel(
      valores?.baixasLimitarPorEnderecoDeEstoque ?? false
    );
  }

  async function visualizaGrid(valueEvent: ValueChangedEvent) {
    setLimitarBaixasPorEnderecoDeEstoqueCheckBoxValue(
      valueEvent.value as boolean
    );

    if (
      (!valueEvent.value as boolean) &&
      !isNaN(Number(props.idCentroDeTrabalhoVinculado)) &&
      valueEvent.event
    ) {
      valueEvent.event.preventDefault();
      if (isRegistroEdicao && limitarBaixasPorEnderecoDeEstoqueCheckBoxValue) {
        const existeVinculos = await verificaSeCtTemVinculosEnderecoEstoque();

        const alertaMensagem = renderToStringClient(
          <>
            <p>
              Existem um ou mais vínculos com endereços de estoque cadastrados
              para este centro de trabalho, não sendo possível desmarcar a
              <br />
              opção de &quot;Limitar as baixas de insumos por endereços de
              estoque neste centro de trabalho&quot;. Para prosseguir, caso
              deseje, realize a
              <br />
              exclusão de todos os vínculos.
            </p>
          </>
        );

        if (existeVinculos) {
          await exibirAlerta(
            JanelasDeNotificacaoTitulos.Atencao,
            alertaMensagem
          );

          setValue("baixasLimitarPorEnderecoDeEstoque", true);
          setLimitarBaixasPorEnderecoDeEstoqueCheckBoxValue(true);
          setGridVinculoEnderecoDeEstoqueVisivel(true);
          return;
        }
      }
    }

    setValue("baixasLimitarPorEnderecoDeEstoque", valueEvent.value as boolean);
    setGridVinculoEnderecoDeEstoqueVisivel(valueEvent.value as boolean);
  }

  async function verificaSeCtTemVinculosEnderecoEstoque() {
    try {
      const resposta =
        await CentroDeTrabalhoServico.existeVinculosEnderecoEstoqueCentroDeTrabalho(
          props.idCentroDeTrabalhoVinculado
        );
      checarResponse(resposta);

      return resposta.model;
    } catch (erro) {
      tratarErroApi(erro);

      return true;
    }
  }

  return (
    <TabContainer>
      <Linha>
        <Coluna md={12}>
          <FormCheckBox
            name={"baixasLimitarPorEnderecoDeEstoque"}
            titulo="Limitar as baixas de insumos por endereços de estoque neste centro de trabalho"
            defaultValue={false}
            desabilitado={!isRegistroEdicao}
            control={control}
            onValueChanged={visualizaGrid}
          />
        </Coluna>
      </Linha>
      {isGridVinculoEnderecoDeEstoqueVisivel && (
        <Linha>
          <Coluna md={12}>
            <TituloGrid texto={NomesTelas.vinculosEnderecoEstoque} />
            <GridInternalVinculoEnderecoEstoque
              idCentroDeTrabalho={props.idCentroDeTrabalhoVinculado}
              hookForm={props.hookForm}
            />
          </Coluna>
        </Linha>
      )}
    </TabContainer>
  );
}
