import { DataGrid } from "devextreme-react";
import { Column, DataGridRef, DataGridTypes } from "devextreme-react/data-grid";
import { useCallback, useMemo, useRef, useState } from "react";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import { LinkButtonStyleComponent } from "../../../../../components/botoes/link-button/styles";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import { MenuEdicaoOrdemDeProducao } from "../../../../../components/ordem-de-producao/menu-edicao-op";
import { ProvedorMenuEdicaoOrdemDeProducao } from "../../../../../components/ordem-de-producao/provedor-menu-op";
import { useRegistrarAtalhosGrid } from "../../../../../hooks/atalhos.hooks";
import { useSeletorGridMxp } from "../../../../../hooks/seletor.hooks";
import { ResultadoAcaoFormulario } from "../../../../../models/shared/ui/formularios";
import { GridMxpProps } from "../../../../../models/shared/ui/grid";
import GetColunasDeAuditoria from "../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import criarNameof from "../../../../../utils/common/cria-name-of";
import {
  abrirModalMxpAntigo,
  DadosLink,
  navegarParaMxpAntigoEmNovaAba,
} from "../../../../../utils/common/menu-utils";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import { formatarDataAno4digitos } from "../../../../../utils/formatadores/formatador-de-datas";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import FormInsumoDaOrdemDeProducao from "../../../../producao/insumo-da-ordem-de-producao/componentes/formulario";
import FormOrdemDeProducao from "../../../../producao/ordem-de-producao/componentes/formularios";
import { ProvedorOrdemDeProducaoGrid } from "../../../../producao/ordem-de-producao/componentes/provedor-contexto-ordem-de-producao/provedor-ordem-de-producao-grid";
import {
  InformacaoDaEntidadeOrigemDestinoDaProjecaoDeUso,
  InformacaoDoEstoqueOrigemDaProjecaoDeUso,
  ProjecaoDeUsoGridModel,
} from "../../models/projecao-de-uso.api";
import { ProjecaoDeUsoServico } from "../../service/projecao-de-uso.service";
import {
  TipoDeDestino,
  TipoDeOrigem,
  TipoDeOrigemEDestinoHelper,
} from "../../utils/enum/tipo-destino-ou-origem";

const nameOfGridHandler = criarNameof<ProjecaoDeUsoGridModel>();

const formatarDataEntrada = (
  data: DataGridTypes.ColumnCellTemplateData | ProjecaoDeUsoGridModel
): string | null => {
  const dados =
    "row" in data && data.row
      ? data.row.data
      : (data as ProjecaoDeUsoGridModel);

  if (!dados) return null;

  const dataDeOrigem =
    TipoDeOrigemEDestinoHelper.getDescricaoOuDataDaEntidadeCorrespondente(
      "data",
      dados.informacoesOrigemEstoque ??
        dados.informacoesOrigemItemDoPedidoDeCompra ??
        dados.informacoesOrigemOrdemDeProducao ??
        dados.informacoesOrigemSolicitacaoDeCompra
    );

  dados.dataEntrada = dataDeOrigem;
  return dataDeOrigem ? formatarDataAno4digitos(dataDeOrigem) : null;
};

const formatarDataSaida = (
  data: DataGridTypes.ColumnCellTemplateData | ProjecaoDeUsoGridModel
): string | null => {
  const dados =
    "row" in data && data.row
      ? data.row.data
      : (data as ProjecaoDeUsoGridModel);

  if (!dados) return null;

  const dataDeDestino =
    TipoDeOrigemEDestinoHelper.getDescricaoOuDataDaEntidadeCorrespondente(
      "data",
      dados.informacoesDestinoOperacaoDaOrdemDeProducao ??
        dados.informacoesDestinoItemDoPedidoDeVenda ??
        dados.informacoesDestinoSolicitacaoDeCompra ??
        dados.informacoesDestinoItemDoPedidoDeCompra ??
        dados.informacoesDestinoInsumoDaOrdemDeProducao
    );

  dados.dataSaida = dataDeDestino;
  return dataDeDestino ? formatarDataAno4digitos(dataDeDestino) : null;
};

const formatarItemDoPedidoDeVendaFinal = (
  data: DataGridTypes.ColumnCellTemplateData | ProjecaoDeUsoGridModel
) => {
  const dados =
    "row" in data && data.row
      ? (data.row.data as ProjecaoDeUsoGridModel)
      : (data as ProjecaoDeUsoGridModel);
  const itemDoPedidoDeVendaFinalId = dados.itemDoPedidoDeVendaFinalId;
  if (!dados || !itemDoPedidoDeVendaFinalId) return null;

  // Coloca-se o id do pv como 0 pois nunca será aberto um modal dele, por conta do terceiro parâmetro ser false
  // _ZoomChips_AbrirModalPVITePV(idPvit, idPv, abrirPv)
  const dadosLink: DadosLink = {
    rota: "_ZoomChips_AbrirModalPVITePV",
    tipo: "javaScriptMxpAntigo",
    paramFunction: () => `(${itemDoPedidoDeVendaFinalId.toString()}, 0, false)`,
  };

  return (
    <LinkButtonStyleComponent
      $sublinhado={true}
      key={`${itemDoPedidoDeVendaFinalId}`}
      onClick={() => {
        abrirModalMxpAntigo(dadosLink, true);
      }}
    >
      {dados.itemDoPedidoDeVendaFinal}
    </LinkButtonStyleComponent>
  );
};

export default function GridProjecaoDeUso(
  props: GridMxpProps<ProjecaoDeUsoGridModel>
) {
  const gridRef = useRef<DataGridRef>(null);
  const [modalModalOrdemDeProducaoVisivel, setModalOrdemDeProducaoVisivel] =
    useState<boolean>(false);
  const [ordemDeProducaoId, setOrdemDeProducaoId] = useState<number>(0);
  const [modalInsumoVisivel, setModalInsumoVisivel] = useState<boolean>(false);
  const [insumoId, setInsumoId] = useState<number>(0);
  const [ordemDeProducaoDoInsumoId, setOrdemDeProducaoDoInsumoId] =
    useState<number>(0);

  const dataSource =
    ProjecaoDeUsoServico.ObterDataSourceParaGrid<ProjecaoDeUsoGridModel>(
      props.filtrosNoServidor
    );

  useSeletorGridMxp(() => gridRef.current, props.filtrosNoCliente);

  const gridController = useMemo(
    () =>
      new GridController<ProjecaoDeUsoGridModel>(() =>
        gridRef.current?.instance()
      ),
    []
  );

  const handleAtualizarGrid = useCallback(() => {
    gridController.atualizar();
  }, [gridController]);

  useRegistrarAtalhosGrid<ProjecaoDeUsoGridModel>({
    controller: gridController,
  });

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar(
      "projecao-de-uso",
      () => gridRef.current?.instance(),
      false,
      props.filtrosNoCliente
    )
      .definirDataSource(dataSource)
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirFiltros()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(NomesTelas.projecaoDeUso)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirSelecao()
      .definirOrdenacao()
      .build();
  }, [props.filtrosNoCliente, dataSource, handleAtualizarGrid]);

  const handleFecharModalOrdemDeProducao = useCallback(() => {
    setOrdemDeProducaoId(0);
    setModalOrdemDeProducaoVisivel(false);
  }, []);

  const handleCallbackFormularioEdicaoOrdemDeProducao = useCallback(
    (resultado: ResultadoAcaoFormulario) => {
      setOrdemDeProducaoId(NaN);

      if (
        resultado == ResultadoAcaoFormulario.AcaoConcluida ||
        resultado == ResultadoAcaoFormulario.AcaoCancelada
      ) {
        setModalOrdemDeProducaoVisivel(false);
        handleAtualizarGrid();
      }
    },
    [handleAtualizarGrid]
  );

  const handleFecharModalInsumo = useCallback(() => {
    setModalInsumoVisivel(false);
  }, []);

  const handleCallbackFormularioEdicaoInsumo = useCallback(
    (resultado: ResultadoAcaoFormulario) => {
      setInsumoId(NaN);
      setOrdemDeProducaoDoInsumoId(NaN);

      if (
        resultado == ResultadoAcaoFormulario.AcaoConcluida ||
        resultado == ResultadoAcaoFormulario.AcaoCancelada
      ) {
        setModalInsumoVisivel(false);
        handleAtualizarGrid();
      }
    },
    [handleAtualizarGrid]
  );

  const criarLinkDestino = (
    tipoDestino: TipoDeDestino,
    informacoes: InformacaoDaEntidadeOrigemDestinoDaProjecaoDeUso | null
  ) => {
    if (!informacoes) return "";

    let rotaMxp1: string = "";
    let funcaoAbrirMxp1: string = "";

    if (tipoDestino == TipoDeDestino.ItemDoPedidoDeCompra) {
      rotaMxp1 = "NotaFiscal/PedidoCompra";
      funcaoAbrirMxp1 = "_NotaFiscal_AbrirModalEdicaoAtravesMxp2";
    } else if (tipoDestino == TipoDeDestino.ItemDoPedidoDeVenda) {
      rotaMxp1 = "NotaFiscal/PedidoVenda";
      funcaoAbrirMxp1 = "_NotaFiscal_AbrirModalEdicaoAtravesMxp2";
    } else if (tipoDestino == TipoDeDestino.SolicitacaoDeCompra) {
      rotaMxp1 = "SolicitacaoCompra";
      funcaoAbrirMxp1 = "_SolicitacaoCompra_AbrirModalEdicaoAtravesMxp2";
    }

    const dadosLink: DadosLink = {
      rota: funcaoAbrirMxp1,
      tipo: "javaScriptMxpAntigo",
      paramFunction: () => `(${informacoes.entidadeId.toString()})`,
    };

    return (
      <LinkButtonStyleComponent
        $sublinhado={true}
        key={`${informacoes.entidadeId}`}
        onClick={() => {
          if (tipoDestino == TipoDeDestino.InsumoDaOrdemDeProducao) {
            setInsumoId(informacoes.entidadeId);
            setOrdemDeProducaoDoInsumoId(
              informacoes.entidadeRelacionadaId ?? 0
            );
            setModalInsumoVisivel(true);
          } else if (tipoDestino == TipoDeDestino.OperacaoDaOrdemDeProducao) {
            setOrdemDeProducaoId(informacoes.entidadeId);
            setModalOrdemDeProducaoVisivel(true);
          } else {
            abrirModalMxpAntigo(dadosLink, true, rotaMxp1);
          }
        }}
      >
        {informacoes.descricao ?? ""}
      </LinkButtonStyleComponent>
    );
  };

  const criarLinkOrigem = (
    tipoOrigem: TipoDeOrigem,
    informacoes:
      | InformacaoDaEntidadeOrigemDestinoDaProjecaoDeUso
      | InformacaoDoEstoqueOrigemDaProjecaoDeUso
      | null
  ) => {
    if (!informacoes) return "";

    let rotaMxp1: string = "";
    let funcaoAbrirMxp1: string = "";

    if (tipoOrigem == TipoDeOrigem.ItemDoPedidoDeCompra) {
      rotaMxp1 = "NotaFiscal/PedidoCompra";
      funcaoAbrirMxp1 = "_NotaFiscal_AbrirModalEdicaoAtravesMxp2";
    } else if (tipoOrigem == TipoDeOrigem.SolicitacaoDeCompra) {
      rotaMxp1 = "SolicitacaoCompra";
      funcaoAbrirMxp1 = "_SolicitacaoCompra_AbrirModalEdicaoAtravesMxp2";
    } else if (tipoOrigem == TipoDeOrigem.Estoque) {
      const informacoesEstoque =
        informacoes as InformacaoDoEstoqueOrigemDaProjecaoDeUso;
      rotaMxp1 = `/ItemEstoque?visualizarEstoqueOutrasEmpresas=True&iditem=${
        informacoesEstoque.itemId
      }&loteDoFabricanteNumeroDeSerie=${
        informacoesEstoque.loteDoFabricanteNumeroDeSerie ?? ""
      }&idEnderecoDeEstoque=${informacoesEstoque.enderecoDeEstoqueId}&idConta=${
        informacoesEstoque.contaId
      }`;
    }

    const dadosLink: DadosLink = {
      rota: funcaoAbrirMxp1,
      tipo: "javaScriptMxpAntigo",
      paramFunction: () => `(${informacoes.entidadeId.toString()})`,
    };

    return (
      <LinkButtonStyleComponent
        $sublinhado={true}
        key={`${informacoes.entidadeId}`}
        onClick={() => {
          if (tipoOrigem == TipoDeOrigem.OrdemDeProducao) {
            setOrdemDeProducaoId(informacoes.entidadeId);
            setModalOrdemDeProducaoVisivel(true);
          } else if (tipoOrigem == TipoDeOrigem.Estoque) {
            navegarParaMxpAntigoEmNovaAba(rotaMxp1);
          } else {
            abrirModalMxpAntigo(dadosLink, true, rotaMxp1);
          }
        }}
      >
        {informacoes.descricao ?? ""}
      </LinkButtonStyleComponent>
    );
  };

  const formatarOrigem = (
    data: DataGridTypes.ColumnCellTemplateData | ProjecaoDeUsoGridModel
  ) => {
    const dados =
      "row" in data && data.row
        ? (data.row.data as ProjecaoDeUsoGridModel)
        : (data as ProjecaoDeUsoGridModel);

    if (!dados) return null;

    const informacoesDaEntidade =
      dados.informacoesOrigemEstoque ??
      dados.informacoesOrigemItemDoPedidoDeCompra ??
      dados.informacoesOrigemOrdemDeProducao ??
      dados.informacoesOrigemSolicitacaoDeCompra;

    const hyperLink = criarLinkOrigem(
      dados.tipoDaOrigem,
      informacoesDaEntidade
    );

    return hyperLink;
  };

  const formatarDestino = (
    data: DataGridTypes.ColumnCellTemplateData | ProjecaoDeUsoGridModel
  ) => {
    const dados =
      "row" in data && data.row
        ? (data.row.data as ProjecaoDeUsoGridModel)
        : (data as ProjecaoDeUsoGridModel);

    if (!dados) return null;

    const informacoesDaEntidade =
      dados.informacoesDestinoOperacaoDaOrdemDeProducao ??
      dados.informacoesDestinoItemDoPedidoDeVenda ??
      dados.informacoesDestinoSolicitacaoDeCompra ??
      dados.informacoesDestinoItemDoPedidoDeCompra ??
      dados.informacoesDestinoInsumoDaOrdemDeProducao;

    const hyperLink = criarLinkDestino(
      dados.tipoDoDestino,
      informacoesDaEntidade
    );

    return hyperLink;
  };

  return (
    <>
      <ProvedorOrdemDeProducaoGrid>
        <ProvedorAjuda id={"tooltips-grid-projecao-de-uso"}>
          <DataGrid ref={gridRef} {...configuracoesGrid}>
            <Column
              key={nameOfGridHandler("itemCodigo")}
              dataField={nameOfGridHandler("itemCodigo")}
              {...obterConfiguracaoColuna("stringM")}
              width={120}
              caption="Item"
            />
            <Column
              key={nameOfGridHandler("itemDescricao")}
              dataField={nameOfGridHandler("itemDescricao")}
              {...obterConfiguracaoColuna("stringGG")}
              caption="Descrição"
              width={280}
            />
            <Column
              key={nameOfGridHandler("dataEntrada")}
              dataField={nameOfGridHandler("dataEntrada")}
              {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
              width={90}
              caption="Entrada"
              cellRender={formatarDataEntrada}
              allowFiltering={false}
            />
            <Column
              key={nameOfGridHandler("tipoDaOrigemDecodificada")}
              dataField={nameOfGridHandler("tipoDaOrigemDecodificada")}
              {...obterConfiguracaoColuna("stringMG")}
              caption="Tipo de origem"
              width={170}
            />
            <Column
              key={nameOfGridHandler("origem")}
              dataField={nameOfGridHandler("origem")}
              {...obterConfiguracaoColuna("stringGG")}
              caption="Origem"
              cellRender={formatarOrigem}
              allowFiltering={false}
              allowSorting={false}
              width={250}
            />
            <Column
              key={nameOfGridHandler("dataSaida")}
              dataField={nameOfGridHandler("dataSaida")}
              {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
              width={90}
              sortOrder="desc"
              caption="Saída"
              cellRender={formatarDataSaida}
              allowFiltering={false}
            />
            <Column
              key={nameOfGridHandler("tipoDeDestinoDecodificado")}
              dataField={nameOfGridHandler("tipoDeDestinoDecodificado")}
              {...obterConfiguracaoColuna("stringG")}
              caption="Tipo de destino"
              width={190}
            />
            <Column
              key={nameOfGridHandler("destino")}
              dataField={nameOfGridHandler("destino")}
              {...obterConfiguracaoColuna("stringGG")}
              caption="Destino"
              cellRender={formatarDestino}
              allowFiltering={false}
              allowSorting={false}
              width={280}
            />
            <Column
              key={nameOfGridHandler("quantidade")}
              dataField={nameOfGridHandler("quantidade")}
              {...obterConfiguracaoColuna(
                "quantidadeComNoMaximoCincoCasasDecimais"
              )}
              caption="Qt"
              width={110}
            />
            <Column
              key={nameOfGridHandler("itemUnidadeDeMedida")}
              dataField={nameOfGridHandler("itemUnidadeDeMedida")}
              {...obterConfiguracaoColuna("stringM")}
              caption="Unid"
              width={75}
            />
            <Column
              key={nameOfGridHandler("itemDoPedidoDeVendaFinal")}
              dataField={nameOfGridHandler("itemDoPedidoDeVendaFinal")}
              {...obterConfiguracaoColuna("stringGG")}
              cellRender={formatarItemDoPedidoDeVendaFinal}
              caption="Item do pedido de venda"
            />
            <Column
              key={nameOfGridHandler("quantidadeReservada")}
              dataField={nameOfGridHandler("quantidadeReservada")}
              {...obterConfiguracaoColuna(
                "quantidadeComNoMaximoCincoCasasDecimais"
              )}
              caption="Qt reservada"
            />
            <Column
              key={nameOfGridHandler("quantidadeNaoReservada")}
              dataField={nameOfGridHandler("quantidadeNaoReservada")}
              {...obterConfiguracaoColuna(
                "quantidadeComNoMaximoCincoCasasDecimais"
              )}
              caption="Qt não reservada"
            />
            {GetColunasDeAuditoria(false, false, [
              { ordenarPor: "criacaoData", ordem: "desc" },
            ])}
          </DataGrid>

          {/*Modal Ordem de produção*/}
          <ProvedorMenuEdicaoOrdemDeProducao>
            <ModalMxp
              titulo={"Ordem de produção"}
              visivel={modalModalOrdemDeProducaoVisivel}
              handleFechar={handleFecharModalOrdemDeProducao}
              largura={"99vw"}
              altura={"99vh"}
              alturaMaxima={"92vh"}
              larguraMaxima={"98vw"}
              componentesAdicionais={MenuEdicaoOrdemDeProducao}
            >
              <FormOrdemDeProducao
                idRegistroEmEdicao={ordemDeProducaoId}
                setIdRegistroEmEdicao={setOrdemDeProducaoId}
                handleCallback={handleCallbackFormularioEdicaoOrdemDeProducao}
                handleImpressaoCallback={() => undefined}
              />
            </ModalMxp>

            <ModalMxp
              titulo={"Insumo"}
              visivel={modalInsumoVisivel}
              handleFechar={handleFecharModalInsumo}
              largura={"max(30vw, 800px)"}
              altura={" auto"}
            >
              <FormInsumoDaOrdemDeProducao
                idRegistroEmEdicao={insumoId}
                ordemDeProducaoId={ordemDeProducaoDoInsumoId}
                handleCallback={handleCallbackFormularioEdicaoInsumo}
              />
            </ModalMxp>
          </ProvedorMenuEdicaoOrdemDeProducao>
        </ProvedorAjuda>
      </ProvedorOrdemDeProducaoGrid>
    </>
  );
}
