import { useContext, useEffect, useState } from "react";
import BotaoCancelarMxp from "../../../../../components/botoes/botao-cancelar-mxp";
import FormMxp from "../../../../../components/layout/form";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import ToolbarMxp from "../../../../../components/layout/toolbar-mxp";
import {
  FormataDescricao,
  FormatadoresSelectBox,
} from "../../../../../utils/formatadores/formatador-de-selectbox";
import { InsumoDaOrdemDeProducaoGridModel } from "../../models/insumo-da-ordem-de-producao";
import ContextoOperacoesInsumo from "../contexto-funcoes-insumo";
import GridInsumoPorNumeroDeSerie from "./insumo-por-numero-de-serie-grid";

export const ModalInsumoPorNumeroDeSerie = () => {
  const [insumoPorNumeroDeSerie, setInsumoPorNumeroDeSerie] = useState<
    InsumoDaOrdemDeProducaoGridModel | undefined
  >(undefined);

  const { funcoes, referenciasGridInsumos } = useContext(
    ContextoOperacoesInsumo
  );

  useEffect(() => {
    funcoes.definfirSetInsumoPorNumeroDeSerie((prop) => {
      setInsumoPorNumeroDeSerie(prop);
    });
  }, [funcoes]);

  const handleFecharModal = () => {
    referenciasGridInsumos.referenciaGridSelecaoEstoqueBaixaInsumo?.current
      ?.instance()
      .cancelEditData();
    funcoes.atualizaGridsAoFechar();
    setInsumoPorNumeroDeSerie(undefined);
  };

  return (
    <ModalMxp
      titulo={"Insumo por números de série"}
      visivel={insumoPorNumeroDeSerie !== undefined}
      altura={"90vh"}
      largura={"90vw"}
      handleFechar={handleFecharModal}
    >
      <FormMxp>
        <div>
          <span style={{ fontSize: "14px" }}>
            {"Insumo: "}
            <b>
              {FormataDescricao(
                FormatadoresSelectBox.CodigoDescricaoParenteses,
                insumoPorNumeroDeSerie?.codigo,
                insumoPorNumeroDeSerie?.descricao
              )}
            </b>
            {"      Operacao: "}
            <b>{insumoPorNumeroDeSerie?.operacao}</b>
          </span>
        </div>
        <GridInsumoPorNumeroDeSerie
          insumo={insumoPorNumeroDeSerie}
          style={{ height: "calc(100% - 30px)" }}
        ></GridInsumoPorNumeroDeSerie>
      </FormMxp>
      <ToolbarMxp dadosAuditoria={undefined}>
        <BotaoCancelarMxp handleClick={handleFecharModal} />
      </ToolbarMxp>
    </ModalMxp>
  );
};
