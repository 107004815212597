import DataGrid, { Column, DataGridRef } from "devextreme-react/data-grid";
import { useCallback, useMemo, useRef } from "react";
import { useRegistrarAtalhosGrid } from "../../../../../hooks/atalhos.hooks";
import { useSeletorGridMxp } from "../../../../../hooks/seletor.hooks";
import { GridMxpProps } from "../../../../../models/shared/ui/grid";
import GetColunasDeAuditoria from "../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import { EstadoPedidoDeVendaHelper } from "../../../../vendas/pedidos-de-venda/models/pedidos-de-venda.enum";
import { ItemPedidoDeVendaGridModel } from "../../models/item-pedido-de-venda.api";
import { EstadoItemPedidoDeVendaHelper } from "../../models/item-pedido-de-venda.enum";
import { ItemPedidoDeVendaServico } from "../../servicos/item-pedido-de-venda.service";

const nameOfGridHandler = criarNameof<ItemPedidoDeVendaGridModel>();

export default function GridItemPedidoDeVenda(
  props: GridMxpProps<ItemPedidoDeVendaGridModel>
) {
  const gridRef = useRef<DataGridRef>(null);

  const gridController = useMemo(
    () =>
      new GridController<ItemPedidoDeVendaGridModel>(() =>
        gridRef.current?.instance()
      ),
    [gridRef]
  );

  const dataSource = useMemo(
    () =>
      ItemPedidoDeVendaServico.ObterDataSourceParaGrid<ItemPedidoDeVendaGridModel>(
        props.filtrosNoServidor
      ),
    [props.filtrosNoServidor]
  );

  useSeletorGridMxp(() => gridRef.current, props.filtrosNoCliente);

  const handleAtualizarGrid = useCallback(() => {
    gridController.atualizar();
  }, [gridController]);

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar(
      "pedidos-de-venda",
      () => gridRef.current?.instance(),
      false,
      props.filtrosNoCliente
    )
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(NomesTelas.pedidosDeVenda)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirSelecao()
      .definirOrdenacao()
      .build();
  }, [gridRef, dataSource, props.filtrosNoCliente, handleAtualizarGrid]);

  useRegistrarAtalhosGrid<ItemPedidoDeVendaGridModel>({
    controller: gridController,
  });

  return (
    <>
      <DataGrid ref={gridRef} {...configuracoesGrid}>
        <Column
          key={nameOfGridHandler("dataEmissao")}
          dataField={nameOfGridHandler("dataEmissao")}
          {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
          caption="Emissão"
          width={150}
        />
        <Column
          key={nameOfGridHandler("numeroPedidoDeVenda")}
          dataField={nameOfGridHandler("numeroPedidoDeVenda")}
          {...obterConfiguracaoColuna("codigoNumerico")}
          caption="Nº"
          width={70}
          sortIndex={0}
          sortOrder="desc"
        />
        <Column
          key={nameOfGridHandler("numeroPedidoDeVendaCliente")}
          dataField={nameOfGridHandler("numeroPedidoDeVendaCliente")}
          {...obterConfiguracaoColuna("stringMG")}
          caption="Pedido do cliente"
          width={165}
          defaultVisible={false}
        />
        <Column
          key={nameOfGridHandler("numeroItemPedidoDeVenda")}
          dataField={nameOfGridHandler("numeroItemPedidoDeVenda")}
          {...obterConfiguracaoColuna("codigoNumerico")}
          caption="Sequência"
          width={130}
          sortIndex={1}
          sortOrder="desc"
        />
        <Column
          key={nameOfGridHandler("apelidoCliente")}
          dataField={nameOfGridHandler("apelidoCliente")}
          {...obterConfiguracaoColuna("stringGG")}
          caption="Cliente"
        />
        <Column
          key={nameOfGridHandler("cnpjCpfCliente")}
          dataField={nameOfGridHandler("cnpjCpfCliente")}
          {...obterConfiguracaoColuna("cpfCnpj")}
          caption="CPF/CNPJ do cliente"
          width={180}
        />
        <Column
          key={nameOfGridHandler("estadoPedidoDeVenda")}
          dataField={nameOfGridHandler("estadoPedidoDeVenda")}
          {...obterConfiguracaoColuna("stringM")}
          caption="Estado"
          lookup={EstadoPedidoDeVendaHelper.asLookup()}
        />
        <Column
          key={nameOfGridHandler("descricaoEstadoConfiguravelPedidoDeVenda")}
          dataField={nameOfGridHandler(
            "descricaoEstadoConfiguravelPedidoDeVenda"
          )}
          {...obterConfiguracaoColuna("stringM")}
          caption="Estado configurável"
          defaultVisible={false}
        />
        <Column
          key={nameOfGridHandler("codigoItem")}
          dataField={nameOfGridHandler("codigoItem")}
          {...obterConfiguracaoColuna("stringG")}
          caption="Item"
        />
        <Column
          key={nameOfGridHandler("descricaoItem")}
          dataField={nameOfGridHandler("descricaoItem")}
          {...obterConfiguracaoColuna("stringXG")}
          caption="Descrição"
        />
        <Column
          key={nameOfGridHandler("estadoItemPedidoDeVenda")}
          dataField={nameOfGridHandler("estadoItemPedidoDeVenda")}
          {...obterConfiguracaoColuna("stringM")}
          caption="Estado do item"
          lookup={EstadoItemPedidoDeVendaHelper.asLookup()}
        />
        <Column
          key={nameOfGridHandler("dataEntrega")}
          dataField={nameOfGridHandler("dataEntrega")}
          {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
          caption="Entrega"
          width={150}
        />
        <Column
          key={nameOfGridHandler("dataEntregaPrevista")}
          dataField={nameOfGridHandler("dataEntregaPrevista")}
          {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
          caption="Previsão de entrega"
          width={150}
        />
        <Column
          key={nameOfGridHandler("quantidade")}
          dataField={nameOfGridHandler("quantidade")}
          {...obterConfiguracaoColuna("decimalComMaximoDuasCasasDecimais")}
          caption="Qt"
          width={150}
        />
        <Column
          key={nameOfGridHandler("quantidadeNaUnidadeDoItem")}
          dataField={nameOfGridHandler("quantidadeNaUnidadeDoItem")}
          {...obterConfiguracaoColuna("decimalComMaximoDuasCasasDecimais")}
          caption="Qt unid principal"
          width={150}
          defaultVisible={false}
        />
        <Column
          key={nameOfGridHandler("descricaoUnidadeDeMedidaItemPedidoDeVenda")}
          dataField={nameOfGridHandler(
            "descricaoUnidadeDeMedidaItemPedidoDeVenda"
          )}
          {...obterConfiguracaoColuna("stringPP")}
          caption="Unid"
          width={70}
        />
        <Column
          key={nameOfGridHandler(
            "descricaoUnidadeDeMedidaItemItemPedidoDeVenda"
          )}
          dataField={nameOfGridHandler(
            "descricaoUnidadeDeMedidaItemItemPedidoDeVenda"
          )}
          {...obterConfiguracaoColuna("stringPP")}
          caption="Unid principal"
          width={70}
          defaultVisible={false}
        />
        <Column
          key={nameOfGridHandler("valorUnitario")}
          dataField={nameOfGridHandler("valorUnitario")}
          {...obterConfiguracaoColuna("monetario")}
          caption="Vl unitário"
          width={120}
        />
        <Column
          key={nameOfGridHandler("valorTotal")}
          dataField={nameOfGridHandler("valorTotal")}
          {...obterConfiguracaoColuna("monetario")}
          caption="Vl total"
          width={120}
        />
        <Column
          key={nameOfGridHandler("numeroDoItemPedidoDeVendaCliente")}
          dataField={nameOfGridHandler("numeroDoItemPedidoDeVendaCliente")}
          {...obterConfiguracaoColuna("stringM")}
          caption="Nº do item do pedido do cliente"
          defaultVisible={false}
        />
        <Column
          key={nameOfGridHandler("informacoesAdicionais")}
          dataField={nameOfGridHandler("informacoesAdicionais")}
          {...obterConfiguracaoColuna("stringXXG")}
          caption="Informações adicionais"
          width={500}
          defaultVisible={false}
        />
        {GetColunasDeAuditoria(false, true)}
      </DataGrid>
    </>
  );
}
