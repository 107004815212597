import { DataGrid } from "devextreme-react";
import {
  Column,
  DataGridRef,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import { SingleMultipleOrNone } from "devextreme/common";
import ArrayStore from "devextreme/data/array_store";
import {
  forwardRef,
  memo,
  RefObject,
  useCallback,
  useContext,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import ContextoModal from "../../../../../../components/layout/contexto-modal";
import { Seletor } from "../../../../../../components/selecao/seletor";
import useEffectOnLoad from "../../../../../../hooks/effect.hooks";
import DocumentoFiscalSelecaoMdfeGridModel, {
  novoRegistroDocumentoFiscalSelecaoMdfeGrid,
} from "../../../../../../models/api/nota-fiscal/nota-fiscal-selecao-mdfe";
import { PermissoesNotaFiscal } from "../../../../../../models/permissoes/vendas/nota-fiscal/permissoes-nota-fiscal";
import {
  IGridSelecao,
  ResultadoAcaoFormulario,
} from "../../../../../../models/shared/ui/formularios";
import GridDefaults from "../../../../../../parts/layout/grid-defaults";
import NomesTelas from "../../../../../../utils/common/nomes-telas";
import exibirNotificacaoToast, {
  JanelasDeNotificacaoTitulos,
  MensagensParaNotificacao,
  TipoNotificacao,
} from "../../../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../../utils/common/permissoes-utils";
import {
  criarItensMenuContextoPadrao,
  tratarDadosContextMenu,
} from "../../../../../../utils/context-menu/context-menu-utils";
import {
  exibirAlerta,
  exibirConfirmacao,
} from "../../../../../../utils/dialogos";
import { extraiTipoDocumentoChaveAcesso } from "../../../../../../utils/especifico/mdfe/mdfe-utils";
import { formatarNumero } from "../../../../../../utils/formatadores/formatador-de-numeros";
import {
  getGridDefaultProps,
  GridProps,
} from "../../../../../../utils/grid/grid-utils";
import obterConfiguracaoColuna from "../../../../../../utils/grid/padroes-colunas";
import {
  getGridSelecaoProps,
  gridSelecaoConstructor,
} from "../../../../../../utils/grid/selecao-utils";
import { renderToStringClient } from "../../../../../../utils/react/react-utils";
import MDFeEditFormContext from "../../../contexts/mdfe-editform.context";
import { SituacaoMDFe, tiposNFe } from "../../../models/mdfe-enums";
import EditFormDocumentoFiscalAvulso from "../../formulario/formularios-acessorios/edit-form-documento-fiscal-avulso";
import { SelecaoNotasMDFe } from "./selecao-notas-fiscais";

interface GridDocumentosFiscaisProps extends GridProps {
  idRegistro: number;
}

const gridId = "mdfe-lista-documentos";

export const GridDocumentosFiscais = memo(
  forwardRef((props: GridDocumentosFiscaisProps, ref) => {
    const {
      descarregamentos,
      adicionarDocumentoFiscal,
      removerDocumentoFiscal,
      baseMdfe,
    } = useContext(MDFeEditFormContext);

    const [
      modalDocumentoAvulsoEdicaoVisivel,
      setModalDocumentoAvulsoEdicaoVisivel,
    ] = useState(false);
    const [
      registroDocumentoAvulsoEmEdicao,
      setRegistroDocumentoAvulsoEmEdicao,
    ] = useState<DocumentoFiscalSelecaoMdfeGridModel | undefined>(undefined);
    const [seletorVisivel, setSeletorVisivel] = useState(false);
    const [modoSelecao, setModoSelecao] =
      useState<SingleMultipleOrNone>("none");
    const [acaoDoubleClick, setAcaoDoubleClick] = useState<() => void>();

    const documentos = useMemo(() => {
      const lista: DocumentoFiscalSelecaoMdfeGridModel[] = [];
      for (const descarregamento of descarregamentos) {
        for (const documento of descarregamento?.documentosVinculados ?? []) {
          const documentoFiscal: DocumentoFiscalSelecaoMdfeGridModel = {
            id: documento.id,
            chaveAcesso: documento.documentoFiscal?.chaveAcesso ?? "",
            dataEmissao: documento.documentoFiscal?.dataEmissao ?? "",
            idDestinatario: documento.documentoFiscal?.idDestinatario ?? 0,
            destinatario: documento.documentoFiscal?.destinatario ?? "",
            numero: documento.documentoFiscal?.numero ?? "",
            pesoBruto: documento.documentoFiscal?.pesoBruto ?? 0,
            pesoLiquido: documento.documentoFiscal?.pesoLiquido ?? 0,
            idMunicipioDestino:
              documento.documentoFiscal?.idMunicipioDestino ?? 0,
            municipioDestino: documento.documentoFiscal?.municipioDestino ?? "",
            serie: documento.documentoFiscal?.serie ?? "",
            idUfDestino: documento.documentoFiscal?.idUfDestino ?? 0,
            ufDestino: documento.documentoFiscal?.ufDestino ?? "",
            valor: documento.documentoFiscal?.valor ?? 0,
            tipoDocumentoFormatado: tiposNFe.includes(
              extraiTipoDocumentoChaveAcesso(
                documento.documentoFiscal?.chaveAcesso
              )
            )
              ? "NF-e"
              : "CT-e",
            idNotaFiscal: documento.idNotaFiscal ?? 0,
          };

          lista.push(documentoFiscal);
        }
      }

      return lista;
    }, [descarregamentos]);

    const dataSourceNotas = useMemo(
      () => new ArrayStore({ data: documentos, key: "id" }),
      [documentos]
    );

    useEffectOnLoad(() => {
      //Evita que o modal pisque no primeiro render
      setRegistroDocumentoAvulsoEmEdicao(
        novoRegistroDocumentoFiscalSelecaoMdfeGrid
      );
    });

    let gridRef: DataGridRef<any, any>;

    function getGridRef() {
      return gridRef;
    }

    function setGridRef(ref: DataGridRef<any, any>) {
      gridRef = ref;
    }

    useImperativeHandle(ref, () =>
      gridSelecaoConstructor(
        gridRef,
        () => modoSelecao,
        setModoSelecao,
        setAcaoDoubleClick
      )
    );

    const atualizarGrid = useCallback(() => {
      gridRef.instance().refresh();
    }, []);

    const novoRegistro = useCallback(() => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesNotaFiscal.Consultar,
        ])
      ) {
        return;
      }

      setSeletorVisivel(true);
    }, [setSeletorVisivel]);

    const novoRegistroDocumentoFiscalAvulso = useCallback(() => {
      setRegistroDocumentoAvulsoEmEdicao(
        novoRegistroDocumentoFiscalSelecaoMdfeGrid
      );
      setModalDocumentoAvulsoEdicaoVisivel(true);
    }, [
      setRegistroDocumentoAvulsoEmEdicao,
      setModalDocumentoAvulsoEdicaoVisivel,
    ]);

    const editarRegistro = useCallback(
      (documentoFiscal: DocumentoFiscalSelecaoMdfeGridModel | undefined) => {
        if (documentoFiscal) {
          if (documentoFiscal.idNotaFiscal > 0) {
            exibirAlerta(
              JanelasDeNotificacaoTitulos.Atencao,
              "Para editar documentos emitidos pelo sistema acesse Vendas > Notas fiscais."
            );
            return;
          }

          setRegistroDocumentoAvulsoEmEdicao(documentoFiscal);
          setModalDocumentoAvulsoEdicaoVisivel(true);
        }
      },
      [setRegistroDocumentoAvulsoEmEdicao, setModalDocumentoAvulsoEdicaoVisivel]
    );

    const handleDoubleClickGrid = useCallback(
      (gridData: any) => {
        editarRegistro(gridData.data);
      },
      [editarRegistro]
    );

    const handleModalCallBack = useCallback(
      (info: ResultadoAcaoFormulario) => {
        setRegistroDocumentoAvulsoEmEdicao(undefined);
        setModalDocumentoAvulsoEdicaoVisivel(false);
        if (info == ResultadoAcaoFormulario.AcaoConcluida) {
          atualizarGrid();
        }
      },
      [
        setRegistroDocumentoAvulsoEmEdicao,
        setModalDocumentoAvulsoEdicaoVisivel,
        atualizarGrid,
      ]
    );

    const adicionarSelecaoNotas = useCallback(
      (dados: DocumentoFiscalSelecaoMdfeGridModel[]) => {
        let alterado = false;

        const notasAdd: DocumentoFiscalSelecaoMdfeGridModel[] = [];

        for (const nota of dados) {
          if (documentos.some((n) => n.id == nota.id)) {
            const mensagem = `A nota fiscal Nº ${nota.numero} - Série ${nota.serie} já foi adicionada.`;
            exibirNotificacaoToast({
              mensagem,
              tipo: TipoNotificacao.Advertencia,
            });
          } else {
            notasAdd.push(nota);
            exibirNotificacaoToast({
              mensagem: `Documento Nº ${nota.numero} adicionado com sucesso. Clique em "Salvar" para confirmar a operação.`,
              tipo: TipoNotificacao.Advertencia,
            });
            alterado = alterado || true;
          }

          if (alterado) {
            adicionarDocumentoFiscal(notasAdd);
          }
        }
      },
      [adicionarDocumentoFiscal, documentos]
    );

    const excluirRegistro = useCallback(
      async (nota: DocumentoFiscalSelecaoMdfeGridModel | undefined) => {
        if (nota) {
          const mensagem = renderToStringClient(
            <>
              Tem certeza de que deseja excluir a nota fiscal{" "}
              <strong>
                nº {nota.numero} série {nota.serie}
              </strong>
              ?
            </>
          );

          const excluir = await exibirConfirmacao(
            "Confirmar exclusão",
            mensagem
          );

          if (excluir) {
            removerDocumentoFiscal(nota);

            exibirNotificacaoToast({
              mensagem: MensagensParaNotificacao.ExcluidoComSucesso,
              tipo: TipoNotificacao.Sucesso,
            });
          }
        }
      },
      [removerDocumentoFiscal]
    );

    const gerarContextMenu = useCallback(
      (data: any) => {
        const [getData] =
          tratarDadosContextMenu<DocumentoFiscalSelecaoMdfeGridModel>(
            data,
            gridRef,
            getGridRef
          );

        return !props.somenteLeitura
          ? criarItensMenuContextoPadrao({
              editar: () => editarRegistro(getData()),
              excluir: () => excluirRegistro(getData()),
            })
          : [];
      },
      [editarRegistro, excluirRegistro, getGridRef, props.somenteLeitura]
    );

    const contextModal = useContext(ContextoModal);

    const callBackSeletor = useCallback(
      (ok: boolean, dados: any[]) => {
        setSeletorVisivel(false);

        if (ok) {
          adicionarSelecaoNotas(dados as DocumentoFiscalSelecaoMdfeGridModel[]);
        }
      },
      [adicionarSelecaoNotas, setSeletorVisivel]
    );

    const callBackFecharSeletor = useCallback(
      () => setSeletorVisivel(false),
      [setSeletorVisivel]
    );

    const renderGridSelecao = useCallback(
      (r: RefObject<IGridSelecao>) => <SelecaoNotasMDFe ref={r} />,
      []
    );

    return (
      <>
        <DataGrid
          ref={setGridRef}
          dataSource={dataSourceNotas}
          {...getGridDefaultProps({
            executarOperacoesNoServidor: false,
            nomeDoArquivoAoExportar: NomesTelas.notasFiscais,
            getGridRef: getGridRef,
            gerarOpcoesDoRegistro: gerarContextMenu,
            isModal: props.isModal,
            style: props.style,
          })}
          {...getGridSelecaoProps(
            () => modoSelecao,
            () => acaoDoubleClick,
            handleDoubleClickGrid
          )}
        >
          {GridDefaults({
            gridId,
            novoRegistro: props.somenteLeitura ? undefined : novoRegistro,
            exportavel: false,
            filtravel: false,
            paginavel: false,
            selecionavel: false,
            exibirMenuDeContexto: baseMdfe.situacao == SituacaoMDFe.EmEdicao,
            gerarContextMenu,
            getGridRef,
            isGridInterno: true,
            contextoModal: contextModal,
            dropDownItems: props.somenteLeitura
              ? undefined
              : [
                  {
                    text: "Adicionar NF-e emitida pelo Maxiprod",
                    onClick: novoRegistro,
                  },
                  {
                    text: "Adicionar documento avulso",
                    onClick: novoRegistroDocumentoFiscalAvulso,
                  },
                ],
          })}
          <Column
            dataField="tipoDocumentoFormatado"
            caption="Tipo do documento"
            allowResizing
            width={100}
          />
          <Column
            dataField="numero"
            caption="Número"
            dataType="number"
            allowResizing
            width={80}
          />
          <Column dataField="serie" caption="Série" allowResizing width={80} />
          <Column
            dataField="dataEmissao"
            caption="Emissão"
            allowResizing
            {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
          />
          <Column
            dataField="chaveAcesso"
            caption="Chave de acesso"
            allowResizing
            width={350}
          />
          <Column
            dataField="destinatario"
            caption="Destinatário"
            allowResizing
          />
          <Column
            dataField="municipioDestino"
            caption="Município de destino"
            allowResizing
            width={140}
          />
          <Column
            dataField="ufDestino"
            caption="UF de destino"
            allowResizing
            width={80}
          />
          <Column
            dataField="valor"
            caption="Valor"
            dataType="number"
            allowResizing
            width={100}
            cellRender={({ data }) => {
              const dados = data as DocumentoFiscalSelecaoMdfeGridModel;
              return formatarNumero(dados.valor, 2);
            }}
          />
          <Column
            dataField="pesoLiquido"
            caption="Peso líquido"
            dataType="number"
            allowResizing
            width={80}
            cellRender={({ data }) => {
              const dados = data as DocumentoFiscalSelecaoMdfeGridModel;
              return formatarNumero(dados.pesoLiquido, 3);
            }}
          />
          <Column
            dataField="pesoBruto"
            caption="Peso bruto"
            dataType="number"
            allowResizing
            width={80}
            cellRender={({ data }) => {
              const dados = data as DocumentoFiscalSelecaoMdfeGridModel;
              return formatarNumero(dados.pesoBruto, 3);
            }}
          />
          <Summary>
            <TotalItem
              column="valor"
              summaryType="sum"
              customizeText={(data: any) => formatarNumero(data.value, 2)}
            />
            <TotalItem
              column="pesoLiquido"
              summaryType="sum"
              customizeText={(data: any) => formatarNumero(data.value, 3)}
            />
            <TotalItem
              column="pesoBruto"
              summaryType="sum"
              customizeText={(data: any) => formatarNumero(data.value, 3)}
            />
          </Summary>
        </DataGrid>

        {/* Modal de seleção de NFs */}
        <div>
          <Seletor
            titulo="Selecionar notas fiscais"
            modo="selecaoMultipla"
            visivel={seletorVisivel}
            larguraMaxima="80%"
            callBack={callBackSeletor}
            onClose={callBackFecharSeletor}
            componente={renderGridSelecao}
          />
        </div>
        <div>
          <EditFormDocumentoFiscalAvulso
            visivel={modalDocumentoAvulsoEdicaoVisivel}
            idRegistroEmEdicao={
              Number(registroDocumentoAvulsoEmEdicao?.chaveAcesso) ?? 0
            }
            handleCallback={handleModalCallBack}
            documentoFiscal={registroDocumentoAvulsoEmEdicao}
          />
        </div>
      </>
    );
  })
);

export default GridDocumentosFiscais;
