import { yupResolver } from "@hookform/resolvers/yup";
import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import {
  FormSelectBox,
  FormTextBox,
} from "../../../../../../components/formularios";
import { Coluna, Linha } from "../../../../../../components/layout/grid-system";
import TabContainer from "../../../../../../components/layout/tab-container";
import Sessao from "../../../../../../components/organizacao/sessao";
import { IFormulario } from "../../../../../../models/shared/ui/formularios";
import SelectItem from "../../../../../../models/shared/ui/select-item";
import { MDFeAbaPercursoViewModel } from "../../../../../../models/viewmodels/vendas/mdfe/mdfe-edit-form-view-model";
import { ComponentAsyncLoader } from "../../../../../../parts/utils/load-on-demand";
import APIUF from "../../../../../../services/uf/uf.service";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../../utils/api/api-utils";
import { checarSeFormFoiModificado } from "../../../../../../utils/common/form-utils";
import { GridProps } from "../../../../../../utils/grid/grid-utils";
import yup from "../../../../../../utils/validacao/validacao";
import { MensagensPadraoYup } from "../../../../../comum/utils/yup/mensagens";
import MDFeEditFormContext from "../../../contexts/mdfe-editform.context";
import {
  ModalidadeTransporteMDFe,
  TipoEmitenteMDFe,
} from "../../../models/mdfe-enums";
import GridCarregamentos from "./grid-carregamentos";
import GridPercursos from "./grid-percursos";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DadosGeraisProps extends GridProps {
  idRegistroEmEdicao: number;
}

// Cria um componente referenciável
export const MDFeAbaPercurso = forwardRef((props: DadosGeraisProps, ref) => {
  const {
    baseMdfe,
    descarregamentos,
    percursos,
    dadosPercurso,
    dadosGerais,
    definirDadosPercurso,
    definirDadosUfs,
  } = useContext(MDFeEditFormContext);

  const model: MDFeAbaPercursoViewModel = useMemo(() => {
    return {
      id: baseMdfe.id,
      idUfFim: dadosPercurso.idUfFim,
      idUfInicio: dadosPercurso.idUfInicio,
      cepLocalCarregamento: dadosPercurso.cepLocalCarregamento,
      cepLocalDescarregamento: dadosPercurso.cepLocalDescarregamento,
    };
  }, [baseMdfe, dadosPercurso]);

  const [ufs, setUfs] = useState<SelectItem[]>([]);

  const cepLocalRequerido = useMemo(() => {
    return (
      dadosGerais.modalidadeTransporte ===
        ModalidadeTransporteMDFe.Rodoviario &&
      (dadosGerais.tipoEmitente === TipoEmitenteMDFe.PrestadorServico ||
        (dadosGerais.tipoEmitente ===
          TipoEmitenteMDFe.TransportadorCargaPropria &&
          dadosGerais.tipoTransportador != null)) &&
      descarregamentos.filter((x) => (x.documentosVinculados?.length ?? 0) > 0)
        .length === 1
    );
  }, [dadosGerais, descarregamentos]);

  const schema = useMemo(
    () =>
      yup.object().shape({
        id: yup.number().required().moreThan(-1).integer(),
        idUfInicio: yup
          .number()
          .required(MensagensPadraoYup.campoObrigatorio)
          .moreThan(0, MensagensPadraoYup.campoObrigatorio)
          .integer()
          .test({
            message: "O valor não pode estar presente nas UFs do percurso.",
            test: (v) => {
              return !percursos.some((x) => x.idUf == v);
            },
          }),
        idUfFim: yup
          .number()
          .required(MensagensPadraoYup.campoObrigatorio)
          .moreThan(0, MensagensPadraoYup.campoObrigatorio)
          .integer()
          .test({
            message: "O valor não pode estar presente nas UFs do percurso.",
            test: (v) => {
              return !percursos.some((x) => x.idUf == v);
            },
          }),
        cepLocalCarregamento: yup
          .string()
          .nullable()
          .test({
            message: MensagensPadraoYup.campoObrigatorio,
            test: (v) => {
              return !(!v && cepLocalRequerido);
            },
          }),
        cepLocalDescarregamento: yup
          .string()
          .nullable()
          .test({
            message: MensagensPadraoYup.campoObrigatorio,
            test: (v) => {
              return !(!v && cepLocalRequerido);
            },
          }),
      }),
    [percursos, cepLocalRequerido]
  );
  const carregarUfs = useCallback(async () => {
    try {
      const resposta = await APIUF.obterListagemSimples();
      checarResponse(resposta);
      setUfs(
        resposta.model.map((x) => ({
          valor: x.valor,
          descricao: x.descricao,
        }))
      );
    } catch (erro) {
      tratarErroApi(erro);
    }
  }, [setUfs]);

  const { reset, control, formState, handleSubmit, watch } =
    useForm<MDFeAbaPercursoViewModel>({
      mode: "onChange",
      reValidateMode: "onChange",
      resolver: yupResolver(schema),
    });

  const carregarTela = useCallback(async () => {
    reset(model);
    await carregarUfs();
  }, [reset, carregarUfs, model]);

  useEffect(() => {
    if (!Number.isNaN(baseMdfe.id)) {
      carregarTela();
    }
  }, [baseMdfe.id]);

  const form = useRef<HTMLFormElement | null>(null);

  const handleSubmitInterno = useCallback(
    (data: MDFeAbaPercursoViewModel) => {
      definirDadosPercurso(data);
    },
    [definirDadosPercurso]
  );

  // Repassar referências para componente pai
  useImperativeHandle(
    ref,
    (): IFormulario => ({
      requestSubmit() {
        form.current?.requestSubmit();
      },
      valido() {
        form.current?.requestSubmit();
        return Object.keys(formState.errors).length == 0;
      },
      isDirty() {
        return checarSeFormFoiModificado(formState);
      },
    }),
    [formState, form]
  );

  watch((dados) => {
    if (
      dados.idUfFim != dadosPercurso.idUfFim ||
      dados.idUfInicio != dadosPercurso.idUfInicio
    ) {
      if (props.idRegistroEmEdicao === baseMdfe.id) {
        definirDadosUfs(dados.idUfInicio ?? 0, dados.idUfFim ?? 0);
      }
    }
  });

  return (
    <TabContainer>
      <form ref={form} onSubmit={handleSubmit(handleSubmitInterno)}>
        <Sessao>
          <Linha>
            <Coluna md={6}>
              <FormSelectBox
                name="idUfInicio"
                titulo="UF de origem"
                toolTip="UF de origem"
                control={control}
                exibirBotaoLimpar
                dataSource={ufs}
                somenteLeitura={props.somenteLeitura}
                habilitaBusca
                tipoBusca="contains"
              />
            </Coluna>
            <Coluna md={6}>
              <FormSelectBox
                name="idUfFim"
                titulo="UF de destino"
                toolTip="UF de destino"
                control={control}
                exibirBotaoLimpar
                dataSource={ufs}
                somenteLeitura={props.somenteLeitura}
                habilitaBusca
                tipoBusca="contains"
              />
            </Coluna>
          </Linha>
        </Sessao>
        <ComponentAsyncLoader>
          <Linha>
            <Coluna md={6}>
              {/* Grid Carregamentos */}
              <GridCarregamentos
                somenteLeitura={props.somenteLeitura}
                style={{
                  minHeight: "18em",
                  maxHeight: "calc(100vh - 45em)",
                }}
              />
            </Coluna>
            <Coluna md={6}>
              {/* Grid Percurso */}
              <GridPercursos
                somenteLeitura={props.somenteLeitura}
                style={{
                  minHeight: "18em",
                  maxHeight: "calc(100vh - 45em)",
                }}
              />
            </Coluna>
          </Linha>
        </ComponentAsyncLoader>
        <Sessao>
          <Linha>
            <Coluna md={3}>
              <FormTextBox
                name="cepLocalCarregamento"
                titulo="CEP do local de carregamento"
                control={control}
                requerido={cepLocalRequerido}
                tamanhoMaximo={8}
                mask="00.000-000"
              />
            </Coluna>
            <Coluna md={3}>
              <FormTextBox
                name="cepLocalDescarregamento"
                titulo={"CEP do local de descarregamento"}
                control={control}
                requerido={cepLocalRequerido}
                tamanhoMaximo={8}
                mask="00.000-000"
              />
            </Coluna>
          </Linha>
        </Sessao>
      </form>
    </TabContainer>
  );
});

export default MDFeAbaPercurso;
