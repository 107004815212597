import DataGrid, { Column, DataGridRef } from "devextreme-react/data-grid";
import { useCallback, useMemo, useRef, useState } from "react";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import FormIncluirReservaOrdemProducao from "../../../../../features/estoque/reserva/componentes/formulario/form-incluir-reserva-ordem-producao";
import { useRegistrarAtalhosGrid } from "../../../../../hooks/atalhos.hooks";
import { ReservaDaOrdemDeProducaoGridModel } from "../../../../../models/api/reserva/reserva-da-ordem-de-producao-grid-model";
import { GridMxpProps } from "../../../../../models/shared/ui/grid";
import GetColunasDeAuditoria from "../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import criarNameof from "../../../../../utils/common/cria-name-of";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import { podeReservarOp } from "../../../../estoque/reserva/utils/reserva.helpers";
import { OrdemDeProducaoServico } from "../../servicos/ordem-de-producao.service";

const nameOfGridHandler = criarNameof<ReservaDaOrdemDeProducaoGridModel>();

interface GridEmbutidaReservasDaOrdemDeProducaoProps
  extends GridMxpProps<ReservaDaOrdemDeProducaoGridModel> {
  ordemDeProducaoId: number;
  ordemDeProducaoCodigo: number | undefined;
  ordemDeProducaoEhSugerida: boolean;
  ordemDeProducaoIdItem: number;
}

export default function GridEmbutidaReservasDaOrdemDeProducao({
  filtrosNoCliente,
  filtrosNoServidor,
  style,
  ordemDeProducaoId,
  ordemDeProducaoCodigo,
  ordemDeProducaoEhSugerida,
  ordemDeProducaoIdItem,
}: GridEmbutidaReservasDaOrdemDeProducaoProps) {
  const gridRef = useRef<DataGridRef>(null);

  const [modalNovaReservaVisivel, setModalNovaReservaVisivel] = useState(false);

  const dataSource = OrdemDeProducaoServico.ObterDataSourceParaGridReservas(
    ordemDeProducaoId,
    filtrosNoServidor
  );

  const gridController = useMemo(
    () =>
      new GridController<ReservaDaOrdemDeProducaoGridModel>(() =>
        gridRef.current?.instance()
      ),
    []
  );

  const handleAtualizarGrid = useCallback(() => {
    gridController.atualizar();
  }, [gridController]);

  function handleModalReservaCallback() {
    handleAtualizarGrid();
    setModalNovaReservaVisivel(false);
  }

  const handleNovo = useCallback(async () => {
    if (await podeReservarOp(ordemDeProducaoId)) {
      setModalNovaReservaVisivel(true);
    }
  }, [ordemDeProducaoId]);

  useRegistrarAtalhosGrid({
    controller: gridController,
    handleNovo,
    // Adicionar handlers de manipulação se/quando forem criados.
  });

  const configuracoesGrid = useMemo(() => {
    const isGridInterna = false;
    return GridBuilder.criar(
      "reservas-op",
      () => gridRef?.current?.instance(),
      isGridInterna,
      filtrosNoCliente
    )
      .definirStyles(style)
      .definirDataSource(dataSource)
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirFiltros()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao("Reservas da ordem de produção")
      .definirBotaoNovo(handleNovo)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirSelecao("none")
      .definirOrdenacao()
      .build();
  }, [filtrosNoCliente, style, dataSource, handleNovo, handleAtualizarGrid]);

  return (
    <>
      <DataGrid ref={gridRef} {...configuracoesGrid}>
        <Column
          key={nameOfGridHandler("numeroPedidoEItemFormatado")}
          dataField={nameOfGridHandler("numeroPedidoEItemFormatado")}
          {...obterConfiguracaoColuna("stringG")}
          caption="Pedido / Item"
          width={220}
          allowFiltering={false}
        />
        <Column
          key={nameOfGridHandler("apelidoCliente")}
          dataField={nameOfGridHandler("apelidoCliente")}
          {...obterConfiguracaoColuna("stringGG")}
          caption="Cliente"
        />
        <Column
          key={nameOfGridHandler("dataEntregaItemPedidoVenda")}
          dataField={nameOfGridHandler("dataEntregaItemPedidoVenda")}
          {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
          caption="Data de entrega"
          width={150}
        />
        <Column
          key={nameOfGridHandler("quantidade")}
          dataField={nameOfGridHandler("quantidade")}
          {...obterConfiguracaoColuna("quantidade")}
          caption="Quantidade"
        />
        <Column
          key={nameOfGridHandler("codigoItem")}
          dataField={nameOfGridHandler("codigoItem")}
          {...obterConfiguracaoColuna("stringG")}
          caption="Item"
        />
        <Column
          key={nameOfGridHandler("descricaoItem")}
          dataField={nameOfGridHandler("descricaoItem")}
          {...obterConfiguracaoColuna("stringXG")}
          caption="Descrição do item"
        />
        {GetColunasDeAuditoria(false, false, [
          { ordenarPor: "criacaoData", ordem: "desc" },
        ])}
      </DataGrid>

      <ModalMxp
        titulo={"Nova reserva"}
        visivel={modalNovaReservaVisivel}
        handleFechar={handleModalReservaCallback}
      >
        <FormIncluirReservaOrdemProducao
          idOrdemDeProducao={ordemDeProducaoId}
          codigoOrdemDeProducao={ordemDeProducaoCodigo}
          ordemDeProducaoEhSugerida={ordemDeProducaoEhSugerida}
          idItem={ordemDeProducaoIdItem}
          handleCallback={handleModalReservaCallback}
        />
      </ModalMxp>
    </>
  );
}
