import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";

class VeiculoService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.Veiculo);
  }
}

export const VeiculoServico = new VeiculoService();
