import DataGrid, { Column, DataGridRef } from "devextreme-react/data-grid";
import { useCallback, useEffect, useMemo, useRef } from "react";
import ProvedorAjuda from "../../../../../../components/ajuda/provedor-ajuda";
import GridColunaAcoes from "../../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import { ModalMxp } from "../../../../../../components/layout/modal-mxp";
import { useRegistrarAtalhosGrid } from "../../../../../../hooks/atalhos.hooks";
import { useControlarFormDeEdicao } from "../../../../../../hooks/form.hooks";
import { useMenuDeContextosGrid } from "../../../../../../hooks/menus.hooks";
import { useParametroId } from "../../../../../../hooks/route.hooks";
import { PermissoesNaturezaDoRendimento } from "../../../../../../models/permissoes/fiscal/efd-reinf/permissoes-natureza-do-rendimento";
import { ResultadoAcaoFormulario } from "../../../../../../models/shared/ui/formularios";
import GetColunasDeAuditoria from "../../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import { checarResponseExibeMensagemExclusaoDeSucesso } from "../../../../../../utils/api/api-utils";
import criarNameof from "../../../../../../utils/common/cria-name-of";
import NomesModais from "../../../../../../utils/common/nomes-modais";
import NomesTelas from "../../../../../../utils/common/nomes-telas";
import NormalizaTituloModal from "../../../../../../utils/common/normaliza-titulo";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../../utils/common/permissoes-utils";
import { exibirConfirmacao } from "../../../../../../utils/dialogos";
import GridBuilder from "../../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../../utils/grid/padroes-colunas";
import UrlUtils from "../../../../../../utils/url/url-utils";
import { NaturezaDoRendimentoGridModel } from "../../../models/natureza-do-rendimento/natureza-do-rendimento";
import { NaturezaDoRendimentoServico } from "../../../servicos/natureza-do-rendimento/natureza-do-rendimento.service";
import EditFormNaturezaDoRendimento from "../../formulario/natureza-do-rendimento";

const dataSource =
  NaturezaDoRendimentoServico.ObterDataSourceParaGrid<NaturezaDoRendimentoGridModel>();

const nameOfGridHandler = criarNameof<NaturezaDoRendimentoGridModel>();

export default function GridNaturezaDoRendimento() {
  const gridRef = useRef<DataGridRef>(null);
  const parametroId = useParametroId();

  const {
    idRegistroEmEdicao,
    setIdRegistroEmEdicao,
    modalVisivel,
    encerrarEdicao,
  } = useControlarFormDeEdicao(NaN);

  const handleNovoRegistro = useCallback(() => {
    if (
      !verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesNaturezaDoRendimento.InserirEditar,
      ])
    ) {
      return;
    }

    setIdRegistroEmEdicao(0);
  }, []);

  const handleEditarRegistro = useCallback(
    (registro: NaturezaDoRendimentoGridModel) => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesNaturezaDoRendimento.InserirEditar,
        ])
      ) {
        return;
      }

      setIdRegistroEmEdicao(registro.id);
    },
    []
  );

  const handleExcluirRegistro = useCallback(
    async (registro: NaturezaDoRendimentoGridModel) => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesNaturezaDoRendimento.Excluir,
        ])
      ) {
        return;
      }

      const excluir = await exibirConfirmacao(
        "Confirmar exclusão",
        obterMensagemExclusao(registro)
      );

      if (excluir) {
        const resposta = await NaturezaDoRendimentoServico.Excluir(registro.id);

        if (resposta) {
          checarResponseExibeMensagemExclusaoDeSucesso(resposta);
          handleAtualizarGrid();
        }
      }
    },
    []
  );

  function obterMensagemExclusao(registro: NaturezaDoRendimentoGridModel) {
    return `Tem certeza que deseja excluir o registro ${registro.descricao}?`;
  }

  const gridController = useMemo(
    () =>
      new GridController<NaturezaDoRendimentoGridModel>(() =>
        gridRef.current?.instance()
      ),
    []
  );

  const menuContexto = useMenuDeContextosGrid([], true, gridController);

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar("natureza-do-rendimento", () =>
      gridRef.current?.instance()
    )
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(NomesTelas.naturezaDoRendimento)
      .definirBotaoNovo(handleNovoRegistro)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirMenuDeContexto(menuContexto)
      .definirSelecao()
      .definirOrdenacao()
      .definirDuploCliqueLinha(handleEditarRegistro)
      .build();
  }, [menuContexto]);

  useEffect(() => {
    if (parametroId || parametroId === 0) {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesNaturezaDoRendimento.InserirEditar,
        ])
      ) {
        return;
      }

      setIdRegistroEmEdicao(parametroId);
    }
  }, []);

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  useRegistrarAtalhosGrid<NaturezaDoRendimentoGridModel>({
    controller: gridController,
    handleNovo: handleNovoRegistro,
    handleEditar: handleEditarRegistro,
    handleExcluir: handleExcluirRegistro,
  });

  const handleCallbackFormulario = useCallback(
    (resultado: ResultadoAcaoFormulario) => {
      encerrarEdicao();

      if (resultado == ResultadoAcaoFormulario.AcaoConcluida) {
        handleAtualizarGrid();
      }

      UrlUtils.RemoverParametroId(parametroId);
    },
    [parametroId]
  );

  const handleFecharModal = useCallback(() => {
    encerrarEdicao();
    UrlUtils.RemoverParametroId(parametroId);
  }, [parametroId]);

  return (
    <>
      <ProvedorAjuda id={"tooltips-grid-natureza-do-rendimento"}>
        <DataGrid ref={gridRef} {...configuracoesGrid}>
          {GridColunaAcoes<NaturezaDoRendimentoGridModel>({
            handleEditar: handleEditarRegistro,
            handleExcluir: handleExcluirRegistro,
          })}
          <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
          <Column
            key={nameOfGridHandler("codigo")}
            dataField={nameOfGridHandler("codigo")}
            {...obterConfiguracaoColuna("stringP")}
            sortIndex={0}
            sortOrder="asc"
            caption="Código"
          />
          <Column
            key={nameOfGridHandler("descricao")}
            dataField={nameOfGridHandler("descricao")}
            {...obterConfiguracaoColuna("stringG")}
            caption="Descrição"
          />
          {GetColunasDeAuditoria()}
        </DataGrid>
      </ProvedorAjuda>

      <ModalMxp
        titulo={NormalizaTituloModal.Normalizar(
          idRegistroEmEdicao,
          NomesModais.naturezaDoRendimento,
          true
        )}
        idRegistro={idRegistroEmEdicao}
        visivel={modalVisivel}
        handleFechar={handleFecharModal}
        largura={"max(30vw, 600px)"}
        altura={"auto"}
      >
        <EditFormNaturezaDoRendimento
          idRegistroEmEdicao={idRegistroEmEdicao}
          handleCallback={handleCallbackFormulario}
        />
      </ModalMxp>
    </>
  );
}
