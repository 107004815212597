import { Menu } from "devextreme-react";
import { ToolbarItem } from "devextreme-react/autocomplete";
import { useContext, useMemo } from "react";
import { convertItemContextMenu } from "../../../utils/grid/grid-builder";
import ContextoMenuEdicaoOrdemDeProducao from "../contexto-menu-op";

const MenuEdicaoOrdemDeProducaoComContexto = () => {
  const { menu } = useContext(ContextoMenuEdicaoOrdemDeProducao);
  const itens = useMemo(() => {
    return menu.itens.map((x) => convertItemContextMenu(x));
  }, [menu.itens]);

  return (
    <Menu
      className="dx-menu"
      items={itens}
      displayExpr="name"
      showFirstSubmenuMode="onHover"
      showSubmenuMode="onHover"
      orientation="horizontal"
      hideSubmenuOnMouseLeave={false}
    ></Menu>
  );
};

export const MenuEdicaoOrdemDeProducao = () => {
  return [
    <ToolbarItem
      key={"menu-edicao-ordem-de-producao-separador"}
      toolbar="top"
      location="before"
      visible={true}
    >
      |
    </ToolbarItem>,
    <ToolbarItem
      key={"menu-edicao-ordem-de-producao-menu"}
      cssClass="dx-menu"
      toolbar="top"
      location="before"
      visible={true}
    >
      <MenuEdicaoOrdemDeProducaoComContexto />
    </ToolbarItem>,
  ];
};

export const NomesMenuEdicaoOrdemDeProducao = {
  insumos: {
    name: "Insumos",
    baixarSelecionados: "Baixar insumos",
    estornarBaixas: "Estornar baixas",
    desfazerConclusaoSemEstornarBaixas:
      "Desfazer conclusão, sem estornar as baixas",
    consultar: {
      estoque: "Estoque",
      roteiroDeProducao: "Roteiro de produção",
      movimentacoesDeBaixa: "Movimentações de baixa",
      item: "Item",
    },
  },
  acoes: {
    name: "Ações",
    atualizarRoteiro: "Atualizar roteiro",
    criarBoletimDeInspecao:
      "Criar boletim de inspeção do lote gerado pela ordem de produção",
    apontarDuracaoDaSessao: "Apontar duração da sessão",
    retirarMaterialDaOrdemDeProducao: "Retirar material",
    retirarAparaDaOrdemDeProducao: "Retirar apara",
    excluir: "Excluir",
    importarBaixasDeInsumosDeArquivoExcel:
      "Importar baixas de insumos de arquivo Excel",
    importarOrdensDeProducaoDeArquivoExcel:
      "Importar ordens de produção de arquivo Excel",
    reservar: "Reservar",
    concluir: "Concluir",
    desfazerConclusao: "Desfazer conclusão",
    cancelar: "Cancelar",
    suspender: "Suspender",
    confirmar: "Confirmar",
    reservarOrdensDeProducaoComProjecoesDeUso:
      "Reservar ordens de produção com projeções de uso destinadas a item de pedido de venda",
    criarBoletimDeInspecaoComOperacoes:
      "Criar boletim de inspeção com operações",
  },
  consultar: {
    name: "Consultar",
    operacoesRoteiroGantt: "Operações: roteiro/gantt",
    apontamentoDeOperacoes: "Apontamento de operações",
    item: "Item",
    fichaFutura: "Ficha futura",
    estoqueEmElaboracao: "Estoque em elaboração",
    fichaFuturaDoItem: "Ficha futura do item",
    movimentacoes: "Movimentações",
    roteiroDoItem: "Roteiro do item",
    reservas: "Reservas",
  },
};
