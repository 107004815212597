import getApi from "../../../../configs/api";
import {
  ResponseBase,
  ResponseModel,
} from "../../../../models/api/comum/response-base";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import {
  PreferenciaGradeResponse,
  PreferenciasGradeRequest,
} from "../models/preferencias-grade.api";

class PreferenciasGradeService {
  private _nomeEndpoint: NomesEndpoints;

  constructor() {
    this._nomeEndpoint = NomesEndpoints.PreferenciasGrade;
  }

  public async Salvar(model: PreferenciasGradeRequest) {
    const api = getApi();
    const response = await api.post<ResponseBase>(this._nomeEndpoint, model);

    return response.data;
  }

  public async ObterPreferencias(nomeGrade: string) {
    const api = getApi(undefined, false);
    const response = await api.get<ResponseModel<PreferenciaGradeResponse>>(
      `${this._nomeEndpoint}?nomeGrade=${nomeGrade}`
    );

    return response.data;
  }

  public async RemoverPreferencias(nomeGrade: string) {
    const api = getApi();
    const response = await api.delete<ResponseBase>(
      `${this._nomeEndpoint}?nomeGrade=${nomeGrade}`
    );

    return response.data;
  }
}

export const PreferenciasGradeServico = new PreferenciasGradeService();
