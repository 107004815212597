import DataGrid, { Column, DataGridRef } from "devextreme-react/data-grid";
import { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { GridMxpProps } from "../../../../../models/shared/ui/grid";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import {
  InsumoDaOrdemDeProducaoGridModel,
  InsumoPorNumeroDeSerieGridModel,
} from "../../models/insumo-da-ordem-de-producao";
import { InsumoDaOrdemDeProducaoServico } from "../../servicos/insumo-da-ordem-de-producao";
import { ColunaInsumoQuantidadeBaixada } from "../colunas-insumos/colunaQuantidadeBaixada";
import {
  colunasInsumosBase,
  colunasQuantidadeInsumo,
} from "../colunas-insumos/colunas-insumos";
import ContextoOperacoesInsumo from "../contexto-funcoes-insumo";
import { QuantidadeABaixarEditavelComponent } from "../quantidade-a-baixar-editavel/quantidade-a-baixar-editavel";

const nameOfGridHandler = criarNameof<InsumoPorNumeroDeSerieGridModel>();

interface GridInsumoPorNumeroDeSerieProps
  extends GridMxpProps<InsumoPorNumeroDeSerieGridModel> {
  insumo: InsumoDaOrdemDeProducaoGridModel | undefined;
}

export default function GridInsumoPorNumeroDeSerie(
  props: GridInsumoPorNumeroDeSerieProps
) {
  const gridRef = useRef<DataGridRef>(null);

  const { funcoes } = useContext(ContextoOperacoesInsumo);

  const dataSource =
    InsumoDaOrdemDeProducaoServico.ObterDataSourceParaInsumoProNumeroDeSerieGrid(
      props.insumo
    );

  const gridController = useMemo(
    () =>
      new GridController<InsumoPorNumeroDeSerieGridModel>(() =>
        gridRef.current?.instance()
      ),
    []
  );

  const handleAtualizarGrid = useCallback(() => {
    gridController.atualizar();
  }, [gridController]);

  useEffect(() => {
    funcoes.definirAtualizaGridInsumoPorNumeroDeSerieAoFechar(
      handleAtualizarGrid
    );
  }, [funcoes, handleAtualizarGrid]);

  const configuracoesGrid = useMemo(() => {
    const configsGrid = GridBuilder.criar(
      "insumo-por-numero-de-serie-grid",
      () => gridRef.current?.instance(),
      false,
      props.filtrosNoCliente
    )
      .definirStyles(props.style)
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(NomesTelas.insumosDaOrdemDeProducao)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirOrdenacao()
      .definirEditavel()
      .definirOperacoesNoLadoDoCliente();

    return configsGrid.build();
  }, [props.filtrosNoCliente, props.style, dataSource, handleAtualizarGrid]);

  return (
    <>
      <DataGrid ref={gridRef} {...configuracoesGrid}>
        <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
        <Column
          key={nameOfGridHandler("loteDoFabricanteOuNumeroDeSerie")}
          dataField={nameOfGridHandler("loteDoFabricanteOuNumeroDeSerie")}
          {...obterConfiguracaoColuna("stringM")}
          allowEditing={false}
          width={200}
          caption="Número de série"
        />
        {colunasQuantidadeInsumo}
        <Column
          key={nameOfGridHandler("quantidadeBaixada")}
          dataField={nameOfGridHandler("quantidadeBaixada")}
          {...obterConfiguracaoColuna(
            "quantidadeComNoMaximoCincoCasasDecimais"
          )}
          allowEditing={false}
          caption="Qt baixada"
          cellRender={ColunaInsumoQuantidadeBaixada}
        />
        <Column
          key={nameOfGridHandler("quantidadeABaixar")}
          dataField={nameOfGridHandler("quantidadeABaixar")}
          {...obterConfiguracaoColuna("quantidade")}
          allowEditing={true}
          showEditorAlways={true}
          width={200}
          visibleIndex={11}
          caption="Qt a baixar"
          editCellComponent={QuantidadeABaixarEditavelComponent}
        ></Column>
        {colunasInsumosBase}
      </DataGrid>
    </>
  );
}
