import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "devextreme-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import BotaoCancelarMxp from "../../../../../../components/botoes/botao-cancelar-mxp";
import BotaoSalvarMxp from "../../../../../../components/botoes/botao-salvar-mxp";
import {
  FormGrupo,
  FormTextBox,
} from "../../../../../../components/formularios";
import FormMxp from "../../../../../../components/layout/form";
import { ContainerFormMxp } from "../../../../../../components/layout/form/styles";
import { Coluna, Linha } from "../../../../../../components/layout/grid-system";
import ToolbarMxp from "../../../../../../components/layout/toolbar-mxp";
import AuditavelDTO from "../../../../../../models/api/comum/auditavel-dto";
import {
  IFormularioEditavelBase,
  ResultadoAcaoFormulario,
} from "../../../../../../models/shared/ui/formularios";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../../utils/api/api-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../../utils/common/notificacoes-utils";
import { exibirConfirmacao } from "../../../../../../utils/dialogos";
import { ProvedorToolTip } from "../../../../../../utils/help/provedor-tooltip";
import { renderToStringClient } from "../../../../../../utils/react/react-utils";
import yup from "../../../../../../utils/validacao/validacao";
import { LinkAjudaRequestDTO } from "../../../models/link-ajuda";
import { LinkDeAjudaServico } from "../../../servicos/link-ajuda.service";

interface EditFormLinkAjudaProps extends IFormularioEditavelBase {
  codigo: string;
}

const linkhome = process.env.REACT_APP_HELP_HOME as string;

let dadosAuditoria: AuditavelDTO | undefined = undefined;

export default function EditFormLinkAjuda(props: EditFormLinkAjudaProps) {
  const novoRegistro: LinkAjudaRequestDTO = {
    id: 0,
    codigo: props.codigo,
    slug: "",
  };

  const [excluirVisivel, setExcluirVisivel] = useState(false);

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    codigo: yup.string().required().max(100),
    slug: yup.string().required().max(300),
  });

  const { register, control, handleSubmit, getValues, reset } =
    useForm<LinkAjudaRequestDTO>({
      resolver: yupResolver(schema),
    });

  //Hook usado para carregar os dados da tela
  useEffect(() => {
    if (Number.isNaN(props.idRegistroEmEdicao)) {
      return;
    }

    preencherTela();
  }, [props.idRegistroEmEdicao]);

  async function preencherTela() {
    if (props.idRegistroEmEdicao == 0) {
      limparTela();
    } else if (props.idRegistroEmEdicao > 0) {
      await carregarRegistroDoId();
    }
  }

  async function carregarRegistroDoId() {
    try {
      const resposta = await LinkDeAjudaServico.Obter(props.codigo);
      checarResponse(resposta);
      if (resposta.model) {
        reset(resposta.model);
        dadosAuditoria = resposta.model;
      } else {
        reset(novoRegistro);
        dadosAuditoria = undefined;
      }
      setExcluirVisivel(!!resposta.model);
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  async function handleSalvar() {
    const model = getValues();
    try {
      const resposta = await LinkDeAjudaServico.InserirOuAtualizar(
        model as LinkAjudaRequestDTO
      );
      checarResponse(resposta);
      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: "Salvo com sucesso! Favor recarregar a página.",
          tipo: TipoNotificacao.Sucesso,
        });
        fechar(ResultadoAcaoFormulario.AcaoConcluida);
      }
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  function limparTela() {
    reset(novoRegistro);
  }

  async function onClickExcluir() {
    const confirmacao = await exibirConfirmacao(
      "Confirmar exclusão",
      "Tem certeza de que deseja excluir o link ajuda?"
    );

    if (!confirmacao) {
      return;
    }

    try {
      const resposta = await LinkDeAjudaServico.Excluir(props.codigo);
      checarResponse(resposta);
      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: "Excluído com sucesso! Favor recarregar a página.",
          tipo: TipoNotificacao.Sucesso,
        });
        fechar(ResultadoAcaoFormulario.AcaoConcluida);
      }
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  async function onLimparCacheLocal() {
    const confirmacao = await exibirConfirmacao(
      "Confirmar limpeza",
      renderToStringClient(
        <>
          Tem certeza de que deseja excluir o cache local dos textos de ajuda?
          <br />
          <br />
          <i>
            Essa operação visa apenas facilitar testes internos e apagará apenas
            os dados armazenados localmente.
            <br />
            Se seu intuito é limpar o cache no servidor, basta salvar esse
            registro através do botão <strong>Salvar</strong>.
          </i>
        </>
      )
    );

    if (confirmacao) {
      ProvedorToolTip.limparCache();
      exibirNotificacaoToast({
        mensagem: "Limpeza realizada com sucesso! Favor atualizar a página.",
        tipo: TipoNotificacao.Sucesso,
      });
    }
  }

  function handleCancelar() {
    fechar(ResultadoAcaoFormulario.AcaoCancelada);
  }

  function fechar(resultado: ResultadoAcaoFormulario) {
    limparTela();
    props.handleCallback(resultado);
  }

  return (
    <>
      <ContainerFormMxp>
        <FormMxp>
          <input type="hidden" {...register("id")} defaultValue={0} />
          <input
            type="hidden"
            {...register("codigo")}
            defaultValue={props.codigo}
          />
          <FormGrupo>
            <Linha>
              <Coluna md={12}>
                <FormTextBox
                  name="slug"
                  titulo={linkhome}
                  toolTip="Slug"
                  control={control}
                  requerido
                  tamanhoMaximo={300}
                />
              </Coluna>
            </Linha>
          </FormGrupo>
        </FormMxp>
        <ToolbarMxp dadosAuditoria={dadosAuditoria}>
          <BotaoSalvarMxp handleClick={handleSubmit(handleSalvar)} />
          <BotaoCancelarMxp handleClick={handleCancelar} />
          <Button
            key="btn-excluir"
            type="danger"
            text="Excluir"
            icon="trash"
            visible={excluirVisivel}
            onClick={onClickExcluir}
          />
          <Button
            key="btn-limpar"
            type="normal"
            text="Limpar cache local"
            visible
            onClick={onLimparCacheLocal}
          />
        </ToolbarMxp>
      </ContainerFormMxp>
    </>
  );
}
