import { CheckBox } from "devextreme-react";
import { ValueChangedEvent } from "devextreme/ui/check_box";
import { useState } from "react";
import { Controller, FieldValues, UseControllerProps } from "react-hook-form";
import {
  obterPermissoesFaltantesFormatada,
  verificaSeUsuarioPossuiPermissoes,
} from "../../../utils/common/permissoes-utils";
import IconeAjuda from "../../ajuda/icone-ajuda";
import LinkAjuda from "../../ajuda/link-ajuda";
import IconeRequerPermissao from "../../seguranca/icone-requer-permissao";
import { LabelErro } from "../label-erro";

interface FormCheckBoxProps<T extends FieldValues>
  extends UseControllerProps<T> {
  tabIndex?: number;
  titulo: string;
  toolTip?: string;
  desabilitado?: boolean;
  requerido?: boolean;
  visivel?: boolean;
  exibirLinkAjuda?: boolean;
  onValueChanged?: (value: ValueChangedEvent) => Promise<void>;
  somenteLeitura?: boolean;
  permissoesNecessarias?: string[];
}

export default function <T extends FieldValues>(
  props: FormCheckBoxProps<T>,
  { visivel = true } = props
) {
  const [possuiPermissoesNecessarias] = useState(
    props.permissoesNecessarias
      ? verificaSeUsuarioPossuiPermissoes(props.permissoesNecessarias)
      : true
  );

  const [permissoesFaltantes] = useState(
    props.permissoesNecessarias
      ? obterPermissoesFaltantesFormatada(props.permissoesNecessarias)
      : []
  );

  return (
    <Controller
      {...props}
      render={({ field, fieldState }) => (
        <>
          <IconeRequerPermissao
            exibirIcone={!possuiPermissoesNecessarias}
            inputName={props.name}
            permissoesFaltantesFormatada={permissoesFaltantes}
          >
            <CheckBox
              name={props.name}
              text={props.titulo}
              tabIndex={props.tabIndex}
              hint={props.toolTip}
              disabled={
                !possuiPermissoesNecessarias || (props.desabilitado ?? false)
              }
              readOnly={props.somenteLeitura}
              onValueChange={(value) => {
                field.onChange(value);
              }}
              onValueChanged={props.onValueChanged}
              value={field.value}
              validationStatus={fieldState.invalid ? "invalid" : "valid"}
            />
            <label htmlFor={field.name}>
              <span className="dx-field-item-label-content">
                {visivel && props.requerido && (
                  <span className="dx-field-item-required-mark">&nbsp;*</span>
                )}
                {(props.exibirLinkAjuda ?? true) && (
                  <LinkAjuda keyAjuda={field.name}>
                    <IconeAjuda linhaUnica />
                  </LinkAjuda>
                )}
              </span>
            </label>
            <LabelErro>{fieldState.error?.message}</LabelErro>
          </IconeRequerPermissao>
        </>
      )}
    />
  );
}
