import getApi from "../../../../configs/api";
import {
  ResponseBase,
  ResponseModel,
} from "../../../../models/api/comum/response-base";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import criarDatasourceGrid from "../../../../utils/grid/back-end-grid-utils";
import DataSourceFactory, {
  DataSourceFiltragem,
  DataSourceRawFilter,
} from "../../../../utils/grid/data-source-factory";
import { ApuracaoLalurIrpjEditarRequest } from "../models/apuracao-lalur-irpj";

class ApuracaoLalurIrpjService {
  private _nomeEndpoint: NomesEndpoints;

  constructor() {
    this._nomeEndpoint = NomesEndpoints.ApuracaoLalurIrpj;
  }

  public async ObterPorIdComDadosAuditoria<T>(id: number) {
    const api = getApi();
    const response = await api.get<ResponseModel<T>>(
      `${this._nomeEndpoint}/ObterPorIdComDadosAuditoria?id=${id}`
    );

    return response.data;
  }

  public async Inserir<T>(model: T) {
    const api = getApi();
    const response = await api.post<ResponseBase>(this._nomeEndpoint, model);

    return response.data;
  }

  public async Recalcular(id: number) {
    const api = getApi();
    const response = await api.put<ResponseBase>(
      `${this._nomeEndpoint}/Recalcular?id=${id}`
    );

    return response.data;
  }

  public async Atualizar(model: ApuracaoLalurIrpjEditarRequest) {
    const api = getApi();
    const response = await api.put<ResponseBase>(this._nomeEndpoint, model);

    return response.data;
  }

  public GetGridSource() {
    return criarDatasourceGrid(`${this._nomeEndpoint}/grid`);
  }

  public ObterDataSourceParaGrid<T, TKey = number>(
    filtroPadrao?: DataSourceFiltragem<T>[],
    filtroPadraoExato?: DataSourceRawFilter<T>
  ) {
    return DataSourceFactory.CriarParaGrid<T, TKey>(
      `${this._nomeEndpoint}/grid`,
      filtroPadrao,
      filtroPadraoExato
    );
  }

  public async Excluir(id: number) {
    const api = getApi();
    const response = await api.delete<ResponseBase>(
      `${this._nomeEndpoint}?id=${id}`
    );

    return response.data;
  }

  public async TornarLucroOuPrejuizoLivre(id: number) {
    const api = getApi();
    const response = await api.patch<ResponseBase>(
      `${this._nomeEndpoint}/TornarLucroOuPrejuizoLivre?id=${id}`
    );

    return response.data;
  }
}

export const ApuracaoLalurIrpjServico = new ApuracaoLalurIrpjService();
