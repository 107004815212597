import { useEffect, useState } from "react";
import { FieldValues, UseControllerProps } from "react-hook-form";
import Multiselectbox, {
  getDataSourceMulltiSelectBox,
  MulltiSelectDataSource,
} from "../../../../../components/formularios/multiselectbox";
import { useAppSelector } from "../../../../../hooks/store.hooks";
import { PermissoesEmpresa } from "../../../../../models/permissoes/crm/empresa/permissoes-empresa";
import SelectItem from "../../../../../models/shared/ui/select-item";
import empresaService from "../../../../../services/empresa/empresa.service";
import { DataSourceFiltragem } from "../../../../../utils/grid/data-source-factory";
import { EmpresaGridModel } from "../../models/empresa-grid";
import GridEmpresa from "../grid";

const filtroSeletor: DataSourceFiltragem<EmpresaGridModel>[] = [
  { campo: "propria", operador: "=", valor: true },
  { campo: "ativo", operador: "=", valor: true },
];

interface MultiSelectMinhasEmpresasProps<T extends FieldValues>
  extends UseControllerProps<T> {
  requerido?: boolean;
}

export default function MultiSelectMinhasEmpresas<T extends FieldValues>(
  props: MultiSelectMinhasEmpresasProps<T>
) {
  const [dataSource, setDataSource] = useState<
    MulltiSelectDataSource<SelectItem>
  >({});
  const assinanteLogado = useAppSelector(
    (state) => state.sessao.dadosSessao?.assinanteId
  );

  useEffect(() => {
    carregarMultiSelect();
  }, [assinanteLogado]);

  async function carregarMultiSelect() {
    const selectItem: SelectItem[] = [];

    const response = await empresaService.ObterListaSimplesMinhasEmpresas(
      false
    );

    if (!response.sucesso) {
      setDataSource(getDataSourceMulltiSelectBox(selectItem));
      return;
    }

    for (const minhaEmpresa of response.model) {
      selectItem.push({ ...minhaEmpresa });
    }

    setDataSource(getDataSourceMulltiSelectBox(selectItem));
  }

  return (
    <Multiselectbox
      control={props.control}
      habilitaBusca
      titulo="Minha empresa"
      dataSource={dataSource}
      name={props.name}
      requerido={props.requerido}
      maxDisplayedTags={2}
      seletorConfig={{
        componenteGrid: <GridEmpresa filtrosNoServidor={filtroSeletor} />,
        titulo: "Selecionar empresa",
      }}
      permissoesNecessarias={[PermissoesEmpresa.ConsultarMinhasEmpresas]}
    />
  );
}
