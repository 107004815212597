import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";

class GrupoDeCentroDeTrabalhoCalendarioService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.GrupoDeCentroDeTrabalhoCalendario);
  }
}

export const GrupoDeCentroDeTrabalhoCalendarioServico =
  new GrupoDeCentroDeTrabalhoCalendarioService();
