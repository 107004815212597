import SelectItem from "../../../../models/shared/ui/select-item";
import { gerarSelectItemDoDicionario } from "../../../../utils/common/enum-utils";

export enum DimensionamentoExcel {
  SemDimensionamento = 1,
  AjustarPlanilhaEmUmaPagina = 2,
  AjustarColunasEmUmaPagina = 3,
  AjustarLinhasEmUmaPagina = 4,
}

export enum FinalidadeModeloRelatorio {
  OrdemDeProducao = 1,
}

export enum TipoDadosAbstraido {
  Complexo = 0,
  Texto = 1,
  Enumerador = 2,
  Inteiro = 3,
  Decimal = 4,
  DataHora = 5,
  Booleano = 6,
  Tempo = 7,
  Binario = 8,
}

export enum TipoEntidadeCampoAdicional {
  PedidoDeVenda = 1,
  Titulo = 2,
  ContaAReceber = 3,
  ContaAPagar = 4,
  Empresa = 5,
}

export enum MetadadoTag {
  Nenhum = 0,
  CodigoDeBarras = 1 << 0,
  Imagem = 1 << 1,
}

export const dimensionamentoExcelDecodificado: {
  [key in DimensionamentoExcel]: string;
} = {
  [DimensionamentoExcel.SemDimensionamento]: "Sem dimensionamento",
  [DimensionamentoExcel.AjustarPlanilhaEmUmaPagina]:
    "Ajustar planilha em uma página",
  [DimensionamentoExcel.AjustarColunasEmUmaPagina]:
    "Ajustar todas as colunas em uma página",
  [DimensionamentoExcel.AjustarLinhasEmUmaPagina]:
    "Ajustar todas as linhas em uma página",
};

export const FinalidadesPerfilAcessoRelatoriosMxp1 = new Map<
  FinalidadeModeloRelatorio,
  string
>([[FinalidadeModeloRelatorio.OrdemDeProducao, "Ordens de produção"]]);

export const SelectItemEnumDimensionamentoExcel: SelectItem[] =
  gerarSelectItemDoDicionario(dimensionamentoExcelDecodificado);
