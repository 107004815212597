import { AxiosResponse } from "axios";
import { UseFormReturn } from "react-hook-form";
import TabContainer from "../../../../../../components/layout/tab-container";
import RequerPermissao from "../../../../../../components/seguranca/requer-permissao";
import { ResponseBaseArquivo } from "../../../../../../models/api/comum/response-base";
import { PermissoesLote } from "../../../../../../models/permissoes/estoque/lote/permissoes-lote";
import GridAnexos, {
  OperacoesFieldArray,
} from "../../../../../sistema/arquivo/componentes/grid";
import { LoteRequestDTO } from "../../../models/lote.api";

interface LoteAbaAnexosProps {
  idRegistro: number;
  hookForms: UseFormReturn<LoteRequestDTO>;
  funcaoParaBaixarAnexo: (
    idAnexo: number
  ) => Promise<AxiosResponse<ResponseBaseArquivo, any>>;
  operacoesFieldArray: OperacoesFieldArray;
}

export default function LoteAbaAnexos(props: LoteAbaAnexosProps) {
  const { getValues } = props.hookForms;

  return (
    <TabContainer>
      <RequerPermissao
        codigoPermissoes={[PermissoesLote.VisualizarEBaixarAnexos]}
      >
        <GridAnexos
          idRegistroRelacionado={props.idRegistro}
          operacoesFieldArray={props.operacoesFieldArray}
          permissaoVisualizarEBaixar={PermissoesLote.VisualizarEBaixarAnexos}
          permissaoIncluir={PermissoesLote.IncluirAnexos}
          permissaoExcluir={PermissoesLote.ExcluirAnexos}
          funcaoParaBaixarAnexo={props.funcaoParaBaixarAnexo}
          arquivos={getValues().arquivos}
        />
      </RequerPermissao>
    </TabContainer>
  );
}
