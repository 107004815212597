import { Button } from "devextreme-react";
import DataGrid, { Column, DataGridRef } from "devextreme-react/data-grid";
import { useCallback, useEffect, useMemo, useRef } from "react";
import BotaoCancelarMxp from "../../../../../../components/botoes/botao-cancelar-mxp";
import ToolbarMxp from "../../../../../../components/layout/toolbar-mxp";
import { useSeletorGridMxp } from "../../../../../../hooks/seletor.hooks";
import { PermissoesBoletimInspecao } from "../../../../../../models/permissoes/qualidade/boletim-de-inspecao/permissoes-boletim-de-inspecao";
import { GridMxpProps } from "../../../../../../models/shared/ui/grid";
import criarNameof from "../../../../../../utils/common/cria-name-of";
import {
  abrirModalMxpAntigo,
  DadosLink,
} from "../../../../../../utils/common/menu-utils";
import NomesModais from "../../../../../../utils/common/nomes-modais";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../../utils/common/permissoes-utils";
import { exibirAlerta } from "../../../../../../utils/dialogos";
import GridBuilder from "../../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../../utils/grid/padroes-colunas";
import { OperacaoDaOrdemDeProducaoCriarBoletimDeInspecaoGridModel } from "../../../models/operacao-de-ordem-de-producao";
import { OperacaoDeOrdemDeProducaoServico } from "../../../servicos/operacao-de-ordem-de-producao-service";

const nameOfGridHandler =
  criarNameof<OperacaoDaOrdemDeProducaoCriarBoletimDeInspecaoGridModel>();

interface GridOperacaoDaOrdemDeProducaoCriarBoletimDeInspecaoProps
  extends GridMxpProps<OperacaoDaOrdemDeProducaoCriarBoletimDeInspecaoGridModel> {
  ordemDeProducaoId: number;
  fecharModal: () => Promise<void>;
}

export default function GridOperacaoDaOrdemDeProducaoCriarBoletimDeInspecao(
  props: GridOperacaoDaOrdemDeProducaoCriarBoletimDeInspecaoProps
) {
  const gridRef = useRef<DataGridRef>(null);

  const dataSource =
    OperacaoDeOrdemDeProducaoServico.ObterQueryGridCriarBoletimDeInspecaoDaOrdemDeProducaoComOperacoes(
      props.ordemDeProducaoId
    );

  const selecionarUltimaOperacaoDaGrid = useCallback(() => {
    if (gridRef.current && dataSource?.totalCount() > 0) {
      const dadosGrid =
        dataSource.items() as OperacaoDaOrdemDeProducaoCriarBoletimDeInspecaoGridModel[];
      const ultimaOperacaoIndex = dadosGrid[dadosGrid.length - 1].id;
      gridRef.current.instance().selectRows([ultimaOperacaoIndex], false);
    }
  }, [dataSource]);

  useEffect(() => {
    if (dataSource?.totalCount() > 0) {
      selecionarUltimaOperacaoDaGrid();
    }
  }, [dataSource, selecionarUltimaOperacaoDaGrid]);

  const gridController = useMemo(
    () =>
      new GridController<OperacaoDaOrdemDeProducaoCriarBoletimDeInspecaoGridModel>(
        () => gridRef.current?.instance()
      ),
    []
  );

  const handleAtualizarGrid = useCallback(() => {
    gridController.atualizar();
  }, [gridController]);

  useSeletorGridMxp(() => gridRef.current, props.filtrosNoCliente);

  const criarBoletimDeInspecaoDaOperacao = async () => {
    const operacaoSelecionada = gridRef.current
      ?.instance()
      .getSelectedRowsData()[0] as OperacaoDaOrdemDeProducaoCriarBoletimDeInspecaoGridModel;

    if (!operacaoSelecionada) {
      return;
    }

    if (
      !verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesBoletimInspecao.Consultar,
        PermissoesBoletimInspecao.InserirEditar,
      ])
    ) {
      return;
    }

    await exibirAlerta(
      "Aviso",
      "A funcionalidade de criação de boletins de inspeção ainda não foi adaptada para a nova versão do MAXIPROD.<br />" +
        ' Ao clicar em "Ok", você será direcionado à tela na versão antiga, onde o boletim poderá ser criado'
    );

    const dadosLink: DadosLink = {
      rota: "_BoletimInspecao_OrdemDeProducao_CriarBoletimDeInspecaoDaOperacaoMXP2",
      tipo: "javaScriptMxpAntigo",
      paramFunction: () => `(${operacaoSelecionada.id.toString()})`,
    };

    const abrirEmNovaAba = true;
    abrirModalMxpAntigo(dadosLink, abrirEmNovaAba, "BoletimInspecao/Index");

    await props.fecharModal();
  };

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar(
      "operacao-da-ordem-de-producao-criar-boletim-inspecao-ordem-de-producao",
      () => gridRef.current?.instance(),
      false,
      props.filtrosNoCliente
    )
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(
        NomesModais.criarBoletimDeInspecaoComOperacoesDaOrdemDeProducao
      )
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirSelecao("single")
      .definirOrdenacao()
      .build();
  }, [props.filtrosNoCliente, dataSource, handleAtualizarGrid]);

  return (
    <>
      <span style={{ fontSize: "14px", marginBottom: 10 }}>
        Selecione uma operação da ordem de produção para criar um boletim de
        inspeção:
      </span>
      <DataGrid
        ref={gridRef}
        {...configuracoesGrid}
        onContentReady={selecionarUltimaOperacaoDaGrid}
      >
        <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
        <Column
          key={nameOfGridHandler("numero")}
          dataField={nameOfGridHandler("numero")}
          {...obterConfiguracaoColuna("stringP")}
          caption="Operação"
          sortOrder="asc"
        />
        <Column
          key={nameOfGridHandler("descricao")}
          dataField={nameOfGridHandler("descricao")}
          {...obterConfiguracaoColuna("stringMP")}
          caption="Descrição"
        />
        <Column
          key={nameOfGridHandler("codigoCentroDeTrabalho")}
          dataField={nameOfGridHandler("codigoCentroDeTrabalho")}
          {...obterConfiguracaoColuna("stringM")}
          caption="Centro de trabalho"
        />
        <Column
          key={nameOfGridHandler("estado")}
          dataField={nameOfGridHandler("estado")}
          {...obterConfiguracaoColuna("stringP")}
          caption="Estado"
        />
      </DataGrid>
      <ToolbarMxp dadosAuditoria={undefined}>
        <Button
          type="success"
          text={"Criar boletim de inspeção"}
          onClick={criarBoletimDeInspecaoDaOperacao}
        />
        <BotaoCancelarMxp handleClick={props.fecharModal} />
      </ToolbarMxp>
    </>
  );
}
