import { yupResolver } from "@hookform/resolvers/yup";
import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import {
  FormNumberBox,
  FormSelectBox,
  FormTextBox,
} from "../../../../../../components/formularios";
import { Coluna, Linha } from "../../../../../../components/layout/grid-system";
import TabContainer from "../../../../../../components/layout/tab-container";
import TituloSessao from "../../../../../../components/organizacao/sessao";
import {
  SelectItemEnumTipoCarregamentoMDFe,
  SelectItemEnumUnidadeMedidaPesoBrutoMDFe,
} from "../../../../../../models/const/dicionario-combos/mdfe";
import { IFormulario } from "../../../../../../models/shared/ui/formularios";
import { MDFeAbaDocumentosFiscaisViewModel } from "../../../../../../models/viewmodels/vendas/mdfe/mdfe-edit-form-view-model";

import { checarSeFormFoiModificado } from "../../../../../../utils/common/form-utils";
import {
  calcularQuantidadeTotalCTeMDFe,
  calcularQuantidadeTotalNFeMDFe,
} from "../../../../../../utils/especifico/mdfe/mdfe-utils";
import { obterFormatStringNumero } from "../../../../../../utils/formatadores/formatador-de-numeros";
import yup from "../../../../../../utils/validacao/validacao";
import { MensagensPadraoYup } from "../../../../../comum/utils/yup/mensagens";
import MDFeEditFormContext from "../../../contexts/mdfe-editform.context";
import {
  TipoCarregamentoMDFe,
  UnidadeMedidaPesoBrutoMDFe,
} from "../../../models/mdfe-enums";
import {
  pesoTamanhoMaximo,
  valorTamanhoMaximo,
} from "../../formulario/formularios-acessorios/edit-form-documento-fiscal-avulso/constantes";
import GridDocumentosFiscais from "./grid-documentos-fiscais";

interface DadosGeraisProps {
  abaSomenteLeitura: boolean;
}

// Cria um componente referenciável
const MDFeAbaDocumentosFiscais = forwardRef(
  ({ abaSomenteLeitura }: DadosGeraisProps, ref) => {
    const {
      produtoPredominante,
      descarregamentos,
      baseMdfe,
      dadosNotas,
      dadosGerais,
      definirDadosNotas,
    } = useContext(MDFeEditFormContext);

    const model: MDFeAbaDocumentosFiscaisViewModel = useMemo(() => {
      return {
        id: baseMdfe.id,
        descricaoProdutoPredominante: produtoPredominante?.descricao ?? "",
        pesoBrutoTotal: dadosNotas.pesoBruto,
        quantidadeTotalNFe: calcularQuantidadeTotalNFeMDFe(descarregamentos),
        quantidadeTotalCTe: calcularQuantidadeTotalCTeMDFe(descarregamentos),
        valorTotal: dadosNotas.valorTotalNfe,
        tipoCarregamento: dadosNotas.tipoCarregamento,
        unidadeMedidaPesoBruto: dadosNotas.unidadePesoBruto,
      };
    }, [produtoPredominante, descarregamentos, baseMdfe, dadosNotas]);

    useEffect(() => {
      if (!Number.isNaN(baseMdfe.id)) {
        carregarTela();
      }
    }, [baseMdfe.id]);

    async function carregarTela() {
      reset(model);
      //Carregar combos e afins
    }

    const schema = useMemo(
      () =>
        yup.object().shape({
          id: yup.number().required().moreThan(-1).integer(),
          descricaoProdutoPredominante: yup
            .string()
            .max(120)
            .test(
              "produtoPredominante_test",
              MensagensPadraoYup.campoObrigatorio,
              function (value) {
                return dadosGerais.tipoTransportador && !value ? false : true;
              }
            ),
          pesoBrutoTotal: yup.number().moreThan(0).required(),
          quantidadeTotalNFe: yup.number().required().moreThan(-1),
          quantidadeTotalCTe: yup.number().required().moreThan(-1),
          valorTotal: yup.number().required().moreThan(-1),
          tipoCarregamento: yup
            .mixed<TipoCarregamentoMDFe>()
            .transform((v) => (v ? v : null))
            .oneOf(
              Object.values(TipoCarregamentoMDFe).map((x) => x as number),
              "Valor inválido"
            )
            .required(),
          unidadeMedidaPesoBruto: yup
            .mixed<UnidadeMedidaPesoBrutoMDFe>()
            .transform((v) => (v ? v : null))
            .oneOf(
              Object.values(UnidadeMedidaPesoBrutoMDFe).map((x) => x as number),
              "Valor inválido"
            )
            .required(),
        }),
      [dadosGerais.tipoTransportador]
    );

    const { setValue, reset, control, formState, handleSubmit } =
      useForm<MDFeAbaDocumentosFiscaisViewModel>({
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: yupResolver(schema),
      });

    const form = useRef<HTMLFormElement | null>(null);

    const handleSubmitInterno = useCallback(
      (data: MDFeAbaDocumentosFiscaisViewModel) => {
        definirDadosNotas(data);
      },
      [definirDadosNotas]
    );

    // Repassar referências para componente pai
    useImperativeHandle(
      ref,
      (): IFormulario => ({
        requestSubmit() {
          form.current?.requestSubmit();
        },
        valido() {
          form.current?.requestSubmit();
          return Object.keys(formState.errors).length == 0;
        },
        isDirty() {
          return checarSeFormFoiModificado(formState);
        },
      }),
      [formState, form]
    );

    const [ultimoValorTotal, setUltimoValorTotal] = useState(0);
    const [ultimaQuantidadeNFe, setUltimaQuantidadeNFe] = useState(0);
    const [ultimaQuantidadeCTe, setUltimaQuantidadeCTe] = useState(0);
    const [ultimoPesoBrutoTotal, setUltimoPesoBrutoTotal] = useState(0);

    useEffect(() => {
      if (ultimoValorTotal !== model.valorTotal) {
        setUltimoValorTotal(model.valorTotal);
        setValue("valorTotal", model.valorTotal);
      }

      if (ultimaQuantidadeNFe !== model.quantidadeTotalNFe) {
        setUltimaQuantidadeNFe(model.quantidadeTotalNFe);
        setValue("quantidadeTotalNFe", model.quantidadeTotalNFe);
      }

      if (ultimaQuantidadeCTe !== model.quantidadeTotalCTe) {
        setUltimaQuantidadeCTe(model.quantidadeTotalCTe);
        setValue("quantidadeTotalCTe", model.quantidadeTotalCTe);
      }

      if (ultimoPesoBrutoTotal !== model.pesoBrutoTotal) {
        setUltimoPesoBrutoTotal(model.pesoBrutoTotal);
        setValue("pesoBrutoTotal", model.pesoBrutoTotal);
      }
    }, [
      model.quantidadeTotalNFe,
      model.quantidadeTotalCTe,
      model.valorTotal,
      model.pesoBrutoTotal,
    ]);

    return (
      <TabContainer>
        <form ref={form} onSubmit={handleSubmit(handleSubmitInterno)}>
          {/* Grid de notas */}
          <Linha>
            <Coluna>
              <GridDocumentosFiscais
                idRegistro={baseMdfe.id}
                somenteLeitura={abaSomenteLeitura}
                isModal
                style={{
                  minHeight: "18em",
                  maxHeight: "calc(100vh - 40em)",
                }}
              />
            </Coluna>
          </Linha>

          {/* Totalizadores */}
          <TituloSessao titulo="Totalizadores">
            <Linha>
              <Coluna md={2}>
                <FormNumberBox
                  name="quantidadeTotalNFe"
                  titulo="Quantidade total NF-e"
                  toolTip="Quantidade total NF-e"
                  control={control}
                  somenteLeitura
                  formato={obterFormatStringNumero(0)}
                />
              </Coluna>
              <Coluna md={2}>
                <FormNumberBox
                  name="quantidadeTotalCTe"
                  titulo="Quantidade total CT-e"
                  toolTip="Quantidade total CT-e"
                  control={control}
                  somenteLeitura
                  formato={obterFormatStringNumero(0)}
                />
              </Coluna>
              <Coluna md={3}>
                <FormNumberBox
                  name="valorTotal"
                  titulo="Valor total"
                  toolTip="Valor total"
                  control={control}
                  somenteLeitura
                  formato={obterFormatStringNumero(2)}
                  maximo={valorTamanhoMaximo}
                />
              </Coluna>
              <Coluna md={5}>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "50%" }}>
                    <FormNumberBox
                      name="pesoBrutoTotal"
                      titulo="Peso bruto"
                      toolTip="Peso bruto"
                      control={control}
                      requerido
                      formato={obterFormatStringNumero(4)}
                      somenteLeitura={abaSomenteLeitura}
                      maximo={pesoTamanhoMaximo}
                    />
                  </div>
                  <div style={{ marginLeft: "0.2em", width: "30%" }}>
                    <FormSelectBox
                      name="unidadeMedidaPesoBruto"
                      toolTip="Unidade de medida"
                      control={control}
                      dataSource={SelectItemEnumUnidadeMedidaPesoBrutoMDFe}
                      somenteLeitura={abaSomenteLeitura}
                      exibirLinkAjuda={false}
                    />
                  </div>
                </div>
              </Coluna>
            </Linha>
          </TituloSessao>

          {/* Produto predominante */}
          <TituloSessao titulo="Produto predominante">
            <Linha>
              <Coluna md={3}>
                <FormSelectBox
                  name="tipoCarregamento"
                  titulo="Tipo de carregamento"
                  toolTip="Tipo de carregamento"
                  control={control}
                  requerido
                  dataSource={SelectItemEnumTipoCarregamentoMDFe}
                  somenteLeitura={abaSomenteLeitura}
                />
              </Coluna>
              <Coluna md={9}>
                <FormTextBox
                  name="descricaoProdutoPredominante"
                  titulo="Descrição do produto"
                  toolTip="Descrição do produto"
                  control={control}
                  requerido={dadosGerais.tipoTransportador != null}
                  tamanhoMaximo={120}
                  somenteLeitura={abaSomenteLeitura}
                />
              </Coluna>
            </Linha>
          </TituloSessao>
        </form>
      </TabContainer>
    );
  }
);

export default MDFeAbaDocumentosFiscais;
