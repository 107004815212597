import { useParams } from "react-router-dom";
import PaginaMxp from "../../../../../components/layout/pagina-mxp";
import ProvedorMenus from "../../../../../components/menus/provedor-menus";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import { FinalidadeModeloDeDocumentoOuRelatorioTituloHelper } from "../../../../sistema/modelo-de-documento-ou-relatorio/models/modelo-de-documento-ou-relatorio.enums";
import DicionarioTagsSchemaProvider from "../../componentes/dicionario-tags-schema-provider";
import PainelPrincipalDicionarioTags from "../../componentes/painel-principal-dicionario-tags";
import { FinalidadeModeloRelatorio } from "../../models/relatorio-personalizado.enums";

interface DicionarioDeTagsPageProps {
  tipo?: FinalidadeModeloRelatorio;
}

export default function DicionarioDeTagsPage({
  tipo,
}: DicionarioDeTagsPageProps) {
  const params = useParams();
  const tipoFonte: FinalidadeModeloRelatorio = tipo ?? Number(params["tipo"]);

  return (
    <ProvedorMenus>
      <PaginaMxp
        id="listagem-tags-relatorios-page"
        titulo={`${
          NomesTelas.dicionarioDeTags
        } de ${FinalidadeModeloDeDocumentoOuRelatorioTituloHelper.obterTitulo(
          tipoFonte
        )}`}
      >
        <DicionarioTagsSchemaProvider>
          <PainelPrincipalDicionarioTags tipo={tipoFonte} />
        </DicionarioTagsSchemaProvider>
      </PaginaMxp>
    </ProvedorMenus>
  );
}
