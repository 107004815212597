import { Accordion, AccordionRef } from "devextreme-react/accordion";
import { TabPanel } from "devextreme-react/tab-panel";
import ArrayStore from "devextreme/data/array_store";
import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { ComponentAsyncLoader } from "../../utils/load-on-demand";
import "./showhide.scss";

// Interfaces
interface AccordionItemPropsBase {
  children: React.ReactNode;
  style?: React.CSSProperties;
  aberto?: boolean;
  text: string;
  carregarApenasQuandoAberto?: boolean;
  index?: number;
}

interface IShowHideItemProps extends AccordionItemPropsBase {
  disabled?: boolean;
  html?: string;
  icon?: string;
}

interface AccordionItemProps extends AccordionItemPropsBase {
  usaAba?: boolean;
}

interface ShowHideProps {
  id: string;
  idRegistroEmEdicao: number;
  children: React.ReactNode;
  usarAba?: boolean;
  height?: number | string | (() => number | string);
}

export interface IShowHideRef {
  handleSubmitErrors: (errors: object) => void;
}

// Styled components
const AccordionTitle = styled.div`
  font-size: 13px;
  font-weight: 500;
`;

// Constantes
const EMPTY_ARRAY_STORE = new ArrayStore({
  key: "text",
  data: [],
});

// Utility functions
const itemTitleRender = (item: IShowHideItemProps) => (
  <AccordionTitle>{item.text}</AccordionTitle>
);

const itemKeyExpr = (item: IShowHideItemProps) => item.text;

// Componentes internos
const MemoizedAccordionItem = memo(
  ({
    children,
    aberto,
    carregarApenasQuandoAberto,
    text,
    style,
    usaAba,
    index,
  }: AccordionItemProps) => {
    if (usaAba) {
      return <>{(aberto || !carregarApenasQuandoAberto) && children}</>;
    }

    return (
      <div
        className="dx-item-content showhide-item"
        data-text={text}
        data-index={index}
        style={{
          ...style,
        }}
      >
        {(aberto || !carregarApenasQuandoAberto) && children}
      </div>
    );
  }
);

MemoizedAccordionItem.displayName = "AccordionItem";

const ShowHideItem: React.FC<
  IShowHideItemProps & { usaAba?: boolean | undefined }
> = (props) => (
  <MemoizedAccordionItem
    style={props.style}
    carregarApenasQuandoAberto={props.carregarApenasQuandoAberto}
    aberto={props.aberto}
    text={props.text}
    usaAba={props.usaAba}
    index={props.index}
  >
    <ComponentAsyncLoader>{props.children}</ComponentAsyncLoader>
  </MemoizedAccordionItem>
);

// Componente principal
const ShowHide = forwardRef<IShowHideRef, ShowHideProps>((props, ref) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [itensData, setItensData] = useState<ArrayStore>(EMPTY_ARRAY_STORE);
  const loadedItems = useRef<Set<number>>(new Set());
  const accordionRef = useRef<AccordionRef>(null);

  useImperativeHandle(ref, () => ({
    handleSubmitErrors: abrirShowhidesComErros,
  }));

  const abrirShowhidesComErros = useCallback((errors: object) => {
    const nomesElementos = Object.keys(errors);
    if (!accordionRef.current || nomesElementos.length === 0) {
      return;
    }

    const elemento = accordionRef.current.instance().element();
    const children = Array.from(
      elemento.querySelectorAll('.showhide-item[data-text]:not([data-text=""])')
    ) as HTMLElement[];

    const keysToOpen = children
      .filter((child) => {
        const labels = Array.from(child.getElementsByTagName("label"));
        return labels.some((label) => {
          const forValue = label.getAttribute("for");
          return forValue && nomesElementos.includes(forValue);
        });
      })
      .map((child) => child.dataset.text)
      .filter((text): text is string => text !== undefined);

    if (keysToOpen.length > 0) {
      setSelectedItems((prev) => Array.from(new Set([...prev, ...keysToOpen])));
    }
  }, []);

  const selectedItemKeysChange = useCallback(async (keys: string[]) => {
    setSelectedItems(keys);
  }, []);

  const mostrarItem = useCallback(
    (e: any) => {
      // Log para descobrir o motivo do erro especificado na tarefa
      // SL-58997 (Ao rodar ação "Atualizar roteiro" na OP, as grids embutidas da janela aberta de OP não carregam)
      // TODO: Retirar depois de descoberto o motivo do erro
      console.log(
        "abriu modal",
        e.itemIndex,
        e.itemData.text,
        loadedItems.current
      );
      if (loadedItems.current.has(e.itemIndex)) {
        return;
      }

      loadedItems.current.add(e.itemIndex);
      const key = e.itemData.text;
      if (props.usarAba) {
        itensData.push([{ type: "update", data: { aberto: true }, key: key }]);
      } else {
        itensData.update(key, { aberto: true });
      }
      // Log para descobrir o motivo do erro especificado na tarefa
      // SL-58997 (Ao rodar ação "Atualizar roteiro" na OP, as grids embutidas da janela aberta de OP não carregam)
      // TODO: Retirar depois de descoberto o motivo do erro
      console.log(itensData);
    },
    [itensData, props.usarAba]
  );

  const carregarItens = useCallback(async () => {
    const itensAbertos: string[] = [];
    const elementos = React.Children.map(props.children, (child, index) => {
      if (!React.isValidElement<IShowHideItemProps>(child)) {
        return undefined;
      }

      const elementChild = child.props;
      if (elementChild.aberto) {
        itensAbertos.push(elementChild.text);
      }

      if (
        elementChild.carregarApenasQuandoAberto != true ||
        elementChild.aberto
      ) {
        loadedItems.current.add(index);
      }

      return { ...elementChild, index };
    })?.filter((x) => x != undefined) as IShowHideItemProps[];

    const dataSource = new ArrayStore({
      key: "text",
      data: elementos,
    });

    // Log para descobrir o motivo do erro especificado na tarefa
    // SL-58997 (Ao rodar ação "Atualizar roteiro" na OP, as grids embutidas da janela aberta de OP não carregam)
    // TODO: Retirar depois de descoberto o motivo do erro
    console.log("carregar itens ", itensAbertos);
    setItensData(dataSource);
    accordionRef.current?.instance().option("dataSource", dataSource);
    accordionRef.current?.instance().option("selectedItemKeys", itensAbertos);
  }, [props.children]);

  useEffect(() => {
    setSelectedItems([]);
    loadedItems.current.clear();
  }, [props.idRegistroEmEdicao, props.usarAba]);

  useEffect(() => {
    // Log para descobrir o motivo do erro especificado na tarefa
    // SL-58997 (Ao rodar ação "Atualizar roteiro" na OP, as grids embutidas da janela aberta de OP não carregam)
    // TODO: Retirar depois de descoberto o motivo do erro
    console.log("state ", props.idRegistroEmEdicao, " - ", loadedItems.current);

    // Ao atualizar o modal e carregar os itens, os itens carregados serão limpos para que sejam carregados novamente
    loadedItems.current.clear();
    if (Number.isNaN(props.idRegistroEmEdicao)) return;

    carregarItens();
    // Log para descobrir o motivo do erro especificado na tarefa
    // SL-58997 (Ao rodar ação "Atualizar roteiro" na OP, as grids embutidas da janela aberta de OP não carregam)
    // TODO: Retirar depois de descoberto o motivo do erro
    console.log(loadedItems.current);
  }, [props.idRegistroEmEdicao, carregarItens, props.usarAba]);

  if (props.usarAba) {
    return (
      <TabPanel
        id={props.id}
        className="showhide"
        deferRendering={false}
        showNavButtons
        repaintChangesOnly={true}
        swipeEnabled={false}
        onTitleClick={mostrarItem}
        dataSource={itensData}
        itemRender={ShowHideItem}
        itemTitleRender={itemTitleRender}
        height={props.height}
      />
    );
  }
  return (
    <Accordion
      ref={accordionRef}
      id={props.id}
      className="showhide"
      collapsible
      multiple
      repaintChangesOnly={true}
      dataSource={itensData}
      selectedItemKeys={selectedItems}
      keyExpr={itemKeyExpr}
      onItemClick={mostrarItem}
      onSelectedItemKeysChange={selectedItemKeysChange}
      deferRendering={false}
      itemRender={ShowHideItem}
      itemTitleRender={itemTitleRender}
    />
  );
});

ShowHide.displayName = "ShowHide";

export default ShowHide;
export { ShowHideItem };
