import { useCallback } from "react";
import ImportadorDeRegistros, {
  colunaParaBoolean,
  colunaParaNumero,
  colunaParaPercentual,
  colunaUtcParaData,
  MxpSchemaImportar,
} from "../../../../../components/formularios/importador";
import { InserirEmMassaResponse } from "../../../../../models/api/comum/inserir-varios";
import { ResponseModel } from "../../../../../models/api/comum/response-base";
import yup from "../../../../../utils/validacao/validacao";
import { ImobilizadoInserirVariosExcelRequest } from "../../models/imobilizado";
import ImobilizadoConstantes from "../../models/imobilizado.constantes";
import { TipoDeDepreciacao } from "../../models/imobilizado.enums";
import { ImobilizadoServico } from "../../servicos/imobilizado.service";

const schema: MxpSchemaImportar<ImobilizadoInserirVariosExcelRequest> = {
  "Item *": {
    prop: "codigoItem",
    type: String,
    dataType: "string",
  },
  "Número do patrimônio *": {
    prop: "numeroDoPatrimonio",
    type: String,
    dataType: "string",
  },
  "Descrição *": {
    prop: "descricao",
    type: String,
    dataType: "string",
  },
  "Conta contábil *": {
    prop: "contaContabil",
    type: String,
    dataType: "string",
  },
  "Centro de custos *": {
    prop: "centroDeCustos",
    type: String,
    dataType: "string",
  },
  "Utilização do bem *": {
    prop: "utilizacaoDoBem",
    type: String,
    dataType: "string",
  },
  "Data de aquisição *": {
    prop: "dataDaAquisicao",
    type: colunaUtcParaData,
    dataType: "date",
  },
  "Forma de cálculo da primeira parcela*": {
    prop: "formaDeCalculoDaPrimeiraParcela",
    type: String,
    dataType: "string",
  },
  "Custo da aquisição *": {
    prop: "valorDaAquisicao",
    type: colunaParaNumero,
    dataType: "number",
  },
  "Valor residual": {
    prop: "valorResidual",
    type: colunaParaNumero,
    dataType: "number",
  },
  "Tipo de depreciação": {
    prop: "tipoDeDepreciacao",
    type: String,
    dataType: "string",
  },
  "Mês de início da depreciação": {
    prop: "mesDeInicioDaDepreciacao",
    type: String,
    dataType: "string",
  },
  "Percentual (%)": {
    prop: "percentualDeDepreciacao",
    type: colunaParaPercentual,
    dataType: "number",
  },
  "Vida útil (em meses) *": {
    prop: "vidaUtilEmMeses",
    type: colunaParaNumero,
    dataType: "number",
  },
  "Conta contábil de débito *": {
    prop: "contaDeDebitoDaDepreciacao",
    type: String,
    dataType: "string",
  },
  "Centro de custos de débito": {
    prop: "centroDeCustosDeDebitoDaDepreciacao",
    type: String,
    dataType: "string",
  },
  "Conta contábil de crédito *": {
    prop: "contaDeCreditoDaDepreciacao",
    type: String,
    dataType: "string",
  },
  "Centro de custos de crédito": {
    prop: "centroDeCustosDeCreditoDaDepreciacao",
    type: String,
    dataType: "string",
  },
  "Quantidade inicial de parcelas depreciadas": {
    prop: "quantidadeInicialDeParcelasDepreciadas",
    type: colunaParaNumero,
    dataType: "number",
  },
  "Valor depreciado inicial": {
    prop: "valorInicialDepreciado",
    type: colunaParaNumero,
    dataType: "number",
  },
  "Sujeito ao CIAP": {
    prop: "sujeitoAoCiap",
    type: colunaParaBoolean,
    dataType: "boolean",
  },
  "Número da nota fiscal recebida": {
    prop: "numeroNotaRecebida",
    type: colunaParaNumero,
    dataType: "number",
  },
  "Item da nota fiscal recebida": {
    prop: "itemNotaRecebida",
    type: colunaParaNumero,
    dataType: "number",
  },
  "Documento fiscal": {
    prop: "documentoFiscal",
    type: String,
    dataType: "string",
  },
  "Chave de acesso": {
    prop: "chaveAcesso",
    type: String,
    dataType: "string",
  },
  Número: { prop: "numero", type: String, dataType: "string" },
  Série: { prop: "serie", type: String, dataType: "string" },
  Emissão: { prop: "dataEmissao", type: colunaUtcParaData, dataType: "date" },
  Remetente: { prop: "fornecedor", type: String, dataType: "string" },
  Quantidade: {
    prop: "quantidade",
    type: colunaParaNumero,
    dataType: "number",
  },
  "Unidade de medida da quantidade": {
    prop: "unidade",
    type: String,
    dataType: "string",
  },
  "Base de cálculo do ICMS": {
    prop: "baseCalculoIcms",
    type: colunaParaNumero,
    dataType: "number",
  },
  "Alíquota do ICMS": {
    prop: "aliquotaIcms",
    type: colunaParaPercentual,
    dataType: "number",
  },
  "Valor do ICMS": {
    prop: "valorIcms",
    type: colunaParaNumero,
    dataType: "number",
  },
  "Valor do ICMS ST": {
    prop: "valorIcmsSt",
    type: colunaParaNumero,
    dataType: "number",
  },
  "Valor do ICMS do frete": {
    prop: "valorIcmsFrete",
    type: colunaParaNumero,
    dataType: "number",
  },
  "Valor do ICMS DIFAL": {
    prop: "valorIcmsDifal",
    type: colunaParaNumero,
    dataType: "number",
  },
  "Parcelas *": {
    prop: "parcelas",
    type: colunaParaNumero,
    dataType: "number",
  },
  "Parcelas apropriadas *": {
    prop: "parcelasApropriadas",
    type: colunaParaNumero,
    dataType: "number",
  },
};

const modelos = ["55", "67"];

const schemaYup = yup.object().shape({
  codigoItem: yup.string().required().label("Item"),
  numeroDoPatrimonio: yup
    .string()
    .required()
    .max(ImobilizadoConstantes.NumeroDoPatrimonioTamanhoMaximo)
    .label("Número do patrimônio"),
  descricao: yup
    .string()
    .required()
    .max(ImobilizadoConstantes.DescricaoTamanhoMaximo)
    .label("Descrição"),
  contaContabil: yup.string().required().label("Conta contábil"),
  centroDeCustos: yup.string().required().label("Centro de custos"),
  utilizacaoDoBem: yup
    .string()
    .required()
    .max(ImobilizadoConstantes.UtilizacaoDoBemTamanhoMaximo)
    .label("Utilização do bem"),
  dataDaAquisicao: yup.date().required().label("Data de aquisição"),
  valorDaAquisicao: yup.number().min(0).required().label("Custo da aquisição"),
  valorResidual: yup
    .number()
    .min(0)
    .transform((parsedValue, originalValue) =>
      originalValue === "" ? null : parsedValue
    )
    .test(
      "valor_residual_valido",
      "Deve ser menor ou igual ao campo 'Custo da aquisição'.",
      function (valorResidual, contexto) {
        if (valorResidual) {
          const valorDaAquisicao = contexto.parent.valorDaAquisicao as number;
          return valorResidual <= valorDaAquisicao;
        }
        return true;
      }
    )
    .label("Valor residual"),
  formaDeCalculoDaPrimeiraParcela: yup
    .string()
    .required()
    .label("Forma de cálculo da primeira parcela"),
  mesDeInicioDaDepreciacao: yup
    .string()
    .notRequired()
    .label("Mês de início da depreciação"),
  tipoDeDepreciacao: yup.string().notRequired().label("Tipo de depreciação"),
  percentualDeDepreciacao: yup
    .number()
    .notRequired()
    .min(ImobilizadoConstantes.PercentualDeDepreciacao.Minimo)
    .max(ImobilizadoConstantes.PercentualDeDepreciacao.Maximo)
    .label("Percentual (%)"),
  vidaUtilEmMeses: yup
    .number()
    .required()
    .moreThan(ImobilizadoConstantes.ParcelasEVidaUtilEmMesesYup.MaiorQue)
    .lessThan(ImobilizadoConstantes.ParcelasEVidaUtilEmMesesYup.MenorQue)
    .integer()
    .test(
      "vida_util_valida_em_depreciacao_anual",
      'Quando o tipo da depreciação for "Anual", a vida útil deve ser um múltiplo de 12.',
      function (vidaUtilEmMeses, contexto) {
        const tipo = contexto.parent.tipoDeDepreciacao as TipoDeDepreciacao;
        if (vidaUtilEmMeses && tipo === TipoDeDepreciacao.Anual) {
          return vidaUtilEmMeses % 12 === 0;
        }
        return true;
      }
    )
    .label("Vida útil (em meses)"),
  contaDeDebitoDaDepreciacao: yup
    .string()
    .required()
    .label("Conta contábil de débito"),
  centroDeCustosDeDebitoDaDepreciacao: yup
    .string()
    .label("Centro de custos de débito"),
  contaDeCreditoDaDepreciacao: yup
    .string()
    .required()
    .label("Conta contábil de crédito"),
  centroDeCustosDeCreditoDaDepreciacao: yup
    .string()
    .label("Centro de custos de crédito"),
  quantidadeInicialDeParcelasDepreciadas: yup
    .number()
    .integer()
    .required()
    .moreThan(ImobilizadoConstantes.ParcelasEVidaUtilEmMesesYup.MaiorQue)
    .lessThan(ImobilizadoConstantes.ParcelasEVidaUtilEmMesesYup.MenorQue)
    .label("Quantidade inicial de parcelas depreciadas"),
  valorInicialDepreciado: yup.number().min(0).label("Valor depreciado inicial"),
  sujeitoAoCiap: yup.boolean().required().label("Sujeito ao CIAP"),
  numeroNotaRecebida: yup.number().label("Número da nota fiscal recebida"),
  itemNotaRecebida: yup.number().label("Item da nota fiscal recebida"),
  documentoFiscal: yup
    .string()
    .when("sujeitoAoCiap", {
      is: true,
      then: (sch) => {
        return sch.max(ImobilizadoConstantes.NumeroTamanhoMaximo);
      },
      otherwise: (sch) => {
        return sch;
      },
    })
    .label("Documento fiscal"),
  chaveAcesso: yup
    .string()
    .when(["sujeitoAoCiap", "tipoDeDocumentoFiscalId"], (valores, schema) => {
      const [suj, mod] = valores;

      if (!suj) {
        return schema;
      }

      if (modelos.some((x) => x == mod)) {
        return schema.max(ImobilizadoConstantes.ChaveDeAcessoTamanhoMaximo);
      } else {
        return schema.max(ImobilizadoConstantes.ChaveDeAcessoTamanhoMaximo);
      }
    })
    .label("Chave de acesso"),
  numero: yup
    .string()
    .when("sujeitoAoCiap", {
      is: true,
      then: (sch) => {
        return sch.max(ImobilizadoConstantes.NumeroTamanhoMaximo);
      },
      otherwise: (sch) => {
        return sch;
      },
    })
    .label("Número"),
  serie: yup
    .string()
    .when("sujeitoAoCiap", {
      is: true,
      then: (sch) => {
        return sch.max(ImobilizadoConstantes.SerieTamanhoMaximo);
      },
      otherwise: (sch) => {
        return sch;
      },
    })
    .label("Série"),
  dataEmissao: yup
    .date()
    .when("sujeitoAoCiap", {
      is: true,
      then: (sch) => {
        return sch;
      },
      otherwise: (sch) => {
        return sch;
      },
    })
    .label("Emissão"),
  fornecedor: yup
    .string()
    .when("sujeitoAoCiap", {
      is: true,
      then: (sch) => {
        return sch;
      },
      otherwise: (sch) => {
        return sch;
      },
    })
    .label("Remetente"),
  quantidade: yup.number().min(0).label("Quantidade"),
  unidade: yup.string().label("Unidade de medida da quantidade"),
  baseCalculoIcms: yup
    .number()
    .when("sujeitoAoCiap", {
      is: true,
      then: (sch) => {
        return sch.min(0);
      },
      otherwise: (sch) => {
        return sch;
      },
    })
    .label("Base de cálculo do ICMS"),
  aliquotaIcms: yup
    .number()
    .when("sujeitoAoCiap", {
      is: true,
      then: (sch) => {
        return sch
          .min(ImobilizadoConstantes.AliquotaICMS.Minimo)
          .max(ImobilizadoConstantes.AliquotaICMS.Maximo);
      },
      otherwise: (sch) => {
        return sch;
      },
    })
    .label("Alíquota do ICMS"),
  valorIcms: yup
    .number()
    .when("sujeitoAoCiap", {
      is: true,
      then: (sch) => {
        return sch.min(0);
      },
      otherwise: (sch) => {
        return sch;
      },
    })
    .label("Valor do ICMS DIFAL"),
  valorIcmsSt: yup
    .number()
    .when("sujeitoAoCiap", {
      is: true,
      then: (sch) => {
        return sch.min(0);
      },
      otherwise: (sch) => {
        return sch;
      },
    })
    .label("Valor do ICMS ST"),
  valorIcmsFrete: yup
    .number()
    .when("sujeitoAoCiap", {
      is: true,
      then: (sch) => {
        return sch.min(0);
      },
      otherwise: (sch) => {
        return sch;
      },
    })
    .label("Valor do ICMS do frete"),
  valorIcmsDifal: yup
    .number()
    .when("sujeitoAoCiap", {
      is: true,
      then: (sch) => {
        return sch.min(0);
      },
      otherwise: (sch) => {
        return sch;
      },
    })
    .label("Valor do ICMS DIFAL"),
  parcelas: yup
    .number()
    .when("sujeitoAoCiap", {
      is: true,
      then: (sch) => {
        return sch
          .required()
          .moreThan(ImobilizadoConstantes.ParcelasEVidaUtilEmMesesYup.MaiorQue)
          .lessThan(ImobilizadoConstantes.ParcelasEVidaUtilEmMesesYup.MenorQue)
          .integer();
      },
      otherwise: (sch) => {
        return sch;
      },
    })
    .label("Parcelas"),
  parcelasApropriadas: yup
    .number()
    .when("sujeitoAoCiap", {
      is: true,
      then: (sch) => {
        return sch
          .required()
          .moreThan(ImobilizadoConstantes.ParcelasEVidaUtilEmMesesYup.MaiorQue)
          .lessThan(ImobilizadoConstantes.ParcelasEVidaUtilEmMesesYup.MenorQue)
          .integer()
          .test(
            "parcela_apropriada_valida",
            "Deve ser menor ou igual ao campo 'Parcelas'.",
            function (parcelaApropriada, contexto) {
              if (parcelaApropriada) {
                const parcela = contexto.parent.parcelas as number;
                return parcelaApropriada <= parcela;
              }
              return true;
            }
          );
      },
      otherwise: (sch) => {
        return sch;
      },
    })
    .label("Parcelas apropriadas"),
});

export interface ModalImportacaoExcelProps {
  visivel: boolean;
  onFechar: () => void;
  onImportar: (resultado: ResponseModel<InserirEmMassaResponse[]>) => void;
}

export default function ModalImportacaoExcel({
  visivel,
  onFechar,
  onImportar,
}: ModalImportacaoExcelProps) {
  const handleImportarExcel = useCallback(
    async (registros: ImobilizadoInserirVariosExcelRequest[]) => {
      const resultado = await ImobilizadoServico.InserirEmMassaExcel(registros);
      onImportar(resultado);
      return resultado;
    },
    [onImportar]
  );

  return (
    <ImportadorDeRegistros
      titulo="Importar imobilizado de arquivo Excel"
      onFechar={onFechar}
      nomeDaPlanilha="Imobilizado"
      schema={schema}
      visivel={visivel}
      onImportar={handleImportarExcel}
      modeloUrl={"../modelosXlsx/Planilha_Modelo_Imobilizado_MAXIPROD.xlsx"}
      schemaYup={schemaYup}
    />
  );
}
