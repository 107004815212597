import { DateRangeBox, SelectBox } from "devextreme-react";
import { IDateRangeBoxOptions } from "devextreme-react/date-range-box";
import Gantt, {
  Column,
  IGanttOptions,
  Item,
  Tasks,
  Toolbar,
} from "devextreme-react/gantt";
import { ISelectBoxOptions } from "devextreme-react/select-box";
import { memo, useCallback, useMemo, useState } from "react";
import criarNameof from "../../../../../utils/common/cria-name-of";
import { DataSourceOpcoes } from "../../../../../utils/grid/data-source-factory";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import { OrdemDeProducaoGridModel } from "../../models/ordem-de-producao.api";
import { OrdemDeProducaoServico } from "../../servicos/ordem-de-producao.service";
import { OrdemDeProducaoEstadoAsCellRender } from "../grid-padrao";

const nameOfGridHandler = criarNameof<OrdemDeProducaoGridModel>();

const scaleTypes = [
  "auto",
  "minutes",
  "hours",
  "days",
  "weeks",
  "months",
  "quarters",
  "years",
];
export const scaleTypeLabel = { "aria-label": "Scale Type" };
const scaleType: IGanttOptions["scaleType"] = "months";
const now = new Date();

interface GanttConfig {
  scaleType: IGanttOptions["scaleType"];
  startDateRange: Date;
  endDateRange: Date;
}

const initialGanttConfig: GanttConfig = {
  scaleType,
  startDateRange: new Date(now.getFullYear() - 1, now.getMonth(), 1),
  endDateRange: new Date(now.getFullYear() + 1, now.getMonth(), 1),
};

export const dadosParaCarregar: DataSourceOpcoes<OrdemDeProducaoGridModel> = {
  camposRetorno: [
    "id",
    "numero",
    "itemId",
    "itemCodigo",
    "itemDescricao",
    "quantidade",
    "itemUnidadePrincipalCodigo",
    "estadoDecodificado",
    "estado",
    "inicioPrevistoData",
    "fimPrevistoData",
  ],
  camposFiltro: [
    {
      campo: "estadoDecodificado",
      operador: "=",
      valor: "A produzir",
    },
  ],
  camposOrdenacao: [{ campo: "numero", desc: true }],
};

export default function GanttOrdemDeProducao() {
  const dataSource = useMemo(
    () =>
      OrdemDeProducaoServico.ObterDataSourceParaSelectBoxLazy<OrdemDeProducaoGridModel>(
        dadosParaCarregar
      ),
    []
  );

  const [ganttConfig, setGanttConfig] = useState(initialGanttConfig);
  const updateGanttConfig = useCallback(
    (value: Partial<typeof initialGanttConfig>) =>
      setGanttConfig((prevConfig) => ({
        ...prevConfig,
        ...value,
      })),
    []
  );

  const onScaleTypeChanged: ISelectBoxOptions["onValueChanged"] = useMemo(
    () =>
      ({ value }) =>
        updateGanttConfig({ scaleType: value }),
    [updateGanttConfig]
  );

  const onDateRangeChanged: IDateRangeBoxOptions["onValueChanged"] = useMemo(
    () =>
      ({ value }) =>
        updateGanttConfig({
          startDateRange: value[0] as Date,
          endDateRange: value[1] as Date,
        }),
    [updateGanttConfig]
  );

  return (
    <>
      <Gantt
        sorting={{ mode: "multiple" }}
        headerFilter={{ visible: true }}
        filterRow={{ visible: true }}
        taskTitlePosition="outside"
        scaleTypeRange={{ min: "hours" }}
        scaleType={ganttConfig.scaleType}
        startDateRange={ganttConfig.startDateRange}
        endDateRange={ganttConfig.endDateRange}
        height={"calc(100vh - 8em)"}
        taskListWidth={500}
      >
        <Tasks
          dataSource={dataSource}
          startExpr={nameOfGridHandler("inicioPrevistoData")}
          endExpr={nameOfGridHandler("fimPrevistoData")}
          titleExpr={nameOfGridHandler("numero")}
          progressExpr={nameOfGridHandler("quantidade")}
          keyExpr={nameOfGridHandler("id")}
        />

        <ToolbarGantt
          key="ToolbarGantt"
          ganttConfig={ganttConfig}
          onScaleTypeChanged={onScaleTypeChanged}
          onDateRangeChanged={onDateRangeChanged}
        />

        <Column
          key={nameOfGridHandler("numero")}
          dataField={nameOfGridHandler("numero")}
          {...obterConfiguracaoColuna("codigo")}
          caption="Número"
          width={100}
          alignment="right"
          sortOrder="desc"
          sortIndex={0}
        />
        <Column
          key={nameOfGridHandler("itemCodigo")}
          dataField={nameOfGridHandler("itemCodigo")}
          {...obterConfiguracaoColuna("stringM")}
          caption="Item"
        />
        <Column
          key={nameOfGridHandler("itemDescricao")}
          dataField={nameOfGridHandler("itemDescricao")}
          {...obterConfiguracaoColuna("stringXG")}
          caption="Descrição"
        />
        <Column
          key={nameOfGridHandler("quantidade")}
          dataField={nameOfGridHandler("quantidade")}
          {...obterConfiguracaoColuna("quantidade")}
          width={90}
        />
        <Column
          key={nameOfGridHandler("itemUnidadePrincipalCodigo")}
          dataField={nameOfGridHandler("itemUnidadePrincipalCodigo")}
          {...obterConfiguracaoColuna("stringPP")}
          caption="Unid"
          width={80}
        />
        <Column
          key={nameOfGridHandler("estadoDecodificado")}
          dataField={nameOfGridHandler("estadoDecodificado")}
          {...obterConfiguracaoColuna("stringM")}
          cellRender={OrdemDeProducaoEstadoAsCellRender}
          caption="Estado"
          width={100}
        />
        <Column
          key={nameOfGridHandler("inicioPrevistoData")}
          dataField={nameOfGridHandler("inicioPrevistoData")}
          {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
          caption="Início"
        />
        <Column
          key={nameOfGridHandler("fimPrevistoData")}
          dataField={nameOfGridHandler("fimPrevistoData")}
          {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
          caption="Fim"
        />
      </Gantt>
    </>
  );
}

interface ToolbarGanttProps {
  ganttConfig: GanttConfig;
  onScaleTypeChanged: (e: any) => void;
  onDateRangeChanged: (e: any) => void;
}

const ToolbarGantt = memo(
  ({
    ganttConfig,
    onScaleTypeChanged,
    onDateRangeChanged,
  }: ToolbarGanttProps) => {
    return (
      <Toolbar>
        <Item key={"selectBox"}>
          <SelectBox
            label="Agrupamento"
            items={scaleTypes}
            inputAttr={scaleTypeLabel}
            value={ganttConfig.scaleType}
            onValueChanged={onScaleTypeChanged}
          />
        </Item>
        <Item key={"dateRange"}>
          <DateRangeBox
            width={300}
            defaultValue={[
              ganttConfig.startDateRange,
              ganttConfig.endDateRange,
            ]}
            onValueChanged={onDateRangeChanged}
            calendarOptions={{
              maxZoomLevel:
                ganttConfig.scaleType == "months" ||
                ganttConfig.scaleType == "quarters"
                  ? "year"
                  : ganttConfig.scaleType == "years"
                  ? "decade"
                  : undefined,
            }}
          />
        </Item>
        <Item key={"separator"} name="separator" />
        <Item key={"zoomIn"} name="zoomIn" />
        <Item key={"zoomOut"} name="zoomOut" />
        <Item key={"separator2"} name="separator" />
        <Item key={"fullScreen"} name="fullScreen" />
        <Item key={"taskDetails"} name="taskDetails" />
      </Toolbar>
    );
  }
);
