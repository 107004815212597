import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/store.hooks";
import { removerErroAccordion } from "../../../store/ui/ui.slice";
import { aguardar } from "../../../utils/common/common-utils";
import ModalAccordion from "../modal-accordion";
import { AccordionModel } from "../modal-accordion/modal-accordion";

export default function PilhaErrosAccordion() {
  const [id, setId] = useState("");
  const [modalAccordionVisivel, setModalAccordionVisivel] = useState(false);
  const [model, setModel] = useState<AccordionModel[]>([]);
  const topoDaPilha = useAppSelector((state) =>
    state.estadoUI.errosAccordion.length > 0
      ? state.estadoUI.errosAccordion[0]
      : null
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (topoDaPilha?.id != undefined && id != topoDaPilha.id) {
      setId(topoDaPilha.id);
      setModel(topoDaPilha.model);
      setModalAccordionVisivel(true);
    }
  }, [topoDaPilha, id]);

  const handlerFecharModalAccordion = useCallback(() => {
    aguardar(100).then(() => {
      setModalAccordionVisivel(false);
      dispatch(removerErroAccordion(id));
    });
  }, [id, dispatch, setModalAccordionVisivel]);

  return (
    <ModalAccordion
      modalTitulo="Atenção"
      modalAccordionVisivel={modalAccordionVisivel}
      dataSource={model}
      accordionId={`Ac_${id}`}
      handlerFecharModalAccordion={handlerFecharModalAccordion}
    />
  );
}
