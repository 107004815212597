import DataGrid, { Column, DataGridRef } from "devextreme-react/data-grid";
import ArrayStore from "devextreme/data/array_store";
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import ProvedorAjuda from "../../../../../../components/ajuda/provedor-ajuda";
import GridColunaAcoes from "../../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import TituloGrid from "../../../../../../components/texto/titulo-grid";
import { GridMxpProps } from "../../../../../../models/shared/ui/grid";
import { GridCarregamentoViewModel } from "../../../../../../models/viewmodels/vendas/mdfe/mdfe-edit-form-view-model";
import exibirNotificacaoToast, {
  MensagensParaNotificacao,
  TipoNotificacao,
} from "../../../../../../utils/common/notificacoes-utils";
import GridBuilder from "../../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../../utils/grid/padroes-colunas";
import MDFeEditFormContext from "../../../contexts/mdfe-editform.context";
import ModalAdicionarCarregamento from "./modal-adicionar-carregamento";

interface GridEmbutidaCarregamentosProps
  extends GridMxpProps<GridCarregamentoViewModel> {
  somenteLeitura?: boolean;
}

export default function GridCarregamentos(
  props: GridEmbutidaCarregamentosProps
) {
  const gridRef = useRef<DataGridRef>(null);

  const [modalAddCarregamentoVisivel, setModalAddCarregamentoVisivel] =
    useState(false);

  const { carregamentos, removerCarregamento, baseMdfe } =
    useContext(MDFeEditFormContext);

  const modelCarregamentos = useMemo(() => {
    return carregamentos.map((c) => {
      return {
        id: c.id,
        municipio: c.municipio?.nome ?? "",
        idMunicipio: c.idMunicipio,
        ordem: c.ordem,
        uf: c.municipio?.uf ?? "",
      } as GridCarregamentoViewModel;
    });
  }, [carregamentos]);

  const dataSourceCarregamentos = useMemo(
    () => new ArrayStore({ data: modelCarregamentos, key: "municipioId" }),
    [modelCarregamentos]
  );

  const novoRegistroCarregamento = useCallback(() => {
    setModalAddCarregamentoVisivel(true);
  }, [setModalAddCarregamentoVisivel]);

  function excluirRegistro(
    carregamento: GridCarregamentoViewModel | undefined
  ) {
    if (carregamento) {
      removerCarregamento(carregamento);

      exibirNotificacaoToast({
        mensagem: MensagensParaNotificacao.ExcluidoComSucesso,
        tipo: TipoNotificacao.Sucesso,
      });
    }
  }

  const gridController = new GridController<GridCarregamentoViewModel>(() =>
    gridRef.current?.instance()
  );

  const configuracoesGrid = useMemo(() => {
    let builder = GridBuilder.criar(
      "mdfe-lista-carregamentos",
      () => gridRef.current?.instance(),
      true
    )
      .definirStyles({ height: "100%" })
      .definirDataSource(dataSourceCarregamentos)
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .configurarExportacao("Carregamentos")
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirOrdenacao();

    if (!props.somenteLeitura) {
      builder = builder.definirBotaoNovo(novoRegistroCarregamento);
    }

    return builder.build();
  }, [dataSourceCarregamentos, props.somenteLeitura]);

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  return (
    <>
      <TituloGrid texto="Dados do carregamento" />

      <ProvedorAjuda id={"ajuda-mdfe-lista-carregamentos"}>
        <DataGrid ref={gridRef} {...configuracoesGrid}>
          {GridColunaAcoes<GridCarregamentoViewModel>({
            handleExcluir: props.somenteLeitura ? undefined : excluirRegistro,
          })}
          <Column
            key="ordem"
            dataField="ordem"
            caption="Ordem"
            dataType="number"
            allowResizing
            width={70}
          />
          ,
          <Column
            key="municipio"
            dataField="municipio"
            caption="Município do carregamento"
            allowResizing
            width={70}
            {...obterConfiguracaoColuna("stringG")}
          />
          ,
          <Column
            key="uf"
            dataField="uf"
            caption="UF"
            allowResizing
            dataType="string"
            width={70}
          />
          ,
        </DataGrid>
      </ProvedorAjuda>

      <div>
        <ModalAdicionarCarregamento
          idRegistroEmEdicao={baseMdfe.id}
          visivel={modalAddCarregamentoVisivel}
          handleCallback={() => {
            setModalAddCarregamentoVisivel(false);
          }}
        />
      </div>
    </>
  );
}
