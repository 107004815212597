import getApi from "../../../configs/api";
import {
  IdStringResponse,
  ResponseModel,
} from "../../../models/api/comum/response-base";
import { NomesEndpoints } from "../../comum/nomesEndpoints";
import { ServiceBase } from "../../comum/serviceBase";

class CentroDeCustosService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.CentroDeCustos);
  }

  public async obterIdsPorCodigos(codigos: string[]) {
    const api = getApi();
    const response = await api.post<ResponseModel<IdStringResponse[]>>(
      `${this._nomeEndpoint}/ObterIdsPorCodigos`,
      Array.from(new Set(codigos))
    );

    const map = {} as Record<string, number>;
    if (!response.data.sucesso) {
      return map;
    }
    for (const x of response.data.model) {
      map[x.valor] = x.id;
    }
    return map;
  }
}

export const CentroDeCustosServico = new CentroDeCustosService();
