import { PropsWithChildren, useState } from "react";
import ContextoOperacoesInsumo, {
  DetalhamentoEstoqueInsumo,
  FuncoesColunaQuantidadeABaixarInsumo,
  ReferenciasGridsSelecaoInsumo,
} from "../contexto-funcoes-insumo";

export function ProvedorInsumo(props: PropsWithChildren<any>) {
  const [funcoesColunaQuantidadeABaixarInsumo] =
    useState<FuncoesColunaQuantidadeABaixarInsumo>(
      new FuncoesColunaQuantidadeABaixarInsumo()
    );

  const [referenciasGridInsumo] = useState<ReferenciasGridsSelecaoInsumo>(
    new ReferenciasGridsSelecaoInsumo()
  );

  const [detalhamentoEstoqueInsumo] = useState<DetalhamentoEstoqueInsumo>(
    new DetalhamentoEstoqueInsumo()
  );
  return (
    <ContextoOperacoesInsumo.Provider
      value={{
        funcoes: funcoesColunaQuantidadeABaixarInsumo,
        referenciasGridInsumos: referenciasGridInsumo,
        detalhamentoEstoqueInsumo: detalhamentoEstoqueInsumo,
      }}
    >
      {props.children}
    </ContextoOperacoesInsumo.Provider>
  );
}
