import { DataGrid } from "devextreme-react";
import { Column, DataGridRef } from "devextreme-react/data-grid";
import { useCallback, useEffect, useMemo, useRef } from "react";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import GridColunaAcoes from "../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import { useRegistrarAtalhosGrid } from "../../../../../hooks/atalhos.hooks";
import { useControlarFormDeEdicao } from "../../../../../hooks/form.hooks";
import { useMenuDeContextosGrid } from "../../../../../hooks/menus.hooks";
import { useParametroId } from "../../../../../hooks/route.hooks";
import { useSeletorGridMxp } from "../../../../../hooks/seletor.hooks";
import { decodificaBooleanoEmAtivoInativo } from "../../../../../models/api/comum/decodificadores";
import { ResultadoAcaoFormulario } from "../../../../../models/shared/ui/formularios";
import { GridMxpProps } from "../../../../../models/shared/ui/grid";
import GetColunasDeAuditoria from "../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import {
  checarResponseExibeMensagemExclusaoDeSucesso,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesModais from "../../../../../utils/common/nomes-modais";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import NormalizaTituloModal from "../../../../../utils/common/normaliza-titulo";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import UrlUtils from "../../../../../utils/url/url-utils";
import { EstadoConfiguravelDaOrdemDeProducaoGridModel } from "../../models/estado-configuravel-da-ordem-de-producao";
import { PermissoesEstadoConfiguravelDaOrdemDeProducao } from "../../permissoes/permissoes-estado-configuravel-da-ordem-de-producao";
import { EstadoConfiguravelDaOrdemDeProducaoServico } from "../../services/estado-configuravel-da-ordem-de-producao.service";
import FormEstadoConfiguravelDaOrdemDeProducao from "../formulario";

const nameOfGridHandler =
  criarNameof<EstadoConfiguravelDaOrdemDeProducaoGridModel>();

export default function GridEstadoConfiguravelDaOrdemDeProducao(
  props: GridMxpProps<EstadoConfiguravelDaOrdemDeProducaoGridModel>
) {
  const gridRef = useRef<DataGridRef>(null);
  const parametroId = useParametroId();

  const {
    idRegistroEmEdicao,
    setIdRegistroEmEdicao,
    modalVisivel,
    encerrarEdicao,
  } = useControlarFormDeEdicao(NaN);

  useSeletorGridMxp(() => gridRef.current, props.filtrosNoCliente);

  const dataSource =
    EstadoConfiguravelDaOrdemDeProducaoServico.ObterDataSourceParaGrid<EstadoConfiguravelDaOrdemDeProducaoGridModel>(
      props.filtrosNoServidor
    );

  useEffect(() => {
    if (parametroId || parametroId === 0) {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesEstadoConfiguravelDaOrdemDeProducao.InserirEditar,
        ])
      ) {
        return;
      }

      setIdRegistroEmEdicao(parametroId);
    }
  }, []);

  const handleNovoRegistro = useCallback(() => {
    if (
      verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesEstadoConfiguravelDaOrdemDeProducao.InserirEditar,
      ])
    ) {
      setIdRegistroEmEdicao(0);
    }
  }, []);

  const handleEditarRegistro = useCallback(
    (registro: EstadoConfiguravelDaOrdemDeProducaoGridModel) => {
      if (
        verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesEstadoConfiguravelDaOrdemDeProducao.InserirEditar,
        ])
      ) {
        setIdRegistroEmEdicao(registro.id);
      }
    },
    []
  );

  const handleExcluirRegistro = useCallback(
    async (registro: EstadoConfiguravelDaOrdemDeProducaoGridModel) => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesEstadoConfiguravelDaOrdemDeProducao.Excluir,
        ])
      ) {
        return;
      }

      const excluir = await exibirConfirmacao(
        "Confirmar exclusão",
        obterMensagemExclusao(registro)
      );

      if (excluir) {
        try {
          const resposta =
            await EstadoConfiguravelDaOrdemDeProducaoServico.Excluir(
              registro.id
            );

          if (resposta) {
            checarResponseExibeMensagemExclusaoDeSucesso(resposta);
            handleAtualizarGrid();
          }
        } catch (erro) {
          tratarErroApi(erro);
        }
      }
    },
    []
  );

  function obterMensagemExclusao(
    registro: EstadoConfiguravelDaOrdemDeProducaoGridModel
  ) {
    return `Tem certeza de que deseja excluir o lote ${registro.descricao}?`;
  }

  const gridController = useMemo(
    () =>
      new GridController<EstadoConfiguravelDaOrdemDeProducaoGridModel>(() =>
        gridRef.current?.instance()
      ),
    []
  );

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  const handleCallbackFormulario = useCallback(
    (resultado: ResultadoAcaoFormulario) => {
      encerrarEdicao();

      if (resultado == ResultadoAcaoFormulario.AcaoConcluida) {
        handleAtualizarGrid();
      }

      UrlUtils.RemoverParametroId(parametroId);
    },
    [parametroId]
  );

  const handleFecharModal = useCallback(() => {
    encerrarEdicao();
    UrlUtils.RemoverParametroId(parametroId);
  }, [parametroId]);

  useRegistrarAtalhosGrid<EstadoConfiguravelDaOrdemDeProducaoGridModel>({
    controller: gridController,
    handleNovo: handleNovoRegistro,
    handleEditar: handleEditarRegistro,
    handleExcluir: handleExcluirRegistro,
  });

  const menuContexto = useMenuDeContextosGrid([], true, gridController);

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar(
      "estado-configuravel-da-ordem-de-producao",
      () => gridRef.current?.instance(),
      false,
      props.filtrosNoCliente
    )
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(NomesTelas.estadoConfiguravelDaOrdemDeProducao)
      .definirBotaoNovo(handleNovoRegistro)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirMenuDeContexto(menuContexto)
      .definirSelecao()
      .definirOrdenacao()
      .definirDuploCliqueLinha(handleEditarRegistro)
      .build();
  }, [props.filtrosNoCliente, menuContexto]);

  return (
    <>
      <ProvedorAjuda id={"tooltips-grid-estado-configuravel-ordem-producao"}>
        <DataGrid ref={gridRef} {...configuracoesGrid}>
          <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
          {GridColunaAcoes<EstadoConfiguravelDaOrdemDeProducaoGridModel>({
            handleEditar: handleEditarRegistro,
            handleExcluir: handleExcluirRegistro,
          })}
          <Column
            key={nameOfGridHandler("descricao")}
            dataField={nameOfGridHandler("descricao")}
            {...obterConfiguracaoColuna("stringGG")}
            caption="Descrição"
          />
          <Column
            key={nameOfGridHandler("observacoes")}
            dataField={nameOfGridHandler("observacoes")}
            {...obterConfiguracaoColuna("stringXXG")}
            caption="Observações"
          />
          <Column
            key={nameOfGridHandler("ordem")}
            dataField={nameOfGridHandler("ordem")}
            {...obterConfiguracaoColuna("ordem")}
            caption="Ordem"
          />
          <Column
            key={nameOfGridHandler("ativo")}
            dataField={nameOfGridHandler("ativo")}
            {...obterConfiguracaoColuna("boolAtivoInativo")}
            caption="Ativo"
            visible={true}
            cellRender={({ data }) => {
              const dados =
                data as EstadoConfiguravelDaOrdemDeProducaoGridModel;
              return decodificaBooleanoEmAtivoInativo(dados.ativo);
            }}
          />
          {GetColunasDeAuditoria(false, false, [
            { ordenarPor: "criacaoData", ordem: "desc" },
          ])}
        </DataGrid>
      </ProvedorAjuda>

      <ModalMxp
        titulo={NormalizaTituloModal.Normalizar(
          idRegistroEmEdicao,
          NomesModais.estadoConfiguravelDaOrdemDeProducao
        )}
        idRegistro={idRegistroEmEdicao}
        visivel={modalVisivel}
        handleFechar={handleFecharModal}
        largura={"max(40vw, 800px)"}
        altura={"auto"}
      >
        <FormEstadoConfiguravelDaOrdemDeProducao
          idRegistroEmEdicao={idRegistroEmEdicao}
          handleCallback={handleCallbackFormulario}
        />
      </ModalMxp>
    </>
  );
}
