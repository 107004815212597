import PaginaMxp from "../../../../../components/layout/pagina-mxp";
import ProvedorMenus from "../../../../../components/menus/provedor-menus";
import RequerPermissao from "../../../../../components/seguranca/requer-permissao";
import { PermissoesCentroDeTrabalho } from "../../../../../models/permissoes/producao/centro-de-trabalho/permissoes-centro-de-trabalho";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import { PageProps } from "../../../../../utils/common/page-utils";
import FiltrosGridCentroDeTrabalho from "../../../../../utils/filtros-grid/producao/centro-de-trabalho/filtros-grid-centro-de-trabalho";
import GridCentroDeTrabalho from "../../componentes/grid";

export default function CentroDeTrabalhoPage(props: PageProps) {
  return (
    <ProvedorMenus>
      <PaginaMxp
        id="centros-trabalho-page"
        titulo={NomesTelas.centrosDeTrabalho}
        area={["Produção"]}
      >
        <RequerPermissao
          codigoPermissoes={[PermissoesCentroDeTrabalho.Consultar]}
        >
          <GridCentroDeTrabalho
            abrirModalNovoRegistro={props.abrirModalNovoRegistro}
            filtrosNoCliente={FiltrosGridCentroDeTrabalho.centroDeTrabalhoAtivo}
          />
        </RequerPermissao>
      </PaginaMxp>
    </ProvedorMenus>
  );
}
