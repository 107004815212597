import styled from "styled-components";

export const ContainerFormMxp = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ContainerFormMDFe = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .dx-tabpanel {
    min-height: 10%;
    flex: 1;
    height: 100%;
  }
`;
