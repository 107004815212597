import { yupResolver } from "@hookform/resolvers/yup";
import { TabPanel } from "devextreme-react";
import { Item } from "devextreme-react/tab-panel";
import _ from "lodash";
import { createContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import BotaoCancelarMxp from "../../../../../components/botoes/botao-cancelar-mxp";
import BotaoSalvarMxp from "../../../../../components/botoes/botao-salvar-mxp";
import {
  FormSelectBox,
  FormSelectBoxLazyMxp,
  FormTextBox,
} from "../../../../../components/formularios";
import { assertConfiguracaoExibicaoEBuscaType } from "../../../../../components/formularios/selectbox-lazy-mxp";
import FormMxp from "../../../../../components/layout/form";
import { ContainerFormMxp } from "../../../../../components/layout/form/styles";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import TabContainer from "../../../../../components/layout/tab-container";
import ToolbarMxp from "../../../../../components/layout/toolbar-mxp";
import ComboContaContabilMxp from "../../../../../components/mxp/inputs-custom/conta-contabil";
import { QuebrarLinhas } from "../../../../../components/texto/quebrar-linhas";
import AuditavelDTO from "../../../../../models/api/comum/auditavel-dto";
import { PermissoesContaContabil } from "../../../../../models/permissoes/contabilidade/conta-contabil/permissoes-conta-contabil-b";
import { PermissoesImobilizado } from "../../../../../models/permissoes/contabilidade/imobilizado/permissoes-imobilizado";
import { PermissoesItem } from "../../../../../models/permissoes/itens/item/permissoes-item";
import {
  IFormularioEditavelBase,
  ResultadoAcaoFormulario,
} from "../../../../../models/shared/ui/formularios";
import ImobilizadoAdapter from "../../../../../utils/adapters/contabilidade/imobilizado.adapter";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import { checarSeFormFoiTocado } from "../../../../../utils/common/form-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import { formatarNumeroMonetario } from "../../../../../utils/formatadores/formatador-de-numeros";
import {
  FormataDescricao,
  FormatadoresSelectBox,
} from "../../../../../utils/formatadores/formatador-de-selectbox";
import { DataSourceFiltragem } from "../../../../../utils/grid/data-source-factory";
import { renderToStringClient } from "../../../../../utils/react/react-utils";
import yup from "../../../../../utils/validacao/validacao";
import GridItem from "../../../../itens/item/componentes/grid";
import { ItemGridModel } from "../../../../itens/item/models/item.api";
import { ItemServico } from "../../../../itens/item/servicos/item.service";
import GridCentroDeCustos from "../../../centro-de-custos/componentes/grid";
import { CentroDeCustosGridModel } from "../../../centro-de-custos/models/centro-de-custos";
import { CentroDeCustosServico } from "../../../centro-de-custos/servicos/centro-de-custos.service";
import { ImobilizadoResponse } from "../../models/imobilizado";
import ImobilizadoConstantes from "../../models/imobilizado.constantes";
import {
  EstadoDoImobilizado,
  FormaDeCalculoDaPrimeiraParcela,
  MesDeInicioDaDepreciacao,
  SelectItemEnumEstadoDoImobilizado,
  TipoDeDepreciacao,
} from "../../models/imobilizado.enums";
import {
  CiapDoImobilizadoViewModel,
  ImobilizadoViewModel,
} from "../../models/imobilizado.viewmodel";
import { ImobilizadoServico } from "../../servicos/imobilizado.service";
import AbaCiapImobilizado from "../abas-formulario/aba-ciap";
import AbaDepreciacaoImobilizado from "../abas-formulario/aba-depreciacao";

const novoRegistro: ImobilizadoViewModel = {
  id: 0,
  itemId: null,
  estado: EstadoDoImobilizado.Ativo,
  numeroDoPatrimonio: "",
  descricao: "",
  descricaoEstaFixa: false,
  valorDaAquisicao: 0,
  dataDaAquisicao: "",
  contaContabilId: null,
  utilizacaoDoBem: "",
  centroDeCustosId: null,
  contaDeDebitoDaDepreciacaoId: null,
  centroDeCustosDeDebitoDaDepreciacaoId: null,
  contaDeCreditoDaDepreciacaoId: null,
  centroDeCustosDeCreditoDaDepreciacaoId: null,
  percentualDeDepreciacao: null,
  tipoDeDepreciacao: null,
  vidaUtilEmMeses: 0,
  valorParcelaDepreciacao: 0,
  valorResidual: 0,
  valorInicialDepreciado: 0,
  quantidadeInicialDeParcelasDepreciadas: 0,
  valorDepreciadoPorLancamentoContabil: 0,
  quantidadeParcelasDepreciadasPorLancamentoContabil: 0,
  dataUltimaDepreciacao: null,
  mesDeInicioDaDepreciacao: MesDeInicioDaDepreciacao.MesDaAquisicao,
  formaDeCalculoDaPrimeiraParcela: FormaDeCalculoDaPrimeiraParcela.ProRata,
  sujeitoAoCiap: false,
  ciapDoImobilizado: {
    itemNotaFiscalId: null,
    parcelas: 0,
    parcelasApropriadas: 0,
    tipoDeDocumentoFiscalId: null,
    fornecedorId: null,
    numero: "",
    serie: null,
    chaveAcesso: null,
    dataEmissao: "",
    quantidade: 0,
    unidadeId: null,
    baseCalculoIcms: 0,
    aliquotaIcms: null,
    valorIcms: null,
    valorIcmsSt: null,
    valorIcmsFrete: null,
    valorIcmsDifal: null,
  },
};

let dadosAuditoria: AuditavelDTO | undefined = undefined;

const centroDeCustosDataSource =
  CentroDeCustosServico.ObterDataSourceParaSelectBoxLazy<CentroDeCustosGridModel>(
    {
      camposRetorno: ["id", "codigo", "descricao", "classificacao"],
      camposOrdenacao: [{ campo: "classificacao", desc: false }],
    }
  );

const configuracoesExibicaoSelectCentroCusto =
  assertConfiguracaoExibicaoEBuscaType<CentroDeCustosGridModel>({
    expressaoDeBusca: ["id", "codigo", "descricao", "classificacao"],
    nomeCampoChave: "id",
    nomeCampoExibicao: (c) => {
      if (c) {
        return FormataDescricao(
          FormatadoresSelectBox.ClassificacaoCodigoDescricaoEspacoParenteses,
          c.classificacao,
          c.descricao,
          c.codigo
        );
      }

      return "";
    },
  });

const configuracaoExibicaoEBuscaItem =
  assertConfiguracaoExibicaoEBuscaType<ItemGridModel>({
    nomeCampoChave: "id",
    expressaoDeBusca: ["codigo", "descricao"],
    nomeCampoExibicao: (c) => {
      if (c) {
        return FormataDescricao(
          FormatadoresSelectBox.CodigoDescricaoParenteses,
          c.codigo,
          c.descricao
        );
      }

      return "";
    },
  });

const filtroPadraoParaItem: DataSourceFiltragem<ItemGridModel>[] = [
  { campo: "estado", operador: "=", valor: "Ativo" },
];

const itemDataSource =
  ItemServico.ObterDataSourceParaSelectBoxLazy<ItemGridModel>({
    camposRetorno: ["id", "codigo", "descricao"],
    camposOrdenacao: [{ campo: "codigo", desc: false }],
    camposFiltro: filtroPadraoParaItem,
  });

interface ContextoModelosDocumentos55e67CadastroImobilizadoProps {
  modelos: number[];
  setModelos: (valores: number[]) => void;
}

export const ContextoModelosDocumentos55e67CadastroImobilizado =
  createContext<ContextoModelosDocumentos55e67CadastroImobilizadoProps>({
    modelos: [],
    setModelos: () => {
      /*default*/
    },
  });

export default function EditFormImobilizado(props: IFormularioEditavelBase) {
  const [modelos, setModelos] = useState<number[]>([]);
  const [descricaoFoiPersonalizada, setDescricaoFoiPersonalizada] =
    useState(false);

  const schema = yup
    .object()
    .shape<Record<keyof ImobilizadoViewModel, yup.AnySchema>>({
      id: yup.number().required().moreThan(-1).integer(),
      itemId: yup.number().required().moreThan(-1).integer(),
      estado: yup
        .mixed<EstadoDoImobilizado>()
        .required()
        .oneOf(
          Object.values(EstadoDoImobilizado).map((x) => x as number),
          "Valor inválido"
        ),
      numeroDoPatrimonio: yup
        .string()
        .max(ImobilizadoConstantes.NumeroDoPatrimonioTamanhoMaximo)
        .required(),
      descricao: yup
        .string()
        .max(ImobilizadoConstantes.DescricaoTamanhoMaximo)
        .required(),
      descricaoEstaFixa: yup.boolean().required(),
      valorDaAquisicao: yup.number().required().moreThan(-1),
      dataDaAquisicao: yup.string().required(),
      contaContabilId: yup.number().required().moreThan(-1).integer(),
      utilizacaoDoBem: yup
        .string()
        .max(ImobilizadoConstantes.UtilizacaoDoBemTamanhoMaximo)
        .required(),
      centroDeCustosId: yup.number().required().positive().integer(),
      contaDeDebitoDaDepreciacaoId: yup
        .number()
        .required()
        .moreThan(-1)
        .integer(),
      centroDeCustosDeDebitoDaDepreciacaoId: yup
        .number()
        .notRequired()
        .integer(),
      contaDeCreditoDaDepreciacaoId: yup
        .number()
        .required()
        .moreThan(-1)
        .integer(),
      centroDeCustosDeCreditoDaDepreciacaoId: yup
        .number()
        .notRequired()
        .integer(),
      percentualDeDepreciacao: yup
        .number()
        .notRequired()
        .min(ImobilizadoConstantes.PercentualDeDepreciacao.Minimo)
        .max(ImobilizadoConstantes.PercentualDeDepreciacao.Maximo),
      tipoDeDepreciacao: yup
        .mixed<TipoDeDepreciacao>()
        .notRequired()
        .oneOf(
          Object.values(TipoDeDepreciacao).map((x) => x as number),
          "Valor inválido"
        ),
      vidaUtilEmMeses: yup
        .number()
        .required()
        .moreThan(ImobilizadoConstantes.ParcelasEVidaUtilEmMesesYup.MaiorQue)
        .lessThan(ImobilizadoConstantes.ParcelasEVidaUtilEmMesesYup.MenorQue)
        .integer()
        .test(
          "vida_util_valida_em_depreciacao_anual",
          'Quando o tipo da depreciação for "Anual", a vida útil deve ser um múltiplo de 12.',
          function (vidaUtilEmMeses, contexto) {
            const tipo = contexto.parent.tipoDeDepreciacao as TipoDeDepreciacao;
            if (vidaUtilEmMeses && tipo === TipoDeDepreciacao.Anual) {
              return vidaUtilEmMeses % 12 === 0;
            }
            return true;
          }
        ),
      valorParcelaDepreciacao: yup
        .number()
        .required()
        .when(
          ["percentualDeDepreciacao", "tipoDeDepreciacao"],
          (values: any[], schema: yup.NumberSchema) => {
            const [percentualDeDepreciacao, tipoDeDepreciacao] = values as [
              number | null,
              TipoDeDepreciacao | null
            ];

            if (percentualDeDepreciacao && tipoDeDepreciacao) {
              const valorMinimo =
                ImobilizadoConstantes.ValoresMinimosDepreciacao[
                  tipoDeDepreciacao
                ];
              return schema.min(
                valorMinimo,
                `Para que seja possível gerar lançamentos contábeis, o valor mínimo é de R$ ${formatarNumeroMonetario(
                  valorMinimo
                )}.`
              );
            }

            return schema;
          }
        ),
      valorResidual: yup
        .number()
        .moreThan(-1)
        .test(
          "valor_residual_valido",
          "Deve ser menor ou igual ao campo 'Custo da aquisição'.",
          function (valorResidual, contexto) {
            if (valorResidual) {
              const valorDaAquisicao = contexto.parent
                .valorDaAquisicao as number;
              return valorResidual <= valorDaAquisicao;
            }
            return true;
          }
        ),
      valorInicialDepreciado: yup.number().required().moreThan(-1),
      quantidadeInicialDeParcelasDepreciadas: yup
        .number()
        .required()
        .moreThan(ImobilizadoConstantes.ParcelasEVidaUtilEmMesesYup.MaiorQue)
        .lessThan(ImobilizadoConstantes.ParcelasEVidaUtilEmMesesYup.MenorQue)
        .integer(),
      valorDepreciadoPorLancamentoContabil: yup
        .number()
        .required()
        .moreThan(-1),
      quantidadeParcelasDepreciadasPorLancamentoContabil: yup
        .number()
        .required()
        .moreThan(ImobilizadoConstantes.ParcelasEVidaUtilEmMesesYup.MaiorQue)
        .lessThan(ImobilizadoConstantes.ParcelasEVidaUtilEmMesesYup.MenorQue)
        .integer(),
      dataUltimaDepreciacao: yup.string().notRequired(),
      formaDeCalculoDaPrimeiraParcela: yup
        .mixed<FormaDeCalculoDaPrimeiraParcela>()
        .required()
        .oneOf(
          Object.values(FormaDeCalculoDaPrimeiraParcela).map(
            (x) => x as number
          ),
          "Valor inválido"
        ),
      mesDeInicioDaDepreciacao: yup
        .mixed<MesDeInicioDaDepreciacao>()
        .required()
        .oneOf(
          Object.values(MesDeInicioDaDepreciacao).map((x) => x as number),
          "Valor inválido"
        ),
      sujeitoAoCiap: yup.boolean().required(),
      ciapDoImobilizado: yup.object().when("sujeitoAoCiap", (valor, schema) => {
        const sujeitoAoCiap = valor[0] as boolean;
        if (!sujeitoAoCiap) {
          return schema;
        }
        schema = schema.shape<
          Record<keyof CiapDoImobilizadoViewModel, yup.AnySchema>
        >({
          itemNotaFiscalId: yup.number().notRequired().integer(),
          parcelas: yup
            .number()
            .required()
            .moreThan(
              ImobilizadoConstantes.ParcelasEVidaUtilEmMesesYup.MaiorQue
            )
            .lessThan(
              ImobilizadoConstantes.ParcelasEVidaUtilEmMesesYup.MenorQue
            )
            .integer(),
          parcelasApropriadas: yup
            .number()
            .required()
            .moreThan(
              ImobilizadoConstantes.ParcelasEVidaUtilEmMesesYup.MaiorQue
            )
            .lessThan(
              ImobilizadoConstantes.ParcelasEVidaUtilEmMesesYup.MenorQue
            )
            .integer()
            .test(
              "parcela_apropriada_valida",
              "Deve ser menor ou igual ao campo 'Parcelas'.",
              function (parcelaApropriada, contexto) {
                if (parcelaApropriada) {
                  const parcela = contexto.parent.parcelas as number;
                  return parcelaApropriada <= parcela;
                }
                return true;
              }
            ),
          tipoDeDocumentoFiscalId: yup
            .number()
            .required()
            .moreThan(-1)
            .integer(),
          fornecedorId: yup.number().required().moreThan(-1).integer(),
          numero: yup
            .string()
            .max(ImobilizadoConstantes.NumeroTamanhoMaximo)
            .required(),
          serie: yup
            .string()
            .max(ImobilizadoConstantes.SerieTamanhoMaximo)
            .notRequired(),
          chaveAcesso: yup
            .string()
            .max(ImobilizadoConstantes.ChaveDeAcessoTamanhoMaximo)
            .when(["tipoDeDocumentoFiscalId"], (valores, schema) => {
              if (modelos.some((x) => x == valores[0])) {
                return schema.required();
              } else {
                return schema.notRequired();
              }
            }),
          dataEmissao: yup.string().required(),
          quantidade: yup.number().required().moreThan(-1),
          unidadeId: yup.number().required().moreThan(-1).integer(),
          baseCalculoIcms: yup.number().required().moreThan(-1),
          aliquotaIcms: yup
            .number()
            .notRequired()
            .min(ImobilizadoConstantes.AliquotaICMS.Minimo)
            .max(ImobilizadoConstantes.AliquotaICMS.Maximo),
          valorIcms: yup.number().notRequired().moreThan(-1),
          valorIcmsSt: yup.number().notRequired().moreThan(-1),
          valorIcmsFrete: yup.number().notRequired().moreThan(-1),
          valorIcmsDifal: yup.number().notRequired().moreThan(-1),
        });

        return schema;
      }),
    });

  const hookForm = useForm<ImobilizadoViewModel>({
    resolver: yupResolver(schema),
  });

  const {
    register,
    control,
    handleSubmit,
    getValues,
    reset,
    setValue,
    formState,
  } = hookForm;

  async function tratarDescricao(e: any) {
    const descricaoAtual = getValues("descricao");
    const descricaoAtualEstaFixa = getValues("descricaoEstaFixa");
    const descricaoNova = e.selectedItem.descricao;

    if (descricaoAtual) {
      if (descricaoFoiPersonalizada || descricaoAtualEstaFixa) {
        await confirmarAlteracaoDaDescricao(descricaoAtual, descricaoNova);
        return;
      }
    }

    setValue("descricao", descricaoNova);
  }

  async function confirmarAlteracaoDaDescricao(atual: string, nova: string) {
    const mensagem = renderToStringClient(
      <QuebrarLinhas texto={obterMensagemAlteracaoDescricao(atual, nova)} />
    );
    const alterar = await exibirConfirmacao("Confirmar alteração", mensagem);

    if (alterar) {
      setValue("descricao", nova);
      setValue("descricaoEstaFixa", false);
    }
  }

  function obterMensagemAlteracaoDescricao(atual: string, novo: string) {
    return (
      `A descrição no cadastro do item (${novo}) é diferente da descrição nesta tela (${atual}).\n` +
      `Deseja sobrescrever a descrição em tela com a descrição do cadastro do item?`
    );
  }

  //Hook usado para carregar os dados da tela
  useEffect(() => {
    if (Number.isNaN(props.idRegistroEmEdicao)) {
      return;
    }

    preencherTela();
  }, [props.idRegistroEmEdicao]);

  async function preencherTela() {
    if (props.idRegistroEmEdicao == 0) {
      limparTela();
    } else if (props.idRegistroEmEdicao > 0) {
      await carregarRegistroDoId();
    }
  }

  async function carregarRegistroDoId() {
    try {
      const resposta = await ImobilizadoServico.ObterPorId<ImobilizadoResponse>(
        props.idRegistroEmEdicao
      );
      const viewModel = ImobilizadoAdapter.ToViewModel(resposta.model);
      dadosAuditoria = resposta.model;
      reset(viewModel);
    } catch (error) {
      tratarErroApi(error);
    }
  }

  function removerCiapDoImobilizadoSeNecessario(model: ImobilizadoViewModel) {
    if (
      _.isEqual(model.ciapDoImobilizado, novoRegistro.ciapDoImobilizado) ||
      !model.sujeitoAoCiap
    ) {
      model.ciapDoImobilizado = null;
    }
  }

  function limparTela() {
    dadosAuditoria = undefined;
    reset(novoRegistro);
  }

  async function handleSalvar() {
    try {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesImobilizado.InserirEditar,
        ])
      ) {
        return;
      }
      const model = getValues();

      removerCiapDoImobilizadoSeNecessario(model);

      const request = ImobilizadoAdapter.ToRequest(model);

      const resposta =
        props.idRegistroEmEdicao > 0
          ? await ImobilizadoServico.Atualizar(request)
          : await ImobilizadoServico.Inserir(request);

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar(ResultadoAcaoFormulario.AcaoConcluida);
      }
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  function handleCancelar() {
    fechar(ResultadoAcaoFormulario.AcaoCancelada);
  }

  function fechar(resultado: ResultadoAcaoFormulario) {
    limparTela();
    props.handleCallback(resultado);
  }

  return (
    <>
      <ContainerFormMxp data-testid="edit-form-imobilizado">
        <FormMxp>
          <ProvedorAjuda id="edit-form-imobilizado">
            <input type="hidden" {...register("id")} defaultValue={0} />
            <Linha>
              <Coluna md={3}>
                <FormTextBox
                  name="numeroDoPatrimonio"
                  titulo="Número do patrimônio"
                  control={control}
                  tamanhoMaximo={
                    ImobilizadoConstantes.NumeroDoPatrimonioTamanhoMaximo
                  }
                  requerido
                />
              </Coluna>
              <Coluna md={3}>
                <FormSelectBox
                  name="estado"
                  titulo="Estado"
                  control={control}
                  requerido
                  dataSource={SelectItemEnumEstadoDoImobilizado}
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={6}>
                <FormSelectBoxLazyMxp
                  name="itemId"
                  titulo="Item"
                  control={control}
                  requerido
                  configuracoesExibicaoEBusca={configuracaoExibicaoEBuscaItem}
                  dataSource={itemDataSource}
                  seletorConfig={{
                    modo: "selecaoUnica",
                    titulo: "Selecionar item",
                    componenteGrid: (
                      <GridItem filtrosNoServidor={filtroPadraoParaItem} />
                    ),
                  }}
                  labelSemDados="Sem dados"
                  onSelectionChanged={(e) => {
                    if (!checarSeFormFoiTocado(formState)) {
                      setDescricaoFoiPersonalizada(false);
                      return;
                    }

                    tratarDescricao(e);
                  }}
                  permissoesNecessarias={[PermissoesItem.Consultar]}
                />
              </Coluna>
              <Coluna md={6}>
                <ComboContaContabilMxp
                  name={"contaContabilId"}
                  control={control}
                  titulo="Conta contábil"
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={12}>
                <FormTextBox
                  name="descricao"
                  titulo="Descrição"
                  control={control}
                  tamanhoMaximo={ImobilizadoConstantes.DescricaoTamanhoMaximo}
                  requerido
                  onKeyDown={() => {
                    setValue("descricaoEstaFixa", true);
                    setDescricaoFoiPersonalizada(true);
                  }}
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={6}>
                <FormTextBox
                  name="utilizacaoDoBem"
                  titulo="Utilização do bem"
                  control={control}
                  tamanhoMaximo={
                    ImobilizadoConstantes.UtilizacaoDoBemTamanhoMaximo
                  }
                  requerido
                />
              </Coluna>
              <Coluna md={6}>
                <FormSelectBoxLazyMxp
                  name="centroDeCustosId"
                  titulo="Centro de custos"
                  control={control}
                  requerido
                  dataSource={centroDeCustosDataSource}
                  configuracoesExibicaoEBusca={
                    configuracoesExibicaoSelectCentroCusto
                  }
                  labelSemDados="Sem dados"
                  seletorConfig={{
                    modo: "selecaoUnica",
                    titulo: "Selecionar centro de custos",
                    componenteGrid: <GridCentroDeCustos />,
                  }}
                  permissoesNecessarias={[PermissoesContaContabil.Consultar]}
                />
              </Coluna>
            </Linha>
            <TabPanel
              showNavButtons
              swipeEnabled={false}
              itemTitleRender={(item: any) => item.text}
              deferRendering={false}
              height={"max(40vh, 465px)"}
            >
              <Item text="Depreciação">
                <TabContainer>
                  <AbaDepreciacaoImobilizado hookForm={hookForm} />
                </TabContainer>
              </Item>
              <Item text="CIAP">
                <TabContainer>
                  <ContextoModelosDocumentos55e67CadastroImobilizado.Provider
                    value={{ modelos, setModelos }}
                  >
                    <AbaCiapImobilizado
                      idRegistroEdicao={props.idRegistroEmEdicao}
                      hookForm={hookForm}
                    />
                  </ContextoModelosDocumentos55e67CadastroImobilizado.Provider>
                </TabContainer>
              </Item>
            </TabPanel>
          </ProvedorAjuda>
        </FormMxp>
        <ToolbarMxp dadosAuditoria={dadosAuditoria}>
          <BotaoSalvarMxp handleClick={handleSubmit(handleSalvar)} />
          <BotaoCancelarMxp handleClick={handleCancelar} />
        </ToolbarMxp>
      </ContainerFormMxp>
    </>
  );
}
