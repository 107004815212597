import { DataGridRef } from "devextreme-react/data-grid";
import dxContextMenu, { ItemRenderedEvent } from "devextreme/ui/context_menu";
import { ContextMenuPreparingEvent } from "devextreme/ui/data_grid";
import { Key } from "ts-key-enum";
import { ClickAction } from "../../models/shared/ui/types";
import { AtalhoTooltip } from "../atalhos/colecao-atalhos";
import exibirNotificacaoToast, {
  MensagensParaNotificacao,
  TipoNotificacao,
} from "../common/notificacoes-utils";
import { GestorEventoClick } from "./gestor-evento-click";

interface AcoesEditarExcluir {
  isMenuPrincipal?: boolean;
  editar?: ClickAction;
  excluir?: ClickAction;
  itensAdicionais?: ItemContextMenu[];
  exibirIconeEditarMenuAcoes?: boolean;
  exibirIconeExcluirMenuAcoes?: boolean;
}

export interface ItemContextMenuMxp {
  text: string;
  name?: string;
  icon?: string;
  disabled?: boolean;
  disabledIcon?: boolean;
  visivel?: (registro: any) => boolean;
  hint?: string;
  items?: ItemContextMenuMxp[];
  atalho?: AtalhoTooltip;
  gestorEventoClick?: GestorEventoClick;
  exibirNoMenuPrincipal?: boolean;
  exibirNaLinhaDaGrid?: "sempre" | "menuDeContexto";
  beginGroup?: boolean;
  propriedadesComputadas?: [
    keyof Omit<ItemContextMenuMxp, "propriedadesComputadas">,
    (data: any) => any
  ][];
}

export interface ItemContextMenuNativo {
  text: string;
  name?: string;
  icon?: string;
  onClick?: ClickAction;
  disabled?: boolean;
  disabledIcon?: boolean;
  visivel?: (registro: any) => boolean;
  hint?: string;
  items?: ItemContextMenuNativo[];
  beginGroup?: boolean;
}

export interface ItemContextMenu {
  text: string;
  name?: string;
  icon?: string;
  onClick?: ClickAction;
  disabled?: boolean;
  disabledIcon?: boolean;
  visible?: boolean;
  mostraNaColunaDeAcoes?: boolean;
  ocultarNoMenuPrincipal?: boolean;
  ocultarNoMaisOpcoes?: boolean;
  hint?: string;
  items?: ItemContextMenu[];
  atalho?: AtalhoTooltip;
}

export function tratarDadosContextMenu<T>(
  data: any,
  gridRef: any,
  getGridRef: () => DataGridRef<any, any>,
  isMenuPrincipal?: boolean
): [() => T | undefined, (() => T[] | undefined) | undefined] {
  const gridInstance =
    (getGridRef ? getGridRef()?.instance() : undefined) ?? gridRef?.instance;
  const pegarSelecionado = data === undefined;
  const getData = () => {
    let instance = gridInstance;
    if (getGridRef && instance == undefined) {
      instance = getGridRef()?.instance();
    }
    if ((pegarSelecionado || isMenuPrincipal) && instance) {
      const registrosSelecionados = instance?.getSelectedRowsData().length;
      data =
        registrosSelecionados == 1
          ? (instance.getSelectedRowsData()[0] as T)
          : undefined;

      if (registrosSelecionados < 1) {
        exibirNotificacaoToast({
          mensagem: MensagensParaNotificacao.EhNecessarioSelecionarUmRegistro,
          tipo: TipoNotificacao.Advertencia,
        });
      }

      if (registrosSelecionados > 1) {
        exibirNotificacaoToast({
          mensagem:
            MensagensParaNotificacao.EhNecessarioSelecionarApenasUmRegistro,
          tipo: TipoNotificacao.Advertencia,
        });
      }
    }

    if (instance && instance.hasEditData()) {
      const editedRow = instance
        .getVisibleRows()
        .filter((x: any) => x.key == data.id)[0];
      // Pega sempre o valor mais atual mostrado na tabela.
      if (editedRow.oldData != undefined) {
        return editedRow.data as T;
      }
    }

    return data ? (data as T) : undefined;
  };

  const getSelecionados = () => {
    let instance = gridInstance;
    if (getGridRef && instance == undefined) {
      instance = getGridRef()?.instance();
    }

    const selectedRows = instance.getSelectedRowsData();
    const quantidadeDeRegistrosSelecionados = selectedRows.length;

    if (quantidadeDeRegistrosSelecionados < 1) {
      exibirNotificacaoToast({
        mensagem: MensagensParaNotificacao.EhNecessarioSelecionarUmRegistro,
        tipo: TipoNotificacao.Advertencia,
      });

      return undefined;
    }

    return selectedRows as T[];
  };

  return [getData, pegarSelecionado ? getSelecionados : undefined];
}

export function criarItensMenuContextoPadrao({
  isMenuPrincipal,
  editar,
  excluir,
  itensAdicionais,
  exibirIconeEditarMenuAcoes,
  exibirIconeExcluirMenuAcoes,
}: AcoesEditarExcluir) {
  const itens: ItemContextMenu[] = [];
  const exibirIconeEditar = exibirIconeEditarMenuAcoes ?? true;
  const exibirIconeExcluir = exibirIconeExcluirMenuAcoes ?? true;

  if (editar && exibirIconeEditar) {
    itens.push({
      text: "Editar",
      name: "Editar 2",
      icon: "ic-material-symbols-outlined ic-edit",
      onClick: editar,
      mostraNaColunaDeAcoes: exibirIconeEditar,
      ocultarNoMenuPrincipal: true,
      hint: "Editar",
      atalho: { tecla: "E", ctrl: true },
    });
  }

  if (excluir && exibirIconeExcluir) {
    itens.push({
      text: "Excluir",
      icon: "ic-material-symbols-outlined ic-delete",
      onClick: excluir,
      mostraNaColunaDeAcoes: exibirIconeExcluir,
      ocultarNoMenuPrincipal: true,
      hint: "Excluir",
      atalho: { tecla: Key.Delete, conflitaComInputs: true },
    });
  }

  if (itensAdicionais) {
    itens.push(...itensAdicionais);
  }

  if (isMenuPrincipal) {
    return aplicarCssCorIcones(itens).filter((e) => !e.ocultarNoMenuPrincipal);
  }

  return aplicarCssCorIcones(itens);
}

export function criarTooltipsMenuContexto(
  e: ContextMenuPreparingEvent<any, any>
) {
  const menu = e.element.querySelector(".dx-context-menu[role='presentation']");
  const menuInstance = dxContextMenu.getInstance(menu as any) as dxContextMenu;

  menuInstance.on("itemRendered", eventoTooltipContextMenu);
}

export function eventoTooltipContextMenu(i: ItemRenderedEvent) {
  if ((i.itemData as any).hint) {
    i.itemElement.setAttribute("title", (i.itemData as any).hint);
  }
  if ((i.itemData as any).disabledIcon) {
    const classes = i.itemElement.getAttribute("class");
    i.itemElement.setAttribute("class", classes + " ic-material-disabled");
  }
}

export function aplicarCssCorIcones(itens: ItemContextMenu[]) {
  for (const item of itens) {
    item.icon += " icone-linha-grid";
    item.name = item.text;

    if (item.disabledIcon) {
      item.icon += " ic-material-disabled";
    }

    if (item.items) {
      item.items = aplicarCssCorIcones(item.items);
    }
  }

  return itens;
}
