export enum PermissoesInsumosDasOrdensDeProducao {
  Consultar = "INS000",
  BaixarEConcluirBaixas = "INS01",
  DesfazerEstornarBaixas = "INS001",
  InserirEditar = "INS004",
  BaixarInsumoDaOrdemDeProducaoNaoTotalmenteReservadaParaTerceiro = "INS002",
  BaixarInsumosEmMassa = "INS003",
  Excluir = "INS005",
  AlterarEstadoDaTerceirizacao = "INS006",
}
