import { useCallback, useContext } from "react";
import FormMxp from "../../../../../components/layout/form";
import { ContainerFormMxp } from "../../../../../components/layout/form/styles";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import RelatorioPersonalizadoContexto from "../../../../relatorio/relatorio-personalizado/contexts/relatorio-personalizado.context";
import { FinalidadeModeloDeDocumentoOuRelatorioTituloHelper } from "../../models/modelo-de-documento-ou-relatorio.enums";
import GridModeloDeDocumentoOuRelatorio from "../grid";

export default function ModalGerenciarModelosDeRelatoriosPersonalizados() {
  const {
    finalidade,
    modalConfigurarModeloDeDocumentoOuRelatorioVisivel,
    definirModalConfigurarModeloDeDocumentoOuRelatorioVisivel,
  } = useContext(RelatorioPersonalizadoContexto);

  const handleFechar = useCallback(() => {
    definirModalConfigurarModeloDeDocumentoOuRelatorioVisivel(false);
  }, []);

  return (
    <>
      <ModalMxp
        titulo={`Modelos de documentos/relatórios de ${FinalidadeModeloDeDocumentoOuRelatorioTituloHelper.obterTitulo(
          finalidade!
        )}`}
        visivel={modalConfigurarModeloDeDocumentoOuRelatorioVisivel}
        handleFechar={handleFechar}
      >
        <ContainerFormMxp>
          <FormMxp>
            <GridModeloDeDocumentoOuRelatorio finalidade={finalidade!} />
          </FormMxp>
        </ContainerFormMxp>
      </ModalMxp>
    </>
  );
}
