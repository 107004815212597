import DataGrid, { Column, DataGridRef } from "devextreme-react/data-grid";
import { useCallback, useEffect, useMemo, useRef } from "react";
import GridColunaAcoes from "../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import { useRegistrarAtalhosGrid } from "../../../../../hooks/atalhos.hooks";
import { useControlarFormDeEdicao } from "../../../../../hooks/form.hooks";
import { useParametroId } from "../../../../../hooks/route.hooks";
import { ResultadoAcaoFormulario } from "../../../../../models/shared/ui/formularios";
import GetColunasDeAuditoria from "../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import {
  checarResponseExibeMensagemExclusaoDeSucesso,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import NormalizaTituloModal from "../../../../../utils/common/normaliza-titulo";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";

import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import { useMenuDeContextosGrid } from "../../../../../hooks/menus.hooks";
import { PermissoesVeiculo } from "../../../../../models/permissoes/vendas/veiculo/permissoes-veiculo";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import UrlUtils from "../../../../../utils/url/url-utils";
import { obterMensagemExclusao, StringsComum } from "../../../../comum/strings";
import { VeiculoGridModel } from "../../models/veiculo";
import { VeiculoServico } from "../../servicos/veiculo.servico";
import { StringsVeiculo } from "../../strings";
import FormVeiculo from "../formulario";

const dataSource = VeiculoServico.ObterDataSourceParaGrid<VeiculoGridModel>();

const nameOfGridHandler = criarNameof<VeiculoGridModel>();

export default function GridVeiculo() {
  const gridRef = useRef<DataGridRef>(null);
  const parametroId = useParametroId();

  const {
    idRegistroEmEdicao,
    setIdRegistroEmEdicao,
    modalVisivel,
    encerrarEdicao,
  } = useControlarFormDeEdicao(NaN);

  const handleNovoRegistro = useCallback(() => {
    if (
      verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesVeiculo.InserirEditar,
      ])
    ) {
      setIdRegistroEmEdicao(0);
    }
  }, []);

  const handleEditarRegistro = useCallback((registro: VeiculoGridModel) => {
    if (
      verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesVeiculo.InserirEditar,
      ])
    ) {
      setIdRegistroEmEdicao(registro.id);
    }
  }, []);

  const handleExcluirRegistro = useCallback(
    async (registro: VeiculoGridModel) => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesVeiculo.Excluir,
        ])
      ) {
        return;
      }

      const mensagem = obterMensagemExclusao(
        "o",
        "veículo",
        registro.descricao
      );
      const excluir = await exibirConfirmacao(
        StringsComum.exclusaoTitulo,
        mensagem
      );

      if (excluir) {
        try {
          const resposta = await VeiculoServico.Excluir(registro.id);

          if (resposta) {
            checarResponseExibeMensagemExclusaoDeSucesso(resposta);
            handleAtualizarGrid();
          }
        } catch (erro) {
          tratarErroApi(erro);
        }
      }
    },
    []
  );

  const gridController = useMemo(
    () =>
      new GridController<VeiculoGridModel>(() => gridRef.current?.instance()),
    []
  );

  const menuContexto = useMenuDeContextosGrid([], true, gridController);

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar("veiculo", () => gridRef.current?.instance())
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(NomesTelas.veiculos)
      .definirBotaoNovo(handleNovoRegistro)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirSelecao()
      .definirOrdenacao()
      .definirMenuDeContexto(menuContexto)
      .definirDuploCliqueLinha(handleEditarRegistro)
      .build();
  }, [menuContexto]);

  useEffect(() => {
    if (parametroId || parametroId === 0) {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesVeiculo.InserirEditar,
        ])
      ) {
        return;
      }

      setIdRegistroEmEdicao(parametroId);
    }
  }, []);

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  useRegistrarAtalhosGrid<VeiculoGridModel>({
    controller: gridController,
    handleNovo: handleNovoRegistro,
    handleEditar: handleEditarRegistro,
    handleExcluir: handleExcluirRegistro,
  });

  const handleCallbackFormulario = useCallback(
    (resultado: ResultadoAcaoFormulario) => {
      encerrarEdicao();

      if (resultado == ResultadoAcaoFormulario.AcaoConcluida) {
        handleAtualizarGrid();
      }

      UrlUtils.RemoverParametroId(parametroId);
    },
    [parametroId]
  );

  const handleFecharModal = useCallback(() => {
    encerrarEdicao();
    UrlUtils.RemoverParametroId(parametroId);
  }, [parametroId]);

  return (
    <>
      <ProvedorAjuda id={"tooltips-grid-veiculo"}>
        <DataGrid ref={gridRef} {...configuracoesGrid}>
          {GridColunaAcoes<VeiculoGridModel>({
            handleEditar: handleEditarRegistro,
            handleExcluir: handleExcluirRegistro,
          })}
          <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
          <Column
            key={nameOfGridHandler("descricao")}
            dataField={nameOfGridHandler("descricao")}
            {...obterConfiguracaoColuna("stringGG")}
            sortIndex={0}
            sortOrder="asc"
            caption={StringsComum.descricao}
          />
          <Column
            key={nameOfGridHandler("tipoRodado")}
            dataField={nameOfGridHandler("tipoRodado")}
            {...obterConfiguracaoColuna("stringG")}
            caption={StringsVeiculo.rodado}
          />
          <Column
            key={nameOfGridHandler("tipoCarroceria")}
            dataField={nameOfGridHandler("tipoCarroceria")}
            {...obterConfiguracaoColuna("stringG")}
            caption={StringsVeiculo.carroceria}
          />
          {GetColunasDeAuditoria()}
        </DataGrid>
      </ProvedorAjuda>

      <ModalMxp
        titulo={NormalizaTituloModal.Normalizar(
          idRegistroEmEdicao,
          StringsVeiculo.nomeModal
        )}
        idRegistro={idRegistroEmEdicao}
        visivel={modalVisivel}
        handleFechar={handleFecharModal}
        largura={"max(50vw, 800px)"}
      >
        <FormVeiculo
          idRegistroEmEdicao={idRegistroEmEdicao}
          handleCallback={handleCallbackFormulario}
        />
      </ModalMxp>
    </>
  );
}
