import { FormPopover } from "../../../../../components/formularios";
import { SelectItemEnumSituacaoMDFe } from "../../../../../models/const/dicionario-combos/mdfe";
import { obterDescricaoSelectItem } from "../../../../../utils/common/common-utils";
import { SituacaoMDFe } from "../../models/mdfe-enums";
import { LinkRejeicao } from "../formulario/styles";

interface InfosCabecalhoMDFeProps {
  numero?: number | null;
  serie?: string;
  situacao: SituacaoMDFe;
  motivoRejeicao?: string | null;
  chaveAcesso?: string | null;
}

export default function InfosCabecalhoMDFe(props: InfosCabecalhoMDFeProps) {
  return (
    <>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ flexGrow: 0 }}>
          <strong>Número: </strong>
          {props.numero ?? "Não informado"}
          &nbsp;&nbsp;&nbsp;
          <strong>Série: </strong>
          {props.serie ? props.serie : "Não informado"}
          &nbsp;&nbsp;&nbsp;
          <strong>Situação: </strong>
          {obterDescricaoSelectItem(props.situacao, SelectItemEnumSituacaoMDFe)}
          &nbsp;&nbsp;&nbsp;
          <strong>Chave de acesso: </strong>
          {props.chaveAcesso ? props.chaveAcesso : "Não informada"}
        </div>
        {props.motivoRejeicao && (
          <div style={{ flexGrow: 1, textAlign: "right" }}>
            <>
              <LinkRejeicao id="linkVisualizacaoRejeicao">
                Visualizar o motivo da rejeição
              </LinkRejeicao>
              &nbsp;&nbsp;
              <i
                className="fa-solid fa-circle-info"
                style={{ color: "red" }}
              ></i>
              <FormPopover
                alvo="#linkVisualizacaoRejeicao"
                texto={props.motivoRejeicao}
              />
            </>
          </div>
        )}
      </div>
    </>
  );
}
